import { UNKNOWN } from '../../api'
import { ILogin, IUserLogin } from '../../types/interfaces/login'
import {
  FIRST_TIME_LOGIN,
  GET_PROFILE,
  LOGOUT_USER,
  POINT_UPDATE,
  SET_COMMUNICATION,
  SET_IS_LOGIN,
  SET_IS_LOGIN_LOADER,
  SET_USER_2FA,
  SET_USER_ACCOUNT_LIST,
  SET_USER_BONUS_APPLIED_LIST,
  SET_USER_BONUS_LIST,
  SET_USER_COUNTRY,
  SET_USER_FAVORITE_EVENT_LIST,
  SET_USER_FAVORITE_MARKET_LIST,
  SET_USER_PAYMENT_LIST,
  SET_USER_QUICK_BET,
  SET_USER_QUICK_BET_VALUE,
  UPDATE_USER_POINTS,
} from '../actions'
import { GET_FAVORITE_LIST_EVENTS_NAMES } from '../type'

export interface ISetLogin {
  type: string
  isLogin: IUserLogin
  availablePts?: number
  pts?: number
  exposure?: number
  isLoginLoader: boolean
  loading: boolean
  payload: any
}

export const DEFAULT_COUNTRY_DETAIL = {
  continent: UNKNOWN,
  city: UNKNOWN,
  country_code: UNKNOWN,
  country: UNKNOWN,
  country_flag: '',
  region: UNKNOWN,
}

const initialState: ILogin = {
  userLogInLoader: false,
  firstTimeLogin: false,
  favoriteListEventNames: [],
  userBonus: [],
  isLogin: false,
  quickBet: false,
  pointUpdate: false,
  quickBetValue: 0,
  communicationChannel: '',
  userAccountList: [],
  userPaymentList: [],
  userBonusList: [],
  eventFavoriteList: [],
  marketFavoriteList: [],
  userCountryDetail: DEFAULT_COUNTRY_DETAIL,
  userData: {
    enable2fa: false,
    demo: false,
    totalCommission: 0,
    totalAdmin: 0,
    totalUser: 0,
    userAccount: 0,
    userCount: 0,
    availablePts: 0,
    casinoPts: 0,
    clientPL: 0,
    creditPts: 0,
    exposure: 0,
    pts: 0,
    sportsPts: 0,
    thirdPartyPts: 0,
    whiteLabelType: 'b2b',
    blocked: false,
    createdAt: '',
    email: '',
    emailVerified: false,
    fcmToken: '',
    fullName: '',
    profileSetup: false,
    updatedAt: '',
    userName: '',
    userType: '',
    whiteLabelId: '',
    _id: '',
    profit: 0,
    loss: 0,
    confirmBets: false,
  },
  getProfileLoading: false,
}

export default function (state = initialState, action: ISetLogin) {
  switch (action.type) {
    case SET_IS_LOGIN_LOADER: {
      return {
        ...state,
        userLogInLoader: action.isLoginLoader,
      }
    }

    case UPDATE_USER_POINTS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          pts: action.pts,
          availablePts: action.availablePts,
          exposure: action.exposure,
        },
      }
    }

    case POINT_UPDATE: {
      return {
        ...state,
        pointUpdate: action.payload,
      }
    }

    case SET_USER_BONUS_APPLIED_LIST: {
      return {
        ...state,
        userBonus: action.payload,
      }
    }

    case SET_USER_COUNTRY: {
      return {
        ...state,
        userCountryDetail: action.payload,
      }
    }

    case FIRST_TIME_LOGIN: {
      return {
        ...state,
        firstTimeLogin: action.payload,
      }
    }

    case SET_USER_2FA: {
      return {
        ...state,
        userData: {
          ...state.userData,
          enable2fa: action.payload,
        },
      }
    }

    case 'POINT_REMOVED': {
      return {
        ...state,
        userData: {
          ...state.userData,
          availablePts: state.userData.availablePts - action.payload,
        },
      }
    }

    case SET_USER_FAVORITE_EVENT_LIST: {
      return {
        ...state,
        eventFavoriteList: action.payload,
      }
    }

    case GET_FAVORITE_LIST_EVENTS_NAMES: {
      return {
        ...state,
        favoriteListEventNames: action.payload,
      }
    }

    case SET_USER_FAVORITE_MARKET_LIST: {
      return {
        ...state,
        marketFavoriteList: action.payload,
      }
    }

    case SET_USER_QUICK_BET: {
      return {
        ...state,
        quickBet: action.payload,
      }
    }

    case SET_USER_QUICK_BET_VALUE: {
      return {
        ...state,
        quickBetValue: action.payload,
      }
    }

    case SET_USER_ACCOUNT_LIST: {
      return {
        ...state,
        userAccountList: action.payload,
      }
    }

    case SET_USER_BONUS_LIST: {
      return {
        ...state,
        userBonusList: action.payload,
      }
    }

    case SET_USER_PAYMENT_LIST: {
      return {
        ...state,
        userPaymentList: action.payload,
      }
    }

    case SET_COMMUNICATION: {
      return {
        ...state,
        communicationChannel: action.payload,
      }
    }

    case SET_IS_LOGIN: {
      return {
        ...state,
        userData: action.isLogin,
        isLogin: true,
      }
    }

    case LOGOUT_USER: {
      return {
        ...state,
        userData: initialState.userData,
        isLogin: false,
      }
    }

    case GET_PROFILE: {
      return {
        ...state,
        getProfileLoading: action.loading,
      }
    }
    default:
      return state
  }
}

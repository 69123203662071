import React, { ReactNode, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import SportsChip from './SportsChip'
import { Box, useMediaQuery } from '@mui/material'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'

interface BannerSlidesProps {
  children: ReactNode[]
  hightLightMatch: any[]
}
const BannerSlider: React.FC<BannerSlidesProps> = ({
  children,
  hightLightMatch,
}) => {
  const isLargeScreen = useMediaQuery('(min-width:1360px)')
  const swiperOptions: SwiperOptions = {
    loop: true,
    speed: 300,
    effect: 'fade',
    navigation: {
      nextEl: '.swiper-button-next-13',
      prevEl: '.swiper-button-prev-13',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      bulletActiveClass: 'w-8',
      bulletClass:
        'dark:bg-white bg-black h-1 w-4 rounded-2xl cursor-pointer transition-all duration-500',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },
      1200: {
        slidesPerView: 1,
      },
    },
  }
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        {...swiperOptions}
        className='banner-mySwiper'
      >
        <div
          style={{ position: 'relative', width: '100%', overflow: 'hidden' }}
        >
          {!!children &&
            !!children.length &&
            children.map((child, index) => (
              <SwiperSlide key={index}>{child}</SwiperSlide>
            ))}
        </div>
        <div
          className='cursor-pointer absolute z-10 hidden -translate-y-1/2 swiper-button-prev-13 md:block top-1/2 left-20'
          id='swiper-button-prev-13'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
          >
            <path
              d='M30 15C30 12.0333 29.1203 9.13319 27.472 6.66645C25.8238 4.19971 23.4811 2.27713 20.7403 1.14182C17.9994 0.00650024 14.9834 -0.290548 12.0737 0.288229C9.16394 0.867006 6.49119 2.29562 4.39341 4.39341C2.29562 6.49119 0.867006 9.16394 0.288229 12.0737C-0.290548 14.9834 0.00650024 17.9994 1.14182 20.7403C2.27713 23.4811 4.19971 25.8238 6.66645 27.472C9.13319 29.1203 12.0333 30 15 30C18.9769 29.9957 22.7897 28.414 25.6019 25.6019C28.414 22.7897 29.9957 18.9769 30 15ZM2.50001 15C2.50001 12.5277 3.23312 10.111 4.60664 8.05538C5.98015 5.99976 7.93238 4.39761 10.2165 3.45151C12.5005 2.50542 15.0139 2.25788 17.4386 2.74019C19.8634 3.22251 22.0907 4.41302 23.8388 6.16117C25.587 7.90933 26.7775 10.1366 27.2598 12.5614C27.7421 14.9861 27.4946 17.4995 26.5485 19.7835C25.6024 22.0676 24.0002 24.0199 21.9446 25.3934C19.889 26.7669 17.4723 27.5 15 27.5C11.6859 27.4964 8.50861 26.1782 6.16519 23.8348C3.82178 21.4914 2.50365 18.3141 2.50001 15Z'
              fill='white'
            />
            <path
              d='M10 15C9.99907 14.085 10.3335 13.2014 10.94 12.5163C11.3038 12.1075 11.6575 11.72 11.9113 11.4663L15.4413 7.87501C15.5543 7.75017 15.6914 7.64946 15.8443 7.57892C15.9972 7.50839 16.1628 7.46947 16.3311 7.46452C16.4995 7.45957 16.6671 7.48869 16.8238 7.55012C16.9806 7.61155 17.1234 7.70403 17.2436 7.82201C17.3638 7.93998 17.4588 8.08103 17.5231 8.23667C17.5875 8.39231 17.6196 8.55934 17.6178 8.72773C17.6159 8.89612 17.5801 9.06241 17.5124 9.21659C17.4447 9.37078 17.3465 9.5097 17.2238 9.62501L13.6875 13.225C13.4538 13.46 13.1363 13.81 12.8125 14.1738C12.6111 14.4021 12.4999 14.6961 12.4999 15.0006C12.4999 15.3051 12.6111 15.5992 12.8125 15.8275C13.135 16.19 13.4525 16.54 13.6788 16.7675L17.2238 20.375C17.3465 20.4903 17.4447 20.6292 17.5124 20.7834C17.5801 20.9376 17.6159 21.1039 17.6178 21.2723C17.6196 21.4407 17.5875 21.6077 17.5231 21.7633C17.4588 21.919 17.3638 22.06 17.2436 22.178C17.1234 22.296 16.9806 22.3885 16.8238 22.4499C16.6671 22.5113 16.4995 22.5404 16.3311 22.5355C16.1628 22.5305 15.9972 22.4916 15.8443 22.4211C15.6914 22.3505 15.5543 22.2498 15.4413 22.125L11.905 18.5263C11.655 18.2763 11.305 17.8925 10.9438 17.485C10.3353 16.8003 9.99944 15.916 10 15Z'
              fill='white'
            />
          </svg>
        </div>
        <div
          className='cursor-pointer absolute z-10 hidden -translate-y-1/2 swiper-button-next-13 md:block top-1/2 right-20'
          id='swiper-button-next-13'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
          >
            <path
              d='M0 15C0 12.0333 0.879734 9.13319 2.52796 6.66645C4.17618 4.19971 6.51886 2.27713 9.25975 1.14182C12.0006 0.00650024 15.0166 -0.290548 17.9263 0.288229C20.8361 0.867006 23.5088 2.29562 25.6066 4.39341C27.7044 6.49119 29.133 9.16394 29.7118 12.0737C30.2905 14.9834 29.9935 17.9994 28.8582 20.7403C27.7229 23.4811 25.8003 25.8238 23.3335 27.472C20.8668 29.1203 17.9667 30 15 30C11.0231 29.9957 7.21026 28.414 4.39815 25.6019C1.58604 22.7897 0.00430135 18.9769 0 15ZM27.5 15C27.5 12.5277 26.7669 10.111 25.3934 8.05538C24.0198 5.99976 22.0676 4.39761 19.7835 3.45151C17.4995 2.50542 14.9861 2.25788 12.5614 2.74019C10.1366 3.22251 7.90932 4.41302 6.16116 6.16117C4.41301 7.90933 3.2225 10.1366 2.74018 12.5614C2.25787 14.9861 2.50541 17.4995 3.4515 19.7835C4.3976 22.0676 5.99976 24.0199 8.05537 25.3934C10.111 26.7669 12.5277 27.5 15 27.5C18.3141 27.4964 21.4914 26.1782 23.8348 23.8348C26.1782 21.4914 27.4964 18.3141 27.5 15Z'
              fill='white'
            />
            <path
              d='M20 15C20.0009 14.085 19.6665 13.2014 19.06 12.5163C18.6962 12.1075 18.3425 11.72 18.0887 11.4663L14.5587 7.87501C14.4457 7.75017 14.3086 7.64946 14.1557 7.57892C14.0028 7.50839 13.8372 7.46947 13.6689 7.46452C13.5005 7.45957 13.3329 7.48869 13.1762 7.55012C13.0194 7.61155 12.8766 7.70403 12.7564 7.82201C12.6362 7.93998 12.5412 8.08103 12.4769 8.23667C12.4125 8.39231 12.3804 8.55934 12.3822 8.72773C12.3841 8.89612 12.4199 9.06241 12.4876 9.21659C12.5553 9.37078 12.6535 9.5097 12.7762 9.62501L16.3125 13.225C16.5462 13.46 16.8637 13.81 17.1875 14.1738C17.3889 14.4021 17.5001 14.6961 17.5001 15.0006C17.5001 15.3051 17.3889 15.5992 17.1875 15.8275C16.865 16.19 16.5475 16.54 16.3212 16.7675L12.7762 20.375C12.6535 20.4903 12.5553 20.6292 12.4876 20.7834C12.4199 20.9376 12.3841 21.1039 12.3822 21.2723C12.3804 21.4407 12.4125 21.6077 12.4769 21.7633C12.5412 21.919 12.6362 22.06 12.7564 22.178C12.8766 22.296 13.0194 22.3885 13.1762 22.4499C13.3329 22.5113 13.5005 22.5404 13.6689 22.5355C13.8372 22.5305 14.0028 22.4916 14.1557 22.4211C14.3086 22.3505 14.4457 22.2498 14.5587 22.125L18.095 18.5263C18.345 18.2763 18.695 17.8925 19.0562 17.485C19.6647 16.8003 20.0006 15.916 20 15Z'
              fill='white'
            />
          </svg>
        </div>
      </Swiper>
      {isLargeScreen && (
        <Box
          sx={{
            overflowX: 'auto',
            '::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
            width: '100%',
          }}
          className='flex md:justify-center absolute z-20 flex w-full mx-0 xs:top-4 lg:top-[132px] overflow-x-auto'
        >
          <div className='flex justify-center sm:px-10 px-3 gap-6 AtScroller'>
            {!!hightLightMatch &&
              !!hightLightMatch.length &&
              hightLightMatch.map((data: any, index: number) => (
                <SportsChip data={data} key={index} />
              ))}
          </div>
        </Box>
      )}
      <div className='items-center justify-center hidden w-full gap-3 mt-3 swiper-pagination lg:flex'></div>
    </>
  )
}
export default BannerSlider

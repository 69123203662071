import { IThemeConfiguration } from '../../../types/interfaces/themeConfiguration'
import { IAppThemeConfiguration } from '../../actions/appThemeConfiguration/IAppThemeConfiguration'

import { APP_THEME_CONFIGURATION } from '../../type'

const initialState: IThemeConfiguration = {} as any

export default function (state = initialState, action: IAppThemeConfiguration) {
  switch (action.type) {
    case APP_THEME_CONFIGURATION: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

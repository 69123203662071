import { FC, useEffect, useState } from 'react'
import {
  getPosterForEventType,
  IsMarketInPlay,
  isMarketInPlayOpenDate,
} from '../../../../utils/internalUtils'
import { IThemeConfiguration } from '../../../../types/interfaces/themeConfiguration'
import { useNavigate } from 'react-router-dom'
import { BET_TYPE } from '../../../../types/enums/betType'
import { useMediaQuery } from '@mui/material'
import { toast } from 'react-toastify'
import { encrypt } from '../../../../api/authHelper'

export interface IFastestContent {
  marketStatus: any
  isDarkMode?: boolean
  onPlaceOrder: any
  isLogin?: boolean
  setIsDrawerOpen: any
  updateOpenLoginScreen: any
  themeConfiguration?: IThemeConfiguration
  liveGame: any
}

export const MOCK_DATA = {
  match: {
    _id: 'MOCK_ID',
    teams: [{ name: '-' }, { name: '-' }],
  },
  liveGame: {
    events: [
      {
        marketId: {
          event: {
            name: '-',
          },
        },
      },
    ],
  },
}

export const FastestOddTileContent: FC<IFastestContent> = (
  props: IFastestContent,
) => {
  return (
    <div className='w-full  shadow-[rgba(60,64,67,0.3)_0px_1px_2px_0px,_rgba(60,64,67,0.15)_0px_2px_6px_2px] border border-[rgba(0,0,0,0.1)] xs:pl-0 xs:ml-0 xs:mr-0 xs:pr-0 sm:w-[313px] md:w-[313px] lg:w-[313px] xl:w-[345px] p-2  m-4 rounded-xl bg dark:bg-[linear-gradient(180deg,_var(--fastest-odd-fastest-odd-1)_0%,_var(--fastest-odd-fastest-odd-2)_100%)] dark:bg-[color:transparent] dark:border border-solid border-[var(--fastest-odd-fastest-odd-3)] bg-white dark:shadow-none shadow-[var(--fastest-odd-fastest-odd-4)]'>
      <div
        style={{
          backgroundImage: `url(${getPosterForEventType(props.liveGame.eventTypeId)})`,
        }}
        className='fit-image-fastest relative'
      ></div>
      <div
        className='bg-white dark:shadow-none  [&>*:nth-child(odd)]:border-b !border-solid border-[#969696] text-black rounded-xl h-auto md:gap-2 gap-2 grid px-5'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {props.liveGame.events.map((match: any, index: number) => {
          return (
            <FastestOddTileContentData
              marketStatus={props.marketStatus.get(match.marketId._id)}
              setIsDrawerOpen={props.setIsDrawerOpen}
              updateOpenLoginScreen={props.updateOpenLoginScreen}
              key={index}
              onPlaceOrder={props.onPlaceOrder}
              isLogin={!!props.isLogin}
              isMock={false}
              liveGame={props.liveGame}
              match={match}
            />
          )
        })}
        {/* box-shadow: ; */}
        {props.liveGame.events.length === 1 && (
          <FastestOddTileContentData
            marketStatus={null}
            setIsDrawerOpen={props.setIsDrawerOpen}
            updateOpenLoginScreen={props.updateOpenLoginScreen}
            onPlaceOrder={props.onPlaceOrder}
            isLogin={!!props.isLogin}
            isMock={true}
            {...MOCK_DATA}
          />
        )}
      </div>
    </div>
  )
}

interface IFastestOddTileContent {
  match: any
  marketStatus: any
  isLogin: boolean
  updateOpenLoginScreen: any
  liveGame: any
  setIsDrawerOpen: any
  isMock: boolean
  onPlaceOrder: any
}

export const FastestOddTileContentData = (props: IFastestOddTileContent) => {
  const navigate = useNavigate()
  const { marketStatus } = props
  const [team2BackColor, setTeam2BackColor] = useState(false)
  const [team1BackColor, setTeam1BackColor] = useState(false)

  useEffect(() => {
    setTeam1BackColor(true)
    setTimeout(() => {
      setTeam1BackColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 0 &&
      marketStatus.teams[0] &&
      marketStatus.teams[0]?.back &&
      marketStatus.teams[0]?.back[2] &&
      marketStatus.teams[0]?.back[2].odd,
  ])

  useEffect(() => {
    setTeam2BackColor(true)
    setTimeout(() => {
      setTeam2BackColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 0 &&
      marketStatus.teams[1] &&
      marketStatus.teams[1]?.back &&
      marketStatus.teams[1]?.back[2] &&
      marketStatus.teams[1]?.back[2].odd,
  ])

  const betClick = () => {
    if (props.isLogin) {
      navigate(`events/${encrypt(props.match.marketId.event.id)}`)
    } else {
      props.updateOpenLoginScreen(true)
    }
  }
  const isTabletScreen = useMediaQuery('(max-width:1200px)')

  const onPlaceOrder = (
    type: BET_TYPE,
    odd: number,
    run: number,
    teamId: string,
  ) => {
    if (!props.isLogin) {
      return props.updateOpenLoginScreen(true)
    }

    // FOR CRICKET
    if (
      props.match.marketId.eventType.id === '4' &&
      props.match.marketId.marketName === 'Match Odds' &&
      odd &&
      odd > 3.99
    ) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    // FOR SOCCER
    if (
      props.match.marketId.eventType.id === '1' &&
      props.match.marketId.marketName === 'Match Odds' &&
      odd &&
      odd > 6.99
    ) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    // FOR TENNIS
    if (
      props.match.marketId.eventType.id === '2' &&
      props.match.marketId.marketName === 'Match Odds' &&
      odd &&
      odd > 6.99
    ) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    if (
      props.match.marketId.marketName === 'Match Odds' &&
      !IsMarketInPlay(props.match.marketId.event.openDate)
    ) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    isTabletScreen && props.setIsDrawerOpen(true)
    props.onPlaceOrder({
      externalType: marketStatus.marketId.externalType,
      manual: marketStatus.marketId.manual,
      marketId: marketStatus.marketId,
      teamId: teamId,
      marketName: marketStatus.marketId.name,
      marketType: marketStatus.type,
      oddType: type,
      odd: odd,
      run: run,
      delaySecond: marketStatus.delaySecond || 0,
      max: marketStatus.max,
      min: marketStatus.min,
    })
  }

  return (
    <div key={props.match._id} className={`cursor-pointer`}>
      <div className='mb-1'>
        <div className='w-content whitespace-nowrap overflow-hidden overflow-ellipsis p-2 mb-1 text-center bold md:!text-sm !text-base font-medium tracking-wide'>
          <div
            className='bold whitespace-nowrap overflow-hidden overflow-ellipsis'
            style={{
              maxWidth: 'calc(28ch + 1rem)',
              textOverflow: 'ellipsis',
            }}
          >
            {!props.isMock
              ? props.match.marketId.event && props.match.marketId.event.name
              : _getLocalString('LOC_MATCH_WILL_BE_LIVE_SOON')}
          </div>
          <div className='bold'>
            {!props.isMock
              ? props.match.marketId.name
              : _getLocalString('LOC_WAIT_FOR_FASTEST_ODDS')}
          </div>
        </div>
        <div className='text-center md:!text-sm !text-base font-medium tracking-wide'></div>
        <div className='flex items-center justify-between mb-2'>
          <div className='xs:!text-[14px] sm:!text-[14px] md:!text-[16px] lg:!text-[17px] xl:!text-[18px] flex items-center justify-center font-bold leading-4 tracking-wider font-bebas'>
            {props.match.teams &&
              props.match.teams[0] &&
              props.match.teams[0].name &&
              props.match.teams[0].name.slice(0, 10)}
            {props.match.teams && props.match.teams[0].name.length > 10
              ? '..'
              : ''}
          </div>
          <div className='flex items-center gap-2'>
            <span className='xs:!text-[13px] sm:!text-[13px] md:!text-[14px] lg:!text-[14px] xl:!text-[16px]'>
              <div>
                <div
                  onClick={() => {
                    marketStatus &&
                      marketStatus.teams[0]?.back &&
                      marketStatus.teams[0]?.back[2] &&
                      onPlaceOrder(
                        BET_TYPE.BACK,
                        marketStatus.teams[0]?.back[2].odd,
                        marketStatus.teams[0]?.back[2].run,
                        marketStatus.teams[0].id,
                      )
                  }}
                  style={{
                    background: !props.isMock
                      ? team1BackColor
                        ? '#FFC107'
                        : '#A5D9FE'
                      : '',
                  }}
                  className='p-2 !text-sm flex justify-center items-center font-bold '
                >
                  {marketStatus &&
                  marketStatus.teams &&
                  marketStatus.teams[0]?.back &&
                  marketStatus.teams[0]?.back[2]
                    ? marketStatus.teams[0]?.back[2].odd
                    : '-'}
                </div>
              </div>
            </span>
            <span>:</span>
            <span className='xs:!text-[13px] sm:!text-[13px] md:!text-[14px] lg:!text-[14px] xl:!text-[16px]'>
              <div>
                <div
                  onClick={() => {
                    marketStatus &&
                      marketStatus.teams[1]?.back &&
                      marketStatus.teams[1]?.back[2] &&
                      onPlaceOrder(
                        BET_TYPE.BACK,
                        marketStatus.teams[1]?.back[2].odd,
                        marketStatus.teams[1]?.back[2].run,
                        marketStatus.teams[1]?.id,
                      )
                  }}
                  style={{
                    background: !props.isMock
                      ? team2BackColor
                        ? '#FFC107'
                        : '#A5D9FE'
                      : '',
                  }}
                  className='p-2 !text-sm flex justify-center items-center font-bold '
                >
                  {marketStatus &&
                  marketStatus.teams &&
                  marketStatus.teams[1]?.back &&
                  marketStatus.teams[1]?.back[2]
                    ? marketStatus.teams[1]?.back[2].odd
                    : '-'}
                </div>
              </div>
            </span>
          </div>
          <div className='xs:!text-[14px] sm:!text-[14px] md:!text-[16px] lg:!text-[17px] xl:!text-[18px] font-bold leading-4 tracking-wider font-bebas'>
            {props.match.teams &&
              props.match.teams[1] &&
              props.match.teams[1].name.slice(0, 10)}
            {props.match.teams &&
            props.match.teams[1] &&
            props.match.teams[1].name.length > 10
              ? '..'
              : ''}
          </div>
        </div>

        <div className='text-sm p-1 flex items-center justify-center'>
          {props.isMock ? (
            '--'
          ) : (
            <div className='bold fastest-odd-blinking-dot' onClick={betClick}>
              {_getLocalString('LOC_CLICK_TO_CHECK_ALL_MARKETS')}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { useLocation } from 'react-router-dom'

interface ILiveChat {
  isLogin: boolean
  userType: string
  currentDomain: ICurrentDomain
}

declare global {
  interface Window {
    __lc: any
    LiveChatWidget: any
  }
}

const LiveChat = (props: ILiveChat) => {
  const { communicationChannel } = useSelector((state: any) => state.login)
  const { pathname } = useLocation()
  const appThemeConfiguration = useSelector(
    (state: any) => state.appThemeConfiguration,
  )

  useEffect(() => {
    let type = communicationChannel?.chat?.type
    let value = communicationChannel?.chat?.value
    const chatDivTag = document.querySelector('.widget-visible')
    if (
      !(communicationChannel?.chat?.type && communicationChannel?.chat?.value)
    ) {
      type = appThemeConfiguration?.registration?.chat?.type
      value = appThemeConfiguration?.registration?.chat?.value
    }

    if (!(type && value)) {
      return
    }

    if (!(props.isLogin && props.userType === 'user')) {
      return
    }

    if (pathname !== '/') {
      chatDivTag?.setAttribute('style', 'display:none !important')
      return
    } else {
      chatDivTag?.removeAttribute('style')
    }

    let script: HTMLScriptElement | null = null

    if (props.currentDomain && props.currentDomain.type === 'b2c') {
      if (type === 'liveChat' && value) {
        if (document.getElementById('livechat-script')) return

        script = document.createElement('script')
        script.id = 'livechat-script'
        script.async = true
        script.type = 'text/javascript'
        script.src = 'https://cdn.livechatinc.com/tracking.js'

        document.head.appendChild(script)

        // Initialize LiveChat settings
        window.__lc = window.__lc || {}
        window.__lc.license = value
        window.__lc.integration_name = 'manual_channels'
        window.__lc.product_name = 'livechat'
        ;(function (n, t, c) {
          function i(n: any) {
            return e._h ? e._h.apply(null, n) : e._q.push(n)
          }
          var e: any = {
            _q: [],
            _h: null,
            _v: '2.0',
            on: function () {
              i(['on', c.call(arguments)])
            },
            once: function () {
              i(['once', c.call(arguments)])
            },
            off: function () {
              i(['off', c.call(arguments)])
            },
            get: function () {
              if (!e._h)
                throw new Error(
                  "[LiveChatWidget] You can't use getters before load.",
                )
              return i(['get', c.call(arguments)])
            },
            call: function () {
              i(['call', c.call(arguments)])
            },
            init: function () {
              var n = t.createElement('script')
              n.async = !0
              n.type = 'text/javascript'
              n.src = 'https://cdn.livechatinc.com/tracking.js'
              t.head.appendChild(n)
            },
          }
          !n.__lc.asyncInit && e.init(),
            (n.LiveChatWidget = n.LiveChatWidget || e)
        })(window, document, [].slice)
      } else if (type === 'zoho' && value) {
        script = document.createElement('script')
        script.setAttribute('type', 'text/javascript')

        let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${value}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

        script.appendChild(document.createTextNode(code))
        document.body.appendChild(script)
      } else if (type === 'pulse' && value) {
        script = document.createElement('script')
        script.src = 'https://cdn.pulse.is/livechat/loader.js'
        script.setAttribute('data-live-chat-id', value)
        script.async = true

        document.body.appendChild(script)
      } else if (type === 'tawk' && value) {
        script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = value
        script.charset = 'UTF-8'
        script.setAttribute('crossorigin', '*')

        document.body.appendChild(script)
      }
    }

    return () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script)
        script = null
      }
    }
  }, [
    props.currentDomain,
    appThemeConfiguration,
    props.isLogin,
    props.userType,
    communicationChannel,
    pathname,
  ])

  return <></>
}

export default LiveChat

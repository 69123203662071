import ClearIcon from '@mui/icons-material/Clear'
import { NavTiles2Mobile } from '../navTiles/NavTiles2Mobile'
import { NavTiles } from '../navTiles/NavTiles'
import { IUserData } from '../../types/interfaces/login'
import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

export interface INavBarProps {
  navBarSportsOpen?: (navBarOpen: boolean) => void
  isLogin?: boolean
  userData?: IUserData
  isDarkMode?: boolean
  currentDomain: any
}

export const MobileNavBarSports = (props: INavBarProps) => {
  const { globalSearchLoading } = useSelector(
    (state: any) => state.appConfiguration,
  )

  const isUserOrNotLoggedIn =
    !props.isLogin || props.userData?.userType === 'user'

  const navBarRef = useRef<HTMLDivElement>(null)

  const closeNavSportsHandler = () => {
    props.navBarSportsOpen && props.navBarSportsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      navBarRef.current &&
      !navBarRef.current.contains(event.target as Node)
    ) {
      closeNavSportsHandler()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (globalSearchLoading) {
      closeNavSportsHandler()
    }
  }, [globalSearchLoading])

  return (
    <div
      ref={navBarRef}
      className={`flex flex-col top-full left-0 pt-6 w-80 !bg-[white] absolute text-white z-[999] ${isUserOrNotLoggedIn ? '' : 'dark:!bg-[#2f040f] !p-0 !pt-5'}`}
      style={
        isUserOrNotLoggedIn
          ? {
              backgroundImage:
                'linear-gradient(301.69deg, var(--register-box-right-1) 32.39%, var(--register-box-right-2) 134.95%)',
            }
          : {}
      }
    >
      <div className='absolute right-5 top-1' onClick={closeNavSportsHandler}>
        <ClearIcon className='!text-white' fontSize='medium' />
      </div>
      {isUserOrNotLoggedIn ? (
        <div className='flex items-center py-1 pb-4  px-3 items-center mt-2'>
          <NavTiles
            isMobile={true}
            currentDomain={props.currentDomain}
            isDarkMode={props.isDarkMode}
            onClickHandlerClose={closeNavSportsHandler}
            userLogInLoader={false}
            showGames={true}
          />
        </div>
      ) : (
        <div className='py-3'>
          <NavTiles2Mobile
            currentDomain={props.currentDomain}
            userData={props.userData}
            isDarkMode={props.isDarkMode}
            onClickHandlerClose={closeNavSportsHandler}
          />
        </div>
      )}
    </div>
  )
}

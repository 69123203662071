import { memo, useCallback, useState, useEffect } from 'react'
import { IOpenBet } from '../NewBetSlip'
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material'
import { Check } from '@mui/icons-material'
import { OpenMatchBet } from './OpenMatchBetContent'
import { OpenMatchBetContentAverage } from './OpenMatchBetContentAverage'

interface IMatchBets {
  openBet: IOpenBet[]
  isShowBetInfo: boolean
  isShowBetForThisEvent: boolean
}

export const MatchBetsComponent = (props: IMatchBets) => {
  const [averageOdds, setAverageOdds] = useState(false)
  const [loading, setLoading] = useState(false)

  const updateAverage = () => {
    setLoading(true)
    setAverageOdds(!averageOdds)
  }

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [averageOdds])

  const [isHovered, setIsHovered] = useState(false)
  const isLargeScreen = useMediaQuery('(min-width:1200px)')

  return (
    <div className='grid items-center '>
      <div className='flex my-2 mb-5 bg-[var(--betslip-average-odds)] justify-evenly '>
        <FormControlLabel
          value={averageOdds}
          onChange={updateAverage}
          className='!m-0'
          control={
            <Checkbox
              checked={averageOdds}
              id='remeberMe'
              icon={<div className='w-5 h-5 rounded-[3px] bg-white '></div>}
              checkedIcon={
                <div className='w-5 h-5  justify-center items-center flex rounded-[3px] bg-white'>
                  <Check className='!text-lg text-[#f42020c9] ' />
                </div>
              }
              className='!pl-0'
            />
          }
          classes={{
            label: '!text-white ',
          }}
          label={_getLocalString('LOC_AVERAGE_ODDS')}
        />
      </div>

      <Box
        className='w-full overflow-auto'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          maxHeight: '350px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            borderRadius: '10px',
            width: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            cursor: 'pointer',
            backgroundColor: 'var(--selected-theme-color2)',
            borderRadius: '10px',
          },
          '@media screen and (min-width: 1200px)': {
            '&::-webkit-scrollbar': {
              height: '10px',
              width: isHovered && isLargeScreen ? '10px' : '0',
            },
          },
        }}
      >
        {loading ? (
          <div className='flex justify-center items-center max-h-[200px]'>
            <CircularProgress />
          </div>
        ) : (
          <div className='text-black bold'>
            {averageOdds ? (
              <OpenMatchBetContentAverage
                isShowBetForThisEvent={props.isShowBetForThisEvent}
                isShowBetInfo={props.isShowBetInfo}
                marketStatus={props.openBet}
              />
            ) : (
              <OpenMatchBet
                isShowBetForThisEvent={props.isShowBetForThisEvent}
                isShowBetInfo={props.isShowBetInfo}
                marketStatus={props.openBet}
              />
            )}
          </div>
        )}
      </Box>
    </div>
  )
}

export const MatchBets = memo(MatchBetsComponent)

import { Dispatch } from '@reduxjs/toolkit'
import {
  GET_ALL_GAME_MANAGEMENT_COMMON_DATA,
  GET_FAVORITE_LIST_EVENTS_NAMES,
  GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS,
  GET_GAME_MANAGEMENT_COMMON_DATA,
  GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING,
  GET_GAME_MANAGEMENT_DATA_THIRD_PARTY,
  GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING,
  GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS,
  GetReduxStateFn,
} from '../type'
import { HEADERS, URLType, makeGetHttpCallAsync } from '../../api'
import { extractIds, hierarchyChangeObject } from '../../utils/internalUtils'
import {
  SET_USER_FAVORITE_EVENT_LIST,
  getMarketsHierarchy,
} from './loginAction'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'

export const GET_COMMON_MANAGEMENT_COMMON_MARKET_ANALYSIS_DATA =
  '@GAMES/GET_COMMON_MANAGEMENT_COMMON_MARKET_ANALYSIS_DATA'
export const UPDATE_SELECTED_MARKET = '@MARKET/UPDATE_SELECTED_MARKET'
export const UPDATE_EVENTNAME_FOREVENTID = '@MARKET/UPDATE_EVENTNAME_FOREVENTID'
export const DELETED_MARKET = '@MARKET/DELETED_MARKET'
export const UPCOMMING_EVENTS = '@MARKET/UPCOMMING_EVENTS'
export const ONGOING_EVENTS = '@MARKET/ONGOING_EVENTS'
export const DELETED_MARKET_LIST = '@MARKET/DELETED_MARKET_LIST'
export const ADDED_MARKET = '@MARKET/ADDED_MARKET'

export const getGameManagementCommonAction = (
  pathParam: string,
  currentDomain: ICurrentDomain,
  cb?: (data: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({
        type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING,
        loading: true,
      })
      dispatch({
        type: GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING,
        loading: true,
      })

      let userType = localStorage.getItem(HEADERS.userType)

      makeGetHttpCallAsync(
        dispatch,
        userType === 'administrator'
          ? 'api/administrator/game/market'
          : `api/common/game/market`,
        pathParam,
        URLType.master,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY,
            loading: false,
            data: response.docs,
            page: {
              limit: response.limit,
              nextPage: response.nextPage,
              nextPageNumber: response.nextPageNumber,
              previousPage: response.previousPage,
              previousPageNumber: response.previousPageNumber,
              showingFrom: response.showingFrom,
              showingTo: response.showingTo,
              totalDocs: response.totalDocs,
              totalPages: response.totalPages,
            },
          })

          const { eventIds, marketIds, generateMarket } = extractIds(
            response.docs,
          )

          dispatch({
            type: GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS,
            data: marketIds,
          })

          dispatch({
            type: GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS,
            data: eventIds,
          })

          dispatch({
            type: GET_GAME_MANAGEMENT_COMMON_DATA,
            loading: false,
            data: hierarchyChangeObject(response.docs, false),
          })

          dispatch({
            type: GET_COMMON_MANAGEMENT_COMMON_MARKET_ANALYSIS_DATA,
            loading: false,
            data: hierarchyChangeObject(response.docs, true),
          })

          // dispatch({
          //   type: UPDATE_SELECTED_MARKET,
          //   loading: false,
          //   data: generateMarket.selectedMarket,
          // })

          dispatch({
            type: UPDATE_EVENTNAME_FOREVENTID,
            data: generateMarket.eventNames,
          })

          cb && cb(generateMarket.selectedMarket)
        })
        .catch(async (error: any) => {
          userType === 'administrator' &&
            dispatch({
              type: GET_GAME_MANAGEMENT_COMMON_DATA,
              loading: false,
              page: { totalPage: 1 },
            })
          dispatch({
            type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING,
            loading: false,
            page: { totalPage: 1 },
          })
          dispatch({
            type: GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING,
            loading: false,
            page: { totalPage: 1 },
          })

          cb && cb([])
        })
    } catch (error: any) {
      dispatch({
        type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING,
        loading: false,
        page: { totalPage: 1 },
      })
      dispatch({
        type: GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING,
        loading: false,
        page: { totalPage: 1 },
      })

      cb && cb([])
    }
  }
}

export const getAllGameManagementCommonAction = (
  param: string,
  cb?: (data: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({
        type: GET_ALL_GAME_MANAGEMENT_COMMON_DATA,
        loading: true,
        data: [],
      })
      makeGetHttpCallAsync(
        dispatch,
        `api/common/game/market`,
        param,
        URLType.master,
        false,
        true,
        '',
      ).then((response: any) => {
        dispatch({
          type: GET_ALL_GAME_MANAGEMENT_COMMON_DATA,
          loading: false,
          data: hierarchyChangeObject(response.docs, false),
        })
      })
    } catch (error: any) {
      dispatch({ type: GET_ALL_GAME_MANAGEMENT_COMMON_DATA, loading: false })

      cb && cb([])
    }
  }
}

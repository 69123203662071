import ConnectedApp from './ConnectedApp'
import { AppSideComponent } from './AppSideComponent'
import { SocketConnection } from './SocketConnection'

export const MainApp = () => {
  return (
    <>
      <AppSideComponent />
      <SocketConnection />
      <ConnectedApp />
    </>
  )
}

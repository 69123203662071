import React from 'react'
import { Content, IContent } from './component/Content/Content'
import { Helmet } from 'react-helmet'
import { isActualFuture9 } from '../../App'

// Landing Page
const LandingContainer: React.FC<IContent> = (props: IContent) => {
  return (
    <>
      {isActualFuture9() && props.currentDomain.type === 'b2c' && (
        <Helmet>
          <meta
            name='description'
            content='Play top live casino online games and win big! Join now for real dealers, exclusive games and exchange.'
          />
          <meta
            name='keywords'
            content='casino, exchange, live casino, virtual caisno'
          />
        </Helmet>
      )}
      <Content {...props} />
    </>
  )
}

export default LandingContainer

import {
  ADD_BETFAIR_MARKET_VALUE,
  ADD_BOOKMAKER_MARKET_VALUE,
  ADD_FASTEST_ODDS_MARKET_MARKET_VALUE,
  ADD_HIGHLIGHTS_MARKET_MARKET_VALUE,
  ADD_PREMIUM_MARKET_VALUE,
  ADD_SESSION_MARKET_VALUE,
  ADD_SPORTS_MARKET_MARKET_VALUE,
  DELETE_MARKET_VALUE,
  SAVE_ONTIME_STATUS_MARKETIDS,
  SAVE_SEARCH_MARKETIDS,
  SAVE_STATUS_MARKETIDS,
} from '../actions/marketStatusAction'

export interface IMarketStatus {
  _id: string
  name: string
  ballRunning: boolean
  delaySecond: number
  eventId: string
  marketId: string
  max: number
  min: number
  suspense: boolean
  no: number[]
  yes: number[]
  gameMarket: any
}

export interface IMarketStatusAction {
  betfairData: Map<string, IMarketStatus>
  type: string
  load: boolean
  data: Map<string, IMarketStatus>
}

export interface IMarketStatusInitial {
  sessionMarketStatus: IMarketStatus[]
  bookMakerMarketStatus: IMarketStatus[]
  sportcontentItemStatus: IMarketStatus[]
  betfairMarketStatus: IMarketStatus[]
  premiumMarketStatus: IMarketStatus[]
  premiumMarketLoad: boolean
  sessionMarketLoad: boolean
  bookMakerMarketLoad: boolean
  betfairMarketLoad: boolean
  marketIds: string
  highlightMarketIds: string
  fastestOddMarketIds: string
  sportsContentItemLoad: boolean
  onTimeMarketIds: string
  searchMarketIds: string
}

const initialState: IMarketStatusInitial = {
  sessionMarketStatus: [],
  marketIds: '',
  sportcontentItemStatus: [],
  highlightMarketIds: '',
  fastestOddMarketIds: '',
  bookMakerMarketStatus: [],
  betfairMarketStatus: [],
  premiumMarketStatus: [],
  sportsContentItemLoad: false,
  sessionMarketLoad: false,
  premiumMarketLoad: false,
  bookMakerMarketLoad: false,
  betfairMarketLoad: false,
  onTimeMarketIds: '',
  searchMarketIds: '',
}

export default function (state = initialState, action: IMarketStatusAction) {
  switch (action.type) {
    case ADD_SESSION_MARKET_VALUE:
      return {
        ...state,
        sessionMarketStatus: action.data,
        sessionMarketLoad: !state.sessionMarketLoad,
      }

    case SAVE_STATUS_MARKETIDS:
      return {
        ...state,
        marketIds: action.data,
      }

    case SAVE_SEARCH_MARKETIDS:
      return {
        ...state,
        searchMarketIds: action.data,
      }

    case SAVE_ONTIME_STATUS_MARKETIDS:
      return {
        ...state,
        onTimeMarketIds: action.data,
      }

    case ADD_HIGHLIGHTS_MARKET_MARKET_VALUE:
      return {
        ...state,
        highlightMarketIds: action.data,
      }

    case ADD_FASTEST_ODDS_MARKET_MARKET_VALUE:
      return {
        ...state,
        fastestOddMarketIds: action.data,
      }

    case ADD_SPORTS_MARKET_MARKET_VALUE:
      return {
        ...state,
        sportcontentItemStatus: action.data,
        sportsContentItemLoad: !state.sportsContentItemLoad,
      }

    case ADD_BOOKMAKER_MARKET_VALUE:
      return {
        ...state,
        bookMakerMarketStatus: action.data,
        bookMakerMarketLoad: !state.bookMakerMarketLoad,
      }

    case ADD_BETFAIR_MARKET_VALUE:
      return {
        ...state,
        betfairMarketStatus: action.betfairData,
        betfairMarketLoad: !state.betfairMarketLoad,
      }

    case DELETE_MARKET_VALUE:
      return {
        ...state,
        bookMakerMarketStatus: [],
        betfairMarketStatus: [],
        sessionMarketStatus: [],
        premiumMarketStatus: [],
        premiumMarketLoad: !state.premiumMarketLoad,
        betfairMarketLoad: !state.betfairMarketLoad,
        sessionMarketLoad: !state.sessionMarketLoad,
        bookMakerMarketLoad: !state.bookMakerMarketLoad,
      }

    case ADD_PREMIUM_MARKET_VALUE:
      return {
        ...state,
        premiumMarketStatus: action.data,
        premiumMarketLoad: !state.premiumMarketLoad,
      }

    default:
      return state
  }
}

import { ConnectedComponent, connect } from 'react-redux'
import { BetContent } from './BetContent'
import { AppState } from '../../../../redux'
import { getOpenBet } from '../../../../redux/actions/reportAction/reportsAction'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const currentDomain = state.currentDomain
  const openBet = state.reports.openBet
  const openBetLoading = state.reports.openBetLoading
  const openBetPagination = state.reports.openBetPagination
  const nonUserOpenBet = state.reports.nonUserOpenBet
  const nonUserOpenBetLoading = state.reports.nonUserOpenBetLoading
  const nonUserOpenBetPagination = state.reports.nonUserOpenBetPagination
  const selectedMarketId = state.appConfiguration.selectedMarketId
  const selectedEventId = state.appConfiguration.selectedEventId
  const demoLogin = state.demoLogin.demoLogin

  return {
    nonUserOpenBet,
    nonUserOpenBetLoading,
    nonUserOpenBetPagination,
    isDarkMode,
    demoLogin,
    selectedMarketId,
    selectedEventId,
    currentDomain,
    openBetPagination,
    openBetLoading,
    openBet,
  }
}

const ConnectedBetContent: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  { getOpenBet },
)(BetContent as any)

export default ConnectedBetContent

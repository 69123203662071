import { Dispatch } from '@reduxjs/toolkit'
import { makeGetHttpCallAsync } from '../../api'

export const MAINTENANCE_PAGE = 'APP_CONFIGURATION_MAINTENANCE_PAGE'
export const DUPLICATE_SCREEN = 'APP_CONFIGURATION_DUPLICATE_SCREEN'

export const getMaintenancePage = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: MAINTENANCE_PAGE, loading: true, data: [] })

      makeGetHttpCallAsync(
        dispatch,
        `api/common/maintenance`,
        '',
        'master',
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: MAINTENANCE_PAGE,
            payload: response.docs,
          })
        })
        .catch(async (error: any) => {
          dispatch({ type: MAINTENANCE_PAGE, payload: true })
          let err = await error
        })
    } catch (error: any) {
      dispatch({ type: MAINTENANCE_PAGE, payload: true })
    }
  }
}

export const saveMaintenancePage = (isMaintenceEnable: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
    } catch (error: any) {}
  }
}

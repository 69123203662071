import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../../type'
import { makeGetHttpCallAsync } from '../../../api'

export const GET_USER_QUICKBET = '@STAKES/GET_USER_QUICKBET'
export const UPDATE_USER_QUICKBET = '@STAKES/UPDATE_USER_QUICKBET'

export const DEFAULT_QUICKBET = {
  _id: '655e40b50373ebe10c8b7320',
  userId: '65380d9240713e23d5edf328',
  createdAt: '2023-11-22T17:56:10.421Z',
  stake: [
    {
      label: '1k',
      value: 1000,
      _id: '65679f403adcfd082bb45e42',
    },
    {
      label: '2k',
      value: 2000,
      _id: '65679f413adcfd082bb45e43',
    },
    {
      label: '3k',
      value: 3000,
      _id: '65679f413adcfd082bb45e44',
    },
  ],
  updatedAt: '2023-11-22T17:56:38.124Z',
}

export const getQuickBet = (currentDomain: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: GET_USER_QUICKBET, loading: true })

      makeGetHttpCallAsync(
        dispatch,
        `api/user/quickBet`,
        '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: GET_USER_QUICKBET,
            isLoading: false,
            data: response.docs,
          })
        })
        .catch(async (error: any) => {
          dispatch({
            type: GET_USER_QUICKBET,
            isLoading: false,
            data: DEFAULT_QUICKBET,
          })
        })
    } catch (error: any) {
      dispatch({ type: GET_USER_QUICKBET, loading: false })
    }
  }
}

// withConnectionCheck.tsx
import React, { useState, useEffect, lazy } from 'react'
const CommingSoonComponent = lazy(() => import('./component/404/CommingSoon'))

const ConnectionStatus: React.FC = () => <CommingSoonComponent />

const withConnectionCheck = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const ComponentWithConnectionCheck: React.FC<P> = (props) => {
    const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)

    useEffect(() => {
      const handleOnline = () => setIsOnline(true)
      const handleOffline = () => setIsOnline(false)

      window.addEventListener('online', handleOnline)
      window.addEventListener('offline', handleOffline)

      return () => {
        window.removeEventListener('online', handleOnline)
        window.removeEventListener('offline', handleOffline)
      }
    }, [])

    if (!isOnline) {
      return <ConnectionStatus />
    }

    return <WrappedComponent {...props} />
  }

  return ComponentWithConnectionCheck
}

export default withConnectionCheck

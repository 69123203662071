export const USER_TYPE_ADMINISTRATOR = [
  { key: 'administrator', value: 'administrator' },
  { key: 'whiteLabel', value: 'whiteLabel' },
]

export const USER_TYPE_WHITE_LABEL = [
  { key: 'admin', value: 'admin' },
  { key: 'superMaster', value: 'superMaster' },
  { key: 'master', value: 'master' },
  { key: 'agent', value: 'agent' },
  { key: 'user', value: 'user' },
]

export const USER_TYPES_ALL_B2C = [
  { key: 'administrator', value: 'administrator' },
  { key: 'whiteLabel', value: 'whiteLabel' },
  { key: 'defaultBranch', value: 'defaultBranch' },
  { key: 'branch', value: 'branch' },
  { key: 'subBranch', value: 'subBranch' },
  { key: 'user', value: 'user' },
]

export const USER_TYPE_ADMIN = [
  { key: 'admin', value: 'admin' },
  { key: 'superMaster', value: 'superMaster' },
  { key: 'master', value: 'master' },
  { key: 'agent', value: 'agent' },
  { key: 'user', value: 'user' },
]

export const USER_TYPE_SUPER_MASTER = [
  { key: 'superMaster', value: 'superMaster' },
  { key: 'master', value: 'master' },
  { key: 'agent', value: 'agent' },
  { key: 'user', value: 'user' },
]

export const USER_TYPE_MASTER = [
  { key: 'master', value: 'master' },
  { key: 'agent', value: 'agent' },
  { key: 'user', value: 'user' },
]

export const USER_TYPE_AGENT = [
  { key: 'agent', value: 'agent' },
  { key: 'user', value: 'user' },
]

export const USER_TYPES_ALL = [
  { key: 'administrator', value: 'administrator' },
  { key: 'whiteLabel', value: 'whiteLabel' },
  { key: 'admin', value: 'admin' },
  { key: 'superMaster', value: 'superMaster' },
  { key: 'master', value: 'master' },
  { key: 'agent', value: 'agent' },
  { key: 'branch', value: 'branch' },
  { key: 'subBranch', value: 'subBranch' },
  { key: 'defaultBranch', value: 'defaultBranch' },
]

export const USER_TYPES_ALL_OBJ = {
  administrator: 'administrator',
  whiteLabel: 'whiteLabel',
  admin: 'admin',
  superMaster: 'superMaster',
  master: 'master',
  agent: 'agent',
  user: 'user',
}

export const BET_TYPE_Sport = [
  { key: 'exchange', value: 'All Sports' },
  { key: 'casino', value: 'All Casino' },
  { key: 'Live Casino', value: 'Live Casino' },
  { key: 'Virtual Games', value: 'Virtual Games' },
  { key: 'Slot Games', value: 'Slot Games' },
  { key: 'Crash Games', value: 'Crash games' },
  { key: 'Lottery', value: 'Lottery' },
]

export const Exchange_Data = [
  { key: 'all', value: 'All' },
  { key: '1', value: 'Soccer ' },
  { key: '2', value: 'Tennis' },
  { key: '3', value: 'Golf ' },
  { key: '4', value: 'Cricket' },
  { key: '5', value: 'Rugby Union' },
  { key: '1477', value: 'Rugby League' },
  { key: '6', value: 'Boxing' },
  { key: '7', value: 'Horse Racing' },
  { key: '8', value: 'Motor Sport' },
  { key: '27454571', value: 'Esports' },
  { key: '10', value: 'Special Bets' },
  { key: '998917', value: 'Volleyball' },
  { key: '11', value: 'Cycling' },
  { key: '2152880', value: 'Gaelic Games' },
  { key: '6422', value: 'Snooker' },
  { key: '7511', value: 'Baseball' },
  { key: '6423', value: 'American Football' },
  { key: '451485', value: 'Winter Sports' },
  { key: '7522', value: 'Basketball' },
  { key: '7524', value: 'Ice Hockey' },
  { key: '61420', value: 'Australian Rules' },
  { key: '468328', value: 'Handball' },
  { key: '3503', value: 'Darts' },
  { key: '26420387', value: 'Mixed Martial Arts' },
  { key: '4339', value: 'Greyhound Racing' },
  { key: '2378961', value: 'Politics' },
]

export const DEFAULT_EXCHANGE_DATA = new Map<string, string>([
  ['1', 'soccer'],
  ['7', 'horseracing'],
  ['2', 'tennis'],
  ['4339', 'greyhoundracing'],
  ['7522', 'basketball'],
  ['2378961', 'politics'],
])

export const exchangeDataMap = new Map([
  ['all', 'All'],
  ['1', 'Soccer '],
  ['2', 'Tennis'],
  ['3', 'Golf '],
  ['4', 'Cricket'],
  ['5', 'Rugby Union'],
  ['1477', 'Rugby League'],
  ['6', 'Boxing'],
  ['7', 'Horse Racing'],
  ['8', 'Motor Sport'],
  ['27454571', 'Esports'],
  ['10', 'Special Bets'],
  ['998917', 'Volleyball'],
  ['11', 'Cycling'],
  ['2152880', 'Gaelic Games'],
  ['6422', 'Snooker'],
  ['7511', 'Baseball'],
  ['6423', 'American Football'],
  ['451485', 'Winter Sports'],
  ['7522', 'Basketball'],
  ['7524', 'Ice Hockey'],
  ['61420', 'Australian Rules'],
  ['468328', 'Handball'],
  ['3503', 'Darts'],
  ['26420387', 'Mixed Martial Arts'],
  ['4339', 'Greyhound Racing'],
  ['2378961', 'Politics'],
])

export const TRUE_FALSE = [
  { key: 'true', value: 'true' },
  { key: 'false', value: 'false' },
]

export const TYPE = [
  { key: 'all', value: 'All' },
  { key: 'debit', value: 'Debit' },
  { key: 'credit', value: 'Credit' },
]

export const TYPE2 = [
  { key: 'all', value: 'All' },
  { key: 'pending', value: 'Pending' },
  { key: 'processing', value: 'Processing' },
  { key: 'approved', value: 'Approved' },
  { key: 'rejected', value: 'Rejected' },
]

export const TYPE3 = [
  { key: 'all', value: 'All' },
  { key: 'not-deposited', value: 'Not Deposited' },
  { key: 'deposited', value: 'Deposited' },
]

export const TYPE4 = [
  { key: 'pending', value: 'Pending' },
  { key: 'processing', value: 'Processing' },
  { key: 'approved', value: 'Approved' },
  { key: 'rejected', value: 'Rejected' },
]

export const TYPE10 = [
  { key: 'pending', value: 'Pending' },
  { key: 'processing', value: 'Processing' },
]

export const TYPE5 = [
  { key: 'all', value: 'All' },
  { key: 'pending', value: 'Pending' },
  { key: 'approved', value: 'Approved' },
  { key: 'rejected', value: 'Rejected' },
]

export const TYPE6 = [
  { key: 'all', value: 'All' },
  { key: 'dw', value: 'Deposit/WithDraw' },
  { key: 'bet', value: 'Betting P/L' },
]

export const TYPE7 = [
  { key: 'all', value: 'Select' },
  { key: 'lottery', value: 'Lottery' },
  { key: 'book', value: 'Sports Book' },
  { key: 'exchange', value: 'Exchange' },
  { key: 'crash-games', value: 'Crash Games' },
  { key: 'virtual-games', value: 'Virtual Games' },
  { key: 'live-casino', value: 'Live Casino' },
  { key: 'slot-games', value: 'Slot Games' },
]

export const TYPE8 = [
  { key: 'selectDeposit', value: 'Select Deposit Count' },
  { key: '1', value: '1' },
  { key: '2', value: '2' },
  { key: '3', value: '3' },
  { key: '4', value: '4' },
  { key: '5', value: '5' },
  { key: 'more', value: 'More then 5' },
]

export const BRANCH_AGENT = [
  { key: 'subBranch', value: 'subBranch' },
  { key: 'user', value: 'user' },
]

export const SUBBRANCH_BRANCH_AGENT = [{ key: 'user', value: 'user' }]

export const TYPE9 = [
  { key: 'select', value: 'Select Source' },
  { key: 'signup', value: 'SignUp' },
  { key: 'manual', value: 'Manual' },
]

export const USER_TYPE_WITH_DEFAULT_BRANCH_B2C_WHITE_LABEL = [
  { key: 'branch', value: 'branch' },
  { key: 'defaultBranch', value: 'defaultBranch' },
]

export const USER_TYPE_SUB_BRANCH_B2C = [{ key: 'user', value: 'user' }]

export const USER_TYPE_BRANCH_B2C = [
  { key: 'subBranch', value: 'subBranch' },
  { key: 'user', value: 'user' },
]

export const account = [
  { key: 'active', value: 'Active' },
  { key: 'in-active', value: 'In-Active' },
]

export const AccountName = [
  { key: '1', value: 'Manish Rathor: 0453442211134' },
  { key: '2', value: 'Oliver: 32376343889902' },
  { key: '3', value: 'John Smith: 32376343889902' },
  { key: '4', value: 'Ammar Zaidi: 0453442211134' },
]

export const USER_TYPE_WHITE_LABEL_B2C = [
  { key: 'branch', value: 'branch' },
  { key: 'defaultBranch', value: 'Default Branch' },
]

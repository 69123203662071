import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import { HEADERS } from '../../api'

export const LANGUAGE = '@LANGUAGE/LANGUAGE'

export const updateLanguage = (lang: string) => {
  return (dispatch: Dispatch, getState: GetReduxStateFn) => {
    localStorage.setItem(HEADERS.language, lang)
    dispatch({
      type: LANGUAGE,
      lang: lang,
    })
  }
}

import React, { ReactNode } from 'react'
import SwiperCore from 'swiper'
import { FreeMode } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import CommonSwiper from '../../../../component/commonSwiper'

SwiperCore.use([FreeMode])

interface SportsBookSlidesProps {
  children: ReactNode[]
}

export const SportsBookSlider: React.FC<SportsBookSlidesProps> = ({
  children,
}) => {
  const swiperModules = [FreeMode]
  const swiperOptions: SwiperOptions = {
    freeMode: true,
    wrapperClass: 'items-center',
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 1,
      },
      400: {
        slidesPerView: 'auto',
        spaceBetween: 1,
      },
    },
    navigation: {
      prevEl: `.navigate_prev_14`,
      nextEl: `.navigate_next_14`,
    },
  }
  return (
    <>
      <CommonSwiper
        id='swiper-slide-center'
        swiperOptions={swiperOptions}
        modules={swiperModules}
        className='mySwipwer swiper'
        children={children}
        classNameSlider='!w-max'
        arrow={false}
      />
    </>
  )
}

export interface IAdminDashBoard {
  loading: boolean
  data: IDashBoard
}

export interface IDashBoard {
  approvedDeposit: string
  totalDepositAmount: string
  approvedWithdraw: string
  pendingDeposit: string
  pendingWithdraw: string
  processingDeposit: string
  processingWithdraw: string
  rejectedDeposit: string
  rejectedWithdraw: string
  totalDeposit: string
  totalFirstDeposit: string
  totalWithdrawAmount: string
  rejectedWithdrawAmount: string
  totalWithdraw: string
  pendingDepositAmount: string
  approvedDepositAmount: string
  approvedWithdrawAmount: string
  pendingWithdrawAmount: string
  rejectedDepositAmount: string
  processingWithdrawAmount: string
  processingDepositAmount: string
  uniqueClient: string
}

const initialState: IAdminDashBoard = {
  loading: false,
  data: {
    approvedDeposit: '0',
    approvedWithdraw: '0',
    pendingDeposit: '0',
    pendingWithdraw: '0',
    processingDeposit: '0',
    processingWithdraw: '0',
    approvedDepositAmount: '0',
    pendingDepositAmount: '0',
    pendingWithdrawAmount: '0',
    processingDepositAmount: '0',
    processingWithdrawAmount: '0',
    rejectedDepositAmount: '0',
    rejectedWithdrawAmount: '0',
    totalDepositAmount: '0',
    totalWithdrawAmount: '0',
    approvedWithdrawAmount: '0',
    rejectedDeposit: '0',
    rejectedWithdraw: '0',
    totalDeposit: '0',
    totalFirstDeposit: '0',
    totalWithdraw: '0',
    uniqueClient: '0',
  },
}

export interface ISetCreateUser {
  type: string
  loading: boolean
  payload: any
  page: number
}

export const GET_ADMIN_DASHBOARD = '@DASHBOARD/GET_ADMIN_DASHBOARD'
export const GET_ADMIN_DASHBOARD_LOADING =
  '@DASHBOARD/GET_ADMIN_DASHBOARD_LOADING'

export default function (state = initialState, action: ISetCreateUser) {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD: {
      return {
        ...state,
        data: action.payload,
        page: action.page,
        loading: action.loading,
      }
    }

    case GET_ADMIN_DASHBOARD_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }

    default:
      return state
  }
}

import { BET_TYPE } from '../../../../types/enums/betType'
import { get2Decimal } from '../../../../utils'

export const OpenBetSessionHeader = () => {
  return (
    <div className='flex w-full p-1 text-black dark:text-white bold'>
      <div className='w-1/4 text-center'>{_getLocalString('LOC_RUNS')}</div>
      <div className='w-1/4 text-center'>{_getLocalString('LOC_ODDS')}</div>
      <div className='w-1/4 text-center'>{_getLocalString('LOC_STAKE')}</div>
      <div className='w-1/4 text-center'>{_getLocalString('LOC_P_L')}</div>
    </div>
  )
}

export const OpenBetNonSessionHeader = () => {
  return (
    <div className='flex w-full p-1 text-black dark:text-white bold'>
      <div className='w-2/5 text-center'>Team</div>
      <div className='w-1/5 text-center'>{_getLocalString('LOC_ODDS')}</div>
      <div className='w-1/5 text-center'>{_getLocalString('LOC_STAKE')}</div>
      <div className='w-1/5 text-center'>{_getLocalString('LOC_P_L')}</div>
    </div>
  )
}

interface IOpenBetContent {
  marketContent: any
  isShowBetInfo: boolean
}

export const OpenBetSessionContent = (props: IOpenBetContent) => {
  return (
    <div
      className={`p-1 bg-[${
        props.marketContent.odds.type === BET_TYPE.BACK ? '#A5D9FE' : '#F8D0CE'
      }]`}
    >
      <div className={`flex my-1 text-black`}>
        <div className='w-1/4 text-center whitespace-nowrap overflow-ellipsis'>
          {props.marketContent.odds.run}
        </div>
        <div className='w-1/4 text-center'>
          {get2Decimal(props.marketContent.odds.odd)}
        </div>
        <div className='w-1/4 text-center'>
          {get2Decimal(props.marketContent.stake)}
        </div>
        <div className='w-1/4 text-center'>
          {props.marketContent.odds.type === BET_TYPE.BACK.valueOf()
            ? props.marketContent.profit
            : props.marketContent.liability}
        </div>
      </div>
      <div>
        <div
          className='text-black'
          style={{
            fontSize: '9px',
            fontFamily: 'Lato, Avenir, Verdana, Nunito',
          }}
        >
          {props.isShowBetInfo &&
            `Ref Id: ${props.marketContent.market._id} | Placed: ${props.marketContent.updatedAt}`}
        </div>
      </div>
    </div>
  )
}

export const OpenBetNonSessionContent = (props: IOpenBetContent) => {
  return (
    <div
      className={`p-1 bg-[${
        props.marketContent.odds.type === BET_TYPE.BACK ? '#A5D9FE' : '#F8D0CE'
      }]`}
    >
      <div className={`flex my-1 text-black`}>
        <div
          style={{
            whiteSpace: 'normal',
          }}
          className='w-2/5 text-center bold whitespace-nowrap overflow-ellipsis'
        >
          {props.marketContent.odds.teamName}
        </div>
        <div className='w-1/5 text-center'>
          {get2Decimal(props.marketContent.odds.odd)}
        </div>
        <div className='w-1/5 text-center'>
          {get2Decimal(props.marketContent.stake)}
        </div>
        <div className='w-1/5 text-center'>
          {props.marketContent.odds.type === BET_TYPE.BACK.valueOf()
            ? props.marketContent.profit
            : props.marketContent.liability}
        </div>
      </div>
      <div>
        <div
          className='text-black'
          style={{
            fontSize: '10px',
            fontFamily: 'Lato, Avenir, Verdana, Nunito',
          }}
        >
          {props.isShowBetInfo &&
            `Ref Id: ${props.marketContent.market._id} | Placed: ${props.marketContent.updatedAt}`}
        </div>
      </div>
    </div>
  )
}

import { FC, useState } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Collapse } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { getAssetUrl } from '../../../../utils'
import { Router, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux'
import { NavBarTileSports } from './NavBarTileSports'

export interface IArrayObject {
  title?: string
  name?: string
  date?: string
  icon?: string
  link?: string
}
export interface INavBarTile {
  name: string
  icon?: string
  isSelected?: boolean
  count?: number
  isLive?: boolean
  link?: string
  isDarkMode?: boolean
  showSportTiles?: boolean
  isColored?: boolean
  hideDropDownIcon?: boolean
  children?: Array<IArrayObject>
  customRender?: Function
  isOpen?: string
  setIsOpen?: Function
  onClickHandlerClose?: () => void
  isLiveMarket?: boolean
}

export const NavBarTileMobile: FC<INavBarTile> = (props: INavBarTile) => {
  const router = useLocation()
  const navigate = useNavigate()
  const [openNav, setOpenNav] = useState<string>('')
  const { gamesCommonData } = useSelector(
    (state: AppState) => state.gameManagementCommon,
  )
  const toggleModal = () => {
    if (props?.isOpen === props.name) {
      props?.setIsOpen && props?.setIsOpen('')
    } else {
      props?.setIsOpen && props?.setIsOpen(props.name)
    }
  }

  return (
    <div
      className={`overflow-hidden dark:shadow-none dark:!bg-[#2f040f] !bg-[white]
    ${props.children && props.children.length ? ' cursor-pointer' : ''}`}
    >
      <div
        onClick={() => {
          if (props.link) {
            navigate(props.link || '')
            !props.isLiveMarket &&
              props.onClickHandlerClose &&
              props.onClickHandlerClose()
          } else {
            null
          }
        }}
        style={{
          borderImage: props.isDarkMode ? '' : '',
        }}
        className={`${
          props.link === router.pathname
            ? '!bg-gradient-to-r shadow from-[color:var(--button-first-bg),10%] to-[color:var(--button-second-bg)]  !text-white'
            : ``
        } grid grid-flow-col grid-cols-[22px_1fr_40px] items-center gap-5 md:gap-1 lg:gap-7 p-5  dark:text-white text-black text-sm`}
      >
        <div className='max-w-max '>
          <img
            src={getAssetUrl(`header/${props.icon}.webp`)}
            alt='Header Icon'
          />
        </div>
        <div className='font-primary text-lg font-normal leading-[14px] tracking-normal text-left whitespace-nowrap md:text-sm lg:text-lg'>
          {props.name}
        </div>
        {!!props.count && (
          <div
            className={`${
              !props.isSelected
                ? 'dark:text-[#705E53] text-[#1F1F1F] '
                : ' text-[#C34C04]'
            }  bold max-w-max justify-self-end`}
          >
            {props.count}
          </div>
        )}
        {!!props.isLive && (
          <div className='text-xs justify-self-end gap-[inherit] px-1 uppercase items-center flex justify-center bg-[#FB4A59] text-white self-center'>
            <FiberManualRecordIcon className='!text-[8px]' /> <span>Live</span>
          </div>
        )}

        {!props.isLive && props.children && !props.hideDropDownIcon ? (
          props?.isOpen === props.name ? (
            <ExpandLess fontSize='large' onClick={toggleModal} />
          ) : (
            <ExpandMore fontSize='large' onClick={toggleModal} />
          )
        ) : null}
      </div>

      <Collapse in={props?.isOpen === props.name} timeout='auto' unmountOnExit>
        {props.children && props.children.length
          ? props.children?.map((item, i) => {
              return props.customRender ? (
                props.customRender(item, i, props.isSelected)
              ) : (
                <div
                  key={i}
                  onClick={() => {
                    item.link ? navigate(item.link || '') : null
                    props.onClickHandlerClose && props.onClickHandlerClose()
                  }}
                  style={{
                    borderImage: props.isDarkMode ? '' : '',
                  }}
                  className={`${
                    item.link === router.pathname
                      ? '!bg-gradient-to-r shadow from-[color:var(--button-first-bg),10%] to-[color:var(--button-second-bg)] !text-white'
                      : ``
                  } border-[0.5px] grid grid-flow-col grid-cols-[22px_1fr_40px] items-center gap-2 p-3  text-black dark:text-white text-sm`}
                >
                  <div className='w-[6px] h-auto'>
                    <img alt='Dot Icon' src={getAssetUrl('header/dot.webp')} />
                  </div>
                  <div className='font-primary text-sm font-normal leading-[19px] whitespace-nowrap tracking-normal text-left'>
                    {item.title || item.name}
                  </div>
                </div>
              )
            })
          : ''}
        {gamesCommonData &&
          props.showSportTiles &&
          gamesCommonData.map((sports: any, index: number) => {
            return (
              <NavBarTileSports
                index={index}
                navigate={navigate}
                setOpenNav={setOpenNav}
                openNav={openNav}
                isDarkMode={!!props.isDarkMode}
                sports={sports}
                isPadding={true}
                showSportsMobile={true}
                onClickHandlerClose={props.onClickHandlerClose}
              />
            )
          })}
      </Collapse>
    </div>
  )
}

import { LANGUAGE } from '../actions'

const initialState: ILanguage = {
  lang: 'en-us',
}

export type ILanguage = {
  lang: string
}

export interface ILanguageUpdate {
  type: string
  lang: string
}

export default function (state = initialState, action: ILanguageUpdate) {
  switch (action.type) {
    case LANGUAGE: {
      return {
        ...state,
        lang: action.lang,
      }
    }
    default:
      return state
  }
}

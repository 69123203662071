import { GET_USER_STAKES } from '../../../../redux/actions/userAction'
import { useDispatch } from 'react-redux'
import { makePutHttpCallAsync } from '../../../../api'
import { Check } from '@mui/icons-material'
import { Checkbox, FormControlLabel } from '@mui/material'
import { IOpenBet } from '../NewBetSlip'
import { MatchBets } from './MatchBets'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'

interface IStakes {
  openBet: IOpenBet[]
  editStakes: boolean
  demoLogin: boolean
  quickStakes?: boolean
  quickBetAmounts: any
  stakes: any
  quickBet: any
  currentDomain: any
  availableStakesAmounts: any
  setEditStakes?: Function
  setAvailableStakeAmount: Function
  isShowBetForThisEvent: boolean
  setIsShowBetForThisEvent: (isShowBetForThisEvent: boolean) => void
}

export const Stakes = (props: IStakes) => {
  const [isShowBetInfo, setIsShowBetInfo] = useState(false)

  const onChangeCheckBox = () => {
    props.setIsShowBetForThisEvent(!props.isShowBetForThisEvent)
  }

  return (
    <div className='flex flex-col gap-2 lg:!px-3 md:!px-0 !px-3'>
      <div
        style={{ placeContent: 'space-around' }}
        className='flex items-start justify-start text-black dark:text-white xl:items-center xl:gap-8 md:gap-0 gap-7 xl:flex-row md:flex-row '
      >
        <FormControlLabel
          className='!m-0 mr-4 !text-[17px]'
          control={
            <Checkbox
              id='remeberMe'
              value={isShowBetInfo}
              onChange={() => setIsShowBetInfo(!isShowBetInfo)}
              icon={
                <div className='w-5 h-5 rounded-[3px] border dark:border-white border-black shadow-inner shadow-slate-400 dark:shadow-none dark:bg-white'></div>
              }
              checkedIcon={
                <div className='w-5 h-5  justify-center items-center flex rounded-[3px] shadow-inner shadow-slate-400 dark:shadow-none dark:bg-white'>
                  <Check className='!text-lg text-[#f42020c9] ' />
                </div>
              }
              className='!pl-0'
            />
          }
          label={_getLocalString('LOC_BET_INFO')}
        />
        <FormControlLabel
          className='!m-0 !text-[12px]  '
          control={
            <Checkbox
              id='remeberMe'
              value={props.isShowBetForThisEvent}
              onChange={onChangeCheckBox}
              icon={
                <div className='border dark:border-white border-black w-5 h-5 rounded-[3px] shadow-inner shadow-slate-400 dark:shadow-none dark:bg-white'></div>
              }
              checkedIcon={
                <div className='w-5 h-5  justify-center items-center flex rounded-[3px] shadow-inner shadow-slate-400 dark:shadow-none dark:bg-white'>
                  <Check className='!text-lg text-[#f42020c9] ' />
                </div>
              }
              className='!pl-0'
            />
          }
          label={_getLocalString('LOC_BET_FOR_THIS_EVENTS')}
        />
      </div>
      <MatchBets
        isShowBetInfo={isShowBetInfo}
        isShowBetForThisEvent={props.isShowBetForThisEvent}
        openBet={props.openBet}
      />
    </div>
  )
}

export const EditStakes = (props: IStakes) => {
  const dispatch = useDispatch()

  const saveStake = () => {
    if (props.demoLogin) {
      return toast.error(_getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'))
    }

    let tmpStakes = props.availableStakesAmounts
    tmpStakes.forEach(function (v: any) {
      delete v._id
    })

    makePutHttpCallAsync(
      dispatch,
      `api/user/stake`,
      props.currentDomain.type,
      JSON.stringify({ stake: tmpStakes }),
      false,
      true,
      '',
      true,
      'Updated Sucessfully',
    )
      .then((response: any) => {
        dispatch({
          type: GET_USER_STAKES,
          isLoading: false,
          data: response.docs,
        })
      })
      .catch(async (error: any) => {
        dispatch({ type: GET_USER_STAKES, loading: false })
      })
  }

  return (
    <>
      <div className=''>
        <div className='text-black dark:text-white bg-[--clubLoyalty-darkBackground] grid grid-cols-2 px-4 py-3 font-dm_sans font-bold'>
          <span>Pricing Label</span>
          <span>Pricing Amount</span>
        </div>
        <div className='grid grid-cols-2 '>
          {props.availableStakesAmounts.map((amount: any, index: number) => (
            <Fragment key={index}>
              <div className='bg-[--theme-color] px-[17px] py-[7px] mt-[5px] mx-[5px] text-[13px] font-dm_sans font-bold h-9'>
                <input
                  className='bg-transparent focus:outline-none w-full dark:text-[#fff] text-black font-bold text-[13px] font-dm_sans'
                  value={amount.label}
                  onChange={(e) => {
                    let newArr = [...props.availableStakesAmounts]
                    newArr[index].label = e.target.value
                    props.setAvailableStakeAmount(newArr)
                  }}
                />
              </div>
              <div className='bg-[--theme-color] px-[17px] py-[7px] mt-[5px] mr-[5px] text-[13px] font-dm_sans font-bold h-9'>
                <input
                  className='bg-transparent focus:outline-none w-full dark:text-[#fff] text-black font-bold text-[13px] font-dm_sans'
                  value={amount.value}
                  onChange={(e) => {
                    let newArr = [...props.availableStakesAmounts]
                    newArr[index].value = e.target.value
                    props.setAvailableStakeAmount(newArr)
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
        <div className='mx-[5px] mt-4'>
          <div
            className='bg-gradient-to-r from-[--button-color-1] to-[--button-color-2]  items-center cursor-pointer h-10 px-[5px] justify-center font-bold text-[15px] text-white w-full flex  rounded-[4px]'
            onClick={() => {
              props.setEditStakes && props.setEditStakes(false)
              saveStake()
            }}
          >
            Submit
          </div>
        </div>
      </div>
    </>
  )
}

import { FC } from 'react'
import { Content, IContent } from './content/Content'

const GameContainer: FC<IContent> = (props: IContent) => {
  return (
    <>
      <Content {...props} />
    </>
  )
}

export default GameContainer

import { FC } from 'react'

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

export interface IInPlayHeader {
  title: string
  isDarkMode?: boolean
  headerRemove?: boolean
  icon?: string
}

export interface IInPlayContentHeaderProps {
  name: string
  icon?: string
  isDarkMode?: boolean
}

export const InPlayHeader: FC<IInPlayHeader> = (props: IInPlayHeader) => {
  return (
    <div className=''>
      {!props.headerRemove && (
        <div
          className={`flex gap-3 rounded-t-2xl font-bold text-white  p-5 bg-[var(--inPlay-gradientTo)]`}
        >
          <div className='bg-gradient-to-r flex items-center justify-center  w-6 h-6 rounded-full from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)]'>
            <KeyboardDoubleArrowRightIcon className='!text-lg' />
          </div>
          <div className='text-lg font-bold leading-6 uppercase font-primary'>
            {props.title}
          </div>
        </div>
      )}
    </div>
  )
}

export const InPlayHeaderContent: FC<IInPlayContentHeaderProps> = (
  props: IInPlayContentHeaderProps,
) => {
  return (
    <div className=''>
      <div
        className={` md:grid hidden grid-cols-7   items-center dark:text-white text-black  dark:bg-[var(--inPlay-match-background)] dark:border-r-0 border-r dark:border-t-0  border-t border-[#E1E1E1] bg-white shadow-[0px_4px_44px_0px_#0000001a]`}
      >
        <div className='text-white bg-gradient-to-r col-span-2 from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] py-4 px-5 flex items-center font-bold text-lg capitalize'>
          <img alt='Icon Logo' src={props.icon} className='mr-5 w-7 h-7' />
          {props.name}
        </div>
        <div className='col-span-2 '></div>
        <div className='pr-3 text-xl font-normal leading-5 tracking-normal text-center font-primary'>
          1
        </div>
        <div className='pr-3 text-xl font-normal leading-5 tracking-normal text-center font-primary'>
          X
        </div>
        <div className='pr-3 text-xl font-normal leading-5 tracking-normal text-center font-primary'>
          2
        </div>
      </div>
      <div
        className={` grid md:hidden xs:grid-cols-3  items-center dark:text-white text-black  dark:bg-[var(--inPlay-match-background)] bg-white `}
      >
        <div className='gap-4 text-white bg-gradient-to-r md:col-span-2 from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] py-2 px-3 text-[15px] flex capitalize  items-center font-bold'>
          <img src={props.icon} alt='Icon Logo' className=' w-7 h-7' />
          {props.name}
        </div>
      </div>
    </div>
  )
}

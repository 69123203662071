import { FC, SetStateAction, useEffect, useState } from 'react'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import {
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { DropDownGlobal } from '../../../../landing/component/dropdown/DropDown'
import { ICurrentDomain } from '../../../../../types/interfaces/currentDomain'
import { IDemo } from '../../../../../redux/reducers/demoLoginReducer'
import { FaSort } from 'react-icons/fa'
import React from 'react'
import InternalInput from '../../../../../component/inputBoxes/InternalInput'
import { emptyFunction, get2Decimal } from '../../../../../utils'
import { NavigationIconPagination } from '../../../../../component/utility/NavigationIconPagination'
import { DEFAULT_LIMIT_FOR_REPORT_USER } from '../../../../../api'
import { CopyTextIcon } from '../../../../../component/Copy'
import { handleKeyPressForkeyBoardShortcut } from '../../../../../utils/keyBoardShortcut/onEnterHandle'

interface Column {
  id:
    | 'srNo'
    | 'flag'
    | 'browserDetails'
    | 'userName'
    | 'nation'
    | 'betType'
    | 'amount'
    | 'userRate'
    | 'placedate'
    | 'matchdate'
    | 'ip'
    | 'browserdetails'
  label: string
  headerName?: string
  field?: string
  minWidth?: number
  flex?: number
  align?: 'center'
  bgColor?: string
  sortable?: boolean
  disableColumnMenu?: boolean
  width?: number
  format?: (value: number) => string
  render?: (value: number | string) => JSX.Element
  renderCell?: any
  icon?: JSX.Element
}
export interface ILogin {
  setIsOpen?: Function
  currentDomain: ICurrentDomain
  isMarket?: boolean
  eventId?: any
  marketId?: any
  name: string
  openBet: any
  openBetPagination: any
  demoLogin: IDemo
  openBetLoading: boolean
  getOpenBet: (
    currentDomain: ICurrentDomain,
    payload: any,
    isPrint: boolean,
    cb: any,
    isNonUser: boolean,
  ) => void
}

export const EventRecords: FC<ILogin> = (props: ILogin) => {
  const getDetail = () => {
    let amount = 0
    let totalSize = 0
    props.openBet.forEach((element: any) => {
      amount += element.stake
      totalSize++
    })

    return {
      amount: amount,
      totalSize: totalSize,
    }
  }

  const getTypeName = () => {
    switch (tabValue) {
      case 0:
        return [
          {
            key: 'All',
            value: 'All',
          },
          {
            key: 'Match Odds',
            value: 'Match Odds',
          },
          { key: 'To Win The Toss', value: 'To Win The Toss' },
          { key: 'Tied Match', value: 'Tied Match' },
          {
            key: 'BOOKMAKER',
            value: 'BookMaker',
          },
          { key: 'MINI BOOKMAKER', value: 'Mini BookMaker' },
          {
            key: 'Sessions',
            value: 'Sessions',
          },
          { key: 'W/P Market', value: 'W/P Market' },
          { key: 'Xtra Market', value: 'To Win The Toss' },
        ]
      case 1:
        return [
          {
            key: 'MATCH ODDS',
            value: 'Match Odds',
          },
          { key: 'To Win The Toss', value: 'To Win The Toss' },
          { key: 'Tied Match', value: 'Tied Match' },
        ]

      case 2:
        return [
          {
            key: 'BOOKMAKER',
            value: 'BookMaker',
          },
          { key: 'MINI BOOKMAKER', value: 'Mini BookMaker' },
          { key: 'To Win The Toss', value: 'To Win The Toss' },
        ]

      case 3:
        return [
          {
            key: 'All',
            value: 'All',
          },
        ]

      default:
        return []
    }
  }

  const [search, setSearch] = useState<string>()
  const [ipAddress, setIpAddress] = useState<string>()
  const [startAmount, setStartAmount] = useState<number>(0)
  const [endAmount, setEndAmount] = useState<number>(0)
  const [type, setType] = useState('')
  const [detail, setDetail] = useState(getDetail())
  const [tabValue, setTabValue] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFilter, setSelectedFilter] = useState('all')
  const [matchtype, setMatchType] = useState<number>(0)

  useEffect(() => {
    get(
      tabValue,
      selectedFilter,
      startAmount,
      endAmount,
      ipAddress || '',
      search || '',
    )
  }, [props.eventId, selectedFilter, props.marketId, tabValue, matchtype])

  const reset = () => {
    setSearch('')
    setStartAmount(0)
    setEndAmount(0)
    setSelectedFilter('ALL')
    setIpAddress('')
    get(tabValue, 'ALL', 0, 0, '', '')
  }

  const handlePageChange = (newPage: React.SetStateAction<number>) => {
    if (
      typeof newPage === 'number' &&
      newPage >= 1 &&
      newPage <= props.openBetPagination!.totalPages
    ) {
      setCurrentPage(newPage)
      get(tabValue, type, startAmount, endAmount, ipAddress || '', search || '')
    }
  }

  const get = (
    matchTypeLocal: number,
    selectedFilterLocal: string,
    startAmountLocal: number,
    endAmountLocal: number,
    ipAddressLocal: string,
    searchLocal: string,
  ) => {
    const formData = new URLSearchParams()

    if (matchTypeLocal !== 0) {
      // TODO: We need to add Market type, is manual and external type to open bet filter
      if (matchTypeLocal === 1) {
        formData.append('marketType', 'betfair')
        formData.append('isManual', 'false')
        type && formData.append('marketName', type)
      } else if (matchTypeLocal === 2) {
        formData.append('marketType', 'bookmaker')
        formData.append('isManual', 'false')
        type && formData.append('marketName', type)
      } else if (matchTypeLocal === 3) {
        formData.append('marketType', 'session')
        formData.append('isManual', 'false')
        formData.append('externalType', 'bhawani')
        type && formData.append('marketName', type)
      }
    }

    formData.append('deleted', matchtype === 0 ? 'false' : 'true')

    if (searchLocal) {
      formData.append('search', searchLocal)
    }
    if (startAmountLocal !== 0) {
      formData.append('startAmount', startAmountLocal.toString())
    }
    if (props.isMarket) {
      formData.append('marketId', props.marketId)
    }
    if (endAmountLocal !== 0) {
      formData.append('endAmount', endAmountLocal.toString())
    }
    if (selectedFilterLocal !== 'all') {
      formData.append('type', selectedFilterLocal)
    }
    if (ipAddressLocal) {
      formData.append('ip', ipAddressLocal)
    }
    formData.append('category', 'exchange')
    formData.append('limit', DEFAULT_LIMIT_FOR_REPORT_USER.toString())
    formData.append('page', currentPage.toString())
    formData.append('eventId', props.eventId)

    props.getOpenBet(props.currentDomain, formData, false, emptyFunction, true)
  }

  useEffect(() => {
    setDetail(getDetail())
  }, [props.openBet])

  const columns: readonly Column[] = [
    {
      id: 'srNo',
      flex: 1,
      label: _getLocalString('LOC_SMALL_SR_NO'),
      field: 'srNo',
      minWidth: 120,
      icon: <FaSort />,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      id: 'userName',
      flex: 1,
      label: 'User Name',
      sortable: false,
      disableColumnMenu: true,
      field: 'userName',
      minWidth: 120,
      icon: <FaSort />,
    },

    {
      id: 'nation',
      flex: 1,
      label: 'Nation',
      field: 'nation',
      minWidth: 120,
      sortable: false,
      disableColumnMenu: true,
      icon: <FaSort />,
    },

    {
      id: 'betType',
      flex: 1,
      label: 'Bet Type',
      field: 'betType',
      minWidth: 150,
      sortable: false,
      disableColumnMenu: true,
      icon: <FaSort />,
    },

    {
      id: 'amount',
      flex: 1,
      label: 'Amount',
      field: 'amount',
      minWidth: 150,
      sortable: false,
      disableColumnMenu: true,
      icon: <FaSort />,
    },

    {
      id: 'userRate',
      flex: 1,
      label: 'User Rate',
      field: 'userRate',
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      icon: <FaSort />,
    },

    {
      id: 'placedate',
      flex: 1,
      label: 'Place Date',
      field: 'placedate',
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      icon: <FaSort />,
    },
    {
      id: 'matchdate',
      flex: 1,
      label: 'Match date',
      field: 'matchdate',
      minWidth: 200,
      sortable: false,
      icon: <FaSort />,
    },
    {
      id: 'ip',
      flex: 1,
      label: 'IP',
      field: 'ip',
      minWidth: 120,
      sortable: false,
      icon: <FaSort />,
    },
    {
      id: 'browserDetails',
      flex: 1,
      label: 'Browser Details',
      field: 'browserdetails',
      minWidth: 150,
      sortable: false,
      icon: <FaSort />,
    },
    {
      id: 'flag',
      flex: 1,
      label: 'Flag',
      field: 'countryDetail',
      minWidth: 150,
      sortable: false,
      icon: <FaSort />,
    },
  ]

  const handleTabChange = (_event: any, newValue: SetStateAction<number>) => {
    setTabValue(newValue)
  }

  const [isHovered, setIsHovered] = useState(false)
  const isLargeScreen = useMediaQuery('(min-width:1200px)')

  const handleMatchType = (_event: any, newValue: SetStateAction<number>) => {
    setMatchType(newValue)
  }

  return (
    <div className='xs:h-[100%] md:h-[95%] lg:h-[95%] p-5 rounded-xl bg-center bg-no-repeat backdrop-blur-sm bg-cover w-[90%] justify-center overflow-y-scroll bg-white'>
      <div className='text-2xl font-bold leading-[31px] tracking-normal text-left text-black'>
        View More
      </div>

      <div className='absolute z-[999] top-4 right-5 cursor-pointer'>
        <DisabledByDefaultIcon
          onClick={() => props.setIsOpen && props.setIsOpen(false)}
          className='black'
          color='disabled'
        />
      </div>

      <div>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          className='xs:mb-2 sm:mb-2 md:mb-4  xs:pl-2 pl-12'
          TabIndicatorProps={{
            className: '!bg-[color:var(--header-top-third-bg)] !h-[3px]',
          }}
        >
          <Tab
            label='All'
            style={{
              color: 'Black',
            }}
          />
          <Tab
            label='Match Odds'
            style={{
              color: 'Black',
            }}
          />
          <Tab
            label='Bhawani BookMaker'
            style={{
              color: 'Black',
            }}
          />
          <Tab
            label='Bhawani fancy'
            style={{
              color: 'Black',
            }}
          />
        </Tabs>
      </div>

      <div>
        <Tabs
          value={matchtype}
          onChange={handleMatchType}
          className='xs:mb-2 sm:mb-2 md:mb-4  xs:pl-2 pl-12'
          TabIndicatorProps={{
            className: '!bg-[color:var(--header-top-third-bg)] !h-[3px]',
          }}
        >
          <Tab
            label='Match Bets'
            style={{
              color: 'Black',
            }}
          />
          <Tab
            label='Delete Bets'
            style={{
              color: 'Black',
            }}
          />
        </Tabs>
      </div>

      <div className='flex mt-2'>
        <div className='flex-col justify-center items-center relative w-[20%] mx-1'>
          <label className='font-primary text-base font-medium leading-[30px] tracking-normal text-left text-black'>
            Search By User
          </label>
          <input
            id='userNameTextBox__id'
            className='w-full rounded-lg  ml-[0] p-3 bg-white/[.15] border shadow-[0px_4px_44px_0px_#0000001A] border-solid border-[#E7E7E7] focus:outline-none !placeholder-gray-500 text-black'
            type='email'
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value)
            }}
            onKeyDown={(event) =>
              handleKeyPressForkeyBoardShortcut(
                event,
                () =>
                  get(
                    tabValue,
                    type,
                    startAmount,
                    endAmount,
                    ipAddress || '',
                    search || '',
                  ),
                false,
              )
            }
            placeholder={'Enter Atleast 3 character'}
            tabIndex={1}
          />
        </div>
        <div className='flex-col justify-center items-center relative w-[20%] mx-1'>
          <label className='font-primary text-base font-medium leading-[30px] tracking-normal text-left text-black'>
            IP Address
          </label>
          <input
            id='userNameTextBox__id'
            className='w-full rounded-lg  ml-[0] p-3 bg-white/[.15] border shadow-[0px_4px_44px_0px_#0000001A] border-solid border-[#E7E7E7] focus:outline-none !placeholder-gray-500 text-black'
            type='text'
            value={ipAddress}
            onChange={(e: any) => {
              setIpAddress(e.target.value)
            }}
            placeholder={'IP Address'}
            tabIndex={2}
          />
        </div>
        <div className='flex-col justify-center items-center relative w-[20%] mx-1'>
          <label className='font-primary text-base font-medium leading-[30px] tracking-normal text-left text-black'>
            Amount
          </label>
          <InternalInput
            id='userNameTextBox__id'
            className='w-full rounded-lg  ml-[0] p-3 bg-white/[.15] border shadow-[0px_4px_44px_0px_#0000001A] border-solid border-[#E7E7E7] focus:outline-none !placeholder-gray-500 text-black'
            type='number'
            value={startAmount !== 0 ? startAmount : ''}
            onChange={(e: any) => {
              e.target.value === ''
                ? setStartAmount(0)
                : setStartAmount(parseInt(e.target.value))
            }}
            placeholder={'From Amount'}
            tabIndex={3}
          />
        </div>
        <div className='flex-col justify-center items-center self-end relative w-[20%] mx-1'>
          <label className='font-primary text-base font-medium leading-[30px] tracking-normal text-left text-black'></label>
          <input
            id='userNameTextBox__id'
            className='w-full rounded-lg  ml-[0] p-3 bg-white/[.15] border shadow-[0px_4px_44px_0px_#0000001A] border-solid border-[#E7E7E7] focus:outline-none !placeholder-gray-500 text-black'
            type='number'
            value={endAmount !== 0 ? endAmount : ''}
            onChange={(e: any) => {
              e.target.value === ''
                ? setEndAmount(0)
                : setEndAmount(parseInt(e.target.value))
            }}
            placeholder={'To Amount'}
            tabIndex={4}
          />
        </div>

        <div className='flex-col justify-center items-center self-end relative w-[20%] mx-1'>
          <label className='font-primary text-base font-medium leading-[30px] tracking-normal text-left text-black'>
            Type
          </label>
          <div className='flex justify-center items-center relative w-full bg-opacity-14 !rounded-[50px] '>
            <DropDownGlobal
              className='!w-full !rounded-lg  !ml-[0] !bg-white/[.15] !border !shadow-[0px_4px_44px_0px_#0000001A] !border-solid !border-[#E7E7E7] focus:outline-none !placeholder-gray-500 !text-black'
              item={getTypeName()}
              selectedValue={type}
              update={setType}
              createAccountIcon={true}
              backgroundColor='!bg-white'
              textClass='font-primary text-base font-normal leading-[30px] tracking-normal text-left  !text-black'
            />
          </div>
        </div>

        <button
          onClick={() =>
            get(
              tabValue,
              type,
              startAmount,
              endAmount,
              ipAddress || '',
              search || '',
            )
          }
          tabIndex={5}
          className={`h-[46px] min-w-[100px] cursor-pointer p-2 px-4 bg-[#007DCE] m-2 mb-0 rounded-full self-end font-primary text-base font-normal leading-[26px] tracking-normal text-center`}
        >
          Search
        </button>

        <button
          onClick={reset}
          tabIndex={6}
          className={`h-[46px] min-w-[100px] cursor-pointer p-2 px-4 bg-[#EC1C24] m-2 mb-0 rounded-full self-end font-primary text-base font-normal leading-[26px] tracking-normal text-center`}
        >
          Reset
        </button>
      </div>

      <div
        className='flex px-5 mt-2'
        style={{ justifyContent: 'space-between' }}
      >
        <div>
          <div className='flex flex-wrap gap-5 mt-0 pl-1'>
            <div className='flex items-center gap-1 p-1'>
              <input
                type='radio'
                value='All'
                name='radioGroup'
                checked={selectedFilter === 'all'}
                onChange={() => setSelectedFilter('all')}
              />
              <label className='text-black bold'>All</label>
            </div>
            <div className='flex items-center gap-1 p-1'>
              <input
                type='radio'
                value='Back'
                name='radioGroup'
                checked={selectedFilter === 'back'}
                onChange={() => setSelectedFilter('back')}
              />
              <label className='p-1 text-black bold'>Back</label>
            </div>
            <div className='flex items-center gap-1 p-1'>
              <input
                type='radio'
                value='Lay'
                name='radioGroup'
                checked={selectedFilter === 'lay'}
                onChange={() => setSelectedFilter('lay')}
              />
              <label className='p-1 text-black bold'>Lay</label>
            </div>
          </div>
        </div>
        <div className='flex'>
          <div className='flex mx-2'>
            <h4 className='font-primary text-base font-semibold leading-[31px] tracking-normal text-left text-black'>
              Total Soda:
            </h4>
            <p className='font-primary text-base font-semibold leading-[31px] tracking-normal text-left text-green-600 ml-2'>
              {detail.totalSize}
            </p>
          </div>
          <div className='flex mx-2'>
            <h4 className='font-primary text-base font-semibold leading-[31px] tracking-normal text-left text-black'>
              Total Amount:
            </h4>
            <p className='font-primary text-base font-semibold leading-[31px] tracking-normal text-left text-green-600 ml-2'>
              {detail.amount}
            </p>
          </div>
        </div>
      </div>

      <TableContainer
        className='rounded-md mt-2'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            borderRadius: '10px',
            height: '10px',
            width: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            cursor: 'pointer',
            backgroundColor: 'var(--selected-theme-color2)',
            borderRadius: '10px',
          },
          '@media screen and (min-width: 1200px)': {
            '&::-webkit-scrollbar': {
              height: '10px',
              width: '7px',
            },
          },
        }}
      >
        <Table sx={{ minWidth: 700 }} className='' aria-label='table'>
          <TableHead
            className='bg-[linear-gradient(91.09deg,var(--theme-table-themeDarkColor)_3.38%,var(--theme-table-themeLightColor)_109.87%)] '
            style={{ position: 'sticky', top: 0, zIndex: 1 }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={`!bg-[#2c0506] !bg-opacity-[10%] !border-white !border-opacity-[15%]  xs:!py-[4px] md:!py-2 `}
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    padding: '2px',
                  }}
                >
                  <div className='flex justify-between'>
                    <div className=' xs:[10] sm:[13] sm:[18px] whitespace-nowrap !font-primary !text-base !font-medium  !tracking-normal !text-left !text-white flex justify-between items-center'>
                      <span
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          lineHeight: '31px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                        }}
                      >
                        {column.label}
                      </span>
                    </div>
                    <div className='xs:mt-[9px] sm:mt-[9px] md:mt-2 dark:text-white text-white'>
                      {column.icon}
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className='border-b' style={{ minHeight: '10rem' }}>
            {props.openBetLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                }}
              >
                <CircularProgress />
              </div>
            ) : props.openBet.length ? (
              <>
                {props.openBet.map((currentBet: any, index: number) => {
                  const createdAt = new Date(currentBet.createdAt)
                  const matchDate = new Date(currentBet.market.matchDate)
                  return (
                    <TableRow
                      key={index}
                      className={` ${currentBet.odds.type === 'back' ? 'bg-[#A5D9FE]' : 'bg-[#F8D0CE]'}`}
                    >
                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm ml-3 '> {index + 1}. </div>
                      </TableCell>
                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='flex text-sm'>
                          {' '}
                          {currentBet.user.userName}{' '}
                          <CopyTextIcon message={currentBet.user.userName} />
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm'>{currentBet.user.nation}</div>
                      </TableCell>
                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm'> {currentBet.odds.type} </div>
                      </TableCell>

                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm'> {currentBet.stake} </div>
                      </TableCell>

                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm'>
                          {' '}
                          {currentBet.market.type === 'session'
                            ? get2Decimal(currentBet.odds.run)
                            : get2Decimal(currentBet.odds.odd)}{' '}
                        </div>
                      </TableCell>

                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm'>
                          {createdAt.toLocaleString()}
                        </div>
                      </TableCell>

                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm'>
                          {matchDate.toLocaleString()}
                        </div>
                      </TableCell>

                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm flex'>
                          {' '}
                          {currentBet.user.ip}
                          <CopyTextIcon message={currentBet.user.ip} />
                        </div>
                      </TableCell>

                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        <div className='text-sm'>
                          <Tooltip
                            title={`${currentBet.user.browserDetail.name} ${currentBet.user.browserDetail.version} ${currentBet.user.browserDetail.os}`}
                          >
                            <h5 className='font-primary text-base font-bold leading-[31px] tracking-normal text-left text-green-600 cursor-pointer '>
                              Details
                            </h5>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell sx={{ padding: '1px', height: 'auto' }}>
                        {currentBet?.user?.countryDetail?.countryFlag ? (
                          <img
                            alt='Country Flag Icon'
                            src={currentBet?.user?.countryDetail?.countryFlag}
                            style={{ height: '3rem', width: '3rem' }}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align='center'>
                  <h5 className='font-primary text-lg font-normal leading-[31px] tracking-normal text-center text-black mt-12'>
                    No Record Found
                  </h5>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          backgroundImage:
            'linear-gradient(91.09deg,var(--theme-table-themeDarkColor) 3.38%,var(--theme-table-themeLightColor) 109.87%)',
        }}
      >
        <NavigationIconPagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          pagination={props.openBetPagination}
        />
      </div>
    </div>
  )
}

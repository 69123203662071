import {
  GET_ACCOUNT_STATEMENT,
  GET_BET_HISTORY,
  GET_COMMISSION_REPORT,
  GET_COMPLAIN_LIST,
  GET_DW_REPORT,
  GET_NON_USER_OPEN_BET,
  GET_NON_USER_REPORT,
  GET_OPEN_BET,
  GET_USER_BET_DETAIL,
  GET_USER_FRAUD_DATA,
  GET_USER_REPORT,
} from '../../actions/reportAction/reportsAction'

const initialState: IReports = {
  commissionReport: [],
  commissionReportLoading: false,
  commissionReportPagination: undefined,
  accountStatement: [],
  accountStatementLoading: false,
  accountStatementPagination: undefined,
  complainList: [],
  complainListLoading: false,
  complainListPagination: undefined,
  betHistory: [],
  betHistoryLoading: false,
  betHistoryPagination: undefined,
  dwReport: [],
  dwReportLoading: false,
  dwReportPagination: undefined,
  openBet: [],
  openBetLoading: false,
  openBetPagination: undefined,
  nonUserOpenBet: [],
  nonUserOpenBetLoading: false,
  nonUserOpenBetPagination: undefined,
  userReport: [],
  userReportLoading: false,
  userReportPagination: undefined,
  nonUserReport: [],
  nonUserReportLoading: false,
  nonUserReportPagination: undefined,
  userBetDetail: [],
  userBetDetailLoading: false,
  userBetDetailPagination: undefined,
  fraudReport: [],
  fraudReportLoading: false,
  fraudReportPagination: undefined,
}

export type IReports = {
  accountStatement: any
  accountStatementLoading: any
  accountStatementPagination: any
  fraudReport: any
  fraudReportLoading: any
  fraudReportPagination: any
  complainList: any
  complainListLoading: boolean
  complainListPagination: any
  dwReport: any
  dwReportLoading: any
  dwReportPagination: any
  userReport: any
  userReportLoading: any
  userReportPagination: any
  commissionReport: any
  commissionReportLoading: any
  commissionReportPagination: any
  openBet: any
  openBetLoading: any
  openBetPagination: any
  nonUserOpenBet: any
  nonUserOpenBetLoading: any
  nonUserOpenBetPagination: any
  betHistory: any
  betHistoryLoading: any
  betHistoryPagination: any
  userBetDetail: any
  userBetDetailLoading: any
  userBetDetailPagination: any
  nonUserReport: any
  nonUserReportLoading: any
  nonUserReportPagination: any
}

export default function reportsReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ACCOUNT_STATEMENT:
      return {
        ...state,
        accountStatement: action.payload,
        accountStatementLoading: action.loading,
        accountStatementPagination: action.page,
      }

    case GET_USER_FRAUD_DATA:
      return {
        ...state,
        fraudReport: action.payload,
        fraudReportLoading: action.loading,
        fraudReportPagination: action.page,
      }

    case GET_COMPLAIN_LIST:
      return {
        ...state,
        complainList: action.payload,
        complainListLoading: action.loading,
        complainListPagination: action.page,
      }

    case GET_COMMISSION_REPORT:
      return {
        ...state,
        commissionReport: action.payload,
        commissionReportLoading: action.loading,
        commissionReportPagination: action.page,
      }

    case GET_OPEN_BET:
      return {
        ...state,
        openBet: action.payload,
        openBetLoading: action.loading,
        openBetPagination: action.page,
      }

    case GET_NON_USER_OPEN_BET:
      return {
        ...state,
        nonUserOpenBet: action.payload,
        nonUserOpenBetLoading: action.loading,
        nonUserOpenBetPagination: action.page,
      }

    case GET_USER_BET_DETAIL:
      return {
        ...state,
        userBetDetail: action.payload,
        userBetDetailLoading: action.loading,
        userBetDetailPagination: action.page,
      }

    case GET_BET_HISTORY:
      return {
        ...state,
        betHistory: action.payload,
        betHistoryLoading: action.loading,
        betHistoryPagination: action.page,
      }

    case GET_USER_REPORT:
      return {
        ...state,
        userReport: action.payload,
        userReportLoading: action.loading,
        userReportPagination: action.page,
      }

    case GET_NON_USER_REPORT:
      return {
        ...state,
        nonUserReport: action.payload,
        nonUserReportLoading: action.loading,
        nonUserReportPagination: action.page,
      }

    case GET_DW_REPORT:
      return {
        ...state,
        dwReport: action.payload,
        dwReportLoading: action.loading,
        dwReportPagination: action.page,
      }

    case GET_USER_BET_DETAIL:
      return {
        ...state,
        userBetDetail: action.payload,
        userBetDetailLoading: action.loading,
        userBetDetailPagination: action.page,
      }

    default:
      return state
  }
}

import { FC } from 'react'
import { ManualSessionMarket } from '../marketType/ManualSessionMarket'
import { ICurrentDomain } from '../../../../types/interfaces/currentDomain'

interface IShowFancyMarketsContent {
  fancySessionMarket: any
  value: number
  sessionFancyMarketMap: any
  sessionMarket: any
  sessionMarketMap: any
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
  isDrawerOpen: boolean
  currentBet: any
  marketFavoriteList: any
  betSlipStake: number
  currentDomain: ICurrentDomain
  addFavoriteMarketList: any
  deleteFavoriteMarket: any
  hashMapMarket: any
  isLogin: any
  onMarketClick: any
}

export const ShowFancyMarketsContent: FC<IShowFancyMarketsContent> = (
  props: IShowFancyMarketsContent,
) => {
  const getFancyStatus = (marketLocal: any) => {
    if (marketLocal.length) {
      // TODO: Check market status for all fancy
      return true
    }
    return false
  }

  return (
    <>
      <>
        <div className='flex justify-between w-full mt-2 '>
          {!!(props.fancySessionMarket.length || props.sessionMarket.length) ? (
            <div
              style={{ justifyContent: 'center' }}
              className='pl-5 flex justify-between dark:text-white text-black  gap-2 items-center sm:w-[55%] w-[48%] bold'
            >
              All Session Runs
            </div>
          ) : (
            <div className='dark:text-white text-black  flex justify-content-center gap-2 items-center sm:w-[55%] w-[48%] bold'>
              No Session Found
            </div>
          )}

          {/* -------------Div 2--------------- */}

          {!!(
            getFancyStatus(props.fancySessionMarket) ||
            props.sessionMarket.length
          ) && (
            <div className='flex justify-center mb-[2px] sm:mb-[3px] items-center w-[32%] sm:w-[41.6%] md:w-[43%] lg:w-[43%] xl:w-[41.5%]'>
              <div className='xl:w-[calc(100%/5-2px)] lg:w-[calc(100%/5-2px)] md:w-[calc(100%/5-2px)] sm:w-[calc(100%/5-2px)] w-[calc(100%/2-2px)] text-[17px] font-medium  xs:h-[35px] sm:h-[41px] md:h-[41px] lg:h-[42px] xl:h-[47px] xs:ml-[2px] sm:ml-[4px] md:ml-[6px] lg:ml-[7px] xl:ml-[4px] border-white text-center text-black  dark:bg-[#FAA9BA] bg-transparent rounded-none flex justify-center items-center'>
                No
              </div>
              <div className='xl:w-[calc(100%/5-1px)] lg:w-[calc(100%/5-2px)] md:w-[calc(100%/5-2px)] sm:w-[calc(100%/5-2px)] w-[calc(100%/2-2px)] text-[17px] font-medium xs:h-[35px] sm:h-[41px] md:h-[41px] lg:h-[42px] xl:h-[47px] ml-[2px] border-white text-center text-black bg-transparent dark:bg-[#72BBEF] rounded-none flex justify-center items-center'>
                Yes
              </div>
            </div>
          )}

          {/* -------------Div 3-------------- */}
          <div className=' sm:block hidden sm:w-[13.5%]'></div>
        </div>

        <>
          {props.fancySessionMarket
            .sort((a: any, b: any) => {
              if (a.priority !== b.priority) {
                return a.priority - b.priority
              }
              return a.marketIndex - b.marketIndex
            })
            .map((item: any, index: number) => {
              let tempMarket = props.sessionFancyMarketMap.get(item.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = item
              }
              return (
                <ManualSessionMarket
                  marketFavoriteList={props.marketFavoriteList}
                  betSlipStake={props.betSlipStake}
                  setIsDrawerOpen={props.setIsDrawerOpen}
                  isDrawerOpen={props.isDrawerOpen}
                  currentBet={props.currentBet}
                  currentDomain={props.currentDomain}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={props.hashMapMarket}
                  teams={tempMarket.teams}
                  type='session'
                  islogin={!!props.isLogin}
                  onMarketClick={props.onMarketClick}
                  _id={tempMarket._id}
                  key={index}
                  delaySecond={tempMarket.delaySecond || 0}
                  eventId={tempMarket.eventId}
                  marketId={tempMarket.marketId}
                  status={tempMarket.status}
                  max={tempMarket.max}
                  min={tempMarket.min}
                  name={tempMarket.marketId.name}
                  lay={tempMarket.lay}
                  back={tempMarket.back}
                  marketStatus={undefined}
                />
              )
            })}
        </>

        {(props.value === 0 || props.value === 1) && (
          <>
            {props.sessionMarket.map((item: any, index: any) => {
              let tempMarket = props.sessionMarketMap.get(item.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = item
              }
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <ManualSessionMarket
                  marketFavoriteList={props.marketFavoriteList}
                  betSlipStake={props.betSlipStake}
                  setIsDrawerOpen={props.setIsDrawerOpen}
                  isDrawerOpen={props.isDrawerOpen}
                  currentBet={props.currentBet}
                  currentDomain={props.currentDomain}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={props.hashMapMarket}
                  teams={tempMarket.teams}
                  type='session'
                  islogin={!!props.isLogin}
                  onMarketClick={props.onMarketClick}
                  _id={tempMarket._id}
                  key={index}
                  delaySecond={tempMarket.delaySecond || 0}
                  eventId={tempMarket.eventId}
                  marketId={tempMarket.marketId}
                  status={tempMarket.status}
                  max={tempMarket.max}
                  min={tempMarket.min}
                  name={tempMarket.marketId.name}
                  lay={tempMarket.lay}
                  back={tempMarket.back}
                  marketStatus={undefined}
                />
              )
            })}
          </>
        )}
      </>
    </>
  )
}

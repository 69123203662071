import { memo, useEffect, useMemo, useState } from 'react'
import { organizeDataAverage } from './MatchBetUtility' // Adjust path as needed
import {
  OpenBetNonSessionContent,
  OpenBetNonSessionHeader,
  OpenBetSessionContent,
  OpenBetSessionHeader,
} from './OpenBetContent'
import { useNavigate } from 'react-router-dom'
import { encrypt } from '../../../../api/authHelper'

export interface IOpenMatchBetContent {
  marketStatus: any[]
  isShowBetInfo: boolean
  isShowBetForThisEvent: boolean
}

const OpenMatchBetContentAverageComponent = (props: IOpenMatchBetContent) => {
  const { marketStatus, isShowBetInfo, isShowBetForThisEvent } = props

  const navigate = useNavigate()
  const [organizedMarketStatus, setOrganizedMarketStatus] = useState<any[]>([])

  // Memoize the organizeDataAverage function
  const organizeDataAverageMemoized = useMemo(() => organizeDataAverage, [])

  useEffect(() => {
    setOrganizedMarketStatus(organizeDataAverageMemoized(marketStatus))
  }, [marketStatus, organizeDataAverageMemoized])

  const handleEventClick = (eventId: string) => {
    navigate(`/events/${encrypt(eventId)}`)
  }

  return (
    <>
      {organizedMarketStatus.length ? (
        organizedMarketStatus.map((matchBet, index) => (
          <div key={index} className='mt-3 text-black bold dark:text-white'>
            <div
              className='cursor-pointer'
              onClick={() => handleEventClick(matchBet.eventId)}
            >
              <u>{matchBet.eventName}</u>
            </div>
            {matchBet.market.length &&
              matchBet.market.map((market: any, marketIndex: number) => (
                <div
                  key={marketIndex}
                  className='pt-4 text-black dark:text-white'
                >
                  {market.markets.length && market.markets[0].market.marketName}
                  {market.markets.length &&
                  market.markets[0].market.type === 'session' ? (
                    <OpenBetSessionHeader />
                  ) : (
                    <OpenBetNonSessionHeader />
                  )}
                  {market.markets.length &&
                    market.markets.map(
                      (marketContent: any, contentIndex: number) => (
                        <div key={contentIndex}>
                          {marketContent.market.type === 'session' ? (
                            <OpenBetSessionContent
                              isShowBetInfo={isShowBetInfo}
                              marketContent={marketContent}
                            />
                          ) : (
                            <OpenBetNonSessionContent
                              isShowBetInfo={isShowBetInfo}
                              marketContent={marketContent}
                            />
                          )}
                        </div>
                      ),
                    )}
                </div>
              ))}
          </div>
        ))
      ) : (
        <div className='flex items-center justify-center my-5'>
          <div className='text-[#2D2D2D] dark:text-white text-[17px] text-center'>
            {_getLocalString('LOC_NO_OPEN_BETS_CONTENT')}{' '}
            {isShowBetForThisEvent && 'for this game'}
          </div>
        </div>
      )}
    </>
  )
}

export const OpenMatchBetContentAverage = memo(
  OpenMatchBetContentAverageComponent,
)

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import { get2Decimal, getRoundValue } from '../../../../utils'

interface ISessionBookProps {
  sessionBookArray: { run: number; amount: number }[]
}
export const SessionBook = (props: ISessionBookProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const isLargeScreen = useMediaQuery('(min-width:1200px)')
  return (
    <div className='w-[300px] flex flex-col justify-center items-center'>
      <TableContainer className='rounded-t-md '>
        <Table aria-label='table'>
          <TableHead className='bg-[linear-gradient(94.28deg,#F08A2F_3.45%,#E23542_28.47%,#E53344_61.86%,#8A0537_98.65%)]'>
            <TableCell
              className={`!text-white pl-10`}
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Run
            </TableCell>
            <TableCell
              className={`!text-white pl-10`}
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              Amount
            </TableCell>
          </TableHead>
        </Table>
      </TableContainer>

      <TableContainer
        className='rounded-b-md '
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          maxHeight: '1000px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            borderRadius: '10px',
            height: '5px',
            width: '3px',
          },
          '&::-webkit-scrollbar-thumb': {
            cursor: 'pointer',
            backgroundColor: 'var(--selected-theme-color2)',
            borderRadius: '10px',
          },
          '@media screen and (min-width: 1200px)': {
            '&::-webkit-scrollbar': {
              height: isHovered && isLargeScreen ? '7px' : '0',
              width: isHovered && isLargeScreen ? '5px' : '0',
            },
          },
        }}
      >
        <Table aria-label='table'>
          {props.sessionBookArray.map((sessionIndex, index) => {
            return (
              <TableBody key={index} className='w-full bg-white'>
                <TableCell
                  className={`!text-black pl-10`}
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {sessionIndex.run}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: sessionIndex.amount > 0 ? '#39ff39' : '#f7505e',
                    textAlign: 'center',
                  }}
                  className='bold'
                >
                  {getRoundValue(sessionIndex.amount)}
                </TableCell>
              </TableBody>
            )
          })}
        </Table>
      </TableContainer>
    </div>
  )
}

import { NavBarTile } from '../../pages/landing/component/Content/NavBarTile'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  APP_GLOBAL_SEARCH_CONTENT,
  APP_GLOBAL_SEARCH_CONTENT_LOADING,
  AppState,
} from '../../redux'
import { NavBarTileSports } from '../../pages/landing/component/Content/NavBarTileSports'
import { IUserData } from '../../types/interfaces/login'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { useMediaQuery } from '@mui/material'
import { IoSearch } from 'react-icons/io5'
import { makeGetHttpCallAsync, URLType } from '../../api'
import { toast } from 'react-toastify'
import { SearchableGlobalSearchBox } from '../SearchableGlobalSearchBox'
import { SAVE_SEARCH_MARKETIDS } from '../../redux/actions/marketStatusAction'

export interface INavTiles {
  liveCasinoData?: any[]
  virtualCasinoData?: any[]
  lotteryGamesData?: any[]
  sportsData?: any[]
  slotGamesData?: any[]
  crashGamesData?: any[]
  isDarkMode?: boolean
  pathName?: string
  userLogInLoader: boolean
  userData?: IUserData
  isLogin?: boolean
  currentDomain: ICurrentDomain
  updateOpenLoginScreen?: (openLoginScreen: boolean) => void
  onClickHandlerClose: () => void
  showGames?: boolean
  isSelected?: boolean
  isMobile?: boolean
}

export const NavTiles = (props: INavTiles) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<string>('')
  const [openNav, setOpenNav] = useState<string>('')
  const isSmallScreen = useMediaQuery('(max-width:1200px)')
  const dispatch = useDispatch()
  const { gamesCommonData } = useSelector(
    (state: AppState) => state.gameManagementCommon,
  )

  const gamesNewCommonData = useMemo(() => {
    if (gamesCommonData && gamesCommonData.length > 0) {
      return gamesCommonData.sort((a: any, b: any) =>
        a.name.localeCompare(b.name),
      )
    }
  }, [gamesCommonData])

  const getMatches = (casinoData: any, link: string) => {
    const matches: any[] = []
    casinoData &&
      casinoData.length > 0 &&
      casinoData.forEach((element: any) => {
        matches.push({
          title: element.subProviderName,
          link: `/${link}`,
        })
      })

    return matches
  }

  const [search, setSearch] = useState<any>('')

  const onSearch = () => {
    if (!search) {
      dispatch({
        type: SAVE_SEARCH_MARKETIDS,
        data: '',
      })

      dispatch({
        type: APP_GLOBAL_SEARCH_CONTENT,
        payload: [],
      })

      dispatch({
        type: APP_GLOBAL_SEARCH_CONTENT_LOADING,
        payload: false,
      })
      return
    }

    dispatch({
      type: APP_GLOBAL_SEARCH_CONTENT_LOADING,
      payload: true,
    })

    const formData = new URLSearchParams()
    formData.append('search', search)
    formData.append('active', 'true')
    makeGetHttpCallAsync(
      dispatch,
      'api/common/game/market/globalSearch',
      formData,
      URLType.master,
      '',
      false,
      '',
    )
      .then((res: any) => {
        if (res.length === 0) {
          toast.error('No Result Found')
          dispatch({
            type: APP_GLOBAL_SEARCH_CONTENT_LOADING,
            payload: false,
          })
          return
        }

        let marketIds = ''
        const data = res.map((eventType: any) => {
          const updatedEvents = eventType.events.map((event: any) => {
            marketIds += event.markets[0]._id + ';'
            event.markets[0].teams.forEach((team: any) => {
              team.back =
                team.back && team.back.length > 0
                  ? team.back
                  : [{ run: '-', odd: '-' }]
              team.lay =
                team.lay && team.lay.length > 0
                  ? team.lay
                  : [{ run: '-', odd: '-' }]
            })

            return {
              ...event.markets[0],
              isLockVisible: true,
              marketId: event.markets[0],
              eventTypeId: event.markets[0].eventType.id,
              eventId: event.markets[0].event.id,
            }
          })

          return { ...eventType, events: updatedEvents }
        })

        dispatch({
          type: SAVE_SEARCH_MARKETIDS,
          data: marketIds,
        })

        dispatch({
          type: APP_GLOBAL_SEARCH_CONTENT,
          payload: data,
        })

        dispatch({
          type: APP_GLOBAL_SEARCH_CONTENT_LOADING,
          payload: false,
        })
      })
      .catch(() => {
        toast.error('No Market Found!')
        dispatch({
          type: SAVE_SEARCH_MARKETIDS,
          data: '',
        })

        dispatch({
          type: APP_GLOBAL_SEARCH_CONTENT,
          payload: [],
        })

        dispatch({
          type: APP_GLOBAL_SEARCH_CONTENT_LOADING,
          payload: false,
        })
      })
  }

  return (
    <div
      className={` 
    ${props.showGames ? 'w-full gap-0' : ''}grid grid-col gap-3 mb-4`}
    >
      <>
        <div className='text-[#fff] relative '>
          <SearchableGlobalSearchBox
            placeholder='Search game'
            search={search}
            setSearchQuery={setSearch}
            className={`w-full xs:h-[40px] sm:h-[50px]  xs:px-2 sm:px-8 py-4   dark:from-[var(--gamePlay-secondColor)]  dark:to-[var(--gamePlay-firstColor)] dark:border-solid dark:border-[2px] border dark:border-[var(--gamePlay-secondColor)] border-[var(--gamePlay-firstColor)] dark:bg-transparent bg-white dark:placeholder:!text-white placeholder:!text-black dark:shadow-none shadow-[0px_4px_44px_0px_#0000001f] text-black dark:text-white focus:outline-none
                                font-primary xs:text-[12px] sm:text-base font-normal leading-[30px] tracking-normal text-left`}
            suggestionList={[]}
            isImageDisabled={true}
            searchQuerySubit={onSearch}
          />
          <IoSearch className='dark:text-white text-black absolute right-[20px] xs:top-[8px] sm:top-[13.5px] xs:h-[18px] sm:w-[22px] xs:h-[18px] sm:h-[22px]' />
        </div>
        {props.isLogin && (
          <NavBarTile
            isLogin={props.isLogin}
            height={60}
            name={_getLocalString('LOC_FAVORITE')}
            icon={'favourite'}
            updateOpenLoginScreen={props.updateOpenLoginScreen}
            isSelected={props.pathName?.indexOf('favorite') !== -1}
            link='/favorite'
            isDarkMode={props.isDarkMode}
            onClickHandlerClose={props.onClickHandlerClose}
          />
        )}
        {!!gamesNewCommonData &&
          gamesNewCommonData.map((sports: any, index: number) => {
            return (
              <NavBarTileSports
                showSportsMobile={props.isMobile}
                index={index}
                key={index}
                onClickHandlerClose={props.onClickHandlerClose}
                navigate={navigate}
                setOpenNav={setOpenNav}
                openNav={openNav}
                isDarkMode={props.isDarkMode || true}
                sports={sports}
                isPadding={true}
              />
            )
          })}

        <NavBarTile
          height={60}
          name={'Top Casino'}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
          icon={'casinoChip'}
          isLogin={true}
          onClickHandlerClose={props.onClickHandlerClose}
          isSelected={props.pathName?.indexOf('top-casino') !== -1}
          link={'/top-casino'}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          LiveCasino={true}
        />

        <NavBarTile
          height={60}
          name={_getLocalString('LOC_LIVE_CASINO')}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
          icon={'chip'}
          isLogin={props.isLogin}
          onClickHandlerClose={props.onClickHandlerClose}
          isSelected={props.pathName?.indexOf('live-casino') !== -1}
          matches={getMatches(props.liveCasinoData, 'live-casino')}
          link={isSmallScreen ? '/live-casino' : '/live-casino'}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          LiveCasino={true}
        />

        <NavBarTile
          height={60}
          isLogin={props.isLogin}
          name={_getLocalString('LOC_CRASH_GAME')}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
          icon={'fantasy'}
          onClickHandlerClose={props.onClickHandlerClose}
          isSelected={props.pathName?.indexOf('crash-games') !== -1}
          matches={getMatches(props.crashGamesData, 'crash-games')}
          link={isSmallScreen ? '/crash-games' : '/crash-games'}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          LiveCasino={true}
        />

        <NavBarTile
          height={60}
          name={_getLocalString('LOC_VIRTUAL_GAME')}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
          icon={'virtual'}
          isLogin={props.isLogin}
          onClickHandlerClose={props.onClickHandlerClose}
          isSelected={props.pathName?.indexOf('virtual-games') !== -1}
          matches={getMatches(props.virtualCasinoData, 'virtual-games')}
          link={isSmallScreen ? '/virtual-games' : '/virtual-games'}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          LiveCasino={true}
        />
        <NavBarTile
          height={60}
          name={_getLocalString('LOC_SLOT_GAMES')}
          isLogin={props.isLogin}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
          icon={'casinoChip'}
          onClickHandlerClose={props.onClickHandlerClose}
          isSelected={props.pathName?.indexOf('slot-games') !== -1}
          matches={getMatches(props.slotGamesData, 'slot-games')}
          link={isSmallScreen ? '/slot-games' : '/slot-games'}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          LiveCasino={true}
        />
        <NavBarTile
          height={60}
          name={_getLocalString('LOC_LOTTERY')}
          updateOpenLoginScreen={props.updateOpenLoginScreen}
          icon={'trophy'}
          onClickHandlerClose={props.onClickHandlerClose}
          isSelected={props.pathName?.indexOf('lottery') !== -1}
          matches={getMatches(props.lotteryGamesData, 'lottery')}
          link={isSmallScreen ? '/lottery' : '/lottery'}
          isLogin={props.isLogin}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          LiveCasino={true}
        />
        {props.isLogin &&
          (!gamesCommonData ||
            !gamesCommonData.length ||
            (gamesCommonData.length &&
              gamesCommonData[0].name !== 'Politics')) && (
            <NavBarTile
              height={60}
              name={_getLocalString('LOC_POLITICS')}
              icon={'book'}
              isSelected={props.pathName?.indexOf('2378961') !== -1}
              link='/2378961' // TODO: Provide Politics Id
              isDarkMode={props.isDarkMode}
              onClickHandlerClose={props.onClickHandlerClose}
            />
          )}
      </>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import SkeletonLoading from './skeleton/Skeleton'
import { CircularProgress } from '@mui/material'
interface CustomImageProps {
  src: any
  alt: any
}

const CustomImage = (props: CustomImageProps) => {
  const [loading, setLoading] = useState(true)
  const onLoad = () => {
    setLoading(false)
  }
  useEffect(() => {
    setLoading(true)
  }, [props.src])
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {loading ? (
        <div
          className='flex justify-center items-center'
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99,
          }}
        >
          <CircularProgress color={'primary'} />
        </div>
      ) : null}
      <img
        style={{
          position: 'relative',
          zIndex: loading ? -1 : 999,
          opacity: loading ? 0 : 1,
        }}
        onLoad={onLoad}
        className={`w-full h-full rounded-lg`}
        src={props.src}
        alt={props.alt}
        loading='lazy'
      />
    </div>
  )
}

export default CustomImage

import { useEffect } from 'react'

const useDisableNumberInputScroll = (
  inputRef: React.RefObject<HTMLInputElement>,
) => {
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (inputRef.current && event.type === 'wheel') {
        event.preventDefault()
      }
    }

    // const handleWheel = (event: WheelEvent) => {
    //   if (inputRef.current && document.activeElement === inputRef.current) {
    //     event.preventDefault()
    //   }
    // }

    const inputElement = inputRef.current

    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel)

      return () => {
        inputElement.removeEventListener('wheel', handleWheel)
      }
    }
  }, [inputRef])
}

export default useDisableNumberInputScroll

export const handleKeyPressForkeyBoardShortcut = (
  event: React.KeyboardEvent<HTMLInputElement>,
  cb: any,
  loading: boolean,
) => {
  if (event.key === 'Enter' && !loading) {
    // Trigger button click
    cb()
  }
}

import Marquee from 'react-fast-marquee'
import { getAssetUrl } from '../../../utils'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import 'swiper/css'
import 'swiper/css/free-mode'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CommonSwiper from '../../../component/commonSwiper'
import { Navigation, Pagination, FreeMode } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { useMediaQuery } from '@mui/material'
import { toast } from 'react-toastify'

export const DefaultHeaderGames = (_getLocalString: any) => [
  {
    title: _getLocalString('LOC_EXCHANGE'),
    image: 'Icons-02',
    link: '/',
    selected: false,
  },
  {
    title: _getLocalString('LOC_LIVE_CASINO'),
    image: 'Icons-03',
    link: '/live-casino',
    selected: false,
  },
  {
    title: _getLocalString('LOC_CRASH_GAME'),
    image: 'Icons-04',
    link: '/crash-games',
    selected: false,
  },
  {
    title: _getLocalString('LOC_VIRTUAL_GAME'),
    image: 'Icons-05',
    link: '/virtual-games',
    selected: false,
  },
  {
    title: _getLocalString('LOC_SLOT_GAMES'),
    image: 'Icons-06',
    link: '/slot-games',
    selected: false,
  },
  {
    title: _getLocalString('LOC_LOTTERY'),
    image: 'Icons-07',
    link: '/lottery',
    selected: false,
  },
  {
    title: 'SPORTS BOOK',
    image: 'Icons-08',
    link: '/sports-book',
    selected: false,
  },
]

export const HeaderBottom = () => {
  const headerBottom = useSelector(
    (state: any) => state.appThemeConfiguration.headerBottom,
  )
  const { headerBottomMarquee } = useSelector(
    (state: any) => state.appCommonTheme,
  )
  const { siteLoader } = useSelector((state: any) => state.appConfiguration)
  const { type } = useSelector((state: any) => state.currentDomain)
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [activeGame, setActiveGame] = useState(
    DefaultHeaderGames(_getLocalString)[1],
  )
  const { pathname } = useLocation()
  const isLargeScreen = useMediaQuery('(min-width:800px)')

  const swiperModules = [FreeMode, Navigation, Pagination]
  const swiperOptions: SwiperOptions = {
    freeMode: true,
    allowTouchMove: true,
    speed: 0,
    navigation: {
      nextEl: '.swiper-button-next-13',
      prevEl: '.swiper-button-prev-13',
    },
    breakpoints: {
      0: { slidesPerView: 2.3, spaceBetween: 1 },
      400: { slidesPerView: 2.8, spaceBetween: 5 },
      600: { slidesPerView: 4, spaceBetween: 5 },
    },
  }

  useEffect(() => {
    if (pathname) {
      const defaultGames = DefaultHeaderGames(_getLocalString)

      switch (pathname) {
        case '/lottery':
          setActiveGame(defaultGames[5])
          break
        case '/crash-games':
          setActiveGame(defaultGames[2])
          break
        case '/live-casino':
          setActiveGame(defaultGames[1])
          break
        case '/virtual-games':
          setActiveGame(defaultGames[3])
          break
        case '/slot-games':
          setActiveGame(defaultGames[4])
          break
        default:
          setActiveGame(defaultGames[0])
          break
      }
    }
  }, [params, pathname])

  const setNavItemsHandler = (headerGame: any) => {
    if (headerGame.link === '/sports-book') {
      return toast.error(_getLocalString('LOC_COMMING_SOON'))
    }
    setActiveGame(headerGame)
    navigate(headerGame.link)
  }

  const renderSmallSwiper = () => (
    <div className='w-full mb-2 mt-3'>
      <CommonSwiper
        swiperOptions={swiperOptions}
        modules={swiperModules}
        id='header_content_game'
        className='mySwiper swiper'
        classNameSliderWrapper='swiper-wrapper'
        classNameSlider='swiper-slide'
        arrow
      >
        {DefaultHeaderGames(_getLocalString).map((headerGame, index) => (
          <div
            key={index}
            className={`cursor-pointer flex items-center justify-center h-auto w-auto rounded-sm py-3 xs:py-2 xs:px-1 sm:px-3 gap-2 ${
              activeGame.title === headerGame.title
                ? 'bg-[linear-gradient(91deg,_var(--selected-theme-color)_3.38%,_var(--selected-theme-color2)_109.87%)]'
                : 'bg-[--header-bottom-bg]'
            }`}
            onClick={() => setNavItemsHandler(headerGame)}
          >
            <div
              className={`cursor-pointer w-[27px] h-[27px]  shrink-0 ${headerGame.title === 'Slot Games' ? '!w-[35px] ' : ''}`}
            >
              <img
                alt='Header Image Icon'
                className='w-full h-full !shrink-0 !object-cover'
                src={getAssetUrl(`icons/${headerGame.image}.png`)}
              />
            </div>
            <div
              className='cursor-pointer !font-bold leading-5 tracking-normal text-center   uppercase text-[15px] 
             font-primary whitespace-nowrap'
            >
              {headerGame.title}
            </div>
          </div>
        ))}
      </CommonSwiper>
    </div>
  )

  const renderLargeSlider = () => (
    <>
      {DefaultHeaderGames(_getLocalString).map((headerGame, index) => (
        <div
          key={index}
          className={`cursor-pointer h-[37px] flex gap-2 items-center pointer py-2 px-4 rounded-sm ${
            activeGame.title === headerGame.title
              ? 'bg-[linear-gradient(91deg,_var(--selected-theme-color)_3.38%,_var(--selected-theme-color2)_109.87%)]'
              : ''
          }`}
          onClick={() => setNavItemsHandler(headerGame)}
        >
          <div className={`cursor-pointer w-[22px] h-[22px]  shrink-0`}>
            <img
              alt='Game Icon'
              className='w-full h-full !shrink-0 !object-cover'
              src={getAssetUrl(`icons/${headerGame.image}.png`)}
            />
          </div>
          <div className='cursor-pointer font-medium leading-5 tracking-normal text-left uppercase md:text-base xs:text-sm font-primary whitespace-nowrap'>
            {headerGame.title}
          </div>
        </div>
      ))}
    </>
  )

  return (
    <>
      {isLargeScreen ? (
        <div className='lg:fixed z-50 bg-[#330d0a] justify-center items-center w-full'>
          <div
            className={`${siteLoader ? 'bg-[black]' : 'bg-[--header-bottom-bg]'} w-auto lg:w-[full] items-center justify-center md:justify-around text-white flex`}
          >
            {renderLargeSlider()}
          </div>
          {!siteLoader &&
            (headerBottomMarquee || headerBottom?.marqueeText) && (
              <div className='sm:hidden bg-[color:--header-bottom-marquee-bg] text-center hidden md:flex justify-center'>
                <div className='text-[color:--header-bottom-marquee-text] w-[80%]'>
                  <Marquee className='!text-[13px] font-medium leading-[87px] tracking-[0.03em] text-left'>
                    {headerBottom?.marqueeText || headerBottomMarquee}
                  </Marquee>
                </div>
              </div>
            )}
        </div>
      ) : (
        <>
          <div className='justify-center w-full sm:mx-4'>
            {renderSmallSwiper()}
            {!siteLoader &&
              (headerBottomMarquee || headerBottom?.marqueeText) && (
                <div className='sm:hidden bg-[color:--header-bottom-marquee-bg] text-center hidden md:flex justify-center'>
                  <div className='text-[color:--header-bottom-marquee-text] w-[80%]'>
                    <Marquee className='!text-[13px] font-medium leading-[87px] tracking-[0.03em] text-left'>
                      {headerBottom?.marqueeText || headerBottomMarquee}
                    </Marquee>
                  </div>
                </div>
              )}
          </div>
        </>
      )}
    </>
  )
}

import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Button, MenuItem } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useState } from 'react'

export interface IDropDown {
  update?: (lang: string | Array<any> | any) => void
  selectedValue?: string | any
  item: IDropDownItem[] | any[]
  className?: string
  classes?: any
  containerClass?: string
  backgroundColor?: string
  textClass?: string
  displayKey?: string
  id?: string
  valueKey?: string
  placeholder?: string
  multiple?: boolean
  createAccountIcon?: boolean
  select?: boolean
  dark?: boolean
  partnership?: boolean
  handleEditForm?: any
  setDeleteAccount?: any
  handlePrimary?: any
  isRegisterDropDown?: boolean
}

export interface IDropDownItem {
  key: string
  value: string
}

export const DropDownGlobal = (props: IDropDown) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    props.update && props.update(event.target.value)
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const [open, setOpen] = useState(false)
  function extractCountryCode(value: any) {
    const match = value?.match(/(\+\d+)/)

    if (match) {
      const countryCode = match[0]
      return countryCode
    } else {
      console.log('No country code found.')
      return null
    }
  }
  return (
    <Select
      id={props.id}
      value={props.selectedValue}
      displayEmpty
      size='small'
      onClick={() => {
        setOpen(!open)
      }}
      multiple={props.multiple || false}
      onChange={handleChange}
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      onOpen={() => setMenuOpen(true)}
      className={`!rounded-[5px] !text-white dark:border border-solid  border-black !pr-3  searchBox_input  !text-base w-[115px] ${props.className}`}
      classes={{
        icon: `text-[#FE4902] ${props.textClass} `,
      }}
      SelectDisplayProps={{
        className: '!pr-0 py-[6px] !pl-[10px]',
      }}
      IconComponent={() => (
        <>
          {menuOpen ? (
            <KeyboardArrowUp
              className={
                props.createAccountIcon
                  ? 'text-black'
                  : 'text-black dark:text-white'
              }
              style={{ cursor: 'pointer' }}
              onClick={toggleMenu}
            />
          ) : (
            <KeyboardArrowDown
              className={
                props.createAccountIcon
                  ? 'text-black'
                  : 'text-black dark:text-white'
              }
              style={{ cursor: 'pointer' }}
              onClick={toggleMenu}
            />
          )}
        </>
      )}
      MenuProps={{
        classes: {
          paper: `bg-[rgba(29,5,4,1)] ${props.backgroundColor || ''}`,
        },
        MenuListProps: {
          classes: {
            root: `text-black ${props.textClass}`,
          },
        },
        PaperProps: {
          sx: {
            overflowY: 'auto',
            maxHeight: '200px',
            '&::-webkit-scrollbar': {
              height: '5px',
              width: '3px',
            },
            '&::-webkit-scrollbar-thumb': {
              cursor: 'pointer',
              backgroundColor: 'grey',
            },
          },
        },
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
      renderValue={(selected) => {
        const selectedItem =
          props.item.find((item) => item.key === selected) || {}
        return (
          <span
            className={`text-white !h-[45px] capitalize text-2xl ${props.textClass}${
              props.multiple
                ? ' border border-solid  rounded-md bg-slate-100 flex-1'
                : ''
            }`}
          >
            {props.isRegisterDropDown
              ? extractCountryCode(
                  selectedItem?.value ||
                    (selectedItem as any)[props.displayKey || ''],
                )
              : selectedItem.value ||
                (selectedItem as any)[props.displayKey || '']}
          </span>
        )
      }}
    >
      {!props.select && !props.partnership ? (
        <MenuItem value=''>
          <span className={`text-white capitalize text-2xl ${props.textClass}`}>
            {props.placeholder || 'Select'}
          </span>
        </MenuItem>
      ) : null}

      {props.partnership ? (
        <MenuItem value='' key='placeholder'>
          <span className={`text-white capitalize text-2xl ${props.textClass}`}>
            {props.placeholder || 'Select Partnership'}
          </span>
        </MenuItem>
      ) : null}

      {props.item.map((item, index) => {
        return (
          <MenuItem
            key={index}
            value={item.key || (item as any)[props.valueKey || ''] || ''}
            className={`${props.dark ? 'text-black' : 'text-white'} capitalize text-2xl ${props.textClass}${props.multiple
              ? ' border border-solid p-1 rounded-md bg-slate-100 flex-1'
              : ''
            }`}
          >
            <div className='flex justify-between items-center w-full'>
              <span
                className={`text-white capitalize text-2xl ${props.textClass}${
                  props.multiple
                    ? ' border border-solid p-1 rounded-md bg-slate-100 flex-1'
                    : ''
                }`}
              >
                {item.value || (item as any)[props.displayKey || ''] || ''}
              </span>
            </div>
          </MenuItem>
        )
      })}
    </Select>
  )
}

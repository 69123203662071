import { BET_TYPE } from '../../types/enums/betType'
import { getLiability, getProfit } from '../internalUtils'

export function removeElementAtIndex(array: any[], indexToRemove: number) {
  if (indexToRemove < 0 || indexToRemove >= array.length) {
    return array
  }

  array.splice(indexToRemove, 1)
  return array
}

export function getCalculatedExposureOrProfit(
  teamId: string,
  exposureOrProfit: any,
  currentBet: any,
  stake: any,
) {
  let profit = getProfit(currentBet, stake)
  let liability = getLiability(currentBet, stake)

  if (currentBet.teamId === teamId) {
    if (currentBet.oddType === BET_TYPE.BACK.valueOf()) {
      return profit + exposureOrProfit
    } else {
      return liability! - 2 * liability! + exposureOrProfit
    }
  } else {
    if (currentBet.oddType === BET_TYPE.BACK.valueOf()) {
      return liability! - 2 * liability! + exposureOrProfit
    } else {
      return profit + exposureOrProfit
    }
  }
}

export function getMarketStatus(marketId: any, hashMap: any) {
  let betSlipMarket = []
  let marketHashMapDetail = hashMap[marketId.id]
  if (marketHashMapDetail) {
    for (let index = 0; index < marketHashMapDetail.length; index++) {
      betSlipMarket.push({
        exposureOrProfit: marketHashMapDetail[index].profit,
        teamName: marketHashMapDetail[index].teamName,
        teamId: marketHashMapDetail[index].teamId,
      })
    }
  } else {
    for (let index = 0; index < marketId.teams.length; index++) {
      betSlipMarket.push({
        exposureOrProfit: 0,
        teamName: marketId.teams[index].name,
        teamId: marketId.teams[index].id,
      })
    }
  }
  return betSlipMarket
}

export function removeNullMarketId(array: any) {
  if (!array || !array.length) {
    return []
  }

  let eventMarket = []

  for (let index = 0; index < array.length; index++) {
    let eventsData = removeNullMarketIds(array[index].events)
    eventsData.length &&
      eventMarket.push({
        eventTypeId: array[index].eventTypeId,
        events: eventsData,
      })
  }

  return eventMarket
}

export const getSportcontentItemStatus = (sportsContent: any) => {
  const marketIdMap = new Map()

  if (sportsContent && sportsContent.length) {
    sportsContent.forEach((doc: any) => {
      const marketId = doc.marketId._id
      const object = doc
      marketIdMap.set(marketId, object)
    })
  }

  return marketIdMap
}

export function removeNullMarketIds(array: any) {
  if (!array || !array.length) {
    return []
  }

  return array.filter(
    (obj: any) => obj.marketId !== null && typeof obj.marketId !== 'string',
  )
}

export const getAllEventsLength = (onGoingMatches: any): number => {
  if (!onGoingMatches) {
    return 0
  }

  const events: any[] = []
  let eventsLocal = []
  for (let index = 0; index < onGoingMatches.length; index++) {
    eventsLocal = onGoingMatches[index].events
    events.push(...eventsLocal)
  }

  let eventLength = events && events.length
  return eventLength
}

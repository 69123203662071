import { get2Decimal, getAssetUrl } from '..'
import { GAME_TYPE } from '../../types/enums/gameType'

export function convertEventIdWithEventDataMap(data: any) {
  const resultMap = new Map()

  for (const key in data.userBet) {
    if (data.userBet.hasOwnProperty(key)) {
      const eventId = data.userBet[key][0]
      const eventData = data.userBet[key][1]
      resultMap.set(eventId, eventData)
    }
  }
  return resultMap
}

export function getOverroundCalc(teams: any[]) {
  if (!teams || !teams.length) {
    return false
  }

  const backPercentage = teams.reduce(
    (sum, team) =>
      sum + (!team?.back[0]?.odd ? 0 : (1 / team?.back[0]?.odd) * 100),
    0,
  )
  const layPercentage = teams.reduce(
    (sum, team) =>
      sum + (!team?.lay[0]?.odd ? 0 : (1 / team?.lay[0]?.odd) * 100),
    0,
  )

  return (
    get2Decimal(layPercentage) < 100 &&
    get2Decimal(layPercentage) > 91 &&
    get2Decimal(backPercentage) > 100 &&
    get2Decimal(backPercentage) < 109
  )
}

export function getUserDataByEventId(eventId: string, jsonData: any) {
  for (const key in jsonData) {
    if (jsonData.hasOwnProperty(key) && jsonData[key][0] === eventId) {
      return jsonData[key][1]
    }
  }

  return []
}

export function removeDuplicateIds(idList: any) {
  const ids = idList.split(';')

  const uniqueIdsSet = new Set(ids)
  const uniqueIdsArray = [...uniqueIdsSet]

  const uniqueIdString = uniqueIdsArray.join(';')

  return uniqueIdString
}

type EventMapping = { [eventId: string]: any }
export const getEventMap = (gameData: any) => {
  const eventMapping: EventMapping = {}

  for (const category of gameData) {
    for (const competition of category.competitions) {
      for (const event of competition.events) {
        const { id, name, countryCode, timezone, openDate } = event
        eventMapping[id] = {
          eventId: id,
          name,
          countryCode,
          timezone,
          openDate,
          eventTypeId: category.id,
          competitionName: competition.name,
        }
      }
    }
  }

  return eventMapping
}

export function generateRandom10DigitNumber() {
  return Math.floor(1000000000 + Math.random() * 9000000000)
}

export const getPosterForEventType = (eventType: string) => {
  if (!eventType) {
    return getAssetUrl('league/cricket.webp')
  }

  switch (parseInt(eventType)) {
    case GAME_TYPE.Cricket.valueOf():
      return getAssetUrl('league/cricket.webp')

    case GAME_TYPE.Soccer.valueOf():
      return getAssetUrl('league/soccer.webp')

    case GAME_TYPE.Tennis.valueOf():
      return getAssetUrl('league/tennis.webp')

    case GAME_TYPE.Basketball.valueOf():
      return getAssetUrl('league/basketball.webp')

    case GAME_TYPE.Volleyball.valueOf():
      return getAssetUrl('league/volleyball.webp')
  }

  return getAssetUrl('league/cricket.webp')
}

export const getFirstLetterName = (name: string) => {
  const words = name.split(' ')
  let abbreviation = ''

  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    if (word.length > 1) {
      abbreviation += word.slice(0, 2)
    } else {
      abbreviation += word
    }
  }
  return abbreviation.toUpperCase()
}

export function isMarketInPlayOpenDate(openDate: Date) {
  if (!openDate) return false
  const targetDateUTC = new Date(openDate)
  const currentDateUTC = new Date()
  const targetDateIST = new Date(targetDateUTC.getTime() + 330 * 60 * 1000) // 330 minutes = 5 hours and 30 minutes
  const timeDifference = currentDateUTC.getTime() - targetDateIST.getTime()
  return timeDifference < 60000
}

export function IsMarketInPlay(openDate: Date) {
  if (!openDate) return false
  const currentDate = new Date()

  const timeDifference = new Date(openDate).getTime() - currentDate.getTime()
  return timeDifference < 60000
}

export const DEFAULT_SPORTS_MAP: any = new Map([
  ['cricket', 'LOC_CRICKET'],
  ['tennis', 'LOC_TENNIS'],
  ['soccer', 'LOC_FOOTBALL'],
])

export const getTranslatedSportsName = (
  sportsName: string,
  _getLocalString: any,
) => {
  if (DEFAULT_SPORTS_MAP.get(sportsName.toLocaleLowerCase())) {
    return _getLocalString(
      DEFAULT_SPORTS_MAP.get(sportsName.toLocaleLowerCase()),
    )
  }

  return sportsName
}

export function extractIds(data: any) {
  const eventIds: string[] = []
  const marketIds: string[] = []
  const selectedMarket: { [key: string]: boolean } = {}
  const eventNames: { [key: string]: string } = {}

  for (const item of data) {
    const { id, event } = item
    eventNames[event.id] = event.name
    selectedMarket[id] = true
    eventIds.push(event.id)
    marketIds.push(id)
  }

  const generateMarket = {
    selectedMarket,
    eventNames,
  }

  return { eventIds, marketIds, generateMarket }
}

export function isOpenDateBeforeCurrent(openDate: Date) {
  const currentTime = new Date()
  const eventTime = new Date(openDate)

  return eventTime < currentTime
}

export function isMarketInPlay(market: any) {
  if (!market || typeof market === 'string') return false
  const targetDateUTC = new Date(
    market.event ? market.event.openDate : market.openDate,
  )
  const currentDateUTC = new Date()
  const targetDateIST = new Date(targetDateUTC.getTime() + 330 * 60 * 1000) // 330 minutes = 5 hours and 30 minutes
  const timeDifference = currentDateUTC.getTime() - targetDateIST.getTime()

  return timeDifference < 600000
}

export function getEventFromMarket(eventId: string, eventDetailLocal: any) {
  const event = eventDetailLocal[eventId]
  if (event) {
    const { name, openDate, betOpenDate } = event
    return {
      name,
      openDate,
      betOpenDate,
      eventTypeId: event.eventTypeId,
      competitionName: event.competitionName,
    }
  } else {
    return {
      name: 'Loading ...',
      openDate: new Date(),
      screenPlay: false,
    }
  }
}

export function hierarchyChangeObjectEventWise(obj: any) {
  const eventWiseData: any = []

  return eventWiseData
}

export function hierarchyChangeObject(
  obj1: any,
  isMarketAnalysis: boolean = false,
) {
  const formattedData = gameManagementHierarchyEventTypeId(
    obj1,
    isMarketAnalysis,
  )
  const index = formattedData.findIndex((obj: any) => obj.name === 'Politics')

  if (index !== -1) {
    const politicsObject = formattedData.splice(index, 1)[0]
    formattedData.push(politicsObject)
  }

  return formattedData
}

export const getAllEventTypeId = (allGames: any[]) => {
  let eventTypeIds: any[] = []

  for (let item of allGames) {
    eventTypeIds.push({ key: item.id, value: item.name })
  }

  return eventTypeIds
}

export const getCompetition = (allGames: any[], eventTypdId: string) => {
  if (!allGames) {
    return []
  }

  let competition: any[] = []

  for (let item of allGames) {
    if (item.id === eventTypdId) {
      for (let comp of item.competition) {
        competition.push({ key: comp.id, value: comp.name })
      }
    }
  }

  return competition
}

export const getEvent = (
  allGames: any[],
  eventTypdId: string,
  competitioId: string,
) => {
  let eventLocal: any[] = []

  for (let item of allGames) {
    if (item.id === eventTypdId) {
      for (let comp of item.competition) {
        if (comp.id === competitioId) {
          for (let event of comp.event) {
            eventLocal.push({ key: event.id, value: event.name })
          }
        }
      }
    }
  }

  return eventLocal
}

export const getMarket = (
  allGames: any[],
  eventTypdId: string,
  competitioId: string,
  eventId: string,
) => {
  let marketLocal: any[] = []

  for (let item of allGames) {
    if (item.id === eventTypdId) {
      for (let comp of item.competition) {
        if (comp.id === competitioId) {
          for (let event of comp.event) {
            if (event.id === eventId) {
              for (let market of event.market) {
                marketLocal.push({ key: market._id, value: market.name })
              }
            }
          }
        }
      }
    }
  }

  return marketLocal
}

function gameManagementHierarchyEventTypeId(
  inputData: any,
  isMarketAnalysis: boolean = false,
) {
  if (!!isMarketAnalysis) {
    inputData = inputData.filter((market: any) => market.analysis === true)
  }

  const outputData: any = {
    eventType: [],
  }

  inputData.length &&
    inputData.forEach((item: any) => {
      const eventTypeIndex = outputData.eventType.findIndex(
        (eType: any) => eType.id === item.eventType.id,
      )

      if (eventTypeIndex === -1) {
        // If eventType does not exist in outputData, add a new entry
        outputData.eventType.push({
          id: item.eventType?.id,
          name: item.eventType?.name,
          competition: [
            {
              id: item.competition.id,
              name: item.competition.name,
              region: item.competition.region,
              event: [
                {
                  id: item.event.id,
                  name: item.event.name,
                  countryCode: item.event.countryCode,
                  timezone: item.event.timezone,
                  betOpenDate: item.betOpenDate,
                  openDate: item.event.openDate,
                  market: [
                    {
                      active: item.active,
                      bottom: item.bottom,
                      cashout: item.cashout,
                      score: item.score,
                      category: item.category,
                      analysis: item.analysis,
                      top: item.top,
                      type: item.type,
                      teams:
                        item.teams && item.teams.length ? [...item.teams] : [],
                      assignedTo: item.assignedTo,
                      _id: item._id,
                      screenPlay: item.screenPlay,
                      remarksMessage: item.remarksMessage,
                      betAllow: item.betAllow,
                      id: item.id,
                      name: item.name,
                      min: item.min,
                      max: item.max,
                      volume: item.volume,
                      delay: item.delay,
                      manual: item.manual,
                      totalMatched: item.totalMatched,
                      createdAt: item.createdAt,
                      updatedAt: item.updatedAt,
                    },
                  ],
                },
              ],
            },
          ],
        })
      } else {
        // If eventType already exists in outputData, update the existing entry
        const competitionIndex = outputData.eventType[
          eventTypeIndex
        ].competition.findIndex((comp: any) => comp.id === item.competition.id)

        if (competitionIndex === -1) {
          // If competition does not exist in outputData, add a new entry
          outputData.eventType[eventTypeIndex].competition.push({
            id: item.competition.id,
            name: item.competition.name,
            region: item.competition.region,
            event: [
              {
                id: item.event.id,
                name: item.event.name,
                countryCode: item.event.countryCode,
                timezone: item.event.timezone,
                betOpenDate: item.betOpenDate,
                openDate: item.event.openDate,
                market: [
                  {
                    active: item.active,
                    bottom: item.bottom,
                    cashout: item.cashout,
                    category: item.category,
                    score: item.score,
                    analysis: item.analysis,
                    top: item.top,
                    type: item.type,
                    min: item.min,
                    max: item.max,
                    volume: item.volume,
                    delay: item.delay,
                    teams:
                      item.teams && item.teams.length ? [...item.teams] : [],
                    assignedTo: item.assignedTo,
                    _id: item._id,
                    screenPlay: item.screenPlay,
                    remarksMessage: item.remarksMessage,
                    betAllow: item.betAllow,
                    id: item.id,
                    name: item.name,
                    manual: item.manual,
                    totalMatched: item.totalMatched,
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt,
                  },
                ],
              },
            ],
          })
        } else {
          const eventIndex = outputData.eventType[eventTypeIndex].competition[
            competitionIndex
          ].event.findIndex((comp: any) => comp.id === item.event.id)

          if (eventIndex !== -1) {
            outputData.eventType[eventTypeIndex].competition[
              competitionIndex
            ].event[eventIndex].market.push({
              active: item.active,
              bottom: item.bottom,
              cashout: item.cashout,
              score: item.score,
              category: item.category,
              analysis: item.analysis,
              top: item.top,
              type: item.type,
              teams: item.teams && item.teams.length ? [...item.teams] : [],
              assignedTo: item.assignedTo,
              _id: item._id,
              min: item.min,
              max: item.max,
              volume: item.volume,
              delay: item.delay,
              screenPlay: item.screenPlay,
              remarksMessage: item.remarksMessage,
              betAllow: item.betAllow,
              id: item.id,
              name: item.name,
              manual: item.manual,
              totalMatched: item.totalMatched,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
            })
          } else {
            outputData.eventType[eventTypeIndex].competition[
              competitionIndex
            ].event.push({
              id: item.event.id,
              name: item.event.name,
              countryCode: item.event.countryCode,
              timezone: item.event.timezone,
              betOpenDate: item.betOpenDate,
              openDate: item.event.openDate,
              market: [
                {
                  active: item.active,
                  bottom: item.bottom,
                  cashout: item.cashout,
                  score: item.score,
                  category: item.category,
                  min: item.min,
                  max: item.max,
                  volume: item.volume,
                  delay: item.delay,
                  analysis: item.analysis,
                  top: item.top,
                  type: item.type,
                  teams: item.teams && item.teams.length ? [...item.teams] : [],
                  assignedTo: item.assignedTo,
                  _id: item._id,
                  screenPlay: item.screenPlay,
                  remarksMessage: item.remarksMessage,
                  betAllow: item.betAllow,
                  id: item.id,
                  name: item.name,
                  manual: item.manual,
                  totalMatched: item.totalMatched,
                  createdAt: item.createdAt,
                  updatedAt: item.updatedAt,
                },
              ],
            })
          }
        }
      }
    })

  return outputData.eventType
}

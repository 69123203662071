import { FC } from 'react'
import { getAssetUrl } from '../../../../utils'
import { IClubLoyalty } from '../../../../types/interfaces/themeConfiguration'

export interface IClubLoyaltyTile {
  isGolden?: boolean
  text?: string
  background?: string
  isDarkMode?: boolean
  item: IClubLoyalty
  key: number
}

export const ClubLoyaltyTile: FC<IClubLoyaltyTile> = (
  props: IClubLoyaltyTile,
) => {
  const { item } = props
  const imageUrl = getAssetUrl(
    `clubLoyalty/${props.isGolden ? 'golden' : 'silver'}.png`,
  )
  return (
    <div className='relative'>
      <div className='absolute -translate-x-1/2 -translate-y-1/2 left-1/2'>
        <img src={imageUrl} alt='Image Icon Club Loyalty' />
      </div>

      <div
        className={`dark:shadow-none  shadow-[0px_0px_15px_0px_#52525261]  md:px-7 px-3 md:pt-8 pt-7 md:pb-8 pb-6  rounded-xl dark:text-white  ${
          item.title == 'silver' ? 'text-black' : 'text-white'
        }
          ${
            item.title == 'silver'
              ? 'bg-[#EAEAEA]'
              : item.title == 'golden'
                ? 'bg-[#F7AC16]'
                : 'bg-[#1BD8E0]'
          }
          dark:bg-gradient-to-t dark:bg-inherit
           border-[var(--theme-color)] border-t-[var(--theme-color)] border-l-[var(--theme-color)] dark:border
           `}
        style={{
          backgroundImage: `linear-gradient(to top, ${item.darkBackgroundFrom}, ${item.darkBackground})`,
        }}
      >
        <div
          className={` md:text-3xl sm:text-[20px] text-[13px] md:mt-4 sm:mt-2  mt-0 font-medium text-center uppercase ${
            item.title !== 'golden' ? 'dark:text-white' : 'dark:text-[#F7AC16]'
          }  `}
        >
          {item.title}
        </div>
        <div
          className={`dark:text-[#FF670C] ${
            item.title == 'silver' ? 'text-[#FF670C]' : 'text-white'
          } md:text-2xl text-[15px] md:mt-5 mt-2 font-medium md:mb-3 mb-1`}
        >
          {_getLocalString('LOC_BENEFITS')}
        </div>

        <ul className='md:ml-8 ml-4 md:text-xl text-[12px] list-disc list-outside md:marker:text-3xl '>
          {item.benefit.map((benefit: string, index: number) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

import React from 'react'

interface ImageWithFallbackProps {
  src: string
  alt: string
  fallbackSrc: string
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  fallbackSrc,
}) => {
  const handleError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.onerror = null // Prevent infinite loop in case fallbackSrc also fails
    event.currentTarget.src = fallbackSrc
  }

  return (
    <img
      src={src}
      alt={alt}
      loading='lazy'
      className='!w-[35px] !h-[35px]'
      onError={handleError}
    />
  )
}

export default ImageWithFallback

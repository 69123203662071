import React, { useState } from 'react'
import { ClickAwayListener, Tooltip } from '@mui/material'

interface CustomTooltipProps {
  title: string
  children: React.ReactElement
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          title={title}
          open={open}
          onClose={handleTooltipClose}
          disableHoverListener
          disableFocusListener
          disableTouchListener
        >
          <div onClick={handleTooltipOpen}>{children}</div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export default CustomTooltip

// App Theme Action
export const APP_THEME_CONFIGURATION = '@THEME/APP_THEME_CONFIGURATION'

// App Configuration base layout
export const APP_CONFIGURATION_URL_GAME_ID =
  '@URL/APP_CONFIGURATION_URL_GAME_ID'
export const APP_CONFIGURATION_URL_GAME_CODE =
  '@URL/APP_CONFIGURATION_URL_GAME_CODE'
export const APP_CONFIGURATION_URL_GAME_NAME =
  '@URL/APP_CONFIGURATION_URL_GAME_NAME'
export const APP_CONFIGURATION_SOCKET_BLOCKED =
  'APP_CONFIGURATION_SOCKET_BLOCKED'
export const APP_CONFIGURATION_URL_PROVIDER_NAME =
  '@URL/APP_CONFIGURATION_URL_PROVIDER_NAME'
export const APP_CONFIGURATION_SUB_PROVIDER_NAME =
  '@URL/APP_CONFIGURATION_SUB_PROVIDER_NAME'
export const APP_CONFIGURATION_DARK_MODE = 'APP_CONFIGURATION_DARK_MODE'
export const APP_CONFIGURATION_URL_EVENT_ID =
  '@URL/APP_CONFIGURATION_URL_EVENT_ID'
export const APP_CONFIGURATION_URL_MARKET_ID =
  '@URL/APP_CONFIGURATION_URL_MARKET_ID'
export const APP_CONFIGURATION_URL_TYPE = '@URL/APP_CONFIGURATION_URL_TYPE'
export const APP_CONFIGURATION_BASE_LAYOUT =
  '@THEME/SET_APP_CONFIGURATION_BASE_LAYOUT'
export const APP_CONFIGURATION_EVENT_DETAIL =
  '@THEME/APP_CONFIGURATION_EVENT_DETAIL'
export const APP_CONFIGURATION_MARKET_MAX_DELAY =
  '@THEME/APP_CONFIGURATION_MARKET_MAX_DELAY'
export const UPDATE_MODIFY_MARKET = 'UPDATE_MODIFY_MARKET'
export const APP_UPDATE_USE_SOCKET = 'APP_UPDATE_USE_SOCKET'
export const APP_SELECTED_EVENT = 'APP_SELECTED_EVENT;'
export const APP_UPDATE_IS_MAINTENANCE = 'APP_UPDATE_IS_MAINTENANCE'
export const MARKET_LOAD = '@THEME/MARKET_LOAD'
export const SITE_LOADER = '@THEME/SITE_LOADER'
export const DEPOSIT_ADDED = '@REQUEST/DEPOSIT_ADDED'
export const WITHDRAW_ADDED = '@REQUEST/WITHDRAW_ADDED'
export const USER_ADDED = '@REQUEST/USER_ADDED'
export const APP_CONFIGURATION_DEV_TOOLS = 'DEVTOOLS/OPENED'
export const APP_CONFIGURATION_GAME_FULL_SCREEN =
  '@THEME/APP_CONFIGURATION_GAME_FULL_SCREEN'
export const APP_CONFIGURATION_SELECTED_INNER_BOX =
  '@THEME/APP_CONFIGURATION_SELECTED_INNER_BOX'
export const APP_CONFIGURATION_SUB_PROVIDER_DATA =
  '@THEME/APP_CONFIGURATION_SUB_PROVIDER_DATA'
export const APP_CONFIGURATION_GAME_TYPE = '@THEME/APP_CONFIGURATION_GAME_TYPE'
export const APP_CONFIGURATION_SUB_PROVIDER_TYPE =
  '@THEME/APP_CONFIGURATION_SUB_PROVIDER_TYPE'
export const APP_CONFIGURATION_GAME_PATH_NAME =
  '@THEME/APP_CONFIGURATION_GAME_PATH_NAME'

export const APP_CONFIGURATION_FULL_SCREEN_WITH_FOOTER =
  '@THEME/APP_CONFIGURATION_FULL_SCREEN_WITH_FOOTER'
export const APP_CONFIGURATION_FULL_SCREEN =
  '@THEME/APP_CONFIGURATION_FULL_SCREEN'
export const APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT =
  '@THEME/APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT'
export const APP_CONFIGURATION_IS_MOBILE_SCREEN =
  '@THEME/APP_CONFIGURATION_IS_MOBILE_SCREEN'
export const APP_CONFIGURATION_SEARCH_CONTENT =
  '@THEME/APP_CONFIGURATION_SEARCH_CONTENT'
export const APP_CONFIGURATION_SEARCH_CONTENT_LOADING =
  '@THEME/APP_CONFIGURATION_SEARCH_CONTENT_LOADING'

export const APP_GLOBAL_SEARCH_CONTENT = '@THEME/APP_GLOBAL_SEARCH_CONTENT'
export const APP_GLOBAL_SEARCH_CONTENT_LOADING =
  '@THEME/APP_GLOBAL_SEARCH_CONTENT_LOADING'

export const APP_CONFIGURATION_SEARCH_CONTENT_MARKET =
  '@THEME/APP_CONFIGURATION_SEARCH_CONTENT_MARKET'
export const APP_SELECTED_EVENT_ID = '@THEME/SELECTED_EVENT_ID'
export const APP_SELECTED_MARKET_ID = '@THEME/SELECTED_MARKET_ID'
export const APP_SELECTED_RESULT_MARKET = '@THEME/APP_SELECTED_RESULT_MARKET'
export const TOKEN_EXPIRE = 'TOKEN_EXPIRE'
export const APP_SELECTED_RESULT_MARKETS = '@THEME/APP_SELECTED_RESULT_MARKETS'

// AccountList
export const GET_ACCOUNT_LIST = '@ACCOUNTS/GET_ACCOUNT_LIST'
export const UPDATE_ACCOUNT_LIST_USER = '@ACCOUNTS/UPDATE_ACCOUNT_LIST_USER'
export const REMOVE_ACCOUNT_LIST_USER = '@ACCOUNTS/REMOVE_ACCOUNT_LIST_USER'

export const GET_BRANCH_DETAILS = '@BRANCH/GET_BRANCH_DETAILS'

// RequestList
export const SET_REQUEST_LIST = '@REQUEST/SET_REQUEST_LIST'

//FAVORITE LIST
export const GET_FAVORITE_LIST_EVENTS_NAMES = 'GET_FAVORITE_LIST_EVENTS_NAMES'

// GAME MANAGEMENT
export const GET_GAME_MANAGEMENT_DATA_THIRD_PARTY =
  '@GAMES/GET_GAME_MANAGEMENT_DATA_THIRD_PARTY'
export const GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING =
  '@GAMES/GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING'
export const GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS =
  'GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS'
export const GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS =
  'GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS'
export const GET_GAME_MANAGEMENT_COMMON_DATA =
  '@GAMES/GET_GAME_MANAGEMENT_COMMON_DATA'
export const GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING =
  '@GAMES/GET_GAME_MANAGEMENT_COMMON_DATA_LOADING'
export const GET_ALL_GAME_MANAGEMENT_COMMON_DATA =
  'GET_ALL_GAME_MANAGEMENT_COMMON_DATA'

import { toast } from 'react-toastify'
import {
  getEnvAssetUrl,
  getEnvJsonAssetUrl,
  getEnvResourceAssetUrl,
  getEnvThemeAssetUrl,
} from './envValues'
import axios from 'axios'
import { HEADERS, getUrl } from '../api'

export const getAssetUrl = (asset: string) => {
  return getEnvAssetUrl() + asset
}

export const getAssetJsonUrl = (asset: string) => {
  return getEnvJsonAssetUrl() + asset
}

export const getThemeAssetUrl = (asset: string) => {
  if (!asset) {
  }
  return getEnvThemeAssetUrl() + asset
}

export const getResourceAsseturl = (asset: string) => {
  return getEnvResourceAssetUrl() + asset
}

export const checkPasswordVerification = (password: string) => {
  if (password.length < 8 || !/\d/.test(password)) {
    return 'Password should contain 8 character & 1 digit'
  }
}

export function getRandomNumber(min = 20, max = 1100) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const detectDevTools = (dispatch: any) => {
  let widthThreshold = window.outerWidth - window.innerWidth > 160
  let heightThreshold = window.outerHeight - window.innerHeight > 160
  if (heightThreshold || widthThreshold) {
    dispatch({
      type: 'DEVTOOLS/OPENED',
      payload: true,
    })
  } else {
    dispatch({
      type: 'DEVTOOLS/OPENED',
      payload: false,
    })
  }
}

export const getCorrectUserType = (userType: string | null) => {
  return userType === 'defaultBranch' ? 'branch' : userType
}

export function appendObjects(obj1: any, obj2: any) {
  let result = JSON.parse(JSON.stringify(obj1))
  for (let key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (result.hasOwnProperty(key)) {
        if (typeof obj2[key] === 'object' && obj2[key] !== null) {
          result[key] = appendObjects(result[key], obj2[key])
        } else {
          result[key] = obj2[key]
        }
      } else {
        result[key] = obj2[key]
      }
    }
  }

  return result
}

export function formatNumberToAbsolute(number: number) {
  return Math.ceil(number)
}

export function formatVolumeNumber(number: number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M' // 1,000,000 -> 1.0M
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'k' // 1000 -> 1.0k
  } else {
    return number.toString() // Less than 1000
  }
}

export function updateAndMoveToTop(map: any, key: any, value: any) {
  map.set(key, value)

  map = new Map(
    [...map.entries()].sort((a, b) =>
      b[0] === key ? 1 : a[0] === key ? -1 : 0,
    ),
  )
  return map
}

export const emptyFunction = () => {}

export function getRoundValue(val: any) {
  if (!val) {
    return val
  }

  if (typeof val === 'string') {
    return 0
  }
  return Math.floor(get2Decimal(val))
}

export function get2Decimal(val: any) {
  if (!val && val !== 0) {
    return val
  }

  if (typeof val === 'number') {
    if (Number.isInteger(val)) {
      return val
    } else {
      const roundedVal = parseFloat(val.toFixed(2))
      return Object.is(roundedVal, -0) ? 0 : roundedVal // Avoid returning -0
    }
  }

  if (typeof val === 'string') {
    if (val.includes('.')) {
      const parsedVal = parseFloat(val)
      const roundedVal = parseFloat(parsedVal.toFixed(2))
      return Object.is(roundedVal, -0) ? 0 : roundedVal // Avoid returning -0
    } else {
      return val
    }
  }

  return val
}

export const deleteImage = async (
  imageurl: string,
  currentDomain: any,
  handleImageURl: any,
) => {
  const userType = localStorage.getItem(HEADERS.userType)
  await axios
    .delete(
      getUrl(currentDomain.type) +
        '/' +
        `api/${getCorrectUserType(userType)}/setting/s3Image?` +
        `fileName=${imageurl}`,
      {
        headers: {
          [HEADERS.secretKey]: import.meta.env.VITE_API_SECRET_KEY as string,
          [HEADERS.redirect]: HEADERS.follow,
          [HEADERS.xAuthentication]: localStorage.getItem(
            HEADERS.xAuthentication,
          ),
          [HEADERS.Authorization]: localStorage.getItem(
            HEADERS.xAuthentication,
          ),
        },
      },
    )
    .then((res: any) => {
      toast.success(res?.data?.message)
      handleImageURl('')
    })
    .catch((res: any) => {
      handleImageURl('')
      // toast.error(res.data.message)
    })
}

// utils.js
export const formatNumber = (value: number) => {
  if (value < 100) {
    return get2Decimal(value)
  }

  const suffixes = ['', 'K', 'M', 'B', 'T']
  const tier = (Math.log10(value) / 3) | 0 // Determines the suffix tier

  if (tier === 0) {
    return get2Decimal(value)
  }

  const suffix = suffixes[tier]
  const scale = Math.pow(10, tier * 3)
  const scaledValue = value / scale

  const formattedValue = scaledValue.toFixed(scaledValue % 1 ? 1 : 0)

  return `${formattedValue}${suffix}`
}

export function getClientCountryDetail(data: any) {
  return {
    continent: data.continent,
    city: data.city,
    country: data.country,
    countryFlag: data.country_flag,
    region: data.region,
  }
}

export function getClientInfo() {
  const userAgent = navigator.userAgent
  let browserName = ''
  let os = ''
  let version = ''

  // Detect browser name
  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Firefox'
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Chrome'
  } else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Safari'
  } else if (
    userAgent.indexOf('MSIE') > -1 ||
    userAgent.indexOf('Trident/') > -1
  ) {
    browserName = 'Internet Explorer'
  } else {
    browserName = 'Unknown'
  }

  // Detect operating system
  if (userAgent.indexOf('Win') > -1) {
    os = 'Windows'
  } else if (userAgent.indexOf('Mac') > -1) {
    os = 'MacOS'
  } else if (userAgent.indexOf('Linux') > -1) {
    os = 'Linux'
  } else {
    os = 'Unknown'
  }

  // Get browser version
  const versionIndex = userAgent.indexOf(browserName)
  if (versionIndex > -1) {
    version = userAgent
      .substring(versionIndex + browserName.length + 1)
      .split(' ')[0]
  }

  // Return the client information object
  return {
    name: browserName,
    os: os,
    version: version,
    mobile: (window as any).mobileAndTabletCheck() ? 'true' : 'false',
  }
}

// GET ROOT DOMAIN
export const getRootDomain = () => {
  let location = window.location.href
  var parsedUrl = new URL(location)
  var hostnameParts = parsedUrl.hostname.split('.')
  if (hostnameParts[0] === 'www') {
    return hostnameParts.slice(1).join('.')
  } else {
    return parsedUrl.hostname
  }
}

export function extractFirstSegment(str: string) {
  const segments = str.split('/')
  return '/' + segments[1]
}

export function generateUUID() {
  let uuid = '',
    random
  for (let i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-'
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16)
  }
  return uuid
}

export const characters =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_'

export function generateRandomUserName() {
  const usernameLength = Math.floor(Math.random() * 20) + 5 // Random length between 5 and 25 characters
  let username = ''
  for (let i = 0; i < usernameLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    username += characters.charAt(randomIndex)
  }
  return username
}

export function generateRandom10DigitNumber() {
  const min = 1000000000 // Minimum 10-digit number (inclusive)
  const max = 9999999999 // Maximum 10-digit number (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function createArray(number: number) {
  if (number === 1) {
    return Array.from({ length: 12 }, (_, index) => index + 1)
  } else if (number === 2) {
    return Array.from({ length: 12 }, (_, index) => index + 13)
  } else if (number === 3) {
    return Array.from({ length: 15 }, (_, index) => index + 25)
  } else {
    return []
  }
}

import { ICreateUser } from '../../types/interfaces/login'
import { CREATE_USER } from '../actions'
import { UPDATE_USER } from '../actions/administratorAction/userManagerAction'

const initialState: ICreateUser = {
  addLoading: false,
  updateUserLoading: false,
}

export interface ISetCreateUser {
  type: string
  loading: boolean
  data: any
}

export default function (state = initialState, action: ISetCreateUser) {
  switch (action.type) {
    case CREATE_USER: {
      return {
        ...state,
        addLoading: action.loading,
      }
    }

    case UPDATE_USER: {
      return {
        ...state,
        updateUserLoading: action.loading,
      }
    }

    default:
      return state
  }
}

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { APP_CONFIGURATION_DEV_TOOLS } from './redux'

const DevToolsDetector: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    let lastDevtoolsOpen = false

    const emitEvent = (devtoolsOpen: boolean) => {
      if (devtoolsOpen !== lastDevtoolsOpen) {
        console.log('Dispatching event:', devtoolsOpen)
        try {
          dispatch({
            type: APP_CONFIGURATION_DEV_TOOLS,
            payload: devtoolsOpen,
          })
          lastDevtoolsOpen = devtoolsOpen
          console.log('Event dispatched successfully')
        } catch (error) {
          console.error('Error dispatching event:', error)
        }
      }
    }

    const detect = () => {
      let devtoolsOpen = false

      const startTime = performance.now()
      eval('debugger')
      const endTime = performance.now()

      if (endTime - startTime > 100) {
        devtoolsOpen = true
      }

      emitEvent(devtoolsOpen)
    }

    const intervalId = setInterval(detect, 1000)

    return () => clearInterval(intervalId)
  }, [dispatch])

  return null
}

export default DevToolsDetector

import { CircularProgress, FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAssetUrl } from '../../utils'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Check, CloseSharp, WhatsApp } from '@mui/icons-material'
import { IUserLogin } from '../../types/interfaces/login'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { demoLogin } from '../../redux/actions/demoLoginAction/demoLoginAction'
import { handleKeyPressForkeyBoardShortcut } from '../../utils/keyBoardShortcut/onEnterHandle'

export interface ILogin {
  setIsLoginScren: (isLogin: boolean) => void
  setIsForgetPassword: (isForgetPassword: boolean) => void
  setIsRegister: (isLogin: boolean) => void
  setIsChangePassword: (isChangePassword: boolean) => void
  userLogin?: (
    userLoggedIn: IUserLogin,
    navigate: any,
    closeModal: (isLogin: boolean) => void,
    setIsChangePassword: (isChangedPassword: boolean, docs: any) => void,
    currentDomain: ICurrentDomain,
  ) => void
  currentDomain: ICurrentDomain
}

export const Login = forwardRef((props: ILogin, ref: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const logginInProcess = useSelector(
    (state: any) => state.login.userLogInLoader,
  )
  const whatsappNumber = useSelector(
    (state: any) => state.appThemeConfiguration?.registration?.whatsappNumber,
  )
  const currentDomain = useSelector((state: any) => state.currentDomain)

  const onChangeHandler = (e: any, changeName: (value: string) => void) => {
    changeName(e.target.value)
  }

  const setIsLoginScren = () => {
    props.setIsLoginScren(false)
  }

  const userDemoLogin = () => {
    demoLogin({ dispatch, currentDomain, cb })
  }

  const cb = () => {
    props.setIsLoginScren(false)
    props.setIsChangePassword(false)
  }

  const setIsForgetPassword = () => {
    props.setIsChangePassword(false)
    props.setIsLoginScren(false)
    props.setIsForgetPassword(true)
    props.setIsRegister(false)
  }

  const setIsChangePassword = () => {
    props.setIsChangePassword(true)
    props.setIsLoginScren(false)
    props.setIsForgetPassword(false)
    props.setIsRegister(false)
  }

  const setIsRegister = () => {
    props.setIsRegister(true)
    props.setIsLoginScren(false)
    props.setIsForgetPassword(false)
  }

  const handleWhatsappClick = () => {
    window.open(
      whatsappNumber.includes('https')
        ? whatsappNumber
        : `https://wa.me/${whatsappNumber}`,
      '_blank',
    )
  }

  const loginClick = () => {
    props.userLogin!(
      {
        email,
        password,
      },
      navigate,
      setIsLoginScren,
      setIsChangePassword,
      currentDomain,
    )
  }
  const enabledFeatures = useSelector(
    (state: any) => state.appThemeConfiguration.enable,
  )

  return (
    <div
      tabIndex={-1}
      ref={ref}
      id='normalFont'
      className='relative w-[90%] md:w-[90%] p-[1.5px] h-[600px]   sm:justify-center lg:w-[75%] xl:w-[60%] 2xl:w-[55%] 2xl:h-[700px] rounded-[10px]  bg-[linear-gradient(180deg,var(--popup-box-1),var(--paymentMethodColor)),linear-gradient(104.51deg,var(--popup-box-3),var(--popup-box-4))]'
    >
      <div
        className='w-full h-full bg-no-repeat bg-cover  rounded-[10px]'
        style={{
          backgroundImage: `url(${getAssetUrl('asset/stadium-lights-flashes-3d.webp')})`,
        }}
      >
        <div className='flex bg-no-repeat bg-cover bg-left rounded-[10px] h-full'>
          <div className='relative w-1/2 bg-cover bg-center rounded-l-[10px]  hidden md:flex m-[0.8px]'>
            <div
              className='w-full h-full absolute bg-cover rounded-l-[10px] bg-no-repeat mix-blend-darken '
              style={{
                backgroundImage: `url(${getAssetUrl('light.webp')})`,
              }}
            ></div>
            <div
              className='w-full h-full absolute bg-top bg-[size:685px] xl:bg-cover -left-[14px] bg-no-repeat -top-[29px] mix-blend-color-dodge object-contain'
              style={{
                backgroundImage: `url(${getAssetUrl('asset/girl-bg.webp')})`,
                transform: 'rotate(11deg)',
                borderRadius: 10,
              }}
            ></div>
            <div
              className='w-full h-full absolute  bg-[size:616px] 2xl:bg-[size:750px]  bg-no-repeat'
              style={{
                backgroundPositionX: 'center',
                backgroundImage: `url(${getAssetUrl('asset/girl.webp')})`,
              }}
            ></div>
          </div>
          <div className=' rounded-r-lg bg-center bg-gradient-to-br pb-4 to-[var(--popup-box-right-1)] to-[70%]  from-[var(--popup-box-right-2)] bg-no-repeat  bg-cover w-full sm:w-[100%] justify-center md:w-1/2'>
            <div className='absolute z-[999] top-7 right-7 cursor-pointer'>
              <div
                className='w-5 h-5 rounded-[3px] flex justify-center items-center bg-white'
                onClick={setIsLoginScren}
              >
                <CloseSharp className='!text-black !text-base' />
              </div>
            </div>
            <div className='flex flex-col h-full mx-[10px] justify-center items-center'>
              <div className={`${props.currentDomain.domainName === 'winwave247.com' && 'text-black bold'} 'not-italic font-bebas tracking-[1.05px]  text-[color:var(--fontColor)] xs:text-[40px] sm:text-[42px] md:text-[42px] lg:text-[42px] xl:text-[45px] leading-[35px] text-center mt-[25px]'`}>
                {whatsappNumber && (
                  <WhatsApp
                    style={{
                      marginRight: '1rem',
                      fontSize: '3rem',
                      color: '#22b966',
                      cursor: 'pointer',
                    }}
                    onClick={handleWhatsappClick}
                  />
                )}
                {_getLocalString('LOC_LOGIN')}
              </div>

              <form style={{ width: '90%' }}>
                <>
                  <div className='flex justify-center items-center relative w-full mt-[40px]'>
                    <div className='absolute left-4 self-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 13 12'
                        fill='none'
                      >
                        <g clipPath='url(#clip0_304_9765)'>
                          <path
                            d='M6.37406 5.665C8.13395 5.665 9.56063 4.39685 9.56063 2.8325C9.56063 1.26815 8.13395 0 6.37406 0C4.61417 0 3.1875 1.26815 3.1875 2.8325C3.1875 4.39685 4.61417 5.665 6.37406 5.665Z'
                            fill={props.currentDomain.domainName === 'winwave247.com' ? 'black' : 'white'}
                          />
                          <path
                            d='M6.37359 6.60938C3.73497 6.61199 1.59669 8.51268 1.59375 10.8581C1.59375 11.1188 1.83152 11.3302 2.12484 11.3302H10.6223C10.9156 11.3302 11.1534 11.1188 11.1534 10.8581C11.1505 8.51268 9.01222 6.61196 6.37359 6.60938Z'
                            fill={props.currentDomain.domainName === 'winwave247.com' ? 'black' : 'white'}
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_304_9765'>
                            <rect width='12.7463' height='11.33' fill='white' />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                    <input
                      id='userNameTextBox__id'
                      className={`${props.currentDomain.domainName === 'winwave247.com' ? 'text-box-black-placeholder ' : 'text-box-white-placeholder '} h-[40px] w-full rounded-[5px] ml-[0] p-3 bg-white/[.15]  border border-solid border-white/[.21] focus:outline-none pl-12 text-base font-normal leading-[22px] tracking-normal text-left`}
                      onKeyDown={(event: any) => {
                        handleKeyPressForkeyBoardShortcut(
                          event,
                          loginClick,
                          false,
                        )
                      }}
                      type='text'
                      autoComplete='username'
                      onChange={(e: any) => onChangeHandler(e, setEmail)}
                      placeholder={`${_getLocalString('LOC_UERNAME_LABEL')}`}
                    />
                  </div>

                  <div className='flex justify-center items-center relative w-full mt-[15px]'>
                    <div className='absolute left-4 self-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 12 12'
                        fill='none'
                      >
                        <path
                          d='M9.49998 4.212V3.49999C9.49998 1.56701 7.933 0 5.99999 0C4.06698 0 2.5 1.56701 2.5 3.49999V4.212C1.59002 4.60915 1.00131 5.50713 1 6.49999V9.49999C1.00164 10.88 2.11996 11.9984 3.49998 12H8.49998C9.88 11.9984 10.9983 10.88 11 9.49999V6.49999C10.9987 5.50713 10.41 4.60915 9.49998 4.212ZM6.49998 8.50001C6.49998 8.77615 6.27613 9 5.99999 9C5.72385 9 5.5 8.77615 5.5 8.50001V7.5C5.5 7.22386 5.72385 7.00001 5.99999 7.00001C6.27613 7.00001 6.49998 7.22386 6.49998 7.5V8.50001ZM8.5 4.00001H3.49998V3.50002C3.49998 2.11931 4.61927 1.00001 5.99999 1.00001C7.38072 1.00001 8.5 2.11929 8.5 3.50002V4.00001Z'
                          fill={props.currentDomain.domainName === 'winwave247.com' ? 'black' : 'white'}
                        />
                      </svg>
                    </div>
                    <input
                      id='login-passwordTextBox_id'
                      className={`${props.currentDomain.domainName === 'winwave247.com' ? 'text-box-black-placeholder ' : 'text-box-white-placeholder '} h-[40px] w-full rounded-[5px] ml-[0] p-3 bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12 text-base font-normal leading-[22px] tracking-normal text-left'`}
                      type={isPasswordVisible ? 'text' : 'password'}
                      autoComplete='new-password'
                      onKeyDown={(event: any) => {
                        handleKeyPressForkeyBoardShortcut(
                          event,
                          loginClick,
                          false,
                        )
                      }}
                      placeholder={`${_getLocalString('LOC_PASSWORD_LABEL')}`}
                      onChange={(e: any) => onChangeHandler(e, setPassword)}
                    />
                    {isPasswordVisible ? (
                      <VisibilityIcon
                        className='!text-[18px] absolute right-4 self-center cursor-pointer'
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        className='!text-[18px] absolute right-4 self-center cursor-pointer'
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    )}
                  </div>

                  <div className={`${props.currentDomain.domainName === 'winwave247.com' && 'text-black bold'} flex w-full mt-2 justify-between items-center flex-wrap'`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='remeberMe'
                          icon={
                            <div className='w-5 h-5 rounded-[3px] bg-white ml-[3px]'></div>
                          }
                          checkedIcon={
                            <div className='w-5 h-5 justify-center items-center flex rounded-[3px] bg-white'>
                              <Check className='!text-lg text-[#f42020c9]' />
                            </div>
                          }
                        />
                      }
                      label={`${_getLocalString('LOC_REMEBER_ME')}`}
                    />
                  </div>

                  {!logginInProcess ? (
                    <button
                      className={`demo-login-button h-[40px] mt-3 bg-[rgba(252,252,252,0.15)] border border-solid border-white/[.21] text-base leading-[19px] rounded-md w-full ${props.currentDomain.domainName === 'winwave247.com' ? 'text-black bold ' : 'text-white'}  font-bold py-2 text-center cursor-pointer'`}
                      onClick={loginClick}
                      onKeyDown={(event: any) => {
                        handleKeyPressForkeyBoardShortcut(
                          event,
                          loginClick,
                          false,
                        )
                      }}
                      disabled={logginInProcess}
                    >
                      {_getLocalString('LOC_LOGIN_SMALL_CASE')}
                    </button>
                  ) : (
                    <button
                      className={`h-[40px] mt-6 bg-[linear-gradient(8deg,var(--button-popup-1), var(--button-popup-2))]  shadow-[0_4px_4px_var(--theme-color)] border border-solid border-[var(--button-popup-border)]/[.19]  rounded-md w-full ${props.currentDomain.domainName === 'winwave247.com' ? 'text-black bold ' : 'text-white'} text-base font-bold leading-[22px] tracking-normal text-left ${logginInProcess ? 'py-2' : 'py-2'
                        } text-center cursor-pointer`}
                      onClick={loginClick}
                      disabled={logginInProcess}
                    >
                      <CircularProgress
                        style={{ color: 'white' }}
                        size={25}
                        className='white-circular !text-white'
                      />
                    </button>
                  )}

                  <div
                    onClick={userDemoLogin}
                    className={`demo-login-button h-[40px] mt-3 bg-[rgba(252,252,252,0.15)] border border-solid border-white/[.21] text-base leading-[19px] rounded-md w-full ${props.currentDomain.domainName === 'winwave247.com' ? 'text-black bold ' : 'text-white'} font-bold py-2 text-center cursor-pointer'`}
                  >
                    {_getLocalString('LOC_DEMO_LOGIN')}
                  </div>
                  {enabledFeatures?.isRegister &&
                    props.currentDomain.type !== 'b2b' && (
                      <div className={`text-center ${props.currentDomain.domainName === 'winwave247.com' && 'text-black bold'} tracking-[0.7px] font-light mt-8`}>
                        {_getLocalString('LOC_DONT_HAVE_ACCOUNT')}
                        <span
                          onClick={setIsRegister}
                          style={{ textUnderlineOffset: '7px' }}
                          className='font-bold cursor- pl-1 underline cursor-pointer'
                        >
                          {_getLocalString('LOC_REGISTER')}
                        </span>
                      </div>
                    )}
                  {enabledFeatures?.forgetPassword &&
                    props.currentDomain.type !== 'b2b' && (
                      <div
                        className={`text-center tracking-[0.7px] font-light mt-8 ${props.currentDomain.domainName === 'winwave247.com' && 'text-black bold'}`}
                        onClick={setIsForgetPassword}
                      >
                        {_getLocalString('LOC_DONT_REMEBER_YOUR_PASSWORD')}
                        <span
                          onClick={setIsRegister}
                          style={{ textUnderlineOffset: '7px' }}
                          className='font-bold cursor- pl-1 underline cursor-pointer'
                        >
                          {_getLocalString('LOC_FORGOT_PASSWORD')}
                        </span>
                      </div>
                    )}
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

import Marquee from 'react-fast-marquee'
import { CloseRounded } from '@mui/icons-material'
import { Modal } from '@mui/material'
import { useEffect, useRef } from 'react'
import { getThemeAssetUrl } from '../../utils'

export interface IWelcomeBannerPopup {
  isOpen: boolean
  onRequestClose: any
  screenUrl: string
  screenText: string
}

export const WelcomeBannerPopup = (props: IWelcomeBannerPopup) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.isOpen && modalRef.current) {
      modalRef.current.focus()
    }
  }, [props.isOpen, props.screenUrl])

  return (
    <>
      <Modal onClose={props.onRequestClose} open={props.isOpen}>
        <div className='flex items-center justify-center h-screen'>
          <div className='relative w-4/5 h-4/5 bg-white rounded-lg flex flex-col items-center justify-center'>
            <div
              ref={modalRef}
              className='w-full color-white p-2 pr-15 pl-5 bg-[var(--theme-color)] font-bold'
              tabIndex={-1}
            >
              <Marquee style={{ fontWeight: 800, fontSize: '17px' }}>
                {props.screenText
                  ? props.screenText
                  : `Beware of Phishing Website Before Login. Enable Security Auth to
                Secure Your ID.`}
              </Marquee>
            </div>
            <button
              style={{ zIndex: 999 }}
              className='absolute color-white top-2 right-2 w-12 h-12 rounded-full bg-[var(--header-bottom-bg)] flex items-center justify-center text-white font-extrabold'
              onClick={props.onRequestClose}
            >
              <CloseRounded style={{ fontSize: '2rem', fontWeight: 800 }} />
            </button>
            {props.screenUrl && (
              <ImageBox
                screenUrl={
                  props.screenUrl.startsWith('https')
                    ? props.screenUrl
                    : getThemeAssetUrl(props.screenUrl)
                }
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

const ImageBox = (props: { screenUrl: string }) => {
  return (
    <div
      className='relative w-full h-full'
      style={{
        borderRadius: '0 0 1rem 1rem',
        boxShadow:
          '0 10px 15px -3px var(--theme-color), 0 4px 6px -4px rgb(0 0 0 / 16%)',
      }}
    >
      <img
        src={props.screenUrl}
        alt='Screen Image'
        className='w-full h-full object-cover rounded-b-lg'
        style={{ objectFit: 'cover' }}
      />
    </div>
  )
}

export default WelcomeBannerPopup

import { DEFAULT_STAKE, GET_USER_STAKES } from '../../actions/userAction'

export interface IStakesState {
  isLoading?: boolean
  data: IStakeResponse
}

export interface IStake {
  label: string
  value: number
  _id: string
}

export interface IStakeResponse {
  _id: string
  userId: string
  createdAt: string
  stake: IStake[]
}

const initialState: IStakesState = {
  data: DEFAULT_STAKE,
  isLoading: false,
}

export interface ISetStakeAction {
  type: string
  data: IStakeResponse
  isLoading: boolean
}

export function userStakeReducer(
  state = initialState,
  action: ISetStakeAction,
) {
  switch (action.type) {
    case GET_USER_STAKES: {
      return {
        ...state,
        data: action.data,
        isLoading: action.isLoading,
      }
    }

    default:
      return state
  }
}

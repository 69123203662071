import { useEffect, useState } from 'react'
import { getAssetUrl } from '../../../utils'
import { encrypt } from '../../../api/authHelper'
import ImageWithFallback from '../../../component/ImageWithFallback'

interface IHighLightComponent {
  navigate: any
  hightLightItem: any
  isLogin: boolean
  status: any
}

export const HighLightComponent = (props: IHighLightComponent) => {
  useEffect(() => {
    let marketObj = props.status.get(props.hightLightItem.marketId._id)
    if (marketObj && marketObj.teams && marketObj.teams.length) {
      setTeam1([
        marketObj.teams[0].back && marketObj.teams[0].back.length
          ? marketObj.teams[0].back[0].odd
          : '-',
        marketObj.teams[0].lay && marketObj.teams[0].lay.length
          ? marketObj.teams[0].lay[0].odd
          : '-',
      ])
      setTeam2([
        marketObj.teams[1].back && marketObj.teams[1].back.length
          ? marketObj.teams[1].back.length
          : '-',
        marketObj.teams[1].lay && marketObj.teams[1].lay.length
          ? marketObj.teams[1].lay[0].odd
          : '-',
      ])
    }
  }, [props.status])

  const [team1, setTeam1] = useState<any[]>([])
  const [team2, setTeam2] = useState<any[]>([])

  const getImage = (name: string) => {
    return `${getAssetUrl(`highlight/${props.hightLightItem?.marketId?.eventType?.name?.trim().toLowerCase()}/${name.trim()}.png`)}`
  }

  return (
    <>
      <div
        onClick={() => {
          props.navigate(`/events/${encrypt(props.hightLightItem.eventId)}`)
        }}
        className={`px-3 pb-2 rounded-md !max-h-[177px] !bg-no-repeat w-full !bg-cover bg-[var(--highlight-schedule)]  !bg-center `}
        key={1}
      >
        <div className='flex items-center justify-between w-full flex-wrap  pt-2 overflow-hidden'>
          <div className='flex flex-col  shrink-0 w-[79%]'>
            <div className='text-ellipsis truncate whitespace-nowrap  text-sm font-medium font-dm_sans overflow-hidden'>
              {props.hightLightItem &&
                props.hightLightItem.marketId.competition.name}
            </div>
            <div className='flex items-center overflow-hidden text-sm font-medium font-dm_sans w-full text-ellipsis whitespace-nowrap'>
              <div className='w-[40%] flex items-center justify-start text-left'>
                <div className='mr-1'>
                  {new Date(
                    props.hightLightItem.marketId.event.openDate,
                  ).toLocaleDateString(undefined, {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    weekday: 'short',
                  })}
                  ,
                </div>
                <div className=' uppercase'>
                  {new Date(
                    props.hightLightItem.marketId.event.openDate,
                  ).toLocaleTimeString(undefined, {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className='flex shrink-0 items-center text-[11px] text-[#fff] bg-red-600 px-2 rounded-md'>
            <div className='w-[7px] h-[7px] rounded-full bg-[#fff] mr-[5px]'></div>
            Live
          </div>
        </div>

        <div className='w-[100%] text-center overflow-hidden text-sm font-medium font-dm_sans text-ellipsis whitespace-nowrap'>
          {props.hightLightItem && props.hightLightItem.marketId.marketName}
        </div>

        <div className='flex w-full whitespace-break-spaces place-items-center font-dm_sans items-end mt-[2px]'>
          <div className='flex flex-col items-start justify-around flex-1 gap-1 text-justify font-dm_sans '>
            <div className='w-[35px] '>
              <ImageWithFallback
                alt='Sports Icon'
                src={
                  props.hightLightItem &&
                  props.hightLightItem.marketId.event.name &&
                  getImage(
                    props.hightLightItem.marketId.event.name.split('v')[0],
                  )
                }
                fallbackSrc={getAssetUrl(
                  `sports/png/${
                    props.hightLightItem.marketId &&
                    props.hightLightItem.marketId.eventType &&
                    props.hightLightItem.marketId.eventType.name
                      .replace(/\s/g, '')
                      .toLowerCase()
                  }.webp`,
                )}
              />
            </div>
            <div className='overflow-hidden text-[13px] h-[26px] text-[#FFE2E2] font-medium font-dm_sans '>
              {props.hightLightItem &&
                props.hightLightItem.marketId.event.name &&
                props.hightLightItem.marketId.event.name.split('v')[0]}
            </div>
          </div>
          <div className='flex-[2] text-center'>
            <div className='flex justify-center gap-1 mb-3'>
              <div className='text-sm flex items-center justify-center h-[28px] min-w-[29px] px-2 py-6 rounded-md bg-[#ffe2e254] whitespace-normal  text-[#FFE2E2] font-medium font-dm_sans'>
                {'Odds'} <br />
                {(team1.length && team1[0]) || '-'}
              </div>
              <div className='text-sm flex items-center justify-center h-[28px] min-w-[29px] px-2 py-6 rounded-md bg-[#ffe2e254]   text-[#FFE2E2] font-medium font-dm_sans whitespace-normal'>
                {'Odds'} <br />
                {(team2.length && team2[0]) || '-'}
              </div>
            </div>
          </div>
          <div className='flex flex-col items-end justify-around flex-1 text-right gap-1'>
            <div className='w-[35px]'>
              <ImageWithFallback
                alt='Sports Icon'
                src={
                  props.hightLightItem &&
                  props.hightLightItem.marketId.event.name &&
                  getImage(
                    props.hightLightItem.marketId.event.name.split('v')[1],
                  )
                }
                fallbackSrc={getAssetUrl(
                  `sports/png/${
                    props.hightLightItem.marketId &&
                    props.hightLightItem.marketId.eventType &&
                    props.hightLightItem.marketId.eventType.name
                      .replace(/\s/g, '')
                      .toLowerCase()
                  }.webp`,
                )}
              />
            </div>
            <div className='overflow-hidden text-[13px] h-[26px] text-[#FFE2E2] font-medium leading-[13px]  font-dm_sans'>
              {props.hightLightItem &&
                props.hightLightItem.marketId.event.name &&
                props.hightLightItem.marketId.event.name.split('v')[1]}
            </div>
          </div>
        </div>

        <div className='flex items-center w-full gap-2 mt-1  sm:flex'>
          <div className='flex text-[black] w-1/2 bg-[white] rounded-md py-0.5  justify-between px-2 h-[28px] items-center'>
            <div className='text-sm font-medium font-dm_sans p-0.5 px-1 rounded bg-[#a5d9fe] flex justify-center items-center'>
              {(team1.length && team1[0]) || '-'}
            </div>
            <div className='font-dm_sans text-sm font-bold text-[#535353] p-0.5 px-1 rounded bg-[#f8d0ce] flex justify-center items-center'>
              {(team1.length && team1[1]) || '-'}
            </div>
          </div>
          <div className='flex text-[black] w-1/2 bg-[white] rounded-md py-0.5 justify-between px-2 h-[28px] items-center'>
            <div className='text-sm font-medium font-dm_sans p-0.5 px-1 rounded bg-[#a5d9fe] flex justify-center items-center'>
              {(team2.length && team2[0]) || '-'}
            </div>
            <div className='font-dm_sans text-sm font-bold text-[#535353] p-0.5 px-1 rounded bg-[#f8d0ce] flex justify-center items-center'>
              {(team2.length && team2[1]) || '-'}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
} from '@mui/material'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { QuickBetModal } from './modal/quickBetModal'
import { EditQuickStakes, QuickStakes } from './stakes/QuickStakes'
import { EditStakes, Stakes } from './stakes/Stakes'
import {
  DEFAULT_QUICKBET,
  DEFAULT_STAKE,
} from '../../../redux/actions/userAction'
import { Bet } from './Bet'
import { BetSlipOnComponent } from './BetSlipOn'
import {
  BET_SLIP_STAKE,
  IPlaceOrder,
  UPDATE_IS_BET_LOADING,
} from '../../../redux/actions/userAction/betSlipAction'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { IOddBet } from '../../../types/interfaces/exchangeMarketStatus'
import { MARKET_TYPE } from '../../../types/enums/gameType'
import { IMarket } from '../../../types/interfaces/exchangeMatch'
import { BET_TYPE } from '../../../types/enums/betType'
import { IUserData } from '../../../types/interfaces/login'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import { useDispatch } from 'react-redux'
import { SET_USER_QUICK_BET } from '../../../redux'
import { SiteLoader } from '../../../Fallback'
import { decrypt } from '../../../api/authHelper'

export interface INewBetSlipProps {
  getMatchBet: Function
  openBet: any
  quickBetValue: number
  isLogin: boolean
  quickBetOn: boolean
  betSlip: INewBetsSlip
  userData: IUserData
  stakes: any
  quickBet: any
  currentDomain: any
  setbetSlipId: any
  setBetSlipPopup: any
  expand: any
  isShowBetForThisEvent: boolean
  demoLogin: boolean
  setIsShowBetForThisEvent: (isShowBetForThisEvent: boolean) => void
  placeMarketOrder: (order: IPlaceOrder, currentDomain: ICurrentDomain) => void
  otherExpanded?: boolean
}

export interface INewBetsSlip {
  isBetSlipLoading?: boolean
  betSlipLoaderTimer: number
  betSlipStake: number
  timer?: number
  openLoginScreen: boolean
  currentBet: ICurrentBet
  eventId: string
  hashMapBet: any[]
}

export interface IOpenBet {
  odds: IOddBet
  userId: string
  userName: string // (New Field)
  nation: string // Region (New Field)
  market: any
  marketType: MARKET_TYPE
  eventId: any // New Filed
  stake: number // Amount
  updatedAt: Date
  createdAt: Date // Place Date
  profit: number
  liability: number // New Field
  matchDate: Date // Match Date(New Field);
  browserDetail: IBrowserDetail //(New Field)
  _id: string
}

export interface IBrowserDetail {
  name: string
  mobile: string
  version: string
  os: string
}

export interface ICurrentBet {
  showOdd?: number
  marketCurrentStatus?: any
  marketId: IMarket
  marketName: string
  teamId?: string
  oddType: BET_TYPE
  run: number | string
  odd: number
  externalType: string
  manual: boolean
  teamName: string
  profit?: number
  stake?: number
  liability?: number
  marketType: MARKET_TYPE
  delaySecond: number
  min: number
  max: number
}

export interface ICurrentBetHistory {
  marketId: string
  marketName: string
  odd: IOddBet
}

export const NewBetSlip = (props: INewBetSlipProps) => {
  const { id } = useParams()
  const [decryptId] = decrypt(id)
  const [confirmBets, setConfirmBets] = useState(props.userData?.confirmBets)
  const [openConfirmBets, setOpenConfirmBets] = useState(false)

  const getUserQuickBet = () => {
    if (!props.quickBet.data || !('data' in props.quickBet)) {
      return DEFAULT_QUICKBET.stake
    } else return props.quickBet.data.stake
  }

  const getUserStake = () => {
    if (!props.stakes.data || !('data' in props.stakes)) {
      return DEFAULT_STAKE.stake
    } else return props.stakes.data.stake
  }

  const dispatch = useDispatch()

  const [openBet, setOpenBet] = useState(props.openBet)
  const [expanded, setExpanded] = useState(true)
  const [quickBetModalState, setQuickBetModal] = useState<any>(false)
  const [editStakes, setEditStakes] = useState<any>(false)
  const [availableStakesAmounts, setAvailableStakeAmount] =
    useState<any>(getUserStake)
  const [quickBetAmounts, setQuickBetAmounts] = useState<any>(getUserQuickBet)
  const [betSlipOn, setBetSlipOn] = useState<any>(false)
  const [editQuickBetStack, setEditQuickBetStack] = useState<any>(false)
  const [stakeAmount, setStakeAmount] = useState(props.betSlip.betSlipStake)
  const [isShowBetForThisEvent, setIsShowBetForThisEvent] = useState(false)

  useEffect(() => {
    setAvailableStakeAmount(getUserStake)
    setQuickBetAmounts(getUserQuickBet)
  }, [props.stakes.data, props.quickBet.data])

  useEffect(() => {
    if (props.betSlip.betSlipStake !== stakeAmount) {
      dispatch({
        type: BET_SLIP_STAKE,
        betSlipStake: stakeAmount,
      })
    }
  }, [stakeAmount])

  useEffect(() => {
    setOpenBet(props.openBet)
  }, [props.openBet])

  const setQuickBetOn = (value: boolean) => {
    dispatch({
      type: SET_USER_QUICK_BET,
      payload: value,
    })
  }

  const getFilteredOpenBet = () => {
    let decyptIdLocal = decrypt(id)
    if (isShowBetForThisEvent) {
      let openBetTmp = openBet.filter(
        (item: any) => item.market.event.id === decyptIdLocal,
      )
      return openBetTmp
    } else {
      return openBet
    }
  }

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    let currentBet = props.betSlip.currentBet
    setStakeAmount(props.betSlip.betSlipStake)
    if (Object.keys(props.betSlip.currentBet).length !== 0) {
      setExpanded(true)
      setBetSlipOn(true)

      if (props.quickBetOn) {
        if (!props.isLogin) {
          toast.error(_getLocalString('LOC_LOGIN_TO_PLACE_ORDER'))
        }

        if (props.betSlip.isBetSlipLoading) {
        }

        dispatch({
          type: UPDATE_IS_BET_LOADING,
          isBetSlipLoading: true,
          timer: currentBet.delaySecond || 0,
        })

        if (
          currentBet.min > props.betSlip.betSlipStake ||
          currentBet.max < props.betSlip.betSlipStake
        ) {
          dispatch({
            type: UPDATE_IS_BET_LOADING,
            isBetSlipLoading: false,
            timer: currentBet.delaySecond || 0,
          })
          toast.error(_getLocalString('LOC_AMOUNT_SHOULD_BE_IN_RANGE'))
          return
        }

        // setTimeout(() => {
        props.placeMarketOrder(
          {
            marketId: currentBet.marketId._id,
            oddType: currentBet.oddType || BET_TYPE.BACK,
            odd: currentBet.odd,
            run: currentBet.run,
            teamId: currentBet.teamId,
            eventId: currentBet.marketId.event.id,
            marketType: currentBet.marketType.toLocaleUpperCase(),
            nation: props.betSlip.currentBet.marketId.competition.region,
            matchDate: props.betSlip.currentBet.marketId.event.openDate,
            name: currentBet.marketId.name,
            stake: props.quickBetOn
              ? props.quickBetValue
              : props.betSlip.betSlipStake,
          },
          props.currentDomain,
        )
        // }, currentBet.delaySecond * 1000)
      }
    } else {
      setBetSlipOn(false)
    }
  }, [props.betSlip.currentBet])

  return (
    <div className='pb-4 hidden md:block'>
      <Accordion
        expanded={expanded}
        className='dark:bg-gradient-to-b  dark:!bg-[color:transparent] dark:border-[2px] !rounded-none dark:border-[var(--sideNavBar-border-firstColor)] from-[var(--accordian-gradientTo)] to-[var(--accordian-gradientFrom)]'
      >
        <AccordionSummary
          onClick={toggleExpanded}
          expandIcon={<ExpandMoreIcon className='dark:text-white ' />}
          className=' dark:!bg-gradient-to-b shadow-md dark:!bg-[color:transparent] from-[var(--accordian-gradientTo)]  to-[var(--accordian-gradientFrom)] !min-h-max dark:!border !border-solid !bordervar(--sideNavBar-border-firstColor)]'
          classes={{ content: '!my-1' }}
          aria-controls='panel1a-content'
        >
          <span className='text-black dark:text-white uppercase'>
            {_getLocalString('LOC_BET_SLIP')}
          </span>
        </AccordionSummary>
        <AccordionDetails
          className=' bg-transparent dark:text-white text-black !px-0'
          sx={{
            maxHeight: props.otherExpanded ? '400px' : 'auto',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              borderRadius: '10px',
              height: '10px',
              width: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              cursor: 'pointer',
              backgroundColor: 'var(--selected-theme-color2)',
              borderRadius: '10px',
            },
            '@media screen and (min-width: 1200px)': {
              '&::-webkit-scrollbar': {
                height: '10px',
                width: '7px',
              },
            },
          }}
        >
          {!props.betSlip.isBetSlipLoading ? (
            <>
              <div className='flex   xl:flex-row sm:flex-col flex-row justify-between xl:items-center sm:items-start items-center content-center mb-2  !px-3  gap-1 '>
                <div className='font-primary flex items-center text-[15px] font-normal leading-[30px] tracking-normal text-left h-[20px] whitespace-nowrap'>
                  {_getLocalString('LOC_QUICK_BET')}
                  <Switch
                    className='!text-[#2AB6AD] p-0'
                    classes={{
                      thumb: '!bg-[#2AB6AD]',
                      track:
                        'dark:!bg-[#fff] !bg-[#A5D9FE]  !opacity-100 dark:!opacity-100',
                    }}
                    checked={props.quickBetOn}
                    value={props.quickBetOn}
                    onChange={() => {
                      if (!props.isLogin) {
                        toast.error(_getLocalString('LOC_FIRST_TO_CONTINUE'))
                        return
                      }
                      dispatch({
                        type: SET_USER_QUICK_BET,
                        payload: !props.quickBetOn,
                      })
                    }}
                  />
                </div>
                {props.quickBetOn && (
                  <QuickStakes
                    demoLogin={props.demoLogin}
                    betSlipStake={props.betSlip.betSlipStake}
                    stakeAmount={stakeAmount}
                    setStakeAmount={setStakeAmount}
                    editQuickBetStack={editQuickBetStack}
                    setEditQuickBetStack={setEditQuickBetStack}
                    setQuickBetAmounts={setQuickBetAmounts}
                    currentDomain={props.currentDomain}
                    quickBet={props.quickBet}
                    quickBetAmounts={quickBetAmounts}
                    setEditStakes={setEditStakes}
                    stakes={props.stakes}
                  />
                )}
              </div>

              <Bet
                isLogin={props.isLogin}
                betSlipOn={betSlipOn}
                setBetSlipOn={setBetSlipOn}
                setEditQuickBetStack={setEditQuickBetStack}
                setEditStakes={setEditStakes}
              />

              {editStakes ? (
                <EditStakes
                  demoLogin={props.demoLogin}
                  isShowBetForThisEvent={isShowBetForThisEvent}
                  setIsShowBetForThisEvent={setIsShowBetForThisEvent}
                  openBet={getFilteredOpenBet()}
                  editStakes={editStakes}
                  availableStakesAmounts={availableStakesAmounts}
                  setAvailableStakeAmount={setAvailableStakeAmount}
                  currentDomain={props.currentDomain}
                  quickBet={props.quickBet}
                  quickBetAmounts={quickBetAmounts}
                  setEditStakes={setEditStakes}
                  stakes={props.stakes}
                />
              ) : editQuickBetStack ? (
                <EditQuickStakes
                  demoLogin={props.demoLogin}
                  betSlipStake={props.betSlip.betSlipStake}
                  editQuickBetStack={editQuickBetStack}
                  setEditQuickBetStack={setEditQuickBetStack}
                  setQuickBetAmounts={setQuickBetAmounts}
                  currentDomain={props.currentDomain}
                  quickBet={props.quickBet}
                  quickBetAmounts={quickBetAmounts}
                  setEditStakes={setEditStakes}
                  stakes={props.stakes}
                />
              ) : betSlipOn ? (
                <BetSlipOnComponent
                  betSlipStake={props.betSlip.betSlipStake}
                  openConfirmBets={openConfirmBets}
                  demoLogin={props.demoLogin}
                  setOpenConfirmBets={setOpenConfirmBets}
                  confirmBets={confirmBets}
                  setConfirmBets={setConfirmBets}
                  quickBetOn={props.quickBetOn}
                  quickBetValue={props.quickBetValue}
                  isLogin={props.isLogin}
                  getMatchBet={props.getMatchBet}
                  isBetSlipLoading={props.betSlip.isBetSlipLoading || false}
                  placeMarketOrder={props.placeMarketOrder}
                  currentDomain={props.currentDomain}
                  userData={props.userData}
                  stakeAmount={stakeAmount}
                  setStakeAmount={setStakeAmount}
                  setEditQuickBetStack={setEditQuickBetStack}
                  setEditStakes={setEditStakes}
                  currentBet={props.betSlip.currentBet}
                  availableStakesAmounts={availableStakesAmounts}
                  betSlip={props.betSlip}
                />
              ) : (
                <Stakes
                  demoLogin={props.demoLogin}
                  openBet={getFilteredOpenBet()}
                  isShowBetForThisEvent={isShowBetForThisEvent}
                  setIsShowBetForThisEvent={setIsShowBetForThisEvent}
                  editStakes={editStakes}
                  availableStakesAmounts={availableStakesAmounts}
                  setAvailableStakeAmount={setAvailableStakeAmount}
                  currentDomain={props.currentDomain}
                  quickBet={props.quickBet}
                  quickBetAmounts={quickBetAmounts}
                  setEditStakes={setEditStakes}
                  stakes={props.stakes}
                />
              )}
            </>
          ) : (
            <div className='flex flex-col justify-center items-center my-2 bg-transparent  h-[419px] rounded-[4px] '>
              <div className='flex justify-center items-center h-20% mt-20 ml-50'>
                <SiteLoader />
              </div>
              <div className='text-white text-[17px] text-center'>
                Betting in process
              </div>
              <RenderCounter delaySecond={props.betSlip.betSlipLoaderTimer} />
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      {/* create a modal to notify that the bet data will be cleared */}
      <QuickBetModal
        quickBetModal={quickBetModalState}
        setQuickBetModal={setQuickBetModal}
        setQuickBetOn={setQuickBetOn}
      />
    </div>
  )
}

export const RenderCounter = (props: { delaySecond: number }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(props.delaySecond)

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    // Decrement remainingSeconds every second until it reaches 0
    if (remainingSeconds > 0) {
      interval = setInterval(() => {
        setRemainingSeconds((prevRemainingSeconds) => prevRemainingSeconds - 1)
      }, 1000)
    }

    return () => {
      // Clear the interval when component unmounts
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [remainingSeconds]) // Include remainingSeconds in the dependency array

  // Reset the counter when props.delaySecond changes
  useEffect(() => {
    setRemainingSeconds(props.delaySecond)
  }, [props.delaySecond])

  return (
    <div className='mt-2 gradient_text max-w-fit xl:text-[35px] lg:text-[28px] text-[24px] font-bebas font-normal leading-[33px] text-left'>
      {remainingSeconds}
    </div>
  )
}

import { ConnectedComponent, connect } from 'react-redux'
import { Header } from './Header'
import { AppState } from '../../../redux'
import {
  updateThemeToggle,
  updateLanguage,
  loginUser,
  logoutUser,
  signUpUser,
  changePassword,
} from '../../../redux/actions'
import { updateOpenLoginScreen } from '../../../redux/actions/userAction/betSlipAction'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const lang = state.language.lang
  const isLogin = state.login.isLogin || false
  const appThemeConfiguration = state.appThemeConfiguration
  const userData = state.login.userData
  const matchBet = state.reports.openBet
  const matchBetPagination = state.reports.openBetPagination
  const currentDomain = state.currentDomain
  const openLoginScreen = state.betSlip.openLoginScreen
  const demoLogin = state.demoLogin.demoLogin
  const appConfiguration = state.appConfiguration

  return {
    isDarkMode,
    matchBetPagination,
    demoLogin,
    lang,
    appConfiguration,
    openLoginScreen,
    isLogin,
    appThemeConfiguration,
    userData,
    matchBet,
    currentDomain,
  }
}

const ConnectedHeader: ConnectedComponent<any, any> = connect(mapStateToProps, {
  updateThemeToggle,
  languageUpdate: updateLanguage,
  loginUser,
  signUpUser,
  logoutUser,
  updateOpenLoginScreen,
  changePassword,
})(Header as any)

export default ConnectedHeader

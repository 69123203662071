import { List } from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

export interface INavigationIconPagination {
  handlePageChange: (currenPage: number) => void
  currentPage: number
  pagination?: any
  isWhiteBackground?: boolean
}

export const NavigationIconPagination = (props: INavigationIconPagination) => {
  let totalPages = props.pagination?.totalPages || 0
  totalPages = Math.max(totalPages, 1)

  const renderPageNumbers = () => {
    const pageNumbers = []

    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={`mx-3 my-4 w-[39px] h-[29px] text-center flex items-center justify-center cursor-pointer ${
              props.currentPage === i
                ? 'bg-[linear-gradient(91.09deg,var(--theme-table-themeDarkColor)_3.38%,var(--theme-table-themeLightColor)_109.87%)] rounded-md text-white'
                : `${props.isWhiteBackground ? 'text-black' : 'dark:text-white'} border border-solid dark:border-[var(--navigation-bar)] border-1 border-solid border-black rounded-full`
            }`}
            onClick={() => props.handlePageChange(i)}
          >
            {i}
          </li>,
        )
      }
    } else {
      pageNumbers.push(
        <li
          key={1}
          className={`mx-3 my-4 w-[39px] h-[29px] text-center flex items-center justify-center cursor-pointer ${
            props.currentPage === 1
              ? 'bg-[linear-gradient(91.09deg,var(--theme-table-themeDarkColor)_3.38%,var(--theme-table-themeLightColor)_109.87%)] rounded-md text-white'
              : `${props.isWhiteBackground ? 'text-black' : 'dark:text-white'} dark:border-[var(--navigation-bar)] border-1 border-solid border-black rounded-full`
          }`}
          onClick={() => props.handlePageChange(1)}
        >
          1
        </li>,
      )

      if (props.currentPage > 2) {
        pageNumbers.push(
          <li
            key={'ellipsis'}
            className={`text-black mx-3 ${props.isWhiteBackground ? 'text-black' : 'dark:text-white text-black'} my-4 w-[39px] h-[29px] text-center flex items-center justify-center cursor-pointer`}
          >
            ...
          </li>,
        )
      }

      for (
        let i = Math.max(2, props.currentPage - 1);
        i <= Math.min(totalPages - 1, props.currentPage + 1);
        i++
      ) {
        pageNumbers.push(
          <li
            key={i}
            className={`mx-3 my-4 w-[39px] h-[29px] text-center flex items-center justify-center cursor-pointer ${
              props.currentPage === i
                ? 'bg-[linear-gradient(91.09deg,var(--theme-table-themeDarkColor)_3.38%,var(--theme-table-themeLightColor)_109.87%)] rounded-md text-white'
                : `${props.isWhiteBackground ? 'text-black' : 'dark:text-white'} border border-solid dark:border-[var(--navigation-bar)] border-1 border-solid border-black rounded-full`
            }`}
            onClick={() => props.handlePageChange(i)}
          >
            {i}
          </li>,
        )
      }

      if (props.currentPage < totalPages - 1) {
        pageNumbers.push(
          <li
            key={'ellipsis'}
            style={{ color: props.isWhiteBackground ? 'text-black' : '' }}
            className={`${props.isWhiteBackground ? 'text-black' : 'dark:text-white'} mx-3 my-4 w-[39px] h-[29px] text-center flex items-center justify-center cursor-pointer`}
          >
            ...
          </li>,
        )
      }

      pageNumbers.push(
        <li
          key={totalPages}
          className={`mx-3 my-4 w-[39px] h-[29px] text-center flex items-center justify-center cursor-pointer ${
            props.currentPage === totalPages
              ? 'bg-[linear-gradient(91.09deg,var(--theme-table-themeDarkColor)_3.38%,var(--theme-table-themeLightColor)_109.87%)] rounded-md text-white'
              : `${props.isWhiteBackground ? 'text-black' : 'dark:text-white'} border border-solid dark:border-[var(--navigation-bar)] border-1 border-solid border-black rounded-full`
          }`}
          onClick={() => props.handlePageChange(totalPages)}
        >
          {totalPages}
        </li>,
      )
    }

    return pageNumbers
  }

  return (
    <nav className=''>
      <List className='flex items-center dark:text-white text-black justify-center gap-3'>
        <button
          type='button'
          className={`${
            props.currentPage === 1
              ? 'dark:border-[var(--navigation-bar)] border-1 border-solid border-black cursor-not-allowed text-[grey]'
              : `cursor-pointer ${props.isWhiteBackground ? 'text-black' : 'dark:text-white'} border border-solid dark:border-[var(--navigation-bar)] border-1 border-solid border-black rounded-full p-1`
          }`}
          onClick={() => props.handlePageChange(props.currentPage - 1)}
        >
          <NavigateBeforeIcon
            className={`${props.isWhiteBackground ? 'text-black' : 'dark:text-white text-black'} bold`}
            style={{ fontSize: 22 }}
          />
        </button>
        {renderPageNumbers()}
        <button
          type='button'
          className={`${
            props.currentPage === totalPages
              ? 'dark:border-[var(--navigation-bar)] border-1 border-solid border-black cursor-not-allowed text-[grey]'
              : `cursor-pointer ${props.isWhiteBackground ? 'text-black' : 'dark:text-white'} border border-solid dark:border-[var(--navigation-bar)] border-1 border-solid border-black rounded-full p-1`
          }`}
          onClick={() => props.handlePageChange(props.currentPage + 1)}
        >
          <NavigateNextIcon
            className={`${props.isWhiteBackground ? 'text-black' : 'dark:text-white text-black'} bold`}
            style={{ fontSize: 22 }}
          />
        </button>
      </List>
    </nav>
  )
}

import { useSelector } from 'react-redux'
import { NewGameDetail } from '../NewGameDetail'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { createMapFromArrayLiveTv } from '../../../../../redux/actions/matchesAction/SchedulesMatchesAction'
import { decrypt } from '../../../../../api/authHelper'

export const EventGameDetail = (props: { isUser?: boolean }) => {
  const { id } = useParams()
  const [decryptedId, setDecryptedId] = useState(decrypt(id))

  const { eventTypeId, openDate, name, competitionName } = useSelector(
    (state: any) => state.appConfiguration.selectedEvent,
  )
  const { scoreMatches, liveTvMatches } = useSelector(
    (state: any) => state.inPlayMatches,
  )
  const { openBetPagination, openBet } = useSelector(
    (state: any) => state.reports,
  )
  const { scoreData } = useSelector((state: any) => state.score)
  const [screenPlay, setScreenPlay] = useState(false)

  useEffect(() => {
    let screenPlay = createMapFromArrayLiveTv(liveTvMatches)
    setScreenPlay(screenPlay[decryptedId!])
  }, [liveTvMatches])

  useEffect(() => {
    setDecryptedId(decrypt(id))
  }, [id])

  const getFilteredOpenBet = (): [] => {
    let decyptIdLocal = decrypt(id)
    let openBetTmp = openBet.filter(
      (item: any) => item.market.event.id === decyptIdLocal,
    )
    return openBetTmp
  }

  return (
    <>
      {eventTypeId ? (
        <NewGameDetail
          isUser={props.isUser}
          competitionName={competitionName}
          openBetPagination={openBetPagination}
          openBet={getFilteredOpenBet()}
          screenPlay={screenPlay}
          eventTypeId={eventTypeId}
          scoreData={scoreData}
          eventId={decryptedId || ''}
          score={scoreMatches[decryptedId!]}
          openDate={openDate}
          gameName={name || 'No Event Found'}
        />
      ) : (
        <></>
      )}
    </>
  )
}

import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import { HEADERS, makePatchHttpCallAsync } from '../../api'
import { toast } from 'react-toastify'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { IChangePasswordPayload } from '../../types/interfaces/login'
import { getCorrectUserType } from '../../utils'
import { LOGOUT_USER } from './loginAction'

export const changePassword = (
  data: IChangePasswordPayload,
  currentDomain: ICurrentDomain,
  setShowTransactionCode?: (isChangedPassword: boolean) => void,
  updateTransactionCode?: (transactionCode: string) => void,
  cb?: () => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: 'SET_CHANGE_PASSWORD_LOADER', loading: true })
      let userType = localStorage.getItem(HEADERS.userType)
      let formData = new URLSearchParams()
      formData.append('oldPassword', data.oldPassword)
      formData.append('password', data.newPassword)

      makePatchHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/changePassword`,
        formData,
        '',
        currentDomain.type,
        false,
        true,
        'application/x-www-form-urlencoded',
        true,
        'PATCH',
      )
        .then((response: any) => {
          cb && cb()
          if (response.message === 'error') {
            setShowTransactionCode && setShowTransactionCode(false)
            dispatch({ type: 'SET_CHANGE_PASSWORD_LOADER', loading: false })
            dispatch({ type: LOGOUT_USER })
            return
          }
          updateTransactionCode &&
            updateTransactionCode(
              response.docs && response.docs.transactionCode,
            )
          localStorage.removeItem(HEADERS.userType)
          localStorage.removeItem(HEADERS.changePassword)
          localStorage.removeItem(HEADERS.xAuthentication)
          sessionStorage.removeItem(HEADERS.xAuthentication)
          toast.success('PASSWORD UPDATED')
          setShowTransactionCode && setShowTransactionCode(true)
          dispatch({ type: 'SET_CHANGE_PASSWORD_LOADER', loading: false })
        })
        .catch((error) => {
          cb && cb()
          localStorage.removeItem(HEADERS.userType)
          localStorage.removeItem(HEADERS.changePassword)
          localStorage.removeItem(HEADERS.xAuthentication)
          sessionStorage.removeItem(HEADERS.xAuthentication)
          setShowTransactionCode && setShowTransactionCode(false)
          dispatch({ type: LOGOUT_USER })
          dispatch({ type: 'SET_CHANGE_PASSWORD_LOADER', loading: false })
        })
    } catch (error) {
      cb && cb()
      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      localStorage.removeItem(HEADERS.xAuthentication)
      sessionStorage.removeItem(HEADERS.xAuthentication)
      setShowTransactionCode && setShowTransactionCode(false)
      dispatch({ type: LOGOUT_USER })
      dispatch({ type: 'SET_CHANGE_PASSWORD_LOADER', loading: false })
    }
  }
}

import { Modal } from '@mui/material'

interface IQuickBetModal {
  quickBetModal: boolean
  setQuickBetOn: Function
  setQuickBetModal: Function
}

export const QuickBetModal = (props: IQuickBetModal) => {
  return (
    <Modal
      open={props.quickBetModal}
      onClose={() => {}}
      className='flex items-center justify-center'
    >
      <div className='bg-white rounded-[4px] w-[450px] py-6 flex flex-col justify-center items-center'>
        <div className='flex justify-center items-center text-black mb-5 font-bold font-dm_sans text-[20px] '>
          Warning !
        </div>
        <div className='text-[#2D2D2D] font-dm_sans text-[17px] text-center'>
          Your exicting bet data will be cleared
        </div>
        <div className='flex items-center justify-center mt-4'>
          <div
            className='bg-[#A5D9FE] px-4 py-2 shadow-md rounded-[4px] font-bold text-[15px] leading-[15px] !capitalize text-black   text-center w-65 items-center cursor-pointer'
            onClick={() => {
              props.setQuickBetOn(true)
              props.setQuickBetModal(false)
            }}
          >
            OK
          </div>
          <div className='bg-red-500 py-2 px-4  shadow-md rounded-[3px] text-white font-medium text-center cursor-pointer w-65 ml-5'>
            <div
              className='font-bold text-[15px] leading-[15px] !capitalize'
              onClick={() => {
                props.setQuickBetModal(false)
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

// import { Modal } from '@mui/material'
// import { RxCrossCircled } from "react-icons/rx";
// import { FaMoneyCheckDollar } from "react-icons/fa6";
// interface IQuickBetModal {
//   quickBetModal: boolean
//   setQuickBetOn: Function
//   setQuickBetModal: Function
// }

// export const QuickBetModal = (props: IQuickBetModal) => {
//   return (
//     <Modal
//     open={props.quickBetModal}
//     onClose={() => props.setQuickBetModal(false)}
//       className='flex items-center justify-center'
//     >

//      <div className='rounded-2xl bg-[#330d0a] w-[700px] p-6'>
//       <div className='flex items-center justify-between'>
//         <div className='text-2xl'>Transaction Details</div>
//         <RxCrossCircled onClick={() => props.setQuickBetModal(false)} className='text-3xl cursor-pointer'/>
//       </div>

//       <div className='flex flex-col items-center mt-10 ' >
//         <div className='px-8 py-1 text-black bg-white rounded-full'>
//       <FaMoneyCheckDollar  className='text-4xl'/>
//       </div>

//         <div className='text-center mt-9'>
//           <p className=''># 402019253712</p>
//           <div className='mt-2 text-xl font-bold'>$ 1,500</div>
//           <div className='flex items-center justify-center px-2 py-2 mt-3 text-sm text-white bg-gradient-to-r  from-[var(--banner-button-firstColor)] to-[var(--banner-button-secondColor)] rounded-full'>Completed</div>
//         </div>

//       </div>

//       <div className='mt-5 bg-white rounded-2xl '>

//           <div className='grid grid-cols-2 gap-6 px-5 py-4 text-black'>
// <div>
//   <p className='text-base font-medium text-gray-500'>Request ID</p>
//   <div className='mt-2 text-lg font-bold'>#2401202005344720</div>
// </div>
// <div>
// <p className='text-base font-medium text-gray-500'>Amount</p>
//   <div className='mt-2 text-lg font-bold'>$1,500</div>
// </div>

// <div>
//   <p className='text-base font-medium text-gray-500'>Date And Time</p>
//   <div className='mt-2 text-lg font-bold'>20 January, 2024 @ 08:05:34 PM</div>
// </div>
// <div>
// <p className='text-base font-medium text-gray-500'>Payment Type</p>
// </div>

// <div>
//   <p className='text-base font-medium text-gray-500'>UTR</p>
//   <div className='mt-2 text-lg font-bold'>402019253712</div>
// </div>
// <div>
// <p className='text-base font-medium text-gray-500'>Wallet</p>
// </div>
//           </div>
//       </div>
//      </div>
//     </Modal>
//   )
// }

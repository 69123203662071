export const getTeam = (market: any) => {
  let teams = market.teams
  if (!teams) return []
  return teams.map((team: any) => ({
    ...team,
    back: { odd: 0, run: 0 },
    lay: { odd: 0, run: 0 },
  }))
}

export function getSelection(row: any) {
  return row.odds?.teamName ? row.odds?.teamName : 'Session'
}

export function transformNumber(number: number) {
  return number / 100 + 1
}

export function replaceTeamsObject(
  marketId: any,
  newTeams: any,
  status: any,
  delaySecond: any,
  max: any,
  min: any,
  data: any,
) {
  let marketData = data.get(marketId)
  marketData.teams = newTeams
  marketData.status = status
  marketData.max = max
  marketData.min = min
  marketData.delaySecond = delaySecond || 0
  return marketData
}

export function getTeamName(teamId: string, teams: any[]) {
  const foundTeam = teams.find((team) => team.id === teamId)
  return foundTeam ? foundTeam.name : 'Team not found'
}

export function isOpenBetSlipBelowMarket(
  currentBet: any,
  market: any,
  isSmallScreen: boolean,
  isTabletScreen: boolean,
  teamItem?: any,
) {
  if (
    currentBet &&
    currentBet.marketId &&
    currentBet.marketId._id === market._id &&
    !isSmallScreen &&
    isTabletScreen
  ) {
    if (market.type !== 'session') {
      if (teamItem && teamItem.id === currentBet.teamId) {
        return true
      }
    } else {
      return true
    }
  }

  return false
}

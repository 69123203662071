import { useSelector } from 'react-redux'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'

// import required modules
import {
  Navigation,
  Pagination as SwipperPagination,
  FreeMode,
  Autoplay,
} from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
import SwiperCore from 'swiper'
import CommonSwiper from './commonSwiper'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import { getYesterdayDate } from '../utils'

SwiperCore.use([FreeMode, Navigation])

interface IWinner {
  name: string
  amount: string
  date: string
}

interface IWinners {}

export const TopWinners = () => {
  const currentDomain = useSelector((state: any) => state.currentDomain)
  const { topWinners } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )

  const swiperModules = [FreeMode, Navigation]
  const swiperOptions: SwiperOptions = {
    freeMode: true,
    wrapperClass: 'items-center ',

    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        direction: 'horizontal',
      },
      600: {
        slidesPerView: 'auto',
        direction: 'horizontal',
        spaceBetween: 40,
      },
      900: {
        slidesPerView: 'auto',
        direction: 'horizontal',
        spaceBetween: 30,
      },
    },
    navigation: {
      prevEl: `.navigate_prev_${19}`,
      nextEl: `.navigate_next_${19}`,
    },
  }

  let yesterday: any = getYesterdayDate()

  return currentDomain.type === 'b2c' && topWinners && topWinners.length ? (
    <div className={`!bg-no-repeat !bg-right !bg-cover mt-5 mb-5`}>
      <div style={{ padding: '2rem' }} className='xs:w-full sm:w-full'>
        <div className='sm:mb-2 mb-0 gradient_text pt-1 lg:text-[52px] md:text-4xl sm:text-3xl  text-[24px] font-bebas uppercase  font-extrabold  tracking-normal text-center md:text-left'>
          {_getLocalString('LOC_TOP_WINNERS')}
        </div>
        <div
          style={{ lineHeight: '1.5' }}
          className='mb-3 md:text-xl sm:text-xl lg:text-[24px] font-normal text-black dark:text-white lg:text-[20px] text-center md:text-left'
        >
          {_getLocalString('LOC_TOP_WINNERS_CONTENT')}
        </div>
      </div>
      <div>
        <CommonSwiper
          id={`top-winners-tiles`}
          loop={true}
          swiperOptions={swiperOptions}
          slidePreview={2}
          spaceBetween={30}
          speed={6000}
          centered={true}
          classNameSliderWrapper='swiper-wrapper'
          parallax={true}
          classNameSlider='!w-60 swiper-slide'
          freeMode={true}
          centerInsufficientSlides={true}
          clickable={true}
          effect={'slide'}
          autoPlay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          modules={[...swiperModules, SwipperPagination, Autoplay]}
          className='mySwiper swiper'
          style={{ zIndex: 0 }}
          arrow={false}
        >
          {topWinners &&
            [...topWinners, ...topWinners, ...topWinners, ...topWinners].map(
              (topWinner: any) => (
                <SingleWinner yesterday={yesterday} winner={topWinner} />
              ),
            )}
        </CommonSwiper>
      </div>
    </div>
  ) : (
    <></>
  )
}

const SingleWinner = (props: { winner: IWinner; yesterday: string }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        borderRadius: '0.5rem',
        background: `linear-gradient(130deg, var(--advertisement2-backgroundFirst)0%, var(--advertisement2-backgroundSecond)26.28%, var(--advertisement2-backgroundThird)51.69%, var(--advertisement2-backgroundFourth)100%)`,
      }}
    >
      <div>
        <PersonPinIcon
          className='m-2'
          style={{ width: '3.5rem', height: '3.5rem' }}
        />
      </div>
      <div>
        <div className='text-[15px]'>{props.winner.name}</div>
        <div className='text-[15px]'>{props.yesterday}</div>
      </div>
      <div className='pb-5 mt-2 text-[25px] bold'>₹ {props.winner.amount}</div>
    </div>
  )
}

import { FC, useEffect, useState } from 'react'
import { FastestOddTileContent } from './FastestOddTile'
import { Container, useMediaQuery } from '@mui/material'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'

// import required modules
import { FreeMode, Navigation } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
import SwiperCore from 'swiper'
import { ADD_FASTEST_ODDS_MARKET_MARKET_VALUE } from '../../../../redux/actions/marketStatusAction'
import { useDispatch, useSelector } from 'react-redux'
import { getSportcontentItemStatus } from '../../../../utils/gameUtils/gameUtil'

SwiperCore.use([FreeMode, Navigation])

interface IFastestOdd {
  fastestOdds: any[]
  isLogin: boolean
  isDarkMode?: boolean
  updateOpenLoginScreen: any
  setIsDrawerOpen: any
  PlaceOrder: any
}

export const FastestOdd: FC<IFastestOdd> = (props: IFastestOdd) => {
  const sportcontentItemStatus = useSelector(
    (state: any) => state.marketStatus.sportcontentItemStatus,
  )

  const isSmallScreen = useMediaQuery('(min-width:353px) and (max-width:600px)')

  const getFastestOddContent = (fastestOddLocal: any) => {
    const convertedData: any = []
    let marketIds = ''

    const eventTypeMap = new Map()

    fastestOddLocal.forEach((item: any) => {
      const eventTypeId = item.eventTypeId
      const object = item

      if (eventTypeMap.has(eventTypeId)) {
        eventTypeMap.get(eventTypeId).push(object)
      } else {
        eventTypeMap.set(eventTypeId, [object])
      }
    })

    for (const [eventTypeId, objects] of eventTypeMap.entries()) {
      let events: any = []

      objects.forEach((object: any) => {
        if (object.marketId) {
          marketIds += `${object.marketId._id};`

          events.push({
            marketId: object.marketId,
            teams: object.marketId.teams,
            eventTypeId: eventTypeId,
            eventId: object.marketId.event.id,
          })
        }
      })

      let object = {
        eventTypeId: eventTypeId,
        events: events,
      }

      convertedData.push(object)
    }
    return { data: convertedData, marketIds: marketIds }
  }

  const [fastestOddContent, setFastestOddContent] = useState(
    getFastestOddContent(props.fastestOdds).data,
  )

  const swiperModules = [FreeMode, Navigation]
  const swiperOptions: SwiperOptions = {
    freeMode: true,
    loop: fastestOddContent && fastestOddContent.length > 5 ? true : false,
    wrapperClass: 'items-center',
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 'auto',
        spaceBetween: 30,
      },
      900: {
        slidesPerView: 'auto',
        spaceBetween: 30,
      },
    },
    navigation: {
      prevEl: `.navigate_prev_10`,
      nextEl: `.navigate_next_10`,
    },
  }

  const [marketStatus, setMarketStatus] = useState(
    getSportcontentItemStatus(sportcontentItemStatus),
  )

  useEffect(() => {
    setMarketStatus(getSportcontentItemStatus(sportcontentItemStatus))
  }, [sportcontentItemStatus])

  const dispatch = useDispatch()

  useEffect(() => {
    const fastestOddsData = getFastestOddContent(props.fastestOdds)
    setFastestOddContent(fastestOddsData.data)
    dispatch({
      type: ADD_FASTEST_ODDS_MARKET_MARKET_VALUE,
      data: fastestOddsData.marketIds,
    })
  }, [props.fastestOdds])

  return (
    <>
      <Container className='xs:pl-0 xs:pr-0' maxWidth='xl' fixed>
        <div
          style={{ lineHeight: 'normal' }}
          className='mx-auto pt-1 md:mb-5 mb-2  lg:text-[52px] md:text-4xl sm:text-3xl  sm:leading-[50px] text-[24px] font-extrabold tracking-wider text-center gradient_text max-w-max font-bebas'
        >
          {_getLocalString('LOC_FASTEST_ODDS')}
        </div>
        <div
          style={{ justifyContent: 'center' }}
          className='relative flex items-center'
        >
          <div
            style={{ left: -15 }}
            className={`absolute md:hidden m-4 border-2 dark:border-white border-black bg-[#ffe2e254] rounded-full cursor-pointer p-4 w-8 h-8 flex justify-center items-center left-0 navigate_prev_10 cursor-pointer`}
          >
            <ChevronLeftOutlinedIcon className='dark:!text-white !text-black' />
          </div>
          <div
            style={{ placeContent: 'center' }}
            className='mb-5 text-[14px]  md:text-2xl sm:text-xl lg:text-[34px] font-normal tracking-wider text-center text-black uppercase dark:text-white font-bebas'
          >
            {_getLocalString('LOC_BET_NOW_ON_MULTIPLE_SPORTS')}
          </div>
          <div
            style={{ right: -15 }}
            className={`absolute  md:hidden m-4 border-2 dark:border-white border-black bg-[#ffe2e254] rounded-full p-4 w-8 h-8 flex justify-center items-center cursor-pointer navigate_next_10 cursor-pointer`}
          >
            <ChevronRightOutlinedIcon className='dark:!text-white !text-black' />
          </div>
        </div>

        <div className='relative flex items-center'>
          <div
            className={`hidden md:flex m-4 border-2 dark:border-white border-black bg-[#ffe2e254] rounded-full cursor-pointer p-4 w-8 h-8 flex justify-center items-center left-0 navigate_prev_10 cursor-pointer`}
          >
            <ChevronLeftOutlinedIcon className='dark:!text-white !text-black' />
          </div>

          <Swiper
            modules={swiperModules}
            {...swiperOptions}
            className='mySwiper fastest-odd-swiper'
          >
            {!!fastestOddContent &&
              !!fastestOddContent.length &&
              fastestOddContent?.map((item: any, index: number) => (
                <SwiperSlide
                  className={` ${isSmallScreen ? '!w-[251px]' : 'md:!w-[300px] sm:!w-[300px] lg:!w-fit xl:!w-fit 2xl:!w-fit swiper-slide'} `}
                  key={index}
                >
                  <FastestOddTileContent
                    marketStatus={marketStatus}
                    setIsDrawerOpen={props.setIsDrawerOpen}
                    updateOpenLoginScreen={props.updateOpenLoginScreen}
                    onPlaceOrder={props.PlaceOrder}
                    key={index}
                    isLogin={props.isLogin}
                    liveGame={item}
                    isDarkMode={props.isDarkMode}
                  />
                </SwiperSlide>
              ))}
          </Swiper>

          <div
            className={`hidden md:flex m-4 border-2 dark:border-white border-black bg-[#ffe2e254] rounded-full p-4 w-8 h-8 flex justify-center items-center cursor-pointer navigate_next_10 cursor-pointer`}
            id={`navigate_prev_10`}
          >
            <ChevronRightOutlinedIcon className='dark:!text-white !text-black' />
          </div>
        </div>
      </Container>
    </>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { MatchScore } from '../MatchScore'
import { useParams } from 'react-router-dom'
import {
  IS_SCORE_SOCKET_ENABLED,
  MARKET_INTERNAL_STATUS_CALL_LOGIN,
  URLType,
  makeGetHttpCallAsync,
} from '../../../../../api'
import { SAVE_SCORE } from '../../../../../redux/reducers/userReducer/ScoreReducer'
import { useEffect, useRef, useState } from 'react'
import { decrypt } from '../../../../../api/authHelper'

export const GameScoreDetail = (props: {
  isScoreCardShow: boolean
  setIsScoreCardShow: any
}) => {
  const { id } = useParams()
  const [decryptedId, setDecryptedId] = useState(decrypt(id))

  const { selectedEvent, useSocket } = useSelector(
    (state: any) => state.appConfiguration,
  )
  const { scoreMatches } = useSelector((state: any) => state.inPlayMatches)
  const dispatch = useDispatch()
  const scoreIntervalinRef = useRef<number | null>(null)

  useEffect(() => {
    setDecryptedId(decrypt(id))
    if (scoreIntervalinRef.current) {
      clearInterval(scoreIntervalinRef.current)
    }

    dispatch({
      type: SAVE_SCORE,
      scoreData: [],
      isLoading: false,
    })

    if (
      !!decryptedId &&
      scoreMatches[decryptedId!] &&
      selectedEvent.eventTypeId
    ) {
      getScore(selectedEvent.eventTypeId)
    }
  }, [id])

  useEffect(() => {
    if (!useSocket && !IS_SCORE_SOCKET_ENABLED) {
      if (!!decryptedId && !!scoreMatches[decryptedId!]) {
        const intervalId = setInterval(
          () => getScore(selectedEvent.eventTypeId),
          MARKET_INTERNAL_STATUS_CALL_LOGIN,
        )
        scoreIntervalinRef.current = intervalId as unknown as number
      }
    }
    dispatch({
      type: SAVE_SCORE,
      scoreData: [],
      isLoading: false,
    })

    return () => {
      if (scoreIntervalinRef.current) {
        clearInterval(scoreIntervalinRef.current)
      }
    }
  }, [scoreMatches])

  const { scoreData } = useSelector((state: any) => state.score)

  const getScore = (eventTypeId: string) => {
    makeGetHttpCallAsync(
      dispatch,
      'api/common/game/market/scoreDirect',
      `eventId=${decryptedId}&eventTypeId=${eventTypeId}`,
      URLType.master,
      '',
      true,
    )
      .then((res: any) => {
        let dataLocal = res.docs
        let scoreData = {
          eventId: decryptedId,
          score: dataLocal.data,
          iframeUrl: dataLocal.iframeUrl,
        }

        if (scoreData) {
          dispatch({
            type: SAVE_SCORE,
            scoreData: scoreData,
            isLoading: false,
          })
        }
      })
      .catch(() => {
        dispatch({
          type: SAVE_SCORE,
          scoreData: [],
          isLoading: false,
        })
      })
  }

  return (
    <>
      {scoreMatches[decryptedId!] && (
        <MatchScore
          isScoreCardShow={props.isScoreCardShow}
          setIsScoreCardShow={props.setIsScoreCardShow}
          eventTypeId={selectedEvent.eventTypeId}
          scoreData={scoreData}
          iframe={scoreData.iframeUrl}
        />
      )}
    </>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { SportsContentItem } from '../../Sports/content/SportsContentItem'
import { useEffect, useRef, useState } from 'react'
import {
  MARKET_STATUS_CALL_LOGIN,
  MARKET_STATUS_CALL_NON_LOGIN,
  URLType,
  makeGetHttpCallAsync,
} from '../../../api'
import { ADD_SPORTS_MARKET_MARKET_VALUE } from '../../../redux/actions/marketStatusAction'

interface IInPlaySportsContent {
  isDrawerOpen: boolean
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
  isMaketShown: any
  currentDomain: any
  deleteFavoriteEvent: any
  eventFavoriteList: any
  addFavoriteEventList: any
  updateOpenLoginScreen: any
  currentBet: any
  onMarketClick: any
  isLogin: boolean
  isUpComing: boolean
  onGoing: boolean
  isPopUpOpen: boolean
  marketStatus: any
}

export const InPlaySportsContent = (props: IInPlaySportsContent) => {
  const { marketIds, highlightMarketIds, fastestOddMarketIds } = useSelector(
    (state: any) => state.marketStatus,
  )
  const [tempStatus, setTempStatus] = useState<any>()

  const intervalIdRef = useRef<any>(null)
  const dispatch = useDispatch()

  const getStatus = () => {
    let marketIdsLocal = marketIds + fastestOddMarketIds

    if (highlightMarketIds && props.isLogin) {
      marketIdsLocal += highlightMarketIds
    }

    marketIdsLocal &&
      makeGetHttpCallAsync(
        dispatch,
        'api/common/game/market/status',
        `marketId=${marketIdsLocal}`,
        URLType.master,
        false,
        false,
        '',
      ).then((res: any) => {
        const marketIdMap = new Map()

        res.docs.forEach((doc: any) => {
          const marketId = doc.marketId._id
          const object = doc
          marketIdMap.set(marketId, object)
        })
        dispatch({
          type: ADD_SPORTS_MARKET_MARKET_VALUE,
          data: res.docs,
        })
        setTempStatus(marketIdMap)
      })
  }

  useEffect(() => {
    intervalIdRef.current = setInterval(
      getStatus,
      props.isLogin ? MARKET_STATUS_CALL_LOGIN : MARKET_STATUS_CALL_NON_LOGIN,
    )
    return () => {
      clearInterval(intervalIdRef.current)
    }
  }, [marketIds, highlightMarketIds, fastestOddMarketIds])

  return (
    <>
      <SportsContentItem
        tempStatus={tempStatus}
        onGoing={props.onGoing}
        isDrawerOpen={props.isDrawerOpen}
        setIsDrawerOpen={props.setIsDrawerOpen}
        isUpComing={props.isUpComing}
        isMaketShown={props.isMaketShown}
        currentDomain={props.currentDomain}
        deleteFavoriteEvent={props.deleteFavoriteEvent}
        eventFavoriteList={props.eventFavoriteList}
        addFavoriteEventList={props.addFavoriteEventList}
        updateOpenLoginScreen={props.updateOpenLoginScreen}
        isPopUpOpen={props.isPopUpOpen}
        currentBet={props.currentBet}
        onMarketClick={props.onMarketClick}
        isLogin={props.isLogin || false}
        marketStatus={props.marketStatus}
      />
    </>
  )
}

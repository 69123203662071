import { IUserLogin } from '../../types/interfaces/login'
import { GET_BRANCH_DETAILS } from '../type'

const initialState: IBranchInitial = {
  branchData: [],
  getBranchDataLoading: false,
}

export interface IBranchInitial {
  branchData: any
  getBranchDataLoading: boolean
}

export interface ISetBranch {
  type: string
  data: IUserLogin
  loading: boolean
}

export default function (state = initialState, action: ISetBranch) {
  switch (action.type) {
    case GET_BRANCH_DETAILS: {
      return {
        ...state,
        branchData: action.data,
        getBranchDataLoading: action.loading,
      }
    }

    default:
      return state
  }
}

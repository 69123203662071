import { useEffect, useState } from 'react'

interface ISoccerScore {
  scoreData: any
}

export const SoccerScore = (props: ISoccerScore) => {
  const [scoreData, setScoreData] = useState(props.scoreData?.score)

  useEffect(() => {
    if (props.scoreData && props.scoreData.score) {
      setScoreData(props.scoreData?.score)
    }
  }, [props.scoreData?.score])

  return (
    <>
      <div className='text-white'>
        <div>
          {!!scoreData &&
            scoreData.length &&
            scoreData.map((score: any) => (
              <div className='pb-2 dark:text-white text-black'>
                <div>
                  <div className='flex mb-1 justify-between'>
                    <div className='text-[15px] score-blinking p-5 bold'>
                      {score.matchStatus}
                    </div>
                    <div style={{ width: '15rem' }}>
                      <div
                        style={{
                          background: '#bb1d34',
                          borderRadius: '0.5rem',
                        }}
                        className='bold pl-5 pr-5 flex mb-2 justify-between'
                      >
                        <div>{score.score.home.name} </div>
                        <div>{score.score.home.score} </div>
                      </div>
                      <div
                        style={{
                          background: '#bb1d34',
                          borderRadius: '0.5rem',
                        }}
                        className='bold pl-5 pr-5 flex mb-2 justify-between'
                      >
                        <div>{score.score.away.name} </div>
                        <div>{score.score.away.score} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

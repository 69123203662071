interface Odds {
  type: string
  run: number
  odd: number
  teamId?: string
}

interface Market {
  _id: string
  id: string
  event: any
  eventName: string
  name: string
  marketName: string
  eventId: string
  matchDate: string
  type: string
  manual: boolean
  teams: any[] // You might want to define a type for the 'teams' property if possible
}

interface User {
  browserDetail: {
    name: string
    version: string
    os: string
    mobile: boolean
  }
  _id: string
  userName: string
  ip: string
  nation: string
  createdBy: string
  whiteLabelId: string
  parents: string[]
}

interface OrganizedData {
  eventId: string
  eventName: string
  market: any[]
}

interface InputItem {
  user: User
  market: Market
  odds: Odds
  _id: string
  stake: number
  profit: number
  liability: number
  createdAt: string
  updatedAt: string
}

export function organizeDataAverage(inputArray: InputItem[]): OrganizedData[] {
  // Create a map to store events based on eventId
  const eventsMap = new Map<string, OrganizedData>()

  // Iterate through the input array
  inputArray &&
    inputArray.forEach((item) => {
      const { event } = item.market
      const marketId = item.market.id

      // Check if the event is already in the map
      if (!eventsMap.has(event.id)) {
        // If not, create a new entry for the event
        eventsMap.set(event.id, {
          eventId: event.id,
          market: [],
          eventName: event.name,
        })
      }

      // Check if the marketId is already in the event
      const eventEntry = eventsMap.get(event.id)
      const marketEntry = eventEntry!.market.find(
        (market) => market.marketId === marketId,
      )

      if (!marketEntry) {
        // If not, create a new entry for the market
        eventEntry!.market.push({
          marketId: marketId,
          markets: [],
        })
      }

      // Add the item to the appropriate market
      eventEntry!.market
        .find((market) => market.marketId === marketId)
        .markets.push(item)
    })

  let res: OrganizedData[] = []

  eventsMap.forEach((event) => {
    event.market.forEach((marketData) => {
      let tmpMarketMap = new Map<string, InputItem>()

      marketData.markets.forEach((marketItem: any) => {
        const key =
          marketItem.odds.type === 'session'
            ? marketItem.odds.type
            : `${marketItem.odds.teamId}/${marketItem.odds.type}`

        if (tmpMarketMap.has(key)) {
          const existingItem = tmpMarketMap.get(key)!
          existingItem.stake += marketItem.stake
          existingItem.profit += marketItem.profit
          existingItem.liability += marketItem.liability
        } else {
          tmpMarketMap.set(key, { ...marketItem })
        }
      })

      marketData.markets = Array.from(tmpMarketMap.values())
    })

    res.push({
      eventId: event.eventId,
      market: event.market,
      eventName: event.eventName,
    })
  })

  return res
}

export function organizeData(inputArray: InputItem[]): OrganizedData[] {
  const result: OrganizedData[] = []

  // Create a map to store events based on eventId
  const eventsMap = new Map<string, OrganizedData>()

  // Iterate through the input array
  inputArray &&
    inputArray.forEach((item) => {
      const { event } = item.market
      const marketId = item.market.id

      // Check if the event is already in the map
      if (!eventsMap.has(event.id)) {
        // If not, create a new entry for the event
        eventsMap.set(event.id, {
          eventId: event.id,
          market: [],
          eventName: event.name,
        })
      }

      // Check if the marketId is already in the event
      const eventEntry = eventsMap.get(event.id)
      const marketEntry = eventEntry!.market.find(
        (market) => market.marketId === marketId,
      )

      if (!marketEntry) {
        // If not, create a new entry for the market
        eventEntry!.market.push({
          marketId: marketId,
          markets: [],
        })
      }

      // Add the item to the appropriate market
      eventEntry!.market
        .find((market) => market.marketId === marketId)
        .markets.push(item)
    })

  // Sort the markets based on createdAt in descending order
  eventsMap.forEach((event) => {
    event.market.forEach((market) => {
      market.markets.sort(
        (
          a: { createdAt: string | number | Date },
          b: { createdAt: string | number | Date },
        ) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )
    })

    result.push(event)
  })

  return result
}

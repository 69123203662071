import { toast } from 'react-toastify'

export const CopyTextIcon = (props: { message: string }) => {
  const handleCopyValue = () => {
    navigator.clipboard.writeText(props.message)
    toast.success(`${_getLocalString('LOC_COPIED')} ${props.message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    })
  }

  return (
    <>
      <div
        className='ml-5 dark:text-[var(--theme-color)] text-[var(--theme-color)] cursor-pointer'
        onClick={() => handleCopyValue()}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M14.1111 0H4.35731C3.31893 0 2.47418 0.847289 2.47418 1.8888V2.4736H1.88314C0.844746 2.4736 0 3.32089 0 4.36298V14.1112C0 15.1527 0.844746 16 1.88314 16H11.6373C12.679 16 13.5263 15.1527 13.5263 14.1112V13.5264H14.1111C15.1529 13.5264 16.0005 12.6791 16.0005 11.637V1.88874C16.0005 0.847289 15.1529 0 14.1111 0ZM12.3703 14.1111C12.3703 14.5153 12.0415 14.8439 11.6373 14.8439H1.88314C1.48232 14.8439 1.15608 14.5153 1.15608 14.1111V4.36292C1.15608 3.95876 1.48238 3.62962 1.88314 3.62962H11.6373C12.0414 3.62962 12.3703 3.9587 12.3703 4.36292V14.1111ZM14.8444 11.637C14.8444 12.0411 14.5156 12.3703 14.1111 12.3703H13.5263V4.36292C13.5263 3.32089 12.679 2.47354 11.6372 2.47354H3.6302V1.88874C3.6302 1.48458 3.9565 1.15602 4.35726 1.15602H14.1111C14.5155 1.15602 14.8444 1.48458 14.8444 1.88874V11.637Z'
            fill='currentColor'
          />
        </svg>
      </div>
    </>
  )
}

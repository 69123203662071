import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from '../../../redux/type'
import { MainContent } from './Content/MainContent'

const mapStateToProps = (state: AppState) => {
  const isLogin = state.login.isLogin
  const userData = state.login.userData

  return {
    isLogin,
    userData,
  }
}

const NewConnectedEvents: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  {},
)(MainContent as any)

export default NewConnectedEvents

import { useSelector } from 'react-redux'
import { getAssetUrl } from '../../../../utils'
import { Container } from '@mui/material'
import { IEnable } from '../../../../types/interfaces/themeConfiguration'
import { IAdvertisement } from './Advertise1'

export const Advertise2 = (props: IAdvertisement) => {
  const advertisementData = useSelector(
    (state: any) => state.appThemeConfiguration.advertisement2,
  )
  const enableFeatures: IEnable = useSelector(
    (state: any) => state.appThemeConfiguration.enable,
  )

  const handleButtonClick = () => {
    !props.isLogin && props.updateOpenLoginScreen(true)
  }

  return (
    <>
      {enableFeatures?.isAdvertisement && (
        <div
          style={{
            background: `linear-gradient(130deg, var(--advertisement2-backgroundFirst), var(--advertisement2-backgroundSecond), var(--advertisement2-backgroundThird), var(--advertisement2-backgroundFourth)),url(--advertisement2-backgroundUrl)`,
          }}
          className={`mb-10 relative z-40 !bg-no-repeat !bg-right !bg-cover md:h-[430px]  h-[250px]`}
        >
          <Container fixed maxWidth={'xl'} className=' !h-full  relative'>
            <div className='absolute md:block hidden md:bottom-0 bottom-12 right-0 z-50  md:h-[467px]  md:w-[311px]'>
              <img
                alt='Advertisement Man'
                src={getAssetUrl('advertise_cta/man.webp')}
              />
            </div>
            <div
              className={` h-full md:w-[300px] w-[175px]  !bg-no-repeat md:!bg-contain !bg-300 md:!bg-right absolute !bg-bottom right-0 bottom-0 z-10`}
            ></div>
            <div
              style={{
                backgroundImage: `url(${getAssetUrl('advertise_cta/bg-adv_2.webp')})`,
              }}
              className={` h-[161px] md:w-[476px] md:h-[476px] -z-20 w-[161px] !bg-no-repeat !bg-contain md:!bg-right !bg-right-bottom absolute md:right-[120px]  right-0 md:bottom-0 bottom-4`}
            ></div>
            <div
              style={{
                backgroundImage: `url(${getAssetUrl('advertise_cta/arrow_1.svg')})`,
              }}
              className={` md:!h-[300px] !h-[56px] md:w-[200px] w-[69px] !bg-no-repeat !bg-contain !bg-right absolute md:left-[46%] left-[42%]  z-10 md:bottom-7 bottom-10`}
            ></div>
            <div className='relative z-20 flex flex-col h-full md:justify-center'>
              <div className=' md:text-4xl text-[18px] mb-3   text-[#EBFF00] font-bold font-Lobster md:pt-0 pt-10'>
                {advertisementData.title}
              </div>
              <div className='md:text-6xl text-[27px] mb-3 leading-none   tracking-widest md:font-bold font-normal text-white font-urban md:max-w-[750px] max-w-[350px] '>
                {advertisementData.description}
              </div>
              <div
                className='flex gap-5 mt-3 md:mt-5'
                onClick={handleButtonClick}
              >
                <button
                  aria-label='advertisement button'
                  className='bg-black rounded-[10px] py-3 px-5 md:w-60 w-[122px] font-bebas md:text-2xl text-[15px] tracking-wider'
                >
                  {advertisementData!.button?.text}
                </button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

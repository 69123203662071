import { toast } from 'react-toastify'
import { HEADERS, makePostHttpCallAsync } from '../../../api'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import {
  generateRandom10DigitNumber,
  generateRandomUserName,
} from '../../../utils'
import {
  FIRST_TIME_LOGIN,
  SET_IS_LOGIN,
  SET_IS_LOGIN_LOADER,
} from '../loginAction'
import { DEMO_LOGIN } from '../../reducers/demoLoginReducer'

interface IDemoLogin {
  dispatch: any
  currentDomain: ICurrentDomain
  cb: () => void
}

export const demoLogin = (props: IDemoLogin) => {
  let id = generateRandomUserName()
  props.dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: true })
  props.dispatch({ type: DEMO_LOGIN, payload: true, delayTime: 10 })
  let formData = new URLSearchParams()
  formData.append('userName', `${id}`)
  formData.append('email', `${id}@gmail.com`)
  formData.append('phone', `${generateRandom10DigitNumber()}`)
  formData.append('fullName', 'Demo')
  formData.append('password', 'Test@123')
  formData.append('demoWhiteLabelId', props.currentDomain.whitelabelId)

  makePostHttpCallAsync(
    props.dispatch,
    'api/auth/signup/demo',
    props.currentDomain.type,
    formData,
    false,
    false,
    'application/x-www-form-urlencoded',
  )
    .then(() => {
      let formData = new URLSearchParams()
      formData.append('email', id)
      formData.append('password', 'Test@123')
      formData.append('whitelabelId', props.currentDomain.whitelabelId)

      makePostHttpCallAsync(
        props.dispatch,
        'api/auth',
        props.currentDomain.type,
        formData,
        false,
        false,
        'application/x-www-form-urlencoded',
      )
        .then((response: any) => {
          props.dispatch({
            type: FIRST_TIME_LOGIN,
            payload: true,
          })

          props.dispatch({
            type: SET_IS_LOGIN,
            isLogin: response.docs,
          })
          localStorage.setItem(HEADERS.userType, 'user')
          props.cb && props.cb()
          props.dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
        })
        .catch(() => {
          toast.error(_getLocalString('LOC_SOMETHING_WENT_WRONG'))
          props.dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
        })
    })
    .catch(() => {
      toast.error(_getLocalString('LOC_SOMETHING_WENT_WRONG'))
      props.dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
    })
}

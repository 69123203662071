import { IUserLogin } from '../../types/interfaces/login'
import { GET_BANK_DETAILS } from '../actions'

const initialState: IBankInitial = {
  bankData: [],
  getBankDataLoading: false,
}

export interface IBankInitial {
  bankData: any
  getBankDataLoading: boolean
}

export interface ISetBank {
  type: string
  data: IUserLogin
  loading: boolean
}

export default function (state = initialState, action: ISetBank) {
  switch (action.type) {
    case GET_BANK_DETAILS: {
      return {
        ...state,
        bankData: action.data,
        getBankDataLoading: action.loading,
      }
    }

    default:
      return state
  }
}

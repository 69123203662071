import {
  UPDATE_USER_BET,
  UPDATE_USER_CURRENT_BET,
} from '../../actions/administratorAction/UserBetAction'

interface IUserBetAction {
  userBet: any
  currentUserBet: any[]
}

export function UserBetSlipReducer(
  state = initialState,
  action: IUserBetAction & { type: string; userBet: any },
) {
  switch (action.type) {
    case UPDATE_USER_BET:
      return {
        ...state,
        userBet: { ...Array.from(action.userBet) },
      }

    case UPDATE_USER_CURRENT_BET:
      return {
        ...state,
        currentUserBet: action.userBet,
      }

    default:
      return state
  }
}

const initialState: IUserBetAction = {
  userBet: {},
  currentUserBet: [],
}

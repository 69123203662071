import { GetReduxStateFn } from '../..'
import { Dispatch } from '@reduxjs/toolkit'
import { IMarketStatus } from '../../../types/interfaces/exchangeMarketStatus'

export const REMOVED_MARKET = '@MARKET/REMOVED_MARKET'

export const removeMarket = (marketStatus: IMarketStatus) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const gameManagementCommon = getState().gameManagementCommon.removedMarket
    dispatch({
      type: REMOVED_MARKET,
      data: [...gameManagementCommon, marketStatus.marketId.id],
    })
  }
}

import { useEffect, useState } from 'react'
import { NavBarTileMobile } from '../../pages/landing/component/Content/NavBarTileMobile'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux'
import { USER_TYPES_ALL_OBJ } from '../../redux/type/AppConstant'

interface INavBarTile {
  isDarkMode?: boolean
  userData: any
  currentDomain: any
  isUserOrNotLoggedIn?: boolean
  onClickHandlerClose: () => void
}

export const NavTiles2Mobile = (props: INavBarTile) => {
  const [isOpen, setIsOpen] = useState<string>('')
  const { sportsThirdPartyData, sportsCommonData } = useSelector(
    (state: AppState) => {
      return {
        sportsThirdPartyData:
          state.gameManagementThirdParty.gamesThirdPartyData,
        getSportsThirdPartyLoading:
          state.gameManagementThirdParty.getGamesThirdPartyDataLoading,
        sportsCommonData: state.gameManagementCommon.gamesCommonData,
      }
    },
  )

  useEffect(() => {
    if (!sportsCommonData || !sportsCommonData.length) {
      return
    }
  }, [sportsThirdPartyData])

  const getAccountForUser = () => {
    let accountList = [
      { name: 'Account List', link: '/account-list' },
      { name: 'Request List', link: '/request-accounts' },
      { name: 'UTR Form', link: '/utr-form' },
    ]

    if (
      props.userData?.userType === USER_TYPES_ALL_OBJ.whiteLabel &&
      props.currentDomain.type === 'b2c'
    ) {
      accountList.push({
        name: 'Create Branch',
        link: '/branch?createBranch=true',
      })
    } else {
      accountList.push({ name: 'Create Account', link: '/create' })
    }

    return accountList
  }

  const getReports = () => {
    const reports = [
      { name: _getLocalString('LOC_ACCOUNT_STATEMENT'), link: '/accounts' },
    ]

    if (props.currentDomain.type === 'b2c') {
      reports.push({
        name: 'Deposit & WithDraw Report',
        link: '/deposit-withdraw-report',
      })
    }

    if (
      props.userData.userType !== 'whiteLable' &&
      props.userData.userType !== 'administrator'
    ) {
      reports.push({ name: 'User Report', link: '/user-report' })
    }

    if (props.userData.userType === 'administrator') {
      reports.push({ name: 'Commision Report', link: '/commision-report' })
    }

    let reportsLocal = [
      { name: 'Open Bets', link: '/open-bets' },
      { name: 'Bets History', link: '/bet-history' },
      { name: 'Fraud Report', link: '/fraud-report' },
    ]

    reports.push(...reportsLocal)

    return reports
  }

  return (
    <div className={`flex flex-col gap-0 `}>
      {props.currentDomain.type === 'b2c' && (
        <NavBarTileMobile
          name={_getLocalString('LOC_B2C_DASHBOARD')}
          onClickHandlerClose={props.onClickHandlerClose}
          link='/dashboard'
          icon={`reports`}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <NavBarTileMobile
        name={_getLocalString('LOC_MARKET_ANALYSIS')}
        onClickHandlerClose={props.onClickHandlerClose}
        link='/market-analysis'
        icon={`market`}
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {props.userData?.userType === USER_TYPES_ALL_OBJ.administrator && (
        <NavBarTileMobile
          name={_getLocalString('LOC_MULTI_LOGIN_ACCOUNT')}
          icon={`user-arrow`}
          onClickHandlerClose={props.onClickHandlerClose}
          link='/multi-account-login'
          isDarkMode={props.isDarkMode}
          isSelected={true}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <NavBarTileMobile
        name={_getLocalString('LOC_ACCOUNT')}
        icon={`account`}
        onClickHandlerClose={props.onClickHandlerClose}
        isDarkMode={props.isDarkMode}
        link=''
        children={getAccountForUser()}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <NavBarTileMobile
        name={_getLocalString('LOC_BANK')}
        icon={`bank`}
        onClickHandlerClose={props.onClickHandlerClose}
        link='/bank'
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <NavBarTileMobile
        name={_getLocalString('LOC_REPORTS')}
        icon={`reports`}
        onClickHandlerClose={props.onClickHandlerClose}
        link=''
        children={getReports()}
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      {props.userData?.userType === USER_TYPES_ALL_OBJ.administrator && (
        <NavBarTileMobile
          name={_getLocalString('LOC_ACTIONS')}
          icon={`reports`}
          onClickHandlerClose={props.onClickHandlerClose}
          link=''
          children={[
            {
              name: _getLocalString('LOC_DELETE_OPEN_BETS'),
              link: '/delete-open-bets',
            },
            {
              name: _getLocalString('LOC_DELETE_BET_HISTORY'),
              link: '/delete-bet-history',
            },
            { name: _getLocalString('LOC_MODIFY_USER'), link: '/modify-user' },
            {
              name: _getLocalString('LOC_MODIFY_SETTING'),
              link: '/modify-setting',
            },
            {
              name: _getLocalString('LOC_MODIFY_ALL_WHITELABLE'),
              link: '/modify-whiteLabel',
            },
            {
              name: 'Modify Betfair Keys',
              link: '/modify-betfair-keys',
            },
          ]}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {props.userData?.userType !== USER_TYPES_ALL_OBJ.administrator && (
        <NavBarTileMobile
          name={_getLocalString('LOC_ACTIONS')}
          icon={`reports`}
          onClickHandlerClose={props.onClickHandlerClose}
          link=''
          children={[
            { name: _getLocalString('LOC_MODIFY_USER'), link: '/modify-user' },
          ]}
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {props.userData?.userType === USER_TYPES_ALL_OBJ.administrator && (
        <NavBarTileMobile
          name={_getLocalString('LOC_GAMES')}
          icon={'games'}
          isDarkMode={props.isDarkMode}
          onClickHandlerClose={props.onClickHandlerClose}
          children={[
            { name: _getLocalString('LOC_GAME_LIST'), link: '/games' },
            {
              name: _getLocalString('LOC_GAME_MANAGEMENT'),
              link: '/game-management',
            },
            { name: _getLocalString('LOC_CASINO_LIST'), link: '/casino-list' },
          ]}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {(props.userData?.userType === USER_TYPES_ALL_OBJ.administrator ||
        (props.userData?.userType === USER_TYPES_ALL_OBJ.whiteLabel &&
          'b2c' === props?.currentDomain.type)) && (
        <NavBarTileMobile
          name={_getLocalString('LOC_THEME_EDITOR')}
          icon={`themeEditor`}
          onClickHandlerClose={props.onClickHandlerClose}
          link='/edit-theme'
          isDarkMode={props.isDarkMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {props.userData?.userType !== 'user' &&
        props.userData?.userType !== 'administrator' &&
        props.currentDomain.type === 'b2c' && (
          <NavBarTileMobile
            name={_getLocalString('LOC_TYPE_LIST')}
            icon={`bank`}
            onClickHandlerClose={props.onClickHandlerClose}
            link=''
            children={[
              {
                name: _getLocalString('LOC_DEPOSIT_TYPE'),
                link: '/deposit-types',
              },
              {
                name: _getLocalString('LOC_WITHDRAW_TYPE'),
                link: '/withdraw-types',
              },
            ]}
            isDarkMode={props.isDarkMode}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}

      <NavBarTileMobile
        name={_getLocalString('LOC_EVENTS')}
        icon={`events`}
        onClickHandlerClose={props.onClickHandlerClose}
        link=''
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        children={[
          { name: _getLocalString('LOC_LIVE_CASINO'), link: '/live-casino' },
          { name: _getLocalString('LOC_CRASH_GAME'), link: '/crash-games' },
          { name: _getLocalString('LOC_VIRTUAL_GAME'), link: '/virtual-game' },
          { name: _getLocalString('LOC_SLOT_GAMES'), link: '/slot-games' },
          { name: _getLocalString('LOC_LOTTERY'), link: '/lottery' },
        ]}
        setIsOpen={setIsOpen}
        showSportTiles={true}
      />
    </div>
  )
}

import { Dispatch } from '@reduxjs/toolkit'
import {
  makeDeleteHttpCallAsync,
  makeGetHttpCallAsync,
  makePatchHttpCallAsync,
  makePostHttpCallAsync,
  makePutHttpCallAsync,
} from '../../api/httpMethod'
import { URLType } from '../../api'
import { toast } from 'react-toastify'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { GetReduxStateFn } from '..'

export const GET_SPORTS_DATA = '@ADMIN/GET_SPORTS_DATA'
export const GET_CASINO_GAMES_DATA = '@ADMIN/GET_CASINO_GAMES_DATA'
export const GET_CASINO_TOTAL_GAMES_ID_DATA =
  '@ADMIN/GET_CASINO_TOTAL_GAMES_ID_DATA'
export const GET_CASINO_LIVE_CASINO_GAMES_DATA =
  '@ADMIN/GET_CASINO_LIVE_CASINO_GAMES_DATA'
export const GET_CASINO_VIRTUAL_CASINO_GAMES_DATA =
  '@ADMIN/GET_CASINO_VIRTUAL_CASINO_GAMES_DATA'
export const GET_CASINO_SLOT_GAMES_DATA = '@ADMIN/GET_CASINO_SLOT_GAMES_DATA'
export const GET_CRASH_GAMES_DATA = '@ADMIN/GET_CRASH_GAMES_DATA'
export const CASINO_LOADED = '@ADMIN/CASINO_LOADED'
export const GET_LOTTERY_GAMES_DATA = '@ADMIN/GET_LOTTERY_GAMES_DATA'
export const GET_ROYAL_CASINO_GAMES_DATA = '@ADMIN/GET_ROYAL_CASINO_GAMES_DATA'
export const GET_COMPETITION_DATA = '@ADMIN/GET_COMPETITION_DATA'
export const GET_EVENTS_DATA = '@ADMIN/GET_EVENTS_DATA'
export const GET_MARKET_DATA = '@ADMIN/GET_MARKET_DATA'
export const SAVE_GAME = '@ADMIN/SAVE_GAME'
export const ASSIGN_TO = '@ADMIN/ASSIGN_TO'
export const EUROPIAN_GAME = new Map([['All Bet', true]])

const DEFAULT_GAMES = [
  {
    eventType: {
      id: '1',
      name: 'Soccer',
    },
    marketCount: 6713,
  },
  {
    eventType: {
      id: '2',
      name: 'Tennis',
    },
    marketCount: 1698,
  },
  {
    eventType: {
      id: '3',
      name: 'Golf',
    },
    marketCount: 8,
  },
  {
    eventType: {
      id: '4',
      name: 'Cricket',
    },
    marketCount: 374,
  },
  {
    eventType: {
      id: '5',
      name: 'Rugby Union',
    },
    marketCount: 78,
  },
  {
    eventType: {
      id: '1477',
      name: 'Rugby League',
    },
    marketCount: 50,
  },
  {
    eventType: {
      id: '6',
      name: 'Boxing',
    },
    marketCount: 49,
  },
  {
    eventType: {
      id: '7',
      name: 'Horse Racing',
    },
    marketCount: 448,
  },
  {
    eventType: {
      id: '8',
      name: 'Motor Sport',
    },
    marketCount: 6,
  },
  {
    eventType: {
      id: '27454571',
      name: 'Esports',
    },
    marketCount: 28,
  },
  {
    eventType: {
      id: '10',
      name: 'Special Bets',
    },
    marketCount: 15,
  },
  {
    eventType: {
      id: '998917',
      name: 'Volleyball',
    },
    marketCount: 1,
  },
  {
    eventType: {
      id: '11',
      name: 'Cycling',
    },
    marketCount: 3,
  },
  {
    eventType: {
      id: '2152880',
      name: 'Gaelic Games',
    },
    marketCount: 28,
  },
  {
    eventType: {
      id: '6422',
      name: 'Snooker',
    },
    marketCount: 3,
  },
  {
    eventType: {
      id: '7511',
      name: 'Baseball',
    },
    marketCount: 13,
  },
  {
    eventType: {
      id: '6423',
      name: 'American Football',
    },
    marketCount: 3,
  },
  {
    eventType: {
      id: '451485',
      name: 'Winter Sports',
    },
    marketCount: 3,
  },
  {
    eventType: {
      id: '7522',
      name: 'Basketball',
    },
    marketCount: 137,
  },
  {
    eventType: {
      id: '7524',
      name: 'Ice Hockey',
    },
    marketCount: 109,
  },
  {
    eventType: {
      id: '61420',
      name: 'Australian Rules',
    },
    marketCount: 92,
  },
  {
    eventType: {
      id: '468328',
      name: 'Handball',
    },
    marketCount: 33,
  },
  {
    eventType: {
      id: '3503',
      name: 'Darts',
    },
    marketCount: 43,
  },
  {
    eventType: {
      id: '26420387',
      name: 'Mixed Martial Arts',
    },
    marketCount: 21,
  },
  {
    eventType: {
      id: '4339',
      name: 'Greyhound Racing',
    },
    marketCount: 878,
  },
  {
    eventType: {
      id: '2378961',
      name: 'Politics',
    },
    marketCount: 45,
  },
]

export const getSportsData = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_SPORTS_DATA, loading: true })

      makeGetHttpCallAsync(
        dispatch,
        'api/administrator/eventtype',
        '',
        URLType.master,
        '',
        true,
      )
        .then((response: any) => {
          dispatch({
            type: GET_SPORTS_DATA,
            loading: false,
            data: response?.docs,
          })
        })
        .catch(async (error: any) => {
          let err = await error
          dispatch({
            type: GET_SPORTS_DATA,
            data: DEFAULT_GAMES,
            loading: false,
          })
        })
    } catch (error: any) {
      dispatch({ type: GET_SPORTS_DATA, data: DEFAULT_GAMES, loading: false })
    }
  }
}

export const getCompetitionData = (payload: any | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_COMPETITION_DATA, loading: true })

      makePostHttpCallAsync(
        dispatch,
        'api/administrator/competition',
        URLType.master,
        JSON.stringify(payload),
        '',
        true,
      )
        .then((response: any) => {
          dispatch({
            type: GET_COMPETITION_DATA,
            loading: false,
            data: response?.docs || [],
          })
        })
        .catch(async (error: any) => {
          let err = await error
          dispatch({ type: GET_COMPETITION_DATA, loading: false })
        })
    } catch (error: any) {
      dispatch({ type: GET_COMPETITION_DATA, loading: false })
    }
  }
}

export const getEventsData = (payload: any | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_EVENTS_DATA, loading: true })

      makePostHttpCallAsync(
        dispatch,
        'api/administrator/event',
        URLType.master,
        JSON.stringify(payload),
        '',
        true,
      )
        .then((response: any) => {
          dispatch({
            type: GET_EVENTS_DATA,
            loading: false,
            data: response?.docs || [],
          })
        })
        .catch(async (error: any) => {
          let err = await error
          dispatch({ type: GET_EVENTS_DATA, loading: false })
        })
    } catch (error: any) {
      dispatch({ type: GET_EVENTS_DATA, loading: false })
    }
  }
}

export const getMarketData = (payload: any | undefined) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_MARKET_DATA, loading: true })
      makePostHttpCallAsync(
        dispatch,
        'api/administrator/market',
        URLType.master,
        JSON.stringify(payload),
        '',
        true,
      )
        .then((response: any) => {
          dispatch({
            type: GET_MARKET_DATA,
            loading: false,
            data: response?.docs || [],
          })
        })
        .catch(async (error: any) => {
          let err = await error
          dispatch({ type: GET_MARKET_DATA, loading: false })
        })
    } catch (error: any) {
      dispatch({ type: GET_MARKET_DATA, loading: false })
    }
  }
}

export const saveGame = (payload: any | undefined) => {
  return async (dispatch: Dispatch) => {
    const dispatchType = SAVE_GAME
    try {
      dispatch({ type: dispatchType, loading: true })
      makePostHttpCallAsync(
        dispatch,
        'api/administrator/game/market',
        URLType.master,
        JSON.stringify(payload),
        '',
        true,
        '',
        true,
        'Market Added Successfully',
      )
        .then((response: any) => {
          dispatch({ type: dispatchType, loading: false })
        })
        .catch(async (error: any) => {
          let err = await error
          toast.error(err?.message)
          dispatch({ type: dispatchType, loading: false })
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

export const assignToManual = (payload: any | undefined) => {
  return async (dispatch: Dispatch) => {
    const dispatchType = ASSIGN_TO
    try {
      dispatch({ type: dispatchType, loading: true })

      makePatchHttpCallAsync(
        'api/administrator/game/market',
        JSON.stringify(payload),
        '',
        URLType.master,
        '',
        true,
      )
        .then((response: any) => {
          dispatch({ type: dispatchType, loading: false })
        })
        .catch(async (error: any) => {
          let err = await error
          dispatch({ type: dispatchType, loading: false })
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

export const getRoyalCasinoGames = (payload: any, currentDomain: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({
        type: GET_ROYAL_CASINO_GAMES_DATA,
        payload: [],
        pagination: { totalPages: 1 },
        loading: true,
      })
      makeGetHttpCallAsync(
        dispatch,
        'api/administrator/game/royalGaming',
        '',
        currentDomain.type,
        '',
        true,
      ).then((res: any) => {
        const casinoTotalGamesId = getState().adminGames.casinoTotalGamesId
        var result = casinoTotalGamesId.reduce(function (map: any, obj: any) {
          map[obj] = true
          return map
        }, {})

        const docsRoyalCasino = filterObjectsNotInHashmap(res.docs.data, result)
        dispatch({
          type: GET_ROYAL_CASINO_GAMES_DATA,
          payload: docsRoyalCasino,
          pagination: {
            limit: res.limit,
            nextPage: res.nextPage,
            nextPageNumber: res.nextPageNumber,
            previousPage: res.previousPage,
            previousPageNumber: res.previousPageNumber,
            showingFrom: res.showingFrom,
            showingTo: res.showingTo,
            totalDocs: res.totalDocs,
            totalPages: res.totalPages,
          },
          loading: false,
        })
      })
    } catch (error: any) {
      dispatch({
        type: GET_ROYAL_CASINO_GAMES_DATA,
        payload: [],
        pagination: { totalPages: 1 },
        loading: false,
      })
    }
  }
}

export function filterObjectsNotInHashmap(array: any, hashmap: any) {
  const result = []
  for (const obj of array) {
    if (!hashmap[obj.game_id]) {
      result.push(obj)
    }
  }
  return result
}

export const getHashMapCasinoData = (casinoData: any) => {
  const gameIdToObjectMap: Map<any, any> = new Map()

  casinoData &&
    casinoData.forEach((game: any) => {
      gameIdToObjectMap.set(game.gameId, game)
    })

  return gameIdToObjectMap
}

export const getCasinoGames = (payload: any, currentDomain: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: GET_CASINO_GAMES_DATA,
        payload: [],
        pagination: { totalPages: 1 },
        loading: true,
      })
      makeGetHttpCallAsync(
        dispatch,
        'api/administrator/game/casinoGame',
        payload,
        currentDomain.type,
        '',
        true,
      )
        .then((res: any) => {
          dispatch({
            type: GET_CASINO_GAMES_DATA,
            payload: res.docs,
            mapping: getHashMapCasinoData(res.docs),
            pagination: {
              limit: res.limit,
              nextPage: res.nextPage,
              nextPageNumber: res.nextPageNumber,
              previousPage: res.previousPage,
              previousPageNumber: res.previousPageNumber,
              showingFrom: res.showingFrom,
              showingTo: res.showingTo,
              totalDocs: res.totalDocs,
              totalPages: res.totalPages,
            },
            loading: false,
          })
        })
        .catch(() => {
          dispatch({
            type: GET_CASINO_GAMES_DATA,
            payload: [],
            pagination: { totalPages: 1 },
            loading: false,
          })
        })
    } catch (error: any) {
      dispatch({
        type: GET_CASINO_GAMES_DATA,
        payload: [],
        pagination: { totalPages: 1 },
        loading: false,
      })
    }
  }
}

export const saveCasinoGames = (
  payload: any,
  currentDomain: any,
  cb: () => void,
) => {
  return async (dispatch: Dispatch) => {
    makePostHttpCallAsync(
      dispatch,
      'api/administrator/game/casinoGame',
      currentDomain.type,
      payload,
      '',
      true,
      '',
      true,
      'Added Successfully',
    )
      .then(() => {
        cb && cb()
      })
      .catch(() => {})
  }
}

export const deleteCasinoGames = (payload: any, currentDomain: any) => {
  return async (dispatch: Dispatch) => {
    makeDeleteHttpCallAsync(
      dispatch,
      'api/administrator/game/casinoGame',
      payload,
      currentDomain.type,
      '',
      true,
      '',
      true,
      'Deleted Successfully',
    )
      .then(() => {})
      .catch(() => {})
  }
}

export const updateCasinoGames = (payload: any, currentDomain: any) => {
  return async (dispatch: Dispatch) => {
    makePutHttpCallAsync(
      dispatch,
      'api/administrator/game/casinoGame',
      currentDomain.type,
      JSON.stringify(payload),
      '',
      true,
      '',
      true,
      'Updated Successfully',
    )
      .then(() => {})
      .catch(() => {})
  }
}

export const activeCasinoGames = (payload: any, currentDomain: any) => {
  return async (dispatch: Dispatch) => {
    makePatchHttpCallAsync(
      dispatch,
      'api/administrator/game/casinoGame',
      payload,
      '',
      currentDomain.type,
      '',
      true,
      'application/x-www-form-urlencoded',
      true,
      'PATCH',
      'Updated Successfull',
    )
      .then(() => {})
      .catch(() => {})
  }
}

interface Game {
  _id: string
  gameId: string
  gameName: string
  category: string
  providerName: string
  subProviderName: string
  status: string
  urlThumb: string
  gameCode: string
  active: boolean
  gameCategory: string
  gameCategoryArray: string[]
  gameType: string
  providerType: string
  createdAt: string
  updatedAt: string
}

interface GameTypeGroup {
  gameType: string
  games: Game[]
}

interface SubProviderGroup {
  subProviderName: string
  subProviders: GameTypeGroup[]
  allGames: Game[]
}

const gameTypesOrder = [
  'All',
  'Teen Patti',
  'Dragon Tiger',
  'Andar Bahar',
  'Bollywood',
  '3 Cards Judgement',
  '7 Up Down',
  'High Low',
  'Lucky 7',
  'Poker',
  'Roulette',
  'SicBo',
  'Baccarat',
  'Black Jack',
  'Casino War',
  'Worli Matka',
  'Trio',
  'Dice',
  'Lottery',
]

const subProviderOrder = [
  'Iconic 21',
  'Indian Casino',
  'Yuvi Games',
  'CreedRoomz',
  'Cock Fight',
  'Vivo Gaming',
  'Ezugi',
  'Evolution Gaming',
  'Spribe',
  'PopOk',
  'Pascal Gaming',
  'All Bet',
]

const getCasinoHierarchy = (casinoGames: Game[]): SubProviderGroup[] => {
  const result: SubProviderGroup[] = []

  const allSubProviderEntry: SubProviderGroup = {
    subProviderName: 'All',
    subProviders: [],
    allGames: [],
  }

  casinoGames.forEach((game) => {
    allSubProviderEntry.allGames.push(game)

    let subProviderEntry = result.find(
      (sub) => sub.subProviderName === game.subProviderName,
    )
    if (!subProviderEntry) {
      subProviderEntry = {
        subProviderName: game.subProviderName,
        subProviders: [],
        allGames: [],
      }
      result.push(subProviderEntry)
    }

    subProviderEntry.allGames.push(game)

    let allGameTypeEntry = subProviderEntry.subProviders.find(
      (sub) => sub.gameType === 'All',
    )
    if (!allGameTypeEntry) {
      allGameTypeEntry = {
        gameType: 'All',
        games: [],
      }
      subProviderEntry.subProviders.unshift(allGameTypeEntry) // Insert 'All' game type at the top
    }
    allGameTypeEntry.games.push(game) // Add game to 'All' gameType

    let gameTypeEntry = subProviderEntry.subProviders.find(
      (sub) => sub.gameType === game.gameType,
    )
    if (!gameTypeEntry) {
      gameTypeEntry = {
        gameType: game.gameType,
        games: [],
      }
      subProviderEntry.subProviders.push(gameTypeEntry)
    }
    gameTypeEntry.games.push(game) // Add game to specific gameType

    let allProviderGameTypeEntry = allSubProviderEntry.subProviders.find(
      (sub) => sub.gameType === game.gameType,
    )
    if (!allProviderGameTypeEntry) {
      allProviderGameTypeEntry = {
        gameType: game.gameType,
        games: [],
      }
      allSubProviderEntry.subProviders.push(allProviderGameTypeEntry)
    }
    allProviderGameTypeEntry.games.push(game) // Add game to the 'All' subProvider's gameType
  })

  const allGameTypeEntryForAllSubProvider: GameTypeGroup = {
    gameType: 'All',
    games: allSubProviderEntry.allGames, // Contains all games in the allGames array
  }
  allSubProviderEntry.subProviders.unshift(allGameTypeEntryForAllSubProvider) // Insert 'All' game type at the top

  allSubProviderEntry.subProviders.sort((a, b) => {
    const indexA = gameTypesOrder.indexOf(a.gameType)
    const indexB = gameTypesOrder.indexOf(b.gameType)
    return (
      (indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA) -
      (indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB)
    )
  })

  result.sort((a, b) => {
    const indexA = subProviderOrder.indexOf(a.subProviderName)
    const indexB = subProviderOrder.indexOf(b.subProviderName)
    return (
      (indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA) -
      (indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB)
    )
  })

  result.forEach((subProvider) => {
    subProvider.subProviders.sort((a, b) => {
      const indexA = gameTypesOrder.indexOf(a.gameType)
      const indexB = gameTypesOrder.indexOf(b.gameType)
      return (
        (indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA) -
        (indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB)
      )
    })

    subProvider.subProviders.forEach((gameTypeGroup) => {
      gameTypeGroup.games.sort((a, b) => {
        const indexA = gameTypesOrder.indexOf(a.gameType)
        const indexB = gameTypesOrder.indexOf(b.gameType)
        return (
          (indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA) -
          (indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB)
        )
      })
    })
  })

  allSubProviderEntry.subProviders.forEach((gameTypeGroup) => {
    gameTypeGroup.games.sort((a, b) => {
      const indexA = gameTypesOrder.indexOf(a.gameType)
      const indexB = gameTypesOrder.indexOf(b.gameType)
      return (
        (indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA) -
        (indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB)
      )
    })
  })

  return [allSubProviderEntry, ...result]
}

export const getUserCasinoList = (
  currentDomain: ICurrentDomain,
  type: string,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: GET_CASINO_GAMES_DATA,
        payload: [],
        pagination: { totalPages: 1 },
        loading: true,
      })
      dispatch({
        type: CASINO_LOADED,
        payload: false,
      })
      makeGetHttpCallAsync(
        dispatch,
        'api/common/game/casinoGame',
        `gameType=${type}`,
        currentDomain.type,
        '',
      ).then((res: any) => {
        let casinoGames = res.docs

        if (!casinoGames) {
          return []
        }

        casinoGames = getCasinoHierarchy(casinoGames)

        if (type === 'Live Casino') {
          dispatch({
            type: GET_CASINO_LIVE_CASINO_GAMES_DATA,
            payload: casinoGames,
            loading: false,
          })
        } else if (type === 'Virtual Games') {
          dispatch({
            type: GET_CASINO_VIRTUAL_CASINO_GAMES_DATA,
            payload: casinoGames,
            loading: false,
          })
        } else if (type === 'Slot Games') {
          dispatch({
            type: GET_CASINO_SLOT_GAMES_DATA,
            payload: casinoGames,
            loading: false,
          })
        } else if (type === 'Crash Games') {
          dispatch({
            type: GET_CRASH_GAMES_DATA,
            payload: casinoGames,
            loading: false,
          })
        } else if (type === 'Lottery') {
          dispatch({
            type: GET_LOTTERY_GAMES_DATA,
            payload: casinoGames,
            loading: false,
          })
        }
        dispatch({
          type: CASINO_LOADED,
          payload: true,
        })
      })
    } catch (error: any) {
      dispatch({
        type: GET_CASINO_GAMES_DATA,
        payload: [],
        pagination: { totalPages: 1 },
        loading: false,
      })
    }
  }
}

export const getAdminCasinoList = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: GET_CASINO_TOTAL_GAMES_ID_DATA,
        payload: [],
        loading: true,
      })
      makeGetHttpCallAsync(
        dispatch,
        'api/administrator/game/casinoGame',
        ``,
        currentDomain.type,
        '',
        true,
      ).then((res: any) => {
        let GameIds = getGameId(res.docs)
        dispatch({
          type: GET_CASINO_TOTAL_GAMES_ID_DATA,
          payload: GameIds,
          loading: false,
        })
      })
    } catch (error: any) {
      dispatch({
        type: GET_CASINO_TOTAL_GAMES_ID_DATA,
        payload: [],
        loading: false,
      })
    }
  }
}

export const getGameId = (games: any) => {
  return games.map((obj: any) => obj.gameId)
}

export const isAnyEuropianGame = (
  games: any,
  currentDomain: ICurrentDomain,
) => {
  if (currentDomain.domainName !== 'test.future9.club') {
    return false
  }

  return games.every((obj: any) => EUROPIAN_GAME.has(obj.subProviderName))
}

import { get2Decimal } from '..'
import { ICurrentBet } from '../../pages/Game/betslip/NewBetSlip'
import { BET_TYPE } from '../../types/enums/betType'
import { MARKET_TYPE } from '../../types/enums/gameType'

export const getCalculatedBetDetail = (
  currentBet: any,
  amount: number = 0,
  currentBetDetail: any,
) => {
  if (!currentBetDetail) {
    return undefined
  }

  return currentBetDetail + amount
}

export const getProfit = (currentBet: ICurrentBet, stakeAmount: number) => {
  let profit = 0
  if (
    currentBet.marketType === MARKET_TYPE.SESSION ||
    currentBet.marketType === MARKET_TYPE.BOOKMAKER
  ) {
    if (currentBet.oddType === BET_TYPE.BACK.valueOf()) {
      profit = (currentBet.odd / 100) * stakeAmount
    } else {
      profit = stakeAmount
    }
  } else {
    profit = getBMProfit(currentBet, stakeAmount)
  }
  return profit
}

export const getLiability = (currentBet: ICurrentBet, stakeAmount: number) => {
  let liability = 0
  if (
    currentBet.marketType === MARKET_TYPE.SESSION ||
    currentBet.marketType === MARKET_TYPE.BOOKMAKER
  ) {
    if (currentBet.oddType === BET_TYPE.LAY.valueOf()) {
      liability = (currentBet.odd / 100) * stakeAmount
    } else {
      liability = stakeAmount
    }
  } else {
    liability = getBMLiability(currentBet, stakeAmount)
  }
  return liability
}

export const getBMProfit = (
  currentBet: ICurrentBet,
  stakeAmount: number,
): number => {
  let profit = 0
  if (currentBet.oddType === BET_TYPE.BACK.valueOf()) {
    profit = (currentBet.odd - 1) * stakeAmount
  } else {
    profit = stakeAmount
  }
  return parseFloat(get2Decimal(profit))
}

export function getHasMap(hashMapMarket: any[]) {
  const resultMap: any = {}

  if (!hashMapMarket) {
    return new Map()
  }

  hashMapMarket.forEach((item) => {
    const marketId = item.marketId
    const marketValues = item.marketValue.map((value: any) => ({
      liability: value.liability,
      profit: value.profit,
      teamId: value.teamId,
      teamName: value.teamName,
      minLoss: value.minLoss,
      profitPercentage: value.profitPercentage,
      minLossPercentage: value.minLossPercentage,
      sessionBook: value.sessionBook,
      sessionBookArray: value.sessionBookArray,
    }))

    resultMap[marketId] = marketValues
  })
  return resultMap
}

export function getHasMapUpdate(hashMapMarket: any[]) {
  const resultMap: any = new Map()

  if (!hashMapMarket) {
    return new Map()
  }

  hashMapMarket.forEach((item) => {
    const marketId = item.marketId
    const marketValues = item.marketValue.map((value: any) => ({
      liability: value.liability,
      profit: value.profit,
      teamId: value.teamId,
      teamName: value.teamName,
      minLoss: value.minLoss,
      profitPercentage: value.profitPercentage,
      minLossPercentage: value.minLossPercentage,
      sessionMinMax: value.sessionMinMax,
      sessionBook: value.sessionBook,
      sessionBookArray: value.sessionBookArray,
    }))

    resultMap.set(marketId, marketValues)
  })
  return resultMap
}

export const getBMLiability = (
  currentBet: ICurrentBet,
  stakeAmount: number,
) => {
  let liability = 0
  if (currentBet.oddType === BET_TYPE.LAY.valueOf()) {
    liability = (currentBet.odd - 1) * stakeAmount
  } else {
    liability = stakeAmount
  }
  return parseFloat(get2Decimal(liability))
}

interface ISessionMinMax {
  min: number
  max: number
}

const getExistingSessionMinMax = (
  openBet: any,
  sessionMap: Map<string, ISessionMinMax> | null,
): any => {
  let sessionMapLocal: Map<string, ISessionMinMax> = new Map()
  let min = 1
  let max = 1

  if (!!sessionMap && sessionMap.has(openBet.market.id)) {
    let sessionMax: ISessionMinMax | undefined = sessionMap.get(
      openBet.market.id,
    )
    min = sessionMax!.min
    max = sessionMax!.max
    min = Math.min(min, openBet.odds.run)
    max = Math.max(max, openBet.odds.run)
    sessionMapLocal.set(openBet.market.id, { min: min, max: max })
  } else {
    sessionMapLocal.set(openBet.market.id, {
      min: openBet.odds.run,
      max: openBet.odds.run,
    })
  }

  return sessionMapLocal
}

const getSessionMinMax = (openBet: any): any => {
  let sessionMap: Map<string, ISessionMinMax> = new Map()

  let min = 1
  let max = 1

  openBet.forEach((item: any) => {
    if (item.market.type === MARKET_TYPE.SESSION.valueOf()) {
      if (sessionMap.has(item.market.id)) {
        let sessionMax: ISessionMinMax | undefined = sessionMap.get(
          item.market.id,
        )
        min = sessionMax!.min
        max = sessionMax!.max
        min = Math.min(min, item.odds.run)
        max = Math.max(max, item.odds.run)
        sessionMap.set(item.market.id, { min: min, max: max })
      } else {
        sessionMap.set(item.market.id, {
          min: item.odds.run,
          max: item.odds.run,
        })
      }
    }
  })

  return sessionMap
}

interface IMarketBook {
  liability: number
  profit: number
  profitPercentage?: number
  teamId?: string
  teamName?: string
  minLoss?: number
  minLossPercentage?: number
  sessionBook?: Map<number, number>
  sessionMinMax?: any
  sessionBookArray?: { run: number; amount: number }[]
}

function makeOpposite(num: number) {
  if (num >= 0) {
    return -num // If the number is positive, make it negative
  } else {
    return Math.abs(num) // If the number is negative, make it positive
  }
}

export function createLiabilityHashmap(
  openBet: any,
  userType: string,
  userId: string,
) {
  const liabilityHashmap: Map<any, IMarketBook[]> = new Map()
  const liabilityMarket: any[] = []

  const sessionMinMax: Map<string, ISessionMinMax> = getSessionMinMax(openBet)
  let tmpSessionMinMax: any = {}
  let isMarketAvailable = false
  let sessionBook: any = {}
  let minLoss: any = undefined
  let sessionBookArray: any[] = []
  let minLossPercentage: any = 0

  // Iterate through the data and update the hashmap
  openBet.forEach((item: any) => {
    const marketId = item.market.id // Assuming _id is used as marketId
    const liability = item.liability
    const profit = item.profit
    let marketBook: IMarketBook[] = []
    isMarketAvailable = liabilityHashmap.has(marketId)
    // Session
    if (userType === 'user') {
      if (item.market.type === 'session') {
        tmpSessionMinMax = sessionMinMax.get(marketId)
        if (isMarketAvailable) {
          sessionBook = liabilityHashmap.get(marketId)![0].sessionBook
        } else {
          sessionBook = new Map()
        }

        for (
          let index = tmpSessionMinMax.min - 1;
          index <= tmpSessionMinMax.max;
          index++
        ) {
          let sessionBookIndexVal = 0
          if (sessionBook.size && sessionBook.has(index)) {
            sessionBookIndexVal = sessionBook.get(index)
            if (item.odds.type === BET_TYPE.BACK) {
              if (index >= item.odds.run) {
                sessionBookIndexVal += profit
              } else {
                sessionBookIndexVal -= liability
              }
            } else {
              if (index >= item.odds.run) {
                sessionBookIndexVal -= liability
              } else {
                sessionBookIndexVal += profit
              }
            }
            sessionBook.set(index, sessionBookIndexVal)
          } else {
            if (item.odds.type === BET_TYPE.BACK) {
              if (index >= item.odds.run) {
                sessionBookIndexVal += profit
              } else {
                sessionBookIndexVal -= liability
              }
            } else {
              if (index >= item.odds.run) {
                sessionBookIndexVal -= liability
              } else {
                sessionBookIndexVal += profit
              }
            }
            sessionBook.set(index, sessionBookIndexVal)
          }
          minLoss = minLoss
            ? Math.min(minLoss, sessionBookIndexVal)
            : sessionBookIndexVal
        }

        sessionBook.forEach((value: any, key: any) => {
          sessionBookArray.push({
            run: key,
            amount: value,
          })
        })

        marketBook = [
          {
            sessionBook: sessionBook,
            sessionBookArray: sessionBookArray,
            liability: -1,
            profit: -1,
            teamId: '',
            teamName: '',
            minLoss: minLoss,
          },
        ]

        liabilityHashmap.set(marketId, marketBook)
        sessionBookArray = []
        minLoss = 0
      } else {
        let liabilityTeam: any = {}
        let liabilityHashMap: any = {}
        let market = item.market
        if (!market || (market && !market.teams)) {
        } else if (liabilityHashmap.has(marketId)) {
          liabilityTeam = liabilityHashmap.get(marketId)
          liabilityHashMap = getLiabilityHashMap(liabilityTeam)
          for (let index = 0; index < market.teams.length; index++) {
            if (market.teams[index].id === item.odds.teamId) {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit:
                    profit +
                    liabilityHashMap.get(market.teams[index].id).profit,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit:
                    liabilityHashMap.get(market.teams[index].id).profit -
                    liability,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            } else {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit:
                    liabilityHashMap.get(market.teams[index].id).profit -
                    liability,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit:
                    profit +
                    liabilityHashMap.get(market.teams[index].id).profit,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            }
          }
        } else {
          for (let index = 0; index < market.teams.length; index++) {
            if (market.teams[index].id === item.odds.teamId) {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit: profit,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit: liability - 2 * liability,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            } else {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit: liability - 2 * liability,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit: profit,
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            }
          }
        }
        liabilityHashmap.set(marketId, marketBook)
      }
    } else {
      // Non User
      if (item.market.type === 'session') {
        tmpSessionMinMax = sessionMinMax.get(marketId)
        if (isMarketAvailable) {
          sessionBook = liabilityHashmap.get(marketId)![0].sessionBook
        } else {
          sessionBook = new Map()
        }

        for (
          let index = tmpSessionMinMax.min - 1;
          index <= tmpSessionMinMax.max;
          index++
        ) {
          let sessionBookIndexVal = 0
          if (sessionBook.size && sessionBook.has(index)) {
            sessionBookIndexVal = sessionBook.get(index)
            if (item.odds.type === BET_TYPE.BACK) {
              if (index >= item.odds.run) {
                sessionBookIndexVal -= liability
              } else {
                sessionBookIndexVal += profit
              }
            } else {
              if (index >= item.odds.run) {
                sessionBookIndexVal += profit
              } else {
                sessionBookIndexVal -= liability
              }
            }
            sessionBook.set(index, sessionBookIndexVal)
          } else {
            if (item.odds.type === BET_TYPE.BACK) {
              if (index >= item.odds.run) {
                sessionBookIndexVal -= liability
              } else {
                sessionBookIndexVal += profit
              }
            } else {
              if (index >= item.odds.run) {
                sessionBookIndexVal += profit
              } else {
                sessionBookIndexVal -= liability
              }
            }
            sessionBook.set(index, sessionBookIndexVal)
          }

          minLoss = minLoss
            ? Math.min(minLoss, sessionBookIndexVal)
            : sessionBookIndexVal
          minLossPercentage = getMinLossPercentage(minLoss, item, userId)
          // minLossPercentage = minLossPercentage < minLossPercentageLocal ? minLossPercentageLocal - minLossPercentage : minLossPercentage - minLossPercentageLocal
        }

        sessionBook.forEach((value: any, key: any) => {
          sessionBookArray.push({
            run: key,
            amount: value,
          })
        })

        marketBook = [
          {
            sessionBook: sessionBook,
            sessionBookArray: sessionBookArray,
            liability: -1,
            profit: -1,
            teamId: '',
            minLoss: minLoss,
            minLossPercentage: minLossPercentage,
            sessionMinMax: sessionMinMax,
          },
        ]

        liabilityHashmap.set(marketId, marketBook)
        sessionBookArray = []
        minLoss = 0
      } else {
        let liabilityTeam: any = {}
        let liabilityHashMap: any = {}
        let market = item.market
        if (!market || (market && !market.teams)) {
        } else if (liabilityHashmap.has(marketId)) {
          liabilityTeam = liabilityHashmap.get(marketId)
          liabilityHashMap = getLiabilityHashMap(liabilityTeam)
          for (let index = 0; index < market.teams.length; index++) {
            if (market.teams[index].id === item.odds.teamId) {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit:
                    profit +
                    liabilityHashMap.get(market.teams[index].id).profit,
                  liability: -1,
                  profitPercentage:
                    liabilityHashMap.get(market.teams[index].id)
                      .profitPercentage +
                    getMinLossPercentage(profit, item, userId),
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit:
                    liabilityHashMap.get(market.teams[index].id).profit -
                    liability,
                  profitPercentage:
                    liabilityHashMap.get(market.teams[index].id)
                      .profitPercentage -
                    getMinLossPercentage(liability, item, userId),
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            } else {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit:
                    liabilityHashMap.get(market.teams[index].id).profit -
                    liability,
                  profitPercentage:
                    liabilityHashMap.get(market.teams[index].id)
                      .profitPercentage -
                    getMinLossPercentage(liability, item, userId),
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit:
                    profit +
                    liabilityHashMap.get(market.teams[index].id).profit,
                  profitPercentage:
                    liabilityHashMap.get(market.teams[index].id)
                      .profitPercentage +
                    getMinLossPercentage(profit, item, userId),
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            }
          }
        } else {
          for (let index = 0; index < market.teams.length; index++) {
            if (market.teams[index].id === item.odds.teamId) {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit: profit,
                  profitPercentage: getMinLossPercentage(profit, item, userId),
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit: liability - 2 * liability,
                  profitPercentage: getMinLossPercentage(
                    liability - 2 * liability,
                    item,
                    userId,
                  ),
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            } else {
              if (item.odds.type === BET_TYPE.BACK.valueOf()) {
                marketBook.push({
                  profit: liability - 2 * liability,
                  profitPercentage: getMinLossPercentage(
                    liability - 2 * liability,
                    item,
                    userId,
                  ),
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              } else {
                marketBook.push({
                  profit: profit,
                  profitPercentage: getMinLossPercentage(profit, item, userId),
                  liability: -1,
                  teamId: market.teams[index].id,
                  teamName: market.teams[index].name,
                })
              }
            }
          }
        }
        let marketBookLocal = [...marketBook]
        liabilityHashmap.set(marketId, marketBookLocal)
      }
    }
  })

  liabilityHashmap.forEach((value, key) => {
    let marketBook = value

    if (userType !== 'user') {
      for (let index = 0; index < marketBook.length; index++) {
        marketBook[index].profit = makeOpposite(marketBook[index].profit)
        marketBook[index].profitPercentage = makeOpposite(
          marketBook[index].profitPercentage || 0,
        )
      }
    }

    liabilityMarket.push({
      marketId: key,
      marketValue: marketBook,
    })
  })

  return liabilityMarket
}

export function getLiabilityHashMapUpdate(
  hashMap: any,
  openBet: any,
  userType: string,
  userId: string,
) {
  let liabilityHashMapUpdate = getHasMapUpdate(hashMap)

  const marketId = openBet.market.id
  let minLoss: any = undefined
  const liability = makeOpposite(openBet.liability)
  const profit = makeOpposite(openBet.profit)
  let isMarketAvailable = liabilityHashMapUpdate.has(marketId)
  let tmpSessionMinMax: any = {}
  let liabilityMarketLocal: any[] = []
  let sessionBook: any = {}
  let sessionBookArray: any[] = []
  let marketBook: IMarketBook[] = []
  let minLossPercentage: any = 0
  let sessionMinMax: Map<string, ISessionMinMax> = new Map<
    string,
    ISessionMinMax
  >()

  if (userType !== 'user') {
    if (openBet.market.type === 'session') {
      if (isMarketAvailable) {
        let minLossData = liabilityHashMapUpdate.get(marketId)
        sessionBook = minLossData![0].sessionBook
        sessionMinMax = getExistingSessionMinMax(
          openBet,
          minLossData![0].sessionMinMax,
        )
        tmpSessionMinMax = sessionMinMax.get(marketId)
      } else {
        sessionBook = new Map()
        sessionMinMax = getExistingSessionMinMax(openBet, null)
        tmpSessionMinMax = sessionMinMax.get(marketId)
      }

      for (
        let index = tmpSessionMinMax.min - 1;
        index <= tmpSessionMinMax.max;
        index++
      ) {
        let sessionBookIndexVal = 0
        if (sessionBook.size && sessionBook.has(index)) {
          sessionBookIndexVal = sessionBook.get(index)
          if (openBet.odds.type === BET_TYPE.BACK) {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal += profit
            } else {
              sessionBookIndexVal -= liability
            }
          } else {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal -= liability
            } else {
              sessionBookIndexVal += profit
            }
          }
          sessionBook.set(index, sessionBookIndexVal)
        } else {
          if (openBet.odds.type === BET_TYPE.BACK) {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal += profit
            } else {
              sessionBookIndexVal -= liability
            }
          } else {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal -= liability
            } else {
              sessionBookIndexVal += profit
            }
          }
          sessionBook.set(index, sessionBookIndexVal)
        }

        minLoss = minLoss
          ? Math.min(minLoss, sessionBookIndexVal)
          : sessionBookIndexVal
        minLossPercentage = getMinLossPercentage(minLoss, openBet, userId)
        // minLossPercentage = minLossPercentage < minLossPercentageLocal ? minLossPercentageLocal - minLossPercentage : minLossPercentage - minLossPercentageLocal
      }

      sessionBook.forEach((value: any, key: any) => {
        sessionBookArray.push({
          run: key,
          amount: value,
        })
      })
      marketBook = [
        {
          sessionBook: sessionBook,
          sessionBookArray: sessionBookArray,
          liability: -1,
          profit: -1,
          teamId: '',
          teamName: '',
          minLoss: minLoss,
          minLossPercentage: minLossPercentage,
        },
      ]
      liabilityHashMapUpdate.set(marketId, marketBook)
    } else {
      let liabilityTeam: any = {}
      let liabilityHashMap: any = {}
      let market = openBet.market
      let marketBook: IMarketBook[] = []

      if (!market || (market && !market.teams)) {
      } else if (liabilityHashMapUpdate.has(marketId)) {
        liabilityTeam = liabilityHashMapUpdate.get(marketId)
        liabilityHashMap = getLiabilityHashMap(liabilityTeam)
        for (let index = 0; index < market.teams.length; index++) {
          if (market.teams[index].id === openBet.odds.teamId) {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit:
                  profit + liabilityHashMap.get(market.teams[index].id).profit,
                liability: -1,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(
                    profit +
                      liabilityHashMap.get(market.teams[index].id).profit,
                  ),
                  openBet,
                  userId,
                ),
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit:
                  liabilityHashMap.get(market.teams[index].id).profit -
                  liability,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(
                    liabilityHashMap.get(market.teams[index].id).profit -
                      liability,
                  ),
                  openBet,
                  userId,
                ),
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          } else {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit:
                  liabilityHashMap.get(market.teams[index].id).profit -
                  liability,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(
                    liabilityHashMap.get(market.teams[index].id).profit -
                      liability,
                  ),
                  openBet,
                  userId,
                ),
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit:
                  profit + liabilityHashMap.get(market.teams[index].id).profit,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(
                    profit +
                      liabilityHashMap.get(market.teams[index].id).profit,
                  ),
                  openBet,
                  userId,
                ),
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          }
        }
      } else {
        for (let index = 0; index < market.teams.length; index++) {
          if (market.teams[index].id === openBet.odds.teamId) {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit: profit,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(profit),
                  openBet,
                  userId,
                ),
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit: liability - 2 * liability,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(liability - 2 * liability),
                  openBet,
                  userId,
                ),
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          } else {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit: liability - 2 * liability,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(liability - 2 * liability),
                  openBet,
                  userId,
                ),
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit: profit,
                profitPercentage: getMinLossPercentage(
                  makeOpposite(profit),
                  openBet,
                  userId,
                ),
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          }
        }
      }

      liabilityHashMapUpdate.set(marketId, marketBook)
    }
  } else {
    if (openBet.market.type === 'session') {
      const sessionMinMax: Map<string, ISessionMinMax> = getSessionMinMax([
        openBet,
      ])
      tmpSessionMinMax = sessionMinMax.get(marketId)
      if (isMarketAvailable) {
        sessionBook = liabilityHashMapUpdate.get(marketId)![0].sessionBook
      } else {
        sessionBook = new Map()
      }
      for (
        let index = tmpSessionMinMax.min - 1;
        index <= tmpSessionMinMax.max;
        index++
      ) {
        let sessionBookIndexVal = 0
        if (sessionBook.size && sessionBook.has(index)) {
          sessionBookIndexVal = sessionBook.get(index)
          if (openBet.odds.type === BET_TYPE.BACK) {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal += profit
            } else {
              sessionBookIndexVal -= liability
            }
          } else {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal -= liability
            } else {
              sessionBookIndexVal += profit
            }
          }
          sessionBook.set(index, sessionBookIndexVal)
        } else {
          if (openBet.odds.type === BET_TYPE.BACK) {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal += profit
            } else {
              sessionBookIndexVal -= liability
            }
          } else {
            if (index >= openBet.odds.run) {
              sessionBookIndexVal -= liability
            } else {
              sessionBookIndexVal += profit
            }
          }
          sessionBook.set(index, sessionBookIndexVal)
        }
        minLoss = minLoss
          ? Math.min(minLoss, sessionBookIndexVal)
          : sessionBookIndexVal
      }
      sessionBook.forEach((value: any, key: any) => {
        sessionBookArray.push({
          run: key,
          amount: value,
        })
      })
      marketBook = [
        {
          sessionBook: sessionBook,
          sessionBookArray: sessionBookArray,
          liability: -1,
          profit: -1,
          teamId: '',
          teamName: '',
          minLoss: minLoss,
        },
      ]
      liabilityHashMapUpdate.set(marketId, marketBook)
    } else {
      let liabilityTeam: any = {}
      let liabilityHashMap: any = {}
      let market = openBet.market
      let marketBook: IMarketBook[] = []

      if (!market || (market && !market.teams)) {
      } else if (liabilityHashMapUpdate.has(marketId)) {
        liabilityTeam = liabilityHashMapUpdate.get(marketId)
        liabilityHashMap = getLiabilityHashMap(liabilityTeam)
        for (let index = 0; index < market.teams.length; index++) {
          if (market.teams[index].id === openBet.odds.teamId) {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit:
                  profit + liabilityHashMap.get(market.teams[index].id).profit,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit:
                  liabilityHashMap.get(market.teams[index].id).profit -
                  liability,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          } else {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit:
                  liabilityHashMap.get(market.teams[index].id).profit -
                  liability,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit:
                  profit + liabilityHashMap.get(market.teams[index].id).profit,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          }
        }
      } else {
        for (let index = 0; index < market.teams.length; index++) {
          if (market.teams[index].id === openBet.odds.teamId) {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit: profit,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit: liability - 2 * liability,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          } else {
            if (openBet.odds.type === BET_TYPE.BACK.valueOf()) {
              marketBook.push({
                profit: liability - 2 * liability,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            } else {
              marketBook.push({
                profit: profit,
                liability: -1,
                teamId: market.teams[index].id,
                teamName: market.teams[index].name,
              })
            }
          }
        }
      }

      liabilityHashMapUpdate.set(marketId, marketBook)
    }
  }

  liabilityHashMapUpdate.forEach((value: any, key: any) => {
    let marketBook = value

    if (userType !== 'user') {
      for (let index = 0; index < marketBook.length; index++) {
        // marketBook[index].profit = makeOpposite(marketBook[index].profit)
        marketBook[index].profitPercentage = makeOpposite(
          marketBook[index].profitPercentage || 0,
        )
      }
    }

    liabilityMarketLocal.push({
      marketId: key,
      marketValue: marketBook,
    })
  })

  return liabilityMarketLocal
}

export function getLiabilityHashMap(liability: any) {
  const teamMap = new Map()

  for (const item of liability) {
    const { teamId } = item
    teamMap.set(teamId, item)
  }

  return teamMap
}

function getMinLossPercentage(minLoss: number, item: any, userId: string) {
  let parentPercentage: any[] = item.user.parentPercentage
  let selfIndex = item.user.parents.findIndex((item: string) => item === userId)
  let childPercentage = parentPercentage[item.user.parents[selfIndex + 1]]
  let selfPercentage = parentPercentage[item.user.parents[selfIndex]]

  const absolutePercentage = !childPercentage
    ? 100 - selfPercentage
    : childPercentage > selfPercentage
      ? childPercentage - selfPercentage
      : 0
  return (minLoss * absolutePercentage) / 100
}

export const getTeamsValue = (
  hashMapMarket: any,
  marketId: any,
  id: string,
  isPercentage?: boolean,
) => {
  if (Object.keys(hashMapMarket).length === 0) {
    return undefined
  }

  let market: any = hashMapMarket[marketId.id]

  if (!market) {
    return undefined
  }

  for (let index = 0; index < market.length; index++) {
    if (market[index].teamId === id) {
      return isPercentage
        ? market[index].profitPercentage
        : market[index].profit
    }
  }

  return undefined
}

export const getCurrentBetDetail = (
  currentBet: ICurrentBet,
  teamItem: any,
  marketId: any,
  stakeAmount: number,
) => {
  let profit = getProfit(currentBet, stakeAmount)
  let liability = getLiability(currentBet, stakeAmount)
  if (
    !!Object.keys(currentBet).length &&
    !!currentBet.marketId &&
    currentBet.marketId._id === marketId._id
  ) {
    if (currentBet.oddType === BET_TYPE.BACK.valueOf()) {
      if (currentBet.teamId === teamItem.id) {
        return profit
      } else {
        return liability! - 2 * liability!
      }
    } else {
      if (currentBet.teamId === teamItem.id) {
        return liability! - 2 * liability!
      } else {
        return profit
      }
    }
  }

  return undefined
}

import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from '../../../redux/type'
import { NewBetSlip } from './NewBetSlip'
import {
  onCurrentMarketUpdate,
  placeMarketOrder,
} from '../../../redux/actions/userAction/betSlipAction'
import { getOpenBet } from '../../../redux/actions/reportAction/reportsAction'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const lang = state.language.lang
  const isLogin = state.login.isLogin
  const currentDomain = state.currentDomain
  const stakes = state.userStake
  const quickBet = state.userQuickBet
  const betSlip = state.betSlip
  const userData = state.login.userData
  const userBet = state.userBet
  const openBet = state.reports.openBet
  const quickBetOn = state.login.quickBet
  const quickBetValue = state.login.quickBetValue
  const demoLogin = state.demoLogin.demoLogin

  return {
    isDarkMode,
    quickBetValue,
    quickBetOn,
    lang,
    userData,
    isLogin,
    demoLogin,
    currentDomain,
    stakes,
    quickBet,
    openBet,
    betSlip,
    userBet,
  }
}

const ConnectedBetSlipNew: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  { placeMarketOrder, getMatchBet: getOpenBet, onCurrentMarketUpdate },
)(NewBetSlip as any)

export default ConnectedBetSlipNew

import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import {
  makeGetHttpCallAsync,
  makePatchHttpCallAsync,
  makePostHttpCallAsync,
} from '../../api/httpMethod'
import { HEADERS } from '../../api'
import { GET_DW_REPORT } from './reportAction/reportsAction'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { getCorrectUserType } from '../../utils'
import { USER_TYPES_ALL } from '../type/AppConstant'

export const WITHDRAWAL = '@WITHDRAWAL/WITHDRAWAL'

export const getUserWithdrawalApi = (
  currentDomain: ICurrentDomain,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)
    !isPrint && dispatch({ type: GET_DW_REPORT, loading: true })
    makeGetHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/user/withdraw`,
      payload,
      currentDomain.type,
      '',
      true,
      '',
    )
      .then((res: any) => {
        cb && cb(res.docs)
        !isPrint &&
          dispatch({
            type: GET_DW_REPORT,
            payload: res.docs,
            page: {
              limit: res.limit,
              nextPage: res.nextPage,
              nextPageNumber: res.nextPageNumber,
              previousPage: res.previousPage,
              previousPageNumber: res.previousPageNumber,
              showingFrom: res.showingFrom,
              showingTo: res.showingTo,
              totalDocs: res.totalDocs,
              totalPages: res.totalPages,
            },
            loading: false,
          })
      })
      .catch(() => {
        cb && cb([])
        dispatch({ type: GET_DW_REPORT, loading: false })
      })
  }
}

export const withdrawalApi = (
  payload: any,
  userType: string,
  currentDomain: ICurrentDomain,
  cb?: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: WITHDRAWAL, payload: true })
      if (userType === 'user') {
        makePostHttpCallAsync(
          dispatch,
          'api/user/withdraw',
          currentDomain.type,
          JSON.stringify(payload),
          false,
          true,
          '',
          true,
          'Withdraw Request Added',
        )
          .then((response: any) => {
            cb && cb()
            dispatch({ type: WITHDRAWAL, payload: false, data: response.docs })
          })
          .catch(async (error: any) => {
            cb && cb()
            let err = await error
          })
      } else {
        let formData = new URLSearchParams()
        formData.append('amount', String(payload?.amount))
        formData.append('depositId', String(payload?.withdrawId))
        formData.append('action', String(payload?.action))
        formData.append('remarks', String(payload?.remarks))
        formData.append('transactionCode', String(payload?.transactionCode))
        formData.append('action', String(payload?.action))

        makePatchHttpCallAsync(
          dispatch,
          `api/${getCorrectUserType(userType)}/withdraw`,
          formData,
          '',
          currentDomain.type,
          false,
          true,
          '',
          true,
          'patch',
        )
          .then((response: any) => {
            cb && cb()
            dispatch({ type: WITHDRAWAL, payload: false, data: response.docs })
          })
          .catch(async () => {
            cb && cb()
          })
      }
    } catch (error: any) {
      cb && cb()
      dispatch({ type: WITHDRAWAL, payload: false })
    }
  }
}

import { forwardRef, useCallback, useEffect, useState } from 'react'
import { getAssetJsonUrl } from '../../utils'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'

interface IRules {
  onCloseModal: any
  lang: any
  languageUpdate: any
  languages: any
}

export const Rules = forwardRef((props: IRules, ref: any) => {
  const [content, setContent] = useState<any>([])
  const [expandedIndex, setExpandedIndex] = useState(0)

  const getContent = useCallback(async (url: string) => {
    const response = await fetch(url)
    if (!response.ok) {
      console.log('Error Occured!!!')
    }
    const data = await response.json()
    return data
  }, [])

  useEffect(() => {
    getContent(`${getAssetJsonUrl('content/rules/rules')}.json`).then((res) => {
      setContent(res)
    })
  }, [])

  return (
    <div
      ref={ref}
      style={{ minWidth: '10rem' }}
      className='xs:ml-5 xs:mr-5 mt-5 mb-10 lg:col-span-10 md:col-span-10 pt-2 xl:col-span-10 '
    >
      <div
        style={{
          display: 'flex',
          borderRadius: '1rem 1rem 0 0',
          justifyContent: 'center',
          padding: '10px 15px',
          alignItems: 'center',
          backgroundColor: 'var(--theme-color)',
        }}
      >
        <NotificationsNoneIcon />

        <div style={{ width: '100%', marginLeft: '1rem' }}>
          {_getLocalString('LOC_RULES_AND_REGULATIONS')}
        </div>
        <DisabledByDefaultIcon
          onClick={() => props.onCloseModal(false)}
          className='cursor-pointer'
        />
      </div>
      <div style={{ maxHeight: '30rem' }}>
        {content && content.length ? (
          content.map((contentData: any, index: number) => (
            <div
              key={index}
              style={{
                background: 'white',
                width: '100%',
                padding: '0rem 1rem',
              }}
              className='h-auto mb-[2px] flex'
            >
              <Accordion
                expanded={expandedIndex == index + 1}
                style={{ margin: '1rem 0rem', width: '100%' }}
                className='dark:border !rounded-none'
              >
                <AccordionSummary
                  onClick={() =>
                    index + 1 === expandedIndex
                      ? setExpandedIndex(0)
                      : setExpandedIndex(index + 1)
                  }
                  expandIcon={<ExpandMoreIcon className='text-black' />}
                  className=' shadow-none !min-h-max dark:!border !border-solid !border-[#440115]'
                  classes={{ content: '!my-1' }}
                  style={{ background: 'var(--theme-color)' }}
                  aria-controls='panel1a-content'
                >
                  <div className='p-1'>
                    <span
                      style={{ color: 'white' }}
                      className='leading-tight text-black !font-bebas text-xl'
                    >
                      {contentData.title}
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className=' bg-transparent text-black'
                  style={{ padding: '10' }}
                >
                  {contentData.content &&
                    contentData.content.map((data: any, indexNum: number) => (
                      <div
                        key={index + indexNum}
                        className='p-2'
                        style={{ border: 'dashed 0.2px grey' }}
                      >
                        <div
                          style={{
                            fontWeight: data.isBold ? 600 : 400,
                            color: data.color ? data.color : 'black',
                          }}
                        >
                          {' '}
                          {data.message}{' '}
                        </div>
                      </div>
                    ))}
                </AccordionDetails>
              </Accordion>
            </div>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
})

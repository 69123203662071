import { Tab, Tabs } from '@mui/material'
import { FC, useState } from 'react'
import { isMobileScreensix } from '../../../../utils/internalUtils'

interface IShowFancyMarkets {
  isDarkMode: boolean
  value: number
  setValue: (value: number) => void
}

export const ShowFancyMarkets: FC<IShowFancyMarkets> = (
  props: IShowFancyMarkets,
) => {
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <div>
      <div className='my-4'>
        <Tabs
          value={props.value}
          onChange={(e, val) => {
            props.setValue(val)
          }}
          aria-label=''
          className='pl-12 xs:pl-2 !text-white'
          TabIndicatorProps={{
            className: '!bg-[color:var(--header-top-third-bg)] !h-[3px]',
          }}
        >
          <Tab
            label={isMobileScreensix() ? 'All' : 'All'}
            {...a11yProps(0)}
            style={{
              minWidth: 'auto',
              color: props.isDarkMode ? 'white' : 'black',
            }}
          />
          <Tab
            label={isMobileScreensix() ? 'SESSIONS' : 'SESSIONS'}
            {...a11yProps(1)}
            style={{
              minWidth: 'auto',
              color: props.isDarkMode ? 'white' : 'black',
            }}
          />
          <Tab
            label={isMobileScreensix() ? 'W/P' : 'W/P MARKET'}
            {...a11yProps(2)}
            style={{
              minWidth: 'auto',
              color: props.isDarkMode ? 'white' : 'black',
            }}
          />
          <Tab
            label={isMobileScreensix() ? 'XTRA' : 'XTRA MARKET'}
            {...a11yProps(3)}
            style={{
              minWidth: 'auto',
              color: props.isDarkMode ? 'white' : 'black',
            }}
          />
        </Tabs>
      </div>
    </div>
  )
}

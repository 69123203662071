import {
  IFooter,
  ITermsAndCondition,
} from '../../types/interfaces/themeConfiguration'

export interface IAppCommonThemeReducers {
  headerMarquee: string
  termsAndCondition: ITermsAndCondition
  footer: IFooter
  casinoTiles: any[]
  withdrawRules: any[]
  languages: any[]
  headerBottomMarquee: string
  slideShow: any[]
  advertisement: IAdvertisementCommon
  news: string[]
}

export interface IAdvertisementCommon {
  title: string
  frontImage: string
  firstButton: string
  secondButton: string
}

const initialState: IAppCommonThemeReducers = {} as any

export const GET_COMMON_THEME = 'GET_COMMON_THEME'

export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_COMMON_THEME: {
      return {
        ...state,
        ...action.data,
      }
    }

    default:
      return state
  }
}

import { ISetCommon } from '../../types/common'
import { GET_REQUEST_DETAILS, UPDATE_REQUEST_DETAILS } from '../actions'

const initialState: IRequestInitial = {
  requestsData: [],
  requestPage: undefined,
  getRequestsDataLoading: false,
  updateLoading: false,
}

export interface IRequestInitial {
  requestsData: any
  requestPage: any
  getRequestsDataLoading: boolean
  updateLoading: boolean
}

export default function (state = initialState, action: ISetCommon) {
  switch (action.type) {
    case GET_REQUEST_DETAILS: {
      return {
        ...state,
        requestsData: action.data,
        getRequestsDataLoading: action.loading,
        requestPage: action.page,
      }
    }

    case UPDATE_REQUEST_DETAILS: {
      return {
        ...state,
        updateLoading: action.loading,
      }
    }

    default:
      return state
  }
}

import { useState } from 'react'
import { getAssetUrl } from '../../../utils'

interface IProdComponent {
  item: number
}

export const ProdComponent = (props: IProdComponent) => {
  const [mouseHover, setMouseHover] = useState(false)

  const updateMouseHover = () => {
    setMouseHover(!mouseHover)
  }

  return (
    <div
      onMouseEnter={updateMouseHover}
      onMouseLeave={updateMouseHover}
      style={{
        background: '#f2f8fc',
        placeContent: 'center',
        cursor: 'pointer',
        boxShadow: 'inset 0px 0px 10px 2px rgba(0, 0, 0, 0.6)',
        padding: '0 2rem',
        borderRadius: '20px',
        width: 'auto',
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={getAssetUrl(
          `partners/${props.item}/${mouseHover ? 'color' : 'black'}.webp`,
        )}
        alt={`Partner ${props.item}`}
      />
    </div>
  )
}

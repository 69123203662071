import { useEffect, useState } from 'react'
import { NavBarTile } from '../../pages/landing/component/Content/NavBarTile'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SkeletonLoading from '../skeleton/Skeleton'
import { INavTiles } from './NavTiles'
import { AppState } from '../../redux'
import { USER_TYPES_ALL_OBJ } from '../../redux/type/AppConstant'

export const NavTiles2 = (props: INavTiles) => {
  const [isOpen, setIsOpen] = useState<string>('')
  const [isChildOpen, setIsChildOpen] = useState<string>('')
  const [searchParams] = useSearchParams()
  const createBranch = searchParams.get('createBranch')

  const { sportsThirdPartyData, sportsCommonData } = useSelector(
    (state: AppState) => {
      return {
        sportsThirdPartyData:
          state.gameManagementThirdParty.gamesThirdPartyData,
        getSportsThirdPartyLoading:
          state.gameManagementThirdParty.getGamesThirdPartyDataLoading,
        sportsCommonData: state.gameManagementCommon.gamesCommonData,
      }
    },
  )

  const currentDomain = useSelector((state: any) => state.currentDomain)

  const getAccountMatches = () => {
    let accountListMatches = [
      {
        title: 'Account List',
        link: '/account-list',
      },
    ]

    if (props.currentDomain.type === 'b2c') {
      accountListMatches.push({
        title: 'Request List',
        link: '/request-accounts',
      })
      accountListMatches.push({
        title: 'UTR Form',
        link: '/utr-form',
      })
    }

    accountListMatches.push({ title: 'Create Account', link: '/create' })

    return accountListMatches
  }

  useEffect(() => {
    if (!sportsCommonData || !sportsCommonData.length) {
      return
    }
  }, [sportsThirdPartyData])

  const getReports = () => {
    const reports = [
      { name: _getLocalString('LOC_ACCOUNT_STATEMENT'), link: '/accounts' },
    ]

    if (props.currentDomain.type === 'b2c') {
      reports.push({
        name: 'Deposit & WithDraw Report',
        link: '/deposit-withdraw-report',
      })
    }

    if (
      props.userData &&
      props.userData.userType !== 'whiteLabel' &&
      props.userData.userType !== 'administrator'
    ) {
      reports.push({ name: 'User Report', link: '/user-report' })
    }

    if (props.userData && props.userData.userType === 'administrator') {
      reports.push({ name: 'Commision Report', link: '/commision-report' })
    }

    let reportsLocal = [
      { name: 'Open Bets', link: '/open-bets' },
      { name: 'Bets History', link: '/bet-history' },
      { name: 'Fraud Report', link: '/fraud-report' },
    ]

    reports.push(...reportsLocal)

    return reports
  }

  return (
    <div className='flex flex-col gap-3 mt-6 xs:w-40 sm:w-50 md:w-52 lg:w-60 xl:w-72 '>
      {false ? (
        <>
          <SkeletonLoading width={'95%'} height={55} />
          <SkeletonLoading width={'95%'} height={55} />
          <SkeletonLoading width={'95%'} height={55} />
          <SkeletonLoading width={'95%'} height={55} />
          <SkeletonLoading width={'95%'} height={55} />
          <SkeletonLoading width={'95%'} height={55} />
        </>
      ) : (
        <>
          {props.currentDomain.type === 'b2c' && (
            <NavBarTile
              height={55}
              name={`B2C Dashboard`}
              icon={'reports'}
              isSelected={props.pathName?.indexOf('dashboard') !== -1}
              link='/dashboard'
              isDarkMode={props.isDarkMode}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onClickHandlerClose={props.onClickHandlerClose}
            />
          )}
          <NavBarTile
            height={55}
            name={`Market Analysis`}
            icon={'market'}
            isSelected={props.pathName?.indexOf('market-analysis') !== -1}
            link='/market-analysis'
            isDarkMode={props.isDarkMode}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClickHandlerClose={props.onClickHandlerClose}
          />
          {props.userData?.userType === USER_TYPES_ALL_OBJ.administrator && (
            <NavBarTile
              height={55}
              name={`Multi Login Account`}
              icon='user-arrow'
              isDarkMode={props.isDarkMode}
              isSelected={props.pathName?.indexOf('multi-account-login') !== -1}
              link='/multi-account-login'
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onClickHandlerClose={props.onClickHandlerClose}
            />
          )}
          {props.userData?.userType === USER_TYPES_ALL_OBJ.whiteLabel &&
          currentDomain.type === 'b2c' ? (
            <NavBarTile
              height={55}
              name={`Account`}
              icon={'account'}
              isDarkMode={props.isDarkMode}
              onClickHandlerClose={props.onClickHandlerClose}
              isSelected={
                props.pathName?.indexOf('accounts') !== -1 ||
                props.pathName?.indexOf('account-list') !== -1 ||
                props.pathName?.indexOf('request-accounts') !== -1 ||
                props.pathName?.indexOf('branch') !== -1
              }
              link='/account-list'
              matches={[
                {
                  title: 'Account List',
                  link: '/account-list',
                },
                // { title: 'Branch List', link: '/account-list' },
                {
                  title: 'Request List',
                  link: '/request-accounts',
                },
                {
                  title: 'Create Branch',
                  link: '/branch?createBranch=true',
                },
              ]}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          ) : (
            <NavBarTile
              height={55}
              name={`Account`}
              icon={'account'}
              isDarkMode={props.isDarkMode}
              onClickHandlerClose={props.onClickHandlerClose}
              isSelected={
                props.pathName?.indexOf('account-list') !== -1 ||
                props.pathName?.indexOf('request-accounts') !== -1 ||
                props.pathName?.indexOf('utr-form') !== -1 ||
                props.pathName?.indexOf('create') !== -1
              }
              link='/account-list'
              matches={getAccountMatches()}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
          <NavBarTile
            height={55}
            name={`Bank`}
            icon={'bank'}
            onClickHandlerClose={props.onClickHandlerClose}
            isSelected={props.pathName?.indexOf('bank') !== -1}
            isDarkMode={props.isDarkMode}
            link='/bank'
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          <NavBarTile
            height={55}
            name={`Reports`}
            onClickHandlerClose={props.onClickHandlerClose}
            icon={'reports'}
            isSelected={
              props.pathName === '/accounts' ||
              props.pathName === '/deposit-withdraw-report' ||
              props.pathName === '/user-report' ||
              props.pathName === '/open-bets' ||
              props.pathName === '/bet-history'
            }
            link=''
            isDarkMode={props.isDarkMode}
            matches={getReports()}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />

          {props.userData?.userType === USER_TYPES_ALL_OBJ.administrator && (
            <NavBarTile
              height={55}
              name={`Actions`}
              onClickHandlerClose={props.onClickHandlerClose}
              icon={'reports'}
              isSelected={
                props.pathName === '/delete-open-bets' ||
                props.pathName === '/delete-bet-history'
              }
              link=''
              isDarkMode={props.isDarkMode}
              matches={[
                {
                  title: 'Delete Open Bets',
                  link: '/delete-open-bets',
                },
                {
                  title: 'Delete Bets History',
                  link: '/delete-bet-history',
                },
                {
                  title: 'Modify User',
                  link: '/modify-user',
                },
                {
                  title: _getLocalString('LOC_MODIFY_SETTING'),
                  link: '/modify-setting',
                },
                {
                  title: 'Modify All WhiteLabel',
                  link: '/modify-whiteLabel',
                },
                {
                  title: 'Modify Betfair Keys',
                  link: '/modify-betfair-keys',
                },
              ]}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}

          {props.userData?.userType !== USER_TYPES_ALL_OBJ.administrator && (
            <NavBarTile
              height={55}
              name={`Actions`}
              onClickHandlerClose={props.onClickHandlerClose}
              icon={'reports'}
              isSelected={props.pathName === '/modify-user'}
              link=''
              isDarkMode={props.isDarkMode}
              matches={[
                {
                  title: 'Modify User',
                  link: '/modify-user',
                },
              ]}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}

          {props.userData?.userType === USER_TYPES_ALL_OBJ.administrator && (
            <NavBarTile
              height={55}
              name={`Games`}
              icon={'games'}
              isDarkMode={props.isDarkMode}
              onClickHandlerClose={props.onClickHandlerClose}
              isSelected={props.pathName?.indexOf('games') !== -1}
              matches={[
                { title: 'Game List', link: '/games' },
                {
                  title: 'Game Management',
                  link: '/game-management',
                },
                {
                  title: 'Casino List',
                  link: '/casino-list',
                },
              ]}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}

          {(props.userData?.userType === USER_TYPES_ALL_OBJ.administrator ||
            (props.userData?.userType === USER_TYPES_ALL_OBJ.whiteLabel &&
              'b2c' === props?.currentDomain.type)) && (
            <NavBarTile
              height={55}
              name={`Theme Editor`}
              icon={'themeEditor'}
              onClickHandlerClose={props.onClickHandlerClose}
              isDarkMode={props.isDarkMode}
              isSelected={props.pathName?.indexOf('edit-theme') !== -1}
              link='/edit-theme'
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}

          {props.userData?.userType !== 'user' &&
            props.userData?.userType !== 'administrator' &&
            props.currentDomain.type === 'b2c' && (
              <NavBarTile
                height={55}
                name={`Type Lists`}
                icon={'bank'}
                onClickHandlerClose={props.onClickHandlerClose}
                isSelected={
                  props.pathName?.indexOf('deposit-types') !== -1 ||
                  props.pathName?.indexOf('withdraw-types') !== -1
                }
                isDarkMode={props.isDarkMode}
                link=''
                matches={[
                  {
                    title: 'Deposit Type',
                    link: '/deposit-types',
                  },
                  {
                    title: 'WithDraw Type',
                    link: '/withdraw-types',
                  },
                ]}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            )}

          {props.userData?.userType === USER_TYPES_ALL_OBJ.whiteLabel &&
            (props.userData.whiteLabelType === 'b2c' ||
              props.userData.whiteLabelType === 'branch') && (
              <NavBarTile
                height={55}
                name={`Branch`}
                icon={'branch'}
                isDarkMode={props.isDarkMode}
                onClickHandlerClose={props.onClickHandlerClose}
                isSelected={
                  props.pathName?.indexOf('branch') !== -1 && !createBranch
                }
                link='/branch'
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            )}

          <NavBarTile
            height={55}
            name={`Events`}
            icon={'events'}
            onClickHandlerClose={props.onClickHandlerClose}
            isPadding={true}
            isSelected={props.pathName?.indexOf('events') !== -1}
            link=''
            matches={[]}
            isDarkMode={props.isDarkMode}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isChildOpen={isChildOpen}
            setIsChildOpen={setIsChildOpen}
            showSportTiles={true}
          />
        </>
      )}
    </div>
  )
}

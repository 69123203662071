import { useEffect, useState } from 'react'

interface ITennisScore {
  scoreData: any
}

export const TennisScore = (props: ITennisScore) => {
  const [scoreData, setScoreData] = useState(props.scoreData?.score)

  useEffect(() => {
    if (props.scoreData && props.scoreData.score) {
      setScoreData(props.scoreData?.score)
    }
  }, [props.scoreData?.score])

  return (
    <>
      <div className='text-white'>
        <div>
          {!!scoreData &&
            scoreData.length &&
            scoreData.map((score: any) => (
              <div className='sm:p-2 dark:text-white xs:text-white sm:text-black text-black'>
                <div className='flex mb-2 justify-between'>
                  <div className='flex'>
                    {' '}
                    <div>{score.score.home.name} </div>
                    {score.score.home.highlight && (
                      <div className='blinking-dot ml-5 mt-2 ' />
                    )}
                  </div>
                  <div className='flex'>
                    {!!score.score.home.gameSequence &&
                      !!score.score.home.gameSequence.length &&
                      score.score.home.gameSequence.map((game: any) => (
                        <div className='mr-7'>{game} </div>
                      ))}
                    <div className='bold mr-7 text-[#21c521]'>
                      {score.score.home.games}{' '}
                    </div>
                    <div className='bold text-[#21c521]'>
                      {score.score.home.score}{' '}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between'>
                  <div className='flex'>
                    {' '}
                    <div>{score.score.away.name} </div>
                    {score.score.away.highlight && (
                      <div className='blinking-dot ml-5 mt-2 ' />
                    )}
                  </div>
                  <div className='flex'>
                    {!!score.score.away.gameSequence &&
                      !!score.score.away.gameSequence.length &&
                      score.score.away.gameSequence.map((game: any) => (
                        <div className='mr-7'>{game} </div>
                      ))}
                    <div className='bold mr-7 text-[#21c521]'>
                      {score.score.away.games}{' '}
                    </div>
                    <div className='bold text-[#21c521]'>
                      {score.score.away.score}{' '}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between'>
                  <div className='flex'>
                    {' '}
                    <div></div>
                  </div>
                  <div className='xs:text-white flex text-[10px] text-[grey]'>
                    {!!score.score.away.gameSequence &&
                      !!score.score.away.gameSequence.length && (
                        <div className='bold mr-7'> 2 </div>
                      )}
                    <div className='bold mr-5'> 1 </div>
                    <div className='bold'> POINT </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

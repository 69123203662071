import { useRef } from 'react'
import QRCode from 'react-qr-code'

interface GenerateQRProps {
  upiUrl: string
}

export function GenerateQR({ upiUrl }: GenerateQRProps) {
  const svgRef = useRef<SVGSVGElement | null>(null)

  const handleDownload = () => {
    const svg = svgRef.current
    if (svg) {
      const serializer = new XMLSerializer()
      const svgString = serializer.serializeToString(svg)

      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const img = new Image()

      img.onload = () => {
        const padding = 20 // Adjust padding as needed
        const canvasWidth = img.width + padding * 2
        const canvasHeight = img.height + padding * 2

        canvas.width = canvasWidth
        canvas.height = canvasHeight

        // Set a white background
        ;(context as any).fillStyle = 'white'
        context?.fillRect(0, 0, canvasWidth, canvasHeight)

        // Draw the image in the center with padding
        context?.drawImage(img, padding, padding)

        const pngDataUrl = canvas.toDataURL('image/png')

        const link = document.createElement('a')
        link.href = pngDataUrl
        link.download = `${upiUrl}-qr-code.png`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

      img.src =
        'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString)
    }
  }

  return (
    <div
      style={{ background: 'white', padding: '1rem', borderRadius: '0.5rem' }}
    >
      <QRCode
        value={upiUrl}
        size={200}
        ref={(ref: any) => {
          if (ref) {
            svgRef.current = ref as SVGSVGElement
          }
        }}
      />
      <button
        className='cursor-pointer mt-3 mb-3 text-black text-center'
        onClick={handleDownload}
      >
        Download QR Code
      </button>
    </div>
  )
}

export function mapEventIdsToTrue(docs: any) {
  return docs.reduce((map: any, doc: any) => {
    map[doc.eventId] = true
    return map
  }, {})
}

export function getCommonEvents(ongoing: any, upcoming: any, eventIdMap: any) {
  const eventIdSet = new Set(
    Object.keys(eventIdMap).filter((key) => eventIdMap[key]),
  )

  function filterEventsArray(eventsArray: any) {
    return eventsArray.filter((event: any) => eventIdSet.has(event.eventId))
  }

  function filterData(dataArray: any) {
    return dataArray
      .map((eventType: any) => {
        const filteredEvents = filterEventsArray(eventType.events)
        if (filteredEvents.length > 0) {
          return {
            ...eventType,
            events: filteredEvents,
          }
        }
        return null
      })
      .filter((eventType: any) => eventType !== null)
  }

  const filteredOngoing = filterData(ongoing)
  const filteredUpcoming = filterData(upcoming)

  const mergedEvents = [...filteredOngoing, ...filteredUpcoming]

  return mergedEvents
}

export function deleteEventIdFromMap(eventIdMap: any, eventId: any) {
  delete eventIdMap[eventId]
  return eventIdMap
}

export function generateRandomMobileNumber() {
  // Define the mobile number prefix
  const prefixes = ['7', '8', '9']
  // Choose a random prefix
  let mobileNumber = prefixes[Math.floor(Math.random() * prefixes.length)]
  // Generate the remaining 9 digits
  for (let i = 0; i < 9; i++) {
    mobileNumber += Math.floor(Math.random() * 10).toString()
  }
  return mobileNumber
}

export function LiveButton() {
  return (
    <div className='relative inline-block flex items-center justify-center xs:ml-1 md:ml-0 md:mr-4'>
      <button
        style={{ background: 'rgb(161, 28, 28)' }}
        className='relative flex items-center justify-center h-[15px] px-1 text-white rounded-sm'
      >
        <span className=' pl-[10px] text-xs font-bold'>
          {_getLocalString('LOC_LIVE')}
        </span>
        <span
          style={{
            top: '4px',
            animation: 'blink 1s infinite',
            background: 'rgb(245, 245, 245)',
          }}
          className='absolute inset-y-0 left-1 flex items-center h-1.5 w-1.5 rounded-full'
        />
      </button>
      <style>{`
      @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
      }
      `}</style>
    </div>
  )
}
export interface IAccountDetail {
  item: any
}

export const AccountDetailEntry = (props: IAccountDetail) => {
  return !props.item.accountDetails || !props.item.accountDetails.length ? (
    <div className='text-center'> --- </div>
  ) : (
    <div
      className='text-sm justify-center text-black p-2'
      style={{ boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)' }}
    >
      {props.item.accountDetails.map((account: any) => (
        <div>
          <span className='bold'> {account.label} </span>
          {account.value}
        </div>
      ))}
    </div>
  )
}

export const getAccountDetailFromPayment = (item: any) => {
  const excludedProperties =
    item.paymentType === 'UPI'
      ? [
          '_id',
          'paymentType',
          'active',
          'minAmount',
          'accountNumber',
          'walletNumber',
          'bankName',
          'accountName',
          'ifscCode',
          'maxAmount',
          'createdAt',
          'updatedAt',
          'remarks',
          'createdBy',
          'whiteLabelId',
        ]
      : [
          '_id',
          'paymentType',
          'active',
          'upiId',
          'mobileNumber',
          'appName',
          'minAmount',
          'maxAmount',
          'createdAt',
          'updatedAt',
          'remarks',
          'createdBy',
          'whiteLabelId',
        ]

  let labelValueArray = []

  for (let prop in item) {
    if (!excludedProperties.includes(prop)) {
      let propLocal = getPropLabelName(prop)
      if (propLocal === 'customFields') {
        for (let index = 0; index < item[prop].length; index++) {
          labelValueArray.push({
            Label: item[prop][index].label,
            Value: item[prop][index].value,
          })
        }
      } else {
        labelValueArray.push({ Label: propLocal, Value: item[prop] })
      }
    }
  }

  return labelValueArray
}

const getPropLabelName = (name: string) => {
  const hashmap: any = {}
  hashmap['accountNumber'] = 'Account Number'
  hashmap['ifscCode'] = 'IFSC Code'
  hashmap['accountName'] = 'Account Name'
  hashmap['bankName'] = 'Bank Name'
  hashmap['walletNumber'] = 'Wallet Number'
  hashmap['mobileNumber'] = 'Mobile Number'
  hashmap['appName'] = 'App Name'
  hashmap['upiId'] = 'UPI Id'

  let nameLocal = hashmap[name]
  if (nameLocal) {
    return nameLocal
  } else {
    return name
  }
}

export const AccountDetailForDepositEntry = (props: IAccountDetail) => {
  return (
    <div
      className='p-2 text-sm justify-center text-black'
      style={{ boxShadow: '0 2px 10px rgba(0, 0, 0, 0.5)' }}
    >
      {props.item.depositTypeDetails &&
        Object.keys(props.item.depositTypeDetails).length &&
        getAccountDetailFromPayment(props.item.depositTypeDetails).map(
          (account: any) => (
            <div className='flex'>
              <div className='flex justify-between  w-full'>
                <span
                  className='font-bold'
                  style={{
                    letterSpacing: '1px',
                    textTransform: 'capitalize',
                  }}
                >
                  {account.Label}:
                </span>
                <span className='mr-2' style={{ letterSpacing: '1px' }}>
                  {account.Value}
                </span>
              </div>
            </div>
          ),
        )}
    </div>
  )
}

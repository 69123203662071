import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from '../../../redux/type'
import {
  onMarketClick,
  updateHashMap,
  updateOpenLoginScreen,
} from '../../../redux/actions/userAction/betSlipAction'
import {
  AddBetFairMarketStatus,
  AddBookMakerMarketStatus,
  AddSessionMarketStatus,
  removeMarkStatus,
} from '../../../redux/actions/marketStatusAction'
import {
  addFavoriteMarketList,
  deleteFavoriteMarket,
  getGameManagementCommonAction,
} from '../../../redux'
import { removeOpenBet } from '../../../redux/actions/userAction/openBetAction'
import { MarketContent } from './Content/MarketContent'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const betSlip = state.betSlip
  const selectedMarket = state.gameManagementCommon.selectedMarket
  const marketStatus = state.marketStatus
  const userData = state.login.userData
  const betHistory = state.reports.betHistory
  const gameManagementThirdParty =
    state.gameManagementThirdParty.gamesThirdPartyData
  const currentDomain = state.currentDomain
  const marketFavoriteList = state.login.marketFavoriteList
  const resultDeclaredMarketId = state.appConfiguration.resultDeclaredMarketId
  const deletedEventId = state.appConfiguration.deletedEventId
  const isLogin = state.login.isLogin
  const commonMarket = state.gameManagementCommon.gamesCommonData
  const openBet = state.reports.openBet
  const getGamesCommonDataLoading =
    state.gameManagementCommon.getGamesCommonDataLoading
  const isMarketDeletedload = state.gameManagementCommon.isMarketDeletedload
  const isMarketAddedLoad = state.gameManagementCommon.isMarketAddedLoad
  const deletedMarketList = state.gameManagementCommon.deletedMarketList
  const scoreData = state.score.scoreData
  const modifyMarket = state.appConfiguration.modifyMarket
  const useSocket = state.appConfiguration.useSocket
  const selectedEventId = state.appConfiguration.selectedEventId
  const eventDetail = state.appConfiguration.eventDetail
  const tokenExpire = state.appConfiguration.tokenExpire

  return {
    eventDetail,
    tokenExpire,
    selectedEventId,
    isLogin,
    modifyMarket,
    scoreData,
    deletedMarketList,
    isMarketDeletedload,
    isMarketAddedLoad,
    getGamesCommonDataLoading,
    openBet,
    selectedMarket,
    deletedEventId,
    isDarkMode,
    commonMarket,
    resultDeclaredMarketId,
    marketFavoriteList,
    betSlip,
    marketStatus,
    userData,
    betHistory,
    gameManagementThirdParty,
    currentDomain,
    useSocket,
  }
}

const NewConnectedMarket: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  {
    onMarketClick,
    AddBetFairMarketStatus,
    updateHashMap,
    AddSessionMarketStatus,
    AddBookMakerMarketStatus,
    removeMarkStatus,
    updateOpenLoginScreen,
    addFavoriteMarketList,
    deleteFavoriteMarket,
    getGameManagementCommonAction,
    removeOpenBet,
  },
)(MarketContent as any)

export default NewConnectedMarket

import { forwardRef } from 'react'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { FormControlLabel } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useSelector } from 'react-redux'
import {
  IPartA,
  IPartB,
  ITermsAndCondition,
} from '../../types/interfaces/themeConfiguration'

interface ITerms {
  setIsTermsAndConditions: (isLogin: boolean) => void
}
export const TermsAndConditions = forwardRef((props: ITerms, ref: any) => {
  const onCloseModal = () => {
    props.setIsTermsAndConditions(false)
  }
  const termsConditionData: ITermsAndCondition = useSelector(
    (state: any) => state.appCommonTheme.termsAndCondition,
  )

  interface IContentTerms {
    partA?: IPartA
    partB?: IPartB
  }

  const Content = (props: IContentTerms) => {
    const { partA } = props
    return (
      <div className='termsTextContainer '>
        <div className='text'>
          <p className='point'>
            {'1)'} {partA?.points1?.title}
          </p>
          <p>{partA?.points1?.description2}</p>
        </div>

        <ul className='bullet-points'>
          <li>{partA?.points1?.description3}</li>
          <li>{partA?.points1?.description4}</li>
        </ul>

        <div className='text'>
          <p>
            {partA?.points1?.description5}
            {partA?.points1?.description6}
          </p>
        </div>
        <div className='text'>
          <p className='point'> {`2) ${partA?.points2?.title}`}</p>
          <p></p>
        </div>
        <ul className='bullet-points'>
          <li>{partA?.points2?.description1}</li>
          <li>{partA?.points2?.description2}</li>
        </ul>
      </div>
    )
  }

  return (
    <div
      ref={ref}
      id='normalFont'
      className='termsWrapperComponent scrollbar_hide'
    >
      <div className='termsHeader'>
        <NotificationsNoneIcon />

        <div className='termsHeaderText'>
          Beware of Phishing Websites Before Login. Enable <b>Security Auth</b>{' '}
          To Secure Your ID.
        </div>
        <DisabledByDefaultIcon
          onClick={onCloseModal}
          className='cursor-pointer'
        />
      </div>
      <div className='termsContent'>
        <div className='termsHeading'>
          {_getLocalString('LOC_TERMS_UPPER_CASE')}
        </div>
        <div className='termsSubHeading'>
          PART A - {termsConditionData?.partA?.title}
        </div>
        {<Content partA={termsConditionData?.partA} />}
        <div className='footerContainer my-4'>
          <FormControlLabel
            control={
              <Checkbox
                className='termCheckbox'
                color='error'
                id='remeberMe'
                defaultChecked
              />
            }
            label={`I accept all the Terms & Conditions`}
          />
          <button onClick={onCloseModal} className='footerButton'>
            Continue
          </button>
        </div>
      </div>
    </div>
  )
})

import {
  DEFAULT_LAZER7_WEBSITE_NAME,
  DEFAULT_WEBSITE_NAME,
  IS_LOCAL_FUTURE9,
} from '../../api'
import { getAssetUrl } from '../../utils'

const SportsChip = (props: any) => {
  const currentDomain = window.location.hostname
  const domainName = currentDomain.split('.')[0]

  return (
    <div
      style={{
        background:
          domainName !== DEFAULT_WEBSITE_NAME &&
          domainName !== DEFAULT_LAZER7_WEBSITE_NAME &&
          domainName !== 'localhost' &&
          IS_LOCAL_FUTURE9
            ? ''
            : `url(${getAssetUrl('games/highlight-bg.webp')}) #d3d3d3a1`,
      }}
      className='rounded-md overflow-auto AtScrollHide !bg-no-repeat !bg-cover !bg-top  max-w-full'
    >
      <div className='sm:py-2 px-2 sm:px-3 md:justify-start justify-center md:px-5 bg-cover bg-[color:var(--highLight-color)] sm:h-[30px] h-[35px] bg-blend-color bg-no-repeat bg-center flex items-center  text-white  font-primary rounded-lg text-[15px] font-bold leading-[13px] tracking-normal text-center whitespace-nowrap '>
        <img
          alt='Sports Icon'
          className='w-5 mr-3 sm:w-4'
          src={getAssetUrl(
            `sports/png/${
              props.data.marketId.eventType.name &&
              props.data.marketId.eventType.name
                .replace(/\s/g, '')
                .toLowerCase()
            }.webp`,
          )}
        />
        {props.data.marketId.event.name}
      </div>
    </div>
  )
}

export default SportsChip

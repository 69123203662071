import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import { makePostHttpCallAsync } from '../../api/httpMethod'
import { URLType } from '../../api'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { USER_TYPES_ALL, USER_TYPES_ALL_B2C } from '../type/AppConstant'

export const CREATE_USER = '@USER/CRAETE_USER'

export const createUser = (
  payload: URLSearchParams,
  userType: string,
  currentDomain: ICurrentDomain,
  CB: () => null,
) => {
  const dispatchType = CREATE_USER
  let api = getUserApi(currentDomain.type, userType)

  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: dispatchType, loading: true })
      makePostHttpCallAsync(
        dispatch,
        api,
        currentDomain.type,
        payload,
        false,
        true,
        'application/x-www-form-urlencoded',
        true,
        'User Created',
      )
        .then((response: any) => {
          dispatch({ type: dispatchType, loading: false })
          CB && CB()
        })
        .catch(async (error: any) => {
          let err = await error
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}
const getUserApi = (urlType: URLType | string, userType: string): string => {
  if (urlType === URLType.b2c) {
    if (userType === USER_TYPES_ALL_B2C[0]?.key)
      return 'api/administrator/createUser'
    if (userType === USER_TYPES_ALL_B2C[1]?.key)
      return 'api/whiteLabel/createUser'
    if (userType === USER_TYPES_ALL_B2C[2]?.key) return 'api/branch/createUser'
    if (userType === USER_TYPES_ALL_B2C[3]?.key) return 'api/branch/createUser'
    if (userType === USER_TYPES_ALL_B2C[4]?.key)
      return 'api/subBranch/createUser'
  } else if (userType === USER_TYPES_ALL[0]?.key)
    return 'api/administrator/createUser'
  if (userType === USER_TYPES_ALL[1]?.key) return 'api/whiteLabel/createUser'
  if (userType === USER_TYPES_ALL[2]?.key) return 'api/admin/createUser'
  if (userType === USER_TYPES_ALL[3]?.key) return 'api/superMaster/createUser'
  if (userType === USER_TYPES_ALL[4]?.key) return 'api/master/createUser'
  if (userType === USER_TYPES_ALL[5]?.key) return 'api/agent/createUser'

  return 'notFound'
}

export const getUserApiUpdate = (
  urlType: URLType | string,
  userType: any,
): string => {
  if (urlType === URLType.b2c) {
    if (userType === USER_TYPES_ALL_B2C[0]?.key)
      return 'api/administrator/user/updateProfile'
    if (userType === USER_TYPES_ALL_B2C[1]?.key)
      return 'api/whiteLabel/user/updateProfile'
    if (userType === USER_TYPES_ALL_B2C[2]?.key)
      return 'api/branch/user/updateProfile'
    if (userType === USER_TYPES_ALL_B2C[3]?.key)
      return 'api/branch/user/updateProfile'
    if (userType === USER_TYPES_ALL_B2C[4]?.key)
      return 'api/subBranch/user/updateProfile'
    if (userType === USER_TYPES_ALL_B2C[5]?.key) return 'api/user/updateProfile'
  }

  return 'notFound'
}

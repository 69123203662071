import { useSelector } from 'react-redux'
import { getAssetUrl } from '../../../utils'
import SwiperCore from 'swiper'
import { SwiperOptions } from 'swiper/types/swiper-options'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone'
import { useNavigate } from 'react-router-dom'
import { HighLightComponent } from './HighLightComponent'
import { useEffect, useState } from 'react'
import { getSportcontentItemStatus } from '../../../utils/gameUtils/gameUtil'
import CommonSwiper from '../../../component/commonSwiper'
SwiperCore.use([FreeMode, Navigation])
import { FreeMode, Autoplay, Pagination, Navigation } from 'swiper/modules'

export const GameContent = () => {
  const navigate = useNavigate()
  const gameHighlights: any[] = useSelector(
    (state: any) => state.hightLightMatch.eventIds,
  )

  const sportcontentItemStatus = useSelector(
    (state: any) => state.marketStatus.sportcontentItemStatus,
  )

  const [marketStatus, setMarketStatus] = useState(
    getSportcontentItemStatus(sportcontentItemStatus),
  )

  useEffect(() => {
    setMarketStatus(getSportcontentItemStatus(sportcontentItemStatus))
  }, [sportcontentItemStatus])

  const isLogin: any = useSelector((state: any) => state.login.isLogin)

  const swiperModules = [FreeMode, Navigation]

  const swiperOptions: SwiperOptions = {
    freeMode: true,
    allowTouchMove: true,
    navigation: {
      nextEl: '.swiper-button-next-12',
      prevEl: '.swiper-button-prev-12',
    },
    // wrapperClass: "gap-3",
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      600: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
    },
  }

  return (
    <div className='relative flex items-center gap-0 px-2 mt-2  overflow-visible sm:px-0'>
      {gameHighlights.length > 0 && (
        <div className='swiper-button-prev-12 absolute md:block !text-white cursor-pointer z-10 bg-[#ffe2e254] rounded-r-md'>
          <ChevronLeftTwoToneIcon />
        </div>
      )}

      {gameHighlights.length > 0 ? (
        <CommonSwiper
          id='game-content-slider'
          loop={gameHighlights && gameHighlights.length > 3 ? true : false}
          speed={7000}
          parallax={true}
          freeMode={true}
          autoPlay={{
            delay: 0,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          modules={[...swiperModules, Pagination, Autoplay]}
          swiperOptions={swiperOptions}
          arrow={false}
          classNameSlider=' min-w-[250px] sm:min-w-[320px]  !w-auto max-w-[285px] sm:max-w-[380px] !cursor-pointer !mr-[8px]'
          centered={false}
        >
          {!!gameHighlights &&
            !!gameHighlights.length &&
            gameHighlights.map((v, i) => (
              <div
                key={i}
                style={{
                  background: `url(${getAssetUrl(
                    'games/highlight-bg.webp',
                  )}) #d3d3d3a1`,
                }}
                className='rounded-md !bg-no-repeat w-full !bg-cover !bg-center'
              >
                <HighLightComponent
                  status={marketStatus} // TODO: NEED TO COMPLETE
                  isLogin={isLogin}
                  hightLightItem={v}
                  navigate={navigate}
                />
              </div>
            ))}
        </CommonSwiper>
      ) : (
        <div
          className='dark:text-white text-black'
          style={{ textAlign: 'center', width: '100%' }}
        >
          No HighLight Matches
        </div>
      )}

      {gameHighlights.length > 0 && (
        <div className='swiper-button-next-12 absolute md:block !text-white cursor-pointer z-10 right-0 bg-[#ffe2e254] rounded-l-md'>
          <ChevronRightTwoToneIcon />
        </div>
      )}
    </div>
  )
}

import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../..'
import { GET_OPEN_BET } from '../reportAction/reportsAction'

export const removeOpenBet = (resultMarket: any, currentOpenBet: any[]) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: GET_OPEN_BET,
      page: { totalPages: 1 },
      payload: [],
      loading: true,
    })
    let resultMarketLocal = resultMarket
    let currentOpenBetLocal = currentOpenBet.filter(
      (obj: any) => obj.market._id !== resultMarketLocal.marketId._id,
    )
    dispatch({
      type: GET_OPEN_BET,
      page: { totalPages: 1 },
      payload: currentOpenBetLocal,
      loading: false,
    })
  }
}

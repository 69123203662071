import { Box, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { IUserData } from '../../../../types/interfaces/login'
import { SideBarContent } from './SideBarContent'
import NewConnectedMarket from '../NewConnectedMarket'
import { GameScoreDetail } from './component/GameScoreDetail'
import { EventGameDetail } from './component/EventGameDetail'
import ConnectedBetContent from './ConnectedBetContent'
import { UpcommingCountDown } from './UpcommingCountDown'

export interface IMainContent {
  isLogin: boolean
  userData: IUserData
}

export const MainContent: FC<IMainContent> = (props: IMainContent) => {
  const [isScoreCardShow, setIsScoreCardShow] = useState(false)
  const isSmallScreen = useMediaQuery('(max-width:900px)')
  const isAdmin = props.userData.userType !== 'user'

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <Box
        style={{ position: 'relative' }}
        className={`${
          props.isLogin && !isAdmin
            ? 'lg:col-span-10 col-span-12  gap-6 lg:grid-cols-9 grid-cols-12'
            : props.isLogin && isAdmin
              ? 'p-4 lg:col-span-12 col-span-12 gap-6 lg:grid-cols-12 grid-cols-12 rounded-2xl md:mr-5 lg:mr-5'
              : 'col-span-7 gap-6 grid-cols-7'
        } grid  `}
      >
        <Box
          style={{
            position: 'relative',
            paddingRight: '5px',
          }}
          className={`${
            props.isLogin
              ? isAdmin
                ? 'lg:col-9 md:col-span-8'
                : 'lg:col-span-6 md:col-span-8'
              : 'md:col-span-5 '
          } col-span-12 sm:px-0 px-2  mb-20`}
        >
          {/* GAME DETAIL */}
          <EventGameDetail isUser={props.userData.userType === 'user'} />

          <UpcommingCountDown />

          {/* MARKET DETAIL */}
          <Box>
            <GameScoreDetail
              isScoreCardShow={isScoreCardShow}
              setIsScoreCardShow={setIsScoreCardShow}
            />
            <NewConnectedMarket />
            {!!props.userData &&
              !!props.userData.userType &&
              isSmallScreen &&
              props.userData.userType !== 'user' && (
                <div>
                  <ConnectedBetContent />
                </div>
              )}
          </Box>
        </Box>
        <SideBarContent />
      </Box>
    </>
  )
}

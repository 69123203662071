import { FC, useCallback, useState } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Collapse, Tooltip, useMediaQuery } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { getAssetUrl } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { NavBarTileSports } from './NavBarTileSports'
import { AppState } from '../../../../redux'

export interface IArrayObject {
  _id?: any
  name?: string
  title?: string
  time?: string
  link?: string
  icon?: string
  image?: string
  openDate?: string
  matches?: IArrayObject[]
}

export interface INavBarTile {
  name: string
  icon?: string
  isSelected?: boolean
  count?: number
  isLive?: boolean
  updateOpenLoginScreen?: (openLoginScreen: boolean) => void
  link?: string
  isLogin?: boolean
  isDarkMode?: boolean
  isColored?: boolean
  hideDropDownIcon?: boolean
  matches?: IArrayObject[]
  isOpen?: string
  isPadding?: boolean
  setIsOpen?: Function
  isChildOpen?: string
  showGames?: boolean
  height?: number
  setIsChildOpen?: Function
  customRender?: (
    item: IArrayObject,
    i: Number,
    isSelected: boolean,
  ) => JSX.Element
  showSportTiles?: boolean
  onClickHandlerClose: () => void
  LiveCasino?: boolean
}

export const NavBarTile: FC<INavBarTile> = (props: INavBarTile) => {
  const isSmallScreen = useMediaQuery('(max-width:1200px)')
  const navigate = useNavigate()
  const [openNav, setOpenNav] = useState<string>('')
  const { gamesCommonData } = useSelector(
    (state: AppState) => state.gameManagementCommon,
  )
  const toggleModal = useCallback(() => {
    props.setIsChildOpen && props.setIsChildOpen('')
    if (props?.isOpen === props.name) {
      props?.setIsOpen && props?.setIsOpen('')
    } else {
      props?.setIsOpen && props?.setIsOpen(props.name)
    }
  }, [props?.isOpen])

  const [selectedItemIndex, setSelectedItemIndex] = useState<null | number>(
    null,
  )
  const handleItemClick = useCallback(
    (index: number) => {
      setSelectedItemIndex((prevState) => {
        if (prevState === index) {
          return null
        }
        return index
      })
    },
    [selectedItemIndex],
  )

  const clickFunctionality = () => {
    if (props.link) {
      if (!props.isLogin && props.updateOpenLoginScreen) {
        props.updateOpenLoginScreen(true)
      } else {
        navigate(props.link)
      }
    } else if (props?.onClickHandlerClose && !props?.LiveCasino) {
      props.onClickHandlerClose()
    }

    props.link ? navigate(props.link || '') : null
    props?.onClickHandlerClose && props?.onClickHandlerClose()
  }

  return (
    <div
      className={`cursor-pointer xs:border-transparent lg:border-[var(--sideNavBar-border-secondColor)] lg:border-solid xl:border-solid xl:border-[var(--sideNavBar-border-secondColor)] dark:border overflow-hidden first-of-type:!rounded-t-2xl last-of-type:!rounded-b-2xl  cursor-pointer
      ${
        props.matches && props.matches.length
          ? 'cursor-pointer dark:bg-[linear-gradient(180.48deg,#2C050600_12.85%,#69120800_85.99%),linear-gradient(173.49deg,rgba(77,9,30,0.48)_5.4%,rgba(77,9,30,0)_94.88%)]'
          : ''
      } mb-${props.isPadding ? 4 : 0}`}
    >
      <div
        onClick={clickFunctionality}
        style={{
          borderImage: props.isDarkMode
            ? 'linear-gradient(var(--sideNavBar-border-firstColor), var(--sideNavBar-border-secondColor)) 30'
            : '',
        }}
        className={`${
          props.isSelected
            ? 'lg:!bg-gradient-to-r xl:!bg-gradient-to-r from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)]'
            : ''
        } lg:border-[0.5px] xl:border-[0.5px] grid grid-flow-col xs:grid-cols-[33px_1fr_25px] lg:grid-cols-[33px_1fr_25px] xl:grid-cols-[38px_1fr_28px] items-center px-3 lg:dark:bg-[linear-gradient(173.49deg,var(--sideNavbar-darkGradient),rgba(77,9,30,0)_94.88%)] text-white text-xl h-[${!!props.height ? props.height : 50}px]`}
      >
        <div className='max-w-max w-[24px] h-[24px]'>
          <img
            src={getAssetUrl(`header/${props.icon}.webp`)}
            alt='Header Icon'
          />
        </div>
        <Tooltip
          title={props.name}
          arrow
          placement='top'
          className=''
          componentsProps={{
            tooltip: {
              className:
                'bg-black dark:bg-white !mb-0 dark:text-black text-white !text-sm',
            },
          }}
        >
          <div
            className={`${!isSmallScreen ? 'dark:text-white text-black ' : 'text-white'} font-primary font-normal whitespace-nowrap xl:text-[20px] lg:text-[16px] md:text-[16px] w-full text-ellipsis overflow-hidden leading-[31px] tracking-normal text-left`}
          >
            {props.name}
          </div>
        </Tooltip>
        {!!props.count && (
          <div
            className={`${
              !props.isSelected
                ? 'dark:text-[#DADADA] text-[#000000] '
                : 'text-[#C34C04]'
            } ${isSmallScreen && 'text-white'} max-w-max justify-self-end text-base !font-normal `}
          >
            {props.count}
          </div>
        )}
        {!!props.isLive && (
          <div className='md:text-xs text-sm justify-self-end gap-1 px-1 uppercase items-center flex justify-center bg-[#FB4A59] text-white self-center'>
            <FiberManualRecordIcon className='!text-[8px]' /> <span>Live</span>
          </div>
        )}

        {!props.isLive &&
        (props.matches?.length || props.showSportTiles) &&
        !props.hideDropDownIcon ? (
          props?.isOpen === props.name ? (
            <ExpandLess
              fontSize='large'
              className={'dark:text-white text-black'}
              onClick={() => toggleModal()}
            />
          ) : (
            <ExpandMore
              fontSize='large'
              className={'dark:text-white text-black'}
              onClick={() => toggleModal()}
            />
          )
        ) : null}
      </div>

      <Collapse in={props?.isOpen === props.name} timeout='auto' unmountOnExit>
        {props.matches && props.matches.length > 0
          ? props.matches?.map((item, index: number) => {
              return props.customRender ? (
                props.customRender(
                  { ...item, icon: props.icon },
                  index,
                  props.isSelected || false,
                )
              ) : (
                <div key={index}>
                  <div
                    onClick={() => {
                      if (item.link) {
                        if (item.title)
                          localStorage.setItem('selectedGameTile', item.title)
                        navigate(item.link)
                        if (props?.onClickHandlerClose) {
                          props.onClickHandlerClose()
                        }
                      }
                    }}
                    style={{
                      borderImage: props.isDarkMode
                        ? 'linear-gradient(var(--sideNavBar-firstGradient), var(--sideNavBar-secondGradiendFrom)) 30'
                        : '',
                    }}
                    className={`${
                      props.isSelected
                        ? 'xs:bg-[#f63123] md:bg-transparent lg:bg-transparent xl:bg-transparent '
                        : ''
                    } pt-3 pb-3 whitespace-nowrap lg:border-[0.5px] xl:border-[0.5px] grid grid-flow-col xs:grid-cols-[33px_1fr_25px] lg:grid-cols-[20px_1fr_26px] xl:grid-cols-[38px_1fr_28px] items-center px-3 lg:dark:bg-[linear-gradient(173.49deg,var(--sideNavbar-darkGradient),rgba(77,9,30,0)_94.88%)] dark:text-white text-black h-[${!!props.height ? props.height : 30}px] ${index === selectedItemIndex ? 'lg:!bg-gradient-to-r  !from-[var(--inPlay-match-gradientFrom)] !to-[var(--inPlay-match-gradientTo)] xl:!bg-gradient-to-r  !from-[#F04A36] !to-[#FAA43F]' : ''}`}
                  >
                    {item.image ? (
                      <div className='min-w-max'>
                        <img
                          alt='Header Icon'
                          src={getAssetUrl(`header/${item.image}.webp`)}
                        />
                      </div>
                    ) : (
                      <div className='w-[7px] h-auto'>
                        <img
                          alt='Header Dot Icon'
                          src={getAssetUrl('header/dot.webp')}
                        />
                      </div>
                    )}
                    <div
                      onClick={() => handleItemClick(index)}
                      className='font-primary lg:text-[14px] xl:text-[17px] font-normal leading-[19px] tracking-normal text-left'
                    >
                      {item.title || item.name}
                    </div>
                  </div>
                </div>
              )
            })
          : ''}

        {props.showSportTiles &&
          !!gamesCommonData &&
          gamesCommonData.map((sports: any, index: number) => {
            return (
              <NavBarTileSports
                key={index}
                index={index}
                onClickHandlerClose={props.onClickHandlerClose}
                navigate={navigate}
                setOpenNav={setOpenNav}
                openNav={openNav}
                isDarkMode={props.isDarkMode || true}
                sports={sports}
                isPadding={true}
              />
            )
          })}
      </Collapse>
    </div>
  )
}

import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from '../../../redux/type'
import { slotsCasinoContainer } from './slotsCasinoContainer'
import { updateOpenLoginScreen } from '../../../redux/actions/userAction/betSlipAction'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const lang = state.language.lang
  const isLogin = state.login.isLogin
  const userData = state.login.userData
  const liveCasinoData = state.adminGames.liveCasinoData
  const liveCasinoLoading = state.adminGames.liveCasinoLoading
  const virtualCasinoData = state.adminGames.virtualCasinoData
  const virtualCasinoLoading = state.adminGames.virtualCasinoLoading
  const crashGamesLoading = state.adminGames.crashGamesLoading
  const crashGamesData = state.adminGames.crashGamesData
  const slotGamesData = state.adminGames.slotGamesData
  const slotGamesLoading = state.adminGames.slotGamesLoading
  const lotteryGamesLoading = state.adminGames.lotteryGamesLoading
  const lotteryGamesData = state.adminGames.lotteryGamesData
  const deletedEventId = state.appConfiguration.deletedEventId
  const selectedGameType = state.appConfiguration.selectedGameType
  const selectedSubProvider = state.appConfiguration.selectedSubProvider
  const selectedSubProviderData = state.appConfiguration.selectedSubProviderData
  const gamePathName = state.appConfiguration.gamePathName
  const selectedInnerBox = state.appConfiguration.selectedInnerBox
  const casinoLoader = state.adminGames.casinoLoader

  return {
    casinoLoader,
    selectedGameType,
    gamePathName,
    selectedInnerBox,
    selectedSubProviderData,
    selectedSubProvider,
    deletedEventId,
    liveCasinoData,
    liveCasinoLoading,
    virtualCasinoData,
    virtualCasinoLoading,
    crashGamesLoading,
    crashGamesData,
    slotGamesData,
    slotGamesLoading,
    lotteryGamesLoading,
    lotteryGamesData,
    isDarkMode,
    lang,
    isLogin,
    userData,
  }
}

const ConnectedSlotsCansino: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  { updateOpenLoginScreen },
)(slotsCasinoContainer as any)

export default ConnectedSlotsCansino

import {
  GET_TRANSACTION_DEPOSIT_LIST,
  GET_TRANSACTION_DEPOSIT_LIST_LOADING,
  GET_TRANSACTION_DEPOSIT_LIST_PAGE,
  GET_TRANSACTION_LIST,
  GET_TRANSACTION_WIDRAW_LIST,
  GET_TRANSACTION_WIDRAW_LIST_LOADING,
  GET_TRANSACTION_WIDRAW_LIST_PAGE,
} from '../actions/transactionListAction'

export interface ISetTransactionList {
  type: string
  loading: boolean
  data: ITransactionListData[]
}

export interface ITransactionListData {
  pts: number
  userId: IUser
  debit: number
  type: string
  remarks: string
  createdAt: string
  updatedAt: string
}

interface IUser {
  userName: string
  email: string
}

export interface ITransactionList {
  getTransactionListLoading: boolean
  getDepositLoading: boolean
  getWidrawLoading: boolean
  depositData: any[]
  widrawData: any[]
  transactionList: ITransactionListData[]
  depositDataPagination: any
  widrawDataPagination: any
}

const initialState: ITransactionList = {
  getTransactionListLoading: false,
  getDepositLoading: false,
  getWidrawLoading: false,
  transactionList: [],
  depositData: [],
  widrawData: [],
  depositDataPagination: {},
  widrawDataPagination: {},
}

export default function (state = initialState, action: ISetTransactionList) {
  switch (action.type) {
    case GET_TRANSACTION_LIST: {
      return {
        ...state,
        getTransactionListLoading: action.loading,
        transactionList: action.data,
      }
    }

    case GET_TRANSACTION_WIDRAW_LIST_PAGE: {
      return {
        ...state,
        widrawDataPagination: action.data,
        getWidrawLoading: action.loading,
      }
    }

    case GET_TRANSACTION_DEPOSIT_LIST_PAGE: {
      return {
        ...state,
        depositDataPagination: action.data,
        getDepositLoading: action.loading,
      }
    }

    case GET_TRANSACTION_DEPOSIT_LIST: {
      return {
        ...state,
        depositData: action.data,
        getDepositLoading: action.loading,
      }
    }

    case GET_TRANSACTION_DEPOSIT_LIST_LOADING: {
      return {
        ...state,
        getDepositLoading: action.loading,
      }
    }

    case GET_TRANSACTION_WIDRAW_LIST: {
      return {
        ...state,
        widrawData: action.data,
        getWidrawLoading: action.loading,
      }
    }

    case GET_TRANSACTION_WIDRAW_LIST_LOADING: {
      return {
        ...state,
        getWidrawLoading: action.loading,
      }
    }

    default:
      return state
  }
}

import { useSelector } from 'react-redux'
import { getAssetUrl, getThemeAssetUrl } from '../../../../utils'
import { SportsBookSlider } from './SportsBookSlider'
import { Container, Skeleton } from '@mui/material'
import {
  COMMON_WEBSITE,
  DEFAULT_LAZER7_WEBSITE_NAME,
  DEFAULT_TEST_WEBSITE_NAME,
  DEFAULT_WEBSITE_NAME,
  IS_LOCAL_FUTURE9,
} from '../../../../api'
import { toast } from 'react-toastify'
import { useMemo } from 'react'
import { SportsBannerSlider } from './SportsBannerSlider'
import { isFuture9 } from '../../../../App'

export interface ISportsBook {
  isLogin: boolean
  divHeight: number
  updateOpenLoginScreen: (openLoginScreen: boolean) => void
}

export const SportsBook = (props: ISportsBook) => {
  const { siteLoader } = useSelector((state: any) => state.appConfiguration)
  const { themeColor, paymentMethodColor } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )
  const casinoTiles = useSelector(
    (state: any) => state.appCommonTheme?.casinoTiles,
  )

  const sportsBookClick = (id: string) => {
    if (!props.isLogin) {
      props.updateOpenLoginScreen(true)
    } else {
      toast.info('Comming Soon!')
    }
  }

  const SPORTS_BOOK = [
    {
      id: '998917',
      name: _getLocalString('LOC_VOLLEYBALL'),
      image: `${getAssetUrl('sportsBook/volley-ball.svg')}`,
      normalImage: `${getAssetUrl('sportsBook/common-volleyball.svg')}`,
    },
    {
      id: '5',
      name: _getLocalString('LOC_RUGBY'),
      image: `${getAssetUrl('sportsBook/rugby.svg')}`,
      normalImage: `${getAssetUrl('sportsBook/common-rugby.svg')}`,
    },
    {
      id: '7511',
      name: _getLocalString('LOC_BASEBALL'),
      image: `${getAssetUrl('sportsBook/base-ball.svg')}`,
      normalImage: `${getAssetUrl('sportsBook/common-baseball.svg')}`,
    },
    {
      id: '468328',
      name: _getLocalString('LOC_HANDBALL'),
      image: `${getAssetUrl('sportsBook/handball.svg')}`,
      normalImage: `${getAssetUrl('sportsBook/common-handball.svg')}`,
    },
    {
      id: '2',
      name: _getLocalString('LOC_TABLE_TENNIS'),
      image: `${getAssetUrl('sportsBook/table-tennis.svg')}`,
      normalImage: `${getAssetUrl('sportsBook/common-table-tennis.svg')}`,
    },
    {
      id: '7522',
      name: _getLocalString('LOC_BASKET_BALL'),
      image: `${getAssetUrl('sportsBook/basket-ball.svg')}`,
      normalImage: `${getAssetUrl('sportsBook/common-basketball.svg')}`,
    },
    {
      id: '61420',
      name: _getLocalString('LOC_FORMULA1'),
      image: `${getAssetUrl('sportsBook/formula1.svg')}`,
      normalImage: `${getAssetUrl('sportsBook/common-formula1.svg')}`,
    },
  ]

  return (
    <div className='px-3    !w-full'>
      {/* -------------------------------------------------- */}
      {siteLoader ? (
        <>
          {' '}
          <Skeleton width={'100%'} height={50} />
          <div className='flex mb-3'>
            {[1, 2, 3].map((item) => (
              <Skeleton
                key={item}
                className='ml-4 mt-2'
                variant='circular'
                width={100}
                height={100}
              />
            ))}
            <Skeleton
              key={4}
              className='sm:hidden ml-4 mt-2'
              variant='circular'
              width={100}
              height={100}
            />
          </div>
          <div className='flex mb-3'>
            {[1, 2, 3].map((item) => (
              <Skeleton
                key={item}
                className='ml-4 mt-2'
                variant='circular'
                width={100}
                height={100}
              />
            ))}
            <Skeleton
              key={4}
              className='sm:hidden ml-4 mt-2'
              variant='circular'
              width={100}
              height={100}
            />
          </div>
          <div className='flex'>
            {[1, 2, 3].map((item) => (
              <Skeleton
                key={item}
                className='ml-4 mt-2'
                variant='circular'
                width={100}
                height={100}
              />
            ))}
            <Skeleton
              key={4}
              className='sm:hidden ml-4 mt-2'
              variant='circular'
              width={100}
              height={100}
            />
          </div>
        </>
      ) : (
        <>
          <div className='!w-full'>
            <div
              style={{ lineHeight: 'normal' }}
              className='mb-2 mt-0 lg:grid xs:w-full sm:w-full gradient_text md:max-w-fit xl:text-[35px] lg:text-[28px] text-[24px]  font-bebas font-normal leading-[33px]  text-center md:text-left'
            >
              {_getLocalString('LOC_PREMIUM_SPORTS_BOOK')}
            </div>
          </div>
          <div className='hidden grid-cols-4 gap-3 mb-10 mt-9 lg:grid place-content-center place-items-center'>
            {SPORTS_BOOK.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  sportsBookClick(item.id)
                }}
                className='pointer flex flex-col items-center gap-3 text-black dark:text-white'
              >
                <div className=''>
                  <img
                    alt='Image Icon'
                    src={item.image}
                    className='object-contain xl:!w-14 xl:!h-14 lg:!w-10 lg:!h-10 !h-14 !w-14'
                  />
                </div>
                <div className='font-bold tracking-wider lg:text-xs xl:text-base font-bebas'>
                  {item.name}
                </div>
              </div>
            ))}
          </div>

          {/* TODO: Need to unhide for color */}
          <div className='flex flex-wrap  my-4 justify-center md:my-10 lg:hidden'>
            {SPORTS_BOOK.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  sportsBookClick(item.id)
                }}
                className='cursor-pointer shrink-0 mt-2 w-[80px] h-[80px] sm:w-auto sm:h-auto flex flex-col items-center gap-3 px-4 text-black dark:text-white '
              >
                <div className='cursor-pointer'>
                  <img
                    alt='Normal Image'
                    src={isFuture9() ? item.image : item.normalImage}
                    className='object-contain xl:!w-14 xl:!h-14 lg:!w-10 lg:!h-10 !h-14 !w-14'
                  />
                </div>
                <div className='md:text-xl text-[17px] font-bold tracking-wider font-bebas'>
                  {item.name}
                </div>
              </div>
            ))}
          </div>

          {/* ----------------------------------------------------------------- */}
          <div className='relative overflow-hidden '>
            <div className='xs:w-full sm:w-full gradient_text xl:text-[35px] lg:text-[28px] text-[24px] md:max-w-fit  lg:mb-8 md:mb-6 sm:mb-5 mb-2 font-bebas  font-normal leading-[53px]  text-center md:text-left '>
              {_getLocalString('LOC_NEWLY_LAUNCHED')}
            </div>

            <SportsBannerSlider divHeight={props.divHeight}>
              {casinoTiles &&
                casinoTiles.map((item: any, index: number) => (
                  <Banner
                    color={paymentMethodColor}
                    item={item}
                    key={index}
                    themeColor={themeColor}
                  />
                ))}
            </SportsBannerSlider>
          </div>
        </>
      )}
    </div>
  )
}

export const Banner = (props: {
  item: any
  themeColor: string
  color: string
}) => {
  const { type } = useSelector((state: any) => state.currentDomain)
  return (
    props.item.image && (
      <div
        style={{
          background: `url(${getThemeAssetUrl(props.item.image)})`,
          borderRadius: '0.5rem',
          backgroundColor: props.color,
        }}
        className='new-launch-fit-image !bg-[50%] !relative'
      >
        <Container
          maxWidth={'xl'}
          fixed
          className='relative  !flex h-full items-center'
        />
      </div>
    )
  )
}

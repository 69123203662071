import Marquee from 'react-fast-marquee'

export const Remarks = (props: { remarksMessage: string }) => {
  return (
    <div className='bg-[#4d091e]'>
      {props.remarksMessage && (
        <div className='ml-5'>
          <Marquee>
            <b> {props.remarksMessage.toUpperCase()} </b>
          </Marquee>
        </div>
      )}
    </div>
  )
}

import { FC, lazy, Suspense, useEffect, useState } from 'react'
import './App.css'
import { initializeStrings } from './utils/strings'
import { setTheme } from './utils/theme'
import './scss/main.scss'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import ConnectedBaseLayout from './component/layout/ConnectedBaseLayout'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT,
  APP_CONFIGURATION_FULL_SCREEN,
  APP_CONFIGURATION_URL_GAME_ID,
  APP_CONFIGURATION_URL_GAME_CODE,
  APP_CONFIGURATION_URL_PROVIDER_NAME,
  APP_CONFIGURATION_GAME_FULL_SCREEN,
  APP_CONFIGURATION_URL_TYPE,
  APP_SELECTED_EVENT_ID,
  APP_CONFIGURATION_SUB_PROVIDER_NAME,
  APP_CONFIGURATION_URL_GAME_NAME,
  LANGUAGE,
  POINT_UPDATE,
} from './redux'
import {
  COMMON_WEBSITE,
  DEFAULT_LAZER7_WEBSITE_NAME,
  DEFAULT_PR_WEBSITE_NAME,
  DEFAULT_TEST_WEBSITE_NAME,
  DEFAULT_WEBSITE_NAME,
  HEADERS,
  IS_LOCAL_FUTURE9,
  POINT_UPDATE_TIME,
} from './api'
import { createContext } from 'react'
import { IMarketStatusInitial } from './redux/reducers/marketStatusReducer'
import { publicRoutes, userRoutes } from './Routes'
import { isMobileScreensix, setBackgroundColor } from './utils/internalUtils'
import { IThemeConfiguration } from './types/interfaces/themeConfiguration'
import { ICurrentDomain } from './types/interfaces/currentDomain'
import { IUserData } from './types/interfaces/login'
import { extractFirstSegment } from './utils'
import PublicAPICall from './utils/PublicAPICall'
import { Fallback } from './Fallback'
import MaintencePage from './component/404/MaintencePage'
import withConnectionCheck from './withConnectionCheck'
import { WelcomeBannerPopup } from './component/welcomeBannerPopup/WelcomeBannerPopup'
import { decrypt } from './api/authHelper'
const CommonErrorPage = lazy(() => import('./component/404/CommonErrorPage'))

export const isFuture9 = () => {
  const currentDomain = window.location.hostname
  let domainName = currentDomain.split('.')[0]

  if (domainName === 'www' || domainName === 'test' || domainName === 'pp') {
    domainName = currentDomain.split('.')[1]
  }

  return (
    domainName === DEFAULT_WEBSITE_NAME ||
    domainName === DEFAULT_LAZER7_WEBSITE_NAME ||
    domainName === DEFAULT_TEST_WEBSITE_NAME ||
    domainName === DEFAULT_PR_WEBSITE_NAME ||
    domainName === COMMON_WEBSITE ||
    (domainName === 'localhost' && IS_LOCAL_FUTURE9)
  )
}

export const isActualFuture9 = () => {
  const currentDomain = window.location.hostname
  let domainName = currentDomain.split('.')[0]

  if (domainName === 'www') {
    domainName = currentDomain.split('.')[1]
  }

  return domainName === DEFAULT_WEBSITE_NAME
}

export type IAppContainerProps = {
  isDarkMode: boolean
  isMaintenance: boolean
  isDuplicateScreen: boolean
  logoutUser: any
  siteLoader: boolean
  pointUpdate: boolean
  getHomeData: any
  isDevToolsDetected: boolean
  marketRequest: any
  competitionRequest: any
  getCommunicationDetail: any
  lang: string
  themeConfiguration: IThemeConfiguration
  isLogin?: boolean
  firstTimeLogin: boolean
  marketStatus: IMarketStatusInitial
  currentDomain: ICurrentDomain
  userData?: IUserData
  getGameEventScoreAndLiveTV: Function
  selectedEventId: string
  getTournament: Function
  eventId: string
  getProfile?: (
    navigate: any,
    userType: string,
    currentDomain: ICurrentDomain,
    getUserDetail: boolean,
    cb?: () => void,
  ) => void
  domainUpdate: () => void
}

interface HomeLoaderData {
  date: string
}

const BASELAYOUT_VISIBILITY = [
  '/virtual-games',
  '/login-auth',
  '/live-casino',
  '/slot-games',
  '/lottery',
  '/search',
  '/sports-book',
  '/crash-games',
  '/game-screen',
  '/game-screen-play',
]

export const Context = createContext<any>({})

export function sleep(n: number = 500) {
  return new Promise((r) => setTimeout(r, n))
}

export async function homeLoader(): Promise<HomeLoaderData> {
  await sleep()
  return {
    date: new Date().toISOString(),
  }
}

const App: FC<IAppContainerProps> = (props: IAppContainerProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [reRender, setReRender] = useState(false)
  const [isPublic, setIsPublic] = useState(true)
  const [routerData, setRouterData] = useState(userRoutes)
  const { pathname } = useLocation()

  const [isPageLoaded, setIsPageLoader] = useState(true)
  const [isWelcomeBanner, setIsWelcomeBanner] = useState(true)
  const closeeWelcomeBanner = () => {
    setIsWelcomeBanner(false)
  }

  useEffect(() => {
    const url = window.location.hostname
    document.title = url
    props.domainUpdate()
    document.addEventListener('contextmenu', (event) => event.preventDefault())
  }, [])

  useEffect(() => {
    let path = pathname.split('/')
    if (path.length > 1 && path[1] === 'events') {
      if (!props.selectedEventId) {
        dispatch({
          type: APP_SELECTED_EVENT_ID,
          payload: decrypt(path[2]),
        })
      }
      dispatch({
        type: APP_CONFIGURATION_GAME_FULL_SCREEN,
        payload: true,
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_ID,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_CODE,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_NAME,
        payload: '',
      })
      localStorage.removeItem(APP_CONFIGURATION_URL_PROVIDER_NAME)
      localStorage.removeItem(APP_CONFIGURATION_URL_GAME_NAME)
      localStorage.removeItem(APP_CONFIGURATION_URL_TYPE)
      localStorage.removeItem(APP_CONFIGURATION_SUB_PROVIDER_NAME)
    } else if (
      path.length > 1 &&
      (path[1] === 'game-screen-play' || path[1] === 'login-auth')
    ) {
      dispatch({
        type: APP_CONFIGURATION_FULL_SCREEN,
        payload: true,
      })
    } else if (BASELAYOUT_VISIBILITY.includes(extractFirstSegment(pathname))) {
      dispatch({
        type: APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT,
        isBaseLayoutVisible: false,
      })
      dispatch({
        type: APP_CONFIGURATION_FULL_SCREEN,
        payload: false,
      })

      localStorage.removeItem(APP_CONFIGURATION_URL_PROVIDER_NAME)
      localStorage.removeItem(APP_CONFIGURATION_URL_GAME_ID)
      localStorage.removeItem(APP_CONFIGURATION_URL_GAME_CODE)
      localStorage.removeItem(APP_CONFIGURATION_URL_GAME_NAME)
      localStorage.removeItem(APP_CONFIGURATION_SUB_PROVIDER_NAME)
      dispatch({
        type: APP_CONFIGURATION_URL_PROVIDER_NAME,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_ID,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_CODE,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_NAME,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_SUB_PROVIDER_NAME,
        payloaD: '',
      })
    } else {
      dispatch({
        type: APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT,
        isBaseLayoutVisible: true,
      })
      dispatch({
        type: APP_CONFIGURATION_FULL_SCREEN,
        payload: false,
      })

      localStorage.removeItem(APP_CONFIGURATION_URL_PROVIDER_NAME)
      localStorage.removeItem(APP_CONFIGURATION_URL_GAME_ID)
      localStorage.removeItem(APP_CONFIGURATION_URL_GAME_CODE)
      localStorage.removeItem(APP_CONFIGURATION_URL_GAME_NAME)
      localStorage.removeItem(APP_CONFIGURATION_SUB_PROVIDER_NAME)
      dispatch({
        type: APP_CONFIGURATION_GAME_FULL_SCREEN,
        payload: false,
      })
      dispatch({
        type: APP_CONFIGURATION_URL_PROVIDER_NAME,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_ID,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_CODE,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_URL_GAME_NAME,
        payload: '',
      })
      dispatch({
        type: APP_CONFIGURATION_SUB_PROVIDER_NAME,
        payload: '',
      })
    }
  }, [pathname])

  const updateModifyMarket = (payload: any) => {
    dispatch(payload)
  }

  useEffect(() => {
    const isFuture9Local = isFuture9()

    setBackgroundColor(
      props.siteLoader,
      props.isDarkMode,
      props.themeConfiguration?.background?.dark || '',
      props.themeConfiguration?.background?.light || '',
      isFuture9Local,
      props.themeConfiguration?.themeColor!,
      props.themeConfiguration?.theme?.themeLightColor!,
    )
    setTheme(props.themeConfiguration, isFuture9Local)
  }, [props.isDarkMode, props.themeConfiguration])

  useEffect(() => {
    let localLanguage = localStorage.getItem(HEADERS.language)
    localLanguage &&
      dispatch({
        type: LANGUAGE,
        lang: localLanguage,
      })
    initializeStrings(localLanguage ? localLanguage : props.lang).then(() =>
      setReRender(!reRender),
    )
  }, [props.lang])

  const checkStateAndCallApi = (pointUpdate: any) => {
    if (pointUpdate && props.isLogin) {
      props.getProfile &&
        props.getProfile(
          navigate,
          props.userData?.userType || 'user',
          props.currentDomain,
          true,
        )
      dispatch({
        type: POINT_UPDATE,
        payload: false,
      })
    }
  }

  useEffect(() => {
    setInterval(
      () => checkStateAndCallApi(props.pointUpdate),
      POINT_UPDATE_TIME,
    )
  }, [props.pointUpdate])

  useEffect(() => {
    const token =
      localStorage.getItem(HEADERS.xAuthentication) ??
      sessionStorage.getItem(HEADERS.xAuthentication)
    let userType = localStorage.getItem(HEADERS.userType)
    let domainUpdate = localStorage.getItem(HEADERS.domain)
      ? JSON.parse(localStorage.getItem(HEADERS.domain) || '')
      : ''

    if (
      !props.isLogin &&
      token &&
      userType &&
      domainUpdate &&
      domainUpdate.type !== '' &&
      userType !== 'undefined'
    ) {
      props.getProfile &&
        props.getProfile(navigate, userType, domainUpdate, true, () => {
          setIsPageLoader(false)
        })
    }

    if (!props.siteLoader) {
      if (props.isLogin) {
        setIsPublic(false)
        if (userType === 'user') {
          setRouterData(userRoutes)
          props.getGameEventScoreAndLiveTV(true, false)
          props.getTournament()
          props.getGameEventScoreAndLiveTV(false, false)
        }
      } else if (
        !(
          token &&
          userType &&
          domainUpdate &&
          domainUpdate.type !== '' &&
          userType !== 'undefined'
        )
      ) {
        setRouterData(publicRoutes)
        setIsPageLoader(false)
        setIsPublic(true)
        return
      }
    }
  }, [props.currentDomain, props.isLogin])

  let mobileScreen = isMobileScreensix()
  return (
    <>
      {props.isMaintenance &&
      props.isLogin &&
      props.userData?.userType !== 'administrator' ? (
        <div>
          <MaintencePage
            contentMessage='Website is Under Maintenance'
            titleError='OOPS!'
            titleMessage='Wait for a few Minute'
            Logout={props.logoutUser}
            currentDomain={props.currentDomain}
            navigate={navigate}
          />
        </div>
      ) : props.isDuplicateScreen && props.isLogin ? (
        <div>
          <MaintencePage
            contentMessage='Duplicate Screen or UnAuthorized Access'
            titleError='OOPS!'
            titleMessage='Close the Screen'
            Logout={props.logoutUser}
            hideLogout={true}
            currentDomain={props.currentDomain}
            navigate={navigate}
          />
        </div>
      ) : (
        <div className='root_container'>
          <ToastContainer
            position='top-right'
            autoClose={2000}
            newestOnTop
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            style={{ zIndex: 99999 }}
            theme={props.isDarkMode ? 'dark' : 'light'}
          />
          {props.isDevToolsDetected ? (
            <CommonErrorPage
              contentMessage=' Please close the Developer tools, Thanks for your support'
              titleError='OOPS!'
              titleMessage='Close Window'
            />
          ) : isPageLoaded || props.siteLoader ? (
            <Fallback isFuture9={isFuture9()} />
          ) : (
            <Suspense fallback={<Fallback isFuture9={isFuture9()} />}>
              <Context.Consumer>
                {() => (
                  <>
                    {isPublic && (
                      <PublicAPICall
                        getHomeData={props.getHomeData}
                        isLogin={props.isLogin || false}
                        currentDomain={props.currentDomain}
                        isPublic={isPublic}
                      />
                    )}
                    <>
                      {((mobileScreen &&
                        props.themeConfiguration?.theme?.mobileBanner) ||
                        (!mobileScreen &&
                          props.themeConfiguration?.theme?.banner)) && (
                        <WelcomeBannerPopup
                          screenText={
                            props.themeConfiguration?.theme?.bannerText
                          }
                          isOpen={
                            isWelcomeBanner &&
                            props.firstTimeLogin &&
                            props.isLogin! &&
                            props.userData?.userType === 'user'
                          }
                          onRequestClose={closeeWelcomeBanner}
                          screenUrl={
                            mobileScreen
                              ? props.themeConfiguration?.theme?.mobileBanner ||
                                ''
                              : props.themeConfiguration?.theme?.banner || ''
                          }
                        />
                      )}
                      <ConnectedBaseLayout
                        isLogin={props.isLogin}
                        children={
                          routerData && routerData?.length ? (
                            <>
                              <Routes>
                                {routerData.map((route: any, index: number) => {
                                  return (
                                    <Route
                                      key={index}
                                      caseSensitive={route.exact}
                                      path={route.path}
                                      element={route.element}
                                    />
                                  )
                                })}
                                {routerData?.length ? (
                                  <Route
                                    path='*'
                                    element={
                                      <Navigate
                                        to={`${routerData[0]?.path}`}
                                        replace
                                      />
                                    }
                                  />
                                ) : null}
                              </Routes>
                            </>
                          ) : null
                        }
                      />
                    </>
                  </>
                )}
              </Context.Consumer>
            </Suspense>
          )}
        </div>
      )}
    </>
  )
}

export default withConnectionCheck(App)

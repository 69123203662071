import { useNavigate, useParams } from 'react-router-dom'
import { getAssetUrl } from '../../../utils'
import { SportsBookSlider } from '../../landing/component/sportsBook/SportsBookSlider'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone'
import { PlayArrow } from '@mui/icons-material'
import { isFuture9 } from '../../../App'
import { toast } from 'react-toastify'
import { SAVE_SEARCH_MARKETIDS } from '../../../redux/actions/marketStatusAction'
import { APP_GLOBAL_SEARCH_CONTENT } from '../../../redux'
import { useDispatch } from 'react-redux'

export const GameHeader = (props: {
  setId?: React.Dispatch<React.SetStateAction<string>>
  isSchedule?: boolean
}) => {
  const { gameType } = useParams()
  const navigate = useNavigate()

  const DEFAULTGAMES = [
    {
      name: _getLocalString('LOC_FAVORITE'),
      id: 100,
      image: 'header/favourite.png',
      link: '/favorite',
    },
    {
      name: _getLocalString('LOC_IN_PLAY'),
      id: 'inPlay',
      image: 'asset/inplay.png',
      link: '/inPlay',
    },
    {
      name: _getLocalString('LOC_CRICKET'),
      id: 4,
      image: 'png/cricket.webp',
      link: '/4',
    },
    {
      name: _getLocalString('LOC_FOOTBALL'),
      id: 1,
      image: 'png/soccer.webp',
      link: '/1',
    },
    {
      name: _getLocalString('LOC_TENNIS'),
      id: 2,
      image: 'png/tennis.webp',
      link: '/2',
    },
    {
      name: _getLocalString('LOC_HORSE_RACING'),
      id: 7,
      image: 'png/horseracing.webp',
      link: '/7',
    },
    {
      name: _getLocalString('LOC_GREYHOND_RACING'),
      id: 4339,
      image: 'png/greyhoundracing.webp',
      link: '/4339',
    },
    {
      name: _getLocalString('LOC_BASKET_BALL_SMALLCASE'),
      id: 7522,
      image: 'png/basketball.webp',
      link: '/7522',
    },
    {
      name: _getLocalString('LOC_POLITICS'),
      id: 2378961,
      image: 'png/politics.webp',
      link: '/2378961',
    },
  ]

  const dispatch = useDispatch()

  return (
    <div className='relative flex items-center gap-0 overflow-visible'>
      <div className='navigate_prev_14 absolute md:block left-0  hidden !text-white cursor-pointer z-10 bg-[#705E53] opacity-90   rounded-r-md'>
        {!props.isSchedule && <ChevronLeftTwoToneIcon />}
      </div>
      <SportsBookSlider>
        {DEFAULTGAMES.map((game: any) => (
          <div
            onClick={() => {
              dispatch({
                type: SAVE_SEARCH_MARKETIDS,
                data: '',
              })

              dispatch({
                type: APP_GLOBAL_SEARCH_CONTENT,
                payload: [],
              })

              if (props.isSchedule && props.setId) {
                props.setId(game.id)
              } else {
                navigate(`${game.link}`)
              }
            }}
            key={game.id}
            className={`!text-[15px] font-medium ${game.name === 'In Play' ? 'inPlay-blinking-dot before:bg-header_gradient dark:before:bg-animated_dark_border !p-0' : ''} flex items-center text-base w-max gap-2  justify-center whitespace-nowrap flex-1  md:min-w-[130px] md:px-3 px-5 min-w-[85px] ${gameType == game.id ? 'bg-[var(--game-getStartedNow-firstColor)] text-black' : 'text-white bg-[var(--game-selected-background)]'}  cursor-pointer text-[13px] font-semibold leading-[17px] tracking-normal text-left h-[35px]`}
          >
            {game.name === 'In Play' ? (
              <div
                className={`mac-inplay-icon-wrapper
              
               flex items-center text-base  gap-2  justify-center whitespace-nowrap flex-1 w-full    cursor-pointer text-[13px] font-semibold leading-[17px] tracking-normal text-left 
              
              `}
              >
                {!isFuture9() && game.name === 'In Play' ? (
                  <>
                    <PlayArrow style={{ color: 'var(--paymentMethodColor)' }} />
                  </>
                ) : (
                  <>
                    <img
                      src={
                        game?.image === 'header/favourite.png' ||
                        game?.image === 'asset/inplay.png'
                          ? getAssetUrl(game?.image)
                          : getAssetUrl(
                              `sports/${(game?.image).replace(/ /g, '')}`,
                            )
                      }
                      alt='Inplay Image'
                      className={` ${game.name === 'Horse Racing' || game.name === 'Football' || game.name === 'GreyHound Racing' ? 'shrink-0 !w-[30px] !h-[30px]' : 'shrink-0 !w-[25px] !h-[25px]'}  `}
                    />
                  </>
                )}
                {game.name}
              </div>
            ) : (
              <>
                {!isFuture9() && game.name === 'In Play' ? (
                  <>
                    <PlayArrow style={{ color: 'var(--paymentMethodColor)' }} />
                  </>
                ) : (
                  <>
                    <img
                      src={
                        game?.image === 'header/favourite.png' ||
                        game?.image === 'asset/inplay.png'
                          ? getAssetUrl(game?.image)
                          : getAssetUrl(
                              `sports/${(game?.image).replace(/ /g, '')}`,
                            )
                      }
                      alt='Inplay Image'
                      className={` ${game.name === 'Horse Racing' || game.name === 'Football' || game.name === 'GreyHound Racing' ? 'shrink-0 !w-[30px] !h-[30px]' : 'shrink-0 !w-[25px] !h-[25px]'}  `}
                    />
                  </>
                )}
                {game.name}
              </>
            )}
          </div>
        ))}
      </SportsBookSlider>
      <div className='navigate_next_14 absolute md:block hidden !text-white cursor-pointer z-10 right-0 bg-[#705E53] opacity-90 rounded-l-md'>
        {!props.isSchedule && <ChevronRightTwoToneIcon />}
      </div>
    </div>
  )
}

interface IIsHighLight {
  isHighLight: boolean
  isDarkMode: boolean
  setIsHighLight: () => void
}

export const HighLightContent = (props: IIsHighLight) => {
  const navigate = useNavigate()

  const seeAllClick = () => {
    if (props.isHighLight) {
      return toast.info(_getLocalString('LOC_COMMING_SOON'))
    } else {
      navigate('/schedule')
    }
  }

  return (
    <>
      <div className='inline-flex w-full px-[15px] sm:px-0'>
        <div
          onClick={props.setIsHighLight}
          className={`${props.isHighLight ? 'bg-[var(--selected-box)]' : 'bg-[var(--added-box)]'} font-dm_sans font-[13px] md:font-[17px] cursor-pointer px-12 py-2 w-1/2 sm:w-[170px] items-center justify-center flex`}
        >
          {_getLocalString('LOC_HIGHLIGHTS')}
        </div>
        <div
          onClick={props.setIsHighLight}
          className={`${!props.isHighLight ? 'bg-[var(--selected-box)]' : 'bg-[var(--added-box)]'} font-dm_sans font-[13px] md:font-[17px] cursor-pointer px-12 py-2 w-1/2 sm:w-[170px] items-center justify-center flex`}
        >
          {_getLocalString('LOC_SCHEDULE')}
        </div>
        <div
          onClick={seeAllClick}
          className='dark:text-white text-black self-end ml-auto mr-4 xs:hidden underline sm:block  cursor-pointer font-dm_sans font-bold text-[13px] uppercase whitespace-nowrap'
        >
          {_getLocalString('LOC_SEE_ALL')}
        </div>
      </div>
    </>
  )
}

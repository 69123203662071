import { Switch, useMediaQuery } from '@mui/material'
import { IBrowserDetail, INewBetSlipProps, RenderCounter } from '../NewBetSlip'
import { useEffect, useState } from 'react'
import {
  DEFAULT_QUICKBET,
  DEFAULT_STAKE,
} from '../../../../redux/actions/userAction'
import { toast } from 'react-toastify'
import { QuickBetModal } from './quickBetModal'
import { EditQuickStakes, QuickStakes } from '../stakes/QuickStakes'
import { Bet } from '../Bet'
import { EditStakes, Stakes } from '../stakes/Stakes'
import { BetSlipOnComponent } from '../BetSlipOn'
import { IoIosCloseCircle } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { SET_USER_QUICK_BET, SET_USER_QUICK_BET_VALUE } from '../../../../redux'
import {
  BET_SLIP_STAKE,
  UPDATE_IS_BET_LOADING,
} from '../../../../redux/actions/userAction/betSlipAction'
import { BET_TYPE } from '../../../../types/enums/betType'
import { SiteLoader } from '../../../../Fallback'
import { IOddBet } from '../../../../types/interfaces/exchangeMarketStatus'
import { MARKET_TYPE } from '../../../../types/enums/gameType'
import { decrypt } from '../../../../api/authHelper'
import { useParams } from 'react-router-dom'
import { isFuture9 } from '../../../../App'
import {
  isMobileScreensix,
  isMobileScreensixXSmall,
} from '../../../../utils/internalUtils'
import { getCalculatedExposureOrProfit } from '../../../../utils/gameUtils/gameUtil'

export interface IOpenBet {
  odds: IOddBet
  userId: string
  userName: string // (New Field)
  nation: string // Region (New Field)
  market: any
  marketType: MARKET_TYPE
  eventId: any // New Filed
  stake: number // Amount
  updatedAt: Date
  createdAt: Date // Place Date
  profit: number
  liability: number // New Field
  matchDate: Date // Match Date(New Field);
  browserDetail: IBrowserDetail //(New Field)
  _id: string
}

export const BetSlipOnContent = (props: INewBetSlipProps) => {
  const getUserQuickBet = () => {
    if (!props.quickBet.data || !('data' in props.quickBet)) {
      return DEFAULT_QUICKBET.stake
    } else return props.quickBet.data.stake
  }

  const getUserStake = () => {
    if (!props.stakes.data || !('data' in props.stakes)) {
      return DEFAULT_STAKE.stake
    } else return props.stakes.data.stake
  }

  const dispatch = useDispatch()
  const { id } = useParams()

  const [openConfirmBets, setOpenConfirmBets] = useState(false)
  const [confirmBets, setConfirmBets] = useState(props.userData?.confirmBets)
  const [availableStakesAmounts, setAvailableStakeAmount] =
    useState<any>(getUserStake)
  const [quickBetModalState, setQuickBetModal] = useState<any>(false)
  const [stakeAmount, setStakeAmount] = useState(props.betSlip.betSlipStake)
  const [editQuickBetStack, setEditQuickBetStack] = useState<any>(false)
  const [quickBetAmounts, setQuickBetAmounts] = useState<any>(getUserQuickBet)
  const [editStakes, setEditStakes] = useState<any>(false)
  const [isShowBetForThisEvent, setIsShowBetForThisEvent] = useState(false)
  const [decryptId] = decrypt(id)

  const [betSlipOn, setBetSlipOn] = useState<any>(
    Object.keys(props.betSlip.currentBet).length,
  )

  useEffect(() => {
    if (props.quickBetOn && Object.keys(props.betSlip.currentBet).length) {
      placeMarketOrder()
    }
  }, [props.quickBetOn])

  const placeMarketOrder = () => {
    if (!props.isLogin) {
      toast.error(_getLocalString('LOC_LOGIN_TO_PLACE_ORDER'))
      return
    }

    if (props.betSlip.isBetSlipLoading) {
      return
    }

    let currentBet = props.betSlip.currentBet

    dispatch({
      type: UPDATE_IS_BET_LOADING,
      isBetSlipLoading: true,
      timer: currentBet.delaySecond || 0,
    })

    if (
      currentBet.min > props.quickBetValue ||
      currentBet.max < props.quickBetValue
    ) {
      dispatch({
        type: UPDATE_IS_BET_LOADING,
        isBetSlipLoading: false,
        timer: currentBet.delaySecond || 0,
      })
      return toast.error(_getLocalString('LOC_AMOUNT_SHOULD_BE_IN_RANGE'))
    }

    // setTimeout(() => {
    props.placeMarketOrder(
      {
        marketId: currentBet.marketId._id,
        oddType: currentBet.oddType || BET_TYPE.BACK,
        odd: currentBet.odd,
        run: currentBet.run,
        teamId: currentBet.teamId,
        eventId: currentBet.marketId.event.id,
        marketType: currentBet.marketType.toLocaleUpperCase(),
        nation: props.betSlip.currentBet.marketId.competition.region,
        matchDate: props.betSlip.currentBet.marketId.event.openDate,
        name: currentBet.marketId.name,
        stake: props.quickBetValue,
      },
      props.currentDomain,
    )
    // }, currentBet.delaySecond * 1000)
  }

  useEffect(() => {
    if (
      props.betSlip.betSlipStake &&
      props.betSlip.betSlipStake !== stakeAmount
    ) {
      dispatch({
        type: BET_SLIP_STAKE,
        betSlipStake: stakeAmount,
      })
    }
  }, [stakeAmount])

  const setQuickBetOn = (value: boolean) => {
    dispatch({
      type: SET_USER_QUICK_BET,
      payload: value,
    })
  }

  useEffect(() => {
    setAvailableStakeAmount(getUserStake)
    setQuickBetAmounts(getUserQuickBet)
  }, [props.stakes, props.quickBet])

  const isTabletScreen = useMediaQuery('(max-width:1200px)')

  useEffect(() => {
    if (!Object.keys(props.betSlip.currentBet).length) {
      setBetSlipOn(false)
      setEditStakes(false)
    }
  }, [props.betSlip.currentBet])

  const getFilteredOpenBet = (): IOpenBet[] => {
    let decyptIdLocal = decrypt(id)
    if (isShowBetForThisEvent) {
      let openBetTmp = props.openBet.filter(
        (item: any) => item.market.event.id === decyptIdLocal,
      )
      return openBetTmp
    } else {
      return props.openBet
    }
  }

  return (
    <div
      style={{ maxWidth: '100%', marginLeft: '0' }}
      className='max-h-auto bg-white dark:bg-[#4D091E] '
    >
      <div>
        {props.betSlip.currentBet.marketCurrentStatus &&
          props.betSlip.currentBet.marketCurrentStatus.length &&
          props.betSlip.currentBet.marketCurrentStatus.map((res: any) => {
            let exposureAmount = getCalculatedExposureOrProfit(
              res.teamId,
              res.exposureOrProfit,
              props.betSlip.currentBet,
              stakeAmount,
            )
            return (
              <div className='flex space-between text-black dark:text-white pl-2 pr-2 '>
                <div>{res.teamName}</div>
                <div
                  className='bold'
                  style={{
                    color: `${exposureAmount > 0 ? 'green' : 'red'}`,
                  }}
                >
                  {exposureAmount}
                </div>
              </div>
            )
          })}
      </div>

      <div
        style={{ width: isTabletScreen ? '100%' : '' }}
        className='sm:w-[500px] w-full text-white text-xl p-2 bold bg-[linear-gradient(94.28deg,#F08A2F_3.45%,#E23542_28.47%,#E53344_61.86%,#8A0537_98.65%)] flex  justify-between items-center'
      >
        {_getLocalString('LOC_BET_SLIP')}
        <IoIosCloseCircle
          className='block text-3xl sm:hidden '
          onClick={() => {
            props.setbetSlipId(null)
            props.setBetSlipPopup(false)
          }}
        />
      </div>
      <div
        style={{ width: isTabletScreen ? '100%' : '' }}
        className={`py-4 px-1 text-black sm:w-[500px] w-full overflow-hidden  max-h-auto bg-white ${isFuture9() ? 'dark:bg-[#4D091E] ' : 'dark:bg-[linear-gradient(91.09deg,var(--theme-table-themeDarkColor)_3.38%,var(--theme-table-themeLightColor)_109.87%)]'}`}
      >
        {!props.betSlip.isBetSlipLoading ? (
          <>
            <div className='flex   xl:flex-row sm:flex-col flex-row justify-between xl:items-center sm:items-start items-center content-center mb-2  !px-1  gap-0 '>
              <div className='font-primary text-black dark:text-white flex items-center text-[15px] font-normal leading-[30px] tracking-normal text-left h-[20px] whitespace-nowrap'>
                {_getLocalString('LOC_QUICK_BET')}
                <Switch
                  className='!text-[#2AB6AD] p-0'
                  classes={{
                    thumb: '!bg-[#2AB6AD]',
                    track:
                      'dark:!bg-[#fff] !bg-[#A5D9FE]  !opacity-100 dark:!opacity-100',
                  }}
                  checked={props.quickBetOn}
                  defaultChecked={props.quickBetOn}
                  value={props.quickBetOn}
                  onChange={() => {
                    if (!props.isLogin) {
                      toast.error(_getLocalString('LOC_FIRST_TO_CONTINUE'))
                      return
                    }
                    dispatch({
                      type: SET_USER_QUICK_BET,
                      payload: !props.quickBetOn,
                    })
                    dispatch({
                      type: SET_USER_QUICK_BET_VALUE,
                      payload: getUserQuickBet()[0].value,
                    })
                  }}
                />
              </div>
              {props.quickBetOn && (
                <QuickStakes
                  demoLogin={props.demoLogin}
                  betSlipStake={props.betSlip.betSlipStake}
                  stakeAmount={stakeAmount}
                  setStakeAmount={setStakeAmount}
                  editQuickBetStack={editQuickBetStack}
                  setEditQuickBetStack={setEditQuickBetStack}
                  setQuickBetAmounts={setQuickBetAmounts}
                  currentDomain={props.currentDomain}
                  quickBet={props.quickBet}
                  quickBetAmounts={quickBetAmounts}
                  setEditStakes={setEditStakes}
                  stakes={props.stakes}
                />
              )}
            </div>

            <Bet
              isLogin={props.isLogin}
              betSlipOn={betSlipOn}
              setBetSlipOn={setBetSlipOn}
              setEditQuickBetStack={setEditQuickBetStack}
              setEditStakes={setEditStakes}
            />

            {editStakes ? (
              <EditStakes
                demoLogin={props.demoLogin}
                isShowBetForThisEvent={props.isShowBetForThisEvent}
                setIsShowBetForThisEvent={props.setIsShowBetForThisEvent}
                openBet={props.openBet}
                editStakes={editStakes}
                availableStakesAmounts={availableStakesAmounts}
                setAvailableStakeAmount={setAvailableStakeAmount}
                currentDomain={props.currentDomain}
                quickBet={props.quickBet}
                quickBetAmounts={quickBetAmounts}
                setEditStakes={setEditStakes}
                stakes={props.stakes}
              />
            ) : editQuickBetStack ? (
              <EditQuickStakes
                demoLogin={props.demoLogin}
                betSlipStake={props.betSlip.betSlipStake}
                editQuickBetStack={editQuickBetStack}
                setEditQuickBetStack={setEditQuickBetStack}
                setQuickBetAmounts={setQuickBetAmounts}
                currentDomain={props.currentDomain}
                quickBet={props.quickBet}
                quickBetAmounts={quickBetAmounts}
                setEditStakes={setEditStakes}
                stakes={props.stakes}
              />
            ) : betSlipOn ? (
              <BetSlipOnComponent
                quickBetValue={props.quickBetValue}
                openConfirmBets={openConfirmBets}
                setOpenConfirmBets={setOpenConfirmBets}
                confirmBets={confirmBets}
                setConfirmBets={setConfirmBets}
                quickBetOn={props.quickBetOn}
                isLogin={props.isLogin}
                demoLogin={props.demoLogin}
                getMatchBet={props.getMatchBet}
                isBetSlipLoading={props.betSlip.isBetSlipLoading || false}
                placeMarketOrder={props.placeMarketOrder}
                currentDomain={props.currentDomain}
                userData={props.userData}
                stakeAmount={stakeAmount}
                setStakeAmount={setStakeAmount}
                setEditQuickBetStack={setEditQuickBetStack}
                setEditStakes={setEditStakes}
                currentBet={props.betSlip.currentBet}
                availableStakesAmounts={availableStakesAmounts}
                betSlip={props.betSlip}
              />
            ) : (
              <Stakes
                demoLogin={props.demoLogin}
                openBet={getFilteredOpenBet()}
                isShowBetForThisEvent={isShowBetForThisEvent}
                setIsShowBetForThisEvent={setIsShowBetForThisEvent}
                editStakes={editStakes}
                availableStakesAmounts={availableStakesAmounts}
                setAvailableStakeAmount={setAvailableStakeAmount}
                currentDomain={props.currentDomain}
                quickBet={props.quickBet}
                quickBetAmounts={quickBetAmounts}
                setEditStakes={setEditStakes}
                stakes={props.stakes}
              />
            )}
            <QuickBetModal
              quickBetModal={quickBetModalState}
              setQuickBetModal={setQuickBetModal}
              setQuickBetOn={setQuickBetOn}
            />
          </>
        ) : (
          <div className='flex flex-col justify-center items-center my-2 bg-transparent  h-[419px] rounded-[4px] '>
            <div className='flex justify-center items-center h-40% mt-10 ml-50'>
              <SiteLoader />
            </div>
            <div className='text-white text-[17px] text-center'>
              Betting in process
            </div>
            <RenderCounter delaySecond={props.betSlip.betSlipLoaderTimer} />
          </div>
        )}
      </div>
    </div>
  )
}

import { Dispatch } from '@reduxjs/toolkit'
import { APP_CONFIGURATION_DARK_MODE, GetReduxStateFn } from '../type'

export const THEME_TOGGLE = '@THEME/UPDATE_THEME'

export const updateThemeToggle = () => {
  return (dispatch: Dispatch, getState: GetReduxStateFn) => {
    localStorage.setItem(
      APP_CONFIGURATION_DARK_MODE,
      !getState().theme.isDarkMode ? 'dark' : 'light',
    )
    dispatch({
      type: THEME_TOGGLE,
      isDarkMode: !getState().theme.isDarkMode,
    })
  }
}

import {
  GET_HIGHTLIGHT_MATCH,
  IHightLightAction,
} from '../../actions/userAction/highlightsAction'

export function HighLightReducer(
  state = initialState,
  action: IHightLightAction,
) {
  switch (action.type) {
    case GET_HIGHTLIGHT_MATCH: {
      return {
        ...state,
        eventIds: action.eventIds,
      }
    }

    default:
      return state
  }
}

const initialState: IHightLightAction = {
  eventIds: [],
}

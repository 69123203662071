import { ChangeEventHandler, useRef } from 'react'
import useDisableNumberInputScroll from '../../utils/UseDisableNumberInputScroll'
import { FilledInput } from '@mui/material'

interface IInternalFilledInput {
  id?: string
  className?: string
  type: string
  value?: any
  onKeyDown?: any
  onChange?: ChangeEventHandler<any> | undefined
  placeholder?: string
  min?: number
  max?: number
  readOnly?: boolean
  name?: string
  inputProps?: any
  endAdornment?: any
  classes?: any
  disabled?: boolean
  disableUnderline?: any
  startAdornment?: any
}

const InternalFilledInput = (props: IInternalFilledInput) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useDisableNumberInputScroll(inputRef)

  return (
    <FilledInput
      ref={inputRef}
      disabled={props.disabled}
      disableUnderline={props.disableUnderline}
      classes={props.classes}
      startAdornment={props.startAdornment}
      endAdornment={props.endAdornment}
      inputProps={props.inputProps}
      onKeyDown={props.onKeyDown}
      id={props.id}
      className={props.className}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  )
}

export default InternalFilledInput

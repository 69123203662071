import { useState, useEffect } from 'react'

export const RenderReverseNumber = (props: { startWith: number }) => {
  const { startWith } = props
  const [count, setCount] = useState(startWith)

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1)
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [count])

  return <span className='pl-2'>{count}</span>
}

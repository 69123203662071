import { IOddBet } from '../../types/interfaces/exchangeMarketStatus'

export function replaceSessionLayAndBack(
  marketId: any,
  newLay: IOddBet,
  newBack: IOddBet,
  status: any,
  delaySecond: any,
  max: any,
  min: any,
  data: any,
) {
  let marketData = data.get(marketId)
  marketData.lay = newLay
  marketData.back = newBack
  marketData.status = status
  ;(marketData.max = max), (marketData.min = min)
  marketData.delaySecond = delaySecond || 0
  return marketData
}

export const getRemarks = (market: any, result: string) => {
  if (!market) {
    return ''
  }

  let marketLocal = market
  return `${marketLocal.eventType.name}/${marketLocal.competition.name}/${marketLocal.event.name}/${marketLocal.name}${result ? '/' + result : ''}`
}

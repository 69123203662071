import { useEffect } from 'react'
import { ICurrentDomain } from '../types/interfaces/currentDomain'
import { useDispatch } from 'react-redux'
import {
  GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS,
  GET_GAME_MANAGEMENT_COMMON_DATA,
  GET_GAME_MANAGEMENT_DATA_THIRD_PARTY,
  GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS,
  UPDATE_EVENTNAME_FOREVENTID,
  UPDATE_SELECTED_MARKET,
} from '../redux'
import { URLType, makeGetHttpCallAsync } from '../api'
import { extractIds, hierarchyChangeObject } from './internalUtils'

interface IPublicAPICall {
  currentDomain: ICurrentDomain
  isPublic: boolean
  getHomeData: any
  isLogin: boolean
}

export const PublicAPICall = (props: IPublicAPICall) => {
  const dispatch = useDispatch()

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    let counter = 0

    const fetchData = async () => {
      if (!props.isPublic || props.isLogin || !counter) {
        return
      }

      counter++

      let hierarchyChangeObjectLocal: any = {}
      // Common API CALL
      try {
        makeGetHttpCallAsync(
          dispatch,
          `api/common/game/market`,
          'active=true&marketClosed=false',
          URLType.master,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY,
              loading: false,
              data: response.docs,
              page: {
                limit: response.limit,
                nextPage: response.nextPage,
                nextPageNumber: response.nextPageNumber,
                previousPage: response.previousPage,
                previousPageNumber: response.previousPageNumber,
                showingFrom: response.showingFrom,
                showingTo: response.showingTo,
                totalDocs: response.totalDocs,
                totalPages: response.totalPages,
              },
            })

            const { eventIds, marketIds, generateMarket } = extractIds(
              response.docs,
            )

            dispatch({
              type: GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS,
              data: marketIds,
            })

            dispatch({
              type: GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS,
              data: eventIds,
            })

            hierarchyChangeObjectLocal = hierarchyChangeObject(
              response.docs,
              false,
            )

            props.getHomeData()

            dispatch({
              type: GET_GAME_MANAGEMENT_COMMON_DATA,
              loading: false,
              data: hierarchyChangeObjectLocal,
            })

            dispatch({
              type: UPDATE_SELECTED_MARKET,
              loading: false,
              data: generateMarket.selectedMarket,
            })

            dispatch({
              type: UPDATE_EVENTNAME_FOREVENTID,
              data: generateMarket.eventNames,
            })
          })
          .catch(async (error: any) => {
            dispatch({
              type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY,
              loading: false,
              data: [],
              page: { totalPage: 1 },
            })

            hierarchyChangeObjectLocal = hierarchyChangeObject(
              [],
              false,
            ).eventType

            dispatch({
              type: GET_GAME_MANAGEMENT_COMMON_DATA,
              loading: false,
              data: hierarchyChangeObjectLocal,
            })
          })
      } catch (error: any) {
        dispatch({
          type: GET_GAME_MANAGEMENT_DATA_THIRD_PARTY,
          page: { totalPage: 1 },
          loading: false,
        })
        dispatch({ type: GET_GAME_MANAGEMENT_COMMON_DATA, loading: false })
      }
    }
    const stopApiCalls = () => {
      clearInterval(intervalId)
    }

    if (props.isLogin) {
      stopApiCalls()
    } else {
      intervalId = setInterval(fetchData, 60000)
      fetchData()
    }

    return () => {
      stopApiCalls()
    }
  }, [props.isPublic, props.isLogin])

  return <></>
}

export default PublicAPICall

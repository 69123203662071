import { FC } from 'react'
import { ClubLoyaltyTile } from './ClubLoyaltyTile'
import { Container } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  IClubLoyalty,
  IEnable,
} from '../../../../types/interfaces/themeConfiguration'

interface IClubLoyaltyComponent {
  isDarkMode?: boolean
}

export const ClubLoyalty: FC<IClubLoyaltyComponent> = (
  props: IClubLoyaltyComponent,
) => {
  const clubLoyaltyData = useSelector(
    (state: any) => state.appThemeConfiguration.clubLoyalty,
  )
  const enableFeatures: IEnable = useSelector(
    (state: any) => state.appThemeConfiguration.enable,
  )

  return (
    <>
      {enableFeatures?.isClubLoyalty &&
        !!clubLoyaltyData &&
        !!clubLoyaltyData.length && (
          <Container maxWidth={'xl'} className='!px-0 m-0   ' fixed>
            <div className='mx-auto md:text-5xl text-[24px] font-bold tracking-wide text-center md:mb-14 mb-12  gradient_text max-w-max font-bebas'>
              {_getLocalString('LOC_CLUB_LOYALTY')}
            </div>
            <div className='grid grid-cols-2 justify-items-center gap-x-3 sm:gap-x-5 md:gap-y-0 gap-y-10 sm:grid-cols-3'>
              {clubLoyaltyData.map((tile: IClubLoyalty, index: number) =>
                index === 2 ? (
                  <div
                    key={index}
                    className='col-span-2  sm:col-span-1 sm:w-auto sm:h-auto w-[50%] h-[204px]'
                  >
                    <ClubLoyaltyTile
                      key={index}
                      item={tile}
                      isDarkMode={props.isDarkMode}
                    />
                  </div>
                ) : (
                  <ClubLoyaltyTile
                    isGolden={index === 1}
                    key={index}
                    item={tile}
                    isDarkMode={props.isDarkMode}
                  />
                ),
              )}
            </div>
          </Container>
        )}
    </>
  )
}

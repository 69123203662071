import { ProdComponent } from '../pages/Game/content/ProdComponent'
import { Casino } from '../pages/landing/component/casino/Casino'
import { createArray } from '../utils'
import CommonSwiper from './commonSwiper'

interface ICasinoAndPartnerContent {
  themeConfiguration: any
  getLiveCasinoData: any
  isDarkMode: boolean
  swiperModules: any
  getVirtualCasinoData: any
  SwipperPagination: any
  Autoplay: any
  appThemeConfiguration: any
  swiperOptions: any
}

export const CasinoAndPartnerContent = (props: ICasinoAndPartnerContent) => {
  return (
    <>
      <Casino
        themeConfiguration={props.themeConfiguration}
        isCasino={true}
        isDarkMode={props.isDarkMode}
        title={_getLocalString('LOC_OUR_LIVE_CASINO')}
        casinoData={props.getLiveCasinoData(_getLocalString)}
        sliderNo={1}
      />

      <div className='flex items-center justify-between '>
        <div className='xs:w-full sm:w-full'>
          <div
            style={{ lineHeight: 'normal' }}
            className='sm:mb-2 mt-10 mb-0 gradient_text pt-1 lg:text-[52px] md:text-4xl sm:text-3xl  text-[24px] font-bebas uppercase  font-extrabold  tracking-normal text-center md:text-left'
          >
            {_getLocalString('LOC_OUR_PARTNERS')}
          </div>
          <div
            style={{ lineHeight: 'normal' }}
            className='mb-5 text-black dark:text-white lg:text-[25px]'
          >
            {props.appThemeConfiguration?.logoText}{' '}
            {_getLocalString('LOC_OUR_PARTNERS_CONTENT')}
          </div>
          <div>
            {[1, 2, 3].map((item, key: number) => (
              <div key={key} className='mb-5'>
                <CommonSwiper
                  id={`our-partners-${key}`}
                  loop={true}
                  slidePreview={2}
                  spaceBetween={30}
                  speed={6000}
                  centered={true}
                  parallax={true}
                  freeMode={true}
                  centerInsufficientSlides={true}
                  clickable={true}
                  effect='slide'
                  autoPlay={{
                    delay: 0,
                    reverseDirection: !!(item % 2),
                    disableOnInteraction: false,
                  }}
                  modules={[
                    ...props.swiperModules,
                    props.SwipperPagination,
                    props.Autoplay,
                  ]}
                  className='mySwiper swiper'
                  style={{ zIndex: 0 }}
                  swiperOptions={props.swiperOptions}
                  arrow={false}
                  classNameSlider=' !w-[160px]  sm:!w-60 swiper-slide'
                  classNameSliderWrapper='swiper-wrapper'
                >
                  {!!createArray(item) &&
                    createArray(item).map((child, index) => (
                      <ProdComponent key={index + key} item={child} />
                    ))}
                </CommonSwiper>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Casino
        themeConfiguration={props.themeConfiguration}
        isDarkMode={props.isDarkMode}
        title={_getLocalString('LOC_OUT_VIRTUAL_CASINO')}
        casinoData={props.getVirtualCasinoData(_getLocalString)}
        sliderNo={2}
      />
    </>
  )
}

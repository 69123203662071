import { useCallback, useEffect, useState } from 'react'
import { getAssetJsonUrl, getAssetUrl } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { DropDownGlobal } from '../../pages/landing/component/dropdown/DropDown'
import { CloseSharp } from '@mui/icons-material'
import { toast } from 'react-toastify'
import {
  DEFAULT_WEBSITE_NAME,
  makePatchHttpCallAsync,
  makePostHttpCallAsync,
} from '../../api'

export const ForgetPassword = (props: any) => {
  useEffect(() => {
    const currentDomain = window.location.hostname
    let domainName = currentDomain.split('.')[0]

    if (domainName === 'www' || domainName === 'test' || domainName === 'pp') {
      domainName = currentDomain.split('.')[1]
    }
    fetch(getAssetJsonUrl(`country-list/phone-codes.json`))
      .then((response) => response.json())
      .then((data: any) => {
        if (domainName === '10exchange') {
          setMobileList(data)
          setCountry(data[1].key)
        } else {
          setMobileList(data)
          setCountry(data[0].key)
        }
      })
  }, [])

  const [mobileList, setMobileList] = useState([])
  const [otp, setOtp] = useState('')
  const [password, setPassword] = useState('')

  const [forgetPasswordState, setForgetPasswordState] = useState(false)
  const [resendPasswordState, setResentPasswordState] = useState(false)
  const [mobile, setMobile] = useState<any>()
  const [country, setCountry] = useState('')

  const setIsForgetPassword = () => {
    props.setIsForgetPassword(false)
  }
  const dispatch = useDispatch()

  const setIsRegister = useCallback(() => {
    props.setIsRegister(true)
    props.setIsLoginScren(false)
    props.setIsForgetPassword(false)
  }, [props])

  const enabledFeatures = useSelector(
    (state: any) => state.appThemeConfiguration.enable,
  )

  const savePassword = () => {
    const formData = new URLSearchParams()
    formData.append('phone', country + mobile)
    formData.append('otp', otp)
    formData.append('password', password)

    makePatchHttpCallAsync(
      dispatch,
      'api/common/forgetPassword',
      formData,
      '',
      props.currentDomain.type,
      '',
      false,
      'application/x-www-form-urlencoded',
      true,
      'PATCH',
      'Password Updated Successfull',
    )
      .then((res: any) => {
        if (res.message === 'error') {
          return setResentPasswordState(true)
        }
        setForgetPasswordState(false)
        setResentPasswordState(false)
        setIsForgetPassword()
      })
      .catch(() => {
        setResentPasswordState(true)
      })
  }

  const updateCountry = (value: string) => {
    setCountry(value)
  }

  const handleForgotPassword = () => {
    if (!mobile) {
      return toast.error(_getLocalString('LOC_MOBILE_NUMBER_IS_REQUIRED'))
    }

    if (mobile.toString().length < 10 || mobile.toString().length > 16) {
      return toast.error(_getLocalString('LOC_MOBILE_NUMBER_IS_NOT_VALID'))
    }

    const formData = new URLSearchParams()
    formData.append('phone', country + mobile)
    formData.append('type', 'sms')
    formData.append('operator', DEFAULT_WEBSITE_NAME)
    makePostHttpCallAsync(
      dispatch,
      'api/common/forgetPassword/otp',
      props.currentDomain.type,
      formData,
      '',
      false,
      'application/x-www-form-urlencoded',
      true,
      'OTP Send Successfully',
    ).then((res) => {
      setForgetPasswordState(true)
    })
  }

  return (
    <div
      id='normalFont'
      className='relative w-[90%] md:w-[70%] h-[600px]  p-[1.5px] sm:justify-center lg:w-[60%] xl:w-[50%] rounded-[10px]  bg-[linear-gradient(180deg,var(--popup-box-1),var(--paymentMethodColor)),linear-gradient(104.51deg,var(--popup-box-3),var(--popup-box-4))]'
    >
      <div
        className='w-full h-full bg-no-repeat bg-cover  rounded-[10px]'
        style={{
          backgroundImage: `url(${getAssetUrl('assets/stadium-lights-flashes-3d.webp')})`,
        }}
      >
        <div className='flex bg-no-repeat bg-cover bg-left rounded-[10px] h-full'>
          <div className='relative w-1/2 bg-cover bg-center  rounded-l-[10px]  hidden md:flex'>
            <div
              className='w-full h-full absolute bg-cover rounded-l-[10px] bg-no-repeat mix-blend-darken '
              style={{
                backgroundImage: `url(${getAssetUrl('asset/light.webp')})`,
              }}
            ></div>
            <div
              className='w-full h-full absolute bg-bottom bg-cover bg-no-repeat top-[-50px] mix-blend-color-dodge'
              style={{
                backgroundImage: `url(${getAssetUrl('asset/girl-bg.webp')})`,
                transform: 'rotate(17deg)',
                borderRadius: 10,
              }}
            ></div>
            <div
              className='w-full h-full absolute  bg-[size:135%] bg-[position:-105px_-22px] bg-no-repeat'
              style={{
                backgroundImage: `url(${getAssetUrl('asset/girl.webp')})`,
              }}
            ></div>
          </div>
          <div className=' rounded-r-lg bg-center bg-gradient-to-br pb-4 to-[var(--popup-box-right-1)] to-[60%]  from-[var(--popup-box-right-2)] bg-no-repeat  bg-cover w-full sm:w-[70%] justify-center md:w-1/2'>
            <div className='absolute z-[999] top-7 right-7 cursor-pointer'>
              <div
                className='w-5 h-5 rounded-[3px] flex justify-center items-center bg-white'
                onClick={setIsForgetPassword}
              >
                <CloseSharp className='!text-black !text-base' />
              </div>
            </div>

            <div className='flex flex-col   mx-[30px] h-full justify-center items-center'>
              <div
                className='font-bebas font-bold   text-[40px] leading-30 text-center '
                style={{ lineHeight: '3rem' }}
              >
                {_getLocalString('LOC_FORGOT_PASSWORD_UPPER_CASE')}
              </div>
              <div className='text-center text-lg font-bold my-6 text-col text-[#FFB12E]'>
                {_getLocalString('LOC_ENTER_USER_ID_TO_RESET')}
              </div>
              <div className='flex justify-center items-center relative w-full mt-2 gap-1'>
                <div className='absolute left-4 self-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 13 12'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_304_9765)'>
                      <path
                        d='M6.37406 5.665C8.13395 5.665 9.56063 4.39685 9.56063 2.8325C9.56063 1.26815 8.13395 0 6.37406 0C4.61417 0 3.1875 1.26815 3.1875 2.8325C3.1875 4.39685 4.61417 5.665 6.37406 5.665Z'
                        fill='white'
                      />
                      <path
                        d='M6.37359 6.60938C3.73497 6.61199 1.59669 8.51268 1.59375 10.8581C1.59375 11.1188 1.83152 11.3302 2.12484 11.3302H10.6223C10.9156 11.3302 11.1534 11.1188 11.1534 10.8581C11.1505 8.51268 9.01222 6.61196 6.37359 6.60938Z'
                        fill='white'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_304_9765'>
                        <rect width='12.7463' height='11.33' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <input
                  id='userNameTextBox__id'
                  className='w-full rounded-[5px] font-light text-lg tracking-[0.5px] ml-[0] p-3 leading-[19px] bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12'
                  type='number'
                  value={mobile}
                  onChange={(event) =>
                    setMobile(parseFloat(event.target.value))
                  }
                  placeholder={_getLocalString('LOC_ENTER_MOBILE_NUMBER')}
                />
                <DropDownGlobal
                  className=' bg-white/[.15] !font-light text-lg h-[100%] ml-1 !tracking-[0.5px]'
                  textClass='!text-lg'
                  backgroundColor='!bg-[rgba(196,39,29,1)]'
                  item={mobileList}
                  selectedValue={country}
                  update={updateCountry}
                />
              </div>

              {!forgetPasswordState && (
                <div
                  onClick={handleForgotPassword}
                  className='mt-6 bg-[linear-gradient(43.38deg,var(--button-popup-1)_-13.67%,var(--button-popup-2)_84.99%)] border border-solid border-[var(--button-popup-border)]/[.19] shadow-[0_4px_4px_var(--theme-color)] rounded-md w-full text-white text-lg font-bold py-3 leading-[19px] text-center cursor-pointer'
                >
                  {_getLocalString('LOC_SEND_OTP_SMALLCASE')}
                </div>
              )}

              {forgetPasswordState && (
                <div className='flex mt-5'>
                  <input
                    id='userNameTextBox__id'
                    className='mr-5 w-full rounded-[5px] font-light text-lg tracking-[0.5px] ml-[0] p-3 leading-[19px] bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12'
                    type='text'
                    value={otp}
                    onChange={(event) => setOtp(event.target.value)}
                    placeholder={_getLocalString('LOC_ENTER_OTP')}
                  />
                  <input
                    id='userNameTextBox__id'
                    className='w-full rounded-[5px] font-light text-lg tracking-[0.5px] ml-[0] p-3 leading-[19px] bg-white/[.15] border border-solid border-white/[.21] focus:outline-none'
                    type='text'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder={_getLocalString('LOC_ENTER_PASSWORD')}
                  />
                </div>
              )}

              {forgetPasswordState && (
                <>
                  <div
                    onClick={savePassword}
                    className='mt-6 bg-[linear-gradient(43.38deg,var(--button-popup-1),var(--button-popup-2))] border border-solid border-[var(--button-popup-border)]/[.19] shadow-[0_4px_4px_var(--theme-color)] rounded-md w-full text-white text-lg font-bold py-3 leading-[19px] text-center cursor-pointer'
                  >
                    {_getLocalString('LOC_VERIFY_AND_CONTINUE')}
                  </div>

                  <div
                    onClick={handleForgotPassword}
                    className='mt-6 bg-[linear-gradient(43.38deg,var(--button-popup-1),var(--button-popup-2))] border border-solid border-[var(--button-popup-border)]/[.19] shadow-[0_4px_4px_var(--theme-color)] rounded-md w-full text-white text-lg font-bold py-3 leading-[19px] text-center cursor-pointer'
                  >
                    {_getLocalString('LOC_RESEND_OTP')}
                  </div>
                </>
              )}

              {enabledFeatures?.isRegister && (
                <div className='text-center tracking-[0.7px] font-light mt-3'>
                  {_getLocalString('LOC_NOT_MEMBER')}
                  <span
                    onClick={setIsRegister}
                    className='font-bold cursor-pointer pl-1 underline-offset-4 underline'
                  >
                    {_getLocalString('LOC_REGISTER')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Collapse, Tooltip, useMediaQuery } from '@mui/material'
import { getAssetUrl } from '../../../../utils'
import { useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { OPEN_LOGIN_SCREEN } from '../../../../redux/actions/userAction/betSlipAction'
import {
  getTranslatedSportsName,
  IsMarketInPlay,
} from '../../../../utils/internalUtils'
import { encrypt } from '../../../../api/authHelper'

interface INavBarTileSports {
  sports: any
  isPadding: boolean
  isDarkMode: boolean
  height?: number
  showSports?: boolean
  showSportsMobile?: boolean
  navigate: any
  openNav: string
  index: number
  onClickHandlerClose?: () => void
  setOpenNav: (openNav: string) => void
}

export const NavBarTileSports = (props: INavBarTileSports) => {
  const isSmallScreen = useMediaQuery('(max-width:1200px)')
  const isDarkMode = useSelector((state: any) => state.theme.isDarkMode)
  const toggleModal = () => {
    if (props.openNav === props.sports.name) {
      return props.setOpenNav('')
    }
    props.setOpenNav(props.sports.name)
  }

  return (
    <div
      className={` ${
        props.showSports && !props.showSportsMobile
          ? ''
          : props.showSportsMobile
            ? 'overflow-hidden dark:shadow-none shadow-[none] cursor-pointer '
            : 'xs:border-transparent lg:border-[var(--sideNavBar-border-secondColor)] lg:border-solid xl:border-solid dark:xl:border-[var(--sideNavBar-border-secondColor)] dark:border overflow-hidden first-of-type:!rounded-t-2xl last-of-type:!rounded-b-2xl  cursor-pointer '
      }  
      ${
        props.sports?.count && !props.showSports && !props.showSportsMobile
          ? ` cursor-pointer 
          `
          : props.showSports
            ? 'cursor-pointer '
            : props.showSportsMobile
              ? 'cursor-pointer'
              : ''
      } `}
      key={props.index}
    >
      <div
        onClick={toggleModal}
        style={{
          borderImage: isDarkMode
            ? 'linear-gradient(var(--sideNavBar-border-firstColor), var(--sideNavBar-border-secondColor)) 30'
            : '',
        }}
        className={`${
          props.openNav === props.sports.name
            ? 'lg:!bg-gradient-to-r xl:!bg-gradient-to-r from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)]'
            : ''
        } lg:border-[0.5px] xl:border-[0.5px] grid grid-flow-col xs:grid-cols-[33px_1fr_25px] lg:grid-cols-[33px_1fr_25px] xl:grid-cols-[38px_1fr_28px] items-center px-3 lg:dark:bg-[linear-gradient(173.49deg,var(--sideNavbar-darkGradient),rgba(77,9,30,0)_94.88%)] text-white text-xl h-[${!!props.height ? props.height : 50}px]`}
      >
        <div
          className={`${props.showSportsMobile ? 'h-[18px] w-[14px]' : 'max-w-max xs:w-[24px] xs:h-[24px] sm:w-[24px] sm:h-[24px]  md:w-[20px] md:h-[20px]  xl:w-[24px] xl:h-[24px] lg:h-[20px] lg:w-[20px]'} `}
        >
          <img
            src={getAssetUrl(
              `sports/png/${
                props.sports.name &&
                props.sports.name.replace(/\s/g, '').toLowerCase()
              }.webp`,
            )}
            alt='Sport Image'
          />
        </div>
        <Tooltip
          title={getTranslatedSportsName(props.sports.name, _getLocalString)}
          arrow
          placement='top'
          className='xl:!text-lg lg:!text-base'
          componentsProps={{
            tooltip: {
              className:
                'bg-black dark:bg-white !mb-0 dark:text-black text-white !text-sm',
            },
          }}
        >
          <div
            className={`${!isSmallScreen ? 'dark:text-white text-black ' : 'text-white'} font-primary font-normal whitespace-nowrap !xl:text-[20px] lg:text-[16px]!md:text-[16px] w-full text-ellipsis overflow-hidden leading-[31px] tracking-normal text-left`}
          >
            {getTranslatedSportsName(props.sports.name, _getLocalString)}
          </div>
        </Tooltip>

        {!!props.sports?.totalEventCount && (
          <div
            className={`${
              !props.setOpenNav === props.sports.name
                ? 'dark:text-[#DADADA] text-[#000000] '
                : isSmallScreen
                  ? 'text-white'
                  : 'xs:dark:text-white xs:text-black  lg:dark:text-[#705E53] lg:text-[#705E53]'
            } font-primary font-normal whitespace-nowrap xl:text-[20px] lg:text-[16px] md:text-[16px]  w-full text-ellipsis overflow-hidden leading-[31px] tracking-normal text-left  max-w-max justify-self-end xl:text-lg text-base    !font-bold `}
          >
            {props.sports?.totalEventCount}
          </div>
        )}

        {props.openNav === props.sports.name ? (
          <ExpandLess
            fontSize='large'
            className={`${isSmallScreen ? 'text-white' : 'dark:text-[white] text-[black]'}`}
          />
        ) : (
          <ExpandMore
            className={`${isSmallScreen ? 'text-white' : 'dark:text-[white] text-[black]'}`}
            fontSize='large'
          />
        )}
      </div>

      <Collapse
        in={props?.openNav === props.sports.name}
        timeout='auto'
        unmountOnExit
      >
        <NavBarCompetition
          icon={getAssetUrl(
            `sports/png/${
              props.sports.name &&
              props.sports.name.replace(/\s/g, '').toLowerCase()
            }.webp`,
          )}
          navigate={props.navigate}
          isSelected={props.openNav === props.sports.name}
          isDarkMode={props.isDarkMode}
          competitions={props.sports.competitions}
          onClickHandlerClose={props.onClickHandlerClose}
        />
      </Collapse>
    </div>
  )
}

interface INavBarCompetitionProps {
  competitions: any[]
  navigate: any
  icon: any
  isDarkMode: boolean
  isSelected: boolean
  onClickHandlerClose?: () => void
}

const NavBarCompetition = (props: INavBarCompetitionProps) => {
  const [openCompetition, toggleCompetition] = useState('')
  return (
    <>
      {!!props.competitions &&
        props.competitions.map((competition: any, index: number) => {
          return (
            <div key={index}>
              <NavBarCompetitionContent
                icon={props.icon}
                navigate={props.navigate}
                toggleCompetition={toggleCompetition}
                openCompetition={openCompetition}
                isSelected={props.isSelected}
                isDarkMode={props.isDarkMode}
                competition={competition}
                onClickHandlerClose={props.onClickHandlerClose}
              />
            </div>
          )
        })}
    </>
  )
}

// ------------------------
interface INavBarCompetitionContentProps {
  competition: any
  icon: string
  navigate: any
  isDarkMode: boolean
  isSelected: boolean
  openCompetition: string
  toggleCompetition: (openCompetition: string) => void
  onClickHandlerClose?: () => void
}

const NavBarCompetitionContent = (props: INavBarCompetitionContentProps) => {
  const isSmallScreen = useMediaQuery('(max-width:1200px)')
  const toggleCompetition = (event: any) => {
    event?.stopPropagation()
    const newOpenCompetition =
      props.openCompetition === props.competition.name
        ? ''
        : props.competition.name
    props.toggleCompetition(newOpenCompetition)
  }
  const isDarkMode = useSelector((state: any) => state.theme.isDarkMode)

  return (
    <>
      <div
        onClick={toggleCompetition}
        style={{
          backgroundImage: isDarkMode
            ? 'linear-gradient(var(--sideNavBar-border-firstColor), var(--sideNavBar-border-secondColor)) 30'
            : '',
        }}
        className={`lg:!border-[var(--sideNavBar-border-secondColor)] lg:!border-solid xl:!border-solid dark:xl:border-[var(--sideNavBar-border-secondColor)] dark:border border-[0.5px] dark:border-l-[0.5px] dark:border-r-[0.5px] dark:md:border-l-0 dark:md:border-r-0 overflow-hidden   cursor-pointer 
         mt-2 grid grid-flow-col lg:grid-cols-[28px_1fr_10px] xl:grid-cols-[38px_1fr_10px] items-center px-2  dark:text-white text-black text-xl h-fit min-h-[${50}px]`}
      >
        <div className='xs:w-[20px] xs:h-[20px] sm:w-[20px] sm:h-[20px]  md:h-[20px] md:w-[20px]   xl:w-[24px] xl:h-[24px] lg:h-[20px] lg:w-[20px]'>
          <img src={props.icon} alt='Dot Icon' />
        </div>
        <Tooltip
          title={props.competition.name}
          arrow
          placement='top'
          className='xl:!text-base !text-sm'
          componentsProps={{
            tooltip: {
              className:
                'bg-black dark:bg-white !mb-0 dark:text-black text-white xl:!text-base !text-sm',
            },
          }}
        >
          <div
            className={`${!isSmallScreen ? 'dark:text-white text-black' : 'text-white'} text-pretty font-primary lg:text-[14px] xl:text-[17px] font-normal  tracking-normal text-left whitespace-nowrap lg:text-lg w-full text-ellipsis overflow-hidden`}
          >
            {props.competition.name}
          </div>
        </Tooltip>

        {!!props.competition.eventCount && (
          <div
            className={`${
              !props.isSelected
                ? 'dark:text-[#DADADA] text-[#000000] '
                : isSmallScreen
                  ? 'text-white'
                  : 'xs:dark:text-white xs:text-black  lg:dark:text-[#705E53] lg:text-[#705E53]'
            } font-primary font-normal whitespace-nowrap xl:text-[20px] lg:text-[16px] md:text-[16px]  w-full text-ellipsis overflow-hidden leading-[31px] tracking-normal text-left  max-w-max justify-self-end xl:text-lg text-base    !font-bold `}
          >
            {props.competition.eventCount}
          </div>
        )}

        {props.openCompetition === props.competition.name ? (
          <ExpandLess
            className={`${isSmallScreen ? 'text-white' : 'dark:text-[white] text-[black]'}`}
            fontSize='large'
          />
        ) : (
          <ExpandMore
            className={`${isSmallScreen ? 'text-white' : 'dark:text-[white] text-[black]'}`}
            fontSize='large'
          />
        )}
      </div>
      <Collapse
        in={props.openCompetition === props.competition.name}
        timeout='auto'
        unmountOnExit
      >
        <NavBarEvent
          icon={props.icon}
          navigate={props.navigate}
          events={props.competition.events}
          isDarkMode={props.isDarkMode}
          isSelected={props.isSelected}
          onClickHandlerClose={props.onClickHandlerClose}
        />
      </Collapse>
    </>
  )
}

interface INavBarEvent {
  events: any
  navigate: any
  icon: string
  isDarkMode: boolean
  isSelected: boolean
  onClickHandlerClose?: () => void
}

const NavBarEvent = (props: INavBarEvent) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)

  const handleItemClick = (eventId: string) => {
    setSelectedItemId(eventId)
  }
  return (
    <>
      {props.events.map((event: any) => {
        return (
          <div key={event.id}>
            <NavBarEventContent
              icon={props.icon}
              navigate={props.navigate}
              event={event}
              isDarkMode={props.isDarkMode}
              isSelected={selectedItemId === event.id}
              onClickHandlerClose={props.onClickHandlerClose}
              onSelect={() => handleItemClick(event.id)}
            />
          </div>
        )
      })}
    </>
  )
}

interface INavBarEventContent {
  event: any
  navigate: any
  isDarkMode: boolean
  icon: string
  isSelected: boolean
  onClickHandlerClose?: () => void
  onSelect: () => void
}

export const NavBarEventContent = (props: INavBarEventContent) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const isLogin = useSelector((state: any) => state.login.isLogin)
  const dispatch = useDispatch()

  const navigate = () => {
    isLogin
      ? props.navigate(`/events/${encrypt(props.event.id)}`)
      : dispatch({
          type: OPEN_LOGIN_SCREEN,
          openLoginScreen: true,
        })
  }

  return (
    <>
      <div
        onClick={() => {
          props.onSelect()
          navigate()
          props?.onClickHandlerClose && props?.onClickHandlerClose()
        }}
        style={{
          padding: '0.5rem',
          background: props.isSelected
            ? 'linear-gradient(to right, var(--selected-theme-color2), var(--selected-theme-color))'
            : 'none',
        }}
        className={`${!props.isSelected ? 'flex items-center h-fit min-h-[50px] mt-2 justify-between  ' : 'flex min-h-[50px] items-center h-fit mt-2 justify-between'} 
            lg:!border-[var(--sideNavBar-border-secondColor)] lg:!border-solid xl:!border-solid dark:xl:border-[var(--sideNavBar-border-secondColor)] dark:border border-[0.5px] dark:border-l-[0.5px] dark:border-r-[0.5px] dark:md:border-l-0 dark:md:border-r-0 overflow-hidden   cursor-pointer 
          `}
      >
        <div style={{ width: '70%' }} className='flex items-center'>
          <div
            className='w-[20px] h-[20px] shrink-0'
            style={{ alignSelf: 'center', marginRight: '5px' }}
          >
            <img className='w-full h-full' src={props.icon} alt='Dot Icon' />
          </div>

          <Tooltip
            title={props.event.name}
            arrow
            placement='top'
            className='!text-l text-start'
            componentsProps={{
              tooltip: {
                className:
                  'bg-black dark:bg-white !mb-0 dark:text-black text-white !text-sm',
              },
            }}
          >
            <div className='flex items-center lg:ml-5'>
              <div
                className={`${!isSmallScreen ? 'dark:text-white text-black' : 'text-white'} !leading-5 text-pretty font-primary font-normal whitespace-nowrap xl:!text-[16px] lg:text-[14px] sm:!text-[11px] md:!text-[14px] w-full   tracking-normal text-left`}
              >
                {props.event.name}
              </div>
            </div>
          </Tooltip>
        </div>

        {IsMarketInPlay(props.event.openDate) && (
          <button className='relative flex items-center justify-center h-[15px] px-1 bg-red-500 text-white rounded-sm'>
            <span className=' pl-[10px] text-xs font-bold'>
              {_getLocalString('LOC_LIVE')}
            </span>
            <span className='absolute inset-y-0 left-1 flex items-center'>
              <span
                className='h-1.5 w-1.5 bg-green-500 rounded-full'
                style={{ animation: 'blink 1s infinite' }}
              ></span>
            </span>
          </button>
        )}

        <style>{`
              @keyframes blink {
                0% { opacity: 1; }
                50% { opacity: 0; }
                100% { opacity: 1; }
              }
        `}</style>
      </div>
    </>
  )
}

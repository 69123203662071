import {
  DEFAULT_LAZER7_WEBSITE_NAME,
  DEFAULT_WEBSITE_NAME,
  IS_LOCAL_FUTURE9,
} from '../api'
import { IThemeConfiguration } from '../types/interfaces/themeConfiguration'
import { getAssetUrl } from './helper'
import { addAlphaToColor, darkenColor } from './internalUtils'

export const setTheme = (
  theme: IThemeConfiguration,
  isThemeFuture9: boolean,
) => {
  document.documentElement.style.setProperty(
    '--fastest-odd-fastest-odd-1',
    isThemeFuture9 ? '#360812' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--fastest-odd-fastest-odd-2',
    isThemeFuture9 ? 'rgba(54,8,18,0.22)' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--fastest-odd-fastest-odd-3',
    isThemeFuture9 ? 'rgba(179,155,156,0.24)' : theme?.themeColor || '',
  )

  document.documentElement.style.setProperty(
    '--button-popup-1',
    isThemeFuture9
      ? '#f99c3e'
      : darkenColor(theme?.theme?.selectedThemeColor || '', 30),
  )
  document.documentElement.style.setProperty(
    '--button-popup-2',
    isThemeFuture9
      ? '#ff5d20'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 60),
  )
  document.documentElement.style.setProperty(
    '--button-popup-border',
    isThemeFuture9 ? '#f06842%' : theme?.themeColor || '',
  )

  document.documentElement.style.setProperty(
    '--popup-box-1',
    isThemeFuture9 ? '#8B2006' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--popup-box-2',
    isThemeFuture9 ? '#FD4F0F' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--popup-box-3',
    isThemeFuture9 ? '#AF0010' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--popup-box-4',
    isThemeFuture9 ? '#501E00' : theme?.themeColor || '',
  )

  document.documentElement.style.setProperty(
    '--popup-box-right-1',
    isThemeFuture9 ? '#f42020c9' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--popup-box-right-2',
    isThemeFuture9 ? '#ff973680' : theme?.themeColor || '',
  )

  document.documentElement.style.setProperty(
    '--register-box-right-1',
    isThemeFuture9
      ? '#f42020c9'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 80),
  )
  document.documentElement.style.setProperty(
    '--register-box-right-2',
    isThemeFuture9
      ? '#FD4F0F'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 80),
  )

  document.documentElement.style.setProperty(
    '--highLight-color',
    isThemeFuture9
      ? '#620707C9'
      : darkenColor(theme?.theme?.selectedThemeColor || '', 30),
  )

  document.documentElement.style.setProperty(
    '--nav-bar-popup-1',
    isThemeFuture9
      ? '#F08A2F'
      : darkenColor(theme?.theme?.selectedThemeColor || '', 40),
  )
  document.documentElement.style.setProperty(
    '--nav-bar-popup-2',
    isThemeFuture9
      ? '#E23542'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 30),
  )
  document.documentElement.style.setProperty(
    '--nav-bar-popup-3',
    isThemeFuture9 ? '#8A0537' : theme?.themeColor || '',
  )

  document.documentElement.style.setProperty(
    '--nav-bar-icon-1',
    isThemeFuture9
      ? '#F08A2F'
      : darkenColor(theme?.theme?.selectedThemeColor || '', 40),
  )
  document.documentElement.style.setProperty(
    '--nav-bar-icon-2',
    isThemeFuture9
      ? '#E23542'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 30),
  )
  document.documentElement.style.setProperty(
    '--nav-bar-icon-3',
    isThemeFuture9
      ? '#E53344'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 80),
  )
  document.documentElement.style.setProperty(
    '--nav-bar-icon-4',
    isThemeFuture9 ? '#8A0537' : theme?.themeColor || '',
  )

  document.documentElement.style.setProperty(
    '--button-color-1',
    isThemeFuture9
      ? '#FF2E15'
      : darkenColor(theme?.theme?.selectedThemeColor || '', 30),
  )
  document.documentElement.style.setProperty(
    '--button-color-2',
    isThemeFuture9
      ? '#FF8A00'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 60),
  )

  document.documentElement.style.setProperty(
    '--highlight-schedule',
    isThemeFuture9
      ? 'rgba(98,7,7,0.79)'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 60),
  )

  document.documentElement.style.setProperty(
    '--selected-box',
    isThemeFuture9 ? '#943e2c' : theme?.theme?.selectedThemeColor || '',
  )
  document.documentElement.style.setProperty(
    '--added-box',
    isThemeFuture9
      ? '#4D091E'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 80),
  )

  document.documentElement.style.setProperty(
    '--form-sidebar-1',
    isThemeFuture9
      ? '#4D091E7A'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 80),
  )

  document.documentElement.style.setProperty(
    '--dropdown-first-color',
    isThemeFuture9
      ? 'rgba(77,9,30,0.48)'
      : theme?.theme?.selectedThemeColor || '',
  )

  document.documentElement.style.setProperty(
    '--dropdown-second-color',
    isThemeFuture9
      ? 'rgba(77,9,30,0)'
      : theme?.theme?.selectedThemeColor2 || '',
  )

  document.documentElement.style.setProperty(
    '--dropdown-first-color-light',
    isThemeFuture9
      ? 'rgba(77,9,30,0.48)'
      : addAlphaToColor(theme?.theme?.selectedThemeColor || '', 0.1),
  )

  document.documentElement.style.setProperty(
    '--dropdown-second-color-light',
    isThemeFuture9
      ? 'rgba(77,9,30,0)'
      : addAlphaToColor(theme?.theme?.selectedThemeColor2 || '', 0.1),
  )

  document.documentElement.style.setProperty(
    '--fastest-odd-fastest-odd-4',
    isThemeFuture9 ? '#b4b4b475' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--header-top-first-bg',
    isThemeFuture9 ? 'rgb(240, 138, 47)' : theme?.headerTop?.firstColor,
  )
  document.documentElement.style.setProperty(
    '--header-top-second-bg',
    isThemeFuture9 ? 'rgb(226, 53, 66)' : theme?.headerTop?.secondColor,
  )
  document.documentElement.style.setProperty(
    '--header-top-third-bg',
    isThemeFuture9 ? 'rgb(229, 51, 68)' : theme?.headerTop?.thirdColor,
  )
  document.documentElement.style.setProperty(
    '--header-top-forth-bg',
    isThemeFuture9 ? 'rgb(138, 5, 55)' : theme?.headerTop?.fourthColor,
  )
  document.documentElement.style.setProperty(
    '--header-bottom-bg',
    isThemeFuture9 ? 'rgb(51, 13, 10)' : theme?.paymentMethodColor || '',
  )
  document.documentElement.style.setProperty(
    '--header-bottom-marquee-text',
    isThemeFuture9 ? 'rgb(235, 255, 3)' : theme?.headerBottom?.marqueeTextColor,
  )
  document.documentElement.style.setProperty(
    '--header-bottom-marquee-bg',
    isThemeFuture9 ? 'rgb(126, 26, 53)' : theme?.paymentMethodColor || '',
  )
  document.documentElement.style.setProperty(
    '--button-first-bg',
    isThemeFuture9 ? 'rgb(240, 74, 54)' : theme?.buttonTheme?.firstColor || '',
  )
  document.documentElement.style.setProperty(
    '--button-second-bg',
    isThemeFuture9 ? 'rgb(240, 74, 54)' : theme?.buttonTheme?.secondColor || '',
  )
  document.documentElement.style.setProperty(
    '--text-first',
    isThemeFuture9 ? 'rgb(240, 74, 54)' : theme?.titleText?.firstColor || '',
  )
  document.documentElement.style.setProperty(
    '--text-second',
    isThemeFuture9 ? 'rgb(250, 164, 63)' : theme?.titleText?.secondColor || '',
  )
  document.documentElement.style.setProperty(
    '--light-background',
    isThemeFuture9 ? '#FFFFFF' : theme?.background?.light || '',
  )
  document.documentElement.style.setProperty(
    '--dark-background',
    isThemeFuture9
      ? getAssetUrl('asset/bg_dark.webp')
      : theme?.background?.dark || '',
  )
  document.documentElement.style.setProperty(
    '--buttonTheme-borderButtonFirst',
    isThemeFuture9
      ? 'rgb(240, 74, 54)'
      : theme?.buttonTheme?.borderButtonFirst || '',
  )
  document.documentElement.style.setProperty(
    '--buttonTheme-borderButtonSecond',
    isThemeFuture9
      ? 'rgb(250, 164, 63)'
      : theme?.buttonTheme?.borderButtonSecond || '',
  )

  document.documentElement.style.setProperty(
    '--sideNavBar-firstColor',
    isThemeFuture9 ? '#BC0B43' : theme?.theme?.selectedThemeColor2 || '',
  )
  document.documentElement.style.setProperty(
    '--sideNavBar-first-color',
    isThemeFuture9
      ? 'rgba(77,9,30,0.48)'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 50),
  )
  document.documentElement.style.setProperty(
    '--sideNavBar-second-color',
    isThemeFuture9
      ? 'rgba(77,9,30,0.47)'
      : darkenColor(theme?.theme?.selectedThemeColor2 || '', 50),
  )

  document.documentElement.style.setProperty(
    '--icon-color',
    isThemeFuture9 ? '#e5463e' : theme?.themeColor || '',
  )

  document.documentElement.style.setProperty(
    '--sideNavBar-border-firstColor',
    isThemeFuture9 ? '#2C0506' : theme?.sideNavBar?.border.firstColor || '',
  )
  document.documentElement.style.setProperty(
    '--sideNavBar-border-secondColor',
    isThemeFuture9 ? '#691208' : theme?.sideNavBar?.border.secondColor || '',
  )
  document.documentElement.style.setProperty(
    '--sideNavBar-firstGradient',
    isThemeFuture9 ? 'rgb(105, 18, 8)' : theme?.sideNavBar?.firstGradient || '',
  )
  document.documentElement.style.setProperty(
    '--sideNavBar-secondGradiendFrom',
    isThemeFuture9
      ? 'rgb(44, 5, 6)'
      : theme?.sideNavBar?.secondGradiendFrom || '',
  )
  document.documentElement.style.setProperty(
    '--sideNavbar-darkGradient',
    isThemeFuture9
      ? 'rgba(77, 9, 30, 0.48)'
      : theme?.sideNavBar?.darkGradient || '',
  )
  document.documentElement.style.setProperty(
    '--paymentMethodColor',
    isThemeFuture9 ? 'rgb(108, 3, 22)' : theme?.paymentMethodColor || '',
  )
  document.documentElement.style.setProperty(
    '--form-gradientFrom',
    isThemeFuture9 ? 'rgba(195, 76, 4, 0.11)' : theme?.form?.gradientFrom || '',
  )
  document.documentElement.style.setProperty(
    '--form-gradientTo',
    isThemeFuture9 ? 'rgb(195, 76, 4)' : theme?.form?.gradientTo || '',
  )
  document.documentElement.style.setProperty(
    '--banner-firstColor',
    isThemeFuture9 ? 'rgb(240, 138, 47)' : theme?.form?.gradientFrom || '',
  )
  document.documentElement.style.setProperty(
    '--banner-secondColor',
    isThemeFuture9 ? 'rgb(226, 53, 66)' : theme?.form?.gradientTo || '',
  )
  document.documentElement.style.setProperty(
    '--banner-button-firstColor',
    isThemeFuture9 ? '#F04A36' : theme?.game?.getStartedNow?.firstColor || '',
  )
  document.documentElement.style.setProperty(
    '--banner-button-secondColor',
    isThemeFuture9 ? '#FAA43F' : theme?.game?.getStartedNow?.secondColor || '',
  )
  document.documentElement.style.setProperty(
    '--theme-color',
    isThemeFuture9 ? 'rgb(179, 56, 30)' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--button-theme-color',
    isThemeFuture9 ? 'rgb(239 68 68)' : theme?.themeColor || '',
  )
  document.documentElement.style.setProperty(
    '--theme-table-themeDarkColor',
    isThemeFuture9 ? 'rgb(240, 74, 54)' : theme?.theme?.themeDarkColor || '',
  )
  document.documentElement.style.setProperty(
    '--theme-table-themeLightColor',
    isThemeFuture9 ? 'rgb(250, 164, 63)' : theme?.theme?.themeLightColor || '',
  )
  document.documentElement.style.setProperty(
    '--selected-theme-color',
    isThemeFuture9
      ? 'rgb(240, 74, 54)'
      : theme?.theme?.selectedThemeColor || '',
  )
  document.documentElement.style.setProperty(
    '--selected-theme-color2',
    isThemeFuture9
      ? 'rgb(250, 164, 63)'
      : theme?.theme?.selectedThemeColor2 || '',
  )
  document.documentElement.style.setProperty(
    '--navigation-bar',
    isThemeFuture9 ? 'rgba(105,18,8,1)' : 'white',
  )
  document.documentElement.style.setProperty(
    '--pop-up-background-color',
    isThemeFuture9 ? '#2b0506' : theme?.theme?.selectedThemeColor || '',
  )
  document.documentElement.style.setProperty(
    '--inPlay-gradientTo',
    isThemeFuture9 ? '#2c0506e0' : theme?.inPlay?.gradientTo || '',
  )
  document.documentElement.style.setProperty(
    '--inPlay-gradientFrom',
    isThemeFuture9 ? '#FAA43F' : theme?.inPlay?.gradientFrom || '',
  )

  document.documentElement.style.setProperty(
    '--betslip-average-odds',
    isThemeFuture9 ? '#482f15' : theme?.paymentMethodColor || '',
  )

  document.documentElement.style.setProperty(
    '--accordian-gradientTo',
    isThemeFuture9 ? 'rgba(77,_9,_30,_0.48)' : theme?.inPlay?.gradientTo || '',
  )
  document.documentElement.style.setProperty(
    '--accordian-gradientFrom',
    isThemeFuture9
      ? 'rgba(77,_9,_30,_0.00)'
      : theme?.inPlay?.gradientFrom || '',
  )

  document.documentElement.style.setProperty(
    '--inPlay-match-gradientTo',
    isThemeFuture9
      ? 'rgb(250, 164, 63)'
      : theme?.inPlay?.match.gradientTo || '',
  )

  document.documentElement.style.setProperty(
    '--colored-text-box',
    isThemeFuture9
      ? 'rgb(240, 74, 54)'
      : theme?.inPlay?.match.gradientFrom || '',
  )

  document.documentElement.style.setProperty(
    '--inPlay-match-gradientFrom',
    isThemeFuture9 ? '#F04A36' : theme?.inPlay?.match.gradientFrom || '',
  )
  document.documentElement.style.setProperty(
    '--inPlay-match-background',
    isThemeFuture9 ? 'rgb(75, 13, 26)' : theme?.inPlay?.match.background || '',
  )
  document.documentElement.style.setProperty(
    '--gamePlay-firstColor',
    isThemeFuture9 ? 'rgb(44, 5, 6)' : theme?.gamePlay?.firstColor || '',
  )
  document.documentElement.style.setProperty(
    '--gamePlay-secondColor',
    isThemeFuture9
      ? 'rgba(105, 18, 8, 0.62)'
      : theme?.gamePlay?.secondColor || '',
  )
  document.documentElement.style.setProperty(
    '--gamePlay-resFirstColor',
    isThemeFuture9 ? 'rgb(44, 5, 6)' : theme?.gamePlay?.resFirstColor || '',
  )
  document.documentElement.style.setProperty(
    '--gamePlay-resSecondColor',
    isThemeFuture9
      ? 'rgba(105, 18, 8, 0.62)'
      : theme?.gamePlay?.resSecondColor || '',
  )
  document.documentElement.style.setProperty(
    '--game-background',
    isThemeFuture9 ? 'rgb(82, 13, 35)' : theme?.game?.background || '',
  )
  document.documentElement.style.setProperty(
    '--game-scheduleColor',
    isThemeFuture9 ? 'rgb(54, 19, 11)' : theme?.game?.scheduleColor || '',
  )
  document.documentElement.style.setProperty(
    '--game-selected-background',
    isThemeFuture9 ? 'rgb(64, 15, 4)' : theme?.game?.selectedBackground || '',
  )
  document.documentElement.style.setProperty(
    '--game-matchBackground',
    isThemeFuture9 ? 'rgb(30, 1, 10)' : theme?.game?.matchBackground || '',
  )
  document.documentElement.style.setProperty(
    '--game-getStartedNow-firstColor',
    isThemeFuture9
      ? 'rgb(250, 164, 63)'
      : theme?.theme?.selectedThemeColor || '',
  )
  document.documentElement.style.setProperty(
    '--game-getStartedNow-secondColor',
    isThemeFuture9
      ? 'rgb(240, 74, 54)'
      : theme?.theme?.selectedThemeColor2 || '',
  )
  document.documentElement.style.setProperty(
    '--advertisement2-backgroundFirst',
    isThemeFuture9
      ? 'rgba(254, 170, 95, 0.9)'
      : theme?.advertisement2?.backgroundFirst || '',
  )
  document.documentElement.style.setProperty(
    '--advertisement2-backgroundSecond',
    isThemeFuture9
      ? 'rgba(226, 53, 66, 0.9)'
      : theme?.advertisement2?.backgroundSecond || '',
  )
  document.documentElement.style.setProperty(
    '--advertisement2-backgroundThird',
    isThemeFuture9
      ? 'rgba(229, 51, 68, 0.9)'
      : theme?.advertisement2?.backgroundThird || '',
  )
  document.documentElement.style.setProperty(
    '--advertisement2-backgroundFourth',
    isThemeFuture9
      ? 'rgba(133, 13, 58, 0.9)'
      : theme?.advertisement2?.backgroundFourth || '',
  )
  document.documentElement.style.setProperty(
    '--advertisement2-backgroundUrl',
    isThemeFuture9 ? '' : theme?.advertisement2?.backgroundUrl || '',
  )

  // FORM
  document.documentElement.style.setProperty(
    '--form-gradientFrom',
    isThemeFuture9 ? 'rgba(195, 76, 4, 0.11)' : theme?.form?.gradientFrom || '',
  )
  document.documentElement.style.setProperty(
    '--form-gradientTo',
    isThemeFuture9 ? 'rgb(195, 76, 4)' : theme?.form?.gradientTo || '',
  )
}

import { useEffect, useState } from 'react'
import { extractNumberFromString } from '../MatchScore'
import { isMobileScreensix } from '../../../../../utils/internalUtils'

interface ICricketScore {
  scoreData: any
  isMobile: boolean
}

export const CricketScore = (props: ICricketScore) => {
  const getActualRunForTeam = (last24BallRun: any, currentOver: string) => {
    let currentOverLocal = extractNumberFromString(currentOver)

    return sendValues(last24BallRun, sendDecimalValue(currentOverLocal))
  }

  function sendDecimalValue(number: number) {
    if (number % 1 !== 0) {
      const fractionalPart = Number(number.toString().split('.')[1])
      if (fractionalPart) {
        return fractionalPart
      }
    }
    return 0
  }

  function sendValues(array: any, n: number) {
    if (n === 0) {
      return array
    }

    if (typeof n === 'number' && n > 0 && n <= array.length) {
      return array.slice(-n)
    }

    return []
  }

  const [ballRun, setBallRun] = useState(
    props.scoreData &&
      props.scoreData.score &&
      getActualRunForTeam(
        props.scoreData.score.last24ballsNew,
        props.scoreData.score.current_over,
      ),
  )

  const getActualRunForTeamCurrentOver = (last24BallRun: any) => {
    return last24BallRun
  }

  useEffect(() => {
    props.scoreData &&
      props.scoreData.score &&
      setBallRun(
        getActualRunForTeamCurrentOver(props.scoreData.score.last24ballsNew),
      )
  }, [
    props.scoreData &&
      props.scoreData.score &&
      props.scoreData.score.last24ballsNew,
    props.scoreData &&
      props.scoreData.score &&
      props.scoreData.score.current_over,
  ])

  const getBackgroundColor = (balls: any) => {
    switch (balls.score_card) {
      case 'w':
        return '#ffffff'

      case 'ww':
        return '#e91e1e'

      case '1w':
      case '2w':
      case '3w':
      case '4w':
      case '5w':
      case '6w':
        return 'rgb(31 234 78)'

      case '1ww':
        return 'red'

      case '2ww':
        return 'red'

      case '3ww':
        return 'red'

      case '4ww':
        return 'red'

      case '4':
      case '6':
        return 'rgb(31 234 78)'

      case 'n':
        return '#ffff00'

      default:
        return '#0A38AF'
    }
  }

  const getContent = (balls: any) => {
    switch (balls) {
      case 'w':
        return 'WD'

      case '1w':
        return '1WD'

      case '2w':
        return '2WD'

      case '3w':
        return '3WD'

      case '4w':
        return '4WD'

      case '5w':
        return '5WD'

      case '6w':
        return '6WD'

      case '1ww':
        return '1w'

      case '2ww':
        return '2w'

      case '3ww':
        return '3w'

      case '4ww':
        return '4w'

      case 'ww':
        return 'W'

      case 'n':
        return 'Nb'

      default:
        return balls
    }
  }

  const getFontColor = (balls: any) => {
    switch (balls.score_card) {
      case 'ww':
      case '1w':
      case '2w':
      case '3w':
      case '4w':
      case '5w':
      case '6w':
        return '#000000'

      case 'w':
        return '#000000'

      case '1ww':
        return '#000000'

      case '2ww':
        return '#000000'

      case '3ww':
        return '#000000'

      case '4ww':
        return '#000000'

      case 'n':
        return '#000000'

      default:
        return '#ffffff'
    }
  }

  return (
    <>
      {props.scoreData && props.scoreData.score && (
        <div
          className={`${props.isMobile ? '' : 'dark:bg-[linear-gradient(173.49deg,var(--dropdown-second-color-light)_5.4%,var(--dropdown-second-color-light)_94.88%)]'} flex flex-col m-0 py-2 px-4`}
        >
          <p
            className={`bold font-dm_sans ${isMobileScreensix() ? 'text-white' : 'dark:text-white text-black'} text-[13px] font-normal leading-[31px] tracking-normal text-left`}
          >
            {props.scoreData.score.msg}
          </p>

          <div className='bold flex gap-2 my-2 '>
            {extractNumberFromString(props.scoreData.score.current_over) !==
              20 &&
              ballRun &&
              ballRun
                .filter((ball: any) => ball.score_card !== '')
                .map((balls: any, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        color: getFontColor(balls),
                        background: getBackgroundColor(balls),
                      }}
                      className={`bold flex justify-center items-center
                p-2 rounded-full  h-[22px] font-dm_sans text-[13px] font-normal leading-[31px] tracking-normal text-left`}
                    >
                      {getContent(balls.score_card)}
                    </div>
                  )
                })}
          </div>
          {!props.isMobile && (
            <>
              <div className='flex my-2 text-black dark:text-white'>
                {props.scoreData.score.current_over && (
                  <div className='border-r border-white pr-4 font-dm_sans text-[13px] font-normal leading-[16px] tracking-normal text-left'>
                    Current Inning: {props.scoreData.score.current_inning}
                  </div>
                )}
                {props.scoreData.score.current_over && (
                  <div className='border-r border-white px-4 font-dm_sans text-[13px] font-normal leading-[16px] tracking-normal text-left'>
                    Current Over: {props.scoreData.score.current_over}
                  </div>
                )}
                {props.scoreData.score.current_score && (
                  <div className='border-r border-white px-4 font-dm_sans text-[13px] font-normal leading-[16px] tracking-normal text-left'>
                    Current Score: {props.scoreData.score.current_score}
                  </div>
                )}
                {!!parseFloat(props.scoreData.score.current_wickets) && (
                  <div className='border-r border-white px-4 font-dm_sans text-[13px] font-normal leading-[16px] tracking-normal text-left'>
                    Current Wicket: {props.scoreData.score.current_wickets}
                  </div>
                )}
                {!!parseFloat(props.scoreData.score.target) && (
                  <div className=' px-4 font-dm_sans text-[13px] font-normal leading-[16px] tracking-normal text-left'>
                    Target: {props.scoreData.score.target}
                  </div>
                )}
                {props.scoreData.score.currentRunRate && (
                  <div className=' px-4 font-dm_sans text-[13px] font-normal leading-[16px] tracking-normal text-left'>
                    Run Rate: {props.scoreData.score.currentRunRate}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

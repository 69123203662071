import CryptoJS from 'crypto-js'

export const encrypt = (payload: any) => {
  let secretKey = import.meta.env.VITE_API_SECRET_KEY as string
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    secretKey,
  ).toString()
  const urlSafeEncrypted = encrypted.replace(/\//g, '_')
  return urlSafeEncrypted
}

export const decrypt = (secretKeyPayload: any) => {
  if (!secretKeyPayload) {
    return ''
  }
  let secretKey = import.meta.env.VITE_API_SECRET_KEY as string
  const safeEncrypted = secretKeyPayload.replace(/_/g, '/')
  const bytes = CryptoJS.AES.decrypt(safeEncrypted, secretKey)
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
  return JSON.parse(decryptedData)
}

export const isMobileScreen = () => {
  return (window as any).mobileAndTabletCheck() || window.innerWidth <= 900
}

export const isMobileScreensix = () => {
  return window.innerWidth <= 600
}

export const isMobileScreensixXSmall = () => {
  return window.innerWidth <= 400
}

export const isMobileScreensixSmall = () => {
  return window.innerWidth <= 500
}

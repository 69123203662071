export interface IScore {
  scoreData: IScoreData
  isLoading: boolean
}

export interface IScoreData {
  eventId: string
  iframeUrl: string
  score: any
}

const initialState: IScore = {
  scoreData: {
    eventId: '',
    score: null,
    iframeUrl: '',
  },
  isLoading: false,
}

export const SAVE_SCORE = '@SCORE/SAVE_SCORE'

export function ScoreReducer(state = initialState, action: any) {
  switch (action.type) {
    case SAVE_SCORE: {
      return {
        ...state,
        scoreData: action.scoreData,
        isLoading: action.isLoading,
      }
    }

    default:
      return state
  }
}

import { useState } from 'react'
import { ShowFancyMarkets } from '../ShowFancyMarkets'
import { ShowFancyMarketsContent } from '../ShowFancyMarketsContent'
import { RunBetfairMarket } from '../showMatch/RunBetfairMarket'

interface ISessionComponent {
  sessionMarket: any
  fancySessionTotalMarket: any
  premiumMarkets: any
  isLogin: any
  isDarkMode: boolean
  currentBet: any
  sessionFancyXtraMarketMap: any
  premiumSessionMarketMap: any
  fancySessionMarket: any
  fancySessionWPMarket: any
  fancySessionXtraMarket: any
  onMarketClick: any
  sessionMarketMap: any
  sessionFancyTotalMarketMap: any
  sessionFancyMarketMap: any
  sessionFancyWPMarketMap: any
  deleteFavoriteMarket: any
  marketFavoriteList: any
  isDrawerOpen: any
  hashMapMarket: any
  setIsDrawerOpen: any
  currentDomain: any
  addFavoriteMarketList: any
  betSlip: any
}

export const SessionComponent = (props: ISessionComponent) => {
  const [fancy, setFancy] = useState(true)
  const [value, setValue] = useState(0)

  const getFancyMarket = () => {
    switch (value) {
      case 0:
        return props.fancySessionTotalMarket

      case 1:
        return props.fancySessionMarket

      case 2:
        return props.fancySessionWPMarket

      default:
        return props.fancySessionXtraMarket
    }
  }

  const getFancyHasMap = () => {
    switch (value) {
      case 0:
        return props.sessionFancyTotalMarketMap

      case 1:
        return props.sessionFancyMarketMap

      case 2:
        return props.sessionFancyWPMarketMap

      default:
        return props.sessionFancyXtraMarketMap
    }
  }

  return (
    <>
      {(!!props.sessionMarket.length ||
        !!props.fancySessionTotalMarket.length ||
        !!props.premiumMarkets.length) && (
        <>
          <div className='px-3 fancyWrapper md:px-0'>
            <div
              onClick={() => setFancy(true)}
              className='cursor-pointer font-dm_sans xs:text-[14px] sm:text-[17px] font-medium leading-[17px] tracking-normal text-left bg-[#CC7603] xs:py-2 xs:px-2 sm:p-3 sm:px-5 rounded-md mr-4'
            >
              FANCY
            </div>
            <div
              onClick={() => setFancy(false)}
              className='cursor-pointer font-dm_sans xs:text-[14px] sm:text-[17px] font-medium leading-[17px] tracking-normal text-left bg-[#1B982D] xs:py-2 xs:px-2 sm:p-3  sm:px-8 rounded-md mr-4'
            >
              PREMIUM
            </div>
          </div>

          <>
            {fancy && (
              <>
                <ShowFancyMarkets
                  value={value}
                  setValue={setValue}
                  isDarkMode={props.isDarkMode || false}
                />
                <ShowFancyMarketsContent
                  marketFavoriteList={props.marketFavoriteList}
                  betSlipStake={props.betSlip.betSlipStake}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  currentBet={props.currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  fancySessionMarket={getFancyMarket()}
                  hashMapMarket={props.hashMapMarket}
                  isDrawerOpen={props.isDrawerOpen}
                  isLogin={!!props.isLogin}
                  onMarketClick={props.onMarketClick}
                  sessionFancyMarketMap={getFancyHasMap()}
                  sessionMarket={props.sessionMarket}
                  sessionMarketMap={props.sessionMarketMap}
                  setIsDrawerOpen={props.setIsDrawerOpen}
                  value={value}
                />
              </>
            )}
          </>
          <>
            {!fancy &&
              !!props.premiumMarkets.length &&
              props.premiumMarkets.map((market: any) => {
                let tempMarket = props.premiumSessionMarketMap.get(market.id)
                if (!tempMarket || Object.keys(tempMarket).length === 0) {
                  tempMarket = market
                }
                tempMarket.name = market.marketId.marketName
                return tempMarket.status === 'result' ? (
                  <></>
                ) : (
                  <RunBetfairMarket
                    tokenExpire={false}
                    showFullNumber={true}
                    addFavoriteMarketList={props.addFavoriteMarketList}
                    betSlip={props.betSlip}
                    currentBet={props.currentBet}
                    currentDomain={props.currentDomain}
                    deleteFavoriteMarket={props.deleteFavoriteMarket}
                    hashMapMarket={props.hashMapMarket}
                    isDrawerOpen={props.isDrawerOpen}
                    isLogin={props.isLogin}
                    market={market}
                    marketFavoriteList={props.marketFavoriteList}
                    onMarketClick={props.onMarketClick}
                    setIsDrawerOpen={props.setIsDrawerOpen}
                    tempMarket={tempMarket}
                  />
                )
              })}
          </>
        </>
      )}
    </>
  )
}

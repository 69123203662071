import { SAVE_FASTEST_ODD } from '../../actions/userAction/fastestOddAction'

export function FastestOddReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case SAVE_FASTEST_ODD: {
      return {
        ...state,
        fastestOdds: action.payload,
      }
    }

    default:
      return state
  }
}

const initialState: any = {
  fastestOdds: [],
}

import { DEFAULT_QUICKBET, GET_USER_QUICKBET } from '../../actions/userAction'
import { ISetStakeAction, IStakeResponse } from './StakesReducer'

export function QuickBetReducer(state = initialState, action: ISetStakeAction) {
  switch (action.type) {
    case GET_USER_QUICKBET: {
      return {
        ...state,
        data: action.data,
        isLoading: action.isLoading,
      }
    }

    default:
      return state
  }
}

export interface IQuickBetStake {
  isLoading?: boolean
  data: IStakeResponse
}

const initialState: IQuickBetStake = {
  data: DEFAULT_QUICKBET,
  isLoading: false,
}

import { formatDateTime, getAssetUrl } from '../../../utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules'
import SwiperCore from 'swiper'
import { SwiperOptions } from 'swiper/types/swiper-options'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import CommonSwiper from '../../../component/commonSwiper'
import { encrypt } from '../../../api/authHelper'

SwiperCore.use([FreeMode, Navigation])

export const ScheduleContent = (props: { upComingMatches: any[] }) => {
  const navigate = useNavigate()
  const [upComingMatches, setUpComingMatches] = useState(
    extractAllEvents(props.upComingMatches),
  )

  useEffect(() => {
    setUpComingMatches(extractAllEvents(props.upComingMatches))
  }, [props.upComingMatches])

  function extractAllEvents(data: any) {
    const allEvents: any[] = []

    data.forEach((matchType: any) => {
      matchType.events.forEach((event: any) => {
        allEvents.push(event)
      })
    })

    return allEvents
  }

  const swiperModules = [FreeMode, Navigation]

  const swiperOptions: SwiperOptions = {
    freeMode: true,
    allowTouchMove: true,
    navigation: {
      nextEl: '.swiper-button-next-12',
      prevEl: '.swiper-button-prev-12',
    },
    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 20,
      },
      600: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
    },
  }

  const seeAllClick = () => {
    navigate('/schedule')
  }

  const isSmallScreen = useMediaQuery('(max-width:600px)')

  return (
    <div className='flex flex-nowrap overflow-x-scroll scroll-smooth snap-x scrollbar_hide	snap-mandatory touch-pan-x xs:mt-2 sm:mt-2 md:mt-4 gap-0 items-center relative'>
      {upComingMatches.length > 0 && (
        <div className='swiper-button-prev-12 absolute md:block hidden !text-white cursor-pointer z-50 bg-[#ffe2e254] rounded-r-md'>
          <ChevronLeftTwoToneIcon />
        </div>
      )}

      {upComingMatches.length > 0 ? (
        <>
          <CommonSwiper
            id='schedule-content-slider'
            swiperOptions={swiperOptions}
            loop={upComingMatches && upComingMatches.length > 3 ? true : false}
            speed={7000}
            parallax={true}
            freeMode={true}
            autoPlay={{
              delay: 0,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            modules={[...swiperModules, Pagination, Autoplay]}
            arrow={false}
            classNameSlider={
              isSmallScreen
                ? '!min-w-[200px] !w-auto !max-w-[210px] !h-[110px] !cursor-pointer relative !bg-[var(--highlight-schedule)] !px-5 !py-2'
                : 'cursor-pointer !w-auto !flex !text-[white] !bg-[var(--highlight-schedule)] !px-5 !py-2 !h-[58px]'
            }
          >
            {!!upComingMatches &&
              !!upComingMatches.length &&
              upComingMatches.map((item, i) => {
                return !item.marketId.eventType ? (
                  <></>
                ) : (
                  <>
                    <img
                      className='w-5 h-5 mt-3 mr-3'
                      src={getAssetUrl(
                        `adminGames/sports/${item.marketId.eventType.name.toLowerCase().replace(/\s+/g, '')}.webp`,
                      )}
                      alt='Admin Games Sports Icon'
                    />
                    <div
                      onClick={() => {
                        navigate(`/events/${encrypt(item.marketId.event.id)}`)
                      }}
                      className='flex flex-col'
                    >
                      <div className='text-[13px] sm:text-[15px] font-medium '>
                        {item.marketId.event.name}
                      </div>
                      <div className='text-[12px] sm:text-[14px] font-normal'>
                        {formatDateTime(item.marketId.event.openDate)}
                      </div>
                    </div>
                  </>
                )
              })}
          </CommonSwiper>

          {isSmallScreen && (
            <>
              <div
                onClick={seeAllClick}
                className='!bg-[#36130B] !text-[white] rounded-md !bg-no-repeat !bg-cover !bg-center !h-[96px] flex items-center justify-center relative mx-2 p-2'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div className='text-[12px] text-bolder text-center flex flex-col'>
                  See All
                </div>
                <div className='bg-gray-200 border border-gray-400 rounded-full p-2 mt-1'>
                  <div className='w-2 h-2  flex items-center justify-center text-gray-600'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path
                        fillRule='evenodd'
                        d='M10 18a1 1 0 01-1-1v-6H3a1 1 0 110-2h6V3a1 1 0 112 0v6h6a1 1 0 110 2h-6v6a1 1 0 01-1 1z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className='dark:text-white text-black'
          style={{ textAlign: 'center', width: '100%' }}
        >
          No Schedule Matches
        </div>
      )}
      {upComingMatches.length > 0 && (
        <div className='swiper-button-next-12 absolute md:block hidden !text-white cursor-pointer z-50 right-0 bg-[#ffe2e254] rounded-l-md'>
          <ChevronRightTwoToneIcon />
        </div>
      )}
    </div>
  )
}

import { ChangeEventHandler, useRef } from 'react'
import useDisableNumberInputScroll from '../../utils/UseDisableNumberInputScroll'

interface IInput {
  id?: string
  className?: string
  type: string
  value?: any
  onKeyDown?: any
  onChange?: ChangeEventHandler<any> | undefined
  placeholder?: string
  min?: number
  max?: number
  readOnly?: boolean
  name?: string
  tabIndex?: number
  autoFocus?: any
  label?: any
}

const InternalInput = (props: IInput) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useDisableNumberInputScroll(inputRef)

  return (
    <input
      ref={inputRef}
      autoFocus={props.autoFocus}
      onKeyDown={props.onKeyDown}
      id={props.id}
      tabIndex={props.tabIndex}
      className={props.className}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  )
}

export default InternalInput

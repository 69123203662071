import { Dispatch } from '@reduxjs/toolkit'
import {
  GET_COMPETITION_DATA,
  GET_EVENTS_DATA,
  GET_MARKET_DATA,
  GetReduxStateFn,
} from '../..'

export const SAVE_COMPETITION_REQUEST = 'SAVE_COMPETITION_REQUEST'
export const SAVE_EVENT_REQUEST = 'SAVE_EVENT_REQUEST'
export const SAVE_MARKET_REQUEST = 'SAVE_MARKET_REQUEST'

export const saveCompetitionRequest = (data: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: SAVE_COMPETITION_REQUEST,
      loading: true,
      payload: data,
    })
  }
}

export const saveEventRequest = (data: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: SAVE_EVENT_REQUEST,
      loading: true,
      payload: data,
    })
  }
}

export const saveMarketRequest = (data: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: SAVE_MARKET_REQUEST,
      loading: true,
      payload: data,
    })
  }
}

export const saveCompetitionResponse = (data: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: GET_COMPETITION_DATA,
      data: data,
    })
  }
}

export const saveEventResponse = (data: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: GET_EVENTS_DATA,
      data: data,
    })
  }
}

export const saveMarketResponse = (data: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: GET_MARKET_DATA,
      data: data,
    })
  }
}

import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../../type'
import {
  IBrowserDetail,
  ICurrentBet,
} from '../../../pages/Game/betslip/NewBetSlip'
import {
  // HEADERS,
  UNKNOWN,
  // makeGetHttpCallAsync,
  makePostHttpCallAsync,
} from '../../../api'
import { getClientCountryDetail, getClientInfo } from '../../../utils'
// import { createLiabilityHashmap } from '../../../utils/internalUtils'
import { BET_TYPE } from '../../../types/enums/betType'
// import { GET_OPEN_BET } from '../reportAction/reportsAction'
import { UPDATE_USER_POINTS } from '../loginAction'

export const UPDATE_CURRENT_BETSLIP = 'UPDATE_CURRENT_BETSLIP'
export const UPDATE_IS_BET_LOADING = 'UPDATE_IS_BET_LOADING'
export const UPDATE_CURRENT_BETSLIP_ODDS = 'UPDATE_CURRENT_BETSLIP_ODDS'
export const UPDATE_BETHISTORY_EVENT_ID = 'UPDATE_BETHISTORY_EVENT_ID'
export const HASHMAP_BET = 'HASHMAP_BET'
export const BET_SLIP_STAKE = 'BET_SLIP_STAKE'
export const OPEN_LOGIN_SCREEN = 'OPEN_LOGIN_SCREEN'
export const SET_IS_LOGIN = '@LOGIN/SET_IS_LOGIN'
export const GET_PROFILE = '@PROFILE/GET_PROFILE'

export const updateOpenLoginScreen = (openLoginScreen: boolean) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: OPEN_LOGIN_SCREEN,
      openLoginScreen: openLoginScreen,
    })
  }
}

export const onMarketClick = (marketDetail: ICurrentBet) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: UPDATE_CURRENT_BETSLIP,
      currentBet: marketDetail,
    })
  }
}

export interface IMarketBetUpdateRequest {
  profit: number
  liability: number
  stake: number
}

export const onCurrentMarketUpdate = (
  marketUpdate: IMarketBetUpdateRequest,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let currentMarket = getState().betSlip.currentBet
    currentMarket.stake = marketUpdate.stake
    currentMarket.profit =
      marketUpdate.profit === -1 ? currentMarket.profit : marketUpdate.profit
    currentMarket.liability =
      marketUpdate.liability === -1
        ? currentMarket.liability
        : marketUpdate.liability

    dispatch({
      type: UPDATE_CURRENT_BETSLIP,
      currentBet: currentMarket,
    })
  }
}

export interface IPlaceOrder {
  marketId?: any
  stake?: number
  teamId?: string
  oddType: BET_TYPE
  run: number | string
  odd: number
  nation?: string
  marketType?: string
  eventId?: any
  name?: string
  matchDate?: Date
  browserDetail?: IBrowserDetail
  clientCountryDetail?: any
}

;(window as any).mobileAndTabletCheck = function () {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}

export const placeMarketOrder = (
  order: IPlaceOrder,
  currentDomain: any,
  data: any,
) => {
  let tmpOrder: IPlaceOrder = {
    ...order,
    nation:
      data && Object.keys(data).length ? data.country_code ?? UNKNOWN : UNKNOWN,
    ...{
      browserDetail: getClientInfo(),
    },
    ...{
      countryDetail: data && getClientCountryDetail(data),
    },
  }

  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    // let userType = await localStorage.getItem(HEADERS.userType)
    let { quickBet, userData } = getState().login
    makePostHttpCallAsync(
      dispatch,
      `api/user/openBet`,
      currentDomain.type,
      JSON.stringify({ ...tmpOrder }),
      false,
      true,
      '',
      true,
      'Order Placed',
      '',
      true,
    )
      .then((response: any) => {
        let marketBook = response?.docs?.marketBook
        let marketId = response?.docs?.market?.id
        let liabilityHashMapLocal = [...getState().betSlip.hashMapBet]
        let marketFound = false
        if (response.docs.market.type === 'session') {
          let marketValue: any = {}
          for (let index = 0; index < liabilityHashMapLocal.length; index++) {
            if (liabilityHashMapLocal[index].marketId === marketId) {
              marketValue = liabilityHashMapLocal[index].marketValue[0]
              let sessionBookArray = []
              for (let key in marketBook?.map) {
                let value = marketBook?.map[key]
                sessionBookArray.push({
                  run: key,
                  amount: value,
                })
              }

              marketValue = {
                ...marketValue,
                minLoss: marketBook.exposure,
                sessionBookArray: sessionBookArray,
                sessionBook: marketBook?.map,
              }

              liabilityHashMapLocal[index].marketValue[0] = marketValue
              marketFound = true
            }
          }

          if (!marketFound) {
            let sessionBookArray = []
            for (let key in marketBook?.map) {
              let value = marketBook?.map[key]
              sessionBookArray.push({
                run: key,
                amount: value,
              })
            }

            liabilityHashMapLocal.push({
              marketId: marketId,
              marketValue: [
                {
                  liability: -1,
                  minLoss: marketBook.exposure,
                  sessionBookArray: sessionBookArray,
                  sessionBook: marketBook?.map,
                },
              ],
            })
          }
        } else {
          for (let index = 0; index < liabilityHashMapLocal.length; index++) {
            if (liabilityHashMapLocal[index].marketId === marketId) {
              for (
                let index1 = 0;
                index1 < liabilityHashMapLocal[index].marketValue.length;
                index1++
              ) {
                liabilityHashMapLocal[index].marketValue[index1].profit =
                  marketBook?.map[
                    liabilityHashMapLocal[index].marketValue[index1].teamId
                  ]
              }
              marketFound = true
            }
          }

          if (!marketFound) {
            let marketValueLocal: any = []
            for (let key in marketBook?.map) {
              let value = marketBook?.map[key]
              marketValueLocal.push({
                liability: -1,
                profit: value,
                teamId: key,
                teamName: '',
              })
            }

            liabilityHashMapLocal.push({
              marketId: marketId,
              marketValue: marketValueLocal,
            })
          }
        }

        // let availablePts = userData?.availablePts || 0
        // let pts = userData?.pts || 0
        // let exposure = userData?.exposure || 0

        // availablePts = availablePts - response?.docs?.liability,
        // exposure = response?.docs?.liability + exposure

        // dispatch({
        //   type: UPDATE_USER_POINTS,
        //   availablePts: availablePts,
        //   pts: pts,
        //   exposure: exposure,
        // })

        dispatch({
          type: HASHMAP_BET,
          hashMapBet: liabilityHashMapLocal,
        })

        !quickBet &&
          dispatch({
            type: BET_SLIP_STAKE,
            betSlipStake: 0,
          })

        dispatch({
          type: UPDATE_IS_BET_LOADING,
          isBetSlipLoading: false,
        })

        dispatch({
          type: UPDATE_CURRENT_BETSLIP,
          currentBet: {},
        })

        // dispatch({ type: GET_PROFILE, loading: false })
        // makeGetHttpCallAsync(
        //   dispatch,
        //   `api/${userType === 'defaultBranch' ? 'branch' : userType}/profile`,
        //   '',
        //   currentDomain.type,
        //   '',
        //   true,
        // ).then((response: any) => {
        //   dispatch({ type: SET_IS_LOGIN, isLogin: response.docs })
        // })

        // makeGetHttpCallAsync(
        //   dispatch,
        //   `api/user/openBet`,
        //   `eventId=${order.eventId}&active=true`,
        //   currentDomain.type,
        //   false,
        //   true,
        //   '',
        // ).then((response: any) => {
        //   let userId = getState().login.userData._id

        //   let liabilityHashMap = createLiabilityHashmap(
        //     response.docs,
        //     userType || '',
        //     userId,
        //   )
        //   dispatch({
        //     type: HASHMAP_BET,
        //     hashMapBet: liabilityHashMap,
        //   })
        //   dispatch({
        //     type: GET_OPEN_BET,
        //     page: { totalPages: 1 },
        //     payload: response.docs,
        //     loading: true,
        //   })
        // })
      })
      .catch((error: any) => {
        dispatch({
          type: UPDATE_IS_BET_LOADING,
          isBetSlipLoading: false,
        })
      })
  }
}

export const updateHashMap = (hashMapBet: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({
      type: HASHMAP_BET,
      hashMapBet: hashMapBet,
    })
  }
}

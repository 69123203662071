import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import {
  HEADERS,
  makeGetHttpCallAsync,
  makePatchHttpCallAsync,
  makePutHttpCallAsync,
} from '../../api'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { getCorrectUserType } from '../../utils'

export const GET_REQUEST_DETAILS = '@REQUEST/GET_REQUEST_DETAILS'
export const UPDATE_REQUEST_DETAILS = '@REQUEST/UPDATE_REQUEST_DETAILS'

export const getRequestList = (
  currentDomain: ICurrentDomain,
  type: string,
  payload: any,
  isPrint?: boolean,
  cb?: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const userType = await localStorage.getItem(HEADERS.userType)
    const dispatchType = GET_REQUEST_DETAILS
    try {
      !isPrint &&
        dispatch({ type: GET_REQUEST_DETAILS, data: [], loading: true })
      makeGetHttpCallAsync(
        dispatch,
        `api/${userType === 'defaultBranch' ? 'branch' : userType}/request/${type}`,
        payload,
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((res: any) => {
          if (res.docs) {
            cb && cb(res.docs)
            !isPrint &&
              dispatch({
                type: dispatchType,
                data: res.docs || [],
                page: {
                  limit: res.limit,
                  nextPage: res.nextPage,
                  nextPageNumber: res.nextPageNumber,
                  previousPage: res.previousPage,
                  previousPageNumber: res.previousPageNumber,
                  showingFrom: res.showingFrom,
                  showingTo: res.showingTo,
                  totalDocs: res.totalDocs,
                  totalPages: res.totalPages,
                },
                loading: false,
              })
          } else {
            cb && cb([])
            !isPrint &&
              dispatch({ type: dispatchType, loading: false, data: [] })
          }
        })
        .catch(async (error) => {
          cb && cb([])
          let err: any = await error
          !isPrint && dispatch({ type: dispatchType, loading: false, data: [] })
        })
    } catch (error) {
      cb && cb([])
      let err: any = await error
      dispatch({ type: dispatchType, loading: false, data: [] })
    }
  }
}

export const updateRequestListRequest = (
  payload: any | undefined,
  type = '',
  CB: Function,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      const currentDomain = getState().currentDomain
      let userType = localStorage.getItem(HEADERS.userType)

      makePutHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/request/${type}`,
        currentDomain.type,
        JSON.stringify(payload),
        '',
        true,
        '',
        true,
        'Updated Successfully',
      ).then(() => CB && CB())
    } catch (error: any) {}
  }
}

export const updateRequestList = (
  payload: any | undefined,
  type = '',
  CB: Function,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const dispatchType = UPDATE_REQUEST_DETAILS
    try {
      dispatch({ type: dispatchType, loading: true })
      const currentDomain = getState().currentDomain
      let userType = localStorage.getItem(HEADERS.userType)
      makePatchHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/request/${type}`,
        '',
        payload,
        currentDomain.type,
        false,
        true,
        '',
        true,
        'PATCH',
        'Update Successfull',
      )
        .then(() => {
          dispatch({ type: dispatchType, loading: false })
          CB && CB()
        })
        .catch(async (error: any) => {
          let err = await error
          dispatch({ type: dispatchType, loading: false })
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

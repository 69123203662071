import './fallback-style.css'
import LoaderAnimation from './asset/loader/loader.json'
import PatmentAnimation from './asset/loader/success.json'

import Lottie from 'react-lottie-player'
import { getAssetUrl, getRootDomain } from './utils'

export function Fallback(props: { isFuture9: boolean }) {
  const getCurrentDomain = () => {
    const domainName = getRootDomain()
    if (domainName === 'baazigerexch.com') {
      return 'baazigerexch'
    }

    if (domainName === 'tataexch247.com') {
      return 'tataexch247'
    }

    return ''
  }

  return (
    <div className='fallBackContainer'>
      <div className='bg-animation'>
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <div id='stars4'></div>
      </div>

      {props.isFuture9 ? (
        <div className='video-loader'>
          <img
            src={getAssetUrl('whiteLabel/logo/Future.gif')}
            alt='Future9 Loader'
          />
        </div>
      ) : getCurrentDomain() ? (
        <div className='video-loader'>
          <img
            src={getAssetUrl(`whiteLabel/logo/${getCurrentDomain()}.gif`)}
            alt='Future9 Loader'
          />
        </div>
      ) : (
        <div className='flex justify-center items-center h-screen'>
          <Lottie
            loop
            animationData={LoaderAnimation}
            play
            style={{ width: 200, height: 200 }}
          />
        </div>
      )}
    </div>
  )
}

export const SiteLoader = () => {
  return (
    <Lottie
      loop
      animationData={LoaderAnimation}
      play
      style={{ width: 200, height: 200 }}
    />
  )
}

export const InnerSiteLoader = () => {
  return (
    <Lottie
      loop
      animationData={LoaderAnimation}
      play
      style={{ width: '100%', height: 200, marginTop: '5rem' }}
    />
  )
}

export const PaymentDone = () => {
  return (
    <Lottie
      loop
      animationData={PatmentAnimation}
      play
      style={{ width: '100%', height: 200, marginTop: '5rem' }}
    />
  )
}

import { ISetCommon } from '../../types/common'
import {
  GET_GAME_MANAGEMENT_DATA_THIRD_PARTY,
  GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING,
} from '../type'

const initialState: IGameManagementThirdPartyInitial = {
  gamesThirdPartyData: [],
  getGamesThirdPartyDataLoading: false,
  getGamesThirdPartyDataPagination: undefined,
  eventIds: [],
  marketIds: [],
}

export interface IGameManagementThirdPartyInitial {
  gamesThirdPartyData: any
  getGamesThirdPartyDataLoading: boolean
  getGamesThirdPartyDataPagination: any
  eventIds: any[]
  marketIds: any[]
}

export default function (state = initialState, action: ISetCommon) {
  switch (action.type) {
    case GET_GAME_MANAGEMENT_DATA_THIRD_PARTY: {
      return {
        ...state,
        gamesThirdPartyData: action.data,
        getGamesThirdPartyDataLoading: action.loading,
        getGamesThirdPartyDataPagination: action.page,
      }
    }

    case GET_GAME_MANAGEMENT_DATA_THIRD_PARTY_PAGE_LOADING: {
      return {
        ...state,
        getGamesThirdPartyDataLoading: action.loading,
        getGamesThirdPartyDataPagination: action.page,
      }
    }

    default:
      return state
  }
}

import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from '../../redux/type'
import {
  onMarketClick,
  updateOpenLoginScreen,
} from '../../redux/actions/userAction/betSlipAction'
import {
  addFavoriteEventList,
  deleteFavoriteEvent,
  loginUser,
} from '../../redux'
import LandingContainer from './LandingContainer'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const lang = state.language.lang
  const isLogin = state.login.isLogin
  const appThemeConfiguration = state.appThemeConfiguration
  const hightLightMatch = state.hightLightMatch
  const ongoingMatches = state.inPlayMatches.ongoingMatches
  const upComingMatches = state.inPlayMatches.upComingMatches
  const currentBet = state.betSlip.currentBet
  const fastestOdds = state.fastestOdds.fastestOdds || []
  const commonGame = state.gameManagementCommon.gamesCommonData
  const currentDomain = state.currentDomain
  const eventFavoriteList = state.login.eventFavoriteList
  const liveCasinoData = state.adminGames.liveCasinoData
  const virtualCasinoData = state.adminGames.virtualCasinoData
  const marketLoader = state.appConfiguration.marketLoader

  return {
    isDarkMode,
    marketLoader,
    currentDomain,
    appThemeConfiguration,
    eventFavoriteList,
    liveCasinoData,
    virtualCasinoData,
    lang,
    isLogin,
    commonGame,
    ongoingMatches,
    upComingMatches,
    hightLightMatch,
    currentBet,
    fastestOdds,
  }
}

const ConnectedLanding: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  {
    onMarketClick,
    loginUser,
    updateOpenLoginScreen,
    addFavoriteEventList,
    deleteFavoriteEvent,
  },
)(LandingContainer as any)

export default ConnectedLanding

import { useEffect, useState } from 'react'
import { ICurrentBet } from '../../../Game/betslip/NewBetSlip'
import { toast } from 'react-toastify'
import { Modal, useMediaQuery } from '@mui/material'
import { SessionBook } from './SessionBook'
import { FaRegStar } from 'react-icons/fa'
import { emptyFunction, get2Decimal, getAssetUrl } from '../../../../utils'
import { ITeam } from '../../../../types/interfaces/exchangeMarketStatus'
import { MARKET_TYPE } from '../../../../types/enums/gameType'
import { BET_TYPE } from '../../../../types/enums/betType'
import { ICurrentDomain } from '../../../../types/interfaces/currentDomain'
import {
  getLiability,
  getProfit,
  isMobileScreensixXSmall,
} from '../../../../utils/internalUtils'
import { Remarks } from './Remarks'
import { useSelector } from 'react-redux'
import { Info } from '@mui/icons-material'
import CustomTooltip from './CustomToolTip'

export interface IStatusType {
  odd: number
  run: number
}

export interface IManualMarketGame {
  eventId: string
  marketId: any
  marketStatus: any
  betSlipStake: number
  type: string
  max: number
  min: number
  status: string
  delaySecond: number
  currentDomain: ICurrentDomain
  isDrawerOpen: boolean
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
  addFavoriteMarketList: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  deleteFavoriteMarket: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  teams: ITeam
  onMarketClick: (market: ICurrentBet) => void
  _id: string
  islogin: boolean
  currentBet: ICurrentBet
  name: string
  hashMapMarket: any
  lay: IStatusType
  back: IStatusType
  marketFavoriteList?: any
}

export const ManualSessionMarket = (props: IManualMarketGame) => {
  const isTabletScreen = useMediaQuery('(max-width:900px)')

  let userData = useSelector((state: any) => state.login.userData)
  const { isBetSlipLoading } = useSelector((state: any) => state.betSlip)
  const [boxNoColor, setBoxNoColor] = useState(false)
  const [boxYesColor, setBoxYesColor] = useState(false)
  const [isModelOpen, setIsModelOpen] = useState(false)
  const [sessionBookArray, setSessionBookArray] = useState(
    props.hashMapMarket[props.marketId.id]
      ? props.hashMapMarket[props.marketId.id][0].sessionBookArray
      : [],
  )

  useEffect(() => {
    let marketHash = props.hashMapMarket[props.marketId.id]

    if (marketHash) {
      setSessionBookArray(marketHash[0].sessionBookArray)
    }
  }, [props.marketId.id, isModelOpen, props.hashMapMarket])

  const onNoClick = () => {
    if (isBetSlipLoading) {
      return toast.error('Your Current Bet is Not Finished Yet!')
    }

    if (!props.islogin) {
      toast.error(_getLocalString('LOC_FIRST_TO_CONTINUE'))
    }

    isTabletScreen && props.setIsDrawerOpen(true)
    props.onMarketClick({
      marketId: props.marketId,
      externalType: props.marketId.externalType,
      marketName: props.name,
      manual: props.marketId.manual,
      teamName: '',
      teamId: '',
      marketType: MARKET_TYPE.SESSION,
      oddType: BET_TYPE.LAY,
      odd: props.lay.odd,
      run: props.lay.run,
      delaySecond: (userData.betDelay || 0) + props.delaySecond || 0,
      min: props.min,
      max: props.max,
    })
  }

  const getCalculatedBetDetailForSession = () => {
    let marketDetail = props.hashMapMarket[props.marketId.id]
    let betAmount = get2Decimal(
      marketDetail && marketDetail.length ? marketDetail[0].minLoss : 0,
    )

    if (!betAmount) {
      betAmount = 0
    }

    let currentBet = props.currentBet
    let stake = props.betSlipStake
    let liability = getLiability(currentBet, stake)
    let profit = getProfit(currentBet, stake)

    if (
      currentBet &&
      currentBet.marketId &&
      currentBet.marketId.id === props.marketId.id &&
      stake
    ) {
      if (currentBet.oddType === BET_TYPE.BACK) {
        betAmount += profit
      } else {
        betAmount -= liability
      }
    } else {
      betAmount = undefined
    }
    return betAmount
  }

  const onYesClick = () => {
    if (isBetSlipLoading) {
      return toast.error('Your Current Bet is Not Finished Yet!')
    }

    if (!props.islogin) {
      toast.error(_getLocalString('LOC_FIRST_TO_CONTINUE'))
    }

    isTabletScreen && props.setIsDrawerOpen(true)
    props.onMarketClick({
      marketId: props.marketId,
      externalType: props.marketId.externalType,
      manual: props.marketId.manual,
      teamName: '',
      teamId: '',
      marketName: props.name,
      marketType: MARKET_TYPE.SESSION,
      oddType: BET_TYPE.BACK,
      odd: props.back.odd,
      run: props.back.run,
      delaySecond: (userData.betDelay || 0) + props.delaySecond || 0,
      min: props.min,
      max: props.max,
    })
  }

  useEffect(() => {
    setBoxNoColor(true)
    setTimeout(() => {
      setBoxNoColor(false)
    }, 500)
  }, [props.lay.odd, props.lay.run])

  useEffect(() => {
    setBoxYesColor(true)
    setTimeout(() => {
      setBoxYesColor(false)
    }, 500)
  }, [props.back.odd, props.back.run])

  const handleFavoriteClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    var urlencoded = new URLSearchParams()

    if (!props.marketFavoriteList) {
      urlencoded.append('marketId', props.marketId._id)
      return props.addFavoriteMarketList(
        props.currentDomain,
        urlencoded,
        emptyFunction,
      )
    }

    if (props.marketFavoriteList.includes(props.marketId._id)) {
      urlencoded.append('marketId', props.marketId._id)
      props.deleteFavoriteMarket(props.currentDomain, urlencoded, emptyFunction)
    } else {
      urlencoded.append('marketId', props.marketId._id)
      props.addFavoriteMarketList(
        props.currentDomain,
        urlencoded,
        emptyFunction,
      )
    }
  }

  const isScreenLargerThan1700 = useMediaQuery('(min-width:1700px)')
  const isScreenSmallerThan313 = useMediaQuery('(max-width:313px)')
  const isScreenSmallerThan333 = useMediaQuery('(max-width:333px)')
  const isScreenSmallerThan344 = useMediaQuery('(max-width:344px)')
  const isScreenSmallerThan362 = useMediaQuery('(max-width:362px)')
  const isScreenSmallerThan384 = useMediaQuery('(max-width:384px)')
  const isScreenSmallerThan391 = useMediaQuery('(max-width:391px)')
  const isScreenSmallerThan415 = useMediaQuery('(max-width:415px)')
  const isScreenSmallerThan432 = useMediaQuery('(max-width:432px)')
  const isScreenSmallerThan450 = useMediaQuery('(max-width:450px)')
  const isScreenSmallerThan500 = useMediaQuery('(max-width:500px)')
  const isScreenSmallerThan550 = useMediaQuery('(max-width:550px)')

  const isSmallName = useMediaQuery('(max-width:600px)')
  return (
    <>
      <div
        key={props._id}
        className='flex dark:text-white border border-gray-100 rounded-none shadow-md dark:border-0 text-black dark:bg-[linear-gradient(173.49deg,var(--dropdown-second-color-light),var(--dropdown-first-color-light))] w-full mb-[2px] sm:mb-[3px] '
      >
        <Modal
          open={isModelOpen}
          onClose={() => setIsModelOpen(false)}
          className='flex items-center justify-center overflow-scroll'
        >
          <SessionBook sessionBookArray={sessionBookArray} />
        </Modal>
        {/* ----------------------Div 1---------------------- */}

        <div className='flex  gap-2 items-center xs:w-[85%] sm:w-[55%] w-[48%] bold'>
          <div
            className='pl-2 flex cursor-pointer md:block hover-div'
            onClick={handleFavoriteClick}
          >
            <div className='md:mr-3 items-center order-2 dark:text-white text-black px-2 overflow-hidden !text-sm font-bold leading-5 md:text-base md:order-1 md:px-0 md:border-0 md:font-medium sm:max-w-full max-w-[130px] sm:whitespace-nowrap'>
              {!!props.marketFavoriteList &&
              props.marketFavoriteList.includes(props.marketId._id) ? (
                <img
                  src={getAssetUrl('header/favourite.webp')}
                  style={{
                    filter: 'drop-shadow(0px 0px 0.5px black)',
                    width: '1rem',
                    height: '1rem',
                  }}
                  alt='Favorite'
                />
              ) : (
                <FaRegStar style={{ width: '1rem', height: '1rem' }} />
              )}
            </div>
          </div>

          <div
            className='items-center flex cursor-pointer dark:text-white text-black xs:text-[12px] sm:text-[15px]  overflow-hidden'
            style={{ textOverflow: 'ellipsis', maxWidth: '85%' }}
            onClick={() =>
              props.hashMapMarket[props.marketId.id] && setIsModelOpen(true)
            }
          >
            {props.name.length > 35 && isSmallName
              ? props.name.slice(0, 35) + '...'
              : props.name}
            {props.hashMapMarket[props.marketId.id] &&
              props.hashMapMarket[props.marketId.id][0].minLoss && (
                <div className='relative '>
                  <button className='ml-5 px-1 h-[16px] rounded-[3px] border border-gray-300 bg-white text-xs font-bold'>
                    <span
                      className='bold'
                      style={{ paddingLeft: '7px', color: '#ed6868' }}
                    >
                      {get2Decimal(
                        props.hashMapMarket[props.marketId.id][0].minLoss,
                      )}
                    </span>
                  </button>
                </div>
              )}
          </div>
          {props.currentBet &&
            props.currentBet.marketId &&
            props.currentBet.marketId.id === props.marketId.id && (
              <div className='ml-auto'>
                <div
                  style={{
                    textAlign: 'right',
                    width: '70%',
                    color: `${getCalculatedBetDetailForSession()! < 0 ? 'red' : 'green'}`,
                    fontSize: '0.8rem',
                  }}
                >
                  {getCalculatedBetDetailForSession()
                    ? get2Decimal(getCalculatedBetDetailForSession())
                    : ''}
                </div>
              </div>
            )}
        </div>

        {/* --------------------Div 2----------------------- */}
        <div className='relative flex justify-center items-center xs:w-[58%] sm:w-[40.5%]'>
          <div style={{ left: 0 }} className='absolute sm:hidden'>
            <CustomTooltip title={`Min: ${props.min} | Max: ${props.max}`}>
              <Info
                style={{ fontSize: '1rem' }}
                className='dark:text-white text-black'
              />
            </CustomTooltip>
          </div>
          {props.status !== 'active' || !props.marketId.betAllow ? (
            <div
              className={`${
                isScreenLargerThan1700
                  ? 'ml-[14px]'
                  : isScreenSmallerThan313
                    ? 'ml-[26px]'
                    : isScreenSmallerThan333
                      ? 'ml-[28px]'
                      : isScreenSmallerThan344
                        ? 'ml-[30px]'
                        : isScreenSmallerThan362
                          ? 'ml-[31px]'
                          : isScreenSmallerThan384
                            ? 'ml-[33px]'
                            : isScreenSmallerThan391
                              ? 'ml-[34px]'
                              : isScreenSmallerThan415
                                ? 'ml-[35px]'
                                : isScreenSmallerThan432
                                  ? 'ml-[38px]'
                                  : isScreenSmallerThan450
                                    ? 'ml-[40px]'
                                    : isScreenSmallerThan500
                                      ? 'ml-[42px]'
                                      : isScreenSmallerThan550
                                        ? 'ml-[45px]'
                                        : 'xs:ml-[50px] sm:ml-[12px] md:ml-[10px] lg:ml-[11px] xl:ml-[11px] xl:ml-[15px]'
              }  relative w-full sm:w-[43.5%]`}
            >
              <div className='flex brightness-50  '>
                <div
                  style={{
                    background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}`,
                    color: 'black',
                  }}
                  className={`text-transparent cursor-pointer sm:w-[45%] xs:w-[80%]  border-white text-center text-black  rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  <div className='text-sm' style={{ fontWeight: '900' }}>
                    {props.lay.run}
                  </div>
                  <div className='text-xs md:text-[10px]'>{props.lay.odd}</div>
                </div>

                <div
                  style={{
                    background: `${boxYesColor ? '#FFC107' : '#72BBEF'}`,
                    color: 'black',
                  }}
                  className={`text-transparent cursor-pointer sm:w-[45%] xs:w-[80%] ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  <div className='text-sm' style={{ fontWeight: '900' }}>
                    {props.lay.run}
                  </div>
                  <div className='text-xs md:text-[10px]'>{props.lay.odd}</div>
                </div>
              </div>
              <p
                style={{ fontSize: isMobileScreensixXSmall() ? '8px' : '' }}
                className='absolute z-30 top-0 left-0 right-0 md:right-2 bottom-0 flex items-center justify-center xs:!text-[8x] md:!text-[12px] sm:!text-[10px] font-extrabold text-[#ff9a9a]'
              >
                {!props.marketId.betAllow ? 'SUSPENDED' : 'BALLRUNNING'}
              </p>
            </div>
          ) : boxNoColor ? (
            <div
              onClick={onNoClick}
              style={{ background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}` }}
              className={`cursor-pointer ${
                isScreenSmallerThan313
                  ? 'ml-[26px]'
                  : isScreenSmallerThan333
                    ? 'ml-[28px]'
                    : isScreenSmallerThan344
                      ? 'ml-[30px]'
                      : isScreenSmallerThan362
                        ? 'ml-[31px]'
                        : isScreenSmallerThan384
                          ? 'ml-[33px]'
                          : isScreenSmallerThan391
                            ? 'ml-[34px]'
                            : isScreenSmallerThan415
                              ? 'ml-[35px]'
                              : isScreenSmallerThan432
                                ? 'ml-[38px]'
                                : isScreenSmallerThan450
                                  ? 'ml-[40px]'
                                  : isScreenSmallerThan500
                                    ? 'ml-[42px]'
                                    : isScreenSmallerThan550
                                      ? 'ml-[45px]'
                                      : 'xs:ml-[50px] sm:ml-[2px] md:ml-0 lg:ml-[1px] xl:ml-0'
              } sm:w-[20%] xs:w-[38%] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div className='text-sm' style={{ fontWeight: '900' }}>
                {props.lay.run}
              </div>
              <div className='text-xs md:text-[10px]'>{props.lay.odd}</div>
            </div>
          ) : (
            <div
              onClick={onNoClick}
              style={{ background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}` }}
              className={`cursor-pointer ${
                isScreenSmallerThan313
                  ? 'ml-[26px]'
                  : isScreenSmallerThan333
                    ? 'ml-[28px]'
                    : isScreenSmallerThan344
                      ? 'ml-[30px]'
                      : isScreenSmallerThan362
                        ? 'ml-[31px]'
                        : isScreenSmallerThan384
                          ? 'ml-[33px]'
                          : isScreenSmallerThan391
                            ? 'ml-[34px]'
                            : isScreenSmallerThan415
                              ? 'ml-[36px]'
                              : isScreenSmallerThan432
                                ? 'ml-[38px]'
                                : isScreenSmallerThan450
                                  ? 'ml-[40px]'
                                  : isScreenSmallerThan500
                                    ? 'ml-[42px]'
                                    : isScreenSmallerThan550
                                      ? 'ml-[45px]'
                                      : 'xs:ml-[50px] sm:ml-[2px] md:ml-0 lg:ml-[1px] xl:ml-0'
              } sm:w-[20%] xs:w-[38%] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div className='text-sm' style={{ fontWeight: '900' }}>
                {props.lay.run}
              </div>
              <div className='text-xs md:text-[10px]'>{props.lay.odd}</div>
            </div>
          )}

          {props.status !== 'active' || !props.marketId.betAllow ? (
            <> </>
          ) : boxYesColor ? (
            <div
              onClick={onYesClick}
              style={{ background: `${boxYesColor ? '#FFC107' : '#72BBEF'}` }}
              className={`cursor-pointer sm:w-[20%]  xs:w-[40%]  ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div className='text-sm md:text-xs' style={{ fontWeight: '900' }}>
                {props.back.run}
              </div>
              <div className='text-sm md:text-xs'>{props.back.odd}</div>
            </div>
          ) : (
            <div
              onClick={onYesClick}
              style={{ background: `${boxYesColor ? '#FFC107' : '#72BBEF'}` }}
              className={`cursor-pointer sm:w-[20%]  xs:w-[38%] ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div className='text-sm ' style={{ fontWeight: '900' }}>
                {props.back.run}
              </div>
              <div className='text-xs md:text-[10px]'>{props.back.odd}</div>
            </div>
          )}
        </div>

        {/* ---------------------------Div 3---------------------- */}
        <div className='mt-1 sm:block hidden sm:w-[13.5%]'>
          <div>
            <div className='xs:text-[8px] sm:!text-xs font-normal  dark:text-white text-black'>
              Min Bet: {props.min}
            </div>
            <div className='xs:text-[8px] sm:!text-xs font-normal  dark:text-white text-black'>
              Max Bet: {props.max}
            </div>
          </div>
        </div>
      </div>

      <Remarks remarksMessage={props.marketId.remarksMessage} />
    </>
  )
}

import { combineReducers } from 'redux'
import themeToggleReducer from './themeToggleReducer'
import langReducer from './languageReducer'
import loginReducer from './loginReducer'
import appConfigurationBaseLayoutReducer from './appConfigurationBaseLayoutReducer'
import appThemeConfigurationReducer from './appThemeConfigurationReducer/appThemeConfigurationReducer'
import inPlayMatchesReducer from './matchesReducer/inPlayMatchesReducer'
import createUserReducer from './createUserReducer'
import transactionReducer from './transactionReducer'
import adminGameReducer from './adminGame'
import bankReducer from './bankReducer'
import branchReducer from './branchReducer'
import requestReducer from './requestReducer'
import domainUpdateReducer from './domainUpdateReducer'
import GameManagementThirdPartyReducer from './GameManagementThirdPartyReducer'
import sessionControllerReducer from './SessionControllerReducer'
import GameManagementCommonReducer from './GameManagementCommonReducer'
import NotificationReducer from './NotificationReducer'
import marketStatusReducer from './marketStatusReducer'
import {
  userStakeReducer,
  QuickBetReducer,
  BetSlipReducer,
  HighLightReducer,
} from './userReducer'
import { UserBetSlipReducer } from './userReducer/UserBetSlipReducer'
import IsLoadingReducer from './IsLoadingReducer'
import { FastestOddReducer } from './userReducer/FastestOddReducer'
import newsLetterReducer from './nonUserReducer/newsLetterReducer'
import reportsReducer from './reportsReducer/reportsReducer'
import { RoyalGamingReducer } from './gamingReducer/royalGamingReducer'
import demoLoginReducer from './demoLoginReducer/demoLoginReducer'
import { ScoreReducer } from './userReducer/ScoreReducer'
import dashboardReducer from './dashboardReducer'
import appCommonThemeReducers from './appCommonThemeReducers'

export default combineReducers({
  appConfiguration: appConfigurationBaseLayoutReducer,
  appCommonTheme: appCommonThemeReducers,
  appThemeConfiguration: appThemeConfigurationReducer,
  adminDashboard: dashboardReducer,
  adminGames: adminGameReducer,
  bank: bankReducer,
  betSlip: BetSlipReducer,
  branch: branchReducer,
  currentDomain: domainUpdateReducer,
  createUser: createUserReducer,
  demoLogin: demoLoginReducer,
  fastestOdds: FastestOddReducer,
  gameManagementThirdParty: GameManagementThirdPartyReducer,
  gameManagementCommon: GameManagementCommonReducer,
  hightLightMatch: HighLightReducer,
  inPlayMatches: inPlayMatchesReducer,
  isLoading: IsLoadingReducer,
  language: langReducer,
  login: loginReducer,
  marketStatus: marketStatusReducer,
  newsLetter: newsLetterReducer,
  notification: NotificationReducer,
  reports: reportsReducer,
  request: requestReducer,
  royalGame: RoyalGamingReducer,
  sessionController: sessionControllerReducer,
  score: ScoreReducer,
  theme: themeToggleReducer,
  transaction: transactionReducer,
  userStake: userStakeReducer,
  userQuickBet: QuickBetReducer,
  userBet: UserBetSlipReducer,
})

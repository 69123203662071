import { IThemeToggle } from '../../types/interfaces/themeConfiguration'
import { THEME_TOGGLE } from '../actions'
import { APP_CONFIGURATION_DARK_MODE } from '../type'

let darkTheme = localStorage.getItem(APP_CONFIGURATION_DARK_MODE)
const initialState: ITheme = {
  isDarkMode: darkTheme !== 'light',
}

export type ITheme = {
  isDarkMode: boolean
}

export default function (state = initialState, action: IThemeToggle) {
  switch (action.type) {
    case THEME_TOGGLE: {
      return {
        ...state,
        isDarkMode: !!action.isDarkMode,
      }
    }
    default:
      return state
  }
}

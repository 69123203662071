import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import store from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import { initializeStrings } from './utils/strings'
import { MainApp } from './MainApp'
import DevToolsDetector from './DevToolsDetector'

initializeStrings().then(() => {
  if (
    window.location.protocol === 'http:' &&
    window.location.hostname !== 'localhost'
  ) {
    window.location.replace(
      `https://${window.location.hostname}${window.location.pathname}${window.location.search}`,
    )
  }

  const rootElement = document.getElementById('root') as HTMLElement
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <MainApp />
        {window.location.hostname !== 'localhost' && <DevToolsDetector />}
      </BrowserRouter>
    </Provider>,
  )
})

import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import {
  HEADERS,
  makeDeleteHttpCallAsync,
  makeGetHttpCallAsync,
  makePatchHttpCallAsync,
  makePostHttpCallAsync,
} from '../../api'
import { toast } from 'react-toastify'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { getCorrectUserType } from '../../utils'

export const GET_NOTIFICATIONS = '@NOTIFICATION/GET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = '@NOTIFICATION/DELETE_NOTIFICATION'
export const READ_NOTIFICATION = '@NOTIFICATION/READ_NOTIFICATION'
export const SEND_NOTIFICATION = '@NOTIFICATION/SEND_NOTIFICATION'

export const getNotifications = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch) => {
    const dispatchType = GET_NOTIFICATIONS
    try {
      const userType = await localStorage.getItem(HEADERS.userType)
      dispatch({ type: dispatchType, loading: true, data: [] })

      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/notification`,
        '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: dispatchType,
            loading: false,
            data: response.docs,
          })
        })
        .catch(async (error: any) => {
          dispatch({ type: dispatchType, loading: false })
          let err = await error
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

function deleteObject(array: any, targetId: any) {
  return array.filter((obj: any) => obj._id !== targetId)
}

export const deleteNotification = (
  id: string,
  currentDomain: ICurrentDomain,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const dispatchType = DELETE_NOTIFICATION
    try {
      const userType = await localStorage.getItem(HEADERS.userType)
      dispatch({ type: dispatchType, loading: true, data: [] })
      const queryParam = new URLSearchParams()
      queryParam.append('notificationId', id)

      makeDeleteHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/notification`,
        `notificationId=${id}`,
        currentDomain.type,
        false,
        true,
        '',
        true,
        'Deleted Successfull',
      )
        .then(() => {
          dispatch({
            type: dispatchType,
            loading: false,
          })

          let allNotification = getState().notification.notifications
          dispatch({
            type: GET_NOTIFICATIONS,
            loading: false,
            data: deleteObject(allNotification, id),
          })
        })
        .catch(async (error: any) => {
          dispatch({ type: dispatchType, loading: false })
          let err = await error
          toast.error(err?.message || err)
        })
    } catch (error: any) {
      let err = await error
      toast.error(err?.message || err)
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

export const sendNotification = (
  payload: any,
  CB: () => void,
  currentDomain: ICurrentDomain,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const dispatchType = SEND_NOTIFICATION
    const userType = await localStorage.getItem(HEADERS.userType)
    try {
      dispatch({ type: dispatchType, loading: true })
      makePostHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/user/notification`,
        currentDomain.type,
        JSON.stringify(payload),
        false,
        true,
        '',
        true,
        'Send Successfull',
      )
        .then((response: any) => {
          dispatch({ type: dispatchType, loading: false })
          CB && CB()
        })
        .catch(async (error: any) => {
          let err = await error
          dispatch({ type: dispatchType, loading: false })
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

function updateIsRead(array: any, targetId: any) {
  return array.map((obj: any) => {
    if (obj._id === targetId) {
      return { ...obj, read: true }
    }
    return obj
  })
}

export const readNotification = (id: string, currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const dispatchType = READ_NOTIFICATION
    try {
      const userType = await localStorage.getItem(HEADERS.userType)
      dispatch({ type: dispatchType, loading: true, data: [] })
      makePatchHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/notification`,
        '',
        `notificationId=${id}`,
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: dispatchType,
            loading: false,
            data: response.docs,
          })
          let allNotification = getState().notification.notifications
          let tmpNotification = updateIsRead(allNotification, id)
          dispatch({
            type: GET_NOTIFICATIONS,
            loading: false,
            data: tmpNotification,
          })
        })
        .catch(async (error: any) => {
          dispatch({ type: dispatchType, loading: false })
          let err = await error
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { DOMAIN_UPDATE } from '../actions/domainUpdateAction'

const initialState: ICurrentDomain = {
  domainName: '',
  type: '',
  whitelabelId: '',
}

export default function (state = initialState, action: any) {
  switch (action.type) {
    case DOMAIN_UPDATE: {
      return {
        ...state,
        ...action.data,
      }
    }

    default:
      return state
  }
}

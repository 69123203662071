import { Drawer, useMediaQuery } from '@mui/material'
import { IUserData } from '../../../../../types/interfaces/login'
import { useSelector } from 'react-redux'
import { IoClose, IoDocumentTextOutline } from 'react-icons/io5'
import { BsLightningFill } from 'react-icons/bs'
import ConnectedBetSlipOn from '../../../../Game/betslip/ConnectedBetSlipOn'
import { MdOutlineExpandMore } from 'react-icons/md'

export const DrawerComp = (props: {
  userData: IUserData
  toggleDrawer: any
  isDrawerOpen: any
}) => {
  const isSmallScreen = useMediaQuery('(max-width:900px)')
  const { quickBet, quickBetValue } = useSelector(
    (state: any) => state.login.quickBet,
  )
  const tabletMode = useMediaQuery('(min-width:500px) and (max-width:900px)')
  const showNavBar = useMediaQuery('(max-width:500px)')
  return (
    <>
      {isSmallScreen && props.userData.userType === 'user' && (
        <div
          style={{
            position: 'fixed',
            bottom: '75px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '99999',
          }}
        >
          <button
            className={`${showNavBar && '!hidden'}`}
            style={{
              position: 'relative',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              backgroundColor: !quickBet ? '#f57c3b' : 'purple',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={props.toggleDrawer}
          >
            {props.isDrawerOpen ? (
              <IoClose style={{ fontSize: '30px', color: '#ffffff' }} />
            ) : !quickBet ? (
              <IoDocumentTextOutline
                style={{ fontSize: '30px', color: '#ffffff' }}
              />
            ) : (
              <div style={{ position: 'relative' }}>
                <BsLightningFill
                  style={{ fontSize: '30px', color: '#ffffff' }}
                />
                <div
                  className='px-2 font-bold'
                  style={{
                    position: 'absolute',
                    top: '-18px',
                    right: '-35px',
                    backgroundColor: 'white',
                    borderRadius: '30%',
                    minWidth: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    color: 'black',
                  }}
                >
                  {quickBetValue}
                </div>
              </div>
            )}
          </button>
        </div>
      )}

      <Drawer
        anchor='bottom'
        open={props.isDrawerOpen}
        onClose={props.toggleDrawer}
      >
        <div
          role='presentation'
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          style={{ zIndex: '999999' }}
        >
          <ConnectedBetSlipOn />
          <button
            style={{
              borderRadius: '50%',
              width: '25px',
              height: '25px',
              backgroundColor: '#f57c3b',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              cursor: 'pointer',
              position: 'absolute',
              bottom: tabletMode ? '262px' : '250px',
              right: '6px',
            }}
            onClick={props.toggleDrawer}
          >
            <MdOutlineExpandMore
              style={{ fontSize: '30px', color: '#ffffff' }}
            />
          </button>
        </div>
      </Drawer>
    </>
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ConnectedHeader from '../../pages/landing/component/ConnectedHeader'
import { Footer } from '../../pages/landing/component/footer'
import { PaymentMethod } from '../../pages/landing/component/paymentMethod'
import { NavTiles } from '../navTiles/NavTiles'
import { HeaderBottom } from '../../pages/landing/component/HeaderBottom'
import MainBanner from '../banner/MainBanner'
import BannerSlider from '../banner/BannerSlider'
import { NavTiles2 } from '../navTiles/NavTiles2'
import { NavTiles3 } from '../navTiles/NavTiles3'
import Marquee from 'react-fast-marquee'
import { ICurrentBet } from '../../pages/Game/betslip/NewBetSlip'
import { IAppConfiguration } from '../../types/interfaces/appConfiguration'
import { IUserData } from '../../types/interfaces/login'
import { ISlideShow } from '../../types/interfaces/themeConfiguration'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { SiteLoader } from '../../Fallback'
import { Skeleton, useMediaQuery } from '@mui/material'
import { isMobileScreen } from '../../utils/internalUtils'

import { getAssetUrl, getRandomNumber } from '../../utils'

import { isActualFuture9 } from '../../App'
import MobileNavBar from '../mobileBottomBar'

interface LayoutProps {
  liveCasinoData: any[]
  virtualCasinoData: any[]
  lotteryGamesData: any[]
  isFullScreenWithHeader: boolean
  isGameFullScreen: boolean
  sportsData: any[]
  slotGamesData: any[]
  crashGamesData: any[]
  hightLightMatch: any[]
  isDarkMode?: boolean
  currentDomain: ICurrentDomain
  searchLoading: boolean
  searchContentMarkets: any[]
  isFullScreen: boolean
  isLogin?: boolean
  userType?: boolean
  appConfiguration?: IAppConfiguration
  isBaselayout?: boolean
  userData?: IUserData
  isBaseLayoutVisible: boolean
  appThemeConfiguration: any
  children?: any
  updateOpenLoginScreen?: (openLoginScreen: boolean) => void
  onMarketClick: (market: ICurrentBet) => void
}

const BaseLayout: React.FC<LayoutProps> = (props) => {
  const router = useLocation()
  const [reloadKey, setReloadKey] = useState(0)

  const landingPages = useMemo(() => {
    return router.pathname === '/'
  }, [router])

  const pathName = router.pathname
  let bannerDetailsLocal: ISlideShow[] = useSelector(
    (state: any) => state.appCommonTheme.slideShow,
  )
  const { headerBottom, slideShow } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )
  const showNavBar = useMediaQuery('(max-width:500px)')
  const [bannerDetails, setBannerDetails] = useState<any[]>(
    !!slideShow && !!slideShow.length && !!slideShow[0].image
      ? slideShow
      : bannerDetailsLocal,
  )

  useEffect(() => {
    setBannerDetails(
      !!slideShow && !!slideShow.length && !!slideShow[0].image
        ? slideShow
        : bannerDetailsLocal,
    )
  }, [bannerDetailsLocal, slideShow])

  const userType = useSelector((state: any) => state.login.userData.userType)

  useEffect(() => {
    setReloadKey((prevKey) => prevKey + 1)
  }, [props.isFullScreenWithHeader])

  return (
    <div key={reloadKey} className='relative'>
      {!props.isBaselayout ? (
        <Outlet />
      ) : (
        <>
          {!props.isFullScreenWithHeader && !props.isFullScreen && (
            <ConnectedHeader isLogin={props.isLogin} />
          )}

          <div className='scrollbar_show'>
            <div className='relative overflow-x-hidden '>
              <div
                className={
                  !props.isFullScreen && !props.isFullScreenWithHeader
                    ? `lg:mt-[4rem] xl:mt-[4rem]`
                    : ''
                }
              >
                {!props.isFullScreen && !props.isFullScreenWithHeader && (
                  <>
                    {!props.isLogin && (
                      <>
                        <div className='hidden lg:flex'>
                          <HeaderBottom />
                        </div>
                        <div className='lg:h-[59px] xl:h-[59px] h-0'></div>
                      </>
                    )}

                    {userType === 'user' && (
                      <>
                        <div className='hidden lg:flex'>
                          <HeaderBottom />
                        </div>
                        <div className='lg:h-[59px] xl:h-[59px] h-0'></div>
                      </>
                    )}

                    {props.appConfiguration?.siteLoader ? (
                      <>
                        <Skeleton
                          variant='rectangular'
                          width={'100%'}
                          height={30}
                          className='mb-2'
                        />
                        {!isMobileScreen() && (
                          <Skeleton
                            variant='rectangular'
                            width={'100%'}
                            height={300}
                          />
                        )}
                      </>
                    ) : landingPages && !props.isLogin ? (
                      <BannerSlider hightLightMatch={props.hightLightMatch}>
                        {bannerDetails &&
                          bannerDetails.map((item, index) => (
                            <MainBanner
                              isCommon={!(!!slideShow && !!slideShow.length)}
                              banner={item}
                              key={index}
                            />
                          ))}
                      </BannerSlider>
                    ) : null}

                    {!props.isLogin && (
                      <div className='md:hidden bg-[var(--header-bottom-marquee-bg)]  text-center h-[20px] flex lg:hidden xs:hidden sm:hidden'>
                        <div className='text-[color:--header-bottom-marquee-text] '>
                          <Marquee className='!text-[13px] font-medium leading-[87px] tracking-[0.03em] text-left text-[var(--header-bottom-marquee-text)]'>
                            {headerBottom?.marqueeText}
                          </Marquee>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div
                  className={
                    !props.isFullScreen
                      ? `md:grid mt-2 px-0 gap-6  ${props.isLogin
                        ? 'grid-cols-12 xs:pt-0 sm:pt-2'
                        : 'grid-cols-12'
                      }`
                      : ''
                  }
                >
                  {!props.isFullScreen &&
                    !props.searchLoading &&
                    !!props.isBaseLayoutVisible && (
                      <div
                        className={`${props.isLogin ? '' : ''
                          } hidden col-span-2 pt-2 pl-2 lg:block`}
                      >
                        {pathName.includes('help') ? (
                          <NavTiles3
                            currentDomain={props.currentDomain}
                            userLogInLoader={false}
                            isDarkMode={props.isDarkMode}
                            pathName={pathName}
                            userData={props.userData}
                            onClickHandlerClose={function (): void { }}
                          />
                        ) : !props.isLogin ||
                          props.userData?.userType === 'user' ? (
                          <NavTiles
                            liveCasinoData={props.liveCasinoData}
                            virtualCasinoData={props.virtualCasinoData}
                            lotteryGamesData={props.lotteryGamesData}
                            sportsData={props.sportsData}
                            slotGamesData={props.slotGamesData}
                            crashGamesData={props.crashGamesData}
                            currentDomain={props.currentDomain}
                            isLogin={props.isLogin}
                            updateOpenLoginScreen={props.updateOpenLoginScreen}
                            userLogInLoader={false}
                            isDarkMode={props.isDarkMode}
                            pathName={pathName}
                            onClickHandlerClose={function (): void { }}
                          />
                        ) : (
                          <NavTiles2
                            currentDomain={props.currentDomain}
                            userLogInLoader={false}
                            isDarkMode={props.isDarkMode}
                            pathName={pathName}
                            userData={props.userData}
                            onClickHandlerClose={function (): void { }}
                          />
                        )}
                      </div>
                    )}
                  {props.searchLoading ? (
                    <div className='flex justify-center items-center h-20% mt-20 ml-50'>
                      <SiteLoader />
                    </div>
                  ) : (
                    props.children
                  )}
                  {props.isBaselayout && <Outlet />}
                </div>
              </div>
            </div>

            {!props.isFullScreen &&
              !props.isGameFullScreen &&
              !(!!props.isLogin && props.userData?.userType !== 'user') && (
                <>
                  {isActualFuture9() &&
                    props.currentDomain.type === 'b2c' &&
                    window.location.pathname === '/' && (
                      <div
                        className='pt-5 text-black dark:text-white '
                        style={{ placeContent: 'center', padding: '1rem' }}
                      >
                        <h1 style={{ lineHeight: 'normal' }}>
                          {' '}
                          The Ultimate Online Sports Betting Experience in India{' '}
                        </h1>
                        <p>
                          {' '}
                          Discover the Best{' '}
                          <a
                            href={`${window.location.origin}/live-casino`}
                            className='dark:dark:text-[#fda9b0] text-[red] text-[red] ml-2'
                          >
                            {' '}
                            Live Casino India{' '}
                          </a>{' '}
                          with Future9! Transform your sports knowledge into
                          genuine earnings by betting with confidence on our
                          platform. Enjoy substantial rewards while immersing
                          yourself in a live casino experience from the comfort
                          of your home. As one of India's leading{' '}
                          <a
                            href={`${window.location.origin}/crash-games`}
                            className='dark:text-[#fda9b0] text-[red] ml-2'
                          >
                            {' '}
                            Crash game Online{' '}
                          </a>{' '}
                          and{' '}
                          <a
                            href={`${window.location.origin}/lottery`}
                            className='dark:text-[#fda9b0] text-[red] ml-2'
                          >
                            {' '}
                            Lottery Tickets Online{' '}
                          </a>
                          platforms, Future9 is easily accessible on both
                          desktop and mobile devices. Our globally trusted
                          platform ensures a rapid, secure, and safe betting
                          environment. With a wide variety of gaming options,
                          numerous betting types, competitive odds, and enticing
                          bonuses and promotions, we deliver exceptional value
                          for every player. Our cutting-edge technology
                          guarantees smooth transactions, making it simple for
                          you to maximize your betting ventures on{' '}
                          <a
                            href={`${window.location.origin}/virtual-games`}
                            className='dark:text-[#fda9b0] text-[red] ml-2'
                          >
                            {' '}
                            Best Virtual games{' '}
                          </a>{' '}
                          and{' '}
                          <a
                            href={`${window.location.origin}/slot-games`}
                            className='dark:text-[#fda9b0] text-[red] ml-2'
                          >
                            {' '}
                            Best Slot games to Win Money{' '}
                          </a>
                        </p>
                        <div className='bold md:text-base md:leading-normal leading-tight m-5 bold md:text-base md:leading-normal leading-tight '>
                          <h2>Experience the Best Betting Exchange Live</h2>
                        </div>
                        <p className='xs:text-[18px] leading-tight md:text-[20px] m-5 leading-tight '>
                          Discover the potential of online sports betting at{' '}
                          <b>Future9</b>, India’s largest platform that
                          transforms your betting experience. Our comprehensive
                          betting interface is designed to enhance enjoyment
                          while increasing your chances of success. As a premier{' '}
                          <b>Online Betting Exchange</b>, we offer options that
                          cater to all levels of bettors.
                        </p>

                        <div className='bold md:text-base md:leading-normal leading-tight m-5 bold md:text-base md:leading-normal leading-tight '>
                          <h2>24/7 Customer Support</h2>
                        </div>
                        <p className='xs:text-[18px] leading-tight md:text-[20px] m-5 leading-tight '>
                          Recognizing the importance of support in online sports
                          betting, <b>Future9</b> offers{' '}
                          <b>24/7 customer service.</b> Our dedicated team is
                          always available to assist with any inquiries or
                          issues, ensuring a smooth and enjoyable betting
                          experience on our <b>Betting Exchange Live</b>{' '}
                          platform.
                        </p>

                        <div className='text-[22px] m-5 bold xs:text-[15px] md:text-base md:leading-normal leading-tight'>
                          <h2>24/7 Deposits and Withdrawals</h2>
                        </div>

                        <p className='xs:text-[18px] leading-tight md:text-[20px] m-5 leading-tight  '>
                          Enjoy the convenience of Future9’s swift transaction
                          services. Our 24/7 deposits and withdrawals allow for
                          easy fund management. Whether placing bets or
                          withdrawing your winnings, our prompt and secure
                          process is tailored for your convenience on our{' '}
                          <b>Online Betting Exchange.</b>
                        </p>

                        <div className='text-[22px] m-5 bold xs:text-[15px] md:text-base md:leading-normal leading-tight'>
                          <h2>Fastest Odds and Live Scores</h2>
                        </div>

                        <p className='xs:text-[18px] leading-tight md:text-[20px] m-5 leading-tight  '>
                          Stay ahead with <b>Future9's</b> real-time odds and
                          live scores for all ongoing matches. Our platform
                          delivers instant updates, enabling you to make
                          well-informed betting decisions for the best outcomes,
                          especially on our <b>Betting Exchange</b>.
                        </p>

                        <div className='text-[22px] m-5 bold xs:text-[15px] md:text-base md:leading-normal leading-tight'>
                          <h2>Live TV Streaming of All Sports</h2>
                        </div>

                        <p className='xs:text-[18px] leading-tight md:text-[20px] m-5 leading-tight  '>
                          Experience unrivaled live TV streaming of all major
                          sporting events, including IPL, PSL, cricket,
                          football, tennis, and badminton. Our high-definition
                          streaming guarantees you won’t miss a moment of the
                          action, enhancing your betting experience on our
                          <b> Betting Exchange.</b>
                        </p>

                        <div className='text-[22px] m-5 bold xs:text-[15px] md:text-base md:leading-normal leading-tight'>
                          <h2>Bet on International Sports Leagues</h2>
                        </div>

                        <p className='xs:text-[18px] leading-tight md:text-[20px] m-5 leading-tight  '>
                          <b>Future9</b> broadens your horizons with betting
                          options on international sports leagues, opening up a
                          world of global sporting events. Whether your
                          interests lie in football, basketball, or other
                          sports, our extensive range of betting choices makes
                          us a top pick for
                          <b> Online Sports Exchange</b> enthusiasts.
                        </p>

                        <div className='text-[22px] m-5 bold xs:text-[15px] md:text-base md:leading-normal leading-tight'>
                          <h2>India’s Most Trusted Betting Exchange</h2>
                        </div>

                        <p className='xs:text-[18px] leading-tight md:text-[20px] m-5 leading-tight  '>
                          As India’s most dependable{' '}
                          <b>Betting Exchange, Future9</b> is committed to
                          delivering an extraordinary betting experience. By
                          merging advanced technology with excellent customer
                          service, our platform ensures a superior environment
                          for all bettors.
                        </p>
                      </div>
                    )}

                  {props.currentDomain.type !== 'b2b' && <PaymentMethod />}
                  {props.currentDomain.type === 'b2c' && isActualFuture9() && (
                    <div
                      style={{
                        backgroundImage: `url(${getAssetUrl('footer/light_bg.webp')})`,
                      }}
                      className={`bg-[#171717] pt-5 dark:text-white text-center`}
                    >
                      <span>
                        <a
                          aria-label='best-live-casino-link'
                          id='live-casino-link'
                          href='https://future9.club/live-casino'
                        >
                          Live Casino India |{' '}
                        </a>
                      </span>
                      <span>
                        <a
                          aria-label='best-crash-games-link'
                          id='crash-games-link'
                          href='https://future9.club/crash-games'
                        >
                          Crash Game Online |{' '}
                        </a>
                      </span>
                      <span>
                        <a
                          aria-label='best-virtual-link'
                          id='best-virtual-link'
                          href='https://future9.club/virtual-games'
                        >
                          Best Virtual games |{' '}
                        </a>
                      </span>
                      <span>
                        <a
                          aria-label='best-slots-link'
                          id='best-slot-games'
                          href='https://future9.club/slot-games'
                        >
                          Best Slot Games to Win Money |{' '}
                        </a>
                      </span>
                      <span>
                        <a
                          aria-label='best-lottery-link'
                          id='lottery-tickets-online'
                          href='https://future9.club/lottery'
                        >
                          Lottery Tickets Online{' '}
                        </a>
                      </span>
                    </div>
                  )}
                  <Footer />
                </>
              )}
          </div>
        </>
      )}
      
      {showNavBar ? (
        <div
          className={`fixed ${props.currentDomain.domainName === 'otp365.club' ? 'dark-glass-effect' : 'glass-effect'} xs:text-[16px] sm:text-[14px]`}
        >
          <MobileNavBar userData={props.userData} isLogin={props.isLogin} />
        </div>
      ) : props.currentDomain.domainName !== 'toyo247.ai' && !props.isLogin && (
        <div
          className={`fixed ${props.currentDomain.domainName === 'otp365.club' ? 'dark-glass-effect' : 'glass-effect'} xs:text-[16px] sm:text-[14px]`}
        >
          {getRandomNumber(1500, 10000)} Online Users
        </div>
      )}
    </div>
  )
}

export default BaseLayout

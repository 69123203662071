import { Container } from '@mui/material'
import { convertString, getAssetUrl } from '../../../../utils'
import { useSelector } from 'react-redux'
import { IEnable } from '../../../../types/interfaces/themeConfiguration'
import { getEnvAssetUrl } from '../../../../utils/envValues'
import { Casino, Sports } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export interface IAdvertisement {
  updateOpenLoginScreen: any
  isLogin: boolean
}

export const Advertise1 = (props: IAdvertisement) => {
  const navigate = useNavigate()
  const enableFeatures: IEnable = useSelector(
    (state: any) => state.appThemeConfiguration.enable,
  )
  const logoText: string = useSelector(
    (state: any) => state.appThemeConfiguration.logoText,
  )

  const clickOnAdvertisementButton = useCallback(
    (type: string) => {
      if (type === 'playSports') {
        return props.updateOpenLoginScreen(true)
      } else {
        navigate('/live-casino')
      }
    },
    [props.updateOpenLoginScreen],
  )

  const advertisement = useSelector(
    (state: any) => state.appCommonTheme.advertisement,
  )

  return (
    <>
      {enableFeatures?.isDownloadApp && (
        <div
          style={{
            background: `linear-gradient(130deg, var(--advertisement2-backgroundFirst)0%, var(--advertisement2-backgroundSecond)26.28%, var(--advertisement2-backgroundThird)51.69%, var(--advertisement2-backgroundFourth)100%), url(${getEnvAssetUrl()}advertise_cta/football_object.webp)`,
          }}
          className={` !bg-no-repeat !bg-right !bg-cover md:h-[430px] h-[200px]`}
        >
          <Container
            maxWidth={'xl'}
            fixed
            className={` !h-full !bg-no-repeat md:!bg-contain !bg-300 md:!bg-right-bottom bg-right-bottom relative`}
          >
            <div className='absolute md:bottom-0 !bottom-4  right-0  md:h-[469px] h-[160px] md:w-[291px] w-[111px]'>
              <img
                alt='Advertisement Icon'
                src={getAssetUrl('advertise_cta/boys.webp')}
              />
            </div>
            <div
              style={{
                backgroundImage: `url(${getAssetUrl('advertise_cta/arrow.webp')})`,
              }}
              className={` md:!h-[300px] !h-[63px] md:w-[300px] w-[63px] !bg-no-repeat !bg-contain !bg-right absolute md:!right-60  md:left-auto left-[18rem]   z-10 bottom-14`}
            ></div>
            <div className='flex flex-col h-full pt-10 md:justify-center md:pt-0'>
              <div className='md:mb-3 mb-1 text-[25px] relative z-20 font-bold tracking-widest text-white md:text-6xl font-urban '>
                {advertisement?.title}
                <br />
                {logoText}
              </div>
              <div className='flex  gap-1 mt-4 z-10 md:mt-8 md:gap-5 md:flex-row'>
                {[
                  { type: 'playSports', label: 'Play Sports' },
                  { type: 'playCasino', label: 'Play Casino' },
                ].map((item, index) => (
                  <div
                    onClick={() => {
                      clickOnAdvertisementButton(item.type)
                    }}
                    key={index}
                    style={{
                      alignContent: 'center',
                      background: 'black',
                      borderRadius: '1rem',
                    }}
                    className='text-center cursor-pointer md:w-[200px] w-[100px] md:h-[60px] h-[40px]'
                  >
                    <span className='score-blinking bold'>
                      {item.type === 'playCasino' ? (
                        <Casino className='text-[15px]' />
                      ) : (
                        <Sports className='text-[15px]' />
                      )}
                      {index === 0
                        ? advertisement?.firstButton
                        : advertisement?.secondButton}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

import { Dispatch } from '@reduxjs/toolkit'
import { getHomeData, GetReduxStateFn } from '../..'
import {
  URLType,
  makeDeleteHttpCallAsync,
  makeGetHttpCallAsync,
  makePostHttpCallAsync,
} from '../../../api'
import { IsMarketInPlay } from '../../../utils/internalUtils'
import { emptyFunction } from '../../../utils'

export const GET_SCHEDULES_MATCHES = '@MATCHES/GET_SCHEDULES_MATCHES'
export const GET_TOURNAMENT = '@MATCHES/GET_TOURNAMENT'
export const GET_MATCHES_SCORE = '@MATCHES/GET_MATCHES_SCORE'
export const GET_MATCHES_LIVE_TV = '@MATCHES/GET_MATCHES_LIVE_TV'
export const GET_BETFAIR_ENABLED_MATCHES =
  '@MATCHES/GET_BETFAIR_ENABLED_MATCHES'
export const GET_ONGOING_MATCHES = '@MATCHES/GET_ONGOING_MATCHES'

export const getFilteredEventsByUpcomingAndOnGoing = (
  tournament: any,
  ongoing: any,
  upcomming: any,
  gameManagementCommonData: any,
) => {
  let resContent: any = []
  let eventIdHashMap = new Map()
  let upCommingLocal: any = []
  let onGoingLocal: any = []

  upcomming.forEach((item: any) => {
    const events = item.events

    events.forEach((event: any) => {
      const eventId = event.eventId
      eventIdHashMap.set(eventId, event)
    })
  })

  ongoing.forEach((item: any) => {
    const events = item.events

    events.forEach((event: any) => {
      const eventId = event.eventId
      eventIdHashMap.set(eventId, event)
    })
  })

  let marketIdsLocal = ''
  let marketIdsUpcommingLocal = ''
  for (let index = 0; index < gameManagementCommonData.length; index++) {
    resContent = filteredEventsForUpcommingAndOnGoing(
      tournament,
      gameManagementCommonData[index],
      eventIdHashMap,
    )

    if (resContent.marketIds) {
      marketIdsLocal += `${resContent.marketIds}`
    }

    if (resContent.upCommingMarketIds) {
      marketIdsUpcommingLocal += `${resContent.upCommingMarketIds}`
    }

    let sortedUpCommingEventIds = resContent.upCommingEventIds.sort(
      (a: any, b: any) =>
        new Date(a.openDate).getTime() - new Date(b.openDate).getTime(),
    )

    if (gameManagementCommonData[index].id === '4') {
      if (resContent.ongoingEventIds.length < 5) {
        if (resContent.upCommingEventIds.length > 4) {
          resContent.ongoingEventIds = [
            ...resContent.ongoingEventIds,
            ...sortedUpCommingEventIds.slice(0, 4),
          ]
        } else {
          resContent.ongoingEventIds = [
            ...resContent.ongoingEventIds,
            ...sortedUpCommingEventIds,
          ]
        }
      }

      upCommingLocal = [
        {
          eventTypeId: gameManagementCommonData[index].id,
          events: sortedUpCommingEventIds,
        },
        ...upCommingLocal,
      ]

      onGoingLocal = [
        {
          eventTypeId: gameManagementCommonData[index].id,
          events: resContent.ongoingEventIds,
        },
        ...onGoingLocal,
      ]
    } else {
      if (resContent.ongoingEventIds.length < 5) {
        if (resContent.upCommingEventIds.length > 4) {
          resContent.ongoingEventIds = [
            ...resContent.ongoingEventIds,
            ...sortedUpCommingEventIds.slice(0, 4),
          ]
        } else {
          resContent.ongoingEventIds = [
            ...resContent.ongoingEventIds,
            ...sortedUpCommingEventIds,
          ]
        }
      }
      upCommingLocal.push({
        eventTypeId: gameManagementCommonData[index].id,
        events: sortedUpCommingEventIds,
      })

      onGoingLocal.push({
        eventTypeId: gameManagementCommonData[index].id,
        events: resContent.ongoingEventIds,
      })
    }
  }

  return {
    upComming: upCommingLocal,
    ongoing: onGoingLocal,
    marketIdsUpcomming: marketIdsUpcommingLocal,
    marketIds: marketIdsLocal,
  }
}

export const getFilteredEvents = (
  isUpComing: boolean,
  docs: any,
  gameManagementCommonData: any,
) => {
  let res: any[] = []
  let resContent: any = []
  let eventIdHashMap = new Map()

  docs.forEach((item: any) => {
    const events = item.events

    events.forEach((event: any) => {
      const eventId = event.eventId
      eventIdHashMap.set(eventId, event)
    })
  })

  let marketIdsLocal = ''
  let marketIdsUpcommingLocal = ''

  for (let index = 0; index < gameManagementCommonData.length; index++) {
    resContent = filteredEvent(
      isUpComing,
      eventIdHashMap,
      gameManagementCommonData[index],
    )

    if (!isUpComing && resContent.marketIds) {
      marketIdsLocal += `${resContent.marketIds}`
    } else {
      marketIdsUpcommingLocal += `${resContent.marketIds}`
    }

    if (gameManagementCommonData[index].id === '4') {
      res = [
        {
          eventTypeId: gameManagementCommonData[index].id,
          events: resContent.eventIds,
        },
        ...res,
      ]
    } else {
      res.push({
        eventTypeId: gameManagementCommonData[index].id,
        events: resContent.eventIds,
      })
    }
  }

  return {
    res: res,
    marketIdsUpcommingLocal: marketIdsUpcommingLocal,
    marketIds: marketIdsLocal,
  }
}

function findById(array: any, id: string) {
  return array.find((obj: any) => obj.id === id)
}

export const getEventTypeFilteredEvents = (
  eventTypeId: string,
  docs: any,
  gameManagementCommonData: any,
) => {
  let res = []
  let resContent: any = []
  let eventIdHashMap = new Map()

  docs.forEach((item: any) => {
    const eventId = item.marketId.event.id
    eventIdHashMap.set(eventId, item)
  })

  let commonGameForEventTypeId = findById(gameManagementCommonData, eventTypeId)

  resContent = filteredEventType(eventIdHashMap, commonGameForEventTypeId)
  res.push({
    eventTypeId: eventTypeId,
    events: resContent.eventIds,
  })

  return {
    res: res,
    marketIds: resContent.marketIds,
  }
}

export const filteredEventsForUpcommingAndOnGoing = (
  tournament: any,
  gameManagementCommonData: any,
  eventIdHashMap: any,
) => {
  let ongoingEventIds: any[] = []
  let upCommingEventIds: any[] = []

  let marketIds: string = ''
  let upCommingMarketIds: string = ''

  const competitions = gameManagementCommonData.competitions
  competitions.forEach((competition: any) => {
    const events = competition.events
    events.forEach((event: any) => {
      let isInplay = IsMarketInPlay(event.openDate)
      if (eventIdHashMap.has(event.id)) {
        let eventMarket = eventIdHashMap.get(event.id)
        if (isInplay) {
          marketIds += `${eventMarket.marketId._id};`
          ongoingEventIds.push(eventMarket)
        } else {
          upCommingMarketIds += `${eventMarket.marketId._id};`
          upCommingEventIds.push(eventMarket)
        }
      } else {
        let specifiedMarkets: any = getSpecifiedMarkets(
          event,
          gameManagementCommonData,
        )

        if (isInplay) {
          if (!specifiedMarkets.isLockVisible) {
            marketIds += `${specifiedMarkets.marketId._id};`
          }
          ongoingEventIds.push(specifiedMarkets)
        } else {
          if (!specifiedMarkets.isLockVisible) {
            upCommingMarketIds += `${specifiedMarkets.marketId._id};`
          }
          upCommingEventIds.push(specifiedMarkets)
        }
      }
    })
  })

  let greaterThanThreeTeams = ongoingEventIds.filter(
    (item) => item.teams.length > 3,
  )
  let notGreaterThanThreeTeams = ongoingEventIds.filter(
    (item) => item.teams.length <= 3,
  )
  let onGoingEvents = notGreaterThanThreeTeams.concat(greaterThanThreeTeams)

  const eventOrderMap: { [key: string]: number } = Object.keys(
    tournament,
  ).reduce((map: { [key: string]: number }, eventId, index) => {
    map[eventId] = index
    return map
  }, {})

  // Sort events based on their presence and order in the eventOrderMap
  const sortedEvents = onGoingEvents.sort((a, b) => {
    const aIndex =
      eventOrderMap[a.eventId] !== undefined
        ? eventOrderMap[a.eventId]
        : Infinity
    const bIndex =
      eventOrderMap[b.eventId] !== undefined
        ? eventOrderMap[b.eventId]
        : Infinity
    return aIndex - bIndex
  })

  return {
    ongoingEventIds: sortedEvents,
    upCommingEventIds: upCommingEventIds,
    marketIds: marketIds,
    upCommingMarketIds: upCommingMarketIds,
  }
}

export const filteredEvent = (
  isUpComing: boolean,
  eventIdHashMap: any,
  gameManagementCommonData: any,
) => {
  let eventIds: any[] = []
  let marketIds: string = ''

  const competitions = gameManagementCommonData.competitions
  competitions.forEach((competition: any) => {
    const events = competition.events

    events.forEach((event: any) => {
      let isInplay = IsMarketInPlay(event.openDate)
      let isValid = false

      if ((isInplay && !isUpComing) || (!isInplay && isUpComing)) {
        isValid = true
      }

      if (isValid) {
        if (eventIdHashMap.has(event.id)) {
          let eventMarket = eventIdHashMap.get(event.id)
          marketIds += `${eventMarket.marketId._id};`
          eventIds.push(eventMarket)
        } else {
          let specifiedMarkets: any = getSpecifiedMarkets(
            event,
            gameManagementCommonData,
          )
          if (!specifiedMarkets.isLockVisible) {
            marketIds += `${specifiedMarkets.marketId._id};`
          }
          eventIds.push(specifiedMarkets)
        }
      }
    })
  })

  return {
    eventIds: eventIds,
    marketIds: marketIds,
  }
}

export const filteredEventType = (
  eventIdHashMap: any,
  gameManagementCommonData: any,
) => {
  let eventIds: any[] = []
  let marketIds: string = ''

  const competitions = gameManagementCommonData.competitions
  competitions.forEach((competition: any) => {
    const events = competition.events

    events.forEach((event: any) => {
      if (eventIdHashMap.has(event.id)) {
        let eventMarket = eventIdHashMap.get(event.id)
        marketIds += `${eventMarket.marketId._id};`
        eventIds.push(eventMarket)
      } else {
        let specifiedMarkets: any = getSpecifiedMarkets(
          event,
          gameManagementCommonData,
        )
        if (!specifiedMarkets.isLockVisible) {
          marketIds += `${specifiedMarkets.marketId._id};`
        }
        eventIds.push(specifiedMarkets)
      }
    })
  })

  return {
    eventIds: eventIds,
    marketIds: marketIds,
  }
}

export const getSpecifiedMarkets = (
  event: any,
  gameManagementCommonData: any,
) => {
  const eventRes = {
    _id: event.market._id,
    isLockVisible: event.market.isMarketLocked || event.market.betAllow,
    eventId: event.id,
    eventTypeId: gameManagementCommonData.id,
    teams:
      event.market.teams && event.market.teams.length
        ? event.market.teams.length
        : [1, 2, 3, 4].map(() => ({
            back: [],
            lay: [],
          })),
    type: event.market.type,
    marketId: {
      _id: event.market._id,
      event: {
        id: event.id,
        name: event.name,
        openDate: event.openDate,
      },
      eventType: {
        id: gameManagementCommonData.id,
        name: gameManagementCommonData.name,
      },
      type: event.market.type,
    },
  }

  return eventRes
}

export function createHashMap(array: any) {
  const hashMap: any = {}
  for (const obj of array) {
    hashMap[obj.eventId] = obj
  }
  return hashMap
}

export function createMapFromArrayLiveTv(array: any) {
  if (array && array.length) {
    return array.reduce((map: any, str: any) => {
      map[str.eventId] = str.channelId
      return map
    }, {})
  }

  return {}
}

export const getGameEventScoreAndLiveTV = (
  isLiveTv: boolean,
  isAdministrator: boolean,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeGetHttpCallAsync(
      dispatch,
      `api/${isAdministrator ? 'administrator' : 'common'}/game/${isLiveTv ? 'liveTv' : 'marketScoreList'}`,
      '',
      URLType.master,
      '',
      true,
      '',
    )
      .then((response: any) => {
        let resData: any = {}
        if (isLiveTv) {
          resData = response.docs.event
        } else {
          resData = createHashMap(response.docs)
        }

        dispatch({
          type: isLiveTv ? GET_MATCHES_LIVE_TV : GET_MATCHES_SCORE,
          payload: resData,
        })
      })
      .catch(() => {
        dispatch({
          type: isLiveTv ? GET_MATCHES_LIVE_TV : GET_MATCHES_SCORE,
          payload: [],
        })
      })
  }
}

export const getTournament = () => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeGetHttpCallAsync(
      dispatch,
      `api/common/game/tournament`,
      '',
      URLType.master,
      '',
      false,
      '',
    )
      .then((response: any) => {
        let resData: any = createHashMap(response.docs)
        dispatch({
          type: GET_TOURNAMENT,
          payload: resData,
        })
        dispatch(getHomeData() as any)
      })
      .catch(() => {
        dispatch({
          type: GET_TOURNAMENT,
          payload: [],
        })
      })
  }
}

export const addRemoveScore = () => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeGetHttpCallAsync(
      dispatch,
      `api/common/game/marketScoreList`,
      '',
      URLType.master,
      '',
      true,
      '',
    ).then((res: any) => {
      let resData: any = createHashMap(res.docs)
      dispatch({
        type: GET_MATCHES_SCORE,
        payload: resData,
      })
    })
  }
}

export const addMatchScoreEventAndLiveTV = (
  isLiveTv: boolean,
  payload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePostHttpCallAsync(
      dispatch,
      `api/administrator/game/${isLiveTv ? 'liveTv' : 'marketScoreList'}`,
      URLType.master,
      payload,
      false,
      true,
      '',
      true,
      'Added Successfully',
    )
      .then(() => {
        makeGetHttpCallAsync(
          dispatch,
          `api/administrator/game/${isLiveTv ? 'liveTv' : 'marketScoreList'}`,
          '',
          URLType.master,
          '',
          true,
          '',
        )
          .then((response: any) => {
            let resData: any = {}
            if (isLiveTv) {
              resData = response.docs.event
            } else {
              resData = createHashMap(response.docs)
            }

            dispatch({
              type: isLiveTv ? GET_MATCHES_LIVE_TV : GET_MATCHES_SCORE,
              payload: resData,
            })
          })
          .catch(() => {
            dispatch({
              type: isLiveTv ? GET_MATCHES_LIVE_TV : GET_MATCHES_SCORE,
              payload: [],
            })
          })
      })
      .catch(emptyFunction)
  }
}

export const deleteMatchScoreEventAndLiveTV = (
  isLiveTv: boolean,
  payload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeDeleteHttpCallAsync(
      dispatch,
      `api/administrator/game/${isLiveTv ? 'liveTv' : 'marketScoreList'}`,
      payload,
      URLType.master,
      false,
      true,
      '',
      true,
      'deleted Successfully',
    )
      .then(() => {
        makeGetHttpCallAsync(
          dispatch,
          `api/administrator/game/${isLiveTv ? 'liveTv' : 'marketScoreList'}`,
          '',
          URLType.master,
          '',
          true,
          '',
        )
          .then((response: any) => {
            let resData: any = {}
            if (isLiveTv) {
              resData = response.docs.event
            } else {
              resData = createHashMap(response.docs)
            }

            dispatch({
              type: isLiveTv ? GET_MATCHES_LIVE_TV : GET_MATCHES_SCORE,
              payload: resData,
            })
          })
          .catch(() => {
            dispatch({
              type: isLiveTv ? GET_MATCHES_LIVE_TV : GET_MATCHES_SCORE,
              payload: [],
            })
          })
      })
      .catch(emptyFunction)
  }
}

export const getBetfairEnabled = () => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeGetHttpCallAsync(
      dispatch,
      `api/administrator/game/betfairMarket`,
      '',
      URLType.master,
      '',
      true,
      '',
    )
      .then((response: any) => {
        dispatch({
          type: GET_BETFAIR_ENABLED_MATCHES,
          payload: response.docs.eventId,
        })
      })
      .catch(() => {
        dispatch({
          type: GET_BETFAIR_ENABLED_MATCHES,
          payload: [],
        })
      })
  }
}

export const addBetfairEnabled = (eventId: string, transactionCode: string) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePostHttpCallAsync(
      dispatch,
      `api/administrator/game/betfairMarket`,
      URLType.master,
      JSON.stringify({ eventId: eventId, transactionCode: transactionCode }),
      false,
      true,
      '',
      true,
      'Added Successfully',
    )
      .then((response: any) => {
        dispatch({
          type: GET_BETFAIR_ENABLED_MATCHES,
          eventIds: response.docs.eventId,
        })
      })
      .catch(() => {
        dispatch({
          type: GET_BETFAIR_ENABLED_MATCHES,
          payload: [],
        })
      })
  }
}

export const deleteBetfairEnabled = (
  eventId: string,
  transactionCode: string,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeDeleteHttpCallAsync(
      dispatch,
      `api/administrator/game/betfairMarket`,
      `eventId=${eventId}&transactionCode=${transactionCode}`,
      URLType.master,
      false,
      true,
      '',
      true,
      'deleted Successfully',
    )
      .then((response: any) => {
        dispatch({
          type: GET_BETFAIR_ENABLED_MATCHES,
          eventIds: response.docs.eventId,
        })
      })
      .catch(() => {
        dispatch({
          type: GET_BETFAIR_ENABLED_MATCHES,
          payload: [],
        })
      })
  }
}

export function sortEventsByOpenDate(events: any) {
  if (!events || !events.length) {
    return events
  }

  events.forEach((entry: any) => {
    entry.events.sort((a: any, b: any) => {
      const dateA = new Date(a.marketId.event.openDate).getTime()
      const dateB = new Date(b.marketId.event.openDate).getTime()
      return dateB - dateA
    })
  })
  return events
}

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

import { Fragment, useEffect, useState } from 'react'
import { organizeData } from './MatchBetUtility'
import {
  OpenBetNonSessionContent,
  OpenBetNonSessionHeader,
  OpenBetSessionContent,
  OpenBetSessionHeader,
} from './OpenBetContent'
import { useNavigate } from 'react-router-dom'
import { encrypt } from '../../../../api/authHelper'
import React from 'react'

export interface IOpenMatchBetContent {
  marketStatus: any[]
  isShowBetInfo: boolean
  isShowBetForThisEvent: boolean
}

const OpenMatchBetContent = (props: IOpenMatchBetContent) => {
  const navigate = useNavigate()
  const [marketStatus, setMarketStatus] = useState(
    organizeData(props.marketStatus),
  )

  useEffect(() => {
    setMarketStatus(organizeData(props.marketStatus))
  }, [props.marketStatus])

  const eventClick = (eventId: string) => {
    navigate(`/events/${encrypt(eventId)}`)
  }

  return (
    <>
      {marketStatus.length ? (
        marketStatus.map((matchBet) => (
          <div
            key={matchBet.eventId}
            className='mt-3 text-black bold dark:text-white'
          >
            <div
              className='cursor-pointer'
              onClick={() => eventClick(matchBet.eventId)}
            >
              <u>{matchBet.eventName}</u>
            </div>
            {matchBet.market.length &&
              matchBet.market.map((market) => (
                <div
                  key={market.marketId}
                  className='pt-4 text-black dark:text-white'
                >
                  {market.markets[0].market.marketName}
                  {market.markets[0].market.type === 'session' ? (
                    <OpenBetSessionHeader />
                  ) : (
                    <OpenBetNonSessionHeader />
                  )}
                  {market.markets.length &&
                    market.markets.map(
                      (marketContent: any, indexMarket: any) => (
                        <Fragment key={indexMarket}>
                          {marketContent.market.type === 'session' ? (
                            <OpenBetSessionContent
                              isShowBetInfo={props.isShowBetInfo}
                              marketContent={marketContent}
                            />
                          ) : (
                            <OpenBetNonSessionContent
                              isShowBetInfo={props.isShowBetInfo}
                              marketContent={marketContent}
                            />
                          )}
                        </Fragment>
                      ),
                    )}
                </div>
              ))}
          </div>
        ))
      ) : (
        <div className='flex items-center justify-center my-5'>
          <div className='text-[#2D2D2D] dark:text-white text-[17px] text-center'>
            {_getLocalString('LOC_NO_OPEN_BETS_CONTENT')}{' '}
            {props.isShowBetForThisEvent &&
              _getLocalString('LOC_FOR_THIS_GAME')}
          </div>
        </div>
      )}
    </>
  )
}

export const OpenMatchBet = React.memo(OpenMatchBetContent)

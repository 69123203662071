import {
  GET_BETFAIR_ENABLED_MATCHES,
  GET_MATCHES_LIVE_TV,
  GET_MATCHES_SCORE,
  GET_ONGOING_MATCHES,
  GET_SCHEDULES_MATCHES,
  GET_TOURNAMENT,
} from '../../actions/matchesAction/SchedulesMatchesAction'
import { IInPlayMatchesState } from '../../../types/interfaces/inPlayOutgoingMatches'

const initialState: IInPlayMatchesState = {
  ongoingMatches: [],
  upComingMatches: [],
  betfairEnableMatches: [],
  highlightMatches: [],
  liveTvMatches: [],
  scoreMatches: [],
  tournamentMatches: [],
}

export default function ongoingMatchesReducer(
  state = initialState,
  action: any,
) {
  switch (action.type) {
    case GET_MATCHES_SCORE:
      return {
        ...state,
        scoreMatches: action.payload,
      }

    case GET_MATCHES_LIVE_TV:
      return {
        ...state,
        liveTvMatches: action.payload,
      }

    case GET_TOURNAMENT:
      return {
        ...state,
        tournamentMatches: action.payload,
      }

    case GET_BETFAIR_ENABLED_MATCHES:
      return {
        ...state,
        betfairEnableMatches: action.payload,
      }

    case GET_SCHEDULES_MATCHES:
      return {
        ...state,
        upComingMatches: action.payload,
      }

    case GET_ONGOING_MATCHES:
      return {
        ...state,
        ongoingMatches: action.payload,
      }

    default:
      return state
  }
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useMediaQuery,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { memo, useEffect, useState } from 'react'
import { LiveTelevision } from './Television'
import { useSelector } from 'react-redux'
import ConnectedBetSlipNew from '../../../Game/betslip/ConnectedBetSlipNew'
import ConnectedBetContent from './ConnectedBetContent'
import { useParams } from 'react-router-dom'
import { createMapFromArrayLiveTv } from '../../../../redux/actions/matchesAction/SchedulesMatchesAction'
import { DrawerComp } from './component/DrawerComp'
import { decrypt } from '../../../../api/authHelper'

const SideBarContentComponent = () => {
  const { id } = useParams()
  const [decryptId, setDecryptId] = useState(decrypt(id))
  const isLargerScreen = useMediaQuery('(min-width:1880px)')
  const isBetweenLessLargerScreen = useMediaQuery('(min-width:1750px)')
  const isLessLargerScreen = useMediaQuery('(min-width:1550px)')
  const isLessExtraScreen = useMediaQuery('(min-width:1420px)')
  const isLessLargerExtraScreen = useMediaQuery('(min-width:1320px)')
  const isMoreLessLargerScreen = useMediaQuery('(min-width:1200px)')
  const isExtraTabletScreen = useMediaQuery('(min-width:1120px)')
  const isMoreExtraTabletScreen = useMediaQuery('(min-width:1000px)')
  const isTabletScreen = useMediaQuery('(max-width:900px)')

  const getWidth = () => {
    if (isLargerScreen) return '32rem'
    if (isBetweenLessLargerScreen) return '30rem'
    if (isLessLargerScreen) return '29rem'
    if (isLessExtraScreen) return '26rem'
    if (isLessLargerExtraScreen) return '24rem'
    if (isMoreLessLargerScreen) return '22rem'
    if (isExtraTabletScreen) return '24rem'
    if (isMoreExtraTabletScreen) return '22rem'
    if (isTabletScreen) return '26rem'
    return '20rem'
  }

  const isSmallScreen = useMediaQuery('(max-width:900px)')
  const [expanded, setExpanded] = useState(false)
  const [screenPlay, setScreenPlay] = useState(false)

  const { isLogin, userData } = useSelector((state: any) => state.login)
  const { eventTypeId } = useSelector(
    (state: any) => state.appConfiguration.selectedEvent,
  )
  const liveTv = useSelector((state: any) => state.inPlayMatches.liveTvMatches)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [screenPlayLocal, setScreenPlayLocal] = useState<any>(new Map())

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  useEffect(() => {
    let decryptIdLocal = decrypt(id)
    setDecryptId(decryptIdLocal)
    let screenPlayLocal = createMapFromArrayLiveTv(liveTv)
    setScreenPlayLocal(screenPlayLocal)
    setScreenPlay(!!screenPlayLocal[decryptIdLocal!])
    // setExpanded(!!screenPlayLocal[decryptIdLocal!])
  }, [liveTv, id])

  return (
    <>
      {!isSmallScreen && (
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              borderRadius: '10px',
              height: '10px',
              width: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              cursor: 'pointer',
              backgroundColor: 'var(--selected-theme-color2)',
              borderRadius: '10px',
            },
            '@media screen and (min-width: 1200px)': {
              '&::-webkit-scrollbar': {
                height: '10px',
                width: '7px',
              },
            },
          }}
          style={{
            position: 'fixed',
            right: 0,
            width: getWidth(),
            height: '85vh',
            maxHeight: screen.height,
            display: 'flex',
            flexDirection: 'column',
          }}
          className={`${isLogin ? 'lg:col-span-3 md:col-span-4 ' : 'md:col-span-2 '} mr-2 col-span-12 mb-5`}
        >
          <div
            className='h-auto mb-[2px] xs:hidden md:flex'
            style={{ width: '100%' }}
          >
            <Accordion
              expanded={expanded}
              className='dark:bg-gradient-to-b w-full !bg-[color:transparent] dark:border !rounded-none border-[var(--sideNavBar-border-firstColor)] from-[var(--accordian-gradientTo)] to-[var(--accordian-gradientFrom)]'
            >
              <AccordionSummary
                onClick={() => setExpanded(!expanded)}
                expandIcon={<ExpandMoreIcon className='dark:text-white ' />}
                className='dark:!bg-gradient-to-b !bg-[color:transparent] from-[var(--accordian-gradientTo)] to-[var(--accordian-gradientFrom)] shadow-none !min-h-max dark:!border !border-solid !border-[#440115]'
                classes={{ content: '!my-1' }}
                aria-controls='panel1a-content'
              >
                <span className='dark:text-white leading-tight text-black !font-bebas text-xl'>
                  LIVE
                </span>
              </AccordionSummary>
              <AccordionDetails
                className='bg-transparent dark:text-white text-black'
                style={{ padding: '0' }}
              >
                <LiveTelevision
                  closeTv={!expanded}
                  isLive={expanded && screenPlay}
                  eventId={screenPlayLocal[decrypt(id)]}
                  marketId={''}
                  eventTypeId={eventTypeId}
                />
              </AccordionDetails>
            </Accordion>
          </div>
          <Box style={{ width: '100%', flex: 1 }}>
            {!!userData &&
              !!userData.userType &&
              userData.userType === 'user' && (
                <ConnectedBetSlipNew otherExpanded={expanded} />
              )}
            {!!userData &&
              !!userData.userType &&
              !isSmallScreen &&
              userData.userType !== 'user' && <ConnectedBetContent />}
          </Box>
        </Box>
      )}
      <DrawerComp
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        userData={userData}
      />
    </>
  )
}

export const SideBarContent = memo(SideBarContentComponent)

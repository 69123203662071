import { Box, Modal, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { EventRecords } from './modal/EventRecords'
import { ICurrentDomain } from '../../../../types/interfaces/currentDomain'
import { IDemo } from '../../../../redux/reducers/demoLoginReducer'
import { get2Decimal } from '../../../../utils'

interface IBetContent {
  currentDomain: ICurrentDomain
  nonUserOpenBet: any
  nonUserOpenBetPagination: any
  nonUserOpenBetLoading: boolean
  openBet: any
  openBetPagination: any
  openBetLoading: boolean
  selectedMarketId: string
  selectedEventId: string
  demoLogin: IDemo
  getOpenBet: (currentDomain: any, payload: any) => void
}

export const BetContent = (props: IBetContent) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const formData = new URLSearchParams()
    if (props.selectedMarketId) {
      formData.append('marketId', props.selectedMarketId)
    }

    if (props.selectedEventId) {
      formData.append('eventId', props.selectedEventId)
    }
    formData.append('category', 'exchange')
    !props.demoLogin && props.getOpenBet(props.currentDomain, formData)
  }, [props.selectedEventId, props.selectedMarketId])

  return (
    <>
      <div className='grid pt-4 xs:col-span-6 lg:col-span-1  '>
        <div className='col-span-12'>
          <div className='bg-[linear-gradient(94.28deg,var(--selected-theme-color2)_3.45%,var(--selected-theme-color)_28.47%,var(--selected-theme-color2)_61.86%,var(--selected-theme-color)_98.65%)] p-4 rounded-t-md flex justify-between items-center'>
            <h4 className='font-primary text-lg font-bold leading-[31px] tracking-normal text-left w-40'>
              Child Bet
            </h4>
            <button
              className={`bg-[linear-gradient(43.38deg,var(--theme-table-themeLightColor)_-13.67%,var(--theme-table-themeLightColor)_84.99%)] border border-solid border-[--theme-color]/[.19] shadow-[0_4px_4px_var(--theme-color)] rounded-lg text-white font-primary xs:text-sm sm:text-sm md:text-md lg:text-[14px]  xl:text-lg font-bold leading-[31px] tracking-normal text-left cursor-pointer px-3 py-2`}
              onClick={() => setIsOpen(true)}
            >
              View More
            </button>
          </div>
          <div className='h-[60vh] bg-white rounded-b-md'>
            <div className='flex-col'>
              <div className='flex items-center justify-between p-2'>
                <h5 className='bold font-primary flex justify-center text-[15px] font-normal leading-[31px] tracking-normal text-left text-black flex-[1]'>
                  User Name
                </h5>
                <h5 className='bold font-primary flex justify-center text-[15px] font-normal leading-[31px] tracking-normal text-left text-black flex-[1]'>
                  Nations
                </h5>
                <h5 className='bold font-primary flex justify-center text-[15px] font-normal leading-[31px] tracking-normal text-left text-black flex-1'>
                  Odds
                </h5>
                <h5 className='bold font-primary text-[15px] flex justify-center font-normal leading-[31px] tracking-normal text-left text-black flex-1'>
                  Amount
                </h5>
              </div>
              <hr />
              {!props.openBet || !props.openBet.length ? (
                <h5 className='font-primary text-lg font-normal h-[45vh] justify-center items-center flex text-center text-black'>
                  No Record Found
                </h5>
              ) : (
                <Box
                  className='rounded-b-md'
                  sx={{
                    maxHeight: '54vh',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      borderRadius: '10px',
                      height: '10px',
                      width: '5px',
                      background: 'white',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      cursor: 'pointer',
                      backgroundColor: 'var(--selected-theme-color2)',
                      borderRadius: '10px',
                    },
                    '@media screen and (min-width: 1200px)': {
                      '&::-webkit-scrollbar': {
                        height: '10px',
                        width: '7px',
                      },
                    },
                  }}
                >
                  {props.openBet.map((currentBet: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={`${currentBet.odds.type === 'back' ? 'bg-[#A5D9FE]' : 'bg-[#F8D0CE]'} flex-col`}
                      >
                        <div className='flex items-center justify-between p-2'>
                          <h5 className='font-bold text-[14px] flex justify-center leading-[31px] tracking-normal text-left text-black flex-[1]'>
                            {currentBet.user.userName}
                          </h5>
                          <h5 className='font-primary text-[14px] flex justify-center font-normal leading-[31px] tracking-normal text-left text-black flex-[1]'>
                            {currentBet.user.nation}
                          </h5>
                          <h5 className='font-primary text-[14px] flex justify-center font-normal leading-[31px] tracking-normal text-left text-black flex-1'>
                            {currentBet.market.type === 'session'
                              ? get2Decimal(currentBet.odds.run)
                              : get2Decimal(currentBet.odds.odd)}
                          </h5>
                          <h5
                            style={{ textAlign: 'right' }}
                            className='font-primary text-[14px] flex justify-center font-normal leading-[31px] tracking-normal text-left text-black flex-1'
                          >
                            {get2Decimal(currentBet.stake)}
                          </h5>
                        </div>
                      </div>
                    )
                  })}
                </Box>
              )}
            </div>
          </div>
        </div>

        <Modal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className='fixed left-0 flex items-center justify-center w-full bottom-5 top-5'
        >
          <EventRecords
            demoLogin={props.demoLogin}
            openBetLoading={props.nonUserOpenBetLoading}
            openBetPagination={props.nonUserOpenBetPagination}
            name=''
            openBet={props.nonUserOpenBet}
            getOpenBet={props.getOpenBet}
            eventId={props.selectedEventId}
            isMarket={!!props.selectedMarketId}
            marketId={props.selectedMarketId}
            currentDomain={props.currentDomain}
            setIsOpen={setIsOpen}
          />
        </Modal>
      </div>
    </>
  )
}

import { IVideos } from '../../../../types/interfaces/videos'
import { getAssetUrl } from '../../../../utils'
import { getUrl } from '../../../../api'
import { isActualFuture9 } from '../../../../App'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { isMobileScreensix } from '../../../../utils/internalUtils'

interface ITelevisionProps {
  video: IVideos
}

export const Television = (props: ITelevisionProps) => {
  return (
    <div className='p-4'>
      <img
        className='w-full'
        alt='Television'
        src={getAssetUrl('games/tele.webp')}
      />
    </div>
  )
}

export const LiveTelevision = (props: {
  marketId: any
  isMobile?: boolean
  eventId: any
  eventTypeId: string
  closeTv?: boolean
  isLive: boolean
}) => {
  const currentDomain = useSelector((state: any) => state.currentDomain)

  const getTelevisionUrl = () => {
    return isActualFuture9() && currentDomain.type == 'b2b'
      ? `https://e765432.xyz/static/304a670367b3bb0071c706103526eee9c995486d/getdata.php?chid=${props.eventId}`
      : isMobileScreensix()
        ? `${getUrl('master')}/api/common/mobileTv/${props.eventId}`
        : `${getUrl('master')}/api/common/commonTv/${props.eventId}`
  }


  const isTabletScreen = useMediaQuery(
    '(min-width:410px)  and (max-width: 540px)',
  )
  const isMobileScreen = useMediaQuery(
    '(min-width:0px)  and (max-width: 600px)',
  )


  return props.closeTv ? null : (
    <div
      className='w-full h-full'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {props.isLive && !!props.eventTypeId ? (
        <iframe
          // ref={iframeRef}
          className={` ${isMobileScreen ? '!h-[12rem]' : ''}  h-[22rem] `}
          id='id_livetelevision'
          src={getTelevisionUrl()}
          style={{
            width: '100%',
            border: 'none',
            overflow: 'hidden',
          }}
          scrolling='no'
          frameBorder='0'
        />
      ) : (
        <div>
          <img
            src={getAssetUrl('asset/noLiveTv.webp')}
            style={{
              height: props.isMobile ? '20rem' : '100%',
              width: '100%',
            }}
            alt='image tv'
          />
        </div>
      )}
    </div>
  )
}

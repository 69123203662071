import { forwardRef, useState } from 'react'
import { emptyFunction, getAssetUrl } from '../../utils'
import {
  CloseSharp,
  CopyAll,
  LogoutTwoTone,
  Password,
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import { CircularProgress, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { IChangePasswordPayload } from '../../types/interfaces/login'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { HEADERS } from '../../api'

interface IChangePassword {
  logoutUser: any
  currentDomain: ICurrentDomain
  userType: any
  changePasswordFuncData: any
  setIsChangePassword: (isChangePassword: boolean) => void
  changePassword?: (
    data: IChangePasswordPayload,
    currentDomain: ICurrentDomain,
    setShowTransactionCode: any,
    setTransactionCode: any,
    cb: () => void,
  ) => void
}

export const ChangePassword = forwardRef((props: IChangePassword, ref: any) => {
  const [password, setPassword] = useState('')
  const [comfirmpassword, setComfirmPassword] = useState('')
  const [showTransactionCode, setShowTransactionCode] = useState(false)
  const [transactionCode, setTransactionCode] = useState('')
  const [Copied, setCopied] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const updatePassword = (e: any) => {
    setPassword(e.target.value)
  }
  const navigate = useNavigate()

  const updateComfirmPassword = (e: any) => {
    setComfirmPassword(e.target.value)
  }

  const onChangePassword = () => {
    const oldPassword = localStorage.getItem(HEADERS.tempPassword)
    if (!password || !oldPassword) {
      return toast.error('Invalid Password')
    }

    if (password !== comfirmpassword) {
      return toast.error(`Password Didn't Match`)
    }

    setLoading(true)
    props.changePassword!(
      { newPassword: password, oldPassword: oldPassword },
      props.currentDomain,
      handleSetShowTransactionCode,
      setTransactionCode,
      resetLoading,
    )
  }

  const resetLoading = () => {
    setLoading(false)
  }

  const handleSetShowTransactionCode = (save: boolean) => {
    localStorage.removeItem(HEADERS.tempPassword)
    props.changePasswordFuncData(false)
    if (save && props.userType !== 'user') setShowTransactionCode(save)
    else onCloseChangePassword()
  }

  const isXsScreen = useMediaQuery('(max-width:600px)')

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(transactionCode)
    toast.success(_getLocalString('LOC_COPIED_CLIPBOARD'))
    setCopied(true)
  }

  const onCloseChangePassword = () => {
    props.logoutUser()
    setShowTransactionCode(false)
    props.setIsChangePassword(false)
  }

  const onCloseChangePasswordAndLogout = () => {
    let changePassword = localStorage.getItem(HEADERS.changePassword)
    props.setIsChangePassword(false)
    changePassword &&
      props.logoutUser(navigate, props.userType, props.currentDomain)
  }

  return (
    <div
      ref={ref}
      id='normalFont'
      className='relative w-[90%] md:w-[70%] h-[600px]  p-[1.5px] sm:justify-center lg:w-[60%] xl:w-[50%] rounded-[10px]  bg-[linear-gradient(180deg,var(--popup-box-1),var(--paymentMethodColor)),linear-gradient(104.51deg,var(--popup-box-3),var(--popup-box-4))]'
    >
      <div
        className='w-full h-full bg-no-repeat bg-cover  rounded-[10px]'
        style={{
          backgroundImage: `url(${getAssetUrl('assets/stadium-lights-flashes-3d.webp')})`,
        }}
      >
        <div className='flex bg-no-repeat bg-cover bg-left rounded-[10px] h-full'>
          <div className='relative w-1/2 bg-cover bg-center  rounded-l-[10px]  hidden sm:flex'>
            <div
              className='w-full h-full absolute bg-cover rounded-l-[10px] bg-no-repeat mix-blend-darken '
              style={{
                backgroundImage: `url(${getAssetUrl('light.webp')})`,
              }}
            ></div>
            <div
              className='w-full h-full absolute bg-top-left  bg-[size:150%] bg-[position:-150px_-5px] bg-no-repeat  mix-blend-color-dodge'
              style={{
                backgroundImage: `url(${getAssetUrl('asset/girl-bg.webp')})`,
                transform: 'rotate(10deg)',
              }}
            ></div>
          </div>
          <div
            className={`bg-[size:108%] bg-[position:-205px_-70px] w-full h-full absolute top-0 left-0 bg-no-repeat ${isXsScreen ? 'hidden' : ''}`}
            style={{
              backgroundImage: `url(${getAssetUrl('asset/girl.webp')})`,
            }}
          ></div>

          <div className='w-full rounded-r-lg bg-center bg-gradient-to-br to-[var(--popup-box-right-1)] to-[60%]  from-[var(--popup-box-right-2)] bg-no-repeat  bg-cover w-full xs:w-[100%] justify-center sm:w-1/2'>
            <div
              className='h-full w-full p-0 pt-8 rounded-r-lg bg-center bg-no-repeat backdrop-blur-sm bg-cover sm:justify-center'
              style={{ background: `var(--theme-color)` }}
            >
              <div className='absolute z-[999] top-7 right-7 cursor-pointer'>
                <button
                  onClick={onCloseChangePasswordAndLogout}
                  className='w-5 h-5 rounded-[3px] flex justify-center items-center bg-white'
                >
                  <CloseSharp className='!text-black !text-base' />
                </button>
              </div>

              {!showTransactionCode ? (
                <div className='flex flex-col my-[10px]  mx-[30px] h-[450px] justify-center items-center'>
                  <div className='pb-5 font-bebas font-bold tracking-wide  text-[35px] leading-30 text-center '>
                    {_getLocalString('LOC_CHANGE_PASSWORD_UPPERCASE')}
                  </div>
                  {props.userType !== 'user' && (
                    <div className='text-center font-bold my-6 text-col text-yellow-500'>
                      {_getLocalString(
                        'LOC_CHANGE_PASSWORD_AND_SAVE_TRANSACTION_CODE',
                      )}
                    </div>
                  )}
                  <div className='flex justify-center items-center relative w-full mt-2'>
                    <Password className='absolute left-3 self-center' />
                    <input
                      id='userNameTextBox__id'
                      className='w-full rounded-[5px] ml-[0] p-3 bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12'
                      type={isPasswordVisible ? 'text' : 'password'}
                      value={'***************'}
                      onChange={emptyFunction}
                      placeholder={_getLocalString('LOC_ENTER_OLD_PASSWORD')}
                    />
                  </div>
                  <br />
                  <div className='flex justify-center items-center relative w-full mt-2'>
                    <Password className='absolute left-3 self-center' />
                    <input
                      id='userNameTextBox__id'
                      className='w-full rounded-[5px] ml-[0] p-3 bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12'
                      type={isPasswordVisible ? 'text' : 'password'}
                      value={password}
                      onChange={updatePassword}
                      placeholder={_getLocalString('LOC_NEW_PASSWORD')}
                    />
                    {isPasswordVisible ? (
                      <VisibilityIcon
                        className='absolute right-7 self-center cursor-pointer text-white'
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        className='absolute right-7 self-center cursor-pointer text-white'
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    )}
                  </div>
                  <div className='flex justify-center items-center relative w-full mt-2'>
                    <Password className='absolute left-3 self-center' />
                    <input
                      id='userNameTextBox__id'
                      className='w-full rounded-[5px] ml-[0] p-3 bg-white/[.15] border border-solid border-white/[.21] focus:outline-none pl-12'
                      type={isPasswordVisible ? 'text' : 'password'}
                      value={comfirmpassword}
                      onChange={updateComfirmPassword}
                      placeholder={'Confirm Password'}
                    />
                    {isPasswordVisible ? (
                      <VisibilityIcon
                        className='absolute right-7 self-center cursor-pointer text-white'
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        className='absolute right-7 self-center cursor-pointer text-white'
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    )}
                  </div>
                  <br />
                  <button
                    className='bg-[linear-gradient(43.38deg,var(--button-popup-1),var(--button-popup-2))] mt-2 border shadow-[0px_4px_4px_var(--theme-color)] w-full text-[color:var(--fontColor)] font-bold text-center cursor-pointer leading-[19px] p-3 tracking-[0.8px] rounded-[5px] text-lg border-solid border-[var(--theme-color)]'
                    onClick={onChangePassword}
                  >
                    {loading ? (
                      <CircularProgress
                        size={25}
                        className='white-circular !text-white'
                      />
                    ) : (
                      _getLocalString('LOC_CHANGE_PASSWORD')
                    )}
                  </button>
                </div>
              ) : transactionCode ? (
                <div className='flex flex-col my-[10px]  mx-[30px] h-[450px] justify-center items-center'>
                  <div className='uppercase font-bebas font-bold tracking-wide  text-[35px] leading-30 text-center '>
                    {_getLocalString('LOC_TRANSACTION_CODE')}
                  </div>
                  <div className='text-center font-bold my-6 text-col text-yellow-500'>
                    {_getLocalString('LOC_PLEASE_COPY_AND_SAVE_TRANSACTION')}
                  </div>
                  <div className='w-3/4 flex justify-center border border-[#dddddd] rounded-md p-2'>
                    <span className='w-4/5'>{transactionCode}</span>
                    <span
                      onClick={copyToClipBoard}
                      className='cursor-pointer capitalize text-[var(--button-popup-border)]'
                    >
                      {Copied ? (
                        <CopyAll className='!text-[var(--button-popup-border)]' />
                      ) : (
                        <CopyAll className='!text-[#333]' />
                      )}
                    </span>
                  </div>
                  <br />
                  <div
                    onClick={onCloseChangePassword}
                    className='mt-6 bg-[linear-gradient(43.38deg,var(--button-popup-1),var(--button-popup-2))] border border-solid border-[var(--button-popup-border)]/[.19] shadow-[0_4px_4px_var(--theme-color)] rounded-md w-full text-white font-bold py-3 text-center cursor-pointer'
                  >
                    {_getLocalString('LOC_CLOSE')}
                  </div>
                </div>
              ) : (
                <div className='p-2 text-center'>
                  {' '}
                  {_getLocalString('LOC_LOGOUT_TO_USE_PASSWORD')}
                  <div
                    className='bold mt-10 cursor-pointer'
                    onClick={() =>
                      props.logoutUser(
                        navigate,
                        props.userType,
                        props.currentDomain,
                      )
                    }
                  >
                    {' '}
                    <LogoutTwoTone /> {_getLocalString('LOC_LOGOUT')}{' '}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

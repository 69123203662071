import { useEffect, useMemo, useRef, useState } from 'react'
import {
  MARKET_STATUS_CALL_LOGIN,
  MARKET_STATUS_CALL_NON_LOGIN,
  URLType,
  makeGetHttpCallAsync,
} from '../../../api'
import { SportsContentItem } from '../content/SportsContentItem'
import { ICurrentBet } from '../../Game/betslip/NewBetSlip'
import { InPlayHeader } from '../../landing/component/inPlay/InPlayHeader'
import { getAssetUrl } from '../../../utils'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import {
  removeElementAtIndex,
  removeNullMarketId,
} from '../../../utils/gameUtils/gameUtil'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_SPORTS_MARKET_MARKET_VALUE } from '../../../redux/actions/marketStatusAction'
import { removeDuplicateIds } from '../../../utils/internalUtils'

export interface IUpCommingMatches {
  isUpComing: boolean
  isDarkMode: boolean
  setIsDrawerOpen: any
  isNoHeader?: boolean
  currentBet: ICurrentBet
  markets: any
  isPopUpOpen: boolean
  currentDomain: ICurrentDomain
  eventFavoriteList: any
  addFavoriteEventList: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  deleteFavoriteEvent: (
    currentDomain: ICurrentDomain,
    payload: any,
    cb: any,
  ) => void
  updateOpenLoginScreen: (openLoginScreen: boolean) => void
  onMarketClick: (market: ICurrentBet) => void
  isLogin: boolean
  isDrawerOpen: boolean
  isFavorites?: boolean
}

export const UpCommingMatches = (props: IUpCommingMatches) => {
  const {
    marketIds,
    onTimeMarketIds,
    highlightMarketIds,
    fastestOddMarketIds,
    searchMarketIds,
  } = useSelector((state: any) => state.marketStatus)

  const { tokenExpire } = useSelector((state: any) => state.appConfiguration)
  const [tempStatus, setTempStatus] = useState<Map<any, any>>(new Map())
  const [oneTimeStatus, setOneTimeStatus] = useState<Map<any, any>>(new Map())
  const [marketStatus, setMarketStatus] = useState(
    removeNullMarketId(props.markets),
  )

  useEffect(() => {
    setMarketStatus(removeNullMarketId(props.markets))
  }, [props.markets])

  const removeMarketIndex = (index: number) => {
    let marketStatusLocal = marketStatus
    removeElementAtIndex(marketStatusLocal, index)
    setMarketStatus(marketStatusLocal)
  }

  const intervalIdRef = useRef<any>(null)
  const dispatch = useDispatch()

  const getStatus = () => {
    let marketIdsLocal = marketIds + fastestOddMarketIds
    if (highlightMarketIds && props.isLogin) {
      marketIdsLocal += highlightMarketIds
    }

    if (searchMarketIds) {
      marketIdsLocal = searchMarketIds
    }
    marketIdsLocal = removeDuplicateIds(marketIdsLocal)

    marketIdsLocal &&
      makeGetHttpCallAsync(
        dispatch,
        'api/common/game/market/status',
        `marketId=${marketIdsLocal}`,
        URLType.master,
        false,
        false,
        '',
      ).then((res: any) => {
        const marketIdMap = new Map()

        res.docs.forEach((doc: any) => {
          const marketId = doc.marketId._id
          const object = doc
          marketIdMap.set(marketId, object)
        })
        setTempStatus(marketIdMap)
        dispatch({
          type: ADD_SPORTS_MARKET_MARKET_VALUE,
          data: res.docs,
        })
      })
  }

  const getOneTimeStatus = () => {
    makeGetHttpCallAsync(
      dispatch,
      'api/common/game/market/status',
      `marketId=${onTimeMarketIds}`,
      URLType.master,
      false,
      false,
      '',
    ).then((res: any) => {
      const marketIdMap = new Map()

      res.docs.forEach((doc: any) => {
        const marketId = doc.marketId._id
        const object = doc
        marketIdMap.set(marketId, object)
      })
      setOneTimeStatus(marketIdMap)
    })
  }

  useEffect(() => {
    if (!props.isUpComing) {
      if (searchMarketIds) {
        getStatus()
      }
      intervalIdRef.current = setInterval(
        getStatus,
        props.isLogin ? MARKET_STATUS_CALL_LOGIN : MARKET_STATUS_CALL_NON_LOGIN,
      )
      return () => {
        clearInterval(intervalIdRef.current)
      }
    }
  }, [marketIds, highlightMarketIds, fastestOddMarketIds])

  useEffect(() => {
    onTimeMarketIds && getOneTimeStatus()
  }, [onTimeMarketIds])

  const marketStatusData = useMemo(() => {
    if (marketStatus && marketStatus.length > 0) {
      const desiredOrder = ['4', '1', '2']

      return marketStatus.sort((a: any, b: any) => {
        return (
          desiredOrder.indexOf(a.eventTypeId) -
          desiredOrder.indexOf(b.eventTypeId)
        )
      })
    }
  }, [marketStatus])

  return (
    <div>
      {!props.isNoHeader && (
        <InPlayHeader
          headerRemove={false}
          isDarkMode={false}
          title={
            props.isFavorites
              ? 'Favorite'
              : props.isUpComing
                ? _getLocalString('LOC_UPCOMMING_EVENTS')
                : _getLocalString('LOC_INPLAY')
          }
          icon={getAssetUrl('inPlayHeader/ball_1.svg')}
        />
      )}

      {marketStatusData && marketStatusData.length ? (
        marketStatusData.map((matches: any, index: number) => {
          return (
            <div className='pb-5' key={index}>
              <SportsContentItem
                isBetSlipLoading={false}
                tokenExpire={tokenExpire}
                tempStatus={new Map([...tempStatus, ...oneTimeStatus])}
                setIsDrawerOpen={props.setIsDrawerOpen}
                isDrawerOpen={props.isDrawerOpen}
                isMaketShown={false}
                removeMarketIndex={removeMarketIndex}
                key={index}
                isUpComing={props.isUpComing}
                currentDomain={props.currentDomain}
                addFavoriteEventList={props.addFavoriteEventList}
                deleteFavoriteEvent={props.deleteFavoriteEvent}
                eventFavoriteList={props.eventFavoriteList}
                updateOpenLoginScreen={props.updateOpenLoginScreen}
                onGoing={!props.isUpComing}
                isPopUpOpen={props.isPopUpOpen}
                currentBet={props.currentBet}
                onMarketClick={props.onMarketClick}
                isLogin={props.isLogin || false}
                marketStatus={matches.events}
              />
            </div>
          )
        })
      ) : (
        <div className='p-4 text-black dark:text-white'>
          {_getLocalString('LOC_NO')} {props.isUpComing ? 'UPCOMMING' : 'LIVE'}{' '}
          {_getLocalString('LOC_MATCHES_RIGHT_NOW')}!!
        </div>
      )}
    </div>
  )
}

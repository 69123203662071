import { Dispatch } from '@reduxjs/toolkit'
import {
  GetReduxStateFn,
  GET_FAVORITE_LIST_EVENTS_NAMES,
  GET_GAME_MANAGEMENT_COMMON_DATA,
  APP_CONFIGURATION_EVENT_DETAIL,
  UPDATE_MODIFY_MARKET,
} from '../type'
import { toast } from 'react-toastify'
import {
  makeDeleteHttpCallAsync,
  makeGetHttpCallAsync,
  makePatchHttpCallAsync,
  makePostHttpCallAsync,
  makePutHttpCallAsync,
} from '../../api/httpMethod'
import { HEADERS, URLType } from '../../api'
import { DEFAULT_STAKE, GET_USER_STAKES } from './userAction'
import { IUserLogin } from '../../types/interfaces/login'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { DEMO_LOGIN } from '../reducers/demoLoginReducer'
import { HASHMAP_BET } from './userAction/betSlipAction'
import {
  deleteEventIdFromMap,
  emptyFunction,
  getCommonEvents,
  getCorrectUserType,
  mapEventIdsToTrue,
} from '../../utils'
import { UPDATE_SELECTED_MARKET } from './GameManagementCommonAction'
import { GET_HIGHTLIGHT_MATCH } from './userAction/highlightsAction'
import { SAVE_FASTEST_ODD } from './userAction/fastestOddAction'
import {
  GET_ONGOING_MATCHES,
  GET_SCHEDULES_MATCHES,
  getFilteredEventsByUpcomingAndOnGoing,
} from './matchesAction/SchedulesMatchesAction'
import { getEventMap } from '../../utils/internalUtils'
import {
  SAVE_ONTIME_STATUS_MARKETIDS,
  SAVE_STATUS_MARKETIDS,
} from './marketStatusAction'

export const SET_IS_LOGIN = '@LOGIN/SET_IS_LOGIN'
export const SET_COMMUNICATION = '@LOGIN/COMMUNICATION'
export const LOGOUT_USER = '@LOGIN/LOGOUT_USER'
export const SET_IS_LOGIN_LOADER = '@LOGIN/SET_IS_LOGIN_LOADER'
export const UPDATE_USER_POINTS = '@LOGIN/UPDATE_USER_POINTS'
export const POINT_UPDATE = '@LOGIN/POINT_UPDATE'
export const SET_USER_ACCOUNT_LIST = '@LOGIN/SET_USER_ACCOUNT_LIST'
export const SET_USER_PAYMENT_LIST = '@LOGIN/SET_USER_PAYMENT_LIST'
export const SET_USER_QUICK_BET = '@LOGIN/SET_USER_QUICK_BET'
export const SET_USER_QUICK_BET_VALUE = '@LOGIN/SET_USER_QUICK_BET_VALUE'
export const SET_USER_BONUS_LIST = '@LOGIN/SET_USER_BONUS_LIST'
export const SET_USER_BONUS_APPLIED_LIST = '@LOGIN/SET_USER_BONUS_APPLIED_LIST'
export const SET_USER_COUNTRY = '@LOGIN/SET_USER_COUNTRY'
export const FIRST_TIME_LOGIN = '@LOGIN/FIRST_TIME_LOGIN'
export const SET_USER_FAVORITE_EVENT_LIST =
  '@LOGIN/SET_USER_FAVORITE_EVENT_LIST'
export const SET_USER_CREDIT_REFERENCE = 'SET_USER_CREDIT_REFERENCE'
export const SET_USER_2FA = '@LOGIN/SET_USER_2FA'
export const SET_USER_FAVORITE_MARKET_LIST =
  '@LOGIN/SET_USER_FAVORITE_MARKET_LIST'
export const GET_PROFILE = '@PROFILE/GET_PROFILE'

export const getCommunicationDetail = (id: string) => {
  return async (dispatch: Dispatch) => {
    let userType = localStorage.getItem(HEADERS.userType)

    makeGetHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/communicationDetail`,
      `adminId=${id}`,
      URLType.b2c,
      '',
      true,
      '',
    )
      .then((res: any) => {
        dispatch({
          type: SET_COMMUNICATION,
          payload: res.docs,
        })
      })
      .catch(() => {
        dispatch({
          type: SET_COMMUNICATION,
          payload: null,
        })
      })
  }
}

export const getCacheMarket = () => {
  return async (dispatch: Dispatch) => {
    makeGetHttpCallAsync(
      dispatch,
      `api/common/game/market/group`,
      '',
      URLType.master,
      false,
      true,
      '',
    )
      .then((res: any) => {
        dispatch({
          type: GET_GAME_MANAGEMENT_COMMON_DATA,
          loading: false,
          data: res.docs,
        })

        setTimeout(() => {
          let eventIdMap = getEventMap(res.docs)
          dispatch({
            type: APP_CONFIGURATION_EVENT_DETAIL,
            payload: eventIdMap,
          })
        }, 0)
      })
      .catch(emptyFunction)
  }
}
export const getHomeData = () => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeGetHttpCallAsync(
      dispatch,
      'api/common/home',
      '',
      URLType.master,
      '',
      true,
      '',
    ).then((res: any) => {
      let response = res.docs
      let gameData = getState().gameManagementCommon.gamesCommonData
      let tournamentMatches = getState().inPlayMatches.tournamentMatches

      if (response && gameData) {
        let { fastestOdd, highlight, marketIds, ongoing, upcoming } = response
        dispatch({
          type: UPDATE_SELECTED_MARKET,
          loading: false,
          data: marketIds,
        })

        dispatch({
          type: GET_HIGHTLIGHT_MATCH,
          eventIds: highlight,
        })

        dispatch({
          type: SAVE_FASTEST_ODD,
          payload: fastestOdd,
        })

        dispatch({
          type: GET_SCHEDULES_MATCHES,
          payload: upcoming,
        })

        dispatch({
          type: GET_ONGOING_MATCHES,
          payload: ongoing,
        })

        setTimeout(() => {
          let response = getFilteredEventsByUpcomingAndOnGoing(
            tournamentMatches,
            ongoing,
            upcoming,
            gameData,
          )

          let eventOngoingDetail = response?.ongoing
          let eventUpCommingDetail = response?.upComming
          let marketIds = response?.marketIds
          let marketIdsUpcommingLocal = response?.marketIdsUpcomming

          dispatch({
            type: SAVE_STATUS_MARKETIDS,
            data: marketIds,
          })

          dispatch({
            type: SAVE_ONTIME_STATUS_MARKETIDS,
            data: marketIdsUpcommingLocal,
          })

          dispatch({
            type: GET_ONGOING_MATCHES,
            payload: eventOngoingDetail,
          })

          dispatch({
            type: GET_SCHEDULES_MATCHES,
            payload: eventUpCommingDetail,
          })
        }, 0)
      }
    })
  }
}

export const loginUser = (
  userLoggedIn: IUserLogin,
  navigate: any,
  closeModal: (isLogin: boolean) => void,
  setIsChangePassword: (isChangedPassword: boolean) => void,
  currentDomain: ICurrentDomain,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let { country, country_flag, city } = getState().login.userCountryDetail

    try {
      dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: true })
      let formData = new URLSearchParams()
      formData.append('email', userLoggedIn.email)
      formData.append('password', userLoggedIn.password)
      formData.append('whitelabelId', currentDomain.whitelabelId)
      formData.append('country', country)
      formData.append('city', city)
      formData.append('domain', currentDomain.domainName)
      formData.append('countryFlag', country_flag)

      makePostHttpCallAsync(
        dispatch,
        'api/auth',
        currentDomain.type,
        formData,
        false,
        false,
        'application/x-www-form-urlencoded',
        false,
        '',
        '',
        true,
      )
        .then((response: any) => {
          setTimeout(() => {
            dispatch({
              type: UPDATE_MODIFY_MARKET,
            })
            dispatch(getHomeData() as any)
          }, 1000)

          if (response?.docs?.userType !== 'user') {
            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
            return toast.info('Login with admin website')
          }

          closeModal(false)
          dispatch(getHomeData() as any)

          if (
            response &&
            !response.docs.cockFightCreated &&
            !response.docs.demo
          ) {
            makeGetHttpCallAsync(
              dispatch,
              'api/user/cockFight/create',
              '',
              currentDomain.type,
              '',
              true,
              '',
            )
              .then(emptyFunction)
              .catch(emptyFunction)
          }

          if (
            response &&
            response.docs.enable2fa &&
            !response.docs.otpVerified
          ) {
            localStorage.setItem(HEADERS.enable2FA, response?.docs?.enable2fa)
            localStorage.setItem('tempData', JSON.stringify(response?.docs))
            localStorage.setItem(
              HEADERS.userType,
              response?.docs?.userType === 'defaultBranch'
                ? 'branch'
                : response?.docs?.userType,
            )
            navigate('/login-auth')
            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
            return
          }

          dispatch({
            type: FIRST_TIME_LOGIN,
            payload: true,
          })

          if (response && response.docs.changePassword) {
            dispatch({
              type: SET_IS_LOGIN,
              isLogin: response.docs,
            })
            localStorage.setItem(
              HEADERS.userType,
              response?.docs?.userType === 'defaultBranch'
                ? 'branch'
                : response?.docs?.userType,
            )

            if (response?.docs?.userType === 'user') {
              dispatch(getFavoriteEvent(currentDomain) as any)
            }

            localStorage.setItem(
              HEADERS.changePassword,
              response?.docs?.changePassword,
            )
            localStorage.setItem(HEADERS.tempPassword, userLoggedIn.password)
            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })

            setIsChangePassword(true)
          } else {
            closeModal(false)
            toast.success(_getLocalString('LOC_LOGIN_SUCCESSFUL'))
            dispatch({
              type: SET_IS_LOGIN,
              isLogin: response.docs,
            })
            localStorage.setItem(
              HEADERS.userType,
              response?.docs?.userType === 'defaultBranch'
                ? 'branch'
                : response?.docs?.userType,
            )
            navigate('/')
            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
          }

          if (!response) {
            navigate('/')
          }
        })
        .catch(async (error: any) => {
          let err = await error
          if (err?.message === 'Access Denied: Account Locked By Admin') {
            toast.error(`${err?.message}, Please contact your upline!`)
          } else {
            toast.error(err?.message || err)
          }
          dispatch({
            type: GET_USER_STAKES,
            isLoading: false,
            data: DEFAULT_STAKE,
          })
          dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
        })
    } catch (error: any) {
      dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
    } finally {
    }
  }
}

export const logoutUserFromScreen = (navigate: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOGOUT_USER })
    navigate('/')
    localStorage.removeItem(HEADERS.userType)
    localStorage.removeItem(HEADERS.changePassword)
    localStorage.removeItem(HEADERS.xAuthentication)
    sessionStorage.removeItem(HEADERS.xAuthentication)
    dispatch({ type: DEMO_LOGIN, payload: false, delayTime: 10 })
  }
}

export const logoutUser = (
  navigate: any,
  userType: String,
  currentDomain: ICurrentDomain,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let { country, country_flag, city } = getState().login.userCountryDetail
    let { cockFightCreated } = getState().login.userData

    try {
      let userType = localStorage.getItem(HEADERS.userType)
      navigate('/')

      if (cockFightCreated) {
        makeGetHttpCallAsync(
          dispatch,
          'api/user/cockFight/logout',
          '',
          currentDomain.type,
          '',
          true,
          '',
        )
          .then(emptyFunction)
          .catch(emptyFunction)
      }

      currentDomain &&
        makeDeleteHttpCallAsync(
          dispatch,
          `api/${userType === 'defaultBranch' ? 'branch' : userType}/auth`,
          `country=${country}&city=${city}&countryFlag=${country_flag}&domain=${currentDomain.domainName}`,
          currentDomain.type,
          false,
          true,
        )
          .then((response: any) => {
            dispatch({
              type: SET_IS_LOGIN,
              isLogin: false,
            })

            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
            dispatch({ type: LOGOUT_USER })

            dispatch({
              type: HASHMAP_BET,
              hashMapBet: new Map(),
            })
            localStorage.removeItem(HEADERS.userType)
            localStorage.removeItem(HEADERS.changePassword)
            localStorage.removeItem(HEADERS.xAuthentication)
            sessionStorage.removeItem(HEADERS.xAuthentication)
            dispatch({ type: DEMO_LOGIN, payload: false, delayTime: 10 })
          })
          .catch(async (error: any) => {
            let err = await error
            toast.error(err?.message || err)

            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
            dispatch({ type: LOGOUT_USER })

            localStorage.removeItem(HEADERS.userType)
            localStorage.removeItem(HEADERS.changePassword)
            localStorage.removeItem(HEADERS.xAuthentication)
            sessionStorage.removeItem(HEADERS.xAuthentication)
            dispatch({ type: DEMO_LOGIN, payload: false, delayTime: 10 })
            navigate('/')
          })
    } catch (error: any) {
      dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
      dispatch({ type: LOGOUT_USER })

      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      localStorage.removeItem(HEADERS.xAuthentication)
      sessionStorage.removeItem(HEADERS.xAuthentication)
      dispatch({ type: DEMO_LOGIN, payload: false, delayTime: 10 })
      navigate('/')
    }
  }
}

export const getProfile = (
  navigate: any,
  userType: string,
  currentDomain: ICurrentDomain,
  getUserDetail: boolean,
  cb?: () => void,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_PROFILE, loading: true })
      dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: true })
      makeGetHttpCallAsync(
        dispatch,
        `api/${userType === 'defaultBranch' ? 'branch' : userType}/profile`,
        '',
        currentDomain.type,
        '',
        true,
      )
        .then((response: any) => {
          if (response) {
            if (response.docs.demo) {
              dispatch({ type: DEMO_LOGIN, payload: true, delayTime: 10 })
            }

            if (
              response &&
              !response.docs.cockFightCreated &&
              !response.docs.demo
            ) {
              makeGetHttpCallAsync(
                dispatch,
                'api/user/cockFight/create',
                '',
                currentDomain.type,
                '',
                true,
                '',
              )
                .then(emptyFunction)
                .catch(emptyFunction)
            }

            dispatch({ type: SET_IS_LOGIN, isLogin: response.docs })
            localStorage.setItem(
              HEADERS.userType,
              response?.docs?.userType === 'defaultBranch'
                ? 'branch'
                : response?.docs?.userType,
            )
            cb && cb()
            dispatch({ type: GET_PROFILE, loading: false })
            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
            if (getUserDetail && response?.docs?.userType === 'user') {
              dispatch({ type: GET_USER_STAKES, loading: true })

              makeGetHttpCallAsync(
                dispatch,
                `api/user/stake`,
                '',
                currentDomain.type,
                false,
                true,
                '',
              )
                .then((response: any) => {
                  dispatch({
                    type: GET_USER_STAKES,
                    isLoading: false,
                    data: response.docs || DEFAULT_STAKE,
                  })
                })
                .catch(async (error: any) => {
                  dispatch({
                    type: GET_USER_STAKES,
                    isLoading: false,
                    data: DEFAULT_STAKE,
                  })
                })
            }
          } else {
            cb && cb()
            dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
          }
        })
        .catch(async (error: any) => {
          let err = await error
          if (err && err.message) {
            localStorage.removeItem(HEADERS.userType)
            localStorage.removeItem(HEADERS.changePassword)
            localStorage.removeItem(HEADERS.xAuthentication)
            navigate('/')
          }
          cb && cb()
          dispatch({ type: SET_IS_LOGIN_LOADER, isLoginLoader: false })
        })
    } catch (error: any) {
      cb && cb()
    } finally {
    }
  }
}

export const AddUserAccountList = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: SET_USER_ACCOUNT_LIST, payload: [] })

      makePostHttpCallAsync(
        dispatch,
        'api/user/account',
        currentDomain.type,
        JSON.stringify(payload),
        '',
        true,
        '',
        true,
        'Account Created Successfully',
        'Account Not Created',
      )
        .then((response: any) => {
          try {
            dispatch({ type: SET_USER_ACCOUNT_LIST, data: [], loading: true })
            makeGetHttpCallAsync(
              dispatch,
              `api/user/account`,
              '',
              '',
              false,
              true,
              '',
            )
              .then((response: any) => {
                dispatch({
                  type: SET_USER_ACCOUNT_LIST,
                  payload: response.docs,
                })
              })
              .catch(async (error) => {
                let err: any = await error
              })
          } catch (error) {
            dispatch({ type: SET_USER_ACCOUNT_LIST, loading: false, data: [] })
          }
          cb()
        })
        .catch(async (error: any) => {
          console.error('API request failed:', error)
          let err = await error
        })
    } catch (error: any) {
      console.error('Unexpected error:', error)
    } finally {
      dispatch({ type: SET_USER_ACCOUNT_LIST, payload: [] })
    }
  }
}

export const getUserAccountList = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: SET_USER_ACCOUNT_LIST, data: [], loading: true })
      makeGetHttpCallAsync(
        dispatch,
        `api/user/account`,
        '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({ type: SET_USER_ACCOUNT_LIST, payload: response.docs })
        })
        .catch(async (error) => {
          let err: any = await error
        })
    } catch (error) {
      let err: any = await error
      dispatch({ type: SET_USER_ACCOUNT_LIST, loading: false, data: [] })
    }
  }
}

export const updateUserAccountListForUser = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePutHttpCallAsync(
      dispatch,
      'api/user/account',
      currentDomain.type,
      JSON.stringify(payload),
      '',
      true,
      '',
      true,
      'Account List Updated',
    ).then((res) => {
      cb()
      try {
        dispatch({ type: SET_USER_ACCOUNT_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          `api/user/account`,
          '',
          '',
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({ type: SET_USER_ACCOUNT_LIST, payload: response.docs })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_ACCOUNT_LIST, loading: false, data: [] })
      }
    })
  }
}

export const deleteUserAccountListForUser = (
  currentDomain: ICurrentDomain,
  payload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeDeleteHttpCallAsync(
      dispatch,
      'api/user/account',
      payload,
      currentDomain.type,
      '',
      true,
      '',
      true,
      'Account Deleted',
    ).then((res) => {
      try {
        dispatch({ type: SET_USER_ACCOUNT_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          `api/user/account`,
          '',
          '',
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({ type: SET_USER_ACCOUNT_LIST, payload: response.docs })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_ACCOUNT_LIST, loading: false, data: [] })
      }
    })
  }
}

export const primaryUserAccountListForUser = (
  currentDomain: ICurrentDomain,
  payload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePatchHttpCallAsync(
      dispatch,
      'api/user/account',
      payload,
      '',
      currentDomain.type,
      '',
      true,
      'application/x-www-form-urlencoded',
      true,
      'PATCH',
      'Primary Account Updated Successfull',
    ).then((res) => {
      try {
        dispatch({ type: SET_USER_ACCOUNT_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          `api/user/account`,
          '',
          '',
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({ type: SET_USER_ACCOUNT_LIST, payload: response.docs })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_ACCOUNT_LIST, loading: false, data: [] })
      }
    })
  }
}

export const getPaymentMethodForUser = (
  userWithdrawType: boolean,
  payload: any,
  currentDomain: ICurrentDomain,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)

    try {
      dispatch({ type: SET_USER_PAYMENT_LIST, data: [], loading: true })
      makeGetHttpCallAsync(
        dispatch,
        userWithdrawType
          ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
          : `api/${getCorrectUserType(userType)}/setting/depositType`,
        payload.size && payload,
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: SET_USER_PAYMENT_LIST,
            loading: false,
            payload: userWithdrawType ? response.docs : response.docs,
          })
        })
        .catch(async (error) => {
          let err: any = await error
        })
    } catch (error) {
      let err: any = await error
      dispatch({ type: SET_USER_PAYMENT_LIST, loading: false, data: [] })
    }
  }
}

export const addPaymentMethodForUser = (
  userWithdrawType: boolean,
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
  getPayload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)
    makePostHttpCallAsync(
      dispatch,
      userWithdrawType
        ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
        : `api/${getCorrectUserType(userType)}/setting/depositType`,
      currentDomain.type,
      JSON.stringify(payload),
      '',
      true,
      '',
      true,
      'Payment Method Added',
    ).then((res) => {
      cb()
      try {
        dispatch({ type: SET_USER_PAYMENT_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          userWithdrawType
            ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
            : `api/${getCorrectUserType(userType)}/setting/depositType`,
          getPayload.size && getPayload,
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_PAYMENT_LIST,
              loading: false,
              payload: userWithdrawType ? response.docs : response.docs,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_PAYMENT_LIST, loading: false, data: [] })
      }
    })
  }
}

export const updatePaymentMethodForUser = (
  userWithdrawType: boolean,
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
  getPayload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)
    makePutHttpCallAsync(
      dispatch,
      userWithdrawType
        ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
        : `api/${getCorrectUserType(userType)}/setting/depositType`,
      currentDomain.type,
      JSON.stringify(payload),
      '',
      true,
      '',
      true,
      'Payment Method Updated',
    ).then((res) => {
      cb()
      try {
        dispatch({ type: SET_USER_PAYMENT_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          userWithdrawType
            ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
            : `api/${getCorrectUserType(userType)}/setting/depositType`,
          getPayload,
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_PAYMENT_LIST,
              loading: false,
              payload: userWithdrawType ? response.docs : response.docs,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_PAYMENT_LIST, loading: false, data: [] })
      }
    })
  }
}

export const deletePaymentMethodForUser = (
  userWithdrawType: boolean,
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
  getPayload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)
    makeDeleteHttpCallAsync(
      dispatch,
      userWithdrawType
        ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
        : `api/${getCorrectUserType(userType)}/setting/depositType`,
      payload,
      currentDomain.type,
      '',
      true,
      '',
      true,
      'Payment Method Deleted',
    ).then((res) => {
      try {
        dispatch({ type: SET_USER_PAYMENT_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          userWithdrawType
            ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
            : `api/${getCorrectUserType(userType)}/setting/depositType`,
          getPayload,
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_PAYMENT_LIST,
              loading: false,
              payload: userWithdrawType ? response.docs : response.docs,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_PAYMENT_LIST, loading: false, data: [] })
      }
    })
  }
}

export const makePaymentMethodActiveForUser = (
  userWithdrawType: boolean,
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
  getPayload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)
    makePatchHttpCallAsync(
      dispatch,
      userWithdrawType
        ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
        : `api/${getCorrectUserType(userType)}/setting/depositType`,
      JSON.stringify(payload),
      '',
      currentDomain.type,
      '',
      true,
      '',
      true,
    ).then((res) => {
      try {
        cb()
        dispatch({ type: SET_USER_PAYMENT_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          userWithdrawType
            ? `api/${getCorrectUserType(userType)}/setting/paymentMethodType`
            : `api/${getCorrectUserType(userType)}/setting/depositType`,
          getPayload,
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_PAYMENT_LIST,
              loading: false,
              payload: userWithdrawType ? response.docs : response.docs,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_PAYMENT_LIST, loading: false, data: [] })
      }
    })
  }
}

export const getUserBonusList = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({
        type: SET_USER_BONUS_APPLIED_LIST,
        payload: [],
        loading: true,
      })
      makeGetHttpCallAsync(
        dispatch,
        'api/user/bonus',
        '',
        currentDomain.type,
        '',
        true,
      ).then((res: any) => {
        dispatch({
          type: SET_USER_BONUS_APPLIED_LIST,
          payload: res.docs,
          loading: false,
        })
      })
    } catch (error) {
      let err: any = await error
      dispatch({
        type: SET_USER_BONUS_APPLIED_LIST,
        payload: [],
        loading: false,
      })
    }
  }
}

export const getBonusListForUser = (
  currentDomain: ICurrentDomain,
  isUser?: boolean,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)
    try {
      dispatch({ type: SET_USER_BONUS_LIST, data: [], loading: true })
      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/setting/bonus`,
        'active=true',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: SET_USER_BONUS_LIST,
            loading: false,
            payload: isUser ? response.docs : response.docs,
          })
        })
        .catch(async (error) => {
          let err: any = await error
        })
    } catch (error) {
      let err: any = await error
      dispatch({ type: SET_USER_BONUS_LIST, loading: false, data: [] })
    }
  }
}

export const makeBonusListActive = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePatchHttpCallAsync(
      dispatch,
      `api/whiteLabel/setting/bonus`,
      JSON.stringify(payload),
      '',
      currentDomain.type,
      '',
      true,
      '',
      true,
    ).then((res) => {
      cb()
      try {
        dispatch({ type: SET_USER_BONUS_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          `api/whiteLabel/setting/bonus`,
          '',
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_BONUS_LIST,
              loading: false,
              payload: response.docs,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_BONUS_LIST, loading: false, data: [] })
      }
    })
  }
}

export const addBonusListForUser = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)
    makePostHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/setting/bonus`,
      currentDomain.type,
      JSON.stringify(payload),
      '',
      true,
      '',
      true,
      'Payment Method Added',
    ).then((res) => {
      cb()
      try {
        dispatch({ type: SET_USER_BONUS_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          `api/${getCorrectUserType(userType)}/setting/bonus`,
          '',
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_BONUS_LIST,
              loading: false,
              payload: response.docs,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_BONUS_LIST, loading: false, data: [] })
      }
    })
  }
}

export const updateBonusListForUser = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)

    makePutHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/setting/bonus`,
      currentDomain.type,
      JSON.stringify(payload),
      '',
      true,
      '',
      true,
      'Payment Method Updated',
    ).then((res) => {
      cb()
      try {
        dispatch({ type: SET_USER_BONUS_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          `api/${getCorrectUserType(userType)}/setting/bonus`,
          '',
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_BONUS_LIST,
              loading: false,
              payload: response.docs,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({ type: SET_USER_BONUS_LIST, loading: false, data: [] })
      }
    })
  }
}

export const deleteBonusListForUser = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = localStorage.getItem(HEADERS.userType)

    makeDeleteHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/setting/bonus`,
      payload,
      currentDomain.type,
      '',
      true,
      '',
      true,
      'Payment Method Deleted',
    ).then((res) => {
      try {
        dispatch({ type: SET_USER_BONUS_LIST, data: [], loading: true })
        makeGetHttpCallAsync(
          dispatch,
          `api/${getCorrectUserType(userType)}/setting/bonus`,
          '',
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            dispatch({
              type: SET_USER_BONUS_LIST,
              loading: false,
              payload: response.docs,
            })
          })
          .catch(async (error) => {})
      } catch (error) {
        dispatch({ type: SET_USER_BONUS_LIST, loading: false, data: [] })
      }
    })
  }
}

export function getMarketsHierarchy(data: any, eventId: any) {
  if (!data) {
    return []
  }
  let marketsHierarchy: any = []

  for (let index = 0; index < data.length; index++) {
    if (data[index].event.id === eventId) {
      marketsHierarchy.push(data[index])
      return marketsHierarchy
    }
  }

  return marketsHierarchy
}

export const getFavoriteEvent = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      const { ongoingMatches, upComingMatches } = getState().inPlayMatches

      if (currentDomain) {
        dispatch({ type: SET_USER_FAVORITE_EVENT_LIST, loading: true })
        let userType = localStorage.getItem(HEADERS.userType)
        userType === 'user' &&
          makeGetHttpCallAsync(
            dispatch,
            `api/user/favorite/event`,
            '',
            currentDomain.type,
            false,
            true,
            '',
          )
            .then((response: any) => {
              if (userType === 'user' && response.docs.length) {
                let eventIdsMap = new Map()
                eventIdsMap = mapEventIdsToTrue(response.docs)
                dispatch({
                  type: SET_USER_FAVORITE_EVENT_LIST,
                  loading: false,
                  payload: eventIdsMap,
                })

                let commonEvents = getCommonEvents(
                  ongoingMatches,
                  upComingMatches,
                  eventIdsMap,
                )
                dispatch({
                  type: GET_FAVORITE_LIST_EVENTS_NAMES,
                  payload: commonEvents,
                })

                dispatch({
                  type: SET_USER_FAVORITE_EVENT_LIST,
                  loading: false,
                  payload: eventIdsMap,
                })
              }
            })
            .catch(async (error) => {
              let err: any = await error
            })
      }
    } catch (error) {
      let err: any = await error
      dispatch({ type: SET_USER_FAVORITE_EVENT_LIST, loading: false })
    }
  }
}

export const addFavoriteEventList = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const favoriteListEventNames: any = getState().login.favoriteListEventNames
    const eventFavoriteList = getState().login.eventFavoriteList
    const { ongoingMatches, upComingMatches } = getState().inPlayMatches

    dispatch({
      type: SET_USER_FAVORITE_EVENT_LIST,
      payload: eventFavoriteList,
      loading: true,
    })
    makePostHttpCallAsync(
      dispatch,
      'api/user/favorite/event',
      currentDomain.type,
      payload,
      '',
      true,
      'application/x-www-form-urlencoded',
      true,
      'Favorite Event Added',
    ).then((res: any) => {
      cb()
      eventFavoriteList[res.docs.eventId] = true
      dispatch({
        type: SET_USER_FAVORITE_EVENT_LIST,
        loading: false,
        payload: eventFavoriteList,
      })

      let commonEvents = getCommonEvents(
        ongoingMatches,
        upComingMatches,
        eventFavoriteList,
      )
      dispatch({
        type: GET_FAVORITE_LIST_EVENTS_NAMES,
        payload: commonEvents,
      })

      dispatch({
        type: SET_USER_FAVORITE_EVENT_LIST,
        loading: false,
        payload: eventFavoriteList,
      })
    })
  }
}

export const deleteFavoriteEvent = (
  currentDomain: ICurrentDomain,
  payload: any,
  eventId: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const { ongoingMatches, upComingMatches } = getState().inPlayMatches
    makeDeleteHttpCallAsync(
      dispatch,
      'api/user/favorite/event',
      payload,
      currentDomain.type,
      '',
      true,
      'application/x-www-form-urlencoded',
      true,
      'Favorite Event Deleted',
    ).then((res) => {
      let fav = getState().login.eventFavoriteList
      try {
        let deleted = deleteEventIdFromMap(fav, eventId)
        dispatch({
          type: SET_USER_FAVORITE_EVENT_LIST,
          payload: fav,
          loading: true,
        })

        let commonEvents = getCommonEvents(
          ongoingMatches,
          upComingMatches,
          deleted,
        )
        dispatch({
          type: GET_FAVORITE_LIST_EVENTS_NAMES,
          payload: commonEvents,
        })

        dispatch({
          type: SET_USER_FAVORITE_EVENT_LIST,
          loading: false,
          payload: deleted,
        })
      } catch (error) {
        dispatch({
          type: SET_USER_FAVORITE_EVENT_LIST,
          payload: fav,
          loading: false,
        })
      }
    })
  }
}

export const getFavoriteMarket = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: SET_USER_FAVORITE_MARKET_LIST, loading: true })
      makeGetHttpCallAsync(
        dispatch,
        `api/user/favorite/market`,
        '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: SET_USER_FAVORITE_MARKET_LIST,
            loading: false,
            payload: response.docs.map((a: any) => a.marketId),
          })
        })
        .catch(async (error) => {
          let err: any = await error
        })
    } catch (error) {
      let err: any = await error
      dispatch({ type: SET_USER_FAVORITE_MARKET_LIST, loading: false })
    }
  }
}

export const addFavoriteMarketList = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePostHttpCallAsync(
      dispatch,
      'api/user/favorite/market',
      currentDomain.type,
      payload,
      '',
      true,
      'application/x-www-form-urlencoded',
      true,
      'Favorite Market Added',
    ).then((res) => {
      cb()
      try {
        dispatch({
          type: SET_USER_FAVORITE_MARKET_LIST,
          payload: getState().login.marketFavoriteList,
          loading: true,
        })
        makeGetHttpCallAsync(
          dispatch,
          `api/user/favorite/market`,
          '',
          currentDomain.type,
          false,
          true,
          '',
        )
          .then((response: any) => {
            const map = response.docs.map((a: any) => a.marketId)
            dispatch({
              type: SET_USER_FAVORITE_MARKET_LIST,
              loading: false,
              payload: map,
            })
          })
          .catch(async (error) => {
            let err: any = await error
          })
      } catch (error) {
        dispatch({
          type: SET_USER_FAVORITE_MARKET_LIST,
          payload: getState().login.marketFavoriteList,
          loading: false,
        })
      }
    })
  }
}

export const deleteFavoriteMarket = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeDeleteHttpCallAsync(
      dispatch,
      'api/user/favorite/market',
      payload,
      currentDomain.type,
      '',
      true,
      'application/x-www-form-urlencoded',
      true,
      'Favorite Market Deleted',
    ).then((res) => {
      try {
        dispatch({
          type: SET_USER_FAVORITE_MARKET_LIST,
          payload: getState().login.marketFavoriteList,
          loading: true,
        })
        makeGetHttpCallAsync(
          dispatch,
          `api/user/favorite/market`,
          '',
          '',
          false,
          true,
          '',
        )
          .then((response: any) => {
            const map = response.docs.map((a: any) => a.marketId)
            dispatch({
              type: SET_USER_FAVORITE_MARKET_LIST,
              loading: false,
              payload: map,
            })
          })
          .catch(async (error) => {})
      } catch (error) {
        dispatch({
          type: SET_USER_FAVORITE_MARKET_LIST,
          payload: getState().login.marketFavoriteList,
          loading: false,
        })
      }
    })
  }
}

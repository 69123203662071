import { Fragment, useEffect, useState } from 'react'
import { ICurrentBet } from '../../../Game/betslip/NewBetSlip'
import { IManualMarketGame } from './ManualSessionMarket'
import { toast } from 'react-toastify'
import { FaRegStar } from 'react-icons/fa'
import {
  emptyFunction,
  formatVolumeNumber,
  get2Decimal,
  getAssetUrl,
} from '../../../../utils'
import { useMediaQuery } from '@mui/material'
import {
  getCalculatedBetDetail,
  getCurrentBetDetail,
  getTeamsValue,
} from '../../../../utils/internalUtils'
import { ITeam } from '../../../../types/interfaces/exchangeMarketStatus'
import { IMarket } from '../../../../types/interfaces/exchangeMatch'
import { BET_TYPE } from '../../../../types/enums/betType'
import { MARKET_TYPE } from '../../../../types/enums/gameType'
import { ICurrentDomain } from '../../../../types/interfaces/currentDomain'
import { transformNumber } from '../../../../utils/gameUtils/bookmaker'
import { useDispatch, useSelector } from 'react-redux'
import { BET_SLIP_STAKE } from '../../../../redux/actions/userAction/betSlipAction'
import { Remarks } from './Remarks'
import { getMarketStatus } from '../../../../utils/gameUtils/gameUtil'

interface IManualBookMakerMarketProps extends IManualMarketGame {
  team: ITeam[]
  isLogin: boolean
  currentBet: ICurrentBet
  hashMapMarket: any
  betSlipStake: number
  tempMarket: any
  isDrawerOpen: boolean
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
  onMarketClick: (market: ICurrentBet) => void
}

// Manual bookMaker Market component
export const ManualBookMakerMarket = (props: IManualBookMakerMarketProps) => {
  const { isBetSlipLoading } = useSelector((state: any) => state.betSlip)

  return props.tempMarket.teams && props.tempMarket.teams.length ? (
    <Fragment key={props._id}>
      {props.tempMarket.teams
        .slice()
        .sort((a: any, b: any) => parseInt(a.sort) - parseInt(b.sort))
        .map((team: any, index: any) => (
          <ManualBookMakerMarketItem
            isBetSlipLoading={isBetSlipLoading}
            betSlipStake={props.betSlipStake}
            isDrawerOpen={props.isDrawerOpen}
            setIsDrawerOpen={props.setIsDrawerOpen}
            tempMarket={props.tempMarket}
            hashMapMarket={props.hashMapMarket}
            index={index}
            currentBet={props.currentBet}
            isLogin={props.isLogin}
            key={team.id}
            marketStatus={team.status}
            marketId={props.marketId}
            onMarketClick={props.onMarketClick}
            teamItem={team}
          />
        ))}
      <Remarks remarksMessage={props.marketId.remarksMessage} />
    </Fragment>
  ) : (
    <> No Teams Available </>
  )
}

interface IManualBookMakerMarketItemProps {
  teamItem: ITeam
  betSlipStake: number
  index: number
  marketStatus: any
  isLogin: boolean
  currentBet: ICurrentBet
  marketId: IMarket
  isBetSlipLoading: boolean
  hashMapMarket: any
  tempMarket: any
  isDrawerOpen: boolean
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
  onMarketClick: (market: ICurrentBet) => void
}

export const ManualBookMakerMarketItem = (
  props: IManualBookMakerMarketItemProps,
) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const isTabletScreen = useMediaQuery('(max-width:900px)')
  let userData = useSelector((state: any) => state.login.userData)

  const getRenderMarketStatus = () => {
    if (
      !props.marketId.betAllow ||
      props.teamItem.status !== 'open' ||
      props.marketStatus === 'closed' ||
      props.marketStatus !== 'open' ||
      props.teamItem.status !== 'open'
    ) {
      return 'SUSPENDED'
    }

    return props.teamItem.status.toUpperCase()
  }

  const [boxNoColor, setBoxNoColor] = useState(false)
  const [boxYesColor, setBoxYesColor] = useState(false)
  const [bookMakerAmoount, setBookMakerAmount] = useState(
    getTeamsValue(props.hashMapMarket, props.marketId, props.teamItem.id),
  )
  const [currentBetDetail, setCurrentBetDetail] = useState(
    getCurrentBetDetail(
      props.currentBet,
      props.teamItem,
      props.marketId,
      props.betSlipStake,
    ),
  )

  useEffect(() => {
    setBoxNoColor(true)
    setTimeout(() => {
      setBoxNoColor(false)
    }, 500)
  }, [
    props.teamItem.back.length && props.teamItem.back[0].odd,
    props.teamItem.back.length && props.teamItem.back[0].run,
  ])

  useEffect(() => {
    setBoxYesColor(true)
    setTimeout(() => {
      setBoxYesColor(false)
    }, 500)
  }, [
    (props.teamItem.back.length && props.teamItem.back[0].odd) ||
      (props.teamItem.back.length && props.teamItem.back[0].run),
  ])

  const onPlaceOrder = (
    type: BET_TYPE,
    odd: number,
    run: number,
    teamId: string,
  ) => {
    if (!props.isLogin) {
      toast.error(_getLocalString('LOC_FIRST_TO_CONTINUE'))
    }

    if (props.isBetSlipLoading) {
      return toast.error('Your Current Bet is Not Finished Yet!')
    }

    if (odd == 0) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    let marketCurrentStatus = getMarketStatus(
      props.marketId,
      props.hashMapMarket,
    )
    isTabletScreen && props.setIsDrawerOpen(true)
    props.onMarketClick({
      externalType: props.marketId.externalType,
      marketCurrentStatus: marketCurrentStatus,
      manual: props.marketId.manual,
      teamName: props.teamItem.name,
      marketId: props.marketId,
      teamId: teamId,
      marketName: props.marketId.name,
      marketType: MARKET_TYPE.BOOKMAKER,
      oddType: type,
      odd: odd,
      run: run.toString(),
      delaySecond:
        (userData.betDelay || 0) + (props.tempMarket.delaySecond || 0),
      max: props.tempMarket.max,
      min: props.tempMarket.min,
    })
  }

  useEffect(() => {
    setBookMakerAmount(
      getTeamsValue(props.hashMapMarket, props.marketId, props.teamItem.id),
    )
  }, [props.hashMapMarket, props.teamItem, props.marketId])

  useEffect(() => {
    setCurrentBetDetail(
      getCurrentBetDetail(
        props.currentBet,
        props.teamItem,
        props.marketId,
        props.betSlipStake,
      ),
    )
  }, [props.currentBet, props.betSlipStake])

  const customStyle = {
    width: isSmallScreen ? '90%' : '',
  }

  const isSmallName = useMediaQuery('(max-width:600px)')
  return (
    <>
      <div
        key={props.index}
        className='flex w-full mb-[2px] sm:mb-[3px] border border-gray-100 rounded-none shadow-md dark:border-0 align-item-center  '
      >
        <div
          className='flex py-2 dark:gradient-border-reverse xs:w-[68%] sm:w-[68%] md:w-[68%] lg:w-[57%] xl:w-[68%] pl-4 dark:text-white text-black dark:bg-[linear-gradient(173.49deg,var(--dropdown-second-color-light)_5.4%,var(--dropdown-first-color-light)_94.88%)] mr-1 font-dm_sans h-[46px] xs:text-[12px] sm:text-[15px] font-normal leading-[17px] tracking-normal xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]'
          style={customStyle}
        >
          <div className='flex align-center'>
            <div className='whitespace-nowrap xs:text-[12px] sm:text-[15px] mr-2'>
              {props.teamItem &&
              props.teamItem.name &&
              props.teamItem.name.length > 20 &&
              isSmallName
                ? props.teamItem.name.slice(0, 20) + '...'
                : props.teamItem && props.teamItem.name && props.teamItem.name}
            </div>
            <div
              style={{
                color: `${bookMakerAmoount < 0 ? 'red' : 'green'}`,
                fontSize: '0.8rem',
              }}
              className=' '
            >
              {bookMakerAmoount && (
                <button className='px-1 py-1.5 rounded-[3px] border border-gray-300 bg-white text-xs font-bold'>
                  {get2Decimal(bookMakerAmoount)}
                </button>
              )}
            </div>
          </div>
          {props.currentBet &&
            props.currentBet.marketId &&
            props.currentBet.marketId.id === props.marketId.id && (
              <>
                <div
                  className='ml-auto mr-2 px-1 py-1.5 rounded-[3px] border  text-xs font-bold'
                  style={{
                    textAlign: 'right',
                    borderColor: `${
                      get2Decimal(
                        getCalculatedBetDetail(
                          props.currentBet,
                          bookMakerAmoount,
                          currentBetDetail,
                        ),
                      )
                        ? 'white'
                        : 'transparent'
                    }`,
                    background: `${
                      get2Decimal(
                        getCalculatedBetDetail(
                          props.currentBet,
                          bookMakerAmoount,
                          currentBetDetail,
                        ),
                      )
                        ? 'white'
                        : 'transparent'
                    }`,
                    color: `${
                      get2Decimal(
                        getCalculatedBetDetail(
                          props.currentBet,
                          bookMakerAmoount,
                          currentBetDetail,
                        ),
                      )! < 0
                        ? 'red'
                        : 'green'
                    }`,
                    fontSize: '0.8rem',
                  }}
                >
                  {get2Decimal(
                    getCalculatedBetDetail(
                      props.currentBet,
                      bookMakerAmoount,
                      currentBetDetail,
                    ),
                  )}
                </div>
              </>
            )}
        </div>

        {(props.teamItem.status !== 'active' &&
          props.teamItem.status !== 'open') ||
        !props.marketId.betAllow ||
        (props.teamItem.lay.length &&
          props.teamItem.lay[0].odd === 0 &&
          props.teamItem.lay.length &&
          props.teamItem.lay[0].run === 0 &&
          props.teamItem.back.length &&
          props.teamItem.back[0].odd === 0 &&
          props.teamItem.back.length &&
          props.teamItem.back[0].run === 0) ? (
          <>
            <div className=' relative xs:w-[43.5%] sm:w-[43.5%] md:w-[43.5%] w-[43.5%]'>
              <div className='flex brightness-50 xl:ml-[1px]'>
                <div
                  style={{
                    background: `${boxYesColor ? '#FFC107' : '#72BBEF'}`,
                  }}
                  className={`text-transparent sm:block hidden cursor-pointer w-[30%] ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  <div> - </div>
                </div>

                <div
                  style={{
                    background: `${boxYesColor ? '#FFC107' : '#72BBEF'}`,
                  }}
                  className={`text-transparent cursor-pointer sm:w-[30%] xs:w-[80%] ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  <div> - </div>
                </div>

                <div
                  onClick={() =>
                    props.teamItem.back.length &&
                    props.teamItem.back[0].odd != 0 &&
                    onPlaceOrder(
                      BET_TYPE.BACK,
                      props.teamItem.back[0].odd,
                      props.teamItem.back[0].run,
                      props.teamItem.id,
                    )
                  }
                  style={{
                    background: `${boxYesColor ? '#FFC107' : '#72BBEF'}`,
                  }}
                  className={`text-transparent sm:block hidden cursor-pointer w-[30%] ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  {props.teamItem.back.length &&
                  props.teamItem.back[0].odd === 0 &&
                  props.teamItem.back.length &&
                  props.teamItem.back[0].run === 0 ? (
                    <div> - </div>
                  ) : (
                    <>
                      <div className='text-sm ' style={{ fontWeight: '900' }}>
                        {props.teamItem.back.length &&
                          props.teamItem.back[0].odd}
                      </div>
                      <div className='font-dm_sans text-[10px] font-normal leading-[20px] tracking-normal text-center'>
                        {props.teamItem.back.length &&
                          formatVolumeNumber(props.teamItem.back[0].run)}
                      </div>
                    </>
                  )}
                </div>

                <div
                  onClick={() =>
                    props.teamItem.lay.length &&
                    props.teamItem.lay[0].odd != 0 &&
                    onPlaceOrder(
                      BET_TYPE.LAY,
                      props.teamItem.lay[0].odd,
                      props.teamItem.lay[0].run,
                      props.teamItem.id,
                    )
                  }
                  style={{
                    background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}`,
                  }}
                  className={`text-transparent sm:block hidden cursor-pointer w-[30%] ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  {props.teamItem.lay.length &&
                  props.teamItem.lay[0].odd === 0 &&
                  props.teamItem.lay.length &&
                  props.teamItem.lay[0].run === 0 ? (
                    <div> - </div>
                  ) : (
                    <>
                      <div className='text-sm ' style={{ fontWeight: '900' }}>
                        {props.teamItem.lay.length && props.teamItem.lay[0].odd}
                      </div>
                      <div className='font-dm_sans text-[10px] font-normal leading-[20px] tracking-normal text-center'>
                        {props.teamItem.lay.length &&
                          formatVolumeNumber(props.teamItem.lay[0].run)}
                      </div>
                    </>
                  )}
                </div>

                <div
                  style={{
                    background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}`,
                  }}
                  className={`text-transparent sm:w-[30%] xs:w-[80%] cursor-pointer w-[30%] ml-[2px] border-white  text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  <div> - </div>
                </div>

                <div
                  style={{
                    background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}`,
                  }}
                  className={`text-transparent cursor-pointer w-[30%]  sm:block hidden ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
                >
                  <div> - </div>
                </div>
              </div>

              <div className='absolute z-30 top-0 left-0 right-0 bottom-0 flex items-center justify-center xs:text-[10px] sm:text-[12px] md:text-[16px] font-extrabold text-[#ff9a9a]'>
                {getRenderMarketStatus()}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{ background: `${boxYesColor ? '#FFC107' : '#72BBEF'}` }}
              className={`sm:block hidden cursor-pointer w-[7%] ml-[2px] xl:ml-[4px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div> - </div>
            </div>

            <div
              style={{ background: `${boxYesColor ? '#FFC107' : '#72BBEF'}` }}
              className={`sm:block hidden  cursor-pointer  sm:w-[7%] w-[15.7%]  ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div> - </div>
            </div>

            <div
              onClick={() =>
                props.teamItem.back.length &&
                props.teamItem.back[0].odd != 0 &&
                onPlaceOrder(
                  BET_TYPE.BACK,
                  props.teamItem.back[0].odd,
                  props.teamItem.back[0].run,
                  props.teamItem.id,
                )
              }
              style={{ background: boxYesColor ? '#FFC107' : '#72BBEF' }}
              className={`cursor-pointer sm:w-[7%] xs:w-[21%] w-[15.7%] ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              {props.teamItem.back.length &&
              props.teamItem.back[0].odd === 0 &&
              props.teamItem.back.length &&
              props.teamItem.back[0].run === 0 ? (
                <div> - </div>
              ) : (
                <>
                  <div className='text-sm ' style={{ fontWeight: '900' }}>
                    {props.teamItem.back.length && props.teamItem.back[0].odd}
                  </div>
                  <div className='font-dm_sans text-[10px] font-normal leading-[20px] tracking-normal text-center'>
                    {props.teamItem.back.length &&
                      formatVolumeNumber(props.teamItem.back[0].run)}
                  </div>
                </>
              )}
            </div>

            <div
              onClick={() =>
                props.teamItem.lay.length &&
                props.teamItem.lay[0].odd != 0 &&
                onPlaceOrder(
                  BET_TYPE.LAY,
                  props.teamItem.lay[0].odd,
                  props.teamItem.lay[0].run,
                  props.teamItem.id,
                )
              }
              style={{ background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}` }}
              className={` cursor-pointer sm:w-[7%] xs:w-[21%] w-[15.7%]  ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              {props.teamItem.lay.length &&
              props.teamItem.lay[0].odd === 0 &&
              props.teamItem.lay.length &&
              props.teamItem.lay[0].run === 0 ? (
                <div> - </div>
              ) : (
                <>
                  <div className='text-sm ' style={{ fontWeight: '900' }}>
                    {props.teamItem.lay.length && props.teamItem.lay[0].odd}
                  </div>
                  <div className='font-dm_sans text-[10px] font-normal leading-[20px] tracking-normal text-center'>
                    {props.teamItem.lay.length &&
                      formatVolumeNumber(props.teamItem.lay[0].run)}
                  </div>
                </>
              )}
            </div>
            <div
              style={{ background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}` }}
              className={` cursor-pointer w-[7%] ml-[2px] border-white sm:block hidden text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div> - </div>
            </div>
            <div
              style={{ background: `${boxNoColor ? '#FFC107' : '#FAA9BA'}` }}
              className={`sm:block hidden  cursor-pointer sm:w-[7%] w-[15.7%]  ml-[2px] border-white text-center text-black rounded-none xs:py-[2px] xl:py-1.5 xs:h-[34px] sm:h-[40px] md:h-[40px] lg:h-[40px] xl:h-[46px]`}
            >
              <div> - </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export const ManualBookMakerMarketHeader = (
  props: IManualBookMakerMarketProps & {
    isCashout: boolean
    marketFavoriteList?: any
    addFavoriteMarketList?: (
      currentDomain: ICurrentDomain,
      payload: any,
      cb: any,
    ) => void
    deleteFavoriteMarket?: (
      currentDomain: ICurrentDomain,
      payload: any,
      cb: any,
    ) => void
  },
) => {
  const { isBetSlipLoading } = useSelector((state: any) => state.betSlip)
  const dispatch = useDispatch()
  const handleFavoriteClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    var urlencoded = new URLSearchParams()

    if (!props.marketFavoriteList) {
      urlencoded.append('marketId', props.marketId._id)
      return props.addFavoriteMarketList(
        props.currentDomain,
        urlencoded,
        emptyFunction,
      )
    }

    if (props.marketFavoriteList.includes(props.marketId._id)) {
      urlencoded.append('marketId', props.marketId._id)
      props.deleteFavoriteMarket(props.currentDomain, urlencoded, emptyFunction)
    } else {
      urlencoded.append('marketId', props.marketId._id)
      props.addFavoriteMarketList(
        props.currentDomain,
        urlencoded,
        emptyFunction,
      )
    }
  }

  const isTabletScreen = useMediaQuery('(max-width:1200px)')
  let userData = useSelector((state: any) => state.login.userData)

  const onPlaceOrder = (
    type: BET_TYPE,
    odd: number,
    run: number,
    teamId: string,
    teamName: string,
  ) => {
    if (!props.isLogin) {
      toast.error(_getLocalString('LOC_FIRST_TO_CONTINUE'))
    }

    if (isBetSlipLoading) {
      return toast.error('Your Current Bet is Not Finished Yet!')
    }

    if (odd == 0) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    let marketCurrentStatus = getMarketStatus(
      props.marketId,
      props.hashMapMarket,
    )

    isTabletScreen && props.setIsDrawerOpen(true)
    props.onMarketClick({
      marketCurrentStatus: marketCurrentStatus,
      externalType: props.marketId.externalType,
      manual: props.marketId.manual,
      teamName: teamName,
      marketId: props.marketId,
      teamId: teamId,
      marketName: props.marketId.name,
      marketType: MARKET_TYPE.BOOKMAKER,
      oddType: type,
      odd: odd,
      run: run,
      delaySecond: (userData.betDelay || 0) + props.tempMarket.delaySecond || 0,
      max: props.tempMarket.max,
      min: props.tempMarket.min,
    })
  }

  const cashout = () => {
    let currentTeams = props.tempMarket.teams
    if (!props.hashMapMarket) {
      return toast.error(_getLocalString('LOC_PLACE_FIRST_TO_CONTINUE'))
    }

    let profitLiabilityValue = props.hashMapMarket[props.marketId.id]

    if (!Object.keys(profitLiabilityValue).length) {
      return toast.error(_getLocalString('LOC_PLACE_FIRST_TO_CONTINUE'))
    }

    let teamWhichHaveFavOdds: any = {}
    let teamWhichHavePositiveValue: any = {}
    let teamWhichHaveNegativeValue: any = {}

    if (
      currentTeams[0].status === 'suspended' &&
      currentTeams[1].status === 'suspended'
    ) {
      return toast.error(_getLocalString('LOC_CASHOUT_ELIGIBLE'))
    } else if (currentTeams[0].status === 'suspended') {
      teamWhichHaveFavOdds = currentTeams[1]
    } else if (currentTeams[1].status === 'suspended') {
      teamWhichHaveFavOdds = currentTeams[0]
    } else if (
      transformNumber(currentTeams[0].back[0].odd) <=
      transformNumber(currentTeams[1].back[0].odd)
    ) {
      teamWhichHaveFavOdds = currentTeams[0]
    } else {
      teamWhichHaveFavOdds = currentTeams[1]
    }

    let firstValueIncremented =
      profitLiabilityValue[0].profit > profitLiabilityValue[1].profit
    teamWhichHavePositiveValue =
      profitLiabilityValue[firstValueIncremented ? 0 : 1]
    teamWhichHaveNegativeValue =
      profitLiabilityValue[!firstValueIncremented ? 0 : 1]

    if (
      Math.abs(profitLiabilityValue[0].profit) ===
      Math.abs(profitLiabilityValue[1].profit)
    ) {
      return toast.error(_getLocalString('LOC_CASHOUT_ELIGIBLE'))
    }

    let amount = 0
    let amountOtherSide = 0
    let Ob = 0
    let Ol = 0
    let run = 0
    amountOtherSide = teamWhichHavePositiveValue.profit
    if (teamWhichHaveFavOdds.id === teamWhichHavePositiveValue.teamId) {
      // lay
      Ob =
        !teamWhichHavePositiveValue.profit || !teamWhichHaveNegativeValue.profit
          ? 1
          : Math.abs(teamWhichHaveNegativeValue.profit) /
              teamWhichHavePositiveValue.profit +
            1
      Ol = transformNumber(teamWhichHaveFavOdds.lay[0].odd)
      run = teamWhichHaveFavOdds.lay[0].run
      amount =
        (Ob / Ol) *
        (amountOtherSide === 0
          ? Math.abs(teamWhichHaveNegativeValue.profit)
          : amountOtherSide)

      onPlaceOrder(
        BET_TYPE.LAY,
        teamWhichHaveFavOdds.lay[0].odd,
        run,
        teamWhichHaveFavOdds.id,
        teamWhichHaveFavOdds.name,
      )
    } else {
      // back
      Ob = transformNumber(teamWhichHaveFavOdds.back[0].odd)
      Ol =
        !teamWhichHavePositiveValue.profit || !teamWhichHaveNegativeValue.profit
          ? 1
          : Math.abs(teamWhichHaveNegativeValue.profit) /
              teamWhichHavePositiveValue.profit +
            1
      run = teamWhichHaveFavOdds.back[0].run
      amount =
        (Ol / Ob) *
        (amountOtherSide === 0
          ? Math.abs(teamWhichHaveNegativeValue.profit)
          : amountOtherSide)
      onPlaceOrder(
        BET_TYPE.BACK,
        teamWhichHaveFavOdds.back[0].odd,
        run,
        teamWhichHaveFavOdds.id,
        teamWhichHaveFavOdds.name,
      )
    }

    dispatch({
      type: BET_SLIP_STAKE,
      betSlipStake: amount,
    })
  }

  const isSmallScreenBack = useMediaQuery('(max-width:400px)')

  return (
    <div className='flex justify-between w-full mt-2 '>
      <div
        className={`flex justify-between  mt-2 ${!isSmallScreenBack ? 'sm:w-[66%] w-[65%]' : 'w-full'}`}
      >
        <div className='flex items-center'>
          <div
            className='flex cursor-pointer md:block hover-div'
            onClick={handleFavoriteClick}
          >
            <div className='md:mr-4 items-center order-2 dark:text-white text-black px-2 overflow-hidden !text-sm font-bold leading-5 md:text-base md:order-1 md:px-0 md:border-0 md:font-medium sm:max-w-full max-w-[130px] sm:whitespace-nowrap'>
              {!!props.marketFavoriteList &&
              props.marketFavoriteList.includes(props.marketId._id) ? (
                <img
                  src={getAssetUrl('header/favourite.webp')}
                  style={{
                    filter: 'drop-shadow(0px 0px 0.5px black)',
                    width: '1rem',
                    height: '1rem',
                  }}
                  alt='Favorite'
                />
              ) : (
                <FaRegStar style={{ width: '1rem', height: '1rem' }} />
              )}
            </div>
          </div>

          <div className='flex items-center justify-between xs:mx-2'>
            <div className='dark:text-white text-black xl:!text-lg md:text-base sm:text-lg text-sm font-bold '>
              {props.name}
            </div>
          </div>
        </div>
        <div
          onClick={cashout}
          className='flex items-center xl:gap-7 lg:gap-3 md:gap-3'
        >
          {/* {props.isCashout && ( */}
          {props.tempMarket?.teams && props.tempMarket?.teams?.length <= 2 && (
            <div className='cursor-pointer xl:text-[13px] md:text-[11px] sm:text-[13px] text-[10px] font-medium  whitespace-nowrap sm:py-1 py-0 xl:px-5 md:px-3 sm:px-5 px-3   rounded-full bg-[#CC7603]'>
              CASH OUT
            </div>
          )}
          {/* )} */}
        </div>

        <div className='whitespace-nowrap flex justify-end ml-auto pl-2 pr-2'>
          <div>
            <div className='whitespace-nowrap xs:text-[9px] sm:!text-xs xs:font-bold sm:font-bold  md:font-normal  dark:text-white text-black'>
              Min: {props.tempMarket.min}
            </div>
            <div className='whitespace-nowrap xs:text-[9px] sm:!text-xs xs:font-bold sm:font-bold  md:font-normal  dark:text-white text-black'>
              Max: {props.tempMarket.max}
            </div>
          </div>
        </div>
      </div>

      {!isSmallScreenBack && (
        <>
          <div className='flex justify-center items-center mb-[3px] w-[32%] sm:w-[41.6%] lg:w-[50%] xl:w-[41.5%]'>
            <div className='xl:w-[calc(100%/5-2px)] lg:w-[calc(100%/5-2px)] md:w-[calc(100%/4-2px)] sm:w-[calc(100%/5-2px)] w-[calc(100%/2-2px)] text-[17px] font-medium  xs:h-[35px] sm:h-[41px] md:h-[41px] lg:h-[42px] xl:h-[47px] xs:ml-[2px] sm:ml-[1px] md:ml-[1px] lg:ml-[2px] xl:ml-[2px] border-white text-center text-black dark:bg-[#72BBEF] bg-transparent rounded-none flex justify-center items-center'>
              Back
            </div>
            <div className='xl:w-[calc(100%/5-2px)] lg:w-[calc(100%/5-2px)] md:w-[calc(100%/4-2px)] sm:w-[calc(100%/5-2px)] w-[calc(100%/2-2px)] text-[17px] font-medium xs:h-[35px] sm:h-[41px] md:h-[41px] lg:h-[42px] xl:h-[47px] ml-[2px] border-white text-center text-black bg-transparent dark:bg-[#FAA9BA] rounded-none flex justify-center items-center'>
              Lay
            </div>
          </div>
        </>
      )}
    </div>
  )
}

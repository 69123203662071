import { useMediaQuery } from '@mui/material'
import { FC, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  APP_CONFIGURATION_SELECTED_INNER_BOX,
  APP_CONFIGURATION_SUB_PROVIDER_TYPE,
} from '../../../../redux'
import { getRandomNumber } from '../../../../utils'
import CustomImage from '../../../../component/customImage'

export interface ISingleCasino {
  isDarkMode?: boolean
  casinoData: any
  liveCasino?: boolean
  toggleAll?: any
  isPageVisible?: boolean
  activeNotShow?: boolean
  isCasinoPage?: boolean
}

export const CasinoTile: FC<ISingleCasino> = (props: ISingleCasino) => {
  const { casinoData, liveCasino } = props
  const isSmallScreen = useMediaQuery('(max-width:430px)')

  const dispatch = useDispatch()
  const randomNumber = useMemo(() => getRandomNumber(), [])

  return (
    <div className='relative pb-2 mb-2 flex justify-center items-center w-full h-full'>
      <div
        className={`dark:shadow-none shadow-[0px_0px_15px_0px_#b4b4b461] sm:m-3 rounded-lg ${props.isCasinoPage ? '' : 'xs:p-1'} sm:p-0.5 cursor-pointer relative bg-white 
      dark:bg-[linear-gradient(173deg,_var(--fastest-odd-fastest-odd-1)_5.4%,_var(--fastest-odd-fastest-odd-2)_94.88%)]
      dark:bg-[color:transparent]
      border border-solid border-[rgba(172,22,49,0.48)] !m-2 sm:!m-3 w-full h-full
      ${liveCasino ? (isSmallScreen ? '' : '') : ''}
      `}
        style={{ border: 'solid 1px rgba(172,22,49,0.48)' }}
        onClick={() => {
          dispatch({
            type: APP_CONFIGURATION_SUB_PROVIDER_TYPE,
            payload: casinoData.upperBox,
          })
          dispatch({
            type: APP_CONFIGURATION_SELECTED_INNER_BOX,
            payload: casinoData.innerBox,
          })
          props.toggleAll()
        }}
      >
        {/* <img
          alt={casinoData.gameName}
          src={props.isPageVisible ? casinoData.urlThumb : casinoData.image}
          className={`w-full h-full rounded-lg`}
        /> */}
        <CustomImage
          alt={casinoData.gameName}
          src={props.isPageVisible ? casinoData.urlThumb : casinoData.image}
        />
        <div
          className={`text-white bg-[#130002DE] absolute bottom-4 sm:bottom-10 left-0 font-bebas border-y border-[#ffffff7a] xs:text-md sm:text-xl md:text-xl lg:text-xl xl:text-2xl tracking-wider text-center  w-full p-1 sm:p-4 overflow-hidden whitespace-nowrap text-ellipsis ${liveCasino ? 'opacity-0 hover:opacity-100 transition-opacity duration-300' : 'opacity-100'}`}
        >
          {casinoData.gameName}
        </div>
      </div>
      {props.activeNotShow && (
        <div className='absolute w-full bottom-[-8px] text-center dark:text-white text-black'>
          <div
            className='flex'
            style={{ placeContent: 'center', alignItems: 'center' }}
          >
            <div className='blinking-dot' />
            <div
              className='ml-2 sm:text-[10px] md:text-[14px] xs:text-[10px] bold'
              style={{ color: '#31bc31' }}
            >
              {randomNumber} Active Players
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

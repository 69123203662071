import { FC } from 'react'
import { CasinoTile } from './casinoTile'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'

// import required modules
import {
  Navigation,
  Pagination as SwipperPagination,
  FreeMode,
  Autoplay,
} from 'swiper/modules'
import { SwiperOptions } from 'swiper/types/swiper-options'
import SwiperCore from 'swiper'

SwiperCore.use([FreeMode, Navigation])

import { useNavigate } from 'react-router-dom'
import { IThemeConfiguration } from '../../../../types/interfaces/themeConfiguration'
import CommonSwiper from '../../../../component/commonSwiper'

export interface ICasino {
  title?: string
  icon?: string
  isPageVisible?: boolean
  isDarkMode?: boolean
  isCasino?: boolean
  themeConfiguration?: IThemeConfiguration
  casinoData: any
  sliderNo: number
}

export const Casino: FC<ICasino> = (props: ICasino) => {
  const navigate = useNavigate()

  const toggleAll = () => {
    navigate(`${props.isCasino ? 'live-casino' : 'virtual-games'}`)
  }

  const swiperModules = [FreeMode, Navigation]
  const swiperOptions: SwiperOptions = {
    freeMode: true,
    wrapperClass: 'items-center ',

    breakpoints: {
      0: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        direction: 'horizontal',
      },
      600: {
        slidesPerView: 'auto',
        direction: 'horizontal',
        spaceBetween: 40,
      },
      900: {
        slidesPerView: 'auto',
        direction: 'horizontal',
        spaceBetween: 30,
      },
    },
    navigation: {
      prevEl: `.navigate_prev_${props.sliderNo}`,
      nextEl: `.navigate_next_${props.sliderNo}`,
    },
  }

  return (
    <div className='xs:pt-8 sm:pt-8 md:pt-14'>
      {/* --------------------Div 1----------------- */}
      <div className='flex items-center justify-between '>
        <div className='xs:w-full sm:w-full'>
          <div
            style={{ lineHeight: 'normal' }}
            className='sm:mb-2 mb-0 gradient_text pt-1 lg:text-[52px] md:text-4xl sm:text-3xl  text-[24px] font-bebas uppercase  font-extrabold  tracking-normal text-center md:text-left'
          >
            {props.title}
          </div>
          <div
            style={{ lineHeight: 'normal' }}
            className='mb-3 text-[14px] font-normal tracking-wider text-black uppercase dark:text-white font-bebas  md:text-2xl sm:text-xl lg:text-[34px] text-center md:text-left'
          >
            {_getLocalString('LOC_BET_NOW_ON_MULTIPLE_SPORTS')}
          </div>
        </div>
        <div
          className='md:block hidden w-[152px]  bg-gradient-to-t from-[var(--buttonTheme-borderButtonFirst)] to-[var(--buttonTheme-borderButtonSecond)]  dark:text-white text-black p-[2px] text-center rounded-lg font-medium text-base pointer'
          onClick={toggleAll}
        >
          <div className='bg-white dark:bg-[var(--paymentMethodColor)] py-[10px] rounded-lg font-medium'>
            {_getLocalString('LOC_SEE_ALL')}
          </div>
        </div>
      </div>

      <div className='relative flex items-center'>
        {/* <div
          className={`hidden md:flex m-4 border-2 dark:border-white border-black bg-[#ffe2e254] rounded-full cursor-pointer p-4 w-8 h-8 flex justify-center items-center left-0 navigate_prev_${props.sliderNo} cursor-pointer`}
        >
          <ChevronLeftOutlinedIcon className='dark:!text-white !text-black' />
        </div> */}

        {/* </div> */}
        {/* <Swiper
          loop={props.casinoData && props.casinoData.length > 2 ? true : false}
          slidesPerView={2}
          spaceBetween={30}
          speed={6000}
          centeredSlides={true}
          parallax={true}
          freeMode={true}
          centerInsufficientSlides={true}
          pagination={{ clickable: true }}
          effect='slide'
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          modules={[...swiperModules, SwipperPagination, Autoplay]}
          className='mySwiper swiper'
          style={{ zIndex: 0 }}
          {...swiperOptions}
        >
          <div className='swiper-wrapper'>
            {!!props.casinoData &&
              !!props.casinoData.length &&
              props.casinoData.slice(0, 50)?.map((data: any, index: any) => {
                return (
                  <SwiperSlide className='!w-60 swiper-slide' key={index}>
                    <CasinoTile
                      isPageVisible={props.isPageVisible}
                      toggleAll={toggleAll}
                      isDarkMode={props.isDarkMode}
                      casinoData={data}
                    />
                  </SwiperSlide>
                )
              })}
          </div>
        </Swiper> */}

        <CommonSwiper
          id={`casino-tiles-${props.isCasino ? 'live-casino' : 'virtual-casino'}`}
          loop={props.casinoData && props.casinoData.length > 2 ? true : false}
          swiperOptions={swiperOptions}
          slidePreview={2}
          spaceBetween={4}
          speed={6000}
          centered={true}
          classNameSliderWrapper='swiper-wrapper'
          parallax={true}
          classNameSlider='!w-[180px] sm:!w-60 swiper-slide  !mr-[5px] md:!mr-[10px]'
          freeMode={true}
          centerInsufficientSlides={true}
          clickable={true}
          effect={'slide'}
          autoPlay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          modules={[...swiperModules, SwipperPagination]}
          className='mySwiper swiper'
          style={{ zIndex: 0 }}
          arrow={false}
        >
          {props.casinoData.slice(0, 50).map((data: any, index: number) => (
            <CasinoTile
              key={index}
              isPageVisible={props.isPageVisible}
              toggleAll={toggleAll} // Assuming toggleAll is defined somewhere
              isDarkMode={props.isDarkMode}
              casinoData={data}
            />
          ))}
        </CommonSwiper>
        {/* <div
          className={`hidden md:flex m-4 border-2 dark:border-white border-black bg-[#ffe2e254] rounded-full p-4 w-8 h-8 flex justify-center items-center cursor-pointer navigate_next_${props.sliderNo}  cursor-pointer`}
          id={`navigate_next_casino-click_${props.sliderNo}`}
        >
          <ChevronRightOutlinedIcon className='dark:!text-white !text-black' />
        </div> */}
      </div>
    </div>
  )
}

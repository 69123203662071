import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'

interface ICommonErrorPage {
  titleError: string
  titleMessage: string
  contentMessage: string
  currentDomain: ICurrentDomain
  navigate: any
  hideLogout?: boolean
  Logout: (
    navigate: any,
    userType: String,
    currentDomain: ICurrentDomain,
  ) => void
}

const MaintencePage: FC<ICommonErrorPage> = (props: ICommonErrorPage) => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate('/')
    props.Logout(props.navigate, '', props.currentDomain)
  }

  return (
    <div className='col-span-7 pr-5 mb-10'>
      <div className='h-[90vh] rounded-lg w-full flex justify-center items-center text-black flex-col '>
        <div className='gradient_text font-dm_sans font-extrabold min-w-max tracking-widest md:text-9xl text-4xl'>
          {props.titleError}
        </div>
        <h2 className='text-[#cfc5bb] font-dm_sans font-extrabold min-w-max tracking-widest md:text-4xl text-2xl uppercase'>
          {props.titleMessage}
        </h2>
        <p className='text-[#cfc5bb]'> {props.contentMessage} </p>
        {!props.hideLogout && (
          <div
            className='rounded-lg mt-5 bg-gradient-to-r border border-[#ffffff59] from-[var(--banner-button-firstColor)] to-[var(--banner-button-secondColor)] text-white font-bebas text-base px-10 py-2 cursor-pointer uppercase tracking-widest'
            onClick={goBack}
          >
            {_getLocalString('LOC_LOGOUT')}
          </div>
        )}
      </div>
    </div>
  )
}

export default MaintencePage

import { DELETE, GET, HEADERS, POST, URLType, PUT } from './constants'
import { TypedObject } from '../utils/strings'
import { toast } from 'react-toastify'
import { LOGOUT_USER } from '../redux'

export async function makeGetHttpCallAsync(
  dispatch: any,
  endpoint: string,
  option: any,
  urlType: URLType | string,
  headers?: any,
  tokenAppend?: boolean,
  contentType?: string,
  isToast?: boolean,
  successMessage?: string,
  failureMessage?: string,
): Promise<unknown> {
  let faEnabled = localStorage.getItem(HEADERS.enable2FA)
  let url =
    endpoint.split('https://').length === 1
      ? getUrl(urlType) + '/' + endpoint
      : endpoint
  if (!!option) {
    url = url + '?' + option
  }

  if (Object.keys(headers).length) {
  }

  try {
    const init = {
      headers: prepareHttpHeaders(headers, tokenAppend, contentType),
      method: GET,
    }
    let response = await fetch(url, init)

    if (response.status === 200) {
      if (!!isToast) {
        toast.success(successMessage)
      }
      return response.json()
    } else if (
      !faEnabled &&
      (response.status === 401 || response.statusText === 'Unauthorized')
    ) {
      window.location.href = '/'
      window.location.reload()
      dispatch({ type: LOGOUT_USER })
      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      localStorage.removeItem(HEADERS.xAuthentication)
      sessionStorage.removeItem(HEADERS.xAuthentication)
    } else {
      const res = await response.json()
      if (!!isToast) {
        toast.error(failureMessage ? failureMessage : res.message)
      }
      throw res
    }
  } catch (error) {
    throw error
  }
}

export async function makeDeleteHttpCallAsync(
  dispatch: any,
  endpoint: string,
  option: string,
  urlType: URLType | string,
  headers?: any,
  tokenAppend?: boolean,
  contentType?: string,
  isToast?: boolean,
  successMessage?: string,
  failureMessage?: string,
): Promise<unknown> {
  let faEnabled = localStorage.getItem(HEADERS.enable2FA)
  let url = getUrl(urlType) + '/' + endpoint
  if (!!option) {
    url = url + '?' + option
  }

  try {
    const init = {
      headers: prepareHttpHeaders(headers, tokenAppend, contentType),
      method: DELETE,
    }
    let response = await fetch(url, init)
    if (response.status === 200) {
      if (!!isToast) {
        toast.success(successMessage)
      }
      return response.json()
    } else if (
      !faEnabled &&
      (response.status === 401 || response.statusText === 'Unauthorized')
    ) {
      dispatch({ type: LOGOUT_USER })
      window.location.href = '/'
      window.location.reload()
      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      localStorage.removeItem(HEADERS.xAuthentication)
      sessionStorage.removeItem(HEADERS.xAuthentication)
    } else {
      const res = await response.json()
      if (!!isToast) {
        toast.error(failureMessage ? failureMessage : res.message)
      }
      throw res
    }
  } catch (error) {
    throw error
  }
}

export async function makePostHttpCallAsync(
  dispatch: any,
  endpoint: string,
  urlType: URLType | string,
  body?: any,
  headers?: any,
  tokenAppend?: boolean,
  contentType?: string,
  isToast?: boolean,
  successMessage?: string,
  failureMessage?: string,
  isNotExitOnUnAuthorized?: boolean,
): Promise<unknown> {
  let faEnabled = localStorage.getItem(HEADERS.enable2FA)
  let url =
    endpoint.split('https').length === 1
      ? getUrl(urlType) + '/' + endpoint
      : endpoint
  try {
    const init = {
      headers: prepareHttpHeaders(headers, tokenAppend, contentType),
      method: POST,
      body,
    }
    const response = await fetch(url, init)

    if (response.status === 200) {
      if (!!isToast) {
        toast.success(successMessage)
      }

      let token = response.headers.get('bearer')
      if (token && token?.length) {
        token = `Bearer ${token}`
        localStorage.setItem(HEADERS.xAuthentication, token)
        sessionStorage.setItem(HEADERS.xAuthentication, token)
      }
      return await response.json()
    } else if (
      !isNotExitOnUnAuthorized &&
      !faEnabled &&
      (response.status === 401 || response.statusText === 'Unauthorized')
    ) {
      dispatch({ type: LOGOUT_USER })
      window.location.href = '/'
      window.location.reload()
      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      localStorage.removeItem(HEADERS.xAuthentication)
      sessionStorage.removeItem(HEADERS.xAuthentication)
    } else {
      const res = await response.json()
      if (!!isToast) {
        toast.error(failureMessage ? failureMessage : res.message)
      }
      throw res
    }
  } catch (error: any) {
    throw error
  }
}

export async function makePutHttpCallAsync(
  dispatch: any,
  endpoint: string,
  urlType: URLType | string,
  body?: any,
  headers?: any,
  tokenAppend?: boolean,
  contentType?: string,
  isToast?: boolean,
  successMessage?: string,
  failureMessage?: string,
): Promise<unknown> {
  let faEnabled = localStorage.getItem(HEADERS.enable2FA)
  let url = getUrl(urlType) + '/' + endpoint
  try {
    const init = {
      headers: prepareHttpHeaders(headers, tokenAppend, contentType),
      method: PUT,
      body,
    }
    const response = await fetch(url, init)

    if (response.status === 200) {
      if (!!isToast) {
        toast.success(successMessage)
      }

      let token = response.headers.get('bearer')
      if (token && token?.length) {
        token = `Bearer ${token}`
        localStorage.setItem(HEADERS.xAuthentication, token)
        sessionStorage.setItem(HEADERS.xAuthentication, token)
      }
      return response.json()
    } else if (
      !faEnabled &&
      (response.status === 401 || response.statusText === 'Unauthorized')
    ) {
      dispatch({ type: LOGOUT_USER })
      window.location.href = '/'
      window.location.reload()
      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      localStorage.removeItem(HEADERS.xAuthentication)
      sessionStorage.removeItem(HEADERS.xAuthentication)
    } else {
      const res = await response.json()
      if (!!isToast) {
        toast.error(failureMessage ? failureMessage : res.message)
      }
      throw res
    }
  } catch (error: any) {
    throw error
  }
}

export async function makePatchHttpCallAsync(
  dispatch: any,
  endpoint: string,
  option: any,
  params: any,
  urlType: URLType | string,
  headers?: any,
  tokenAppend?: boolean,
  contentType?: string,
  isToast?: boolean,
  method?: string,
  successMessage?: string,
  failureMessage?: string,
): Promise<unknown> {
  let faEnabled = localStorage.getItem(HEADERS.enable2FA)
  let url = getUrl(urlType) + '/' + endpoint
  if (!!params) {
    url = url + '?' + params
  }

  try {
    const init = {
      headers: prepareHttpHeaders(headers, tokenAppend, contentType),
      method: method || 'PATCH',
      body: option,
    }
    let response = await fetch(url, init)
    if (response.status === 200) {
      if (!!isToast) {
        toast.success(successMessage)
      }
      return response.json()
    } else if (
      !faEnabled &&
      (response.status === 401 || response.statusText === 'Unauthorized')
    ) {
      dispatch({ type: LOGOUT_USER })
      window.location.href = '/'
      window.location.reload()
      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      sessionStorage.removeItem(HEADERS.xAuthentication)
      localStorage.removeItem(HEADERS.xAuthentication)
    } else {
      const res = await response.json()
      if (!!isToast) {
        toast.error(failureMessage ? failureMessage : res.message)
      }
      throw res
    }
  } catch (error) {
    return {
      message: 'error',
    }
  }
}

export function prepareHttpHeaders(
  headers?: any,
  tokenAppend?: boolean,
  contentType?: string,
): TypedObject<string> {
  let currentHeader = {
    ...{},
    [HEADERS.secretKey]: import.meta.env.VITE_API_SECRET_KEY as string,
    [HEADERS.redirect]: HEADERS.follow,
  }

  currentHeader = {
    ...currentHeader,
    [HEADERS.contentTypeKey]: !!contentType
      ? contentType
      : HEADERS.contentTypeValue,
  }

  if (Object.keys(headers).length) {
  }

  if (!!headers) {
    currentHeader = {
      ...currentHeader,
      ...headers,
    }
  }

  if (!!tokenAppend) {
    let token =
      localStorage.getItem(HEADERS.xAuthentication) ??
      sessionStorage.getItem(HEADERS.xAuthentication)

    if (!token) {
      toast.error(
        'Refresh The Page, If Error Continues Please Contact Your Service Provider!',
      )
      localStorage.removeItem(HEADERS.userType)
      localStorage.removeItem(HEADERS.changePassword)
      sessionStorage.removeItem(HEADERS.xAuthentication)
      localStorage.removeItem(HEADERS.xAuthentication)
      window.location.reload()
    } else {
      currentHeader = {
        ...currentHeader,
        [HEADERS.xAuthentication]: token,
        [HEADERS.Authorization]: token,
      }
    }
  }
  return {
    ...currentHeader,
  }
}

export function getUrl(urlType: URLType | string) {
  let masterUrl = import.meta.env.VITE_API_MASTER_URL as string
  let b2bUrl = import.meta.env.VITE_API_B2B_URL as string
  let b2cUrl = import.meta.env.VITE_API_B2C_URL as string

  switch (urlType) {
    case 'master':
      return masterUrl
    case 'b2b':
      return b2bUrl
    case 'b2c':
      return b2cUrl
  }
  return b2cUrl
}

export function getSocketUrl() {
  let masterUrl = import.meta.env.VITE_SOCKTE_URL as string
  return masterUrl
}

import { ISetCommon } from '../../types/common'
import { GET_SESSION_CONTROLLER_DATA } from '../actions'

const initialState: ISessionControllerInitial = {
  sessionControllerData: [],
  getSessionControllerDataLoading: false,
}

export interface ISessionControllerInitial {
  sessionControllerData: any
  getSessionControllerDataLoading: boolean
}

export default function (state = initialState, action: ISetCommon) {
  switch (action.type) {
    case GET_SESSION_CONTROLLER_DATA: {
      return {
        ...state,
        sessionControllerData: action.data,
        getSessionControllerDataLoading: action.loading,
      }
    }

    default:
      return state
  }
}

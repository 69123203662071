import { useState } from 'react'

interface ISearchableGlobalSearchBox {
  search: string
  setSearchQuery: Function
  searchQuerySubit: any
  className: string
  searchIcon?: string
  isImageDisabled?: boolean
  placeholder: string
  suggestionList: string[]
}

export const SearchableGlobalSearchBox = (
  props: ISearchableGlobalSearchBox,
) => {
  const [suggestions, setSuggestions] = useState<string[]>([])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value.toLowerCase()
    props.setSearchQuery(value)
    const filteredNames: string[] = props.suggestionList.filter((name) =>
      name.toLowerCase().includes(value),
    )
    setSuggestions(filteredNames)
  }

  const handleSuggestionClick = (name: string) => {
    props.setSearchQuery(name)
    setSuggestions([])
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      props.searchQuerySubit(props.search)
      event.preventDefault()
    }
  }

  return (
    <div style={{ position: 'relative' }} className='w-full'>
      {!props.isImageDisabled && (
        <img
          src={props.searchIcon}
          alt='Search Icon'
          className='w-[17px] h-[17px] absolute right-5 flex justify-center xs:mt-[5px] sm:mt-3 md:mt-[10px] lg:mt-[12px] xl:mt-3'
        />
      )}
      <input
        id='userNameTextBox__id'
        className={props.className}
        type='text'
        autoComplete='off'
        value={props.search}
        onKeyDown={handleKeyPress}
        onChange={handleInputChange}
        placeholder={props.placeholder}
      />

      {!!suggestions && !!props.search && !!suggestions.length && (
        <div
          style={{
            background: 'white',
            width: '100%',
            color: 'black',
            padding: '0.5rem',
            position: 'absolute',
            zIndex: 999,
          }}
        >
          <ul>
            {suggestions.map((name, index) => (
              <li key={index} onClick={() => handleSuggestionClick(name)}>
                {name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

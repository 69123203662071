import { DEFAULT_DOMAIN_LOADER } from '../../api'
import { IAppConfiguration } from '../../types/interfaces/appConfiguration'
import {
  DUPLICATE_SCREEN,
  MAINTENANCE_PAGE,
} from '../actions/maintenanceAction'
import {
  APP_CONFIGURATION_BASE_LAYOUT,
  APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT,
  APP_CONFIGURATION_SEARCH_CONTENT,
  APP_CONFIGURATION_SEARCH_CONTENT_LOADING,
  APP_CONFIGURATION_SEARCH_CONTENT_MARKET,
  APP_CONFIGURATION_IS_MOBILE_SCREEN,
  APP_SELECTED_EVENT_ID,
  APP_SELECTED_MARKET_ID,
  APP_SELECTED_RESULT_MARKET,
  APP_CONFIGURATION_FULL_SCREEN,
  SITE_LOADER,
  APP_CONFIGURATION_URL_GAME_ID,
  APP_CONFIGURATION_URL_MARKET_ID,
  APP_CONFIGURATION_URL_EVENT_ID,
  APP_CONFIGURATION_URL_PROVIDER_NAME,
  APP_CONFIGURATION_GAME_FULL_SCREEN,
  APP_CONFIGURATION_URL_TYPE,
  APP_SELECTED_RESULT_MARKETS,
  UPDATE_MODIFY_MARKET,
  APP_CONFIGURATION_GAME_TYPE,
  APP_CONFIGURATION_SUB_PROVIDER_TYPE,
  APP_CONFIGURATION_SUB_PROVIDER_DATA,
  APP_CONFIGURATION_GAME_PATH_NAME,
  APP_UPDATE_IS_MAINTENANCE,
  APP_UPDATE_USE_SOCKET,
  APP_CONFIGURATION_SELECTED_INNER_BOX,
  APP_CONFIGURATION_SOCKET_BLOCKED,
  APP_CONFIGURATION_MARKET_MAX_DELAY,
  APP_CONFIGURATION_SUB_PROVIDER_NAME,
  APP_CONFIGURATION_DEV_TOOLS,
  MARKET_LOAD,
  DEPOSIT_ADDED,
  WITHDRAW_ADDED,
  APP_SELECTED_EVENT,
  APP_CONFIGURATION_URL_GAME_NAME,
  APP_CONFIGURATION_EVENT_DETAIL,
  USER_ADDED,
  APP_CONFIGURATION_FULL_SCREEN_WITH_FOOTER,
  TOKEN_EXPIRE,
  APP_CONFIGURATION_URL_GAME_CODE,
  APP_GLOBAL_SEARCH_CONTENT,
  APP_GLOBAL_SEARCH_CONTENT_LOADING,
} from '../type'

const initialState: IAppConfiguration = {
  urlGameId: '',
  urlGameCode: '',
  isSocketBlocked: false,
  selectedInnerBox: '',
  useSocket: false,
  withdrawAdded: false,
  depositAdded: false,
  isDuplicateScreen: false,
  urlEventId: '',
  tokenExpire: false,
  gamePathName: '',
  urlProviderName: '',
  subProviderName: '',
  urlType: '',
  modifyMarket: false,
  urlMarketId: '',
  isBaselayout: true,
  selectedEvent: {},
  isFullScreen: false,
  isFullScreenWithHeader: false,
  isGameFullScreen: false,
  selectedEventId: '',
  deletedMarketId: '',
  resultDeclaredMarketIds: [],
  selectedMarketId: '',
  deletedEventId: '',
  isDevToolsDetected: false,
  isBaseLayoutVisible: true,
  urlGameName: '',
  isMobileScreen: false,
  search: '',
  siteLoader: DEFAULT_DOMAIN_LOADER,
  resultDeclaredMarketId: '',
  searchLoading: false,
  searchContentMarkets: [],
  globalSearchLoading: false,
  globaSearchContent: [],
  eventDetail: [],
  selectedGameType: [],
  selectedSubProviderData: [],
  marketLoader: true,
  marketMinMaxDelay: [],
  selectedSubProvider: {},
  isMaintenance: false,
  userAdded: false,
}

// App Configuration
export interface IAppConfigurationAction {
  type: string
  isBaselayout: boolean
  payload?: boolean | string
  isBaseLayoutVisible: boolean
}

export default function (
  state = initialState,
  action: IAppConfigurationAction,
) {
  switch (action.type) {
    case APP_CONFIGURATION_BASE_LAYOUT: {
      return {
        ...state,
        isBaselayout: action.isBaselayout,
      }
    }

    case APP_CONFIGURATION_EVENT_DETAIL: {
      return { ...state, eventDetail: action.payload }
    }

    case APP_CONFIGURATION_MARKET_MAX_DELAY: {
      return {
        ...state,
        marketMinMaxDelay: action.payload,
      }
    }

    case TOKEN_EXPIRE: {
      return {
        ...state,
        tokenExpire: action.payload,
      }
    }

    case APP_UPDATE_USE_SOCKET: {
      return {
        ...state,
        useSocket: action.payload,
      }
    }

    case APP_SELECTED_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload,
      }
    }

    case APP_UPDATE_IS_MAINTENANCE: {
      return {
        ...state,
        isMaintenance: action.payload,
      }
    }

    case UPDATE_MODIFY_MARKET: {
      return {
        ...state,
        modifyMarket: !state.modifyMarket,
      }
    }

    case APP_CONFIGURATION_URL_GAME_ID: {
      return {
        ...state,
        urlGameId: action.payload,
      }
    }

    case APP_CONFIGURATION_URL_GAME_CODE: {
      return {
        ...state,
        urlGameCode: action.payload,
      }
    }

    case APP_CONFIGURATION_URL_GAME_NAME: {
      return {
        ...state,
        urlGameName: action.payload,
      }
    }

    case APP_CONFIGURATION_SOCKET_BLOCKED: {
      return {
        ...state,
        isSocketBlocked: action.payload,
      }
    }

    case APP_CONFIGURATION_URL_EVENT_ID: {
      return {
        ...state,
        urlEventId: action.payload,
      }
    }

    case APP_CONFIGURATION_URL_PROVIDER_NAME: {
      return {
        ...state,
        urlProviderName: action.payload,
      }
    }

    case APP_CONFIGURATION_SUB_PROVIDER_NAME: {
      return {
        ...state,
        subProviderName: action.payload,
      }
    }

    case MAINTENANCE_PAGE: {
      return {
        ...state,
        isMaintenance: action.payload,
      }
    }

    case DUPLICATE_SCREEN: {
      return {
        ...state,
        isDuplicateScreen: action.payload,
      }
    }

    case APP_CONFIGURATION_URL_TYPE: {
      return {
        ...state,
        urlType: action.payload,
      }
    }

    case APP_CONFIGURATION_URL_MARKET_ID: {
      return {
        ...state,
        urlMarketId: action.payload,
      }
    }

    case MARKET_LOAD: {
      return {
        ...state,
        marketLoader: action.payload,
      }
    }

    case SITE_LOADER: {
      return {
        ...state,
        siteLoader: action.payload,
      }
    }

    case DEPOSIT_ADDED: {
      return {
        ...state,
        depositAdded: action.payload,
      }
    }

    case WITHDRAW_ADDED: {
      return {
        ...state,
        withdrawAdded: action.payload,
      }
    }

    case USER_ADDED: {
      return {
        ...state,
        userAdded: action.payload,
      }
    }

    case APP_CONFIGURATION_DEV_TOOLS: {
      return {
        ...state,
        isDevToolsDetected: action.payload,
      }
    }

    case APP_SELECTED_EVENT_ID: {
      if (state.selectedEventId === action.payload) {
        return {
          ...state,
          deletedEventId: '',
          selectedEventId: action.payload,
        }
      }
      return {
        ...state,
        deletedEventId: state.selectedEventId,
        selectedEventId: action.payload,
      }
    }

    case APP_CONFIGURATION_FULL_SCREEN: {
      return {
        ...state,
        isFullScreen: action.payload,
      }
    }

    case APP_CONFIGURATION_FULL_SCREEN_WITH_FOOTER: {
      return {
        ...state,
        isFullScreenWithHeader: action.payload,
      }
    }

    case APP_CONFIGURATION_GAME_TYPE: {
      return {
        ...state,
        selectedGameType: action.payload,
      }
    }

    case APP_CONFIGURATION_GAME_PATH_NAME: {
      return {
        ...state,
        gamePathName: action.payload,
      }
    }

    case APP_CONFIGURATION_SUB_PROVIDER_TYPE: {
      return {
        ...state,
        selectedSubProvider: action.payload,
      }
    }

    case APP_CONFIGURATION_SUB_PROVIDER_DATA: {
      return {
        ...state,
        selectedSubProviderData: action.payload,
      }
    }

    case APP_CONFIGURATION_SELECTED_INNER_BOX: {
      return {
        ...state,
        selectedInnerBox: action.payload,
      }
    }

    case APP_CONFIGURATION_GAME_FULL_SCREEN: {
      return {
        ...state,
        isGameFullScreen: action.payload,
      }
    }

    case APP_SELECTED_MARKET_ID: {
      return {
        ...state,
        deletedMarketId: state.deletedMarketId,
        selectedMarketId: action.payload,
      }
    }

    case APP_SELECTED_RESULT_MARKET: {
      return {
        ...state,
        resultDeclaredMarketId: action.payload,
      }
    }

    case APP_SELECTED_RESULT_MARKETS: {
      return {
        ...state,
        resultDeclaredMarketIds: [
          ...state.resultDeclaredMarketIds,
          action.payload,
        ],
      }
    }

    case APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT: {
      return {
        ...state,
        isBaseLayoutVisible: action.isBaseLayoutVisible,
      }
    }

    case APP_CONFIGURATION_IS_MOBILE_SCREEN: {
      return {
        ...state,
        isMobileScreen: action.payload,
      }
    }

    case APP_CONFIGURATION_SEARCH_CONTENT: {
      return {
        ...state,
        search: action.payload,
      }
    }

    case APP_CONFIGURATION_SEARCH_CONTENT_LOADING: {
      return {
        ...state,
        searchLoading: action.payload,
      }
    }

    case APP_GLOBAL_SEARCH_CONTENT: {
      return {
        ...state,
        globaSearchContent: action.payload,
      }
    }

    case APP_GLOBAL_SEARCH_CONTENT_LOADING: {
      return {
        ...state,
        globalSearchLoading: action.payload,
      }
    }

    case APP_CONFIGURATION_SEARCH_CONTENT_MARKET: {
      return {
        ...state,
        searchContentMarkets: action.payload,
      }
    }

    default:
      return state
  }
}

import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from './redux/type'
import {
  getCommunicationDetail,
  getHomeData,
  getPaymentMethodForUser,
  getProfile,
  logoutUser,
} from './redux/actions'
import App from './App'
import { domainUpdate } from './redux/actions/domainUpdateAction'
import {
  getGameEventScoreAndLiveTV,
  getBetfairEnabled,
  getTournament,
} from './redux/actions/matchesAction/SchedulesMatchesAction'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const lang = state.language.lang
  const themeConfiguration = state.appThemeConfiguration
  const isLogin = !!state.login && state.login.userData.email !== ''
  const userData = state.login.userData
  const currentDomain = state.currentDomain
  const marketStatus = state.marketStatus
  const eventId = state.betSlip.eventId
  const selectedEventId = state.appConfiguration.selectedEventId
  const isDevToolsDetected = state.appConfiguration.isDevToolsDetected
  const competitionRequest = state.adminGames.competitionRequest
  const siteLoader = state.appConfiguration.siteLoader
  const pointUpdate = state.login.pointUpdate
  const isMaintenance = state.appConfiguration.isMaintenance
  const isDuplicateScreen = state.appConfiguration.isDuplicateScreen
  const firstTimeLogin = state.login.firstTimeLogin
  const liveTvMatches = state.inPlayMatches.liveTvMatches

  return {
    pointUpdate,
    liveTvMatches,
    isDarkMode,
    isDuplicateScreen,
    isMaintenance,
    firstTimeLogin,
    siteLoader,
    isDevToolsDetected,
    competitionRequest,
    selectedEventId,
    lang,
    eventId,
    themeConfiguration,
    isLogin,
    userData,
    currentDomain,
    marketStatus,
  }
}

const ConnectedApp: ConnectedComponent<any, any> = connect(mapStateToProps, {
  getProfile,
  domainUpdate,
  getHomeData,
  getGameEventScoreAndLiveTV,
  getTournament,
  getPaymentMethodForUser,
  getBetfairEnabled,
  logoutUser,
  getCommunicationDetail,
})(App as any)

export default ConnectedApp

import { FC } from 'react'
import { Collapse } from '@mui/material'
import { getAssetUrl } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

export interface IArrayObject {
  title: string
  time?: string
  link?: string
  icon?: string
}

export interface INavBarTile {
  name: string
  icon?: string
  isSelected?: boolean
  count?: number
  isLive?: boolean
  link?: string
  isDarkMode?: boolean
  isColored?: boolean
  hideDropDownIcon?: boolean
  matches?: IArrayObject[]
  customRender?: Function
  isOpen?: string
  setIsOpen?: Function
}

export const NavBarTile2: FC<INavBarTile> = (props: INavBarTile) => {
  const navigate = useNavigate()

  const toggleModal = () => {
    if (props?.isOpen === props.name) {
      props?.setIsOpen && props?.setIsOpen('')
    } else {
      props?.setIsOpen && props?.setIsOpen(props.name)
    }
  }

  return (
    <div
      className={`first-of-type:!rounded-t-xl last-of-type:!rounded-b-xl cursor-pointer flex flex-col relative ${
        props.isSelected
          ? 'bg-[var(--sideNavBar-firstColor)]'
          : `bg-[linear-gradient(173.49deg,var(--sideNavBar-first-color)_5.4%,var(--sideNavBar-second-color)_94.88%)]`
      }`}
    >
      {props.isSelected ? (
        <ArrowRightIcon className='absolute !text-5xl top-3 -right-7 !fill-[var(--sideNavBar-firstColor)]' />
      ) : null}
      <div
        onClick={() => {
          if (props.matches?.length) {
            toggleModal()
          } else {
            props.link ? navigate(props.link || '') : null
          }
        }}
        className={`flex  items-center p-4 text-white text-lg`}
      >
        <div className='h-[25px] w-[25px] mr-5'>
          <img src={getAssetUrl(`help/${props.icon}.webp`)} alt='Help Icon' />
        </div>
        <div className='font-primary font-bold xl:text-[20px] lg:text-[16px] md:text-[16px] w-full text-ellipsis overflow-hidden leading-[31px] tracking-normal text-left'>
          {props.name}
        </div>
      </div>
      <Collapse in={props?.isOpen === props.name} timeout='auto' unmountOnExit>
        <div className='py-2 bg-[linear-gradient(173.49deg,rgba(77,9,30,0.48)_5.4%,rgba(77,9,30,0.47)_94.88%)]'>
          {props?.matches && props?.matches?.length
            ? props.matches?.map((item, i) => {
                return props.customRender ? (
                  props.customRender(
                    { ...item, icon: props.icon },
                    i,
                    props.isSelected,
                  )
                ) : (
                  <div
                    key={i}
                    onClick={() =>
                      item.link ? navigate(item.link || '') : null
                    }
                    className=' px-4 py-2'
                  >
                    <li className='font-dm_sans text-xs font-normal leading-[31px] tracking-normal text-left list-disc overflow-hidden text-ellipsis whitespace-nowrap'>
                      {item.title}
                    </li>
                  </div>
                )
              })
            : ''}
        </div>
      </Collapse>
    </div>
  )
}

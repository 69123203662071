import { ISetCommon } from '../../types/common'
import {
  ADDED_MARKET,
  DELETED_MARKET_LIST,
  DELETED_MARKET,
  GET_COMMON_MANAGEMENT_COMMON_MARKET_ANALYSIS_DATA,
  UPDATE_EVENTNAME_FOREVENTID,
  UPDATE_SELECTED_MARKET,
  UPCOMMING_EVENTS,
  ONGOING_EVENTS,
} from '../actions'
import { REMOVED_MARKET } from '../actions/marketAction/removeMarket'
import {
  GET_ALL_GAME_MANAGEMENT_COMMON_DATA,
  GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS,
  GET_GAME_MANAGEMENT_COMMON_DATA,
  GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING,
  GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS,
} from '../type'

export interface IGameManagementCommonInitial {
  getGamesCommonMarketAnalysisLoading: boolean
  eventNameForId: any
  isMarketDeletedload: boolean
  upcommingEventDetails: any[]
  onGoingEventDetails: any[]
  isMarketAddedLoad: boolean
  removedMarket: string[]
  deletedMarketList: string[]
  marketIds: any[]
  eventIds: any[]
  allGames: any[]
  gamesCommonData: any
  getGamesCommonMarketAnalysisData: any
  getGamesCommonDataLoading: boolean
  selectedMarket: { [key: string]: boolean }
}

const initialState: IGameManagementCommonInitial = {
  removedMarket: [],
  allGames: [],
  upcommingEventDetails: [],
  onGoingEventDetails: [],
  isMarketDeletedload: false,
  isMarketAddedLoad: false,
  gamesCommonData: [],
  getGamesCommonMarketAnalysisLoading: false,
  getGamesCommonDataLoading: false,
  eventNameForId: [],
  deletedMarketList: [],
  eventIds: [],
  marketIds: [],
  getGamesCommonMarketAnalysisData: [],
  selectedMarket: {},
}

export default function (state = initialState, action: ISetCommon) {
  switch (action.type) {
    case GET_GAME_MANAGEMENT_COMMON_DATA: {
      return {
        ...state,
        gamesCommonData: action.data,
        getGamesCommonDataLoading: action.loading,
      }
    }

    case GET_GAME_MANAGEMENT_COMMON_DATA_PAGE_LOADING: {
      return {
        ...state,
        getGamesCommonDataLoading: action.loading,
      }
    }

    case GET_ALL_GAME_MANAGEMENT_COMMON_DATA: {
      return {
        ...state,
        allGames: action.data,
      }
    }

    case UPCOMMING_EVENTS: {
      return {
        ...state,
        upcommingEventDetails: action.data,
      }
    }

    case ONGOING_EVENTS: {
      return {
        ...state,
        onGoingEventDetails: action.data,
      }
    }

    case DELETED_MARKET: {
      return {
        ...state,
        isMarketDeletedload: action.data,
      }
    }

    case DELETED_MARKET_LIST: {
      return {
        ...state,
        deletedMarketList: action.data ? action.data : [],
      }
    }

    case ADDED_MARKET: {
      return {
        ...state,
        isMarketAddedLoad: action.data,
      }
    }

    case GET_COMMON_MANAGEMENT_COMMON_MARKET_ANALYSIS_DATA: {
      return {
        ...state,
        getGamesCommonMarketAnalysisData: action.data,
      }
    }

    case GET_GAME_MANAGEMENT_THIRD_PART_MARKETIDS: {
      return {
        ...state,
        marketIds: action.data,
      }
    }

    case GET_GAME_MANAGEMENR_THIRD_PART_EVENTIDS: {
      return {
        ...state,
        eventIds: action.data,
      }
    }

    case UPDATE_SELECTED_MARKET: {
      return {
        ...state,
        selectedMarket: action.data,
      }
    }

    case UPDATE_EVENTNAME_FOREVENTID: {
      return {
        ...state,
        eventNameForId: action.data,
      }
    }

    case REMOVED_MARKET: {
      return {
        ...state,
        removedMarket: action.data,
      }
    }

    default:
      return state
  }
}

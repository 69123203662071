import React, { FC, useEffect, useRef } from 'react'
import { IN_ACTIVITY_LOGOUT, NON_LOGIN_LOGOUT } from './api'
import { toast } from 'react-toastify'

interface IIdleTimeoutLogout {
  logoutUser: () => void
}

export const IdleTimeoutLogout: FC<IIdleTimeoutLogout> = ({ logoutUser }) => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    timeoutId.current = setTimeout(() => {
      toast.error('You have been logged out due to inactivity.')
      logoutUser()
    }, IN_ACTIVITY_LOGOUT)
  }

  useEffect(() => {
    const handleActivity = () => resetTimeout()

    window.addEventListener('mousemove', handleActivity)
    window.addEventListener('keydown', handleActivity)
    window.addEventListener('scroll', handleActivity)
    window.addEventListener('click', handleActivity)

    resetTimeout()

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
      window.removeEventListener('mousemove', handleActivity)
      window.removeEventListener('keydown', handleActivity)
      window.removeEventListener('scroll', handleActivity)
      window.removeEventListener('click', handleActivity)
    }
  }, [])

  return null
}

export const TimeTracker: FC<IIdleTimeoutLogout> = ({ logoutUser }) => {
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    const saveTime = () => {
      const currentTime = new Date().getTime()
      localStorage.setItem('lastTime', currentTime.toString())
    }

    const startUpdatingTime = () => {
      if (!intervalId) {
        intervalId = setInterval(saveTime, 5000)
      }
    }

    const stopUpdatingTime = () => {
      if (intervalId) {
        clearInterval(intervalId)
        intervalId = null
      }
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        startUpdatingTime()
        const lastTime = localStorage.getItem('lastTime')
        const currentTime = new Date().getTime()

        if (lastTime) {
          const timeDifference = currentTime - parseInt(lastTime, 10)

          if (timeDifference > NON_LOGIN_LOGOUT) {
            logoutUser()
            toast.error('You have been away for more than 2 Hours!')
          }
        }
      } else {
        stopUpdatingTime()
      }
    }

    if (document.visibilityState === 'visible') {
      startUpdatingTime()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      stopUpdatingTime()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return null
}

import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from '../../redux/type'
import BaseLayout from './BaseLayout'
import {
  onMarketClick,
  updateOpenLoginScreen,
} from '../../redux/actions/userAction/betSlipAction'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const lang = state.language.lang
  const currentDomain = state.currentDomain
  const appConfiguration = state.appConfiguration
  const isBaselayout = state.appConfiguration.isBaselayout
  const userData = state.login.userData
  const userLogInLoader = state.login.userLogInLoader
  const searchLoading = state.appConfiguration.searchLoading
  const searchContentMarkets = state.appConfiguration.searchContentMarkets
  const appThemeConfiguration = state.appThemeConfiguration
  const hightLightMatch = state.hightLightMatch.eventIds
  const isBaseLayoutVisible =
    state.appConfiguration.isBaseLayoutVisible || false
  const isFullScreen = state.appConfiguration.isFullScreen
  const isFullScreenWithHeader = state.appConfiguration.isFullScreenWithHeader
  const isGameFullScreen = state.appConfiguration.isGameFullScreen
  const liveCasinoData = state.adminGames.liveCasinoData
  const virtualCasinoData = state.adminGames.virtualCasinoData
  const lotteryGamesData = state.adminGames.lotteryGamesData
  const sportsData = state.adminGames.sportsData
  const slotGamesData = state.adminGames.slotGamesData
  const crashGamesData = state.adminGames.crashGamesData

  return {
    liveCasinoData,
    isGameFullScreen,
    virtualCasinoData,
    isFullScreenWithHeader,
    lotteryGamesData,
    sportsData,
    slotGamesData,
    crashGamesData,
    isDarkMode,
    currentDomain,
    lang,
    isFullScreen,
    appConfiguration,
    isBaselayout,
    userData,
    hightLightMatch,
    userLogInLoader,
    searchLoading,
    searchContentMarkets,
    appThemeConfiguration,
    isBaseLayoutVisible,
  }
}

const ConnectedBaseLayout: ConnectedComponent<any, any> = connect(
  mapStateToProps,
  { onMarketClick, updateOpenLoginScreen },
)(BaseLayout as any)

export default ConnectedBaseLayout

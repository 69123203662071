import { useDispatch, useSelector } from 'react-redux'
import { IdleTimeoutLogout, TimeTracker } from './InActivity'
import { logoutUser, SET_USER_COUNTRY } from './redux'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GoogleTagManager } from './GoogleTagManager'
import { DEFAULT_COUNTRY_DETAIL } from './redux/reducers/loginReducer'
import { UNKNOWN } from './api'

async function fetchIPInfo(retries = 3) {
  const url = 'https://ipwhois.app/json/'
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url)
      const data = await response.json()
      if (!response.ok) throw new Error(`Error: ${response.statusText}`)
      return data
    } catch (error) {
      if (i === retries - 1) throw error
      await new Promise((res) => setTimeout(res, 5000))
    }
  }
}

export const AppSideComponent = () => {
  const { isLogin } = useSelector((state: any) => state.login)
  const currentDomain = useSelector((state: any) => state.currentDomain)
  const { isSocketBlocked } = useSelector(
    (state: any) => state.appConfiguration,
  )
  const [addGoogleManagerTag, setAddGoogleManagerTag] = useState(0)

  useEffect(() => {
    setAddGoogleManagerTag(addGoogleManagerTag + 1)
  }, [])

  useEffect(() => {
    if (isSocketBlocked) {
      window.location.reload()
    }
  }, [isSocketBlocked])

  const dispatch = useDispatch()
  const naviagte = useNavigate()

  const hanldeLogoutUser = () => {
    dispatch(logoutUser(naviagte, '', currentDomain) as any)
  }

  useEffect(() => {
    fetchIPInfo()
      .then((data) => {
        dispatch({
          type: SET_USER_COUNTRY,
          payload: {
            continent: data.continent ?? UNKNOWN,
            city: data.city ?? UNKNOWN,
            country_code: data.country_code ?? UNKNOWN,
            country: data.country ?? UNKNOWN,
            country_flag: data.country_flag ?? UNKNOWN,
            region: data.region ?? UNKNOWN,
          },
        })
      })
      .catch(() => {
        dispatch({
          type: SET_USER_COUNTRY,
          payload: DEFAULT_COUNTRY_DETAIL,
        })
      })
  }, [])

  return (
    <>
      {isLogin && <TimeTracker logoutUser={hanldeLogoutUser} />}
      {isLogin && <IdleTimeoutLogout logoutUser={hanldeLogoutUser} />}
      {addGoogleManagerTag === 1 && (
        <GoogleTagManager
          currentDomain={currentDomain}
          googleTag={import.meta.env.VITE_GOOGLE_TAG as string}
          allCasinoGoogleTag={
            import.meta.env.VITE_ALL_CASINO_365_GOOGLE_TAG as string
          }
          baazigerExchGoogleTag={
            import.meta.env.VITE_BAAZIGER_EXCH_GOOGLE_TAG as string
          }
        />
      )}
    </>
  )
}

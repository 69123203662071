import {
  ICurrentBet,
  INewBetsSlip,
} from '../../../pages/Game/betslip/NewBetSlip'
import {
  BET_SLIP_STAKE,
  HASHMAP_BET,
  OPEN_LOGIN_SCREEN,
  UPDATE_BETHISTORY_EVENT_ID,
  UPDATE_CURRENT_BETSLIP,
  UPDATE_CURRENT_BETSLIP_ODDS,
  UPDATE_IS_BET_LOADING,
} from '../../actions/userAction/betSlipAction'

interface INewBetsSlipAction extends INewBetsSlip {
  type: string
  payload: any
  betSlipStake: number
  openLoginScreen: boolean
}

export function BetSlipReducer(
  state = initialState,
  action: INewBetsSlipAction,
) {
  switch (action.type) {
    case UPDATE_CURRENT_BETSLIP: {
      return {
        ...state,
        currentBet: action.currentBet,
      }
    }

    case OPEN_LOGIN_SCREEN:
      return {
        ...state,
        openLoginScreen: action.openLoginScreen,
      }

    case UPDATE_IS_BET_LOADING:
      return {
        ...state,
        betSlipLoaderTimer: action.timer,
        isBetSlipLoading: action.isBetSlipLoading,
      }

    case UPDATE_CURRENT_BETSLIP_ODDS:
      return {
        ...state,
        currentBet: {
          ...state.currentBet,
          odd: action.payload,
        },
      }

    case UPDATE_BETHISTORY_EVENT_ID:
      return {
        ...state,
        betEvent: action.eventId,
      }

    case HASHMAP_BET:
      return {
        ...state,
        hashMapBet: action.hashMapBet,
      }

    case BET_SLIP_STAKE:
      return {
        ...state,
        betSlipStake: action.betSlipStake,
      }

    default:
      return state
  }
}

const initialState: INewBetsSlip = {
  currentBet: {} as ICurrentBet,
  betSlipLoaderTimer: 0,
  isBetSlipLoading: false,
  betSlipStake: 0,
  hashMapBet: [],
  openLoginScreen: false,
  eventId: '',
}

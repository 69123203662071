import { Container } from '@mui/material'
import { ISlideShow } from '../../types/interfaces/themeConfiguration'
import { useSelector } from 'react-redux'
import { getThemeAssetUrl } from '../../utils'
import image from './no-image-icon.png'
interface IBanner {
  banner: ISlideShow
  isCommon: boolean
  isRounded?: boolean
}

const MainBanner = (props: IBanner) => {
  const { banner, isRounded } = props

  const { paymentMethodColor } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )

  return (



    (banner.image || image) && (
      <div
        style={{
          background: `url(${getThemeAssetUrl(banner.image || image)})`,
          backgroundColor: paymentMethodColor,
          borderRadius: isRounded ? '0.5rem' : 0,
        }}
        className='fit-image !bg-[50%] !h-[100px] !relative'
      >
        <Container
          maxWidth={'xl'}
          fixed
          className='relative  !flex h-full items-center'
        />
      </div>
    )

  )
}

export default MainBanner

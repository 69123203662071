export enum GAME_TYPE {
  'Soccer' = 1,
  'Tennis' = 2,
  'Basketball' = 7522,
  'Golf' = 3,
  'Cricket' = 4,
  'Ice Hockey' = 7524,
  'Rugby League' = 1477,
  'Rugby Union' = 5,
  'Boxing' = 6,
  'Horse Racing' = 7,
  'Motor Sport' = 8,
  'Esports' = 27454571,
  'Special Bets' = 10,
  'Volleyball' = 998917,
  'Cycling' = 11,
  'Australian Rules' = 61420,
  'Darts' = 3503,
  'Gaelic Games' = 2152880,
}

export enum MARKET_TYPE {
  SESSION = 'session',
  BOOKMAKER = 'bookmaker',
  BETFAIR = 'betfair',
}

import { getAssetUrl } from '../helper'

export function getGameFromCasino(casinoGames: any) {
  let gameTypesArray: any[] = []
  casinoGames.forEach((subProvider: any) => {
    subProvider.subProviders.forEach((sub: any) => {
      sub.gameTypes.forEach((game: any) => {
        gameTypesArray.push(game)
      })
    })
  })
  return gameTypesArray
}

export function getAllCasinos(casinoData: any) {
  let allData: any[] = []
  for (let index = 0; index < casinoData.length; index++) {
    allData.push(...casinoData[index].subProviders)
  }

  const finalRes = [
    {
      subProviderName: 'All',
      subProviders: allData,
    },
    ...casinoData,
  ]

  let finalResSorted = []

  for (let index = 0; index < finalRes.length; index++) {
    finalResSorted.push({
      subProviderName: finalRes[index].subProviderName,
      subProviders: sortAndExtractUniqueGameTypes(finalRes[index].subProviders),
    })
  }

  return finalResSorted
}

const sortAndExtractUniqueGameTypes = (gameTypes: any[]): any[] => {
  const gameTypesMap: Record<string, any> = {} // Map to store game types by their categories
  const allGames: any[] = []
  // Group game types by category
  gameTypes.forEach((gameType) => {
    if (!gameTypesMap[gameType.gameType]) {
      gameTypesMap[gameType.gameType] = gameType
    }
    allGames.push(...gameType.gameTypes)
  })

  // Sort game types by category according to the desired sequence
  let sortedGameTypes: any[] = []
  desiredSequence.forEach((category) => {
    if (gameTypesMap[category]) {
      sortedGameTypes.push(gameTypesMap[category])
      delete gameTypesMap[category] // Remove the category from the map once added
    }
  })

  // Add remaining game types (not found in desired sequence) to the end
  for (const category in gameTypesMap) {
    sortedGameTypes.push(gameTypesMap[category])
  }

  sortedGameTypes = [
    { gameType: 'All', gameTypes: allGames },
    ...sortedGameTypes,
  ]

  return sortedGameTypes
}

export const desiredSequence = [
  'Casino',
  'Roulette',
  'Teen Patti',
  'Poker',
  'Baccarat',
  'Dragon Tiger',
  '32 Cards',
  'Andar Bahar',
  'Lucky 7',
  '3 Card Judgement',
  'Casino War',
  'Worli',
  'Sports',
  'Bollywood',
  'Lottery',
  'Queen',
  'Race',
  'Others',
  'Lobby',
]

const LIVE_CASINO_DATA = (_getLocalString: any) => [
  {
    key: 15,
    value: 'Sic Bo',
    upperBox: 'Beter Live',
    innerBox: 'Sic Bo',
  },
  {
    key: 16,
    value: 'Live Roulette',
    upperBox: 'Beter Live',
    innerBox: 'Live Roulette',
  },
  {
    key: 17,
    value: 'Oasis Blackjack',
    upperBox: 'Beter Live',
    innerBox: 'Oasis Blackjack',
  },
  {
    key: 1,
    value: _getLocalString('LOC_1_DAY_TEENPATTI'),
    upperBox: 'Royal Gaming',
    innerBox: 'Teen Patti',
  },
  {
    key: 2,
    value: _getLocalString('LOC_ROULETTE'),
    upperBox: 'Royal Gaming',
    innerBox: 'Roulette',
  },
  {
    key: 3,
    value: _getLocalString('LOC_CARD_JUDGEMENT'),
    upperBox: 'Royal Gaming',
    innerBox: 'Card Judgement',
  },
  {
    key: 4,
    value: _getLocalString('LOC_7_UP_DOWN'),
    upperBox: 'Royal Gaming',
    innerBox: '7 Up Down',
  },
  {
    key: 5,
    value: _getLocalString('LOC_32_CARDS'),
    upperBox: 'Royal Gaming',
    innerBox: '32 Cards',
  },
  {
    key: 6,
    value: _getLocalString('LOC_ANDAR_BAHAR'),
    upperBox: 'Royal Gaming',
    innerBox: 'Andar Bahar',
  },
  {
    key: 7,
    value: _getLocalString('LOC_BACCARAT'),
    upperBox: 'Royal Gaming',
    innerBox: 'Baccarat',
  },
  {
    key: 8,
    value: _getLocalString('LOC_BLACK_JACK'),
    upperBox: 'Royal Gaming',
    innerBox: 'Black Jack',
  },
  {
    key: 9,
    value: _getLocalString('LOC_CASINO_HOLD'),
    upperBox: 'Royal Gaming',
    innerBox: 'Casino Hold',
  },
  {
    key: 10,
    value: _getLocalString('LOC_HIGH_LOW'),
    upperBox: 'Royal Gaming',
    innerBox: 'High Low',
  },
  {
    key: 11,
    value: _getLocalString('LOC_INDIAN_CARD_GAMES'),
    upperBox: 'Royal Gaming',
    innerBox: 'Indian Card Games',
  },
  {
    key: 12,
    value: _getLocalString('LOC_LUCKY_7'),
    upperBox: 'Royal Gaming',
    innerBox: 'Lucky 7',
  },
  {
    key: 13,
    value: _getLocalString('LOC_POKER'),
    upperBox: 'Royal Gaming',
    innerBox: 'Poker',
  },
  {
    key: 14,
    value: _getLocalString('LOC_TEENPATTI'),
    upperBox: 'Royal Gaming',
    innerBox: 'Teenpatti',
  },
]

export function getLiveCasinoData(_getLocalString: any) {
  let gameTypesArray: any[] = []
  LIVE_CASINO_DATA(_getLocalString).map((item: any) => {
    gameTypesArray.push({
      image: getAssetUrl(`casino/liveCasino/livecasino${item.key}.webp`),
      gameName: item.value,
      upperBox: item.upperBox,
      innerBox: item.innerBox,
    })
  })
  return gameTypesArray
}

const VIRTUAL_DATA = (_getLocalString: any) => [
  {
    key: 1,
    value: _getLocalString('LOC_TEENPATTI_ONE_DAY'),
    upperBox: 'Royal Gaming',
    innerBox: 'TeenPatti One Day',
  },
  {
    key: 2,
    value: _getLocalString('LOC_CARD_TEENPATTI'),
    upperBox: 'Royal Gaming',
    innerBox: 'Card TeenPatti',
  },
  {
    key: 3,
    value: _getLocalString('LOC_CARD_JUDGEMENT'),
    upperBox: 'Royal Gaming',
    innerBox: 'Poker',
  },
  {
    key: 4,
    value: _getLocalString('LOC_CARD_BACCARAT'),
    upperBox: 'Royal Gaming',
    innerBox: 'Card Baccarat',
  },
  {
    key: 5,
    value: _getLocalString('LOC_AKBAR_ROMEO'),
    upperBox: 'Royal Gaming',
    innerBox: 'Poker',
  },
  ,
  {
    key: 6,
    value: _getLocalString('LOC_AMAR_AKBAR_ANTHONY'),
    upperBox: 'Royal Gaming',
    innerBox: 'Amar Akbar Anthony',
  },
  {
    key: 7,
    value: _getLocalString('LOC_ANDAR_BAHAR'),
    upperBox: 'Royal Gaming',
    innerBox: 'Andar Bahar',
  },
  {
    key: 8,
    value: _getLocalString('LOC_BACCARAT'),
    upperBox: 'Royal Gaming',
    innerBox: 'Baccarat',
  },
  {
    key: 9,
    value: _getLocalString('LOC_BOLLYWOOD_CASINO'),
    upperBox: 'Royal Gaming',
    innerBox: 'Bollywood Casino',
  },
  {
    key: 10,
    value: _getLocalString('LOC_CASINO_WAR'),
    upperBox: 'Royal Gaming',
    innerBox: 'Dragon Tiger One Day',
  },
  {
    key: 11,
    value: _getLocalString('LOC_DRAGON_TIGER_ONE_DAY'),
    upperBox: 'Royal Gaming',
    innerBox: 'Dragon Tiger One Day',
  },
  {
    key: 12,
    value: _getLocalString('LOC_DRAGON_TIGER_LION'),
    upperBox: 'Royal Gaming',
    innerBox: 'Dragon Tiger Lion',
  },
  {
    key: 13,
    value: _getLocalString('LOC_DRAGON_TIGER_ONE_DAY'),
    upperBox: 'Royal Gaming',
    innerBox: 'Dragon Tiger One Day',
  },
  {
    key: 14,
    value: _getLocalString('LOC_DRAGON_TIGER'),
    upperBox: 'Royal Gaming',
    innerBox: 'Dragon Tiger',
  },
  {
    key: 15,
    value: _getLocalString('LOC_FOOTBALL_STUDIO'),
    upperBox: 'Royal Gaming',
    innerBox: 'Football Studio',
  },
  {
    key: 16,
    value: _getLocalString('LOC_LOTTERY'),
    upperBox: 'Royal Gaming',
    innerBox: 'Lottery',
  },
  {
    key: 17,
    value: _getLocalString('LOC_MOVIE_CASINO'),
    upperBox: 'Royal Gaming',
    innerBox: 'Movie Casino',
  },
  {
    key: 18,
    value: _getLocalString('LOC_POKER'),
    upperBox: 'Royal Gaming',
    innerBox: 'Poker',
  },
  {
    key: 19,
    value: _getLocalString('LOC_QUEEN_RACE'),
    upperBox: 'Royal Gaming',
    innerBox: 'Queen Race',
  },
  {
    key: 20,
    value: _getLocalString('LOC_RACE_TO_17'),
    upperBox: 'Royal Gaming',
    innerBox: 'Race to 17',
  },
  {
    key: 21,
    value: _getLocalString('LOC_ROULETTE'),
    upperBox: 'Royal Gaming',
    innerBox: 'Roulette',
  },
  {
    key: 22,
    value: _getLocalString('LOC_TEENPATTI_MUFLIS'),
    upperBox: 'Royal Gaming',
    innerBox: 'Teenpatti Muflis',
  },
  {
    key: 23,
    value: _getLocalString('LOC_TEENPATTI'),
    upperBox: 'Royal Gaming',
    innerBox: 'Teenpatti',
  },
  {
    key: 24,
    value: _getLocalString('LOC_TRIO'),
    upperBox: 'Royal Gaming',
    innerBox: 'Trio',
  },
]

export function getVirtualCasinoData(_getLocalString: any) {
  let gameTypesArray: any[] = []
  VIRTUAL_DATA(_getLocalString).map((item: any) => {
    gameTypesArray.push({
      image: getAssetUrl(`casino/virtualCasino/virtualcasino${item.key}.webp`),
      gameName: item.value,
      upperBox: item.upperBox,
      innerBox: item.innerBox,
    })
  })
  return gameTypesArray
}

export const getCasinoRemarks = (game: any) => {
  if (!game) {
    return ''
  }

  let gameLocal = game
  return `${gameLocal.category}/${gameLocal.gameType}/${gameLocal.gameType}`
}

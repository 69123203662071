import { useEffect, useState } from 'react'
import { ICurrentBet } from '../../Game/betslip/NewBetSlip'
import { BET_TYPE } from '../../../types/enums/betType'
import { useMediaQuery } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import { toast } from 'react-toastify'
import { isMobileScreensix } from '../../../utils/internalUtils'

interface ISportsContentMarketItem {
  marketStatus: any
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
  isDrawerOpen: boolean
  market: any
  tokenExpire: any
  inPlay: boolean
  noMarketStatus: Function
  isLogin: boolean
  matchNotAllowed?: boolean
  updateOpenLoginScreen: (openLoginScreen: boolean) => void
  onMarketClick: (market: ICurrentBet) => void
  isBetSlipLoading: boolean
}

export const SportsContentMarketItem = (props: ISportsContentMarketItem) => {
  const isTabletScreen = useMediaQuery('(max-width:1200px)')
  const isDesktopScreen = useMediaQuery('(min-width:1201px)')
  const marketStatus = props.marketStatus

  // color update
  const [team1BackColor, setTeam1BackColor] = useState(false)
  const [team2BackColor, setTeam2BackColor] = useState(false)
  const [team3BackColor, setTeam3BackColor] = useState(false)
  const [team1LayColor, setTeam1LayColor] = useState(false)
  const [team2LayColor, setTeam2LayColor] = useState(false)
  const [team3LayColor, setTeam3LayColor] = useState(false)

  const onPlaceOrder = (
    type: BET_TYPE,
    odd: number,
    run: number,
    teamId: string,
  ) => {
    if (!props.isLogin) {
      return props.updateOpenLoginScreen(true)
    }

    if (props.isBetSlipLoading) {
      return toast.error('Your Current Bet is Not Finished Yet!')
    }

    // FOR CRICKET
    if (
      props.market.eventType.id === '4' &&
      props.market.marketName === 'Match Odds' &&
      odd &&
      odd > 3.99
    ) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    // FOR SOCCER
    if (
      props.market.eventType.id === '1' &&
      props.market.marketName === 'Match Odds' &&
      odd &&
      odd > 6.99
    ) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    // FOR TENNIS
    if (
      props.market.eventType.id === '2' &&
      props.market.marketName === 'Match Odds' &&
      odd &&
      odd > 6.99
    ) {
      return toast.error(_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE'))
    }

    if (!props.matchNotAllowed) {
      return toast.error(
        `${_getLocalString('LOC_BET_NOT_ALLOWED_MESSAGE')}, ${_getLocalString('LOC_MATCH_IS_NOT_LIVE_NOW')}`,
      )
    }

    isTabletScreen && props.setIsDrawerOpen(true)
    props.onMarketClick({
      externalType: marketStatus.marketId.externalType,
      manual: marketStatus.marketId.manual,
      teamName: '',
      marketId: marketStatus.marketId,
      teamId: teamId,
      marketName: marketStatus.marketId.name,
      marketType: marketStatus.type,
      oddType: type,
      odd: odd,
      run: run,
      delaySecond: marketStatus.delaySecond || 0,
      max: marketStatus.max,
      min: marketStatus.min,
    })
  }

  useEffect(() => {
    setTeam1BackColor(true)
    setTimeout(() => {
      setTeam1BackColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 0 &&
      marketStatus.teams[0] &&
      marketStatus.teams[0]?.back &&
      marketStatus.teams[0]?.back[0] &&
      marketStatus.teams[0]?.back[0].odd,
  ])

  useEffect(() => {
    setTeam1LayColor(true)
    setTimeout(() => {
      setTeam1LayColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 0 &&
      marketStatus.teams[0] &&
      marketStatus.teams[0]?.lay &&
      marketStatus.teams[0]?.lay[0] &&
      marketStatus.teams[0]?.lay[0].odd,
  ])

  useEffect(() => {
    setTeam2BackColor(true)
    setTimeout(() => {
      setTeam2BackColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 1 &&
      marketStatus.teams[1] &&
      marketStatus.teams[1]?.back &&
      marketStatus.teams[1]?.back[0] &&
      marketStatus.teams[1]?.back[0].odd,
  ])

  useEffect(() => {
    setTeam2LayColor(true)
    setTimeout(() => {
      setTeam2LayColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 1 &&
      marketStatus.teams[1] &&
      marketStatus.teams[1]?.lay &&
      marketStatus.teams[1]?.lay[0] &&
      marketStatus.teams[1]?.lay[0].odd,
  ])

  useEffect(() => {
    setTeam3BackColor(true)
    setTimeout(() => {
      setTeam3BackColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 2 &&
      marketStatus.teams[2] &&
      marketStatus.teams[2].back &&
      marketStatus.teams[2].back[0] &&
      marketStatus.teams[2].back[0].odd,
  ])

  useEffect(() => {
    setTeam3LayColor(true)
    setTimeout(() => {
      setTeam3LayColor(false)
    }, 1000)
  }, [
    marketStatus &&
      marketStatus.teams &&
      marketStatus.teams.length > 2 &&
      marketStatus.teams[2] &&
      marketStatus.teams[2].lay &&
      marketStatus.teams[2].lay[0] &&
      marketStatus.teams[2].lay[0].odd,
  ])

  const width =
    isTabletScreen && !isDesktopScreen && !isMobileScreensix()
      ? '100px'
      : '50px'

  return (
    <div className='flex items-center justify-between gap-1 md:px-0 place-content-center'>
      {(marketStatus && marketStatus.teams && marketStatus.teams.length > 3) ||
      (marketStatus && marketStatus.isLockVisible) ||
      (marketStatus.type === 'betfair' && props.tokenExpire) ? (
        <div className=' relative w-[100%]'>
          <div className='flex brightness-50 gap-1 md:px-0 flex items-center place-content-center justify-between'>
            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[0]?.back &&
                  marketStatus.teams[0]?.back[0] &&
                  onPlaceOrder(
                    BET_TYPE.BACK,
                    marketStatus.teams[0]?.back[0].odd,
                    marketStatus.teams[0]?.back[0].run,
                    marketStatus.teams[0]?.id,
                  )
              }}
              style={{
                width: width,
                background: `${team1BackColor ? '#FFC107' : '#A5D9FE'}`,
              }}
              className={`text-transparent cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {(marketStatus &&
                  marketStatus.teams[0]?.back &&
                  marketStatus.teams[0]?.back[0] &&
                  marketStatus.teams[0]?.back[0].odd) ||
                  '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[0]?.lay &&
                  marketStatus.teams[0]?.lay[0] &&
                  onPlaceOrder(
                    BET_TYPE.LAY,
                    marketStatus.teams[0]?.lay[0].odd,
                    marketStatus.teams[0]?.lay[0].run,
                    marketStatus.teams[0]?.id,
                  )
              }}
              style={{
                width: width,
                background: `${team1LayColor ? '#FFC107' : '#F8D0CE'}`,
              }}
              className={`text-transparent cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold '>
                {(marketStatus &&
                  marketStatus.teams[0]?.lay &&
                  marketStatus.teams[0]?.lay[0] &&
                  marketStatus.teams[0]?.lay[0].odd) ||
                  '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[2].back &&
                  marketStatus.teams[2].back[0] &&
                  onPlaceOrder(
                    BET_TYPE.BACK,
                    marketStatus.teams[2].back[0].odd,
                    marketStatus.teams[2].back[0].run,
                    marketStatus.teams[2].id,
                  )
              }}
              style={{
                width: width,
                background: `${team3BackColor ? '#FFC107' : '#A5D9FE'}`,
              }}
              className={`text-transparent cursor-pointer h-8 flex flex-col items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {marketStatus &&
                marketStatus.teams.length > 2 &&
                marketStatus.teams[2].back &&
                marketStatus.teams[2].back[0]
                  ? marketStatus.teams[2].back[0].odd
                  : '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[2].lay &&
                  marketStatus.teams[2].lay[0] &&
                  onPlaceOrder(
                    BET_TYPE.LAY,
                    marketStatus.teams[2].lay[0].odd,
                    marketStatus.teams[2].lay[0].run,
                    marketStatus.teams[2].id,
                  )
              }}
              style={{
                width: width,
                background: `${team3LayColor ? '#FFC107' : '#F8D0CE'}`,
              }}
              className={`text-transparent cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {marketStatus &&
                marketStatus.teams.length > 2 &&
                marketStatus.teams[2].lay &&
                marketStatus.teams[2].lay[0]
                  ? marketStatus.teams[2].lay[0].odd
                  : '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[1]?.back &&
                  marketStatus.teams[1]?.back[0] &&
                  onPlaceOrder(
                    BET_TYPE.BACK,
                    marketStatus.teams[1]?.back[0].odd,
                    marketStatus.teams[1]?.back[0].run,
                    marketStatus.teams[1]?.id,
                  )
              }}
              style={{
                width: width,
                background: `${team2BackColor ? '#FFC107' : '#A5D9FE'}`,
              }}
              className={`text-transparent cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {(marketStatus &&
                  marketStatus.teams[1]?.back &&
                  marketStatus.teams[1]?.back[0] &&
                  marketStatus.teams[1]?.back[0].odd) ||
                  '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[1]?.lay &&
                  marketStatus.teams[1]?.lay[0] &&
                  onPlaceOrder(
                    BET_TYPE.LAY,
                    marketStatus.teams[1]?.lay[0].odd,
                    marketStatus.teams[1]?.lay[0].run,
                    marketStatus.teams[0]?.id,
                  )
              }}
              style={{
                width: width,
                background: `${team2LayColor ? '#FFC107' : '#F8D0CE'}`,
              }}
              className={`text-transparent cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {(marketStatus &&
                  marketStatus.teams[1]?.lay &&
                  marketStatus.teams[1]?.lay[0] &&
                  marketStatus.teams[1]?.lay[0].odd) ||
                  '-'}
              </div>
            </div>
          </div>
          <div className='absolute z-30 top-0 left-0 right-0 bottom-0 flex items-center justify-center xs:text-[10px] sm:text-[12px] md:text-[16px] font-extrabold text-red-800'>
            <LockIcon />
          </div>
        </div>
      ) : (
        marketStatus &&
        marketStatus.teams &&
        marketStatus.teams.length > 1 && (
          <>
            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[0]?.back &&
                  marketStatus.teams[0]?.back[0] &&
                  onPlaceOrder(
                    BET_TYPE.BACK,
                    marketStatus.teams[0]?.back[0].odd,
                    marketStatus.teams[0]?.back[0].run,
                    marketStatus.teams[0]?.id,
                  )
              }}
              style={{
                background: `${team1BackColor ? '#FFC107' : '#A5D9FE'}`,
                width: width,
              }}
              className={`cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {(marketStatus &&
                  marketStatus.teams[0]?.back &&
                  marketStatus.teams[0]?.back[0] &&
                  marketStatus.teams[0]?.back[0].odd) ||
                  '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[0]?.lay &&
                  marketStatus.teams[0]?.lay[0] &&
                  onPlaceOrder(
                    BET_TYPE.LAY,
                    marketStatus.teams[0]?.lay[0].odd,
                    marketStatus.teams[0]?.lay[0].run,
                    marketStatus.teams[0]?.id,
                  )
              }}
              style={{
                width: width,
                background: `${team1LayColor ? '#FFC107' : '#F8D0CE'}`,
              }}
              className={`cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold '>
                {(marketStatus &&
                  marketStatus.teams[0]?.lay &&
                  marketStatus.teams[0]?.lay[0] &&
                  marketStatus.teams[0]?.lay[0].odd) ||
                  '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[2].back &&
                  marketStatus.teams[2].back[0] &&
                  onPlaceOrder(
                    BET_TYPE.BACK,
                    marketStatus.teams[2].back[0].odd,
                    marketStatus.teams[2].back[0].run,
                    marketStatus.teams[2].id,
                  )
              }}
              style={{
                background: `${team3BackColor ? '#FFC107' : '#A5D9FE'}`,
                width: width,
              }}
              className={`cursor-pointer h-8 flex flex-col items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {marketStatus &&
                marketStatus.teams.length > 2 &&
                marketStatus.teams[2].back &&
                marketStatus.teams[2].back[0]
                  ? marketStatus.teams[2].back[0].odd
                  : '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[2].lay &&
                  marketStatus.teams[2].lay[0] &&
                  onPlaceOrder(
                    BET_TYPE.LAY,
                    marketStatus.teams[2].lay[0].odd,
                    marketStatus.teams[2].lay[0].run,
                    marketStatus.teams[2].id,
                  )
              }}
              style={{
                width: width,
                background: `${team3LayColor ? '#FFC107' : '#F8D0CE'}`,
              }}
              className={`cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {marketStatus &&
                marketStatus.teams.length > 2 &&
                marketStatus.teams[2].lay &&
                marketStatus.teams[2].lay[0]
                  ? marketStatus.teams[2].lay[0].odd
                  : '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[1]?.back &&
                  marketStatus.teams[1]?.back[0] &&
                  onPlaceOrder(
                    BET_TYPE.BACK,
                    marketStatus.teams[1]?.back[0].odd,
                    marketStatus.teams[1]?.back[0].run,
                    marketStatus.teams[1].id,
                  )
              }}
              style={{
                width: width,
                background: `${team2BackColor ? '#FFC107' : '#A5D9FE'}`,
              }}
              className={`cursor-pointer h-8  flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {(marketStatus &&
                  marketStatus.teams[1]?.back &&
                  marketStatus.teams[1]?.back[0] &&
                  marketStatus.teams[1]?.back[0].odd) ||
                  '-'}
              </div>
            </div>

            <div
              onClick={() => {
                marketStatus &&
                  marketStatus.teams[1]?.lay &&
                  marketStatus.teams[1]?.lay[0] &&
                  onPlaceOrder(
                    BET_TYPE.LAY,
                    marketStatus.teams[1]?.lay[0].odd,
                    marketStatus.teams[1]?.lay[0].run,
                    marketStatus.teams[0]?.id,
                  )
              }}
              style={{
                width: width,
                background: `${team2LayColor ? '#FFC107' : '#F8D0CE'}`,
              }}
              className={`cursor-pointer h-8 flex flex-col  items-center justify-center text-center text-black`}
            >
              <div className='!text-sm flex justify-center items-center font-bold'>
                {(marketStatus &&
                  marketStatus.teams[1]?.lay &&
                  marketStatus.teams[1]?.lay[0] &&
                  marketStatus.teams[1]?.lay[0].odd) ||
                  '-'}
              </div>
            </div>
          </>
        )
      )}
    </div>
  )
}

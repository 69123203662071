import { DEPOSIT, SET_SIGNUP_LOADER, WITHDRAWAL } from '../actions'
import {} from '../type'

const initialState: IIsLoading = {
  userSignUpLoader: false,
  withdrawalLoading: false,
  depositLoading: false,
}

export type IIsLoading = {
  userSignUpLoader: boolean
  withdrawalLoading: boolean
  depositLoading: boolean
}

export default function (
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case SET_SIGNUP_LOADER: {
      return {
        ...state,
        userSignUpLoader: action.payload,
      }
    }

    case WITHDRAWAL: {
      return {
        ...state,
        withdrawalLoading: action.payload,
      }
    }

    case DEPOSIT: {
      return {
        ...state,
        depositLoading: action.payload,
      }
    }
    default:
      return state
  }
}

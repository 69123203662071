export const ROYAL_GAME_LOGIN = '@ROYAL/ROYAL_GAME_LOGIN'

export interface IRoyalGame {
  gameLogin: any
  isGameLoginLoading: boolean
}

interface IRoyalGamingReducerAction {
  type: string
  payload: any
  loading: boolean
}

const initialState: IRoyalGame = {
  gameLogin: {},
  isGameLoginLoading: false,
} as IRoyalGame

export function RoyalGamingReducer(
  state = initialState,
  action: IRoyalGamingReducerAction,
) {
  switch (action.type) {
    case ROYAL_GAME_LOGIN:
      return {
        ...state,
        gameLogin: action.payload,
        isGameLoginLoading: action.loading,
      }

    default:
      return state
  }
}

import { IOpenBet } from '../../../pages/Game/betslip/NewBetSlip'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import { convertEventIdWithEventDataMap } from '../../../utils/internalUtils'
import { GetReduxStateFn } from '../../type'
import { Dispatch } from '@reduxjs/toolkit'

export const UPDATE_USER_BET = 'UPDATE_USER_BET'
export const UPDATE_USER_CURRENT_BET = 'UPDATE_USER_CURRENT_BET'

export const getUserBet = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {}
}

export const addUserBet = (odd: IOpenBet) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let eventMap: Map<string, IOpenBet[]> = getState().userBet
    let user_bet = convertEventIdWithEventDataMap(eventMap)
    if (!user_bet || !user_bet.size || user_bet.size === 0) {
      user_bet = new Map<string, IOpenBet[]>()
      user_bet.set(odd.market.eventId, [odd])
    } else if (!user_bet.get(odd.market.eventId)) {
      user_bet.set(odd.market.eventId, [odd])
    } else {
      let tmpOdd = user_bet.get(odd.market.eventId)
      tmpOdd?.push(odd)
      user_bet.set(odd.market.eventId, tmpOdd ? tmpOdd : [odd])
    }
    dispatch({
      type: UPDATE_USER_BET,
      userBet: user_bet,
    })
  }
}

import { IUserLogin } from '../../types/interfaces/login'
import {
  ASSIGN_TO,
  GET_CASINO_GAMES_DATA,
  GET_CASINO_LIVE_CASINO_GAMES_DATA,
  GET_CASINO_SLOT_GAMES_DATA,
  GET_CASINO_TOTAL_GAMES_ID_DATA,
  GET_CASINO_VIRTUAL_CASINO_GAMES_DATA,
  GET_COMPETITION_DATA,
  GET_EVENTS_DATA,
  GET_CRASH_GAMES_DATA,
  GET_LOTTERY_GAMES_DATA,
  GET_MARKET_DATA,
  GET_ROYAL_CASINO_GAMES_DATA,
  GET_SPORTS_DATA,
  SAVE_GAME,
  CASINO_LOADED,
} from '../actions'
import {
  SAVE_COMPETITION_REQUEST,
  SAVE_EVENT_REQUEST,
  SAVE_MARKET_REQUEST,
} from '../actions/administratorAction/gameAction'

export interface ISetAdminGame {
  type: string
  mapping: any
  data: IUserLogin
  loading: boolean
  payload: any
  pagination: any
}

export interface IAdminGames {
  liveCasinoData: any
  liveCasinoLoading: false
  casinoLoader: boolean
  virtualCasinoData: any
  virtualCasinoLoading: boolean
  slotGamesData: any
  slotGamesLoading: boolean
  crashGamesData: any
  casinoTotalGamesId: any
  casinoTotalGamesIdLoading: boolean
  crashGamesLoading: boolean
  lotteryGamesData: any
  lotteryGamesLoading: boolean
  casinoDataMapping: any[]
  royalCasinoData: any
  royalCasinoPagination: any
  royalCasinoLoading: boolean
  casinoData: any
  casinoPagination: any
  casinoLoading: boolean
  sportsData: any
  competitionRequest: any
  eventRequest: any
  marketRequest: any
  getSportsLoading: boolean
  competitionData: any
  getCompetitionLoading: boolean
  eventsData: any
  getEventsLoading: boolean
  marketData: any
  getMarketLoading: boolean
  saveLoading: boolean
  assignToLoading: boolean
}

const initialState: IAdminGames = {
  crashGamesData: [],
  crashGamesLoading: false,
  liveCasinoData: [],
  liveCasinoLoading: false,
  casinoLoader: false,
  lotteryGamesData: [],
  lotteryGamesLoading: false,
  casinoTotalGamesId: [],
  casinoTotalGamesIdLoading: false,
  slotGamesData: [],
  slotGamesLoading: false,
  virtualCasinoData: [],
  virtualCasinoLoading: false,
  royalCasinoData: [],
  casinoDataMapping: [],
  royalCasinoLoading: false,
  royalCasinoPagination: {},
  casinoData: [],
  casinoLoading: false,
  casinoPagination: {},
  sportsData: [],
  competitionRequest: {},
  eventRequest: {},
  marketRequest: {},
  getSportsLoading: false,
  competitionData: [],
  getCompetitionLoading: false,
  eventsData: [],
  getEventsLoading: false,
  marketData: [],
  getMarketLoading: false,
  saveLoading: false,
  assignToLoading: false,
}

export default function (state = initialState, action: ISetAdminGame) {
  switch (action.type) {
    case GET_SPORTS_DATA: {
      return {
        ...state,
        sportsData: action.data,
        getSportsLoading: action.loading,
      }
    }

    case GET_CASINO_GAMES_DATA: {
      return {
        ...state,
        casinoDataMapping: action.mapping
          ? { ...Array.from(action.mapping) }
          : [],
        casinoData: action.payload,
        casinoPagination: action.pagination,
        casinoLoading: action.loading,
      }
    }

    case CASINO_LOADED: {
      return {
        ...state,
        casinoLoader: action.payload,
      }
    }

    case GET_ROYAL_CASINO_GAMES_DATA: {
      return {
        ...state,
        royalCasinoData: action.payload,
        royalCasinoPagination: action.pagination,
        royalCasinoLoading: action.loading,
      }
    }

    case GET_CASINO_LIVE_CASINO_GAMES_DATA: {
      return {
        ...state,
        liveCasinoData: action.payload,
        liveCasinoLoading: action.loading,
      }
    }

    case GET_CASINO_VIRTUAL_CASINO_GAMES_DATA: {
      return {
        ...state,
        virtualCasinoData: action.payload,
        virtualCasinoLoading: action.loading,
      }
    }

    case GET_CASINO_TOTAL_GAMES_ID_DATA: {
      return {
        ...state,
        casinoTotalGamesId: action.payload,
        casinoTotalGamesIdLoading: action.loading,
      }
    }

    case GET_CASINO_SLOT_GAMES_DATA: {
      return {
        ...state,
        slotGamesData: action.payload,
        slotGamesLoading: action.loading,
      }
    }

    case GET_CRASH_GAMES_DATA: {
      return {
        ...state,
        crashGamesData: action.payload,
        crashGamesLoading: action.loading,
      }
    }

    case GET_LOTTERY_GAMES_DATA: {
      return {
        ...state,
        lotteryGamesData: action.payload,
        lotteryGamesLoading: action.loading,
      }
    }

    case SAVE_COMPETITION_REQUEST: {
      return {
        ...state,
        competitionRequest: action.payload,
      }
    }

    case SAVE_EVENT_REQUEST: {
      return {
        ...state,
        eventRequest: action.payload,
      }
    }

    case SAVE_MARKET_REQUEST: {
      return {
        ...state,
        marketRequest: action.payload,
      }
    }

    case GET_COMPETITION_DATA: {
      return {
        ...state,
        competitionData: action.data,
        getCompetitionLoading: action.loading,
      }
    }
    case GET_EVENTS_DATA: {
      return {
        ...state,
        eventsData: action.data,
        getEventsLoading: action.loading,
      }
    }

    case GET_MARKET_DATA: {
      return {
        ...state,
        marketData: action.data,
        getMarketLoading: action.loading,
      }
    }

    case SAVE_GAME: {
      return {
        ...state,
        saveLoading: action.loading,
      }
    }

    case ASSIGN_TO: {
      return {
        ...state,
        assignToLoading: action.loading,
      }
    }

    default:
      return state
  }
}

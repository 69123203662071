import { SAVE_NEWSLETTER } from '../../actions/administratorAction/newsLetterAction'

const initialState: any = {
  newsLetter: [],
}

export default function (
  state = initialState,
  action: { payload: any; type: string },
) {
  switch (action.type) {
    case SAVE_NEWSLETTER: {
      return {
        ...state,
        newsLetter: action.payload,
      }
    }

    default:
      return state
  }
}

import React, { useEffect, useState, useMemo } from 'react'
import {
  dateFormatter,
  formattedTimeZoneOffset,
  timeFormatter,
} from '../../../utils'

interface IShowDateTime {
  userData: any
}

const ShowDateTime = (props: IShowDateTime) => {
  const [formattedDateLocal, setFormattedDateLocal] = useState(
    dateFormatter.format(new Date()),
  )
  const [formattedTimeLocal, setFormattedTimeLocal] = useState(
    timeFormatter.format(new Date()),
  )
  const [formattedTimeZoneOffsetLocal, setFormattedTimeZoneOffsetLocal] =
    useState(formattedTimeZoneOffset)

  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedDateLocal(dateFormatter.format(new Date()))
      setFormattedTimeLocal(timeFormatter.format(new Date()))
      setFormattedTimeZoneOffsetLocal(formattedTimeZoneOffset)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const memoizedComponent = useMemo(
    () => (
      <>
        <span
          className={`xs:ml-3 lg:ml-0 font-primary text-base font-medium leading-[30px] tracking-normal text-left ${props.userData?.userType === 'user' ? 'md:text-xs lg:text-xs xl:text-base' : ' '}`}
        >
          {formattedDateLocal}
        </span>

        <span className={`xs:mr-1`}> </span>
        <span
          className={`text-xl lg:w-[80px] xl:w-[130px] inline-block font-bold leading-[30px] tracking-normal text-left ${props.userData?.userType === 'user' ? 'md:text-sm lg:text-sm xl:text-xl' : ''}`}
        >
          {formattedTimeLocal}
        </span>

        <span
          className={`xs:mr-1 mr-1 lg:ml-0 font-primary text-base font-normal leading-[30px] tracking-normal text-left ${props.userData?.userType === 'user' ? 'md:text-xs lg:text-xs xl:text-base' : ''}`}
        >
          {formattedTimeZoneOffsetLocal}
        </span>
      </>
    ),
    [
      formattedDateLocal,
      formattedTimeLocal,
      formattedTimeZoneOffsetLocal,
      props.userData?.userType,
    ],
  )

  return <div>{memoizedComponent}</div>
}

export default React.memo(ShowDateTime)

import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../..'
import { HEADERS, makeGetHttpCallAsync } from '../../../api'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import { getCorrectUserType } from '../../../utils'

export const SAVE_NEWSLETTER = 'SAVE_NEWSLETTER'

export const saveNewsLetter = (currentDomain: ICurrentDomain, payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const userType = await localStorage.getItem(HEADERS.userType)
    makeGetHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/newsletter`,
      payload,
      currentDomain.type,
      false,
      true,
      '',
    ).then((response: any) => {
      dispatch({
        type: SAVE_NEWSLETTER,
        payload: response.docs,
      })
    })
  }
}

import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IsMarketInPlay } from '../../../../utils/internalUtils'

export const UpcommingCountDown = () => {
  const { selectedEvent } = useSelector((state: any) => state.appConfiguration)

  // Use useMemo to avoid recreating the eventDate on each render
  const eventDate = useMemo(
    () => new Date(selectedEvent.openDate),
    [selectedEvent.openDate],
  )

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date()
      const difference = eventDate.getTime() - now.getTime()

      let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 }

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        }
      }
      return timeLeft
    }

    const updateTimeLeft = () => {
      const newTimeLeft = calculateTimeLeft()
      setTimeLeft(newTimeLeft)
    }

    updateTimeLeft() // Call immediately to set initial time left
    const timer = setInterval(updateTimeLeft, 1000)

    return () => clearInterval(timer)
  }, [eventDate])

  if (IsMarketInPlay(selectedEvent.openDate)) return null

  return (
    <div>
      <div className='text-center dark:text-white text-black mb-1 bold'>
        MATCH WILL BE LIVE IN
      </div>
      <div className='flex justify-center mb-2'>
        <div
          className='p-4 dark:text-white text-black'
          style={{ border: 'solid 0.5px', borderRadius: '0.5rem' }}
        >
          <div className='text-center'>{timeLeft.days}</div>
          <div>Day</div>
        </div>
        <div
          className='ml-3 p-4 dark:text-white text-black'
          style={{ border: 'solid 0.5px', borderRadius: '0.5rem' }}
        >
          <div className='text-center'>{timeLeft.hours}</div>
          <div>Hrs</div>
        </div>
        <div
          className='ml-3 p-4 dark:text-white text-black'
          style={{ border: 'solid 0.5px', borderRadius: '0.5rem' }}
        >
          <div className='text-center'>{timeLeft.minutes}</div>
          <div>Min</div>
        </div>
        <div
          className='ml-3 p-4 dark:text-white text-black'
          style={{ border: 'solid 0.5px', borderRadius: '0.5rem' }}
        >
          <div className='text-center'>{timeLeft.seconds}</div>
          <div>Sec</div>
        </div>
      </div>
    </div>
  )
}

import { IScoreData } from '../../../../redux/reducers/userReducer/ScoreReducer'
import { TennisScore } from './score/TennisScore'
import { SoccerScore } from './score/SoccerScore'
import { CricketScore } from './score/CricketScore'
import { useMediaQuery } from '@mui/material'

interface IMatchScore {
  scoreData: IScoreData
  eventTypeId: any
  isMobile?: boolean
  isScoreCardShow: boolean
  setIsScoreCardShow: any
  iframe?: string
}

export function extractNumberFromString(str: string) {
  const match = str && str.match(/\d+\.\d+/)
  if (match) {
    return parseFloat(match[0])
  } else {
    return NaN
  }
}

export const MatchScore = (props: IMatchScore) => {
  const isLargerScreen = useMediaQuery('(min-width:1880px)')
  const isBetweenLessLargerScreen = useMediaQuery('(min-width:1750px)')

  const getWidth = () => {
    if (isLargerScreen) {
      return '500px'
    }

    if (isBetweenLessLargerScreen) {
      return '350px'
    }

    return '360px'
  }
  return (
    <div
      className={`${props.isMobile ? 'md:hidden' : 'hidden md:block'} border border-gray-100 xs:border-none shadow-2xl dark:border-transparent dark:gradient-border`}
    >
      {props.eventTypeId && props.eventTypeId === '4' && (
        <CricketScore scoreData={props.scoreData} isMobile={!!props.isMobile} />
      )}

      {props.eventTypeId && props.eventTypeId === '2' && (
        <TennisScore scoreData={props.scoreData} />
      )}

      {props.eventTypeId && props.eventTypeId === '1' && (
        <SoccerScore scoreData={props.scoreData} />
      )}

      <>
        {!props.isMobile && (
          <div className='cursor-pointer flex text-center'>
            <div
              onClick={() => props.setIsScoreCardShow(false)}
              className={`bg-[${props.isScoreCardShow ? '' : 'var(--theme-color)'}] ${props.isScoreCardShow ? 'dark:text-white text-black' : '!text-white'} shadow  font-bold w-1/2 p-2`}
            >
              Market
            </div>
            <div
              onClick={() => props.setIsScoreCardShow(true)}
              className={`bg-[${!props.isScoreCardShow ? '' : 'var(--theme-color)'}] ${props.isScoreCardShow ? '!text-white' : 'dark:text-white text-black'}  shadow bold w-1/2 p-2 `}
            >
              Scoreboard & Statistics
            </div>
          </div>
        )}
      </>

      {props.isScoreCardShow && (
        <>
          {props.iframe ? (
            <div style={{ height: getWidth() }}>
              <iframe
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  overflow: 'hidden',
                }}
                src={props.iframe}
              />
            </div>
          ) : (
            <div className='text-black dark:text-white bold p-4 text-center'>
              No Score Card Found
            </div>
          )}
        </>
      )}
    </div>
  )
}

import { Fragment } from 'react/jsx-runtime'
import {
  BetFairMarket,
  BetFairMarketHeader,
} from '../../marketType/BetFairMarket'
import { useSelector } from 'react-redux'

interface IRunBetfairMarket {
  tempMarket: any
  market: any
  addFavoriteMarketList: any
  deleteFavoriteMarket: any
  marketFavoriteList: any
  onMarketClick: any
  hashMapMarket: any
  currentDomain: any
  isLogin: any
  currentBet: any
  isDrawerOpen: any
  betSlip: any
  showFullNumber?: boolean
  setIsDrawerOpen: any
  tokenExpire: boolean
}

export const RunBetfairMarket = (props: IRunBetfairMarket) => {
  const { isBetSlipLoading } = useSelector((state: any) => state.betSlip)
  return (
    <>
      <BetFairMarketHeader
        isBetSlipLoading={isBetSlipLoading}
        setIsDrawerOpen={props.setIsDrawerOpen}
        tempMarket={props.tempMarket}
        isCashout={props.market.marketId.cashout}
        hashMapMarket={props.hashMapMarket}
        currentDomain={props.currentDomain}
        isLogin={props.isLogin}
        onMarketClick={props.onMarketClick}
        addFavoriteMarketList={props.addFavoriteMarketList}
        deleteFavoriteMarket={props.deleteFavoriteMarket}
        marketFavoriteList={props.marketFavoriteList}
        {...props.tempMarket}
      />
      <Fragment key={props.tempMarket.id}>
        <BetFairMarket
          {...props.market}
          isBetSlipLoading={isBetSlipLoading}
          tokenExpire={props.tokenExpire}
          showFullNumber={props.showFullNumber}
          betSlipStake={props.betSlip.betSlipStake}
          setIsDrawerOpen={props.setIsDrawerOpen}
          isDrawerOpen={props.isDrawerOpen}
          status={props.tempMarket.status}
          tempMarket={props.tempMarket}
          currentBet={props.currentBet}
          hashMapMarket={props.hashMapMarket}
          isLogin={props.isLogin}
          onMarketClick={props.onMarketClick}
        />
      </Fragment>
    </>
  )
}

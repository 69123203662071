import { ISetCommon } from '../../types/common'
import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  READ_NOTIFICATION,
  SEND_NOTIFICATION,
} from '../actions'

const initialState: INotificationInitial = {
  notifications: [],
  getNotificationsLoading: false,
  deleteNotificationLoading: false,
  sendNotificationLoading: false,
  readNotificationLoading: false,
}

export interface INotificationInitial {
  notifications: any
  getNotificationsLoading: boolean
  deleteNotificationLoading: boolean
  sendNotificationLoading: boolean
  readNotificationLoading: boolean
}

export default function (state = initialState, action: ISetCommon) {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.data,
        getNotificationsLoading: action.loading,
      }
    }

    case DELETE_NOTIFICATION: {
      return {
        ...state,
        deleteNotificationLoading: action.loading,
      }
    }

    case SEND_NOTIFICATION: {
      return {
        ...state,
        sendNotificationLoading: action.loading,
      }
    }

    case READ_NOTIFICATION: {
      return {
        ...state,
        readNotificationLoading: action.loading,
      }
    }

    default:
      return state
  }
}

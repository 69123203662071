import { FC, useEffect, useState } from 'react'
import { emptyFunction, getAssetUrl } from '../../../../utils'
import { LiveTelevision } from './Television'
import Marquee from 'react-fast-marquee'
import { useSelector } from 'react-redux'
import { IScoreData } from '../../../../redux/reducers/userReducer/ScoreReducer'
import { MatchScore } from './MatchScore'
import { OpenMatchBet } from '../../../Game/betslip/stakes/OpenMatchBetContent'
import {
  isMobileScreensix,
  isMobileScreensixSmall,
} from '../../../../utils/internalUtils'
import { useNavigate } from 'react-router-dom'
import { createMapFromArrayLiveTv } from '../../../../redux/actions/matchesAction/SchedulesMatchesAction'
import { toast } from 'react-toastify'
const play = getAssetUrl('game-play/play.png')

interface INewGameDetail {
  gameName: string
  score: boolean
  isUser?: boolean
  openBet: any
  screenPlay: boolean
  eventId: string
  openBetPagination: any
  scoreData: IScoreData
  competitionName: string
  eventTypeId: string
  isLiveTvDisable?: boolean
  openDate: Date
}

const SelectItem = [
  { title: 'MARKET' },
  { title: 'SCORE' },
  { title: 'LIVE' },
  { title: 'OPEN BETS' },
]

export const NewGameDetail: FC<INewGameDetail> = (props: INewGameDetail) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0)

  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const handleItemClick = (index: number) => {
    // Update the selected item index when an item is clicked
    setSelectedItemIndex(index)
  }

  const headerBottom = useSelector(
    (state: any) => state.appThemeConfiguration.headerBottom,
  )

  const getbackgroundForScoreMatch = () => {
    if (props.eventTypeId === '2') {
      return getAssetUrl('header/score-background/Tennis.webp')
    } else if (props.eventTypeId === '4') {
      return getAssetUrl('header/score-background/Cricket.webp')
    } else {
      return getAssetUrl('header/score-background/Football.webp')
    }
  }

  const [screenPlayLocal, setScreenPlayLocal] = useState<any>(new Map())
  const liveTv = useSelector((state: any) => state.inPlayMatches.liveTvMatches)

  useEffect(() => {
    let screenPlayLocal = createMapFromArrayLiveTv(liveTv)
    setScreenPlayLocal(screenPlayLocal)
  }, [liveTv])

  const getTeamNameWithScore = () => {
    let teams = props.scoreData?.score?.teams
    let scoreData = []
    if (teams) {
      for (let index = 0; index < teams.length; index++) {
        scoreData.push({
          score: teams[index].score,
          teamName: teams[index].team_name,
        })
      }
    }

    return scoreData
  }

  const copyMarketName = () => {
    navigator.clipboard.writeText(props.gameName)
    toast.success(`${_getLocalString('LOC_COPIED')} ${props.gameName}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    })
  }

  return (
    <div>
      <div className='mobile-score-background-container'>
        {isMobileScreensix() && (
          <div
            className='mobile-score-background'
            style={{
              backgroundImage: `url(${getbackgroundForScoreMatch()})`,
            }}
          ></div>
        )}
        <div className={'p-1 mobile-score-background-content'}>
          <div
            className={`flex justify-between px-4 ${isMobileScreensix() ? 'text-white' : 'text-black'} md:px-0 dark:text-white xs:flex-col sm:flex-row`}
          >
            <div className='flex flex-col'>
              <div className='flex items-center pointer'>
                <img
                  src={play}
                  onClick={copyMarketName}
                  alt='Play Icon'
                  className='w-[12px] h-[13px] mr-2'
                />
                <div className='font-dm_sans xs:text-[18px] sm:text-3xl md:text-[29px] lg:text-[29px] xl:text-[29px]  mt-1 font-medium leading-[25px] tracking-normal text-left'>
                  {props.gameName}
                  <div className='text-[14px]'>{props.competitionName}</div>
                </div>
              </div>
            </div>
            <div className='hidden md:block'>
              <span className='mr-2 '>
                {new Date(props.openDate).toLocaleDateString(undefined, {
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  weekday: 'short',
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })}
              </span>
              <span>
                {new Date(props.openDate).toLocaleTimeString(undefined, {
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
          </div>
          <div className='ml-10 dark:text-white xs:text-white sm:text-black text-black md:hidden'>
            <span className='mr-2 '>
              {new Date(props.openDate).toLocaleDateString(undefined, {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                weekday: 'short',
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              })}
            </span>
            <span>
              {new Date(props.openDate).toLocaleTimeString(undefined, {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          </div>
          {props.score && props.scoreData && props.scoreData.score && (
            <div
              className={`my-1 ml-10 xs:text-white dark:text-white sm:text-black md:ml-6 md:my-4 dark:text-white`}
            >
              {
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {getTeamNameWithScore() &&
                    getTeamNameWithScore().map((team: any) => (
                      <div>
                        <p className='bold font-primary text-lg font-normal leading-[25px] tracking-normal text-left'>
                          {team.teamName}
                        </p>
                        <p className='bold font-primary text-lg font-normal leading-[20px] tracking-normal text-left'>
                          {team.score}
                        </p>
                      </div>
                    ))}
                </div>
              }
              {props.score && (
                <MatchScore
                  isScoreCardShow={false}
                  setIsScoreCardShow={emptyFunction}
                  iframe={props.scoreData.iframeUrl}
                  eventTypeId={props.eventTypeId}
                  isMobile={true}
                  scoreData={props.scoreData}
                />
              )}
            </div>
          )}
          {isMobileScreensix() && (
            <div className='bold xs:hidden block my-2 ml-10'>
              <span className='mr-2 '>
                {new Date(props.openDate).toLocaleDateString(undefined, {
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  weekday: 'short',
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })}
              </span>
              <span>
                {new Date(props.openDate).toLocaleTimeString(undefined, {
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className='dark:bg-[var(--added-box)] md:hidden  bg-[var(--sideNavBar-border-firstColor)] shadow flex justify-between items-center  px-4 py-1'>
        {SelectItem.map((item: any, index: any) => (
          <p
            key={index}
            className={`cursor-pointer ${selectedItemIndex === index ? 'border-b-2 border-red-500 ' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            {item.title === 'OPEN BETS'
              ? `OPEN BETS (${props.openBetPagination && props.openBetPagination.totalDocs ? props.openBetPagination.totalDocs : props.openBet.length})`
              : item.title}
          </p>
        ))}
      </div>

      {selectedItemIndex === 1 && (
        <div className='px-4 my-2 md:hidden'>
          <>
            {props.scoreData.iframeUrl && (
              <div
                style={{
                  height: isMobileScreensix()
                    ? isMobileScreensixSmall()
                      ? '250px'
                      : '280px'
                    : '320px',
                }}
              >
                {props.scoreData.iframeUrl ? (
                  <iframe
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      overflow: 'hidden',
                    }}
                    src={props.scoreData.iframeUrl}
                  />
                ) : (
                  <div className='text-black dark:text-white p-4 text-center'>
                    No Score Card Found
                  </div>
                )}
              </div>
            )}
          </>
        </div>
      )}

      {selectedItemIndex === 2 && !props.isLiveTvDisable && (
        <div className='px-4 my-2 md:hidden'>
          <LiveTelevision
            closeTv={props.isLiveTvDisable}
            isLive={props.screenPlay}
            eventId={screenPlayLocal[props.eventId]}
            marketId={''}
            eventTypeId={props.eventTypeId}
          />
        </div>
      )}

      {selectedItemIndex === 3 && (
        <div className='px-4 my-2 md:hidden'>
          <OpenMatchBet
            isShowBetForThisEvent={true}
            isShowBetInfo={false}
            marketStatus={props.openBet}
          />
        </div>
      )}

      <div className='bg-[var(--header-bottom-marquee-bg)]  text-center py-1 md:hidden flex justify-center'>
        <div className='text-[color:--header-bottom-marquee-text]  w-[80%] '>
          <Marquee className='!text-[13px] font-medium leading-[87px] tracking-[0.03em] text-left text-[--header-bottom-marquee-text]'>
            {headerBottom?.marqueeText}
          </Marquee>
        </div>
      </div>
    </div>
  )
}

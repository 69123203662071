import { DisabledByDefault } from '@mui/icons-material'
import { Modal } from '@mui/material'
import { isMobileScreensix } from '../../../../utils/internalUtils'
import { get2Decimal } from '../../../../utils'

interface IBetConfirmationModal {
  profit: number
  odd: number
  oddType: string
  openConfirmBets: boolean
  liability: number
  placeOrder: any
  stakeAmount: number
  setOpenConfirmBets: (openConfirmBets: boolean) => void
}

export const BetConfirmationModal = (props: IBetConfirmationModal) => {
  const placeOrder = () => {
    props.placeOrder(true)
  }

  return (
    <Modal
      open={props.openConfirmBets}
      onClose={() => {
        props.setOpenConfirmBets(false)
      }}
      className='absolute top-0 right-0 m-2 flex items-center justify-center  sm:px-0'
    >
      <div
        className={` flex flex-col gap-4 p-6 bg-white rounded-lg w-[400px] justify-center ${
          props.oddType == 'BACK' ? 'bg-[#fff]' : 'bg-[#fff]'
        }`}
      >
        <div className='mb-2 flex justify-between items-center w-full'>
          <div className='font-primary text-black text-2xl font-bold leading-5 tracking-normal text-left'>
            Betting Confirmation
          </div>

          <DisabledByDefault
            onClick={() => {
              props.setOpenConfirmBets(false)
            }}
            className='w-[20px] h-[20px] text-[var(--inPlay-match-gradientFrom)] cursor-pointer'
          />
        </div>

        <div
          className={`text-[#2D2D2D] grid grid-cols-${isMobileScreensix() ? 2 : 3} w-full font-dm_sans text-[17px] text-center`}
        >
          <div className='border py-2'>
            <div className='font-bold text-[#a2a2a7]'>Odds</div>
            <div className='font-bold'>{get2Decimal(props.odd)}</div>
          </div>
          <div className='border py-2'>
            <div className='font-bold text-[#a2a2a7]'>Stake</div>
            <div className='font-bold'>{get2Decimal(props.stakeAmount)}</div>
          </div>

          <div className='border py-2'>
            <div className='font-bold text-[#a2a2a7]'>{'Profit'}</div>
            <div className='font-bold text-green-400'>
              {get2Decimal(props.profit)}
            </div>
          </div>
          <div className='border py-2'>
            <div className='font-bold text-[#a2a2a7]'>{'Liability'}</div>
            <div className='font-bold text-green-400'>
              {get2Decimal(props.liability)}
            </div>
          </div>
        </div>

        <div className='flex justify-center items-center mt-4 w-full'>
          <div
            className={` bg-gradient-to-r from-[--button-color-1] to-[--button-color-2]   border shadow-[0px_4px_4px_var(--theme-color)] w-full text-[color:var(--fontColor)] font-bold text-center cursor-pointer leading-[19px] p-1 tracking-[0.8px] rounded-[5px] text-lg border-solid border-[var(--theme-color)]
                    `}
            onClick={placeOrder}
          >
            Place Bet
            <div className='font-medium text-[10px]  text-white-400'>
              Place Bet now & Get Profit:
              {props.profit}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

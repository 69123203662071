import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import { makePostHttpCallAsync } from '../../api/httpMethod'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'

export const SET_SIGNUP_LOADER = '@SIGNUP/SET_IS_LOGIN_LOADER'

export const signUpUser = (
  payload: any,
  navigate: any,
  closeModal: (isOpen: boolean) => void,
  currentDomain: ICurrentDomain,
  cb: () => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: SET_SIGNUP_LOADER, payload: true })

      makePostHttpCallAsync(
        dispatch,
        'api/auth/signup',
        currentDomain.type,
        payload,
        false,
        false,
        'application/x-www-form-urlencoded',
        true,
        'Signup Successfull',
      )
        .then((response: any) => {
          closeModal(false)
          navigate('/')
          cb && cb()
        })
        .catch(async (error: any) => {
          let err = await error
          cb && cb()
        })
    } catch (error: any) {
    } finally {
      dispatch({ type: SET_SIGNUP_LOADER, payload: false })
      cb && cb()
    }
  }
}

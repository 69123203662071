import { Dispatch } from '@reduxjs/toolkit'
import {
  URLType,
  makeDeleteHttpCallAsync,
  makeGetHttpCallAsync,
  makePostHttpCallAsync,
} from '../../../api'
import { GetReduxStateFn } from '../../type'

export const GET_HIGHTLIGHT_MATCH = '@HIGHTLIGHT/GET_HIGHTLIGHT_MATCH'
export const ADD_HIGHTLIGHT_MATCH = '@HIGHTLIGHT/UPDATE_HIGHTLIGHT_MATCH'
export const DELETE_HIGHTLIGHT_MATCH = '@HIGHTLIGHT/DELETE_HIGHTLIGHT_MATCH'

export interface IHightLightAction {
  type?: string
  eventIds: string[]
}

export const addHightLightEvent = (payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePostHttpCallAsync(
      dispatch,
      'api/administrator/game/highlight',
      URLType.master,
      payload,
      false,
      true,
      '',
      true,
      'Added Successfully',
    ).then((response: any) => {
      dispatch(getHighLightMatch() as any)
    })
  }
}

export const deleteHightLightEvent = (payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeDeleteHttpCallAsync(
      dispatch,
      'api/administrator/game/highlight',
      payload,
      URLType.master,
      false,
      true,
      '',
      true,
      'deleted Successfully',
    ).then((response: any) => {
      dispatch(getHighLightMatch() as any)
    })
  }
}

export const getHighLightMatch = () => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeGetHttpCallAsync(
      dispatch,
      'api/common/game/highlight',
      '',
      URLType.master,
      false,
      true,
      undefined,
      false,
    )
      .then((response: any) => {
        dispatch({
          type: GET_HIGHTLIGHT_MATCH,
          eventIds: response.docs,
        })
      })
      .catch(() => {
        dispatch({
          type: GET_HIGHTLIGHT_MATCH,
          eventIds: [],
        })
      })
  }
}

export const getAllEvents = (
  eventIds: any,
  gameManagementCommon: any,
  noHighLight: boolean,
  eventsIdsCount: number,
) => {
  const filteredEvents = []
  let marketIds = ''
  let eventsIdsCountLocal = eventsIdsCount

  for (const sport of gameManagementCommon) {
    for (const competition of sport.competition) {
      for (const event of competition.event) {
        if (noHighLight || eventIds.includes(event.id)) {
          let market = getMatchOddsMarket(event)
          if (!(market && Object.keys(market).length)) {
            market = event.market[0]
          }

          marketIds += `${market._id};`
          filteredEvents.push({
            ...event,
            market: market,
            sportsName: sport.name && sport.name.toLowerCase(),
          })
          eventsIdsCountLocal++
        }

        if (eventsIdsCountLocal > 7) {
          break
        }
      }
    }
  }

  return { filteredEvents: filteredEvents, marketIds: marketIds }
}

export const getMatchOddsMarket = (events: any) => {
  const matchingMarket = events.market.find((market: any) => {
    const name = market.name.toLowerCase().replace(/\s/g, '')
    return name === 'matchodds' || name === 'bookmaker'
  })

  return matchingMarket
}

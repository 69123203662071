import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import { makeGetHttpCallAsync } from '../../api'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'

export const GET_SESSION_CONTROLLER_DATA =
  '@SESSION/GET_SESSION_CONTROLLER_DATA'

export const getSessionControllerData = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const dispatchType = GET_SESSION_CONTROLLER_DATA
    try {
      dispatch({ type: dispatchType, loading: true, data: [] })

      makeGetHttpCallAsync(
        dispatch,
        `api/administrator/user/sessionController`,
        '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: dispatchType,
            loading: false,
            data: response.docs,
          })
        })
        .catch(async (error: any) => {
          let err = await error
        })
    } catch (error: any) {
      dispatch({ type: dispatchType, loading: false })
    }
  }
}

import {
  HEADERS,
  makePatchHttpCallAsync,
  makePutHttpCallAsync,
} from '../../../api'
import { GetReduxStateFn } from '../../type'
import { Dispatch } from '@reduxjs/toolkit'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import { getCorrectUserType } from '../../../utils'

export const DELETE_MARKET_VALUE = '@MARKET/DELETE_MARKET_VALUE'
export const UPDATE_USER = '@USER/UPDATE_USER'

export const changeUserPassword = (
  currentDomain: ICurrentDomain,
  payload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = await localStorage.getItem(HEADERS.userType)
    dispatch({
      type: UPDATE_USER,
      loading: true,
    })
    makePatchHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/user/changePassword`,
      payload,
      '',
      currentDomain.type,
      '',
      true,
      'application/x-www-form-urlencoded',
      true,
      'PATCH',
      'Password Changed Successfull',
    )
      .then((res) => {
        localStorage.removeItem(HEADERS.changePassword)
        dispatch({
          type: UPDATE_USER,
          loading: false,
        })
      })
      .catch(() => {
        dispatch({
          type: UPDATE_USER,
          loading: false,
        })
      })
  }
}

export const upateUserProfile = (
  currentDomain: ICurrentDomain,
  payload: any,
  cb: () => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    let userType = await localStorage.getItem(HEADERS.userType)
    dispatch({
      type: UPDATE_USER,
      loading: true,
    })

    makePutHttpCallAsync(
      dispatch,
      `api/${getCorrectUserType(userType)}/user/updateProfile`,
      currentDomain.type,
      JSON.stringify(payload),
      '',
      true,
      '',
      true,
      'Profile Updated',
    )
      .then((res) => {
        cb && cb()
        dispatch({
          type: UPDATE_USER,
          loading: false,
        })
      })
      .catch(() => {
        dispatch({
          type: UPDATE_USER,
          loading: false,
        })
      })
  }
}

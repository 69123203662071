import { Fragment, useEffect, useState } from 'react'
import {
  GET_USER_QUICKBET,
  GET_USER_STAKES,
} from '../../../../redux/actions/userAction'
import { useDispatch } from 'react-redux'
import { makePutHttpCallAsync } from '../../../../api'
import { MdEdit } from 'react-icons/md'
import { SET_USER_QUICK_BET_VALUE } from '../../../../redux'
import { BET_SLIP_STAKE } from '../../../../redux/actions/userAction/betSlipAction'
import { toast } from 'react-toastify'
interface IQuickStakes {
  stakeAmount?: number
  betSlipStake: number
  stakes: any
  quickBet: any
  demoLogin: boolean
  currentDomain: any
  quickBetAmounts: any
  setQuickBetAmounts: any
  editQuickBetStack: any
  setStakeAmount?: Function
  setEditStakes?: Function
  setEditQuickBetStack: Function
}

export const EditQuickStakes = (props: IQuickStakes) => {
  const dispatch = useDispatch()

  const saveQuickBet = () => {
    if (props.demoLogin) {
      return toast.error(_getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'))
    }

    dispatch({ type: GET_USER_QUICKBET, loading: true })

    let tmpStakes = props.quickBetAmounts
    tmpStakes.forEach(function (v: any) {
      delete v._id
    })

    makePutHttpCallAsync(
      dispatch,
      `api/user/quickBet`,
      props.currentDomain.type,
      JSON.stringify({ stake: tmpStakes }),
      false,
      true,
      '',
      true,
      'Updated Sucessfully',
    )
      .then((response: any) => {
        dispatch({
          type: GET_USER_QUICKBET,
          isLoading: false,
          data: response.docs,
        })
      })
      .catch(async (error: any) => {
        dispatch({ type: GET_USER_STAKES, loading: false })
      })
  }
  return (
    <>
      <div className=''>
        <div className='text-black dark:text-white bg-[--clubLoyalty-darkBackground] grid grid-cols-2 px-4 py-3 font-dm_sans font-bold'>
          <span>Pricing Label</span>
          <span>Pricing Amount</span>
        </div>
        <div className='grid grid-cols-2 '>
          {props.quickBetAmounts.map((amount: any, index: number) => (
            <Fragment key={index}>
              <div className='bg-[var(--theme-color)] px-[17px] py-[7px] mt-[5px] mx-[5px] text-[13px] font-dm_sans font-bold h-9'>
                <input
                  className='bg-transparent focus:outline-none w-full dark:text-[#fff] text-black font-bold text-[13px] font-dm_sans'
                  value={amount.label}
                  onChange={(e) => {
                    let newArr = [...props.quickBetAmounts]
                    newArr[index].label = e.target.value
                    props.setQuickBetAmounts(newArr)
                  }}
                />
              </div>
              <div className='bg-[--theme-color] px-[17px] py-[7px] mt-[5px] mr-[5px] text-[13px] font-dm_sans font-bold h-9'>
                <input
                  className='bg-transparent focus:outline-none w-full dark:text-[#fff] text-black font-bold text-[13px] font-dm_sans'
                  value={amount.value}
                  onChange={(e) => {
                    let newArr = [...props.quickBetAmounts]
                    newArr[index].value = parseFloat(e.target.value)
                    props.setQuickBetAmounts(newArr)
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
        <div className='mx-[5px] mt-4'>
          <div
            className='bg-gradient-to-r from-[--button-color-1] to-[--button-color-2]  items-center cursor-pointer h-10 px-[5px] justify-center font-bold text-[15px] text-white w-full flex  rounded-[4px]'
            onClick={() => {
              props.setEditQuickBetStack(false)
              saveQuickBet()
            }}
          >
            Submit
          </div>
        </div>
      </div>
    </>
  )
}

export const QuickStakes = (props: IQuickStakes) => {
  const getIndex = () => {
    if (props.stakeAmount === props.quickBetAmounts[0].value) return 0
    if (props.stakeAmount === props.quickBetAmounts[1].value) return 1
    if (props.stakeAmount === props.quickBetAmounts[2].value) return 2
    return 0
  }

  const [selectedIdx, setSelectedIdx] = useState(getIndex())
  const dispatch = useDispatch()

  useEffect(() => {
    props.setStakeAmount &&
      props.quickBetAmounts.length &&
      props.setStakeAmount(props.quickBetAmounts[0].value)
  }, [])

  return (
    <>
      <div className='flex gap-2 '>
        {props.quickBetAmounts.map((amount: any, i: any) => (
          <div
            key={amount.id}
            onClick={() => {
              dispatch({
                type: SET_USER_QUICK_BET_VALUE,
                payload: amount.value,
              })
              dispatch({
                type: BET_SLIP_STAKE,
                betSlipStake: amount.value,
              })
              props.setStakeAmount && props.setStakeAmount(amount.value)
              setSelectedIdx(i)
            }}
            className={`${
              i % 2 === 1 ? 'dark:bg-white bg-black' : 'dark:bg-white bg-black'
            }  h-[30px] py-[8px] xl:px-[20px] lg:px-[24px] md:px-[24px] px-[24px] leading-[14px] shadow-md rounded-[2px] ${
              i === selectedIdx ? '!text-white !bg-[#066361]' : ''
            } dark:text-[#181818] text-white text-[12px] font-bold text-center cursor-pointer  overflow-hidden text-ellipsis`}
          >
            {amount.label}
          </div>
        ))}
        <div
          className='h-[30px] bg-transparent sm:block hidden border border-[#DF8B32] py-[8px] xl:px-[14px] lg:px-[18px] md:px-[18px] px-[18px] leading-3 shadow-md rounded-[3px] dark:text-[#fff] text-[14px] font-bold text-center cursor-pointer '
          onClick={() => {
            props.setEditQuickBetStack(!props.editQuickBetStack)
            props.setEditStakes && props.setEditStakes(false)
          }}
        >
          Edit
        </div>

        <div
          className='h-[30px] bg-transparent sm:hidden flex justify-center border border-[#DF8B32] items-center leading-3 shadow-md rounded-[3px] dark:text-[#fff] text-[18px] font-bold text-center cursor-pointer min-w-[30px] max-w-[50px]'
          onClick={() => {
            props.setEditQuickBetStack(!props.editQuickBetStack)
            props.setEditStakes && props.setEditStakes(false)
          }}
        >
          <MdEdit className='text-[#DF8B32]' />
        </div>
      </div>
    </>
  )
}

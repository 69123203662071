import { Dispatch } from '@reduxjs/toolkit'
import { GET_ACCOUNT_LIST, GetReduxStateFn } from '../type'
import { HEADERS, makeGetHttpCallAsync } from '../../api'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'

export const GET_BANK_DETAILS = '@BANK/GET_BANK_DETAILS'

export const getBankData = (currentDomain: ICurrentDomain) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      const userType = await localStorage.getItem(HEADERS.userType)
      dispatch({ type: GET_ACCOUNT_LIST, loading: true, data: [] })

      makeGetHttpCallAsync(
        dispatch,
        `api/${userType === 'defaultBranch' ? 'branch' : userType}/user/transaction`,
        '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: GET_ACCOUNT_LIST,
            loading: false,
            data: response.docs,
            page: {
              totalCreditPts: response.totalCreditPts,
              totalPages: response.totalPages,
              totalDocs: response.docs,
              showingFrom: response.showingFrom,
              showingTo: response.showingTo,
              nextPage: response.nextPage,
            },
          })
        })
        .catch(async (error: any) => {
          let err = await error
        })
    } catch (error: any) {
      dispatch({ type: GET_ACCOUNT_LIST, loading: false })
    }
  }
}

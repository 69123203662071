import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import { IMarketStatusInitial } from '../reducers/marketStatusReducer'
import { MARKET_TYPE } from '../../types/enums/gameType'

export const ADD_SESSION_MARKET_VALUE = '@MARKET/ADD_SESSION_MARKET_VALUE'
export const ADD_SPORTS_MARKET_MARKET_VALUE =
  '@MARKET/ADD_SPORTS_MARKET_MARKET_VALUE'
export const ADD_HIGHLIGHTS_MARKET_MARKET_VALUE =
  '@MARKET/ADD_HIGHLIGHTS_MARKET_MARKET_VALUE'
export const ADD_FASTEST_ODDS_MARKET_MARKET_VALUE =
  '@MARKET/ADD_FASTEST_ODDS_MARKET_MARKET_VALUE'
export const ADD_BOOKMAKER_MARKET_VALUE = '@MARKET/ADD_BOOKMAKER_MARKET_VALUE'
export const ADD_BETFAIR_MARKET_VALUE = '@MARKET/ADD_BETFAIR_MARKET_VALUE'
export const ADD_PREMIUM_MARKET_VALUE = '@MARKET/ADD_PREMIUM_MARKET_VALUE'
export const DELETE_MARKET_VALUE = '@MARKET/DELETE_MARKET_VALUE'
export const SAVE_STATUS_MARKETIDS = '@MARKET/SAVE_STATUS_MARKETIDS'
export const SAVE_SEARCH_MARKETIDS = '@MARKET/SAVE_SEARCH_MARKETIDS'
export const SAVE_ONTIME_STATUS_MARKETIDS =
  '@MARKET/SAVE_ONTIME_STATUS_MARKETIDS'

export const AddBookMakerMarketStatus = (
  data: any,
  marketStatus: IMarketStatusInitial,
) => {
  return async (dispatch: Dispatch) => {
    let currentMarketStatus = marketStatus
    if (
      currentMarketStatus &&
      currentMarketStatus.bookMakerMarketStatus.length
    ) {
      deleteItemByMarketId(
        data.marketId,
        currentMarketStatus.bookMakerMarketStatus,
      )
      currentMarketStatus.bookMakerMarketStatus.push(data)
    } else {
      currentMarketStatus.bookMakerMarketStatus = [data]
    }
    dispatch({
      type: ADD_BOOKMAKER_MARKET_VALUE,
      data: currentMarketStatus.bookMakerMarketStatus,
    })
  }
}

export const AddPremiumMarketStatus = (
  data: any,
  marketStatus: IMarketStatusInitial,
) => {
  return async (dispatch: Dispatch) => {
    let currentMarketStatus = marketStatus
    if (currentMarketStatus && currentMarketStatus.premiumMarketStatus.length) {
      deleteItemByMarketId(
        data.marketId,
        currentMarketStatus.premiumMarketStatus,
      )
      currentMarketStatus.premiumMarketStatus.push(data)
    } else {
      currentMarketStatus.premiumMarketStatus = [data]
    }
    dispatch({
      type: ADD_PREMIUM_MARKET_VALUE,
      data: currentMarketStatus.premiumMarketStatus,
    })
  }
}

export const deleteFromMarketStatus = (
  data: any,
  marketStatus: IMarketStatusInitial,
) => {
  let currentMarketStatus = marketStatus
  if (data.marketId.type === MARKET_TYPE.SESSION) {
    if (currentMarketStatus && currentMarketStatus.sessionMarketStatus.length) {
      deleteItemByMarketId(
        data.marketId,
        currentMarketStatus.sessionMarketStatus,
      )
    }
  } else if (data.marketId.type === MARKET_TYPE.BETFAIR) {
    if (currentMarketStatus && currentMarketStatus.betfairMarketStatus.length) {
      deleteItemByMarketId(
        data.marketId,
        currentMarketStatus.betfairMarketStatus,
      )
    }
  } else {
    if (currentMarketStatus && currentMarketStatus.premiumMarketStatus.length) {
      deleteItemByMarketId(
        data.marketId,
        currentMarketStatus.premiumMarketStatus,
      )
    }
  }
}

export const AddSessionMarketStatus = (
  data: any,
  marketStatus: IMarketStatusInitial,
) => {
  return async (dispatch: Dispatch) => {
    let currentMarketStatus = marketStatus
    if (currentMarketStatus && currentMarketStatus.sessionMarketStatus.length) {
      deleteItemByMarketId(
        data.marketId,
        currentMarketStatus.sessionMarketStatus,
      )
      currentMarketStatus.sessionMarketStatus.push(data)
    } else {
      currentMarketStatus.sessionMarketStatus = [data]
    }
    dispatch({
      type: ADD_SESSION_MARKET_VALUE,
      data: currentMarketStatus.sessionMarketStatus,
    })
  }
}

export const AddBetFairMarketStatus = (
  data: any,
  marketStatus: IMarketStatusInitial,
) => {
  return async (dispatch: Dispatch) => {
    let currentMarketStatus = marketStatus
    if (currentMarketStatus && currentMarketStatus.betfairMarketStatus.length) {
      deleteItemByMarketId(
        data.marketId,
        currentMarketStatus.betfairMarketStatus,
      )
      currentMarketStatus.betfairMarketStatus.push(data)
    } else {
      currentMarketStatus.betfairMarketStatus = [data]
    }
    dispatch({
      type: ADD_BETFAIR_MARKET_VALUE,
      betfairData: currentMarketStatus.betfairMarketStatus,
    })
  }
}

export function deleteItemByMarketId(marketid: any, marketStatus: any[]) {
  for (let i = 0; i < marketStatus.length; i++) {
    if (
      (marketStatus[i].marketId &&
        marketStatus[i].marketId.id === marketid.id) ||
      (marketStatus[i].id && marketStatus[i].id === marketid.id)
    ) {
      marketStatus.splice(i, 1)
      break
    }
  }
}

export function checkMarketId(data: any[], id: any) {
  return data.some(function (gfg) {
    return gfg.marketId === id
  })
}

export const removeMarkStatus = () => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    dispatch({ type: DELETE_MARKET_VALUE, data: [] })
  }
}

import { useState } from 'react'
import { NavBarTile2 } from '../../pages/landing/component/Content/NavBarTile2'
import { INavTiles } from './NavTiles'

export const NavTiles3 = (props: INavTiles) => {
  const [isOpen, setIsOpen] = useState<string>('')

  return (
    <div style={{ minWidth: '0rem' }} className='flex flex-col bg-transparent '>
      <NavBarTile2
        name={`Agent Management`}
        icon={'agent'}
        isSelected={props.pathName?.indexOf('help') !== -1}
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        matches={[
          { title: 'Creating a New Agent Account' },
          { title: 'Unlocking an Agent Account' },
          { title: 'Changing the Status of an Agent Account' },
          { title: 'Closing an Agent Account' },
          { title: 'Changing the Credit Limit for an Agent Account' },
          { title: 'Position Taking' },
          { title: 'Position Taking Management' },
          { title: "Viewing a Downline User's Summary" },
        ]}
      />
      <NavBarTile2
        name={`Risk Management`}
        icon={'risk'}
        isSelected={props.pathName?.indexOf('risk') !== -1}
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        matches={[
          { title: 'Net Exposure' },
          { title: 'Bet Ticker' },
          { title: 'Transfer' },
          { title: 'Undo Transfer' },
        ]}
      />
      <NavBarTile2
        name={`Reports`}
        icon={'reports'}
        isSelected={props.pathName?.indexOf('report') !== -1}
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        matches={[
          { title: 'P&L Report by Market' },
          { title: 'P&L Report by Agent' },
          { title: 'Bet List' },
        ]}
      />
      <NavBarTile2
        name={`Account Statement`}
        icon={'statement'}
        isSelected={props.pathName?.indexOf('report') !== -1}
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <NavBarTile2
        name={`Admin Users`}
        icon={'admin'}
        isSelected={props.pathName?.indexOf('report') !== -1}
        isDarkMode={props.isDarkMode}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        matches={[
          { title: 'Creating a New Admin Account' },
          { title: 'Setting Up Access Rights' },
        ]}
      />
    </div>
  )
}

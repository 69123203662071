import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  MARKET_INTERNAL_STATUS_CALL_LOGIN,
  URLType,
  makeGetHttpCallAsync,
} from '../../../../api'
import { replaceSessionLayAndBack } from '../../../../utils/gameUtils/sessionSteps'
import { replaceTeamsObject } from '../../../../utils/gameUtils/bookmaker'
import { useDispatch } from 'react-redux'
import {
  BET_SLIP_STAKE,
  UPDATE_BETHISTORY_EVENT_ID,
  UPDATE_CURRENT_BETSLIP,
} from '../../../../redux/actions/userAction/betSlipAction'
import { GET_BET_HISTORY } from '../../../../redux/actions/reportAction/reportsAction'
import { getEventFromMarket, getHasMap } from '../../../../utils/internalUtils'
import { MARKET_TYPE } from '../../../../types/enums/gameType'
import {
  APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT,
  APP_SELECTED_EVENT,
  APP_SELECTED_EVENT_ID,
  DELETED_MARKET_LIST,
  SET_USER_QUICK_BET,
} from '../../../../redux'
import { ICurrentDomain } from '../../../../types/interfaces/currentDomain'
import {
  SESSION_PROIRITY,
  SESSION_WP_PROIRITY,
  SESSION_XRP_PROIRITY,
} from './Constant'
import { SAVE_SCORE } from '../../../../redux/reducers/userReducer/ScoreReducer'
import { RunBetfairMarket } from './showMatch/RunBetfairMarket'
import {
  ManualBookMakerMarket,
  ManualBookMakerMarketHeader,
} from '../marketType/ManualBookMakerMarket'
import { SiteLoader } from '../../../../Fallback'
import { SessionComponent } from './component/SessionComponent'
import { DrawerComp } from './component/DrawerComp'
import { decrypt } from '../../../../api/authHelper'
import { getCorrectUserType } from '../../../../utils'

export interface IMarketContent {
  deletedEventId: string
  isMarketAddedLoad: any
  selectedEventId: string
  removeMarkStatus: any
  gameManagementThirdParty: any
  isMarketDeleted: any
  commonMarket: any
  betSlip: any
  isMarketDeletedload: any
  deleteFavoriteMarket: any
  tokenExpire: any
  marketStatus: any
  marketFavoriteList: any
  resultDeclaredMarketId: any
  deletedMarketList: any
  useSocket: boolean
  isLogin: boolean
  getGameManagementCommonAction: any
  currentDomain: ICurrentDomain
  addFavoriteMarketList: any
  isDarkMode: boolean
  onMarketClick: any
  userData: any
  modifyMarket: any
  eventIds: any
  removeOpenBet: any
  openBet: any
  eventDetail: any
}

export const MarketContent: FC<IMarketContent> = (props: IMarketContent) => {
  const { id } = useParams()
  const [decryptedId, setDecryptedId] = useState(decrypt(id))

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let localDescrypt = decrypt(id)
    setDecryptedId(localDescrypt)
    if (!decryptedId && props.deletedEventId) {
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: '',
      })
    }

    if (localDescrypt && props.selectedEventId !== decryptedId) {
      props.removeMarkStatus()
      dispatch({
        type: APP_SELECTED_EVENT_ID,
        payload: localDescrypt,
      })
    }

    dispatch({
      type: UPDATE_CURRENT_BETSLIP,
      currentBet: {},
    })
    dispatch({
      type: BET_SLIP_STAKE,
      betSlipStake: 0,
    })
  }, [id])

  const dispatch = useDispatch()

  //Market type
  const [sessionMarket, setSessionMarket] = useState<any[]>([])
  const [fancySessionTotalMarket, setFancySessionTotalMarket] = useState<any[]>(
    [],
  )
  const [fancySessionMarket, setFancySessionMarket] = useState<any[]>([])
  const [fancySessionWPMarket, setFancySessionWPMarket] = useState<any[]>([])
  const [fancySessionXtraMarket, setFancySessionXtraMarket] = useState<any[]>(
    [],
  )

  const [betFairMarket, setBetFairMarket] = useState<any[]>([])
  const [betFairMatchOddsMarket, setBetFairMatchOddsMarket] = useState<any[]>(
    [],
  )

  const [betFairMatchOddsTieMarket, setBetFairMatchOddsTieMarket] = useState<
    any[]
  >([])
  const [betFairToWinTheTossMarket, setBetFairToWinTheTossMarket] = useState<
    any[]
  >([])

  const [betfair25GoalasMarket, setBetfair25GoalsMarket] = useState<any[]>([])
  const [betfair15GoalasMarket, setBetfair15GoalsMarket] = useState<any[]>([])
  const [betfair05GoalsMarket, setBetfair05GoalsMarket] = useState<any[]>([])
  const [betfair35GoalsMarket, setBetfair35GoalsMarket] = useState<any[]>([])
  const [betfairCompletedMatch, setBetfairCompletedMatch] = useState<any[]>([])
  const [betfairTiedMatch, setBetfairTiedmatch] = useState<any[]>([])
  const [bookMakerMarket, setBookMakerMarket] = useState<any[]>([])
  const [premiumMarkets, setPremiumMarkets] = useState<any[]>([])

  const [currentBet, setCurrentBet] = useState<any>(
    Object.keys(props.betSlip.currentBet).length &&
      Object.keys(props.betSlip.currentBet.marketId).length
      ? props.betSlip.currentBet
      : {},
  )

  const [initialLoad, setInitialLoad] = useState(true)
  const [hashMapMarket, setHashMapMarket] = useState<any>(
    getHasMap(props.betSlip.hashMapBet),
  )
  const [sessionMarketMap, setSessionMarketMap] = useState<any>(new Map())

  const [sessionFancyTotalMarketMap, setSessionFancyTotalMarketMap] =
    useState<any>(new Map())
  const [sessionFancyWPMarketMap, setSessionFancyWPMarketMap] = useState<any>(
    new Map(),
  )
  const [sessionFancyXtraMarketMap, setSessionFancyXtraMarketMap] =
    useState<any>(new Map())
  const [sessionFancyMarketMap, setSessionFancyMarketMap] = useState<any>(
    new Map(),
  )

  const [premiumSessionMarketMap, setPremiumSessionMarketMap] = useState<any>(
    new Map(),
  )
  const [noEventPresent, setNoEventPresent] = useState(false)
  const [betFairMarketMap, setBetFairMarketMap] = useState<any>(new Map())
  const [betFairMatchOddsMarketMap, setBetFairMatchOddsMarketMap] =
    useState<any>(new Map())
  const [betFairMatchOddsTieMarketMap, setBetFairMatchOddsTieMap] =
    useState<any>(new Map())
  const [betFairToWinTheTossMap, setBetFairToWinTheTossMap] = useState<any>(
    new Map(),
  )
  const [betFair25GoalsMap, setBetFair25GoalsMap] = useState<any>(new Map())
  const [betFair15GoalsMap, setBetFair15GoalsMap] = useState<any>(new Map())
  const [betFair35GoalsMap, setBetFair35GoalsMap] = useState<any>(new Map())
  const [betFair05GoalsMap, setBetFair05GoalsMap] = useState<any>(new Map())
  const [betfairTiedMatchMap, steBetfairTiedMatchMap] = useState<any>(new Map())
  const [betfairCompletedMatchMap, setBetfairCompletedMatchMap] = useState<any>(
    new Map(),
  )
  const [bookMakerMarketMap, setBookMakerMarketMap] = useState<any>(new Map())
  const [event, setEvent] = useState<any>({
    name: 'Loading ...',
    screenPlay: false,
    competitionName: '',
    openDate: new Date(),
    betOpenDate: new Date(),
    eventTypeId: '',
  })

  const isAdmin = props.userData.userType !== 'user'

  useEffect(() => {
    if (
      Object.keys(props.betSlip.currentBet).length &&
      Object.keys(props.betSlip.currentBet.marketId).length
    ) {
      setCurrentBet(props.betSlip.currentBet)
    } else {
      setIsDrawerOpen(false)
      setCurrentBet({})
    }
  }, [props.betSlip.currentBet])

  useEffect(() => {
    if (props.eventIds && props.eventIds.length) {
      const hasId = props.eventIds.some((obj: any) => obj.includes(decryptedId))
      setNoEventPresent(!hasId)
    }
  }, [props.eventIds && props.eventIds.length])

  useEffect(() => {
    setHashMapMarket(getHasMap(props.betSlip.hashMapBet))
  }, [props.betSlip.hashMapBet])

  useEffect(() => {
    let localSession: any = sessionMarket
    let localFancySession: any = fancySessionMarket
    let localFancyTotalSession: any = fancySessionTotalMarket
    let localFancyXtraSession: any = fancySessionXtraMarket
    let localfancyWPSession: any = fancySessionWPMarket

    if (!!Object.keys(props.resultDeclaredMarketId).length) {
      props.removeOpenBet(props.resultDeclaredMarketId, props.openBet)
      let market = props.resultDeclaredMarketId
      if (market.externalType === 'betfairLine') {
        let localPremiumMarkets = premiumMarkets
        setPremiumMarkets(
          localPremiumMarkets.filter(
            (entry) =>
              entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
          ),
        )
      }
      if (market.type === MARKET_TYPE.SESSION.valueOf()) {
        if (market.externalType === 'bhawani') {
          if (market.priority === -1) {
          } else if (SESSION_PROIRITY.includes(market.priority)) {
            setFancySessionMarket(
              localFancySession.filter(
                (entry: any) =>
                  entry.marketId.id !==
                  props.resultDeclaredMarketId.marketId.id,
              ),
            )
          } else if (SESSION_WP_PROIRITY.includes(market.priority)) {
            setFancySessionWPMarket(
              localfancyWPSession.filter(
                (entry: any) =>
                  entry.marketId.id !==
                  props.resultDeclaredMarketId.marketId.id,
              ),
            )
          } else {
            setFancySessionXtraMarket(
              localFancyXtraSession.filter(
                (entry: any) =>
                  entry.marketId.id !==
                  props.resultDeclaredMarketId.marketId.id,
              ),
            )
          }
          setFancySessionTotalMarket(
            localFancyTotalSession.filter(
              (entry: any) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else {
          setSessionMarket(
            localSession.filter(
              (entry: any) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        }
      } else if (
        market.type === MARKET_TYPE.BETFAIR.valueOf() &&
        !market.marketId.externalType
      ) {
        if (market.marketId.marketName === 'Match Odds') {
          let localBetFairMatchOddsMarket = betFairMatchOddsMarket
          setBetFairMatchOddsMarket(
            localBetFairMatchOddsMarket.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'Match Odds Including Tie') {
          let localBetFairMatchOddsTieMarket = betFairMatchOddsTieMarket
          setBetFairMatchOddsTieMarket(
            localBetFairMatchOddsTieMarket.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'To Win the Toss') {
          let localbetFairToWinTheTossMarket = betFairToWinTheTossMarket
          setBetFairToWinTheTossMarket(
            localbetFairToWinTheTossMarket.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'Over/Under 0.5 Goals') {
          let localbetfair05Market = betfair05GoalsMarket
          setBetfair05GoalsMarket(
            localbetfair05Market.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'Over/Under 1.5 Goals') {
          let localbetfair15Market = betfair15GoalasMarket
          setBetfair15GoalsMarket(
            localbetfair15Market.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'Over/Under 2.5 Goals') {
          let localbetfair25Market = betfair25GoalasMarket
          setBetfair25GoalsMarket(
            localbetfair25Market.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'Over/Under 3.5 Goals') {
          let localbetfair35Market = betfair35GoalsMarket
          setBetfair35GoalsMarket(
            localbetfair35Market.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'Tied Match') {
          let localBetfairTiedMatch = betfairTiedMatch
          setBetfairTiedmatch(
            localBetfairTiedMatch.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else if (market.marketId.marketName === 'Completed Match') {
          let localBetfairCompletedMatch = betfairCompletedMatch
          setBetfairCompletedMatch(
            localBetfairCompletedMatch.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        } else {
          let localBetFairMarket = betFairMarket
          setBetFairMarket(
            localBetFairMarket.filter(
              (entry) =>
                entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
            ),
          )
        }
      } else if (market.type === MARKET_TYPE.BOOKMAKER.valueOf()) {
        let localBookMakerMarket = bookMakerMarket
        setBookMakerMarket(
          localBookMakerMarket.filter(
            (entry) =>
              entry.marketId.id !== props.resultDeclaredMarketId.marketId.id,
          ),
        )
      }
    }
  }, [props.resultDeclaredMarketId])

  const getHashMapForMarket = (markets: any) => {
    const gameHashMap: any = {}
    const localSessionMarketMap = new Map()
    const localFancySessionMarketMap = new Map()
    const localFancySessionTotalMarketMap = new Map()
    const localFancySessionXtraMarketMap = new Map()
    const localFancySessionWPMarketMap = new Map()

    const localBetFairMarketMap = new Map()
    const localBookMakerMarketMap = new Map()
    const localPremiumMarketsMap = new Map()
    const localBetFairMatchOddsMarketMap = new Map()
    const localBetFairMatchOddsTieMap = new Map()
    const localBetFairToWinTheTossMap = new Map()
    const localBetFair05Map = new Map()
    const localBetFair15Map = new Map()
    const localBetFair25Map = new Map()
    const localBetFair35Map = new Map()
    const localBetfairTiedMatchMap = new Map()
    const localbetfairCompletedMap = new Map()
    const localGame = []

    // Markets
    const localSessionMarkets: any[] = []

    const localFancySessionTotalMarkets: any[] = []
    const localFancySessionMarkets: any[] = []
    const localFancySessionWPMarkets: any[] = []
    const localFancySessionXtraMarkets: any[] = []

    const localBetFairMarkets: any[] = []
    const localBetFairMatchOddsMarkets: any[] = []
    const localBetFairMatchOddsTieMarkets: any[] = []
    const localBetFairToWinTheTossMarket: any[] = []
    const localBetFair05Market: any[] = []
    const localBetFair15Market: any[] = []
    const localBetFair25Market: any[] = []
    const localBetFair35Market: any[] = []
    const localBetfairTiedMatchMarket: any[] = []
    const localBetfairCompletedMarket: any[] = []
    const localPremiumMarkets: any[] = []
    let localBookMakerMarkets: any[] = []
    for (const eventObj of markets) {
      // Manal Session Market
      if (!eventObj.marketId || !eventObj.marketActive) {
        continue
      }

      let marketId = eventObj.marketId.id
      if (eventObj.type === MARKET_TYPE.SESSION.valueOf()) {
        if (
          eventObj.marketId.externalType === 'bhawani' &&
          (eventObj.priority === -1 ||
            eventObj.status === 'result' ||
            eventObj.status === 'suspended')
        ) {
        } else if (eventObj.marketId.externalType === 'bhawani') {
          if (SESSION_PROIRITY.includes(eventObj.priority)) {
            localFancySessionMarketMap.set(marketId, eventObj)
            localFancySessionMarkets.push(eventObj)
          } else if (SESSION_WP_PROIRITY.includes(eventObj.priority)) {
            localFancySessionWPMarketMap.set(marketId, eventObj)
            localFancySessionWPMarkets.push(eventObj)
          } else {
            localFancySessionXtraMarketMap.set(marketId, eventObj)
            localFancySessionXtraMarkets.push(eventObj)
          }
          localFancySessionTotalMarketMap.set(marketId, eventObj)
          localFancySessionTotalMarkets.push(eventObj)
        } else {
          localSessionMarkets.push(eventObj)
          localSessionMarketMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.type === MARKET_TYPE.BETFAIR.valueOf() &&
        !eventObj.marketId.externalType
      ) {
        if (eventObj.marketId.marketName === 'Match Odds') {
          localBetFairMatchOddsMarketMap.set(marketId, eventObj)
          localBetFairMatchOddsMarkets.push(eventObj)
        } else if (
          eventObj.marketId.marketName === 'Match Odds Including Tie'
        ) {
          localBetFairMatchOddsTieMarkets.push(eventObj)
          localBetFairMatchOddsTieMap.set(marketId, eventObj)
        } else if (eventObj.marketId.marketName === 'To Win the Toss') {
          localBetFairToWinTheTossMarket.push(eventObj)
          localBetFairToWinTheTossMap.set(marketId, eventObj)
        } else if (eventObj.marketId.marketName === 'Over/Under 0.5 Goals') {
          localBetFair05Market.push(eventObj)
          localBetFair05Map.set(marketId, eventObj)
        } else if (eventObj.marketId.marketName === 'Over/Under 1.5 Goals') {
          localBetFair15Market.push(eventObj)
          localBetFair15Map.set(marketId, eventObj)
        } else if (eventObj.marketId.marketName === 'Over/Under 2.5 Goals') {
          localBetFair25Market.push(eventObj)
          localBetFair25Map.set(marketId, eventObj)
        } else if (eventObj.marketId.marketName === 'Over/Under 3.5 Goals') {
          localBetFair35Market.push(eventObj)
          localBetFair35Map.set(marketId, eventObj)
        } else if (eventObj.marketId.marketName === 'Completed Match') {
          localBetfairCompletedMarket.push(eventObj)
          localbetfairCompletedMap.set(marketId, eventObj)
        } else if (eventObj.marketId.marketName === 'Tied Match') {
          localBetfairTiedMatchMarket.push(eventObj)
          localBetfairTiedMatchMap.set(marketId, eventObj)
        } else {
          localBetFairMarkets.push(eventObj)
          localBetFairMarketMap.set(marketId, eventObj)
        }
      } else if (eventObj.type === MARKET_TYPE.BOOKMAKER.valueOf()) {
        if (eventObj.marketId.marketName === 'BOOKMAKER') {
          if (!localBookMakerMarkets.length) {
            localBookMakerMarkets.push(eventObj)
          } else {
            localBookMakerMarkets = [eventObj, ...localBookMakerMarkets]
          }
        } else {
          localBookMakerMarkets.push(eventObj)
        }
        localBookMakerMarketMap.set(marketId, eventObj)
      } else {
        localPremiumMarketsMap.set(marketId, eventObj)
        localPremiumMarkets.push(eventObj)
      }
      localGame.push(eventObj)
    }

    setSessionMarketMap(new Map(localSessionMarketMap))
    setBetFairMarketMap(new Map(localBetFairMarketMap))

    setSessionFancyTotalMarketMap(new Map(localFancySessionTotalMarketMap))
    setSessionFancyWPMarketMap(new Map(localFancySessionWPMarketMap))
    setSessionFancyXtraMarketMap(new Map(localFancySessionXtraMarketMap))
    setSessionFancyMarketMap(new Map(localFancySessionMarketMap))

    setBetFairMatchOddsMarketMap(new Map(localBetFairMatchOddsMarketMap))
    setBetFairMatchOddsTieMap(new Map(localBetFairMatchOddsTieMap))
    setPremiumSessionMarketMap(new Map(localPremiumMarketsMap))
    setBookMakerMarketMap(new Map(localBookMakerMarketMap))

    // Markets
    setFancySessionTotalMarket(localFancySessionTotalMarkets)
    setFancySessionWPMarket(localFancySessionWPMarkets)
    setFancySessionXtraMarket(localFancySessionXtraMarkets)
    setFancySessionMarket(localFancySessionMarkets)

    setSessionMarket(localSessionMarkets)
    setBetFairMarket(localBetFairMarkets)
    setBetFairMatchOddsMarket(localBetFairMatchOddsMarkets)
    setBetFairMatchOddsTieMarket(localBetFairMatchOddsTieMarkets)
    setBetfairTiedmatch(localBetfairTiedMatchMarket)
    setBetfairCompletedMatch(localBetfairCompletedMarket)
    setBetFairToWinTheTossMarket(localBetFairToWinTheTossMarket)
    setBetfair05GoalsMarket(localBetFair05Market)
    setBetfair15GoalsMarket(localBetFair15Market)
    setBetfair25GoalsMarket(localBetFair25Market)
    setBetfair35GoalsMarket(localBetFair35Market)
    setPremiumMarkets(localPremiumMarkets)
    setBookMakerMarket(localBookMakerMarkets)

    gameHashMap.betFairMatchOdds = new Map(localBetFairMatchOddsMarketMap)
    gameHashMap.betFairMatchOddsTie = new Map(localBetFairMatchOddsTieMap)
    gameHashMap.session = new Map(localSessionMarketMap)

    gameHashMap.fancyTotalSession = new Map(localFancySessionTotalMarketMap)
    gameHashMap.fancyXtraSession = new Map(localFancySessionXtraMarketMap)
    gameHashMap.fancyWPSession = new Map(localFancySessionWPMarketMap)
    gameHashMap.fancySession = new Map(localFancySessionMarketMap)

    gameHashMap.bookmaker = new Map(localBookMakerMarketMap)
    gameHashMap.betfair = new Map(localBetFairMarketMap)
    gameHashMap.premiumMarket = new Map(localPremiumMarketsMap)
    return gameHashMap
  }

  useEffect(() => {
    let deletedMarketList = props.deletedMarketList
    let hashMapDeletedMarketList = deletedMarketList
      ? new Map(
          props.deletedMarketList.map((marketId: any) => [marketId, true]),
        )
      : new Map()

    !initialLoad &&
      props.getGameManagementCommonAction(
        'active=true',
        props.currentDomain,
        (selectedMarket: any) => {
          let hashMapSelectedMarket = selectedMarket
          const localBetFairMarkets: any[] = []
          const localBetFairMatchOddsMarkets: any[] = []
          const localBetFairMatchOddsTieMarkets: any[] = []
          const localBetFairToWinTheTossMarkets: any[] = []
          const localBetFair05GoalsMarkets: any[] = []
          const localBetFair15GoalsMarkets: any[] = []
          const localBetFair25GoalsMarkets: any[] = []
          const localBetFair35GoalsMarkets: any[] = []
          const localBetFairTiedMatchMarkets: any[] = []
          const localBetFaiCompletedMatchMarkets: any[] = []
          const localSessionMarkets: any[] = []
          const localFancySessionTotalMarkets: any[] = []
          const localFancySessionMarkets: any[] = []
          const localFancySessionWPMarkets: any[] = []
          const localFancySessionXtraMarkets: any[] = []
          const localPremiumMarkets: any[] = []
          const localBookMakerMarkets: any[] = []

          for (let index = 0; index < premiumMarkets.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[premiumMarkets[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(premiumMarkets[index].marketId.id)
              ) {
                deletedMarketList.push(premiumMarkets[index].marketId.id)
              }
            } else {
              localBookMakerMarkets.push(premiumMarkets[index])
            }
          }

          for (let index = 0; index < bookMakerMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[bookMakerMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  bookMakerMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(bookMakerMarket[index].marketId.id)
              }
            } else {
              localPremiumMarkets.push(bookMakerMarket[index])
            }
          }

          for (let index = 0; index < fancySessionXtraMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[fancySessionXtraMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  fancySessionXtraMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(
                  fancySessionXtraMarket[index].marketId.id,
                )
              }
            } else {
              localFancySessionXtraMarkets.push(fancySessionXtraMarket[index])
            }
          }

          for (let index = 0; index < fancySessionWPMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[fancySessionWPMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  fancySessionWPMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(fancySessionWPMarket[index].marketId.id)
              }
            } else {
              localFancySessionWPMarkets.push(fancySessionWPMarket[index])
            }
          }

          for (let index = 0; index < fancySessionMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[fancySessionMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  fancySessionMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(fancySessionMarket[index].marketId.id)
              }
            } else {
              localFancySessionMarkets.push(fancySessionMarket[index])
            }
          }

          for (let index = 0; index < fancySessionTotalMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[fancySessionTotalMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  fancySessionTotalMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(
                  fancySessionTotalMarket[index].marketId.id,
                )
              }
            } else {
              localFancySessionTotalMarkets.push(fancySessionTotalMarket[index])
            }
          }

          for (let index = 0; index < sessionMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[sessionMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(sessionMarket[index].marketId.id)
              ) {
                deletedMarketList.push(sessionMarket[index].marketId.id)
              }
            } else {
              localSessionMarkets.push(sessionMarket[index])
            }
          }

          for (let index = 0; index < betFairMatchOddsMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betFairMatchOddsMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betFairMatchOddsMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(
                  betFairMatchOddsMarket[index].marketId.id,
                )
              }
            } else {
              localBetFairMatchOddsMarkets.push(betFairMatchOddsMarket[index])
            }
          }

          for (
            let index = 0;
            index < betFairToWinTheTossMarket.length;
            index++
          ) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[
                betFairToWinTheTossMarket[index].marketId.id
              ]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betFairToWinTheTossMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(
                  betFairToWinTheTossMarket[index].marketId.id,
                )
              }
            } else {
              localBetFairToWinTheTossMarkets.push(
                betFairToWinTheTossMarket[index],
              )
            }
          }

          for (let index = 0; index < betfair05GoalsMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betfair05GoalsMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betfair05GoalsMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(betfair05GoalsMarket[index].marketId.id)
              }
            } else {
              localBetFair05GoalsMarkets.push(betfair05GoalsMarket[index])
            }
          }

          for (let index = 0; index < betfair15GoalasMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betfair15GoalasMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betfair15GoalasMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(betfair15GoalasMarket[index].marketId.id)
              }
            } else {
              localBetFair15GoalsMarkets.push(betfair15GoalasMarket[index])
            }
          }

          for (let index = 0; index < betfair25GoalasMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betfair25GoalasMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betfair25GoalasMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(betfair25GoalasMarket[index].marketId.id)
              }
            } else {
              localBetFair25GoalsMarkets.push(betfair25GoalasMarket[index])
            }
          }

          for (let index = 0; index < betfair35GoalsMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betfair35GoalsMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betfair35GoalsMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(betfair35GoalsMarket[index].marketId.id)
              }
            } else {
              localBetFair35GoalsMarkets.push(betfair35GoalsMarket[index])
            }
          }

          for (let index = 0; index < betfairTiedMatch.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betfairTiedMatch[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betfairTiedMatch[index].marketId.id,
                )
              ) {
                deletedMarketList.push(betfairTiedMatch[index].marketId.id)
              }
            } else {
              localBetFairTiedMatchMarkets.push(betfairTiedMatch[index])
            }
          }

          for (let index = 0; index < betfairCompletedMatch.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betfairCompletedMatch[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betfairCompletedMatch[index].marketId.id,
                )
              ) {
                deletedMarketList.push(betfairCompletedMatch[index].marketId.id)
              }
            } else {
              localBetFaiCompletedMatchMarkets.push(
                betfairCompletedMatch[index],
              )
            }
          }

          for (
            let index = 0;
            index < betFairMatchOddsTieMarket.length;
            index++
          ) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[
                betFairMatchOddsTieMarket[index].marketId.id
              ]
            ) {
              if (
                !hashMapDeletedMarketList.get(
                  betFairMatchOddsTieMarket[index].marketId.id,
                )
              ) {
                deletedMarketList.push(
                  betFairMatchOddsTieMarket[index].marketId.id,
                )
              }
            } else {
              localBetFairMatchOddsTieMarkets.push(
                betFairMatchOddsTieMarket[index],
              )
            }
          }

          for (let index = 0; index < betFairMarket.length; index++) {
            if (
              hashMapSelectedMarket &&
              !hashMapSelectedMarket[betFairMarket[index].marketId.id]
            ) {
              if (
                !hashMapDeletedMarketList.get(betFairMarket[index].marketId.id)
              ) {
                deletedMarketList.push(betFairMarket[index].marketId.id)
              }
            } else {
              localBetFairMarkets.push(betFairMarket[index])
            }
          }

          dispatch({
            type: DELETED_MARKET_LIST,
            data: deletedMarketList,
          })

          setFancySessionTotalMarket(localFancySessionTotalMarkets)
          setFancySessionWPMarket(localFancySessionWPMarkets)
          setFancySessionXtraMarket(localFancySessionXtraMarkets)
          setFancySessionMarket(localFancySessionMarkets)
          setSessionMarket(localSessionMarkets)
          setPremiumMarkets(localPremiumMarkets)
          setBookMakerMarket(localBookMakerMarkets)
          setBetFairMarket(localBetFairMarkets)
          setBetFairToWinTheTossMarket(localBetFairToWinTheTossMarkets)
          setBetfair05GoalsMarket(localBetFair05GoalsMarkets)
          setBetfair15GoalsMarket(localBetFair15GoalsMarkets)
          setBetfair25GoalsMarket(localBetFair25GoalsMarkets)
          setBetfair35GoalsMarket(localBetFair35GoalsMarkets)
          setBetfairCompletedMatch(localBetFaiCompletedMatchMarkets)
          setBetfairTiedmatch(localBetFairTiedMatchMarkets)
          setBetFairMatchOddsTieMarket(localBetFairMatchOddsTieMarkets)
          setBetFairMatchOddsMarket(localBetFairMatchOddsMarkets)
        },
      )
  }, [props.isMarketDeletedload])

  useEffect(() => {
    let deletedMarketList: any[] = []
    !initialLoad &&
      props.getGameManagementCommonAction(
        'active=true',
        props.currentDomain,
        (selectedMarket: any) => {
          let hashSelectedMarket = selectedMarket
            ? new Map(selectedMarket.map((marketId: any) => [marketId, true]))
            : new Map()

          for (let index = 0; index < props.deletedMarketList.length; index++) {
            if (!hashSelectedMarket.get(props.deletedMarketList[index])) {
              deletedMarketList.push(props.deletedMarketList[index])
            }
          }

          dispatch({
            type: DELETED_MARKET_LIST,
            data: deletedMarketList,
          })
        },
      )
  }, [props.isMarketAddedLoad])

  const updateSessionHashMapAndMarket = (marketStatus: any) => {
    if (!marketStatus || !marketStatus.length) return
    let localSessionMap = new Map(sessionMarketMap)
    let localSessionFancyTotalMaprketMap = new Map(sessionFancyTotalMarketMap)
    let localSessionFancyMarketMap = new Map(sessionFancyMarketMap)
    let localSessionFancyWPMarketMap = new Map(sessionFancyWPMarketMap)
    let localSessionFancyXtraMarketMap = new Map(sessionFancyXtraMarketMap)

    let localSessionFancyTotalMarket = fancySessionTotalMarket
    let localSessionFancyWPMarket = fancySessionWPMarket
    let localSessionFancyXtraMarket = fancySessionXtraMarket
    let localSessionFancyMarket = fancySessionMarket
    let localSessionMarket = sessionMarket

    for (const eventObj of marketStatus) {
      let marketId = eventObj.marketId.id
      if (eventObj.eventId !== decryptedId) {
        break
      }
      if (
        eventObj.marketId.externalType === 'bhawani' &&
        (eventObj.priority === -1 ||
          eventObj.status === 'result' ||
          eventObj.status === 'suspended')
      ) {
      } else if (eventObj.marketId.externalType === 'bhawani') {
        if (SESSION_PROIRITY.includes(eventObj.priority)) {
          if (localSessionFancyMarketMap.get(marketId)) {
            localSessionFancyMarketMap.set(
              marketId,
              replaceSessionLayAndBack(
                marketId,
                eventObj.lay,
                eventObj.back,
                eventObj.status,
                eventObj.delaySecond,
                eventObj.max,
                eventObj.min,
                localSessionFancyMarketMap,
              ),
            )
          } else {
            localSessionFancyMarket.push(eventObj)
            localSessionFancyMarketMap.set(marketId, eventObj)
          }
        } else if (SESSION_WP_PROIRITY.includes(eventObj.priority)) {
          if (localSessionFancyWPMarketMap.get(marketId)) {
            localSessionFancyWPMarketMap.set(
              marketId,
              replaceSessionLayAndBack(
                marketId,
                eventObj.lay,
                eventObj.back,
                eventObj.status,
                eventObj.delaySecond,
                eventObj.max,
                eventObj.min,
                localSessionFancyWPMarketMap,
              ),
            )
          } else {
            localSessionFancyWPMarket.push(eventObj)
            localSessionFancyWPMarketMap.set(marketId, eventObj)
          }
        } else if (SESSION_XRP_PROIRITY.includes(eventObj.priority)) {
          if (localSessionFancyXtraMarketMap.get(marketId)) {
            localSessionFancyXtraMarketMap.set(
              marketId,
              replaceSessionLayAndBack(
                marketId,
                eventObj.lay,
                eventObj.back,
                eventObj.status,
                eventObj.delaySecond,
                eventObj.max,
                eventObj.min,
                localSessionFancyXtraMarketMap,
              ),
            )
          } else {
            localSessionFancyXtraMarket.push(eventObj)
            localSessionFancyXtraMarketMap.set(marketId, eventObj)
          }
        }
        if (localSessionFancyTotalMaprketMap.get(marketId)) {
          localSessionFancyTotalMaprketMap.set(
            marketId,
            replaceSessionLayAndBack(
              marketId,
              eventObj.lay,
              eventObj.back,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localSessionFancyTotalMaprketMap,
            ),
          )
        } else {
          localSessionFancyTotalMarket.push(eventObj)
          localSessionFancyTotalMaprketMap.set(marketId, eventObj)
        }
      } else {
        if (localSessionMap.get(marketId)) {
          localSessionMap.set(
            marketId,
            replaceSessionLayAndBack(
              marketId,
              eventObj.lay,
              eventObj.back,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localSessionMap,
            ),
          )
        } else {
          localSessionMarket.push(eventObj)
          localSessionMap.set(marketId, eventObj)
        }
      }
    }

    setFancySessionTotalMarket(
      Array.from(localSessionFancyTotalMaprketMap, ([key, value]) => value),
    )
    setFancySessionMarket(
      Array.from(localSessionFancyMarketMap, ([key, value]) => value),
    )
    setFancySessionWPMarket(
      Array.from(localSessionFancyWPMarketMap, ([key, value]) => value),
    )
    setFancySessionXtraMarket(
      Array.from(localSessionFancyXtraMarketMap, ([key, value]) => value),
    )
    setSessionMarket(Array.from(localSessionMap, ([key, value]) => value))

    setSessionMarketMap(new Map(localSessionMap))
    setSessionFancyTotalMarketMap(new Map(localSessionFancyTotalMaprketMap))
    setSessionFancyMarketMap(new Map(localSessionFancyMarketMap))
    setSessionFancyWPMarketMap(new Map(localSessionFancyWPMarketMap))
    setSessionFancyXtraMarketMap(new Map(localSessionFancyXtraMarketMap))
  }

  const updateBetfairHashMapAndMarket = (marketStatus: any) => {
    if (!marketStatus || !marketStatus.length) return
    let localBetfairMap = new Map(betFairMarketMap)
    let localBetFairMatchOddsMarketMap = new Map(betFairMatchOddsMarketMap)
    let localBetFairMatchOddsTieMarketMap = new Map(
      betFairMatchOddsTieMarketMap,
    )
    let localBetFairToWinTheTossMap = new Map(betFairToWinTheTossMap)
    let localBetFair05GoalsMap = new Map(betFair05GoalsMap)
    let localBetFair15GoalsMap = new Map(betfair15GoalasMarket)
    let localBetFair25GoalsMap = new Map(betfair25GoalasMarket)
    let localBetFair35GoalsMap = new Map(betfair35GoalsMarket)
    let localBetFairCompletedMatchMap = new Map(betfairCompletedMatchMap)
    let localBetFairTiedMatchMap = new Map(betfairTiedMatchMap)

    const localBetFairMarkets: any[] = betFairMarket
    const localBetFairMatchOddsMarkets: any[] = betFairMatchOddsMarket
    const localBetFairMatchOddsTieMarkets: any[] = betFairMatchOddsTieMarket
    const localBetFairToWinTheTossMarkets: any[] = betFairToWinTheTossMarket
    const localBetFair05GoalsMarkets: any[] = betfair05GoalsMarket
    const localBetFair15GoalsMarkets: any[] = betfair15GoalasMarket
    const localBetFair25GoalsMarkets: any[] = betfair25GoalasMarket
    const localBetFair35GoalsMarkets: any[] = betfair35GoalsMarket
    const localBetFaircompletedMarkets: any[] = betfairCompletedMatch
    const localBetFairTiedMatchMarkets: any[] = betfairTiedMatch

    for (const eventObj of marketStatus) {
      let marketId = eventObj.marketId.id
      if (eventObj.eventId !== decryptedId) {
        break
      }
      if (eventObj.marketId.name === 'Match Odds') {
        if (localBetFairMatchOddsMarketMap.get(marketId)) {
          localBetFairMatchOddsMarketMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFairMatchOddsMarketMap,
            ),
          )
        } else {
          localBetFairMatchOddsMarkets.push(eventObj)
          localBetFairMatchOddsMarketMap.set(marketId, eventObj)
        }
      } else if (eventObj.marketId.name === 'Match Odds Including Tie') {
        if (localBetFairMatchOddsTieMarketMap.get(marketId)) {
          localBetFairMatchOddsTieMarketMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFairMatchOddsTieMarketMap,
            ),
          )
        } else {
          localBetFairMatchOddsTieMarkets.push(eventObj)
          localBetFairMatchOddsTieMarketMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.marketId.name === 'To Win the Toss' &&
        eventObj.marketId.type === 'betfair'
      ) {
        if (localBetFairToWinTheTossMap.get(marketId)) {
          localBetFairToWinTheTossMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFairToWinTheTossMap,
            ),
          )
        } else {
          localBetFairToWinTheTossMarkets.push(eventObj)
          localBetFairToWinTheTossMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.marketId.name === 'Over/Under 0.5 Goals' &&
        eventObj.marketId.type === 'betfair'
      ) {
        if (localBetFair05GoalsMap.get(marketId)) {
          localBetFair05GoalsMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFair05GoalsMap,
            ),
          )
        } else {
          localBetFair05GoalsMarkets.push(eventObj)
          localBetFair05GoalsMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.marketId.name === 'Over/Under 1.5 Goals' &&
        eventObj.marketId.type === 'betfair'
      ) {
        if (localBetFair15GoalsMap.get(marketId)) {
          localBetFair15GoalsMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFair15GoalsMap,
            ),
          )
        } else {
          localBetFair15GoalsMarkets.push(eventObj)
          localBetFair15GoalsMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.marketId.name === 'Over/Under 2.5 Goals' &&
        eventObj.marketId.type === 'betfair'
      ) {
        if (localBetFair25GoalsMap.get(marketId)) {
          localBetFair25GoalsMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFair25GoalsMap,
            ),
          )
        } else {
          localBetFair25GoalsMarkets.push(eventObj)
          localBetFair25GoalsMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.marketId.name === 'Over/Under 3.5 Goals' &&
        eventObj.marketId.type === 'betfair'
      ) {
        if (localBetFair35GoalsMap.get(marketId)) {
          localBetFair35GoalsMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFair35GoalsMap,
            ),
          )
        } else {
          localBetFair35GoalsMarkets.push(eventObj)
          localBetFair35GoalsMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.marketId.name === 'Completed Match' &&
        eventObj.marketId.type === 'betfair'
      ) {
        if (localBetFairCompletedMatchMap.get(marketId)) {
          localBetFairCompletedMatchMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFairCompletedMatchMap,
            ),
          )
        } else {
          localBetFaircompletedMarkets.push(eventObj)
          localBetFairCompletedMatchMap.set(marketId, eventObj)
        }
      } else if (
        eventObj.marketId.name === 'Tied Match' &&
        eventObj.marketId.type === 'betfair'
      ) {
        if (localBetFairTiedMatchMap.get(marketId)) {
          localBetFairTiedMatchMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetFairTiedMatchMap,
            ),
          )
        } else {
          localBetFairTiedMatchMarkets.push(eventObj)
          localBetFairTiedMatchMap.set(marketId, eventObj)
        }
      } else {
        if (localBetfairMap.get(marketId)) {
          localBetfairMap.set(
            marketId,
            replaceTeamsObject(
              marketId,
              eventObj.teams,
              eventObj.status,
              eventObj.delaySecond,
              eventObj.max,
              eventObj.min,
              localBetfairMap,
            ),
          )
        } else {
          localBetFairMarkets.push(eventObj)
          localBetfairMap.set(marketId, eventObj)
        }
      }
    }
    setBetFairMarketMap(new Map(localBetfairMap))
    setBetFairMatchOddsMarketMap(new Map(localBetFairMatchOddsMarketMap))
    setBetFairMatchOddsTieMap(new Map(localBetFairMatchOddsTieMarketMap))
    setBetfairCompletedMatchMap(new Map(localBetFairCompletedMatchMap))
    steBetfairTiedMatchMap(new Map(localBetFairTiedMatchMap))
    setBetFairToWinTheTossMap(new Map(localBetFairToWinTheTossMap))
    setBetFair05GoalsMap(new Map(localBetFair05GoalsMap))
    setBetFair15GoalsMap(new Map(localBetFair15GoalsMap))
    setBetFair25GoalsMap(new Map(localBetFair25GoalsMap))
    setBetFair35GoalsMap(new Map(localBetFair35GoalsMap))

    setBetFairMarket(Array.from(localBetfairMap, ([key, value]) => value))
    setBetFairMatchOddsTieMarket(
      Array.from(localBetFairMatchOddsTieMarketMap, ([key, value]) => value),
    )
    setBetFairToWinTheTossMarket(
      Array.from(localBetFairToWinTheTossMap, ([key, value]) => value),
    )
    setBetfair05GoalsMarket(
      Array.from(localBetFair05GoalsMap, ([key, value]) => value),
    )
    setBetfair15GoalsMarket(
      Array.from(localBetFair15GoalsMap, ([key, value]) => value),
    )
    setBetfair25GoalsMarket(
      Array.from(localBetFair25GoalsMap, ([key, value]) => value),
    )
    setBetfair35GoalsMarket(
      Array.from(localBetFair35GoalsMap, ([key, value]) => value),
    )
    setBetfairTiedmatch(
      Array.from(localBetFairTiedMatchMap, ([key, value]) => value),
    )
    setBetfairCompletedMatch(
      Array.from(localBetFairCompletedMatchMap, ([key, value]) => value),
    )
    setBetFairMatchOddsMarket(
      Array.from(localBetFairMatchOddsMarketMap, ([key, value]) => value),
    )
  }

  const updatePremiumSessionHashMapAndMarket = (marketStatus: any) => {
    if (!marketStatus || !marketStatus.length) return
    let localPremiumSessionMap = new Map(premiumSessionMarketMap)
    for (const eventObj of marketStatus) {
      let marketId = eventObj.marketId.id
      if (eventObj.eventId !== decryptedId) {
        break
      }
      if (localPremiumSessionMap.get(marketId)) {
        localPremiumSessionMap.set(
          marketId,
          replaceTeamsObject(
            marketId,
            eventObj.teams,
            eventObj.status,
            eventObj.delaySecond,
            eventObj.max,
            eventObj.min,
            localPremiumSessionMap,
          ),
        )
      } else {
        setPremiumMarkets([...premiumMarkets, eventObj])
        localPremiumSessionMap.set(marketId, eventObj)
      }
    }
    setPremiumSessionMarketMap(localPremiumSessionMap)
  }

  const updateBookMakerHashMapAndMarket = (marketStatus: any) => {
    if (!marketStatus || !marketStatus.length) return
    let localBookMakerMarketMap = new Map(bookMakerMarketMap)
    for (const eventObj of marketStatus) {
      let marketId = eventObj.marketId.id
      if (eventObj.eventId !== decryptedId) {
        break
      }
      if (localBookMakerMarketMap.get(marketId)) {
        localBookMakerMarketMap.set(
          marketId,
          replaceTeamsObject(
            marketId,
            eventObj.teams,
            eventObj.status,
            eventObj.delaySecond,
            eventObj.max,
            eventObj.min,
            localBookMakerMarketMap,
          ),
        )
      } else {
        if (eventObj.marketId.marketName === 'BOOKMAKER') {
          setBookMakerMarket([eventObj, ...bookMakerMarket])
        } else {
          setBookMakerMarket([...bookMakerMarket, eventObj])
        }
        localBookMakerMarketMap.set(marketId, eventObj)
      }
    }
    setBookMakerMarketMap(localBookMakerMarketMap)
  }

  useEffect(() => {
    props.marketStatus.sessionMarketStatus &&
      updateSessionHashMapAndMarket(props.marketStatus.sessionMarketStatus)
  }, [props.marketStatus.sessionMarketLoad])

  useEffect(() => {
    props.marketStatus.betfairMarketStatus &&
      updateBetfairHashMapAndMarket(props.marketStatus.betfairMarketStatus)
  }, [props.marketStatus.betfairMarketLoad])

  useEffect(() => {
    props.marketStatus.premiumMarketStatus &&
      updatePremiumSessionHashMapAndMarket(
        props.marketStatus.premiumMarketStatus,
      )
  }, [props.marketStatus.premiumMarketLoad])

  useEffect(() => {
    props.marketStatus.bookMakerMarketStatus &&
      updateBookMakerHashMapAndMarket(props.marketStatus.bookMakerMarketStatus)
  }, [props.marketStatus.bookMakerMarketLoad])

  useEffect(() => {
    if (props.userData.userType === 'user') {
      dispatch({
        type: APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT,
        isBaseLayoutVisible: true,
      })
    } else {
      dispatch({
        type: APP_CONFIGURATION_BASE_VISIBILITY_LAYOUT,
        isBaseLayoutVisible: false,
      })
    }
  }, [props.userData.userType])

  const intervalIdRef = useRef<number | null>(null)

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
    }

    if (props.userData.userType !== 'user') {
      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(props.userData.userType)}/user/openBet`,
        `eventId=${decryptedId}&active=true`,
        props.currentDomain.type,
        false,
        true,
        '',
        true,
      )
        .then((res: any) => {
          dispatch({
            type: GET_BET_HISTORY,
            betHistory: res.docs,
          })

          dispatch({
            type: UPDATE_BETHISTORY_EVENT_ID,
            eventId: decryptedId,
          })
        })
        .catch(() => {
          dispatch({
            type: GET_BET_HISTORY,
            betHistory: [],
          })
        })
    }

    dispatch({
      type: SET_USER_QUICK_BET,
      payload: false,
    })
    let selectedEvent = getEventFromMarket(decryptedId || '', props.eventDetail)
    if (selectedEvent) {
      dispatch({
        type: APP_SELECTED_EVENT,
        payload: selectedEvent,
      })
    }
    setEvent(selectedEvent)
    getMarketStatus(true)
  }, [decryptedId])

  useEffect(() => {
    dispatch({
      type: SET_USER_QUICK_BET,
      payload: false,
    })
    let selectedEvent = getEventFromMarket(decryptedId || '', props.eventDetail)
    if (selectedEvent) {
      dispatch({
        type: APP_SELECTED_EVENT,
        payload: selectedEvent,
      })
    }
    setEvent(selectedEvent)
    getMarketStatus(false)
  }, [event.eventTypeId])

  useEffect(() => {
    let selectedEvent = getEventFromMarket(decryptedId || '', props.eventDetail)
    if (selectedEvent) {
      dispatch({
        type: APP_SELECTED_EVENT,
        payload: selectedEvent,
      })
    }
    setEvent(selectedEvent)
  }, [props.eventDetail])

  useEffect(() => {
    if (!props.useSocket || !props.isLogin) {
      const intervalId = setInterval(
        () => getMarketStatus(false),
        MARKET_INTERNAL_STATUS_CALL_LOGIN,
      )
      intervalIdRef.current = intervalId as unknown as number
    }

    dispatch({
      type: SAVE_SCORE,
      scoreData: [],
      isLoading: false,
    })

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }
    }
  }, [props.useSocket])

  const getMarketStatus = (firstTimeStatus: boolean) => {
    props.removeMarkStatus()
    firstTimeStatus && setLoading(true)

    makeGetHttpCallAsync(
      dispatch,
      `api/common/game/market/status`,
      `eventId=${decryptedId}&active=true`,
      URLType.master,
      false,
      true,
      '',
    )
      .then((marketStatusResponse: any) => {
        setInitialLoad(false)
        getHashMapForMarket(marketStatusResponse.docs)
        firstTimeStatus && setLoading(false)
        setNoEventPresent(false)
      })
      .catch(() => {
        firstTimeStatus && setLoading(false)
        setNoEventPresent(true)
      })
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <>
      {noEventPresent ? (
        <div className='p-2 text-center dark:text-white text-black'>
          {' '}
          No Market Found{' '}
        </div>
      ) : loading ||
        (!betFairMarket.length &&
          !betFairToWinTheTossMarket.length &&
          !betfair05GoalsMarket.length &&
          !betfair15GoalasMarket.length &&
          !betfair25GoalasMarket.length &&
          !betfair35GoalsMarket.length &&
          !betfairCompletedMatch.length &&
          !betfairTiedMatch.length &&
          !betFairMatchOddsMarket.length &&
          !betFairMatchOddsTieMarket.length &&
          !bookMakerMarket.length &&
          !sessionMarket.length &&
          !premiumMarkets.length &&
          !fancySessionTotalMarket) ? (
        <div className='flex justify-center items-center h-20% mt-20 ml-50'>
          <SiteLoader />
        </div>
      ) : (
        <>
          {/* BetFair Match Odds Market*/}
          {!!betFairMatchOddsMarket.length &&
            betFairMatchOddsMarket.map((market: any) => {
              let tempMarket = betFairMatchOddsMarketMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <>
                  <RunBetfairMarket
                    tokenExpire={props.tokenExpire}
                    addFavoriteMarketList={props.addFavoriteMarketList}
                    betSlip={props.betSlip}
                    currentBet={currentBet}
                    currentDomain={props.currentDomain}
                    deleteFavoriteMarket={props.deleteFavoriteMarket}
                    hashMapMarket={hashMapMarket}
                    isDrawerOpen={isDrawerOpen}
                    isLogin={props.isLogin}
                    market={market}
                    marketFavoriteList={props.marketFavoriteList}
                    onMarketClick={props.onMarketClick}
                    setIsDrawerOpen={setIsDrawerOpen}
                    tempMarket={tempMarket}
                  />
                </>
              )
            })}

          {/* BOOMAKER MANUAL*/}
          {!!bookMakerMarket.length &&
            bookMakerMarket
              .filter((item: any) => (item.isManual = true))
              .map((market: any) => {
                let tempMarket = bookMakerMarketMap.get(market.marketId.id)
                if (!tempMarket || Object.keys(tempMarket).length === 0) {
                  tempMarket = market
                }
                tempMarket.name = market.marketId.marketName
                return tempMarket.status === 'result' ? (
                  <></>
                ) : (
                  <>
                    <ManualBookMakerMarketHeader
                      currentDomain={props.currentDomain}
                      isLogin={props.isLogin}
                      isCashout={market.marketId.cashout}
                      hashMapMarket={hashMapMarket}
                      setIsDrawerOpen={setIsDrawerOpen}
                      addFavoriteMarketList={props.addFavoriteMarketList}
                      deleteFavoriteMarket={props.deleteFavoriteMarket}
                      marketFavoriteList={props.marketFavoriteList}
                      tempMarket={tempMarket}
                      onMarketClick={props.onMarketClick}
                      {...tempMarket}
                    />
                    <Fragment key={market.id}>
                      <ManualBookMakerMarket
                        {...market}
                        betSlipStake={props.betSlip.betSlipStake}
                        setIsDrawerOpen={setIsDrawerOpen}
                        isDrawerOpen={isDrawerOpen}
                        currentBet={currentBet}
                        hashMapMarket={hashMapMarket}
                        tempMarket={tempMarket}
                        isLogin={props.isLogin}
                        onMarketClick={props.onMarketClick}
                      />
                    </Fragment>
                  </>
                )
              })}

          {/* BHAWANI BOOKMAKER */}
          {!!bookMakerMarket.length &&
            bookMakerMarket
              .filter((item: any) => (item.isManual = false))
              .map((market: any) => {
                let tempMarket = bookMakerMarketMap.get(market.marketId.id)
                if (!tempMarket || Object.keys(tempMarket).length === 0) {
                  tempMarket = market
                } else {
                  tempMarket.teams = { ...market.teams }
                }
                tempMarket.name = market.marketId.marketName

                return tempMarket.status === 'result' ? (
                  <></>
                ) : (
                  <>
                    <ManualBookMakerMarketHeader
                      tempMarket={tempMarket}
                      setIsDrawerOpen={setIsDrawerOpen}
                      isLogin={props.isLogin}
                      isCashout={market.marketId.cashout}
                      onMarketClick={props.onMarketClick}
                      hashMapMarket={hashMapMarket}
                      currentDomain={props.currentDomain}
                      addFavoriteMarketList={props.addFavoriteMarketList}
                      deleteFavoriteMarket={props.deleteFavoriteMarket}
                      marketFavoriteList={props.marketFavoriteList}
                      {...tempMarket}
                    />
                    <Fragment key={market.id}>
                      <ManualBookMakerMarket
                        {...market}
                        betSlipStake={props.betSlip.betSlipStake}
                        setIsDrawerOpen={setIsDrawerOpen}
                        isDrawerOpen={isDrawerOpen}
                        currentBet={currentBet}
                        hashMapMarket={hashMapMarket}
                        team={tempMarket.teams}
                        tempMarket={tempMarket}
                        isLogin={props.isLogin}
                        onMarketClick={props.onMarketClick}
                      />
                    </Fragment>
                  </>
                )
              })}

          {/* 0.5 GOALS BETFAIR */}
          {!!betfair05GoalsMarket.length &&
            betfair05GoalsMarket.map((market: any) => {
              let tempMarket = betFair05GoalsMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          {/* 1.5 GOALS BETFAIR */}
          {!!betfair15GoalasMarket.length &&
            betfair15GoalasMarket.map((market: any) => {
              let tempMarket = betFair15GoalsMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          {/* 2.5 GOALS BETFAIR */}
          {!!betfair25GoalasMarket.length &&
            betfair25GoalasMarket.map((market: any) => {
              let tempMarket = betFair25GoalsMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          {/* 3.5 GOALS BETFAIR */}
          {!!betfair35GoalsMarket.length &&
            betfair35GoalsMarket.map((market: any) => {
              let tempMarket = betFair35GoalsMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          {/* BetFair Market*/}
          {!!betFairMarket.length &&
            betFairMarket.map((market: any) => {
              let tempMarket = betFairMarketMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          {/* BetFair Match Odds Tie Market*/}
          {!!betFairMatchOddsTieMarket.length &&
            betFairMatchOddsTieMarket.map((market: any) => {
              let tempMarket = betFairMatchOddsTieMarketMap.get(
                market.marketId.id,
              )
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          <SessionComponent
            addFavoriteMarketList={props.addFavoriteMarketList}
            betSlip={props.betSlip}
            currentBet={currentBet}
            currentDomain={props.currentDomain}
            deleteFavoriteMarket={props.deleteFavoriteMarket}
            fancySessionMarket={fancySessionMarket}
            fancySessionTotalMarket={fancySessionTotalMarket}
            fancySessionWPMarket={fancySessionWPMarket}
            fancySessionXtraMarket={fancySessionXtraMarket}
            isDarkMode={props.isDarkMode || false}
            hashMapMarket={hashMapMarket}
            isDrawerOpen={isDrawerOpen}
            isLogin={props.isLogin}
            marketFavoriteList={props.marketFavoriteList}
            onMarketClick={props.onMarketClick}
            premiumMarkets={premiumMarkets}
            premiumSessionMarketMap={premiumSessionMarketMap}
            sessionFancyMarketMap={sessionFancyMarketMap}
            sessionFancyTotalMarketMap={sessionFancyTotalMarketMap}
            sessionFancyWPMarketMap={sessionFancyWPMarketMap}
            sessionFancyXtraMarketMap={sessionFancyXtraMarketMap}
            sessionMarket={sessionMarket}
            sessionMarketMap={sessionMarketMap}
            setIsDrawerOpen={setIsDrawerOpen}
          />

          {/* BetFair Match Odds Market*/}
          {!!betFairToWinTheTossMarket.length &&
            betFairToWinTheTossMarket.map((market: any) => {
              let tempMarket = betFairToWinTheTossMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          {/* BetFair Completed Market*/}
          {!!betfairCompletedMatch.length &&
            betfairCompletedMatch.map((market: any) => {
              let tempMarket = betfairCompletedMatchMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}

          {/* BetFair Tied Match Market*/}
          {!!betfairTiedMatch.length &&
            betfairTiedMatch.map((market: any) => {
              let tempMarket = betfairTiedMatchMap.get(market.marketId.id)
              if (!tempMarket || Object.keys(tempMarket).length === 0) {
                tempMarket = market
              }
              tempMarket.name = market.marketId.marketName
              return tempMarket.status === 'result' ? (
                <></>
              ) : (
                <RunBetfairMarket
                  tokenExpire={props.tokenExpire}
                  addFavoriteMarketList={props.addFavoriteMarketList}
                  betSlip={props.betSlip}
                  currentBet={currentBet}
                  currentDomain={props.currentDomain}
                  deleteFavoriteMarket={props.deleteFavoriteMarket}
                  hashMapMarket={hashMapMarket}
                  isDrawerOpen={isDrawerOpen}
                  isLogin={props.isLogin}
                  market={market}
                  marketFavoriteList={props.marketFavoriteList}
                  onMarketClick={props.onMarketClick}
                  setIsDrawerOpen={setIsDrawerOpen}
                  tempMarket={tempMarket}
                />
              )
            })}
        </>
      )}

      <DrawerComp
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        userData={props.userData}
      />
    </>
  )
}

import { ConnectedComponent, connect } from 'react-redux'
import { AppState } from '../../redux/type'
import {
  onMarketClick,
  updateOpenLoginScreen,
} from '../../redux/actions/userAction/betSlipAction'
import {
  addFavoriteEventList,
  deleteFavoriteEvent,
  getFavoriteEvent,
} from '../../redux'
import GameContainer from './GameContainer'

const mapStateToProps = (state: AppState) => {
  const isDarkMode = state.theme.isDarkMode
  const lang = state.language.lang
  const isLogin = state.login.isLogin
  const ongoingMatches = state.inPlayMatches.ongoingMatches
  const upComingMatches = state.inPlayMatches.upComingMatches
  const currentBet = state.betSlip.currentBet
  const fastestOdds = state.fastestOdds.fastestOdds || []
  const commonGame = state.gameManagementCommon.gamesCommonData
  const currentDomain = state.currentDomain
  const eventFavoriteList = state.login.eventFavoriteList
  const favoriteListEventNames = state.login.favoriteListEventNames
  const deletedEventId = state.appConfiguration.deletedEventId
  const liveCasinoData = state.adminGames.liveCasinoData
  const virtualCasinoData = state.adminGames.virtualCasinoData
  const appThemeConfiguration = state.appThemeConfiguration
  const marketLoader = state.appConfiguration.marketLoader

  return {
    marketLoader,
    appThemeConfiguration,
    deletedEventId,
    liveCasinoData,
    virtualCasinoData,
    isDarkMode,
    favoriteListEventNames,
    commonGame,
    currentBet,
    eventFavoriteList,
    currentDomain,
    lang,
    isLogin,
    upComingMatches,
    fastestOdds,
    ongoingMatches,
  }
}

const ConnectedGame: ConnectedComponent<any, any> = connect(mapStateToProps, {
  onMarketClick,
  updateOpenLoginScreen,
  addFavoriteEventList,
  deleteFavoriteEvent,
  getFavoriteEvent,
})(GameContainer as any)

export default ConnectedGame

import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../..'
import {
  URLType,
  makeDeleteHttpCallAsync,
  makeGetHttpCallAsync,
  makePostHttpCallAsync,
} from '../../../api'

export const SAVE_FASTEST_ODD = '@ODD/SAVE_FASTEST_ODD'

export const saveFastestOddAction = () => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeGetHttpCallAsync(
      dispatch,
      'api/common/game/fastestOdd',
      '',
      URLType.master,
      '',
      true,
    ).then((res: any) => {
      dispatch({
        type: SAVE_FASTEST_ODD,
        payload: res.docs,
      })
    })
  }
}

export const addFastestOdd = (payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makePostHttpCallAsync(
      dispatch,
      'api/administrator/game/fastestOdd',
      URLType.master,
      payload,
      '',
      true,
      '',
      true,
    ).then((res) => {
      makeGetHttpCallAsync(
        dispatch,
        'api/administrator/game/fastestOdd',
        '',
        URLType.master,
        '',
        true,
      ).then((res: any) => {
        dispatch({
          type: SAVE_FASTEST_ODD,
          payload: res.docs,
        })
      })
    })
  }
}

export const deleteFastestOdd = (payload: string) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    makeDeleteHttpCallAsync(
      dispatch,
      'api/administrator/game/fastestOdd',
      payload,
      URLType.master,
      '',
      true,
      '',
      true,
    ).then((res) => {
      makeGetHttpCallAsync(
        dispatch,
        'api/administrator/game/fastestOdd',
        '',
        URLType.master,
        '',
        true,
      ).then((res: any) => {
        dispatch({
          type: SAVE_FASTEST_ODD,
          payload: res.docs,
        })
      })
    })
  }
}

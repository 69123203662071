import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../../type'
import { makeGetHttpCallAsync } from '../../../api'

export const GET_USER_STAKES = '@STAKES/GET_USER_STAKES'
export const UPDATE_USER_STAKES = '@STAKES/UPDATE_USER_STAKES'

export const DEFAULT_STAKE = {
  _id: '655e40b50373ebe10c8b7320',
  userId: '65380d9240713e23d5edf328',
  createdAt: '2023-11-22T17:56:10.421Z',
  stake: [
    {
      label: '1k',
      value: 1000,
      _id: '655e40d6f9623a657aaa8d80',
    },
    {
      label: '2k',
      value: 2000,
      _id: '655e40d6f9623a657aaa8d81',
    },
    {
      label: '3k',
      value: 3000,
      _id: '655e40d6f9623a657aaa8d82',
    },
    {
      label: '4k',
      value: 4000,
      _id: '655e40d6f9623a657aaa8d83',
    },
    {
      label: '5k',
      value: 5000,
      _id: '655e40d6f9623a657aaa8d84',
    },
    {
      label: '6k',
      value: 6000,
      _id: '655e40d6f9623a657aaa8d85',
    },
  ],
  updatedAt: '2023-11-22T17:56:38.124Z',
}

export const getUserStakes = (currentDomain: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: GET_USER_STAKES, loading: true })
      makeGetHttpCallAsync(
        dispatch,
        `api/user/stake`,
        '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: GET_USER_STAKES,
            isLoading: false,
            data: response.docs,
          })
        })
        .catch(async (error: any) => {
          dispatch({
            type: GET_USER_STAKES,
            isLoading: false,
            data: DEFAULT_STAKE,
          })
        })
    } catch (error: any) {
      dispatch({ type: GET_USER_STAKES, loading: false })
    }
  }
}

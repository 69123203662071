import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../type'
import { HEADERS, makeGetHttpCallAsync } from '../../api'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { getCorrectUserType } from '../../utils'

// TransactionList
export const GET_TRANSACTION_LIST = '@ACCOUNTS/GET_TRANSACTION_LIST'
export const GET_TRANSACTION_DEPOSIT_LIST =
  '@ACCOUNTS/GET_TRANSACTION_DEPOSIT_LIST'
export const GET_TRANSACTION_DEPOSIT_LIST_LOADING =
  '@ACCOUNTS/GET_TRANSACTION_DEPOSIT_LIST_LOADING'
export const GET_TRANSACTION_WIDRAW_LIST =
  '@ACCOUNTS/GET_TRANSACTION_WIDRAW_LIST'
export const GET_TRANSACTION_WIDRAW_LIST_LOADING =
  '@ACCOUNTS/GET_TRANSACTION_WIDRAW_LIST_LOADING'
export const GET_TRANSACTION_DEPOSIT_LIST_PAGE =
  '@ACCOUNTS/GET_TRANSACTION_DEPOSIT_LIST_PAGE'
export const GET_TRANSACTION_WIDRAW_LIST_PAGE =
  '@ACCOUNTS/GET_TRANSACTION_WIDRAW_LIST_PAGE'

export const getTransactionList = (
  currentDomain: ICurrentDomain,
  payload: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      const userType = await localStorage.getItem(HEADERS.userType)

      dispatch({ type: GET_TRANSACTION_LIST, loading: true, data: [] })

      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/transaction`,
        payload || '',
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((response: any) => {
          dispatch({
            type: GET_TRANSACTION_LIST,
            loading: false,
            data: response.docs,
          })
        })
        .catch(async (error: any) => {
          let err = await error
        })
    } catch (error: any) {
      dispatch({ type: GET_TRANSACTION_LIST, loading: false })
    }
  }
}

export const getDepositData = (
  currentDomain: ICurrentDomain,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
  isNotRefresh?: boolean,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const userType = await localStorage.getItem(HEADERS.userType)
    try {
      !isNotRefresh &&
        !isPrint &&
        dispatch({
          type: GET_TRANSACTION_DEPOSIT_LIST_LOADING,
          loading: true,
        })

      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/deposit`,
        payload,
        currentDomain.type,
        '',
        true,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_TRANSACTION_DEPOSIT_LIST,
              data: res.docs,
              loading: false,
            })

          !isPrint &&
            res &&
            dispatch({
              type: GET_TRANSACTION_DEPOSIT_LIST_PAGE,
              data: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          cb && cb([])
          dispatch({
            type: GET_TRANSACTION_DEPOSIT_LIST,
            loading: false,
            data: [],
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({
        type: GET_TRANSACTION_DEPOSIT_LIST,
        loading: false,
        data: [],
      })
    }
  }
}

export const getWidrawData = (
  currentDomain: ICurrentDomain,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
  isNotRefresh?: boolean,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const userType = await localStorage.getItem(HEADERS.userType)

    try {
      !isNotRefresh &&
        !isPrint &&
        dispatch({
          type: GET_TRANSACTION_WIDRAW_LIST,
          loading: true,
        })

      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/withdraw`,
        payload,
        currentDomain.type,
        '',
        true,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_TRANSACTION_WIDRAW_LIST,
              data: res.docs,
              loading: false,
            })

          !isPrint &&
            res &&
            dispatch({
              type: GET_TRANSACTION_WIDRAW_LIST_PAGE,
              data: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          cb && cb([])
          dispatch({
            type: GET_TRANSACTION_WIDRAW_LIST,
            loading: false,
            data: [],
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({ type: GET_TRANSACTION_WIDRAW_LIST, loading: false, data: [] })
    }
  }
}

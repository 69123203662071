import { Dispatch } from '@reduxjs/toolkit'
import { GetReduxStateFn } from '../..'
import { HEADERS, makeGetHttpCallAsync } from '../../../api'
import { ICurrentDomain } from '../../../types/interfaces/currentDomain'
import { HASHMAP_BET } from '../userAction/betSlipAction'
import {
  createLiabilityHashmap,
  getLiabilityHashMapUpdate,
} from '../../../utils/internalUtils'
import { getCorrectUserType } from '../../../utils'

export const GET_ACCOUNT_STATEMENT = 'GET_ACCOUNT_STATEMENT'
export const GET_COMPLAIN_LIST = 'GET_COMPLAIN_LIST'
export const GET_OPEN_BET = 'GET_OPEN_BET'
export const GET_NON_USER_OPEN_BET = 'GET_NON_USER_OPEN_BET'
export const GET_OPEN_BET_LOADING = 'GET_OPEN_BET_LOADING'
export const GET_BET_HISTORY = 'GET_BET_HISTORY'
export const GET_BET_HISTORY_LOADING = 'GET_BET_HISTORY_LOADING'
export const GET_DW_REPORT = 'GET_DW_REPORT'
export const GET_USER_REPORT = 'GET_USER_REPORT'
export const GET_NON_USER_REPORT = 'GET_NON_USER_REPORT'
export const GET_DW_REPORT_LOADING = 'GET_DW_REPORT_LOADING'
export const GET_USER_REPORT_LOADING = 'GET_USER_REPORT_LOADING'
export const GET_USER_BET_DETAIL = 'GET_USER_BET_DETAIL'
export const GET_USER_BET_DETAIL_PAGINATION = 'GET_USER_BET_DETAIL_PAGINATION'
export const GET_USER_BET_DETAIL_LOADING = 'GET_USER_BET_DETAIL_LOADING'
export const GET_COMMISSION_REPORT = 'GET_COMMISSION_REPORT'
export const GET_COMMISSION_REPORT_PAGINATION =
  'GET_COMMISSION_REPORT_PAGINATION'
export const GET_COMMISSION_REPORT_LOADING = 'GET_COMMISSION_REPORT_LOADING'
export const GET_USER_DEPOSIT_DATA = 'GET_USER_DEPOSIT_DATA'
export const GET_USER_DEPOSIT_DATA_LOADING = 'GET_USER_DEPOSIT_DATA_LOADING'
export const GET_USER_DEPOSIT_DATA_PAGE = 'GET_USER_DEPOSIT_DATA_PAGE'
export const GET_USER_FRAUD_DATA = 'GET_USER_FRAUD_DATA'
export const GET_USER_FRAUD_DATA_LOADING = 'GET_USER_FRAUD_DATA_LOADING'
export const GET_USER_FRAUD_DATA_PAGE = 'GET_USER_FRAUD_DATA_PAGE'
export const GET_USER_WITHDRAW_DATA = 'GET_USER_WITHDRAW_DATA'
export const GET_USER_WITHDRAW_DATA_LOADING = 'GET_USER_WITHDRAW_DATA_LOADING'
export const GET_USER_WITHDRAW_DATA_PAGE = 'GET_USER_WITHDRAW_DATA_PAGE'

export const getAccountStatement = (
  currentDomain: ICurrentDomain,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    !isPrint && dispatch({ type: GET_ACCOUNT_STATEMENT, loading: true })
    try {
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        userType === 'user'
          ? 'api/user/transaction'
          : `api/${getCorrectUserType(userType)}/transaction`,
        payload,
        currentDomain.type,
        '',
        true,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_ACCOUNT_STATEMENT,
              loading: false,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              payload: res.docs,
            })
        })
        .catch((err) => {
          cb && cb([])
          dispatch({
            type: GET_ACCOUNT_STATEMENT,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({ type: GET_ACCOUNT_STATEMENT, loading: false })
    }
  }
}

export const getComplainList = (
  currentDomain: ICurrentDomain,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    !isPrint && dispatch({ type: GET_COMPLAIN_LIST, loading: true })
    try {
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}${userType !== 'administrator' && userType !== 'whiteLabel' ? '' : '/user'}/complain`,
        payload,
        currentDomain.type,
        '',
        true,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_COMPLAIN_LIST,
              loading: false,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              payload: res.docs,
            })
        })
        .catch((err) => {
          cb && cb([])
          dispatch({
            type: GET_COMPLAIN_LIST,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({ type: GET_COMPLAIN_LIST, loading: false })
    }
  }
}

export const getOpenBet = (
  currentDomain: any,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
  isNonUser: boolean,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      !isPrint &&
        dispatch({
          type: isNonUser ? GET_NON_USER_OPEN_BET : GET_OPEN_BET,
          payload: [],
          page: {},
          loading: true,
        })
      const userType = await localStorage.getItem(HEADERS.userType)
      const userId = getState().login.userData._id
      makeGetHttpCallAsync(
        dispatch,
        userType === 'user'
          ? `api/${getCorrectUserType(userType)}/openBet`
          : `api/${getCorrectUserType(userType)}/user/openBet`,
        payload,
        currentDomain.type,
        false,
        true,
        '',
      )
        .then((res: any) => {
          let liabilityHashMap = createLiabilityHashmap(
            res.docs,
            userType || '',
            userId,
          )
          cb && cb(res.docs)
          !isPrint &&
            !isNonUser &&
            dispatch({ type: HASHMAP_BET, hashMapBet: liabilityHashMap })

          !isPrint &&
            dispatch({
              type: isNonUser ? GET_NON_USER_OPEN_BET : GET_OPEN_BET,
              payload: res.docs,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          cb && cb([])
          dispatch({
            type: isNonUser ? GET_NON_USER_OPEN_BET : GET_OPEN_BET,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({
        type: isNonUser ? GET_NON_USER_OPEN_BET : GET_OPEN_BET,
        page: { totalPages: 1 },
        payload: [],
        loading: false,
      })
    }
  }
}

export const updateOpenBet = (openBetContent: any, userType: string) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    const openBet = getState().reports.openBet
    dispatch({
      type: GET_OPEN_BET,
      payload: [openBetContent, ...openBet],
    })

    // }

    // Calculate Open Bet HashMap
    // let liabilityHashMap = getLiabilityHashMapUpdate(
    //   hashMapBetLocal,
    //   openBetContent,
    //   userType,
    //   userId,
    // )

    // dispatch({ type: HASHMAP_BET, hashMapBet: liabilityHashMap })
  }
}

export const getBetHistory = (
  currentDomain: any,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      !isPrint &&
        dispatch({ type: GET_BET_HISTORY, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        userType !== 'user'
          ? `api/${getCorrectUserType(userType)}/user/openBet/history`
          : `api/${getCorrectUserType(userType)}/openBet/history`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_BET_HISTORY,
              payload: res.docs,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          cb && cb([])
          dispatch({
            type: GET_BET_HISTORY,
            loading: false,
            page: { totalPages: 1 },
            payload: [],
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({
        type: GET_BET_HISTORY,
        loading: false,
        page: { totalPages: 1 },
        payload: [],
      })
    }
  }
}

export const getCasinoOpenBet = (
  currentDomain: any,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      !isPrint &&
        dispatch({
          type: GET_OPEN_BET,
          payload: [],
          page: {},
          loading: true,
        })
      const userType = await localStorage.getItem(HEADERS.userType)

      makeGetHttpCallAsync(
        dispatch,
        userType === 'user'
          ? `api/${getCorrectUserType(userType)}/casino/openBet`
          : `api/${getCorrectUserType(userType)}/user/casino/openBet`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_OPEN_BET,
              payload: res.docs,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          cb && cb([])
          dispatch({
            type: GET_OPEN_BET,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({
        type: GET_OPEN_BET,
        page: { totalPages: 1 },
        payload: [],
        loading: false,
      })
    }
  }
}

export const getCasinoBetHistory = (
  currentDomain: any,
  payload: any,
  isPrint: boolean,
  cb: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      !isPrint &&
        dispatch({ type: GET_BET_HISTORY, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        userType !== 'user'
          ? `api/${getCorrectUserType(userType)}/user/casino/openBet/history`
          : `api/${getCorrectUserType(userType)}/casino/openBet/history`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_BET_HISTORY,
              payload: res.docs,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          cb && cb([])
          dispatch({
            type: GET_BET_HISTORY,
            loading: false,
            page: { totalPages: 1 },
            payload: [],
          })
        })
    } catch (error: any) {
      cb && cb([])
      dispatch({
        type: GET_BET_HISTORY,
        loading: false,
        page: { totalPages: 1 },
        payload: [],
      })
    }
  }
}

export const getUserReport = (
  currentDomain: any,
  payload: any,
  isNonUser?: boolean,
  isPrint?: boolean,
  cb?: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      !isPrint &&
        dispatch({ type: GET_USER_REPORT, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        `api/${userType === 'defaultBranch' ? 'branch' : userType}/user`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: isNonUser ? GET_NON_USER_REPORT : GET_USER_REPORT,
              payload: res.docs,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
                totalBonus: res.totalBonus,
                totalDeposit: res.totalDeposit,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          cb && cb([])
          !isPrint &&
            dispatch({
              type: GET_USER_REPORT,
              page: { totalPages: 1 },
              payload: [],
              loading: false,
            })
        })
    } catch (error: any) {
      cb && cb([])
      !isPrint &&
        dispatch({
          type: GET_USER_REPORT,
          page: { totalPages: 1 },
          payload: [],
          loading: false,
        })
    }
  }
}

export const getDwReport = (
  currentDomain: any,
  payload: any,
  dwType: string,
  isPrint: boolean,
  cb: (res: any) => void,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      !print && dispatch({ type: GET_DW_REPORT, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/${dwType}`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !print &&
            dispatch({
              type: GET_DW_REPORT,
              payload: res.docs,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          !print &&
            dispatch({
              type: GET_DW_REPORT,
              page: { totalPages: 1 },
              payload: [],
              loading: false,
            })
        })
    } catch (error: any) {
      !print &&
        dispatch({
          type: GET_DW_REPORT,
          page: { totalPages: 1 },
          payload: [],
          loading: false,
        })
    }
  }
}

export const getCasinoReport = (currentDomain: any, payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: GET_COMMISSION_REPORT, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        `api/administrator/user/commission`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          dispatch({
            type: GET_COMMISSION_REPORT,
            payload: res.docs,
            page: {
              limit: res.limit,
              nextPage: res.nextPage,
              nextPageNumber: res.nextPageNumber,
              previousPage: res.previousPage,
              previousPageNumber: res.previousPageNumber,
              showingFrom: res.showingFrom,
              showingTo: res.showingTo,
              totalDocs: res.totalDocs,
              totalPages: res.totalPages,
            },
            loading: false,
          })
        })
        .catch(async (error: any) => {
          dispatch({
            type: GET_COMMISSION_REPORT,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      dispatch({
        type: GET_COMMISSION_REPORT,
        page: { totalPages: 1 },
        payload: [],
        loading: false,
      })
    }
  }
}

export const getUserCommisionDetail = (currentDomain: any, payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: GET_USER_BET_DETAIL, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/user/commission/details`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          dispatch({
            type: GET_USER_BET_DETAIL,
            payload: res.docs,
            page: {
              limit: res.limit,
              nextPage: res.nextPage,
              nextPageNumber: res.nextPageNumber,
              previousPage: res.previousPage,
              previousPageNumber: res.previousPageNumber,
              showingFrom: res.showingFrom,
              showingTo: res.showingTo,
              totalDocs: res.totalDocs,
              totalPages: res.totalPages,
              totalBack: res.totalBack,
              totalBetPts: res.totalBetPts,
              totalCommission: res.totalCommission,
              totalLay: res.totalLay,
            },
            loading: false,
          })
        })
        .catch(async (error: any) => {
          dispatch({
            type: GET_USER_BET_DETAIL,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      dispatch({
        type: GET_USER_BET_DETAIL,
        page: { totalPages: 1 },
        payload: [],
        loading: false,
      })
    }
  }
}

export const getUserDetail = (currentDomain: any, payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: GET_USER_BET_DETAIL, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        userType !== 'user'
          ? `api/${getCorrectUserType(userType)}/user/openBet/history/details`
          : `api/${getCorrectUserType(userType)}/openBet/history/details`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          dispatch({
            type: GET_USER_BET_DETAIL,
            payload: res.docs,
            page: {
              limit: res.limit,
              nextPage: res.nextPage,
              nextPageNumber: res.nextPageNumber,
              previousPage: res.previousPage,
              previousPageNumber: res.previousPageNumber,
              showingFrom: res.showingFrom,
              showingTo: res.showingTo,
              totalDocs: res.totalDocs,
              totalPages: res.totalPages,
              totalBack: res.totalBack,
              totalBetPts: res.totalBetPts,
              totalCommission: res.totalCommission,
              totalLay: res.totalLay,
            },
            loading: false,
          })
        })
        .catch(async (error: any) => {
          dispatch({
            type: GET_USER_BET_DETAIL,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      dispatch({
        type: GET_USER_BET_DETAIL,
        page: { totalPages: 1 },
        payload: [],
        loading: false,
      })
    }
  }
}

export const getCasinoUserDetail = (currentDomain: any, payload: any) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      dispatch({ type: GET_USER_BET_DETAIL, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        userType !== 'user'
          ? `api/${getCorrectUserType(userType)}/user/casino/openBet/history/details`
          : `api/${getCorrectUserType(userType)}/casino/openBet/history/details`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          dispatch({
            type: GET_USER_BET_DETAIL,
            payload: res.docs,
            page: {
              limit: res.limit,
              nextPage: res.nextPage,
              nextPageNumber: res.nextPageNumber,
              previousPage: res.previousPage,
              previousPageNumber: res.previousPageNumber,
              showingFrom: res.showingFrom,
              showingTo: res.showingTo,
              totalDocs: res.totalDocs,
              totalPages: res.totalPages,
              totalBack: res.totalBack,
              totalBetPts: res.totalBetPts,
              totalLay: res.totalLay,
            },
            loading: false,
          })
        })
        .catch(async (error: any) => {
          dispatch({
            type: GET_USER_BET_DETAIL,
            page: { totalPages: 1 },
            payload: [],
            loading: false,
          })
        })
    } catch (error: any) {
      dispatch({
        type: GET_USER_BET_DETAIL,
        page: { totalPages: 1 },
        payload: [],
        loading: false,
      })
    }
  }
}

export const getFraudReport = (
  currentDomain: any,
  payload: any,
  isPrint?: boolean,
  cb?: any,
) => {
  return async (dispatch: Dispatch, getState: GetReduxStateFn) => {
    try {
      !isPrint &&
        dispatch({ type: GET_USER_FRAUD_DATA, loading: true, payload: [] })
      const userType = await localStorage.getItem(HEADERS.userType)
      makeGetHttpCallAsync(
        dispatch,
        `api/${getCorrectUserType(userType)}/user/fraudReport`,
        payload,
        currentDomain.type,
        false,
        true,
        undefined,
      )
        .then((res: any) => {
          cb && cb(res.docs)
          !isPrint &&
            dispatch({
              type: GET_USER_FRAUD_DATA,
              payload: res.docs,
              page: {
                limit: res.limit,
                nextPage: res.nextPage,
                nextPageNumber: res.nextPageNumber,
                previousPage: res.previousPage,
                previousPageNumber: res.previousPageNumber,
                showingFrom: res.showingFrom,
                showingTo: res.showingTo,
                totalDocs: res.totalDocs,
                totalPages: res.totalPages,
              },
              loading: false,
            })
        })
        .catch(async (error: any) => {
          !isPrint &&
            dispatch({
              type: GET_USER_FRAUD_DATA,
              page: { totalPages: 1 },
              payload: [],
              loading: false,
            })
          cb && cb([])
        })
    } catch (error: any) {
      !isPrint &&
        dispatch({
          type: GET_USER_FRAUD_DATA,
          page: { totalPages: 1 },
          payload: [],
          loading: false,
        })
      cb && cb([])
    }
  }
}

export interface IDemo {
  demoLogin: boolean
  demoDelayTime: number
}

const initialState: IDemo = {
  demoLogin: false,
  demoDelayTime: 0,
}

export const DEMO_LOGIN = '@DEMO/DEMO_LOGIN'

export default function (state = initialState, action: any) {
  switch (action.type) {
    case DEMO_LOGIN:
      return {
        ...state,
        demoLogin: action.payload,
        demoDelayTime: action.delayTime,
      }

    default:
      return state
  }
}

import { toast } from 'react-toastify'

interface IBet {
  betSlipOn: boolean
  isLogin: boolean
  setBetSlipOn: Function
  setEditStakes: Function
  setEditQuickBetStack: Function
}

export const Bet = (props: IBet) => {
  const editStakes = () => {
    if (!props.isLogin) {
      toast.error(_getLocalString('LOC_FIRST_TO_CONTINUE'))
      return
    }
    props.setEditStakes(true)
    props.setEditQuickBetStack(false)
  }

  return (
    <div className='flex w-full space-between  items-center  mb-2 gap-2 !px-2 '>
      <div className='flex  items-center justify-between xl:gap-2 md:gap-1 gap-2 h-[35px]'>
        <div
          className={` whitespace-nowrap h-[32px] xl:px-4 md:px-3 px-4 flex justify-center items-center leading-3 shadow-md rounded-md  font-dm_sans font-normal text-center cursor-pointer md:!text-[13px] xl:!text-[15px] !text-[15px] ${
            props.betSlipOn
              ? ' dark:text-[#1F1E1E] text-white dark:bg-[#fff] bg-black '
              : 'dark:text-white bg-transparent border dark:border-white border-black'
          }`}
          onClick={() => {
            props.setBetSlipOn(true)
            props.setEditStakes(false)
          }}
        >
          {_getLocalString('LOC_BET_SLIP')}
        </div>
        <div
          onClick={() => {
            props.setBetSlipOn(false)
            props.setEditStakes(false)
          }}
          className={` whitespace-nowrap h-[32px] xl:px-4 md:px-3 px-4 flex justify-center items-center leading-3 shadow-md rounded-md  font-dm_sans font-normal text-center cursor-pointer md:!text-[13px] xl:!text-[15px] !text-[15px] ${
            props.betSlipOn
              ? 'dark:text-white bg-transparent border dark:border-white border-black'
              : 'dark:text-[#1F1E1E] text-white dark:bg-[#fff] bg-black'
          }`}
        >
          {_getLocalString('LOC_OPEN_BETS')}
        </div>
      </div>
      <div
        className={`dark:text-[#fff]  whitespace-nowrap text-black dark:bg-[#066361] bg-[#A5D9FE] flex items-center h-[32px] leading-3 xl:px-4 md:px-3 px-4  rounded-md  font-dm_sans font-normal text-center cursor-pointer  md:!text-[13px] xl:!text-[15px] !text-[15px] xs:hidden md:flex`}
        onClick={editStakes}
      >
        {_getLocalString('LOC_EDIT_STAKES')}
      </div>
    </div>
  )
}

export const getEnvAssetUrl = () => {
  let assetUrl = import.meta.env.VITE_API_S3_BUCKET_ASSET as string
  return assetUrl
}

export const getEnvJsonAssetUrl = () => {
  let assetUrl = import.meta.env.VITE_API_S3_BUCKET_ASSET_JSON as string
  return assetUrl
}

export const getEnvThemeAssetUrl = () => {
  let assetUrl = import.meta.env
    .VITE_API_S3_BUCKET_THEME_RESOURCE_ASSET as string
  return assetUrl
}

export const getEnvResourceAssetUrl = () => {
  let assetUrl = import.meta.env.VITE_API_S3_BUCKET_RESOURCE_ASSET as string
  return assetUrl
}

import { Box } from '@mui/material'
import React, { ReactNode } from 'react'
import SwiperCore from 'swiper'
import { FreeMode } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/free-mode'
import { getAssetUrl } from '../../../../utils'
import { useSelector } from 'react-redux'
import { IEnable } from '../../../../types/interfaces/themeConfiguration'

SwiperCore.use([FreeMode])

interface PaymentmethodSlidesProps {
  children?: ReactNode[]
}

export const PaymentMethod: React.FC<PaymentmethodSlidesProps> = (children) => {
  const methods = [
    {
      name: 'Visa.svg',
      class: 'md:h-[22px] h-[10px] md:w-[71px] w-[34px]',
    },
    {
      name: 'american-express.svg',
      class: 'md:h-[56px] h-[27px] md:w-[101px] w-[49px]',
    },
    {
      name: 'Paypal.svg',
      class: 'md:h-[35px] h-[17px] md:w-[28px] w-[13px]',
    },
    {
      name: 'amazon.webp',
      class: 'md:h-[27px] h-[13px] w-[66px] md:w-[137px]',
    },
    {
      name: 'discover.webp',
      class: 'md:h-[34px] h-[15px] w-[70px] md:w-[151px]',
    },
  ]

  const enableFeatures: IEnable = useSelector(
    (state: any) => state.appThemeConfiguration.enable,
  )

  return (
    <>
      {enableFeatures?.isPaymentMethod && (
        <div className='bg-[var(--paymentMethodColor)] !w-full mt-1 xs:gap-2 sm:gap-2 md:gap-2  lg:gap-10  !flex flex-wrap items-center md:!px-0   !px-0 justify-center  relative  py-4  flex justify-center items-center'>
          <div
            style={{ lineHeight: 'normal' }}
            className='gradient_text  font-bebas md:text-4xl ml-4 md:ml-0 text-[17px] font-normal  tracking-[0.03em] text-left whitespace-nowrap'
          >
            {_getLocalString('LOC_OUR_PAYMENT_METHOD')}
          </div>
          <div className=' flex justify-center items-center gap-8 px-2'>
            {methods.map((method, index) => (
              <Box key={index} className=''>
                <img
                  alt='Payment Icon'
                  className={`object-contain  ${method.class}`}
                  src={getAssetUrl(`payment/${method.name}`)}
                />
              </Box>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

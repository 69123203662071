import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  useMediaQuery,
} from '@mui/material'
import { ICurrentBet, INewBetsSlip } from './NewBetSlip'
import { Cancel, Check } from '@mui/icons-material'
import ModeIcon from '@mui/icons-material/Mode'
import { useEffect, useState } from 'react'
import { makePatchHttpCallAsync } from '../../../api'
import {
  BET_SLIP_STAKE,
  IPlaceOrder,
  UPDATE_CURRENT_BETSLIP,
  UPDATE_CURRENT_BETSLIP_ODDS,
  UPDATE_IS_BET_LOADING,
} from '../../../redux/actions/userAction/betSlipAction'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { get2Decimal } from '../../../utils'
import {
  getLiability,
  getProfit,
  isMobileScreensix,
} from '../../../utils/internalUtils'
import { BET_TYPE } from '../../../types/enums/betType'
import { IUserData } from '../../../types/interfaces/login'
import { BetConfirmationModal } from './modal/BetConfirmationModal'
import { getCalculatedExposureOrProfit } from '../../../utils/gameUtils/gameUtil'
import InternalFilledInput from '../../../component/inputBoxes/InternalFilledInput'
import { SET_USER_QUICK_BET_VALUE } from '../../../redux'

export interface IBetSlipOnProps {
  isBetSlipLoading: boolean
  currentDomain: any
  userData: IUserData
  currentBet: ICurrentBet
  isLogin: boolean
  betSlip: INewBetsSlip
  availableStakesAmounts: any
  setEditQuickBetStack: Function
  setEditStakes: Function
  stakeAmount: any
  setStakeAmount: Function
  getMatchBet: Function
  demoLogin: boolean
  quickBetOn: boolean
  confirmBets: boolean
  openConfirmBets: boolean
  betSlipStake?: number
  quickBetValue: number
  setOpenConfirmBets: (openConfirmBets: boolean) => void
  setConfirmBets: (confirmBets: boolean) => void
  placeMarketOrder: (order: IPlaceOrder, currentDomain: any, data: any) => void
}

export const BetSlipOnComponent = (props: IBetSlipOnProps) => {
  const [odds, setOdds] = useState(props.currentBet.odd || 0)
  const [isDecimalOdds, setIsDecimalOdds] = useState(false)
  const [stackAmountInput, setStakeAmountInput] = useState(props.stakeAmount)
  const dispatch = useDispatch()
  const tabletMode = useMediaQuery('(min-width:500px) and (max-width:900px)')
  useEffect(() => {
    setStakeAmountInput(props.stakeAmount)
  }, [props.stakeAmount])

  const userCountryDetail = useSelector((state: any) => {
    return state.login.userCountryDetail
  })

  const placeOrder = (closeOpenBet: boolean = false) => {
    if (!props.isLogin) {
      toast.error(_getLocalString('LOC_LOGIN_TO_PLACE_ORDER'))
      return
    }

    if (props.isBetSlipLoading) {
      return
    }

    if (closeOpenBet) {
      props.setOpenConfirmBets(false)
    }

    if (!closeOpenBet && props.confirmBets) {
      return props.setOpenConfirmBets(true)
    }

    let currentBet = props.betSlip.currentBet

    dispatch({
      type: UPDATE_IS_BET_LOADING,
      isBetSlipLoading: true,
      timer: currentBet.delaySecond || 0,
    })

    if (
      currentBet.min > props.stakeAmount ||
      currentBet.max < props.stakeAmount
    ) {
      dispatch({
        type: UPDATE_IS_BET_LOADING,
        isBetSlipLoading: false,
        timer: currentBet.delaySecond || 0,
      })
      return toast.error(_getLocalString('LOC_AMOUNT_SHOULD_BE_IN_RANGE'))
    }

    if (currentBet.odd == 0) {
      dispatch({
        type: UPDATE_IS_BET_LOADING,
        isBetSlipLoading: false,
        timer: currentBet.delaySecond || 0,
      })
      return toast.error(`Odd/Run Can't be 0`)
    }

    // setTimeout(() => {
    props.placeMarketOrder(
      {
        marketId: currentBet.marketId._id,
        oddType: currentBet.oddType || BET_TYPE.BACK,
        odd: currentBet.odd,
        run: currentBet.run,
        teamId: currentBet.teamId,
        eventId: currentBet.marketId.event.id,
        marketType: currentBet.marketType.toLocaleUpperCase(),
        matchDate: props.betSlip.currentBet.marketId.event.openDate,
        name: currentBet.marketId.name,
        stake: props.stakeAmount,
      },
      props.currentDomain,
      userCountryDetail,
    )
    // }, currentBet.delaySecond * 1000)
  }

  const reset = () => {
    dispatch({
      type: UPDATE_CURRENT_BETSLIP,
      currentBet: {},
    })
    dispatch({
      type: BET_SLIP_STAKE,
      betSlipStake: 0,
    })
    setStakeAmountInput(0)
  }

  const updateConfirmBets = () => {
    let formData = new URLSearchParams()
    formData.append('confirmBets', `${!props.confirmBets}`)

    makePatchHttpCallAsync(
      dispatch,
      `api/user/confirmBets`,
      formData,
      '',
      props.currentDomain.type,
      false,
      true,
      'application/x-www-form-urlencoded',
      true,
    ).then(() => {
      props.setConfirmBets(!props.confirmBets)
    })
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    if (/^\d*$/.test(value)) {
      if (value.startsWith('0')) {
        value = value.replace(/^0+/, '')
      }

      setStakeAmountInput(parseInt(value))
      props.quickBetOn &&
        dispatch({
          type: SET_USER_QUICK_BET_VALUE,
          payload: parseInt(e.target.value),
        })
    }
  }

  useEffect(() => {
    props.setStakeAmount(stackAmountInput)
  }, [stackAmountInput])

  useEffect(() => {
    if (Object.keys(props.currentBet).length && props.currentBet.odd) {
      const isDecimal = parseFloat(props.currentBet.run.toString()) % 1 !== 0
      updateOdds(props.currentBet.odd)

      if (props.quickBetOn) {
        dispatch({
          type: BET_SLIP_STAKE,
          betSlipStake: props.quickBetValue,
        })
      } else if (props.betSlipStake) {
      } else {
        dispatch({
          type: BET_SLIP_STAKE,
          betSlipStake: 0,
        })
      }

      setIsDecimalOdds(isDecimal)
    }
  }, [props.currentBet])

  const rateChange = (type: any) => {
    if (type === 'increase') {
      const isDecimal = odds % 1 !== 0

      if (isDecimalOdds) {
        updateOdds(parseFloat(get2Decimal(odds + 0.01)), true)
      } else {
        updateOdds(odds + 1, true)
      }
    } else {
      const isDecimal = odds % 1 !== 0

      if (isDecimalOdds) {
        updateOdds(parseFloat(get2Decimal(odds - 0.01)), true)
      } else {
        updateOdds(odds > 0 ? odds - 1 : 0, true)
      }
    }
  }

  const getBetSlipProfit = () => {
    return getProfit(props.currentBet, props.stakeAmount)
  }

  const updateOdds = (value: number, update: boolean = false) => {
    setOdds(value)
    update &&
      dispatch({
        type: UPDATE_CURRENT_BETSLIP_ODDS,
        payload: value,
      })
  }

  const getBetSlipLiability = () => {
    return getLiability(props.currentBet, props.stakeAmount)
  }

  const maxStake = () => {
    setStakeAmountInput(get2Decimal(props.userData.availablePts))
    props.quickBetOn &&
      dispatch({
        type: SET_USER_QUICK_BET_VALUE,
        payload: get2Decimal(props.userData.availablePts),
      })
  }

  const handleAdditionValue = (amount: any) => {
    setStakeAmountInput(
      parseFloat(props.stakeAmount) + parseFloat(amount.value),
    )
  }

  return (
    <Box
      sx={{
        maxHeight: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          borderRadius: '10px',
          height: '10px',
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          cursor: 'pointer',
          backgroundColor: 'var(--selected-theme-color2)',
          borderRadius: '10px',
        },
        '@media screen and (min-width: 1200px)': {
          '&::-webkit-scrollbar': {
            height: '10px',
            width: '7px',
          },
        },
      }}
      className='overflow-auto'
    >
      <BetConfirmationModal
        liability={get2Decimal(getBetSlipLiability() || 0)}
        oddType={props.currentBet.oddType}
        odd={props.currentBet.odd}
        stakeAmount={props.stakeAmount}
        openConfirmBets={props.openConfirmBets}
        setOpenConfirmBets={props.setOpenConfirmBets}
        placeOrder={placeOrder}
        profit={get2Decimal(getBetSlipProfit())}
      />

      {!Object.keys(props.betSlip.currentBet).length ? (
        <div
          style={{ textAlign: 'center' }}
          className='items-center !justify-center dark:text-white'
        >
          {_getLocalString('LOC_MARKET_SELECTED')}
        </div>
      ) : (
        <>
          <div
            className={`${
              props.currentBet.oddType === BET_TYPE.BACK.valueOf()
                ? 'bg-[#A5D9FE] '
                : 'bg-[#F8D0CE]'
            } rounded-[6px] py-[5px] xl:px-4 lg:px-2 mx-2 md:px-1 px-4 text-[#1B1A1A] font-primary xs:hidden md:block`}
          >
            <div className='flex w-full space-between '>
              <div className='text-[15px] font-bold'>
                {props.currentBet.marketName}
              </div>
              <div className='text-right text-[15px] font-medium'>
                {props.currentBet.marketType !== 'session' &&
                  props.currentBet.teamName}
              </div>
            </div>

            <div className='flex items-center content-center justify-between gap-3 mt-2 '>
              <div className='text-[13px] font-dm_sans font-bold'>
                <div className='text-[13px] font-dm_sans font-bold mb-1'>
                  {_getLocalString('LOC_ODDS')}
                </div>
                {props.currentBet.marketType === 'session' ? (
                  <> {props.currentBet.odd} </>
                ) : (
                  <FormControl variant='outlined' className='' required>
                    <InternalFilledInput
                      value={odds}
                      type='number'
                      className={` rounded-md !min-w-[120px]  max-w-[150px] !bg-white  !p-0 !h-8`}
                      disableUnderline
                      onChange={(event) =>
                        updateOdds(parseFloat(event.target.value), true)
                      }
                      classes={{
                        input:
                          '!py-2 text-center  dark:placeholder:!text-[#010101] text-[#010101]  !font-dm_sans !text-[14px] !font-medium placeholder:!text-[#010101] placeholder:!opacity-100 ',
                      }}
                      id='outlined-adornment-weight'
                      endAdornment={
                        <InputAdornment position='end'>
                          <div
                            className={` !w-8 !h-8 bg-[#292929]  rounded-r-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-white cursor-pointer`}
                            onClick={() => {
                              rateChange('increase')
                            }}
                          >
                            +
                          </div>
                        </InputAdornment>
                      }
                      startAdornment={
                        <InputAdornment position='start'>
                          <div
                            className={` !w-8 !h-8 bg-[#292929]  rounded-l-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-white cursor-pointer`}
                            onClick={() => {
                              rateChange('decrease')
                            }}
                          >
                            -
                          </div>
                        </InputAdornment>
                      }
                      aria-describedby='outlined-weight-helper-text'
                    />
                  </FormControl>
                )}
              </div>

              {props.currentBet.marketType === 'session' && (
                <div className='text-[13px] font-dm_sans font-bold'>
                  <div className='text-[13px] font-dm_sans font-bold mb-1'>
                    {_getLocalString('LOC_RUNS')}
                  </div>
                  {props.currentBet.marketType === 'session' ? (
                    <> {props.currentBet.run} </>
                  ) : (
                    <FormControl variant='outlined' className='' required>
                      <InternalFilledInput
                        value={odds}
                        type='number'
                        className='!min-w-[120px] max-w-[150px] !bg-white !p-0 !h-8'
                        disableUnderline
                        classes={{
                          input:
                            '!py-2 text-center dark:placeholder:!text-[#010101] text-[#010101] !font-dm_sans !text-[13px] !font-medium placeholder:!text-[#010101] placeholder:!opacity-100 ',
                        }}
                        id='outlined-adornment-weight'
                        endAdornment={
                          <InputAdornment position='end'>
                            <div
                              className='!w-8 !h-8 bg-[#292929] rounded-r-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-white cursor-pointer'
                              onClick={() => {
                                rateChange('increase')
                              }}
                            >
                              +
                            </div>
                          </InputAdornment>
                        }
                        startAdornment={
                          <InputAdornment position='start'>
                            <div
                              className='!w-8 !h-8 bg-[#292929] rounded-l-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-white cursor-pointer'
                              onClick={() => {
                                rateChange('decrease')
                              }}
                            >
                              -
                            </div>
                          </InputAdornment>
                        }
                        aria-describedby='outlined-weight-helper-text'
                      />
                    </FormControl>
                  )}
                </div>
              )}

              {/* ----------------------------Stake Input-------------------------------- */}

              <div className='text-[13px] font-dm_sans font-bold '>
                <div className='text-[13px] font-dm_sans font-bold mb-1'>
                  {_getLocalString('LOC_STAKE')}
                </div>
                <FormControl variant='outlined' className='' required>
                  <InternalFilledInput
                    value={stackAmountInput}
                    placeholder='0'
                    onChange={handleAmountChange}
                    type='number'
                    className={` !min-w-[120px] max-w-[150px] !bg-white !p-0 !h-8`}
                    disableUnderline
                    classes={{
                      input:
                        '  !py-2 text-left !pl-4  dark:placeholder:!text-[#010101] text-[#010101]  !font-dm_sans !text-[13px] !font-medium placeholder:!text-[#010101] placeholder:!opacity-100 ',
                    }}
                    id='outlined-adornment-weight'
                    aria-describedby='outlined-weight-helper-text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <div
                          className={` !w-8 !h-8 bg-[#292929]  rounded-r-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-white cursor-pointer`}
                          onClick={() => setStakeAmountInput(0)}
                        >
                          <Cancel className='!text-lg' />
                        </div>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div
            className={`text-[#1B1A1A] justify-between mx-2  font-primary xs:flex xs:items-center  xs:gap-1 md:hidden`}
          >
            <div className='whitespace-nowrap font-primary text-[15px] font-medium leading-[30px] tracking-normal text-left text-black dark:text-white items-center'>
              {props.currentBet.marketName}
            </div>
            <div className='flex flex-wrap items-center justify-start xs:gap-4 sm:gap-8 flex-wrap'>
              <div className='flex flex-wrap items-center justify-start xs:gap-6 sm:gap-8 '>
                <div className='text-[13px] w-[40%] sm:w-auto text-white font-dm_sans font-bold'>
                  {props.currentBet.marketType === 'session' ? (
                    <>
                      <div style={{ textAlign: 'center' }}>
                        <h2 className='dark:text-white text-black font-bold'>
                          {_getLocalString('LOC_ODDS')}
                        </h2>
                        <div
                          className='dark:text-white text-black'
                          style={{ display: 'inline-block' }}
                        >
                          {props.currentBet.odd}
                        </div>
                      </div>
                    </>
                  ) : (
                    <FormControl
                      variant='outlined'
                      className={`${props.currentBet.oddType === BET_TYPE.BACK.valueOf() ? '!bg-[#4097d6]' : 'bg-[#e39894]'} `}
                      required
                    >
                      <InternalFilledInput
                        value={odds}
                        type='number'
                        className={`!w-[100px] ${props.currentBet.oddType === BET_TYPE.BACK.valueOf() ? '!bg-[#4097d6]' : 'bg-[#e39894]'}  !p-0 !h-8 rounded-md`}
                        disableUnderline
                        classes={{
                          input:
                            '!py-2 text-center  dark:placeholder:!text-white !text-white  !font-dm_sans !text-[12px] !font-medium placeholder:!text-white placeholder:!opacity-100 !rounded-md',
                        }}
                        id='outlined-adornment-weight'
                        endAdornment={
                          <InputAdornment position='end'>
                            <div
                              className={`!w-8 !h-8 ${props.currentBet.oddType === BET_TYPE.BACK.valueOf() ? '!bg-[#73BBEE]' : 'bg-[#F8D0CE]'} rounded-r-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-black cursor-pointer`}
                              onClick={() => {
                                rateChange('increase')
                              }}
                            >
                              +
                            </div>
                          </InputAdornment>
                        }
                        startAdornment={
                          <InputAdornment position='start'>
                            <div
                              className={`!w-8 !h-8 ${props.currentBet.oddType === BET_TYPE.BACK.valueOf() ? '!bg-[#73BBEE]' : 'bg-[#F8D0CE]'} rounded-l-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-black cursor-pointer`}
                              onClick={() => {
                                rateChange('decrease')
                              }}
                            >
                              -
                            </div>
                          </InputAdornment>
                        }
                        aria-describedby='outlined-weight-helper-text'
                      />
                    </FormControl>
                  )}
                </div>
                {props.currentBet.marketType === 'session' && (
                  <div className='text-[13px] text-white font-dm_sans font-bold'>
                    {props.currentBet.marketType === 'session' ? (
                      <>
                        <div style={{ textAlign: 'center' }}>
                          <h2 className='font-bold dark:text-white text-black '>
                            {_getLocalString('LOC_RUNS')}
                          </h2>
                          <div
                            className='dark:text-white text-black'
                            style={{ display: 'inline-block' }}
                          >
                            {props.currentBet.run}
                          </div>
                        </div>
                      </>
                    ) : (
                      <FormControl variant='outlined' className='' required>
                        <InternalFilledInput
                          value={odds}
                          type='number'
                          className='!w-[100px] !bg-[#73BBEE] !p-0 !h-8 rounded-md'
                          disableUnderline
                          classes={{
                            input:
                              '!py-2 text-center dark:placeholder:!text-[#010101] text-[#010101] !font-dm_sans !text-[12px] !font-medium placeholder:!text-[#010101] placeholder:!opacity-100 ',
                          }}
                          id='outlined-adornment-weight'
                          endAdornment={
                            <InputAdornment position='end'>
                              <div
                                className='!w-8 !h-8 bg-[#292929] rounded-r-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-white cursor-pointer'
                                onClick={() => {
                                  rateChange('increase')
                                }}
                              >
                                +
                              </div>
                            </InputAdornment>
                          }
                          startAdornment={
                            <InputAdornment position='start'>
                              <div
                                className='!w-8 !h-8 bg-[#292929] rounded-l-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-white cursor-pointer'
                                onClick={() => {
                                  rateChange('decrease')
                                }}
                              >
                                -
                              </div>
                            </InputAdornment>
                          }
                          aria-describedby='outlined-weight-helper-text'
                        />
                      </FormControl>
                    )}
                  </div>
                )}
                <div className='text-[13px] w-[40%] sm:w-auto font-dm_sans font-bold !rounded-md'>
                  <FormControl variant='outlined' className='' required>
                    <InternalFilledInput
                      value={props.stakeAmount}
                      onChange={(e) => {
                        setStakeAmountInput(parseInt(e.target.value || 0))
                        props.quickBetOn &&
                          dispatch({
                            type: SET_USER_QUICK_BET_VALUE,
                            payload: get2Decimal(props.userData.availablePts),
                          })
                      }}
                      type='number'
                      className='border border-solid !w-[100px] !bg-white !p-0 !h-8'
                      disableUnderline
                      classes={{
                        input:
                          ' !py-2 text-left !pl-4  dark:placeholder:!text-[#010101] text-[#010101]  !font-dm_sans !text-[12px] !font-medium placeholder:!text-[#010101] placeholder:!opacity-100 !rounded-md',
                      }}
                      id='outlined-adornment-weight'
                      aria-describedby='outlined-weight-helper-text'
                      endAdornment={
                        <InputAdornment position='end'>
                          <div
                            className={`!w-8 !h-8 ${props.currentBet.oddType === BET_TYPE.BACK.valueOf() ? '!bg-[#73BBEE]' : 'bg-[#F8D0CE]'}  rounded-r-[4px] flex justify-center items-center font-medium  font-dm_sans text-[17px] text-black cursor-pointer`}
                            onClick={() => setStakeAmountInput(0)}
                          >
                            <Cancel className='!text-lg' />
                          </div>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>

          <div className='flex mt-[5px] gap-3 px-2  w-full'>
            <div className='grid grid-cols-3 sm:gap-x-6 !w-full gap-x-3 gap-y-1'>
              {props.availableStakesAmounts.map((amount: any) => (
                <div
                  onClick={() => handleAdditionValue(amount)}
                  key={amount.id}
                  className={`${props.currentBet.oddType !== BET_TYPE.LAY.valueOf() ? 'dark:bg-[#fff] bg-black' : 'dark:bg-[#FFF] bg-black'} ${tabletMode ? '!h-[25px]' : ''} mt-1 flex items-center justify-center !h-6 rounded-[3px] dark:text-[#181818] text-white font-bold text-[12px] text-center cursor-pointer xl:px-12 xs:px-6 sm:px-9 lg:px-8 md:px-9 px-12  flex-1`}
                >
                  +{amount.label}
                </div>
              ))}
            </div>

            <div className='mt-1 grid gap-x-3 gap-y-1'>
              <div
                className={` flex border border-[#FAB020] items-center justify-center !h-6 shadow-md rounded-[3px] ${tabletMode ? '!h-[25px]' : ''} text-[#FAB020] font-bold text-[12px] text-center cursor-pointer xl:px-8 xs:px-2 sm:px-8 lg:px-6 md:px-4`}
                onClick={maxStake}
              >
                {_getLocalString('LOC_MAX')}
              </div>
              <div
                className={`mt-[4px]  flex border border-[#FAB020]  items-center justify-center !h-6 shadow-md  ${tabletMode ? '!h-[25px]' : ''} rounded-[3px] text-[#FAB020] font-bold text-[12px] text-center cursor-pointer xl:px-8 xs:px-2 sm:px-8 lg:px-6 md:px-4`}
                onClick={() => {
                  props.setEditQuickBetStack(false)
                  props.setEditStakes(true)
                }}
              >
                <ModeIcon className='!text-[16px]' />
                Stake
              </div>
            </div>
          </div>
          <div className='flex items-center justify-between px-2 text-black dark:text-white '>
            <FormControlLabel
              className='!m-0'
              control={
                <Checkbox
                  onChange={updateConfirmBets}
                  checked={props.confirmBets}
                  id='remeberMe'
                  icon={
                    <div className='w-5 h-5 rounded-[3px] bg-white  shadow-inner shadow-slate-400 dark:bg-white dark:shadow-none'></div>
                  }
                  checkedIcon={
                    <div className='w-5 h-5  justify-center items-center dark:bg-white shadow-inner shadow-slate-400 dark:shadow-none flex rounded-[3px]'>
                      <Check className='!text-lg text-[#f42020c9]' />
                    </div>
                  }
                  className='!pl-0 '
                />
              }
              label={`Confirm bets`}
            />
            <div className='dark:text-white text-black text-[12px] font-primary '>
              {_getLocalString('LOC_LIABILITY')}: -
              {get2Decimal(getBetSlipLiability() || 0)}
            </div>
          </div>
          <div className='flex gap-5 px-2 space-between'>
            <div
              className='flex justify-center bg-[#066361] px-2 py-0 shadow-md rounded-[4px] text-[14px] text-white w-1/3 font-medium text-center  items-center cursor-pointer !h-10'
              onClick={() => reset()}
            >
              {_getLocalString('LOC_CLEAR_ALL')}
            </div>
            <div
              onClick={() => placeOrder(false)}
              className={`${props.currentBet.oddType !== BET_TYPE.LAY ? 'bg-black dark:bg-[#FFF]' : 'dark:bg-[#FFF] bg-black'} px-4  shadow-md rounded-[3px] dark:text-black text-white font-medium text-center cursor-pointer w-65 !h-10 flex flex-col justify-center items-center`}
            >
              <div className='font-bold text-[15px] mb-[2px] leading-[15px] !capitalize'>
                {_getLocalString('LOC_PLACE_BET')}
              </div>
              <div className='font-medium text-[10px] leading-3 '>
                {_getLocalString('LOC_PLACE_BET_NOW')}:{' '}
                {get2Decimal(getBetSlipProfit())}
              </div>
            </div>
          </div>
        </>
      )}
      {/* {isMobileScreensix() && (
        <div>
          {props.currentBet.marketCurrentStatus &&
            props.currentBet.marketCurrentStatus.length &&
            props.currentBet.marketCurrentStatus.map((res: any) => {
              let exposureAmount = getCalculatedExposureOrProfit(
                res.teamId,
                res.exposureOrProfit,
                props.currentBet,
                props.stakeAmount,
              )
              return (
                <div className='flex space-between text-black dark:text-white pl-10 pr-10 '>
                  <div>{res.teamName}</div>
                  <div
                    className='bold'
                    style={{
                      color: `${exposureAmount > 0 ? 'green' : 'red'}`,
                    }}
                  >
                    {exposureAmount}
                  </div>
                </div>
              )
            })}
        </div>
      )} */}
    </Box>
  )
}

import { getAssetUrl, getThemeAssetUrl } from '..'
import { getEnvAssetUrl } from '../envValues'

export function addAlphaToColor(color: string, alpha: number): string {
  if (!color && color.trim()) {
    return ''
  }

  // Validate alpha value
  if (alpha < 0 || alpha > 1) {
    throw new Error('Alpha value must be between 0 and 1.')
  }

  // Check if color is in #RRGGBB or #RRGGBBAA format
  if (/^#([0-9A-F]{6}|[0-9A-F]{8})$/i.test(color)) {
    const hasAlpha = color.length === 9 // Check if color has alpha channel (#RRGGBBAA)
    if (hasAlpha) {
      // Color already has alpha channel, replace it
      return `#${color.substring(1, 7)}${Math.round(alpha * 255)
        .toString(16)
        .padStart(2, '0')}`
    } else {
      // Color does not have alpha channel, add it
      return `${color}${Math.round(alpha * 255)
        .toString(16)
        .padStart(2, '0')}`
    }

    // Check if color is in rgb(r, g, b) format
  } else if (/^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i.test(color)) {
    const matches = color.match(/^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i)
    if (!matches || matches.length !== 4) {
      throw new Error('Invalid rgb color format.')
    }
    const r = parseInt(matches[1])
    const g = parseInt(matches[2])
    const b = parseInt(matches[3])
    return `rgba(${r}, ${g}, ${b}, ${alpha})`

    // Check if color is in rgba(r, g, b, a) format
  } else if (
    /^rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(0?\.\d+)\s*\)$/i.test(color)
  ) {
    const matches = color.match(
      /^rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(0?\.\d+)\s*\)$/i,
    )
    if (!matches || matches.length !== 5) {
      throw new Error('Invalid rgba color format.')
    }
    const r = parseInt(matches[1])
    const g = parseInt(matches[2])
    const b = parseInt(matches[3])
    const currentAlpha = parseFloat(matches[4])
    const newAlpha = Math.min(1, Math.max(0, currentAlpha + alpha)) // Ensure alpha stays within 0 to 1
    return `rgba(${r}, ${g}, ${b}, ${newAlpha})`
  } else {
    return ''
  }
}

export function darkenColor(color: string, amount: number): string {
  if (!color || !color.trim()) {
    return ''
  }
  // Check if color is in hex format (#RRGGBB)
  if (/^#[0-9A-F]{6}$/i.test(color)) {
    color = color.slice(1) // Remove hash sign (#) from the beginning
    const r = parseInt(color.substring(0, 2), 16)
    const g = parseInt(color.substring(2, 4), 16)
    const b = parseInt(color.substring(4, 6), 16)

    // Calculate darker RGB components by adding more black
    const darkerR = Math.max(0, r * (1 - amount / 100))
    const darkerG = Math.max(0, g * (1 - amount / 100))
    const darkerB = Math.max(0, b * (1 - amount / 100))

    // Convert back to hex format
    return `#${Math.round(darkerR) * 65536 + Math.round(darkerG) * 256 + Math.round(darkerB).toString(16).padStart(6, '0')}`
  } else if (/^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i.test(color)) {
    // Parse RGB components from rgb(r, g, b) format
    const matches = color.match(/^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i)
    if (!matches || matches.length !== 4) {
      throw new Error('Invalid rgb color format.')
    }
    let r = parseInt(matches[1])
    let g = parseInt(matches[2])
    let b = parseInt(matches[3])

    // Calculate darker RGB components by adding more black
    const darkerR = Math.max(0, r * (1 - amount / 100))
    const darkerG = Math.max(0, g * (1 - amount / 100))
    const darkerB = Math.max(0, b * (1 - amount / 100))

    // Format darker color in rgb(r, g, b) format
    return `rgb(${Math.round(darkerR)}, ${Math.round(darkerG)}, ${Math.round(darkerB)})`
  } else {
    return ''
  }
}

export const setBackgroundColor = (
  siteLoader: boolean,
  isDarkMode: boolean,
  darkThemebackground: string,
  lightThemebackground: string,
  isFuture9Theme: boolean,
  themeColor: string,
  selectedThemeColor: string,
) => {
  document.documentElement.style.setProperty('--themeColor', selectedThemeColor)
  document.documentElement.style.setProperty('--fontColor', 'white')
  document.documentElement.style.setProperty('--assetLink', getEnvAssetUrl())
  document.documentElement.style.backgroundAttachment = 'fixed'
  document.documentElement.style.backgroundSize = 'cover'
  document.documentElement.style.backgroundRepeat = 'no-repeat'

  if (siteLoader) {
    document.documentElement.setAttribute('class', 'dark')
    ;(document.documentElement.style.backgroundColor = 'black'),
      (document.documentElement.style.backgroundImage = `url(${
        darkThemebackground
          ? getThemeAssetUrl(darkThemebackground)
          : getAssetUrl('asset/bg_dark.webp')
      })`)

    document.documentElement.style.setProperty(
      '--headerBottom',
      'rgba(240, 74, 54, 0.15)',
    )
  } else if (!!isDarkMode) {
    document.documentElement.setAttribute('class', 'dark')
    ;(document.documentElement.style.backgroundColor = isFuture9Theme
      ? '#150000'
      : darkenColor(themeColor, 100)),
      (document.documentElement.style.backgroundImage = `url(${
        darkThemebackground
          ? getThemeAssetUrl(darkThemebackground)
          : getAssetUrl('asset/bg_dark.webp')
      })`)

    document.documentElement.style.setProperty(
      '--headerBottom',
      isFuture9Theme ? 'rgba(240, 74, 54, 0.15)' : '',
    )
  } else {
    document.documentElement.setAttribute('class', 'light')
    document.documentElement.style.backgroundColor = lightThemebackground
    document.documentElement.style.backgroundImage = ``
    document.documentElement.style.setProperty(
      '--headerBottom',
      'rgba(240, 74, 54, 0.15)',
    )
  }
}

import { HEADERS } from '../api'
import * as data from '../strings.json'
import { getAssetJsonUrl } from './helper'

export type TypedObject<T> = { [key: string]: T }

export const initializeStrings = (lang: string = '') => {
  const fallBackToEnglish = () => {
    return new Promise((resolve) => {
      resolve(data)
    })
  }

  let language = localStorage.getItem(HEADERS.language)

  return (
    (!!lang && lang !== 'en-us') || (!!language && language === 'en-us')
      ? fetch(
          getAssetJsonUrl(
            `intl/${!lang ? 'en-us' : lang.toLowerCase()}/strings.json`,
          ),
        ).then((res) => (res.ok ? res.json() : fallBackToEnglish()))
      : fallBackToEnglish()
  )
    .catch(fallBackToEnglish)
    .then((strings: TypedObject<string>) => {
      const win = window as any
      win._getLocalString = (key: string) => strings[getSpecifiedKey(key)]
    })
}

const getSpecifiedKey = (key: string) => {
  if (!key || key.startsWith('LOC_')) {
    return key
  }
  key = key
    .replace(/[^\w\s]/gi, '')
    .trim()
    .replaceAll(' ', '_')
    .toUpperCase()
  return key
}

export function convertString(input: string) {
  if (!input) {
    return ''
  }
  return 'LOC_' + input.toUpperCase().replace(/ /g, '_')
}
